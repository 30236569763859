<script>
import ListItem from "@/components/common/ListItem.vue";
import ItemList from "./ItemList.vue";
import Attachment from "./Attachment.vue";
import EmojiPicker from "./EmojiPicker.vue";
import AddTaskNew from "../../components/AddTaskNew.vue";
import AddNewRequest from "../../components/AddNewRequest.vue";

export default {
  components: {
    ListItem,
    ItemList,
    Attachment,
    EmojiPicker,
    AddTaskNew,
    AddNewRequest,
  },

  props: {
    selectedWorkspace: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      message: "",
      showUsers: false,
      users: [],
      search: "",
      editor: "",
      showAddTask: false,
      showAddRequest: false,
    };
  },

  computed: {
    _users() {
      return this.users.filter((user) => user.label.includes(this.search));
    },
  },

  watch: {
    message() {
      this.search = "";
      const atIdx = this.message.lastIndexOf("@");

      if (atIdx === -1) return;
      if (atIdx === this.message.length + 1) return;

      this.search = this.message.slice(atIdx + 1, this.message.length);
    },

    selectedWorkspace: {
      immediate: true,
      handler() {
        if (
          this.selectedWorkspace &&
          this.selectedWorkspace.userList &&
          this.selectedWorkspace.userList.length
        ) {
          this.users = this.selectedWorkspace.userList
            .filter((user) => user.email !== this.$store.state.session.email)
            .map((user) => ({
              id: user.userId,
              label: user.email,
              value: user.email,
            }));
        } else {
          this.users = [];
        }
      },
    },
  },

  methods: {
    handleInput(e) {
      this.message = e.target.innerHTML;
    },

    handleSelect(emoji) {
      this.message = `${this.message} ${emoji} `;
      this.$refs.contentEditable.innerHTML = this.message;
    },

    sendMessage() {
      if (!this.message) return;

      this.$emit("send", this.message);
      this.$refs.contentEditable.innerHTML = "";
      this.message = "";
    },

    selectUser(userLabel) {
      const user = this.message.match(/@\w*/g).toReversed()[0];

      if (!user || !this._users.length) {
        return;
      }

      const contentEditable = this.$refs.contentEditable;
      const randomString = this.$nano.id();
      const innerHTML = contentEditable.innerHTML + randomString;
      const updatedInnerHTML = innerHTML.replace(
        user + randomString,
        `&#8203;<span class='user-tag'>${
          userLabel || this._users[0].label
        }</span>&nbsp;`
      );
      contentEditable.innerHTML = updatedInnerHTML;
      this.message = updatedInnerHTML;
      contentEditable.focus();

      const range = document.createRange();
      range.selectNodeContents(contentEditable);
      range.setEnd(contentEditable, contentEditable.childNodes.length);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      selection.collapseToEnd();

      this.showUsers = false;
      this.search = "";
    },

    handleKeyPress(e) {
      if (e.key === "Enter") {
        if (e.shiftKey) {
          this.message += "\n";
          return;
        }

        e.preventDefault();

        if (this.showUsers) {
          this.selectUser();
        } else {
          this.sendMessage();
        }
      }

      if (e.key === "@") {
        this.showUsers = true;
      }

      if (e.key === " ") {
        this.showUsers = false;
      }
    },

    addItem(itemName) {
      if (itemName === "Task") {
        this.showAddTask = true;
      }
      if (itemName === "Workflow") {
        this.showAddRequest = true;
      }
    },
  },
};
</script>

<template>
  <div class="chat-footer">
    <!-- Workspace users -->

    <q-menu
      v-if="showUsers && _users.length"
      v-model="showUsers"
      no-focus
      no-parent-event
      transition-show="scale"
      transition-hide="scale"
      anchor="top left"
      self="bottom left"
    >
      <BaseScrollbar height="196px" width="240px" class="q-py-sm">
        <ListItem
          v-for="(user, idx) in _users"
          :key="user.id"
          :label="user.label"
          :is-selected="idx === 0"
          @click="selectUser(user.label)"
        />
      </BaseScrollbar>
    </q-menu>

    <!-- Emojis -->

    <EmojiPicker @select="handleSelect" />

    <!-- Add Item -->

    <ItemList @add="addItem" />

    <AddTaskNew
      v-if="showAddTask"
      task-mode="ADD"
      :show-add-task="showAddTask"
      @close="showAddTask = false"
      @add="(payload) => $emit('addTask', payload)"
    />

    <AddNewRequest
      :add-request="showAddRequest"
      inbox-mode="ADD"
      @add="(payload) => $emit('addRequest', payload)"
    />

    <!-- Input -->

    <BaseScrollbar :style="{ maxHeight: '72px' }">
      <div
        id="contentEditable"
        ref="contentEditable"
        class="content-editable"
        contentEditable
        :placeholder="message ? '' : 'Type a message'"
        :style="{
          maxWidth: $q.fullscreen.isActive
            ? 'calc(100vw - 768px)'
            : 'calc(100vw - 820px)',
        }"
        @input="handleInput"
        @keypress="handleKeyPress"
      ></div>
    </BaseScrollbar>

    <!-- Attachment -->

    <Attachment @upload="(files) => $emit('upload', files)" />

    <!-- Send Btn -->

    <BaseActionButton is-flat icon="mdi-send" no-border @click="sendMessage" />

    <!-- ... -->
  </div>
</template>

<style lang="scss">
.chat-footer {
  border-radius: 4px;
  background-color: var(--component-bg-color);
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px 16px;

  .content-editable {
    width: 100%;
    min-height: 36px;
    padding: 8px;
    outline: none;
    user-select: text;
    white-space: pre-wrap;
    word-break: break-word;

    &::before {
      content: attr(placeholder);
      opacity: 0.7;
    }
  }
}
</style>
