<template>
  <ValidationProvider v-slot="{ errors }" :rules="validationRules">
    <TimeField
      :value="value"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :time-format="timeFormat"
      :is-clearable="false"
      :error="errors[0] || customError"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import { axiosCrypto } from "@/api/axios.js";
import { connector } from "@/api/factory.js";

export default {
  name: "TimeFieldWrapper",

  components: { ValidationProvider, TimeField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: [Number, String],
      default: 0,
    },

    filterBy: {
      type: Array,
      default: () => [],
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    formSettings: {
      type: Object,
      required: true,
    },

    lookupFilter: {
      type: String,
      default: "",
    },

    lookupFilterBy: {
      type: Array,
      default: () => [],
    },

    lookupFilterInTable: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      customError: "",
      options: [],
      hubLinkId: "",
    };
  },

  computed: {
    // validationRules() {
    //   const rules = {};

    //   if (this.isMandatory && !this.isReadonly) {
    //     rules.required = true;
    //   }

    //   const minTime = this.field.settings.validation.minimum;
    //   if (minTime) {
    //     rules.minTime = minTime;
    //   }

    //   const maxTime = this.field.settings.validation.maximum;
    //   if (maxTime) {
    //     rules.maxTime = maxTime;
    //   }

    //   return rules;
    // },

    validationRules() {
      const rules = {};

      if (this._isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      const range = this.field.settings.validation.timeRange;
      const maxiTime = this.field.settings.validation.maxiTime;
      const miniTime = this.field.settings.validation.miniTime;
      if (range === "TIME_RANGE") {
        const minTime = this.field.settings.validation.minimum;
        if (minTime) {
          rules.minTime = minTime;
        }

        const maxTime = this.field.settings.validation.maximum;
        if (maxTime) {
          rules.maxTime = maxTime;
        }
      } else if (range === "MINI_CURRENT_TIME") {
        if (maxiTime) {
          let time = this.$day.customFormat("hh:mm A");
          let add = time.split(":");
          let maximum = "";
          maximum = Number(add[0]) - maxiTime;

          // console.log(maximum);
          if (maximum <= 0) {
            let time = 12 + maximum;
            // console.log(time);
            rules.minTime = `${!maximum ? 12 : time}:${
              add[1].split(" ")[0]
            } AM`;
          } else {
            rules.minTime = `${maximum}:${add[1]}`;
          }
          // console.log(rules.minTime);
        } else {
          rules.minTime = this.$day.customFormat("hh:mm A");
        }
      } else if (range === "MAX_CURRENT_TIME") {
        if (miniTime) {
          let time = this.$day.customFormat("hh:mm A");
          let add = time.split(":");
          let maximum = "";
          maximum = Number(add[0]) + miniTime;

          // console.log(maximum);
          if (maximum >= 12) {
            let time = maximum - 12;
            // console.log(time);
            rules.maxTime = `${maximum == 12 ? 12 : time}:${
              add[1].split(" ")[0]
            } PM`;
          } else {
            rules.maxTime = `${maximum}:${add[1]}`;
          }
          // console.log(rules.maxTime);
        } else {
          rules.maxTime = this.$day.customFormat("hh:mm A");
        }
      }

      return rules;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    _isMandatory() {
      if (this.isMandatory) {
        return true;
      } else {
        return this.field.settings.validation.fieldRule === "REQUIRED";
      }
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    timeFormat() {
      return this.field.settings.validation.timeFormat;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filterBy) {
          if (this.filterBy.length) {
            if (this.field.settings.specific.masterFormId) {
              this.getMasterEntriesFilter();
            }
          }
        }
      },
    },

    lookupFilter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.lookupFilterBy) {
          if (this.lookupFilterBy.length) {
            if (this.hubLinkId) {
              this.getLookupFilterData(this.lookupFilterBy);
            }
          }
        }
      },
    },

    lookupFilterInTable: {
      immediate: true,
      handler() {
        console.log(this.field.lookupFilterBy, "1 this.field.lookupFilterBy");
        if (this.field.lookupFilterBy) {
          if (this.field.lookupFilterBy.length) {
            if (this.hubLinkId) {
              this.getLookupFilterData(this.field.lookupFilterBy);
            }
          }
        }
      },
    },
  },

  created() {
    let hasLookup = this.field.settings.lookupSettings;
    console.log(hasLookup, "hasLookup");
    if (hasLookup && hasLookup.columnName) {
      if (this.formSettings.hubLinkIds && this.formSettings.hubLinkIds.length) {
        let hublink = this.formSettings.hubLinkIds.find(
          (item) => item.jsonId === this.field.id
        );
        if (hublink) {
          this.hubLinkId = hublink.hubLinkId;
          this.getLookupData(this.hubLinkId);
        }
      }
    }
  },

  methods: {
    handleInput(value) {
      this.customError = "";
      if (this.field.settings.validation.timeRange === "CUSTOM_RANGE") {
        let minTime = this.field.settings.validation.minimum.split(":")[0];
        let maxTime = this.field.settings.validation.maximum.split(":")[0];
        let maxMins = this.field.settings.validation.maximum.split(":")[1];
        let hours = value.split(":")[0];
        let mins = value.split(":")[1];
        if (
          Number(hours) >= Number(minTime) ||
          Number(hours) < Number(maxTime)
        ) {
          //valid
        } else {
          if (
            Number(hours) < Number(minTime) &&
            Number(hours) > Number(maxTime)
          ) {
            this.customError = `should be greater than ${this.field.settings.validation.minimum}`;
          } else if (
            (Number(hours) === Number(maxTime) &&
              Number(mins) > Number(maxMins)) ||
            Number(hours) > Number(maxTime)
          ) {
            this.customError = `should be lesser than ${this.field.settings.validation.maximum}`;
          }
        }
      }
      this.$emit("input", value);
    },

    async getMasterEntriesFilter() {
      let filterBy = [];
      this.filterBy.forEach((item) => {
        filterBy.push({ ...item, value: item.value.toString() });
      });

      try {
        const response = await axiosCrypto.post(
          `/form/${this.field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: this.field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.handleInput(options[0]);
        } else {
          this.handleInput("");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getLookupData(hubLinkId) {
      const { error, payload } = await connector.getSyncValue({
        hublinkId: hubLinkId,
        payloadMapping: "",
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload, this.field.label, "payload");
      this.options = [];
      if (payload.data[0].value.length) {
        let modal = payload.data[0].value;
        modal.forEach((item) => {
          if (item[this.field.id]) {
            let unique = this.options.findIndex((option) => {
              return option.value === item[this.field.id];
            });
            if (unique === -1) {
              this.options.push({
                id: this.$nano.id(),
                label: item[this.field.id],
                value: item[this.field.id],
              });

              // this.$emit(
              //   "updateOptions",
              //   this.field,
              //   this.rowIndex,
              //   this.options
              // );
            }
          }
        });
      }
    },

    async getLookupFilterData(filter) {
      let input = "";
      if (filter && filter.length && filter[0].value) {
        input = {
          filterBy: [
            {
              id: this.$nano.id(),
              filters: filter,
              groupCondition: "",
            },
          ],
        };
      } else {
        input = "";
      }
      // console.log(input, this.hubLinkDetails, this.field.label, "input");

      const { error, payload } = await connector.getSyncValue({
        hublinkId: this.hubLinkId,
        payloadMapping: input ? JSON.stringify(input) : "",
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload, this.field.label, "payload");
      this.options = [];
      if (payload.data[0].value.length) {
        let modal = payload.data[0].value;
        modal.forEach((item) => {
          if (item[this.field.id]) {
            let unique = this.options.findIndex((option) => {
              return option.value === item[this.field.id];
            });
            if (unique === -1) {
              this.options.push({
                id: this.$nano.id(),
                label: item[this.field.id],
                value: item[this.field.id],
              });
              // this.$emit(
              //   "updateOptions",
              //   this.field,
              //   this.rowIndex,
              //   this.options
              // );
            }
          }
        });

        if (this.options.length === 1) {
          this.handleInput(this.options[0].value);
        } else {
          this.handleInput("");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
