<template>
  <div id="item-actions" class="row items-center">
    <template v-if="customProcessForm && false">
      <div class="cursor-pointer" @click="$emit('custom')">
        <q-chip outline color="orange" class="cursor-pointer orange-hover">
          Custom Form
        </q-chip>
      </div>
    </template>
    <template v-if="sendInvite">
      <div class="cursor-pointer" @click="$emit('invite')">
        <q-chip
          outline
          color="primary"
          class="cursor-pointer primary-hover q-mr-sm"
        >
          <q-avatar
            icon="mdi-account-plus"
            class="q-mr-md"
            color="primary"
            text-color="white"
          />
          Send Invite
        </q-chip>
      </div>
    </template>
    <template v-if="processForward">
      <div class="cursor-pointer" @click="$emit('forward')">
        <q-chip outline color="orange" class="cursor-pointer orange-hover">
          <q-avatar
            icon="mdi-arrow-right"
            class="q-mr-md"
            color="orange"
            text-color="white"
          />
          Assign
        </q-chip>
      </div>
    </template>
    <template v-if="workflowInitiate">
      <template v-if="initiateAccess">
        <div class="cursor-pointer" @click="$emit('initiate')">
          <q-chip
            outline
            color="secondary"
            class="cursor-pointer secondary-hover q-mr-sm"
          >
            <q-avatar
              icon="mdi-plus-thick"
              class="q-mr-md"
              color="secondary"
              text-color="white"
            />
            Initiate
          </q-chip>
        </div>
      </template>
      <template v-else>
        <q-chip
          v-tooltip.top="'Choose document to initiate'"
          outline
          disable
          color="secondary"
          class="cursor-pointer secondary-hover"
        >
          <q-avatar
            icon="mdi-plus-thick"
            class="q-mr-md"
            color="secondary"
            text-color="white"
          />
          Initiate
        </q-chip>
      </template>
    </template>
    <template v-if="gotoInbox">
      <div class="cursor-pointer q-mr-xs" @click="$emit('inbox')">
        <q-chip outline color="primary" class="cursor-pointer primary-hover">
          <q-avatar
            icon="mdi-email"
            class="q-mr-md"
            color="primary"
            text-color="white"
          />
          My Inbox
        </q-chip>
      </div>
    </template>

    <ImportPopup
      v-if="workflowImport"
      :chip-button="true"
      :data-imported="dataImported"
      :form-id="formId"
      @upload="upload"
      @close="closeImport"
    />

    <div
      v-if="downloadAll"
      class="row q-pr-xs"
      :class="{ download: checkIconCount }"
    >
      <BaseActionButton
        v-if="visibleDownload"
        v-tooltip.top="'download'"
        icon="mdi-download-outline"
        is-flat
        no-border
        class="repositoryIcon q-ml-xs q-mr-xs"
        @click="$emit('downloadAll', item.id)"
      />

      <BaseActionButton
        v-if="visiblePrint"
        v-tooltip.top="'print'"
        is-flat
        no-border
        icon="eva-printer-outline"
        class="repositoryIcon q-ml-xs q-mr-xs"
        @click.stop="$emit('printAll', item.id)"
      />

      <BaseActionButton
        v-if="visibleEmail"
        v-tooltip.top="'Email Sharing'"
        is-flat
        no-border
        icon="mdi-email-outline"
        class="repositoryIcon q-ml-xs q-mr-xs"
        @click.stop="$emit('emailAll', item)"
      />

      <BaseActionButton
        v-if="visibleDelete"
        v-tooltip.top="'Delete'"
        is-flat
        no-border
        icon="eva-trash-2-outline"
        class="repositoryIcon q-pa-sm"
        @click="$emit('deleteFileAction', item.id)"
      />
    </div>

    <slot name="filterSearchIcon"></slot>

    <template v-if="deleteBtn">
      <div class="cursor-pointer" @click.stop="$emit('delete')">
        <q-chip
          v-tooltip.top="'Delete Selected Items'"
          outline
          is-flat
          color="red"
          class="red-hover q-mr-sm tooltip-custom"
        >
          <q-avatar
            icon="eva-trash-outline"
            class="q-mr-md"
            color="red"
            text-color="white"
          />
          Delete
        </q-chip>
      </div>
    </template>

    <div v-if="!simpleSearchFilter" class="q-mr-xs">
      <SimpleSearchWithFilter
        :columns="columns"
        :filter-by="filterBy"
        @keyup="searchTable"
        @update="updateSimpletSearchFilterBy"
      />
    </div>

    <div v-else>
      <ExpandableSearch
        v-if="!hideActions.includes('search')"
        class="q-mr-sm"
        @search="searchTable"
      />
    </div>

    <SelectField
      v-if="id"
      v-model="dateValue"
      is-mandatory
      placeholder="Select day"
      :is-searchable="true"
      :options="dateFilter"
      class="fieldList q-mr-sm"
    />

    <BaseActionButton
      v-if="refresh || id"
      v-tooltip.top="'refresh'"
      is-flat
      no-border
      icon="eva-refresh"
      @click="$emit('refresh')"
    />

    <BaseActionButton
      v-if="jiraConnect"
      v-tooltip.top="'JIRA - Create Issue'"
      icon="mdi-jira"
      no-border
      is-flat
      @click="$emit('jira')"
    />

    <div v-if="mode === 'TRASH'" class="row justify-end">
      <BaseActionButton
        v-if="deleteBtn"
        v-tooltip.top="'Restore Selected Items'"
        is-flat
        no-border
        icon="mdi-history"
        @click.stop="$emit('restore')"
      />
    </div>

    <!-- <div v-if="deleteBtn">
      <BaseActionButton
        v-tooltip.top="'Delete Selected Items'"
        is-flat
        no-border
        icon="eva-trash-2-outline"
        @click.stop="$emit('delete')"
      />
    </div> -->

    <ToggleColumns
      v-if="!hideActions.includes('toggle')"
      :columns="
        repositoryFields ? (activeView === 'LIST' ? columns : []) : columns
      "
      :repository-fields="repositoryFields"
      :toggle-id="toggleId"
      @update:columns="updateColumns"
      @viewColumns="viewColumns"
    />

    <SortItems
      v-if="!hideActions.includes('sort')"
      :columns="columns"
      :order="order"
      :criteria="criteria"
      @update:order="updateOrder"
      @update:criteria="updateCriteria"
    />

    <GroupItems
      v-if="!hideActions.includes('group')"
      :columns="columns"
      :group-by="groupBy"
      @update:groupBy="updateGroupBy"
    />

    <FilterItems
      v-if="!hideActions.includes('filter')"
      :item="item"
      :columns="_columns"
      :filter-by="filterBy"
      :selected-filter="selectedFilter"
      :search-content="searchContent"
      :content-search="contentSearch"
      :module="module"
      :module-id="moduleId"
      @update="updateFilterBy"
      @updateContentSearch="updateContentSearch"
    />

    <ImportPopup
      v-if="importData"
      :data-imported="dataImported"
      :form-id="formId"
      @upload="upload"
      @close="closeImport"
      @downloadTemplate="downloadTemplate"
    />

    <BaseActionButton
      v-if="importJson"
      v-tooltip.top="'import'"
      is-flat
      no-border
      icon="mdi-import"
      @click="$emit('import')"
    />

    <BaseActionButton
      v-if="!hideActions.includes('export') && exportData"
      v-tooltip.top="'Export'"
      is-flat
      no-border
      icon="mdi-table-arrow-right"
      @click="$emit('export')"
    />

    <BaseSeparator
      v-if="!hideActions.includes('activeView')"
      is-vertical
      has-inset
      class="q-mx-sm"
    />

    <ViewToggle
      v-if="!hideActions.includes('activeView')"
      :active-view="activeView"
      :thumbnail-view="thumbnailView"
      :grid-view="gridView"
      :module="module"
      @update:activeView="updateActiveView"
    />

    <KanbanView
      :kanban-view="kanbanView"
      :active-view="activeKanbanView"
      :kanban-list="kanbanList"
      @update:activeKanbanView="updateActiveKanbanView"
      @clickKanban="$emit('clickKanban')"
    />

    <BaseSeparator v-if="dashboardView" is-vertical has-inset class="q-mx-sm" />

    <DashboardView
      v-if="dashboardView"
      :dashboard-view="dashboardView"
      :chart-view="chartView"
      :table-view="tableView"
      :page="page"
      @update:activeDashboardView="updateActiveDashboardView"
    />

    <slot name="extraIcon"></slot>
  </div>
</template>

<script>
import ToggleColumns from "./components/ToggleColumns.vue";
import SortItems from "./components/SortItems.vue";
import GroupItems from "./components/GroupItems.vue";
import FilterItems from "./components/FilterItems.vue";
import ViewToggle from "./components/ViewToggle.vue";
import KanbanView from "./components/KanbanView.vue";
import DashboardView from "./components/DashboardView.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";
import ImportPopup from "./components/ImportPopup.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import SimpleSearchWithFilter from "./components/SimpleSearchWithFilter.vue";

export default {
  name: "ItemActions",

  components: {
    ToggleColumns,
    ViewToggle,
    SortItems,
    GroupItems,
    FilterItems,
    ExpandableSearch,
    KanbanView,
    DashboardView,
    ImportPopup,
    SelectField,
    SimpleSearchWithFilter,
  },

  props: {
    item: {
      type: String,
      default: "item",
    },

    columns: {
      type: Array,
      required: true,
    },

    criteria: {
      type: String,
      required: true,
    },

    order: {
      type: String,
      required: true,
    },

    groupBy: {
      type: String,
      required: true,
    },

    filterBy: {
      type: Array,
      required: true,
    },

    activeView: {
      type: String,
      required: true,
    },

    thumbnailView: {
      type: Boolean,
      default: false,
    },

    filterColumns: {
      type: Array,
      default: () => [],
    },

    selectedFilter: {
      type: Array,
      default: () => [],
    },

    gridView: {
      type: Boolean,
      default: true,
    },

    kanbanView: {
      type: Boolean,
      default: false,
    },

    activeKanbanView: {
      type: String,
      default: "",
    },

    kanbanList: {
      type: Array,
      default: () => [],
    },

    jiraConnect: {
      type: Boolean,
      default: false,
    },

    importData: {
      type: Boolean,
      default: false,
    },

    dataImported: {
      type: Boolean,
      default: false,
    },

    hideActions: {
      type: Array,
      default: () => [],
    },

    workflowInitiate: {
      type: Boolean,
      default: false,
    },

    initiateAccess: {
      type: Boolean,
      default: false,
    },

    gotoInbox: {
      type: Boolean,
      default: false,
    },

    workflowImport: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "0",
    },

    dashboardView: {
      type: String,
      default: "",
    },

    chartView: {
      type: Boolean,
      default: false,
    },

    tableView: {
      type: Boolean,
      default: false,
    },

    searchContent: {
      type: Boolean,
      default: false,
    },

    contentSearch: {
      type: Object,
      default: () => {
        return { contentSearchValue: "", fuzzy: 0, searchType: 1 };
      },
    },

    repositoryFields: {
      type: Boolean,
      default: false,
    },

    id: {
      type: Number,
      default: 0,
    },

    module: {
      type: String,
      default: "",
    },

    moduleId: {
      type: [String, Number],
      default: 0,
    },

    deleteBtn: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "",
    },

    page: {
      type: String,
      default: "",
    },

    toggleId: {
      type: Number,
      default: 0,
    },

    simpleSearchFilter: {
      type: Boolean,
      default: false,
    },

    simpleSearchApplyed: {
      type: Boolean,
      default: false,
    },

    customProcessForm: {
      type: Boolean,
      default: false,
    },

    refresh: {
      type: Boolean,
      default: true,
    },

    exportData: {
      type: Boolean,
      default: true,
    },

    sendInvite: {
      type: Boolean,
      default: false,
    },

    downloadAll: {
      type: Boolean,
      default: false,
    },

    visibleDownload: {
      type: Boolean,
      default: false,
    },

    visiblePrint: {
      type: Boolean,
      default: false,
    },

    visibleEmail: {
      type: Boolean,
      default: false,
    },

    visibleDelete: {
      type: Boolean,
      default: false,
    },

    processForward: {
      type: Boolean,
      default: false,
    },

    importJson: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dateFilter: [
        {
          id: this.$nano.id(),
          label: "Today",
          value: "today",
        },
        {
          id: this.$nano.id(),
          label: "Yesterday",
          value: "yesterday",
        },
        {
          id: this.$nano.id(),
          label: "This Week",
          value: "7days",
        },
        {
          id: this.$nano.id(),
          label: "Last week",
          value: "week",
        },
        {
          id: this.$nano.id(),
          label: "This Month ",
          value: "Currentmonth",
        },
        {
          id: this.$nano.id(),
          label: "Last Month",
          value: "month",
        },
        {
          id: this.$nano.id(),
          label: "Last Quarter",
          value: "quarter",
        },
        {
          id: this.$nano.id(),
          label: "This Year",
          value: "currentyear",
        },
        {
          id: this.$nano.id(),
          label: "Last Year",
          value: "year",
        },
      ],
      dateValue: "",
    };
  },

  computed: {
    _columns() {
      if (this.filterColumns.length) {
        return this.filterColumns;
      } else {
        return this.columns;
      }
    },

    checkIconCount() {
      let count = 0;

      if (this.visibleDownload) {
        count += 1;
      }

      if (this.visibleEmail) {
        count += 1;
      }

      if (this.visiblePrint) {
        count += 1;
      }

      if (this.visibleDelete) {
        count += 1;
      }

      let iconCount;

      if (count === 2) {
        iconCount = true;
      }

      if (count === 3) {
        iconCount = true;
      }

      if (count === 4) {
        iconCount = true;
      }

      return iconCount;
    },
  },

  watch: {
    dateValue: {
      deep: true,
      immediate: true,
      handler() {
        if (this.dateValue) {
          if (this.dateValue === "today") {
            let keyValue = this.dateValue;
            const today = new Date();

            today.setDate(today.getDate());

            const formattedToday = today.toISOString().split("T")[0];

            this.$emit("filter", formattedToday, formattedToday, keyValue);
          } else if (this.dateValue === "yesterday") {
            let keyValue = this.dateValue;
            const yesterday = new Date();

            yesterday.setDate(yesterday.getDate() - 1);

            const formattedYesterday = yesterday.toISOString().split("T")[0];

            this.$emit(
              "filter",
              formattedYesterday,
              formattedYesterday,
              keyValue
            );
          } else if (this.dateValue === "week") {
            let keyValue = this.dateValue;
            let currentDate = new Date();
            let currentDayOfWeek = currentDate.getDay();
            let startOfCurrentWeek = new Date(currentDate);
            startOfCurrentWeek.setDate(
              currentDate.getDate() - currentDayOfWeek
            );
            let startOfPreviousWeek = new Date(startOfCurrentWeek);
            startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);
            let endOfPreviousWeek = new Date(startOfPreviousWeek);
            endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6);
            let previousWeekStart = startOfPreviousWeek
              .toISOString()
              .split("T")[0];
            let previousWeekEnd = endOfPreviousWeek.toISOString().split("T")[0];
            this.$emit("filter", previousWeekStart, previousWeekEnd, keyValue);
          } else if (this.dateValue === "7days") {
            let keyValue = this.dateValue;
            let currentDate = new Date();
            let currentDayOfWeek = currentDate.getDay();

            // Calculate start of current week
            let startOfCurrentWeek = new Date(currentDate);
            startOfCurrentWeek.setDate(
              currentDate.getDate() - currentDayOfWeek
            );

            // Calculate end of current week
            let endOfCurrentWeek = new Date(startOfCurrentWeek);
            endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

            let currentWeekStart = startOfCurrentWeek
              .toISOString()
              .split("T")[0];
            let currentWeekEnd = endOfCurrentWeek.toISOString().split("T")[0];
            this.$emit("filter", currentWeekStart, currentWeekEnd, keyValue);
          } else if (this.dateValue === "month") {
            let keyValue = this.dateValue;
            let currentDate = new Date();
            let juneFirstCurrentYear = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth() - 1,
              2
            );
            let juneThirtyLastYear = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth()
            );
            let formattedJuneFirstCurrentYear = juneFirstCurrentYear
              .toISOString()
              .split("T")[0];
            let formattedJuneThirtyLastYear = juneThirtyLastYear
              .toISOString()
              .split("T")[0];
            this.$emit(
              "filter",
              formattedJuneFirstCurrentYear,
              formattedJuneThirtyLastYear,
              keyValue
            );
          } else if (this.dateValue === "quarter") {
            // const today = new Date();
            let keyValue = this.dateValue;
            // const lastQuarter = new Date(today);

            // lastQuarter.setMonth(today.getMonth() - 3);

            // const formattedLastQuarter = lastQuarter
            //   .toISOString()
            //   .split("T")[0];

            // today.setDate(today.getDate());

            // const formattedToday = today.toISOString().split("T")[0];

            let currentDate = new Date();
            let currentMonth = currentDate.getMonth();
            let startMonth = (currentMonth - 3 + 12) % 12;
            let startDate = new Date(currentDate.getFullYear(), startMonth, 2);
            let endMonth = currentMonth - 1;
            let endDate = new Date(currentDate.getFullYear(), endMonth + 1, 1);
            let startDateStr = startDate.toISOString().slice(0, 10);
            let endDateStr = endDate.toISOString().slice(0, 10);

            this.$emit(
              "filter",
              // formattedLastQuarter,
              // formattedToday,
              startDateStr,
              endDateStr,
              keyValue
            );
          } else if (this.dateValue === "year") {
            let keyValue = this.dateValue;
            let currentDate = new Date();
            let januaryFirst2023 = new Date(
              currentDate.getFullYear() - 1,
              0,
              2
            );
            let decemberThirtyFirst2023 = new Date(
              currentDate.getFullYear() - 1,
              11,
              32
            );
            let formattedJanuaryFirst2023 = januaryFirst2023
              .toISOString()
              .split("T")[0];
            let formattedDecemberThirtyFirst2023 = decemberThirtyFirst2023
              .toISOString()
              .split("T")[0];
            this.$emit(
              "filter",
              formattedJanuaryFirst2023,
              formattedDecemberThirtyFirst2023,
              keyValue
            );
          } else if (this.dateValue === "Currentmonth") {
            let keyValue = this.dateValue;
            let currentDate = new Date();
            let currentMonthStart = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1
            );
            let formattedCurrentMonthStart = `${currentMonthStart.getFullYear()}-${(
              currentMonthStart.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${currentMonthStart
              .getDate()
              .toString()
              .padStart(2, "0")}`;
            let formattedCurrentDate = `${currentDate.getFullYear()}-${(
              currentDate.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${currentDate
              .getDate()
              .toString()
              .padStart(2, "0")}`;
            this.$emit(
              "filter",
              formattedCurrentMonthStart,
              formattedCurrentDate,
              keyValue
            );
          } else if (this.dateValue === "currentyear") {
            let keyValue = this.dateValue;
            let currentDate = new Date();
            let januaryFirstCurrentYear = new Date(
              currentDate.getFullYear(),
              0,
              2
            );
            let formattedJanuaryFirstCurrentYear = januaryFirstCurrentYear
              .toISOString()
              .split("T")[0];
            let formattedCurrentDate = currentDate.toISOString().split("T")[0];
            this.$emit(
              "filter",
              formattedJanuaryFirstCurrentYear,
              formattedCurrentDate,
              keyValue
            );
          }
        }
      },
    },
  },

  methods: {
    updateColumns(columns) {
      this.$emit("update:columns", columns);
    },

    updateCriteria(criteria) {
      this.$emit("update:criteria", criteria);
    },

    updateOrder(order) {
      this.$emit("update:order", order);
    },

    updateGroupBy(groupBy) {
      this.$emit("update:groupBy", groupBy);
    },

    updateFilterBy(filters, searchContent) {
      this.$emit("update:simpleSearchApplyed", false);
      this.$emit("update:filterBy", filters);
      this.$emit("update", filters, searchContent);
    },

    updateContentSearch(value) {
      this.$emit("update:contentSearch", value);
    },

    updateActiveView(activeView) {
      this.$emit("update:activeView", activeView);
    },

    searchTable(searchQuery) {
      this.$emit("search", searchQuery);
    },

    updateActiveKanbanView(activeView) {
      this.$emit("update:activeKanbanView", activeView);
    },

    updateActiveDashboardView(activeView) {
      this.$emit("update:dashboardView", activeView);
    },

    upload() {
      this.$emit("upload");
    },

    closeImport() {
      this.$emit("close");
    },

    viewColumns(columns) {
      this.$emit("viewColumns", columns);
    },

    updateSimpletSearchFilterBy(filters) {
      this.$emit("update:simpleSearchApplyed", true);
      this.$emit("update:filterBy", filters);
      this.$emit("update", filters);
    },

    downloadTemplate() {
      this.$emit("downloadTemplate");
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-hover:hover {
  background: $secondary-2 !important;
}

.primary-hover:hover {
  background: $primary-2 !important;
}

.orange-hover:hover {
  background: $orange-2 !important;
}

.red-hover:hover {
  background-color: $red-2 !important;
}
</style>
<style lang="scss">
#item-actions {
  #select-field.fieldList {
    .select {
      min-height: 0px !important;
      color: $secondary !important;
      overflow: hidden !important;
      width: 120px;
    }
  }

  .download {
    border: 1px solid #475569;
    border-radius: 30px;
    height: 32px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .repositoryIcon {
    width: 27px;
    height: 27px;
  }
}
</style>
