<template>
  <div id="email-authentication-field">
    <ValidationObserver ref="form">
      <ValidationProvider
        v-slot="{ errors }"
        name="email"
        :rules="{ required: true, email: true }"
      >
        <div id="text-field">
          <FormFieldLabel
            v-if="label"
            label="Please enter a valid email address
          for all communication regarding your claim"
            transform=""
          />

          <div class="input-group">
            <FormFieldWrapper>
              <input
                v-model="email"
                type="text"
                :autofocus="true"
                placeholder="Enter Email Address"
                class="q-px-sm"
                @focus="isFocused = true"
                @blur="isFocused = false"
                @keypress.enter="emailAuthentication"
                @keypress="$emit('error', '')"
                @input="reset"
              />
            </FormFieldWrapper>

            <div class="input-group-btn">
              <!-- label="Generate OTP" -->
              <BaseButton
                label="Authenticate"
                :is-loading="isLoading"
                class="hover"
                color="secondary"
                @click="emailAuthentication"
              />
            </div>
          </div>
          <!-- <FormFieldError v-if="errors[0]" error="errors[0]" /> -->
          <FormFieldError
            v-if="errors[0]"
            error="Authenticate to send a one time password to this email address to login"
          />
        </div>
      </ValidationProvider>
      <OTPField
        v-if="otpVerify"
        otp-type="email"
        :otp-verify="otpVerify"
        :clock-tick="clockTick"
        :error.sync="errorMsg"
        @validate="OTPValidate"
      ></OTPField>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import OTPField from "@/components/common/form/otp-field/OTPField.vue";
import { auth } from "@/api/factory.js";

export default {
  name: "EmailAuthentication",

  components: {
    ValidationObserver,
    ValidationProvider,
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    OTPField,
  },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    portalId: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    loginType: {
      type: String,
      default: "",
    },

    settings: {
      type: Object,
      default: () => {},
    },

    passwordTypes: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      email: "",
      otpVerify: false,
      isFocused: false,
      clockTick: false,
      sendOTP: "",
      isLoading: false,
      errorMsg: "",
    };
  },

  computed: {},

  methods: {
    OTPValidate(value) {
      if (value) {
        this.sendOTP = value.join("");
      } else {
        this.otpVerify = false;
        this.clockTick = false;
      }
      this.emailAuthentication();
    },

    reset() {
      this.errorMsg = "";
      this.clockTick = false;
      this.sendOTP = "";
      this.$emit("error", "");
    },

    async emailAuthentication() {
      this.$emit("error", "");
      this.errorMsg = "";
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }
      if (this.sendOTP === "") {
        this.clockTick = false;
        this.isLoading = true;
      }
      let input = {};
      if (
        this.sendOTP === "" &&
        this.loginType === "MASTER_LOGIN" &&
        (this.passwordTypes === "OTP" || this.passwordTypes === "SOCIAL_LOGIN")
      ) {
        input = {
          tenantId: this.tenantId,
          portalId: this.portalId,
          email: this.email,
          otp: this.sendOTP,
          formId: this.settings.authentication.formId,
          emailColumn: this.settings.authentication.usernameField,
          passwordColumn: this.settings.authentication.passwordField,
          nameColumn: this.settings.authentication.firstnameField,
        };
        const { error } = await auth.portalLogin(input);
        if (this.sendOTP === "") {
          this.isLoading = false;
        }
        if (error) {
          this.$emit("error", error);
          return;
        }
        if (this.sendOTP) {
          this.$alert.success(`OTP Verified`);
          this.$emit("logged");
        } else {
          if (this.passwordTypes === "SOCIAL_LOGIN") {
            this.$emit("showSocialLogin");
          } else {
            this.otpVerify = true;
            this.clockTick = true;
            this.$alert.success(`OTP sent to registered email`);
          }
        }
      } else {
        input = {
          tenantId: this.tenantId,
          portalId: this.portalId,
          email: this.email,
          otp: this.sendOTP,
        };
        const { error } = await auth.authentication(input);
        if (this.sendOTP === "") {
          this.isLoading = false;
        }
        if (error) {
          this.errorMsg = error;
          this.$alert.error(error);
          return;
        }
        if (this.sendOTP) {
          this.$alert.success(`OTP Verified`);
          this.$emit("logged");
        } else {
          this.otpVerify = true;
          this.clockTick = true;
          this.$alert.success(`OTP sent to registered email`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#email-authentication-field {
  #field-wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  input {
    min-height: 46px;
  }

  .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
  }

  .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
  }

  .input-group-btn {
    display: table-cell;
    #button {
      min-height: 48px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
</style>
