<template>
  <div
    :class="['form-field', { 'is-selected': isSelected }]"
    @click.stop="selectField"
  >
    <!-- actions -->

    <div v-if="isSelected" class="actions">
      <BaseActionButton
        v-for="action in actions"
        :key="action.id"
        v-tooltip.top="action.label"
        is-flat
        no-border
        :icon="action.icon"
        :color="action.value === 'delete' ? 'red' : 'gray'"
        :inherit-color="action.value !== 'delete'"
        :class="{ action: action.value !== 'delete' }"
        @click="handleActionClick(action.value)"
      />
    </div>

    <!-- ... -->

    <!-- label -->

    <FormFieldLabel
      v-if="_field === 'WorkspaceField'"
      :label="field.label"
      is-mandatory
    />
    <FormFieldLabel
      v-else-if="_field === 'FoldersField'"
      :label="field.label"
      is-mandatory
    />
    <!-- divider -->

    <div v-else-if="_field === 'PriorityField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'WorkflowsField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'PortalsField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'TimeField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'DateField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'LifeCycleStageField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'DatetimeField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'TaskOwnerField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'CreatedByField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'OverDueField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'EndtDateField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'StartDateField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'TaskWorkspaceField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'TaskStatusField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'TaskassignedByField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <div v-else-if="_field === 'TaskNameField'">
      <FormFieldLabel :label="field.label" is-mandatory />
    </div>
    <!-- ... -->

    <!-- other fields -->

    <component
      :is="_field"
      v-else
      v-model="model"
      is-readonly
      :label="field.label || 'no label'"
      :is-mandatory="field.settings.validation.fieldRule === 'REQUIRED'"
      :placeholder="field.settings.general.placeholder"
      :tooltip="field.settings.general.tooltip"
      :options="customOptions"
      :options-per-line="field.settings.specific.optionsPerLine"
      :content="field.settings.specific.textContent"
      :fib-fields="field.settings.specific.fibFields"
      :table-columns="field.settings.specific.tableColumns"
      :table-rows-type="field.settings.specific.tableRowsType"
      :table-fixed-row-count="field.settings.specific.tableFixedRowCount"
      :qr-value="field.settings.specific.qrValue"
      :table-fixed-row-labels="field.settings.specific.tableFixedRowLabels"
      :matrix-columns="field.settings.specific.matrixColumns"
      :matrix-rows="field.settings.specific.matrixRows"
      :matrix-type="field.settings.specific.matrixType"
      :matrix-type-settings="field.settings.specific.matrixTypeSettings"
      :rating-icon="field.settings.specific.ratingIcon"
      :rating-icon-count="field.settings.specific.ratingIconCount"
      :allow-half-rating="field.settings.specific.allowHalfRating"
      :tabs="field.settings.specific.tabList"
      :secondary-panels="secondaryPanels"
      :popup-trigger-type="field.settings.specific.popupTriggerType"
      :nested-list="field.settings.specific.nestedList"
      :nested-list-type-settings="
        field.settings.specific.nestedListTypeSettings
      "
      :nested-list-items-per-line="
        field.settings.specific.nestedListItemsPerLine
      "
      :login-type="field.settings.specific.loginType"
      :additional-login-types="field.settings.specific.additionalLoginTypes"
    />

    <!-- ... -->
  </div>
</template>

<script>
import ShortTextField from "@/components/common/form/text-field/TextField.vue";
import LongTextField from "@/components/common/form/text-area-field/TextAreaField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";
import CalculatedField from "@/components/common/form/calculated-field/CalculatedField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import SingleSelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
import ChipsField from "@/components/common/form/chips-field/ChipsField.vue";
import FileUploadField from "@/components/common/form/file-upload-field/FileUploadField.vue";
import ParagraphField from "@/components/common/form/paragraph-field/ParagraphField.vue";
import RatingField from "@/components/common/form/rating-field/RatingField.vue";
import ImageField from "@/components/common/form/image-field/ImageField.vue";
import FillInTheBlanksField from "@/components/common/form/fill-in-the-blanks-field/FillInTheBlanksField.vue";
import SignatureField from "@/components/common/form/signature-field/SignatureField.vue";
import TableField from "@/components/common/form/table-field/TableField.vue";
import MatrixField from "@/components/common/form/matrix-field/MatrixField.vue";
import DividerField from "@/components/common/form/divider-field/DividerField.vue";
import TabsField from "@/components/common/form/tabs-field/TabsField.vue";
import PopupField from "@/components/common/form/popup-field/PopupField.vue";
import NestedListField from "@/components/common/form/nested-list-field/nested-list-field/NestedListField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import EmailAuthenticationField from "@/components/common/form/email-authentication-field/EmailAuthentication.vue";
import MobileAuthenticationField from "@/components/common/form/mobile-authentication-field/MobileAuthentication.vue";
import LoginAuthenticationField from "@/components/common/form/login-authentication-field/LoginAuthenticationField.vue";
import { isEmpty, upperFirst, camelCase } from "lodash-es";
import CurrencyField from "@/components/common/form/currency-field/CurrencyField.vue";
import CurrencyAmountField from "@/components/common/form/currency-field/CurrencyAmountField.vue";

export default {
  name: "Field",

  components: {
    ShortTextField,
    LongTextField,
    NumberField,
    CounterField,
    CalculatedField,
    DateField,
    TimeField,
    DateTimeField,
    SingleSelectField,
    MultiSelectField,
    SingleChoiceField,
    MultipleChoiceField,
    ChipsField,
    FileUploadField,
    ParagraphField,
    DividerField,
    ImageField,
    FillInTheBlanksField,
    SignatureField,
    TableField,
    MatrixField,
    RatingField,
    FormFieldLabel,
    TabsField,
    PopupField,
    NestedListField,
    EmailAuthenticationField,
    MobileAuthenticationField,
    LoginAuthenticationField,
    CurrencyField,
    CurrencyAmountField,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },
    views: {
      type: Array,
      default: () => [],
    },
    secondaryPanels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      model: {},
    };
  },

  computed: {
    actions() {
      const actions = [
        // {
        //   id: this.$nano.id(),
        //   icon: "eva-copy-outline",
        //   label: "Duplicate",
        //   value: "duplicate",
        // },
        {
          id: this.$nano.id(),
          icon: "mdi-tune",
          label: "Settings",
          value: "settings",
        },
        {
          id: this.$nano.id(),
          icon: "eva-close",
          label: "Delete",
          value: "delete",
        },
      ];

      if (
        ["PARAGRAPH", "FILL_IN_THE_BLANKS", "NESTED_LIST", "TABLE"].includes(
          this.field.type
        )
      ) {
        actions.unshift({
          id: this.$nano.id(),
          icon: "eva-edit-outline",
          label: "Edit",
          value: "edit",
        });
      }

      return actions;
    },

    _field() {
      return `${upperFirst(camelCase(this.field.type))}Field`;
    },

    customOptions() {
      const customOptions = this.field.settings.specific.customOptions;

      if (isEmpty(customOptions)) {
        return this.options;
      }

      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";

      return this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option,
          value: option,
        }));
    },
  },

  created() {
    switch (this.field.type) {
      case "MULTI_SELECT":
      case "MULTIPLE_CHOICE":
      case "FILE_UPLOAD":
      case "IMAGE_UPLOAD":
      case "TABLE":
      case "MATRIX":
      case "CHIPS":
      case "NESTED_LIST":
        this.model = [];
        break;
      case "COUNTER":
      case "CALCULATED":
      case "RATING":
        this.model = 0;
        break;
      case "FILL_IN_THE_BLANKS":
      case "POPUP":
      case "TABS":
        this.model = {};
        break;
      case "CURRENCY_AMOUNT":
        this.model = {};
        break;
      default:
        this.model = "";
        break;
    }
    this.selectField();
    this.deselectField();
    this.handleActionClick();
  },
  methods: {
    selectField() {
      this.$emit("select");
    },

    deselectField() {
      this.$emit("select", {});
    },

    handleActionClick(action) {
      this.$emit(action);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-field {
  position: relative;
  border: 1px dashed var(--divider-color);
  padding: 12px 8px 8px;
  border-radius: 4px;

  &.is-selected {
    border: 1px dashed var(--secondary);
  }

  .actions {
    position: absolute;
    top: 3px;
    right: 3px;
    display: flex;
    align-items: center;

    .action {
      color: var(--icon-color-inverted);
    }
  }
}
</style>
