<template>
  <div id="folder-fields">
    <template v-if="fieldsType === 'STATIC'">
      <template v-if="checkTenant">
        <Draggable v-model="fields">
          <div
            v-for="(field, index) in fields"
            :key="field._id"
            class="field row items-center"
          >
            <BaseIcon
              name="drag_indicator"
              size="16px"
              class="drag-indicator"
            />

            <div class="col row">
              <BaseIcon
                :name="
                  index === fields.length - 1
                    ? 'eva-file-text-outline'
                    : 'mdi-folder'
                "
                :color="index === fields.length - 1 ? 'secondary' : 'amber'"
              />
              <div class="q-ml-md">{{ field.name }}</div>
            </div>

            <div style="width: 140px">{{ field.dataType }}</div>

            <div style="width: 100px">
              {{ field.isMandatory ? "Yes" : "No" }}
            </div>

            <BaseActionButton
              v-tooltip:secondary.left="'edit field'"
              is-flat
              color="secondary"
              icon="eva-edit-outline"
              no-border
              @click="handleEdit(field._id)"
            />

            <BaseActionButton
              v-tooltip:red.right="'delete field'"
              is-flat
              color="red"
              icon="eva-close-outline"
              no-border
              @click="handleDelete(field._id)"
            />
          </div>
        </Draggable>
      </template>
      <template v-else>
        <div
          v-for="(field, index) in fields"
          :key="field._id"
          class="field row items-center drag-false"
        >
          <div class="col row">
            <BaseIcon
              :name="
                index === fields.length - 1
                  ? 'eva-file-text-outline'
                  : 'mdi-folder'
              "
              :color="index === fields.length - 1 ? 'secondary' : 'amber'"
            />
            <div class="q-ml-md">{{ field.name }}</div>
          </div>

          <div style="width: 140px">{{ field.dataType }}</div>

          <div style="width: 100px">
            {{ field.isMandatory ? "Yes" : "No" }}
          </div>

          <BaseActionButton
            v-tooltip:secondary.left="'edit field'"
            is-flat
            color="secondary"
            icon="eva-edit-outline"
            no-border
            @click="handleEdit(field._id)"
          />

          <BaseActionButton
            v-tooltip:red.right="'delete field'"
            is-flat
            color="red"
            icon="eva-close-outline"
            no-border
            @click="handleDelete(field._id)"
          /></div
      ></template>
    </template>
    <template v-else>
      <div
        v-for="(field, index) in fields"
        :key="field._id"
        class="field row items-center dynamic"
      >
        <BaseIcon name="drag_indicator" size="16px" class="drag-indicator" />

        <div class="col row">
          <BaseIcon
            :name="
              index === fields.length - 1
                ? 'eva-file-text-outline'
                : 'mdi-folder'
            "
            :color="index === fields.length - 1 ? 'secondary' : 'amber'"
          />
          <div class="q-ml-md">{{ field.name }}</div>
        </div>

        <div style="width: 120px">{{ field.dataType }}</div>

        <div style="width: 120px">{{ field.isMandatory ? "Yes" : "No" }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import Draggable from "@/components/common/Draggable.vue";
import { cloneDeep, isEqual } from "lodash-es";

export default {
  name: "FolderFields",

  components: { Draggable },

  props: {
    folderFields: {
      type: Array,
      required: true,
    },

    fieldsType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fields: [],
    };
  },

  computed: {
    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://trial.ezofis.com" ||
        origin === "https://app.ezofis.com" ||
        origin === "http://localhost:8080"
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {
    folderFields: {
      immediate: true,
      deep: true,
      handler() {
        if (isEqual(this.folderFields, this.fields)) {
          return;
        }

        this.fields = cloneDeep(this.folderFields);
      },
    },

    fields: {
      deep: true,
      handler() {
        if (isEqual(this.folderFields, this.fields)) {
          return;
        }

        this.$emit("reorder", this.fields);
      },
    },
  },

  methods: {
    handleEdit(fieldId) {
      this.$emit("edit", fieldId);
    },

    handleDelete(fieldId) {
      this.$emit("delete", fieldId);
    },
  },
};
</script>

<style lang="scss" scoped>
#folder-fields {
  .field:not(.drag-false) {
    margin-bottom: 8px;
    position: relative;
    height: 36px;
    cursor: move;
  }

  .field.dynamic {
    cursor: default !important;
  }

  .drag-indicator {
    position: absolute;
    left: -28px;
  }
}
</style>
