<template>
  <div id="lookup-settings">
    <div class="q-pa-md">
      <!-- form fields validate -->

      <template v-if="fieldType === 'SINGLE_SELECT'">
        <!-- Connection -->

        <ValidationProvider v-slot="{ errors }" name="Connection">
          <SelectField
            :value="connection"
            label="Connection"
            is-mandatory
            :options="connections"
            :error="errors[0]"
            class="q-mb-lg"
            @input="updateConnection"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- Connection name-->

        <ValidationProvider
          v-if="connection"
          v-slot="{ errors }"
          name="Connection name"
          :rules="{ required: connection ? true : false }"
        >
          <SelectField
            :value="connectionId"
            label="Connection Name"
            is-mandatory
            :options="connectionNameList"
            :error="errors[0]"
            class="q-mb-lg"
            @input="updateConnectionId"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- Connection name-->

        <ValidationProvider
          v-if="connectionId"
          v-slot="{ errors }"
          name="Function name"
          :rules="{ required: connectionId ? true : false }"
        >
          <SelectField
            :value="hubName"
            label="Function Name"
            is-mandatory
            :options="husList"
            :error="errors[0]"
            class="q-mb-lg"
            @input="updateHubName"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- Connection name-->

        <ValidationProvider
          v-if="hubName"
          v-slot="{ errors }"
          name="Column name"
          :rules="{ required: connectionId ? true : false }"
        >
          <SelectField
            :value="columnName"
            label="Column Name"
            is-mandatory
            :options="columnsList"
            :error="errors[0]"
            class="q-mb-lg"
            @input="updateColumnName"
          />
        </ValidationProvider>

        <!-- ... -->
      </template>
      <!-- parent field -->
      <SelectField
        :value="parentField"
        label="Parent Field (Filter based on parent field value selection)"
        is-mandatory
        :options="parentPanelFields()"
        class="q-mb-lg"
        @input="updateParentField"
      />
      <!-- ... -->

      <template v-if="hasTextField && parentField">
        <!-- Connection name-->

        <TextField
          :value="functionName"
          label="Function Name"
          is-readonly
          class="q-mb-lg"
        />

        <ValidationProvider
          v-slot="{ errors }"
          name="Column name"
          :rules="{ required: connectionId ? true : false }"
        >
          <SelectField
            :value="columnName"
            label="Column Name"
            is-mandatory
            :options="columnsList"
            :error="errors[0]"
            class="q-mb-lg"
            @input="updateColumnName"
          />
        </ValidationProvider>
      </template>

      <!-- ... -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { form, connector } from "@/api/factory.js";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
// import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
// import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
// import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
// import ConditionBuilder from "@/components/common/condition-builder/ConditionBuilder.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "LookupSettings",

  components: {
    ValidationProvider,
    // MultiSelectField,
    // SingleChoiceField,
    SelectField,
    TextField,
    // ConditionBuilder,
    // MultipleChoiceField,
  },

  props: {
    connection: {
      type: String,
      required: true,
    },

    connectionId: {
      type: [Number, String],
      required: true,
    },

    hubName: {
      type: [Number, String],
      required: true,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    fieldId: {
      type: String,
      default: "",
    },

    columnName: {
      type: String,
      default: "",
    },

    parentField: {
      type: String,
      default: "",
    },

    settingsFor: {
      type: String,
      default: "",
    },

    fieldType: {
      type: String,
      default: "",
    },

    tableFieldId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      connections: [],
      sqlConnections: [],
      apiConnections: [],
      oracleConnections: [],
      husList: [],
      connectorHub: [],
      inData: [],
      outData: [],
      field: [],
      name: "",
      masterForms: [],
      masterFormColumns: [],
      columnsList: [],
      functionName: "",
    };
  },

  computed: {
    connectionNameList() {
      if (this.connection === "sql") {
        return this.sqlConnections;
      } else if (this.connection === "api") {
        return this.apiConnections;
      } else if (this.connection === "oracle") {
        return this.oracleConnections;
      }
      return [];
    },

    formFields() {
      if (!this.panels) return [];
      let fieldList = [];
      let fields = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }

      fieldList.forEach((field) => {
        // console.log(field);

        if (field.id !== this.fieldId) {
          if (
            this.formControlValidate.optionsType === "MASTER" &&
            (field.type === "SHORT_TEXT" ||
              field.type === "LONG_TEXT" ||
              field.type === "LABEL" ||
              field.type === "PARAGRAPH")
          ) {
            fields.push({
              id: this.$nano.id(),
              label: field.label,
              value: field.id,
            });
          }
          if (
            (this.formControlValidate.optionsType === "EXISTING" ||
              this.formControlValidate.optionsType === "CONDITION") &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            field.type !== "FILE_UPLOAD" &&
            field.type !== "CHIPS" &&
            field.type !== "TABLE" &&
            field.type !== "DIVIDER" &&
            field.type !== "TEXT_BUILDER" &&
            field.type !== "FILL_IN_THE_BLANKS" &&
            field.type !== "MATRIX" &&
            field.type !== "SIGNATURE"
          ) {
            fields.push({
              id: this.$nano.id(),
              label: field.label,
              value: field.id,
            });
          }
        }
      });

      return fields;
    },

    hasTextField() {
      if (
        this.fieldType === "SHORT_TEXT" ||
        this.fieldType === "LONG_TEXT" ||
        this.fieldType === "NUMBER" ||
        this.fieldType === "DATE" ||
        this.fieldType === "TIME"
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {
    connectionId: {
      immediate: true,
      handler() {
        if (this.connectionId) {
          this.getLookUp();
        }
      },
    },

    hubName: {
      immediate: true,
      handler() {
        console.log(this.hubName, "hhub");
        if (this.hubName) {
          this.getColumnList();
        }
      },
    },
  },

  created() {
    this.getMasterForms();
    this.getConnection();
  },

  methods: {
    async getConnection() {
      const { error, payload } = await connector.getConnection({
        filterBy: [],
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        // console.log(payload);

        let sql = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "SQL"
        );
        if (sql.length) {
          this.connections.push({
            id: this.$nano.id(),
            label: "SQL",
            value: "sql",
          });
          sql.forEach((item) => {
            this.sqlConnections.push({
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            });
          });
        }

        let api = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "oauth2"
        );
        if (api.length) {
          this.connections.push({
            id: this.$nano.id(),
            label: "API",
            value: "api",
          });
          api.forEach((item) => {
            this.apiConnections.push({
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            });
          });
        }

        let oracle = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "ORACLE"
        );
        if (oracle.length) {
          this.connections.push({
            id: this.$nano.id(),
            label: "Oracle",
            value: "oracle",
          });

          oracle.forEach((item) => {
            this.oracleConnections.push({
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            });
          });
        }
      }
    },

    async getLookUp() {
      this.husList = [];

      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getLookUp({
        filterBy: [
          {
            filters: [
              // {
              //   criteria: "createdBy",
              //   criteriaArray: ["createdBy"],
              //   condition: "IS_EQUALS_TO",
              //   value: this.$store.state.session.id,
              //   arrayValue: [],
              //   dataType: "SHORT_TEXT",
              // },
              {
                criteria: "connectorId",
                criteriaArray: ["connectorId"],
                condition: "IS_EQUALS_TO",
                value: this.connectionId,
                arrayValue: [],
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      let result = [];
      payload.forEach((item) => {
        result.push(item.connectorHub);
      });

      result.forEach((item) => {
        this.husList.push({
          id: this.$nano.id(),
          label: item.name,
          value: item.id,
        });
      });
      this.connectorHub = result;
      console.log(this.connectorHub, "this.connectorHub");
      if (
        this.connectorHub.length &&
        this.hubName &&
        this.columnName &&
        this.fieldType === "SINGLE_SELECT"
      ) {
        this.getColumnList();
      } else if (
        this.connectorHub.length &&
        this.hubName &&
        this.fieldType !== "SINGLE_SELECT"
      ) {
        this.functionName = this.husList.find(
          (hub) => hub.value === this.hubName
        )?.label;
        this.getColumnList();
      }
      // this.$emit("connectorHub", this.connectorHub);
    },

    async getMasterForms() {
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "MASTER",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching master form list");
        return;
      }

      this.masterForms = [];
      const { data } = payload;
      if (data.length) {
        this.masterForms = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: form.id,
        }));
      }
    },

    async getFormFields(formId) {
      this.masterFormColumns = [];
      const { error, payload } = await form.getForm(formId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let form = JSON.parse(payload.formJson);
        let masterfields = [];
        const panels = [...form.panels, ...form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          masterfields.push(...panel.fields);
        }

        masterfields.forEach((field) => {
          if (field.type !== "DIVIDER") {
            this.masterFormColumns.push({
              id: field.id,
              label: field.label,
              value: field.id,
            });
          }
        });
      }
    },

    updateConnection(connection) {
      this.$emit("update:connection", connection);
      this.$emit("update:connectionId", 0);
    },

    updateConnectionId(id) {
      this.$emit("update:connectionId", id);
    },

    updateHubName(id) {
      this.$emit("update:hubName", id);
    },

    updateColumnName(name) {
      this.$emit("update:columnName", name);
    },

    getColumnList() {
      if (this.hubName) {
        let data = this.connectorHub.find((name) => name.id === this.hubName);
        if (data) {
          this.columnsList = [];

          let outData = data.outDataPoint ? JSON.parse(data.outDataPoint) : [];
          outData.forEach((item) => {
            this.columnsList.push({
              id: this.$nano.id(),
              label: item,
              value: item,
            });
          });
        }
      }
    },

    updateParentField(name) {
      this.$emit("update:parentField", name);
      if (this.hasTextField) {
        let fieldList = [];
        for (const panel of this.panels) {
          fieldList.push(...panel.fields);
        }
        let hubDetails = "";
        if (this.settingsFor !== "TABLE") {
          hubDetails = fieldList.find((field) => field.id === name);
        } else {
          let table = fieldList.find((field) => field.id === this.tableFieldId);
          if (table) {
            let columns = table.settings.specific.tableColumns;
            hubDetails = columns.find((field) => field.id === name);
          }
        }
        if (hubDetails) {
          if (hubDetails.settings.lookupSettings) {
            let connections = hubDetails.settings.lookupSettings;
            this.updateConnection(connections.connection);
            this.updateConnectionId(connections.connectionId);
            this.updateHubName(connections.hubName);
          }
        }
      }
      if (!name) {
        this.updateConnection("");
        this.updateConnectionId("");
        this.updateHubName("");
      }
    },

    parentPanelFields() {
      if (!this.panels) return [];
      let fieldList = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }

      let parentFields = [];
      fieldList.forEach((field) => {
        // console.log(field);
        if (this.settingsFor === "NORMAL") {
          if (field.id !== this.fieldId) {
            if (
              field.type === "SINGLE_SELECT" ||
              field.type === "MULTI_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE"
            ) {
              // console.log(field.settings.lookupSettings, field.label);
              if (
                field.settings.lookupSettings &&
                field.settings.lookupSettings.connectionId
              ) {
                // console.log(field.settings.lookupSettings.hubName);
                if (field.settings.lookupSettings.hubName === this.hubName) {
                  parentFields.push({
                    id: this.$nano.id(),
                    label: field.label,
                    value: field.id,
                  });
                } else if (this.hasTextField) {
                  if (
                    field.settings.lookupSettings &&
                    field.settings.lookupSettings.connectionId
                  ) {
                    parentFields.push({
                      id: this.$nano.id(),
                      label: field.label,
                      value: field.id,
                    });
                  }
                }
              }
            }
          }
        } else if (this.settingsFor === "TABLE") {
          if (field.id !== this.fieldId) {
            if (
              field.type === "SINGLE_SELECT" ||
              field.type === "MULTI_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE"
            ) {
              // console.log(field.settings.lookupSettings, field.label);
              if (
                field.settings.lookupSettings &&
                field.settings.lookupSettings.connectionId
              ) {
                // console.log(field.settings.lookupSettings.hubName);
                if (field.settings.lookupSettings.hubName === this.hubName) {
                  parentFields.push({
                    id: this.$nano.id(),
                    label: field.label,
                    value: field.id,
                  });
                }
              }
            }
          }
          let columns = field.settings.specific.tableColumns;
          columns.forEach((column) => {
            if (column.id !== this.fieldId) {
              if (
                column.type === "SINGLE_SELECT" ||
                column.type === "MULTI_SELECT" ||
                column.type === "SINGLE_CHOICE" ||
                column.type === "MULTIPLE_CHOICE"
              ) {
                // console.log(field.settings.lookupSettings, field.label);
                if (
                  column.settings.lookupSettings &&
                  column.settings.lookupSettings.connectionId
                ) {
                  // console.log(field.settings.lookupSettings.hubName);
                  if (column.settings.lookupSettings.hubName === this.hubName) {
                    parentFields.push({
                      id: this.$nano.id(),
                      label: column.label,
                      value: column.id,
                    });
                  } else if (this.hasTextField) {
                    if (
                      column.settings.lookupSettings &&
                      column.settings.lookupSettings.connectionId
                    ) {
                      parentFields.push({
                        id: this.$nano.id(),
                        label: column.label,
                        value: column.id,
                      });
                    }
                  }
                }
              }
            }
          });
        }
      });
      // console.log(parentFields);
      return parentFields;
    },
  },
};
</script>
