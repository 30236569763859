<template>
  <!-- modal -->

  <Modal
    :value="value"
    width="33vw"
    height="80vh"
    has-footer
    @input="closeModal"
  >
    <!-- title -->

    <template #title>SCAN QR</template>

    <!-- ... -->

    <template #default>
      <div v-if="error" class="text-caption text-red q-mb-lg">
        {{ error }}
      </div>

      <div class="row justify-center qr-code">
        <StreamBarcodeReader @decode="onDecode"></StreamBarcodeReader>
        <!-- <qrcode-stream @decode="onDecode" @init="onInit" /> -->
      </div>

      <TextAreaField
        v-model="barcodeValue"
        label="Reader Value"
        placeholder="Enter Value"
        :auto-focus="focus"
        class="q-mt-md"
      />
      <!-- :is-readonly="barcodeValue ? true : false" -->
      <!-- :has-disabled="barcodeValue ? true : false" -->
    </template>

    <template #footer>
      <BaseButton label="save" @click="saveQR" />
    </template>
  </Modal>

  <!-- ... -->
</template>
<script>
import Modal from "@/components/common/popup/Modal.vue";
// import { QrcodeStream } from "vue-qrcode-reader";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: "QRReader",

  components: {
    Modal,
    // QrcodeStream,
    TextAreaField,
    StreamBarcodeReader,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      qrData: "",
      error: "",
      barcodeValue: "",
      focus: true,
    };
  },

  watch: {
    error() {
      if (this.error) {
        this.focus = true;
      }
    },

    barcodeValue() {
      if (this.barcodeValue) {
        //this.focus = false;
      }
    },
  },

  methods: {
    reset() {
      this.qrData = "";
      this.error = "";
      this.barcodeValue = "";
      this.focus = true;
      this.$emit("input", "");
    },

    closeModal() {
      this.reset();
    },

    onDecode(data) {
      this.qrData = data;
      this.barcodeValue = data;
      //this.focus = false;
      if (data) {
        this.saveQR();
      }
    },

    saveQR() {
      this.$emit("input", this.barcodeValue);
      this.reset();
    },

    async onInit(promise) {
      console.log(promise, "error");
      try {
        await promise;
      } catch (error) {
        console.log(error, "error");
        if (error.name === "NotAllowedError") {
          this.error = "Error: User denied camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.errerroror = "ERROR: No camera found";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: Secure connection required (HTTPS)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: Camera is already in use";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: No camera found";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Browser is not supported";
        } else {
          this.error = "";
        }
      }
    },
  },
};
</script>
<style scoped>
.qr-code {
  /* height: 250px; */
}
</style>
<style lang="scss">
#field-wrapper.is-readonly {
  border: 1px solid var(--border-color) !important;
}
</style>
