<template>
  <div id="view-toggle">
    <template v-for="view in _views">
      <BaseActionButton
        :key="view.id"
        v-tooltip.top="view.label"
        is-flat
        no-border
        :icon="activeView === view.value ? view.activeIcon : view.icon"
        :color="activeView === view.value ? 'secondary' : 'gray'"
        @click="updateActiveView(view.value)"
      />
    </template>
    <DefaultView
      :value="showDefaultView"
      :view="defaultView"
      @handleYes="handleYes"
      @handleNo="handleNo"
      @hideSheet="showDefaultView = false"
    />
  </div>
</template>

<script>
import DefaultView from "./DefaultView.vue";
import { profile } from "@/api/factory.js";
import { clone } from "lodash-es";
// import st/index.jsore
import store from "@/store/index.js";

export default {
  name: "ViewToggle",

  components: {
    DefaultView,
  },

  props: {
    activeView: {
      type: String,
      default: "GRID",
      validator(value) {
        return ["GRID", "LIST", "THUMBNAIL"].includes(value);
      },
    },

    thumbnailView: {
      type: Boolean,
      default: false,
    },

    gridView: {
      type: Boolean,
      default: true,
    },

    module: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      views: [
        {
          id: this.$nano.id(),
          label: "Grid View",
          value: "GRID",
          icon: "mdi-view-grid-outline",
          activeIcon: "mdi-view-grid",
        },
        {
          id: this.$nano.id(),
          label: "List View",
          value: "LIST",
          icon: "mdi-format-list-checkbox",
          activeIcon: "mdi-view-list",
        },
        {
          id: this.$nano.id(),
          label: "Thumbnail View",
          value: "THUMBNAIL",
          icon: "eva-image-outline",
          activeIcon: "eva-image",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Kanban View",
        //   value: "KANBAN",
        //   icon: "mdi-grid",
        //   activeIcon: "mdi-grid",
        // },
      ],
      showDefaultView: false,
      defaultView: "",
      userId: this.$store.state.session.id,
      viewAlreadyAdded: true,
      options: {},
    };
  },

  computed: {
    // _views() {
    //   var removeValFrom = [];
    //   var valuesArr = [];

    //   if (this.thumbnailView) {
    //     removeValFrom = [3];
    //     valuesArr = this.views.filter(function (value, index) {
    //       return removeValFrom.indexOf(index) == -1;
    //     });
    //     return valuesArr;
    //   } else if (!this.gridView && !this.thumbnailView) {
    //     removeValFrom = [0, 2];
    //     valuesArr = this.views.filter(function (value, index) {
    //       return removeValFrom.indexOf(index) == -1;
    //     });
    //     return valuesArr;
    //   }

    //   // return this.views.slice(0, 2);
    //   removeValFrom = [2, 3];
    //   valuesArr = this.views.filter(function (value, index) {
    //     return removeValFrom.indexOf(index) == -1;
    //   });
    //   // console.log(valuesArr);
    //   return valuesArr;
    // },

    _views() {
      if (this.thumbnailView) {
        return this.views;
      } else if (!this.gridView) {
        return this.views.slice(1, 2);
      }

      return this.views.slice(0, 2);
    },
  },

  created() {
    this.getDefaultView();
  },

  methods: {
    updateActiveView(activeView) {
      // console.log(activeView);
      this.defaultView = clone(activeView);
      this.showDefaultView = true;

      // this.$emit("update:activeView", activeView);
    },

    handleYes() {
      // this.$emit("update:activeView", this.defaultView);
      this.showDefaultView = false;
      if (this.viewAlreadyAdded) {
        this.updateDefaultView();
      } else {
        this.addDefaultView();
      }
      this.$emit("update:activeView", this.defaultView);
    },

    handleNo() {
      this.$emit("update:activeView", this.defaultView);
      this.showDefaultView = false;
    },

    async addDefaultView() {
      this.$store.commit("showLoadingBar");

      let menu = this.$route.meta.menu;
      let input = {};
      let query = this.$route.query.repositoryId;
      let subMenu1 = "";
      if (query) {
        subMenu1 = "files";
      }
      if (subMenu1) {
        input = {
          preference: {
            [this.$route.meta.subMenu]: [
              {
                menu: this.$route.meta.subMenu,
                subMenu: this.$route.meta.breadcrumb,
                view: this.defaultView,
              },
            ],
          },
        };
      } else {
        input = {
          preference: {
            [menu]: [
              {
                menu: this.$route.meta.menu,
                subMenu: this.$route.meta.breadcrumb,
                view: this.defaultView,
              },
            ],
          },
        };
      }
      const { error, payload } = await profile.addProfilePreference(input);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error("Error fetching changing view");
        return;
      }

      store.commit("setDefaultView", input.preference);
      store.commit("setPreferenceId", payload);
      this.viewAlreadyAdded = true;
      this.options = input;

      this.$emit("update:activeView", this.defaultView);
      //this.$alert.success("Default view added");
    },

    async updateDefaultView() {
      this.$store.commit("showLoadingBar");

      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      let query = this.$route.query.repositoryId;
      let subMenu1 = "";
      if (query) {
        subMenu1 = "files";
      }
      let input = {};
      if (!subMenu1) {
        if (this.options[menu]) {
          if (this.options[menu].length) {
            let index = this.options[menu].findIndex((item) => {
              return item.subMenu === subMenu;
            });
            // console.log(index);
            // return;
            if (index >= 0) {
              this.options[menu][index].view = this.defaultView;
            } else {
              this.options[menu].push({
                menu: this.$route.meta.menu,
                subMenu: this.$route.meta.breadcrumb,
                view: this.defaultView,
              });
            }
          } else {
            this.options[menu] = [];
            this.options[menu].push({
              menu: this.$route.meta.menu,
              subMenu: this.$route.meta.breadcrumb,
              view: this.defaultView,
            });
          }
        } else {
          // console.log("newmenu");
          this.options[menu] = [];
          this.options[menu].push({
            menu: this.$route.meta.menu,
            subMenu: this.$route.meta.breadcrumb,
            view: this.defaultView,
          });
        }
      } else {
        if (this.options[subMenu1]) {
          if (this.options[subMenu1].length) {
            let index = this.options[subMenu1].findIndex((item) => {
              return item.subMenu === subMenu;
            });
            console.log(index);
            // return;
            if (index >= 0) {
              this.options[subMenu1][index].view = this.defaultView;
              // this.options[subMenu1][index].menu = subMenu1;
            } else {
              this.options[subMenu1].push({
                menu: this.$route.meta.subMenu,
                subMenu: this.$route.meta.breadcrumb,
                view: this.defaultView,
              });
            }
          } else {
            this.options[subMenu1] = [];
            this.options[subMenu1].push({
              menu: this.$route.meta.subMenu,
              subMenu: this.$route.meta.breadcrumb,
              view: this.defaultView,
            });
          }
        } else {
          this.options[subMenu1] = [];
          this.options[subMenu1].push({
            menu: this.$route.meta.subMenu,
            subMenu: this.$route.meta.breadcrumb,
            view: this.defaultView,
          });
        }
      }
      let options = JSON.stringify(this.options);
      input = { preference: JSON.parse(options) };
      const { error } = await profile.updateProfilePreference(
        this.$store.state.preferenceId,
        input
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error("Error fetching changing view");
        return;
      }

      store.commit("setDefaultView", input.preference);

      this.$emit("update:activeView", this.defaultView);
      //this.$alert.success("Default view added");
    },

    async getDefaultView() {
      const menu = this.$route.meta.menu;
      const subMenu = this.$route.meta.breadcrumb;
      if (menu !== "workflows" && subMenu !== "inbox") {
        const { error, payload } = await profile.getProfilePreference(
          this.userId
        );

        if (error) {
          this.$alert.error("Error fetching get default view");
          return;
        }
        if (!payload) {
          this.viewAlreadyAdded = false;
        } else {
          this.options = JSON.parse(payload.options);
          store.commit("setDefaultView", this.options);
          store.commit("setPreferenceId", payload.id);

          const savedView = this.options[menu]?.find(
            (item) => item.subMenu === subMenu
          )?.view;
          if (savedView) {
            this.defaultView = savedView;
            this.$emit("update:activeView", savedView);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#view-toggle {
  display: flex;
  align-items: center;
}
</style>
