<template>
  <div id="process-sheet" class="q-my-md">
    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="
        selectedProcess.processId && attachmentList.length ? true : false
      "
      :upload="selectedNode === 'inbox'"
      no-padding
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          @click="getAttachments()"
        />
      </template>

      <template v-if="selectedNode === 'inbox'" #upload>
        <div class="hint" @click="$refs.attachments.click()">
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template
        v-if="selectedProcess.processId && attachmentList.length"
        #subActions
      >
        <CheckboxField
          v-model="selectAll"
          class="col-auto q-pt-sm q-pb-sm"
          @input="fileSelectAll"
        />
        Select All

        <BaseActionButton
          v-tooltip.top="'mail share'"
          :is-disabled="!selectedFileCount"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="documentShare"
        />

        <BaseActionButton
          v-tooltip.top="'merge documents (only PDF)'"
          :is-disabled="selectedFileCount > 1 && isSelectedPDF ? false : true"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          @click="documentMerge"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="selectedNode === 'inbox' ? 'calc(100vh - 200px)' : ''"
        >
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadWflowAttachments"
          />
          <template v-for="row in checkList">
            <template v-if="row.name">
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else :key="row.uid">
                <template v-for="file in attachmentFilterCheckList(row.name)">
                  <div :key="file.id" class="attachmentList">
                    <div class="title row">
                      <div v-if="selectedProcess.processId" class="col-auto">
                        <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                        <BaseIcon
                          :name="
                            file.checked
                              ? 'eva-checkmark-circle-2'
                              : 'eva-radio-button-off-outline'
                          "
                          color="secondary"
                          class="q-mr-sm"
                          @click="file.checked = !file.checked"
                        />
                      </div>
                      <div v-if="file.formJsonId" class="col-auto">
                        <BaseIcon
                          v-tooltip:green.top="getLabel(file.formJsonId)"
                          name="mdi-form-select"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        <FileIcon
                          :mime-type="fileType(file.name)"
                          class="mini-avatar q-mr-sm"
                        />
                      </div>
                      <div class="col ellipsis" @click="openFile(file)">
                        {{ file.name }}
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Metadata'"
                          icon="eva-file-text-outline"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="getFileData(file)"
                        />
                      </div>
                      <template
                        v-if="
                          selectedNode === 'inbox' &&
                          file.createdBy === $store.state.session.id
                        "
                      >
                        <div class="col-auto">
                          <BaseActionButton
                            v-tooltip.top="'delete'"
                            icon="eva-trash-2-outline"
                            color="red"
                            no-border
                            is-flat
                            size="20px"
                            @click.stop="attachmentDelete(file.id)"
                          />
                        </div>
                        <div class="col-auto">
                          <BaseActionButton
                            v-tooltip.top="'Re-Upload'"
                            icon="mdi-file-upload"
                            color="primary"
                            no-border
                            is-flat
                            size="20px"
                            @click.stop="
                              {
                                reuploadId = file.id;
                                reupload(file.name);
                              }
                            "
                          />
                        </div>
                      </template>
                    </div>
                    <div class="meta">
                      <!-- created at -->

                      <div>{{ $day.format(file.createdAt) }}</div>

                      <!-- ... -->

                      <!-- created by -->

                      <template v-if="file.createdBy">
                        <BaseIcon name="mdi-circle-small" />
                        <div>{{ file.createdByEmail }}</div>
                      </template>

                      <!-- ... -->

                      <!-- size -->

                      <template v-if="file.size">
                        <BaseIcon name="mdi-circle-small" />
                        <div>{{ fileSize(file.size) }}</div>
                      </template>

                      <!-- ... -->
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <template v-if="isCheckList(file.name)">
              <div :key="file.id" class="attachmentList">
                <div class="title row">
                  <div class="col-auto">
                    <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                    <BaseIcon
                      :name="
                        file.checked
                          ? 'eva-checkmark-circle-2'
                          : 'eva-radio-button-off-outline'
                      "
                      color="secondary"
                      class="q-mr-sm"
                      @click="file.checked = !file.checked"
                    />
                  </div>
                  <div v-if="file.formJsonId" class="col-auto">
                    <BaseIcon
                      v-tooltip:green.top="getLabel(file.formJsonId)"
                      name="mdi-form-select"
                      color="primary"
                      class="q-mr-sm"
                    />
                  </div>
                  <div class="col-auto">
                    <FileIcon
                      :mime-type="fileType(file.name)"
                      class="mini-avatar q-mr-sm"
                    />
                  </div>
                  <div class="col ellipsis" @click="openFile(file)">
                    {{ file.name }}
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Metadata'"
                      icon="eva-file-text-outline"
                      color="secondary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="getFileData(file)"
                    />
                  </div>
                  <div
                    v-if="
                      selectedNode === 'inbox' &&
                      file.createdBy === $store.state.session.id
                    "
                    class="col-auto"
                  >
                    <BaseActionButton
                      v-tooltip.top="'delete'"
                      icon="eva-trash-2-outline"
                      color="red"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="attachmentDelete(file.id)"
                    />
                  </div>
                </div>

                <div class="meta">
                  <!-- created at -->

                  <div>{{ $day.format(file.createdAt) }}</div>

                  <!-- ... -->

                  <!-- created by -->

                  <template v-if="file.createdBy">
                    <BaseIcon name="mdi-circle-small" />
                    <div>{{ file.createdByEmail }}</div>
                  </template>

                  <!-- ... -->

                  <!-- size -->

                  <template v-if="file.size">
                    <BaseIcon name="mdi-circle-small" />
                    <div>{{ fileSize(file.size) }}</div>
                  </template>

                  <!-- ... -->
                </div>
              </div>
            </template>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>

    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      :custom-height="selectedNode === 'inbox' ? '30' : '0'"
      no-padding
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="
            selectedNode === 'inbox'
              ? checkCommentsOption()
                ? 'calc(100vh - 190px)'
                : 'calc(100vh - 150px)'
              : ''
          "
        >
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastActionDuration(row.createdAt)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div v-if="selectedNode === 'inbox'" class="col q-pa-sm">
        <div class="row">
          <template v-if="checkTenant()">
            <SingleChoiceField
              v-model="commentAction"
              :options="commentActions"
              :options-per-line="2"
              class="col q-mb-sm actions"
            />
          </template>
          <template v-if="isTenantGOTOComments()">
            <SingleChoiceField
              v-model="jiraCommentAction"
              :options="jiraCommentActions"
              :options-per-line="2"
              class="col q-mb-sm actions"
            />
          </template>
        </div>
        <div class="row">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm commentAction"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm commentAction"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <template v-if="checkTenant()">
          <SingleChoiceField
            v-if="checkTenant()"
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="actions q-mr-sm noborder"
          />
        </template>
        <template v-if="isTenantGOTOComments()">
          <SingleChoiceField
            v-model="jiraCommentAction"
            :options="jiraCommentActions"
            :options-per-line="2"
            class="col q-mb-sm actions"
          />
        </template>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="workflow.repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>

    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>

    <Sheet
      v-model="processSheet"
      width="100vw"
      :has-footer="!isCustomizedWorkflow || selectedNode !== 'inbox'"
      no-padding
      @input="processSheet = false"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <!-- ... -->

      <!-- actions -->

      <template #actions>
        <BaseActionButton
          v-if="itemModel.itemId"
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="processDocumentAction(action)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
        <!-- <BaseActionButton
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          class="q-mr-sm"
          @click="toggleWidth"
        /> -->
      </template>

      <!-- ... -->

      <!-- content -->

      <div id="content-wrapper" class="row">
        <template v-if="isCustomizedWorkflow && selectedNode === 'inbox'">
          <CustomForm
            :panels="panels"
            :secondary-panels="secondaryPanels"
            :save-action.sync="saveAction"
            :workflow-form-model.sync="workflowFormModel"
            :actions="actions"
            :request-prefix.sync="requestPrefix"
            :ml-prediction.sync="mlPrediction"
          />
        </template>
        <template v-else>
          <div v-if="formId" id="form-view" class="col">
            <!-- form -->
            <BaseScrollbar height="calc(100vh - 145px)">
              <RenderForm
                v-if="formId"
                :form-id="formId"
                :panels="panels"
                :secondary-panels="secondaryPanels"
                :form-settings="formSettings"
                :form-model="formModel"
                :save-action.sync="saveAction"
                :workflow-form-model.sync="workflowFormModel"
                :workflow-model.sync="workflowModel"
                :is-readonly="formEditAccess"
                :form-edit-controls="formEditControls"
                :form-visibility-access="formVisibilityAccess"
                :form-secure-controls="formSecureControls"
                :enable-controls="enableControls"
                :check-list.sync="checkList"
                :form-fields="formFields"
                :data-validation-values="dataValidationValues"
                :form-check-list.sync="formCheckList"
              />

              <div
                v-if="signList.length"
                id="approvalSignature"
                class="q-pa-md"
              >
                <div class="title q-mb-sm">Approval Signature</div>

                <BaseSeparator class="q-my-sm" />

                <div class="row">
                  <template v-for="sign in signList">
                    <div :key="sign.id" class="col-3 q-mb-sm">
                      <img class="imgCenter col" :src="signLink(sign.id)" />
                      <div class="text row">
                        <span class="col-12 text-bold">{{ sign.stage }}</span>
                        <template v-if="sign.review">
                          <span
                            v-if="sign.review.toLowerCase() === 'approve'"
                            class="col-12 text-bold text-positive"
                            >Approved
                          </span>
                          <span
                            v-else-if="sign.review.toLowerCase() === 'reject'"
                            class="col-12 text-bold text-red"
                            >Rejected
                          </span>
                          <span
                            v-else
                            class="col-12 text-bold text-secondary"
                            >{{ sign.review }}</span
                          >
                        </template>
                        <span class="col-12">{{ sign.createdByEmail }}</span>
                        <span class="col-12">{{
                          $day.format(sign.createdAt)
                        }}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <SignatureField
                v-if="userSignature"
                v-model="signImg"
                label="Signature"
                class="q-pa-md"
              />
            </BaseScrollbar>
          </div>

          <div v-if="itemModel.itemId" class="col">
            <div class="fileFrame">
              <div class="file-list">
                <iframe
                  id="file-frame"
                  ref="myProcessFiles"
                  :src="itemLink()"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="col-auto q-mr-sm leftBorder">
            <template v-for="tab in tabs">
              <template v-if="tab.value === 'TASK'">
                <BaseActionButton
                  v-if="addTaskEnabled"
                  :key="tab.id"
                  v-tooltip.left="tab.label"
                  is-flat
                  no-border
                  :icon="tab.icon"
                  color="primary"
                  class="tabButtons"
                  @click="showLinks(tab.value)"
                >
                  <span v-if="tab.count" class="badge">{{ tab.count }} </span>
                </BaseActionButton>
              </template>
              <template v-else>
                <BaseActionButton
                  v-if="
                    (tab.value !== 'VALIDATION' &&
                      tab.value !== 'PAYMENT_TABLE') ||
                    ((tab.value === 'VALIDATION' ||
                      tab.value === 'PAYMENT_TABLE') &&
                      isCustomizedWorkflow)
                  "
                  :key="tab.id"
                  v-tooltip.left="tab.label"
                  is-flat
                  no-border
                  :icon="tab.icon"
                  color="primary"
                  class="tabButtons"
                  @click="showLinks(tab.value)"
                >
                  <span v-if="tab.count" class="badge">{{ tab.count }} </span>
                </BaseActionButton>
              </template>
            </template>
          </div>
          <!-- ... -->
        </template>
      </div>

      <!-- ... -->

      <!-- footer -->
      <template #footer>
        <template v-if="!isCustomizedWorkflow || selectedNode !== 'inbox'">
          <div v-if="actions.length" class="footer row">
            <BaseButton
              v-if="selectedNode === 'inbox'"
              label="Save"
              class="q-ml-md"
              color="secondary"
              @click="validateRequest('Save')"
            />
            <template v-for="action in actions">
              <BaseButton
                v-if="
                  action === 'Approve' ||
                  action === 'Complete' ||
                  action === 'Rightsize'
                "
                :key="action"
                :label="action"
                color="positive"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else-if="
                  action === 'Reject' ||
                  action === 'Terminate' ||
                  action === 'Force Close'
                "
                :key="action"
                :label="action"
                color="red"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else-if="
                  action === 'Forward' ||
                  action.indexOf('Reply') > -1 ||
                  action === 'Rollback' ||
                  action === 'Assign'
                "
                :key="action"
                :label="action"
                color="orange"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else-if="action === 'To Requester'"
                :key="action"
                :label="action"
                color="pink"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else
                :key="action"
                :label="action"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
            </template>
          </div>
        </template>

        <template v-if="selectedProcess.activityId === 'psyCrYXtKfcs-EJmrYIhv'">
          <BaseButton
            v-if="selectedNode === 'inbox'"
            label="Verify"
            :disabled="customVerify"
            class="q-ml-md"
            color="primary"
            @click="validateRequest('CustomVerify', false, false)"
          />
        </template>
      </template>
      <!-- ... -->
    </Sheet>

    <AddTask
      v-if="taskFormId"
      :value="addTaskSheet"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="taskFormId"
      :repository-id="taskRepositoryId"
      :task-model="selectedTask"
      :workflow-task="true"
      :mode="taskMode"
      @close="addTaskSheet = false"
      @addEntry="addTaskEntry"
    ></AddTask>

    <Sheet
      v-model="mailTransactionSheet"
      width="30vw"
      no-padding
      @input="mailTransactionSheet = false"
    >
      <!-- title -->

      <template #title> Mail Transactions </template>

      <!-- ... -->

      <template #actions>
        <div class="q-ml-sm text-gray q-mr-sm ellipsis" style="font-size: 10px">
          auto-refresh every 30 seconds
        </div>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getMailTransactions()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <div class="q-pl-sm">
          <Tabs v-model="mailTab" :tabs="mailTabs" />
        </div>
        <template v-if="mailTab === 'OUTGOING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in outgoingMails">
              <div :key="'outgoing' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>

                    <BaseActionButton
                      v-tooltip.top="'resend'"
                      icon="mdi-send"
                      color="secondary"
                      is-flat
                      size="16px"
                      @click.stop="resendMail(row.ezMailId)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
        <template v-if="mailTab === 'INCOMING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in incomingMails">
              <div :key="'incoming' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
      </div>

      <!-- ... -->
    </Sheet>

    <MetaDataEdit
      v-if="showMetaDataEditModal"
      :value="showMetaDataEditModal"
      mode="Indexing"
      :file="workflow"
      :repository-field="repositoryField"
      :file-data="fileData"
      title="Index"
      :columns="columns"
      :modal-value="selectedProcess"
      :upload-filename="uploadFilename"
      @close="showMetaDataEditModal = false"
      @save="saveMetaData"
    />

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />
  </div>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import axios from "axios";
import { workflow, repository, menu, form } from "@/api/factory.js";
import formatBytes from "@/helpers/format-bytes.js";
import FileIcon from "@/components/common/FileIcon.vue";
import FileDetails from "@/views/workflows/views/workflows-inbox/components/FileDetails";
import Modal from "@/components/common/popup/Modal.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
  fileSupport,
} from "@/helpers/file-format.js";
import ListItem from "@/components/common/ListItem.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import EmailSharing from "@/components/common/EmailSharing.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import CustomForm from "@/views/custom/CustomForm.vue";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask";
import Tabs from "@/components/common/tabs/Tabs.vue";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";

export default {
  name: "RequestProcessSheet",

  components: {
    Sheet,
    FileIcon,
    FileDetails,
    Modal,
    SingleChoiceField,
    TextAreaField,
    TextBuilderField,
    ListItem,
    CheckboxField,
    EmailSharing,
    RenderForm,
    CustomForm,
    AddTask,
    Tabs,
    MetaDataEdit,
    ProcessHistory,
  },

  props: {
    // kanbanDataList: {
    //   type: Array,
    //   required: true,
    // },

    // workflowName: {
    //   type: String,
    //   required: true,
    // },

    workflowId: {
      type: [String, Number],
      required: true,
    },

    processId: {
      type: [String, Number],
      required: true,
    },

    workflowRequestList: {
      type: Array,
      required: true,
    },

    workflow: {
      type: Object,
      default: () => {},
    },

    form: {
      type: Object,
      default: () => {},
    },

    enableControls: {
      type: Object,
      default: () => {},
    },

    formId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      attachmentSheet: false,
      selectedProcess: {},
      attachmentList: [],
      emailSharingSheet: false,
      checkList: [],
      selectedNode: "",
      selectedTicket: {},
      attachmentLoad: null,
      fileData: [],
      fileDetailsSheet: false,
      checkListRowIndex: null,
      reuploadId: 0,
      repositoryDetails: {},
      repositoryField: [],
      commentsSheet: false,
      commentsList: [],
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 2,
      jiraCommentActions: [
        {
          id: this.$nano.id(),
          label: "This resource",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "All resource",
          value: 2,
        },
      ],
      jiraCommentAction: 1,
      commentText: "",
      advancedCommentsSheet: false,
      advancedCommentText: "",
      selectAll: false,
      fileSheet: false,
      selectedFile: {},
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      selectedFileList: [],

      processSheet: false,
      itemModel: {},
      formModel: {},
      headerTitle: "",
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "mail transaction",
          value: "MAIL",
          icon: "mdi-email-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Data Validation",
          value: "VALIDATION",
          icon: "mdi-file-compare",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Payment Table",
          value: "PAYMENT_TABLE",
          icon: "mdi-table",
          count: 0,
        },
      ],
      actions: [],
      groupActionFields: [],
      saveAction: "",
      workflowFormModel: {},
      workflowModel: {},
      formEditAccess: false,
      formEditControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      formFields: [],
      dataValidationValues: [],
      formCheckList: false,
      signList: [],
      userSignature: false,
      processActions: [],
      processActionsAddOn: [],
      taskFormId: 0,
      taskRepositoryId: 0,
      addTaskForm: {},
      addTaskSheet: false,
      taskEntryId: [],
      taskEntries: [],
      taskEntryList: [],
      taskMode: "",
      selectedTask: {},
      mailTabs: [
        {
          id: this.$nano.id(),
          label: "outgoing",
          value: "OUTGOING",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "incoming",
          value: "INCOMING",
          count: 0,
        },
      ],
      mailTab: "OUTGOING",
      mailTransactionSheet: false,
      mailTransactionList: [],
      mailAttachmentSheet: false,
      mailAttachmentList: [],
      showMetaDataEditModal: false,
      selectedUploadFile: [],
      columns: [],
      search: "",
      addTaskEnabled: false,
      uploadFilename: "",
      historyProcessId: 0,
    };
  },

  computed: {
    selectedFileCount() {
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    isSelectedPDF() {
      let filePDF = true;
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() !== "pdf") {
          filePDF = false;
        }
      });
      return filePDF;
    },

    isCustomizedWorkflow() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.workflow.id === 1014) {
          return true;
        }
      } else if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "http://localhost:8080"
      ) {
        if (this.workflow.id === 1) {
          return true;
        }
      }
      return false;
    },

    formName() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.name;
    },

    formDescription() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.description;
    },

    panels() {
      // console.log(this.formId);
      if (!this.formId) {
        return [];
      }
      if (this.groupActionFields.length) {
        let panels = [];
        this.form.panels.map((panel) => {
          let panelFields = [];
          panel.fields.forEach((field) => {
            if (this.groupActionFields.indexOf(field.id) > -1) {
              panelFields.push(field);
            }
          });
          panels.push({
            id: panel.id,
            fields: panelFields,
            settings: panel.settings,
          });
        });
        return panels;
      }
      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.formId) {
        return [];
      }
      if (this.groupActionFields.length) {
        return [];
      }
      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.formId) {
        return {};
      }
      return this.form.settings;
    },

    outgoingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Outgoing"
      );
    },

    incomingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Incoming"
      );
    },
  },

  watch: {
    processId: {
      deep: true,
      // immediate: true,
      handler() {
        // console.log("showprocesssheet");
        this.showProcessSheet();
      },
    },
    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet) {
          //this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },

    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          this.processRequest();
        }
      },
    },
  },

  methods: {
    getColor(card) {
      // console.log(card.color);
      if (card.color) {
        // return { border: `1px solid ${card.color}` };
        return { border: `1px solid var(--${card.color})` };
      } else {
        return { border: `1px solid var(--secondary)` };
      }
    },

    leftScroll() {
      const left = document.querySelector(".workflowKanbanRow");
      left.scrollBy(-200, 0);
    },
    rightScroll() {
      const right = document.querySelector(".workflowKanbanRow");
      right.scrollBy(200, 0);
    },

    showAttachments(ticket) {
      // console.log(ticket);
      this.selectedProcess = this.workflowRequestList.find(
        (item) => item.processId === Number(ticket.processId)
      );

      this.getAttachments(ticket.processId);
      this.selectedTicket = ticket;
      if (ticket.currentActionUser === this.$store.state.session.email) {
        this.selectedNode = "inbox";
      } else {
        this.selectedNode = "sent";
      }
      this.getFormColumns();
      this.attachmentSheet = true;
    },

    showHistoryModal(request) {
      this.selectedProcess = this.workflowRequestList.find(
        (item) => item.processId === Number(request.processId)
      );
      this.historyProcessId = this.selectedProcess.processId;
    },

    showProcessSheet() {
      // console.log(ticket);
      this.selectedProcess = this.workflowRequestList.find(
        (item) => item.processId === Number(this.processId)
      );

      if (
        this.selectedProcess.activityUserEmail ===
        this.$store.state.session.email
      ) {
        this.selectedNode = "inbox";
      } else {
        this.selectedNode = "sent";
      }

      this.getTaskForm();

      this.openProcess();

      this.getAttachments(this.processId);
      this.getComments(this.processId);

      this.getModel(Number(this.processId));
      this.getFormColumns();

      this.processSheet = true;
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    getLabel(fieldId) {
      let controlLabel = "";
      if (this.form) {
        this.form.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          let field = panel.fields.find((field) => field.id === fieldId);
          if (field) {
            controlLabel = field.label ? field.label : field.type;
            return;
          }
        });
      }
      return controlLabel;
    },

    async uploadWflowAttachments(e) {
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        this.uploadFilename = e.target.files[0].name;
        this.showMetaDataEditModal = true;
      } else {
        this.uploadAttachments(e);
      }
    },

    async uploadAttachments(e, fields) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("workflowId", this.workflowId);
      formData.append("repositoryId", this.workflow.repositoryId);
      formData.append("processId", this.selectedProcess.processId);
      formData.append("transactionId", this.selectedProcess.transactionId);
      if (fields) {
        formData.append("fields", fields);
      } else {
        formData.append("fields", "");
      }
      formData.append("file", file);
      let filename = e.target.files[0].name;
      const ext = filename.split(".").pop();

      if (this.checkListRowIndex) {
        let currentDoc = this.checkList.filter(
          (row) => row.uid === this.checkListRowIndex
        );
        formData.append("filename", currentDoc[0].name + "." + ext);
        filename = currentDoc[0].name + "." + ext;
      }
      console.log(ext);

      if (!fileSupport(ext)) {
        this.$alert.error("this file format not supporting");

        return;
      }
      this.$store.commit("showLoadingBar");

      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
          method: "POST",
          data: formData,
          headers: {
            Token: this.$store.state.identity.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        const { status, data } = response;
        if (status === 200 && data) this.$alert.success(`Document attached`);
        else throw response;
      } catch (error) {
        this.$alert.error("Error uploading file");
        console.error(error);
      }
      this.$store.commit("hideLoadingBar");
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          if (row.uid === this.checkListRowIndex) {
            row.attach = true;
            return;
          }
        });
      }
      e.target.value = "";
      if (this.reuploadId) {
        await this.attachmentDelete(this.reuploadId);
        this.reuploadId = 0;
      }
      this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
    },

    async getAttachments(processId) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      let count = this.attachmentList.length;
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getAttachments(
        this.workflowId,
        processId || this.selectedProcess.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      if (payload.length) {
        let index = payload.length - 1;
        this.getFileData(payload[index], "attach");
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          clearInterval(this.attachmentLoad);
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = this.attachmentList.length;
          }
        });
      } else {
        this.attachmentList = [];
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          let filterAttach = this.attachmentList.filter((file) => {
            let filename = file.name.substr(0, file.name.lastIndexOf("."));
            if (row.name === filename || row.name === filename.split("_")[0]) {
              return file;
            }
          });
          if (filterAttach.length) {
            row.attach = true;
          } else {
            row.attach = false;
          }
        });
      }
    },

    async getFileData(file, attach) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      this.fileData = payload;
      if (!attach) this.fileDetailsSheet = true;
    },

    attachTemplate(uid) {
      this.checkListRowIndex = uid;
      this.$refs.attachments.click();
    },

    reupload(filename) {
      filename = filename.substr(0, filename.lastIndexOf(".")).split("_")[0];
      this.checkListRowIndex = this.checkList.find(
        (row) => row.name === filename
      )?.uid;
      this.$refs.attachments.click();
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.workflow.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
      this.repositoryField = payload.fields;
    },

    isCheckList(name) {
      let list = this.checkList.filter(
        (row) =>
          row.name === name.substring(0, name.lastIndexOf(".")) ||
          row.name === name.substring(0, name.lastIndexOf(".")).split("_")[0]
      );
      if (list.length) {
        return false;
      }
      return true;
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    async attachmentDelete(id) {
      this.$store.commit("showLoadingBar");
      const { error } = await repository.fileDelete(
        this.workflow.repositoryId,
        id
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getAttachments();
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    checkCommentsOption() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return false;
      } else if (
        (origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        if (Object.keys(this.selectedProcess).length) {
          if (this.selectedProcess.jiraIssueInfo.id) {
            return true;
          }
        }

        return false;
      }
      return true;
    },

    async getComments(processId) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        this.workflowId,
        processId || this.selectedProcess.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      let origin = location.origin;
      if (this.commentText) {
        if (this.selectedProcess.processId) {
          if (
            (origin === "https://app.ezofis.com1" ||
              origin === "https://appuat.ezofis.com") &&
            this.$store.state.session.tenantId === 2 &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.commentText,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment(
              this.workflowId,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.commentText,
                showTo: this.commentAction,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                [payload]
              );
            }
          }
          this.commentText = "";
          this.commentAction = 1;
          this.getComments();
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText && this.advancedCommentText != "<p></p>") {
        if (this.selectedProcess.processId) {
          if (
            (origin === "https://app.ezofis.com1" ||
              origin === "https://appuat.ezofis.com") &&
            this.$store.state.session.tenantId === 2 &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.advancedCommentText,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment(
              this.workflowId,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.advancedCommentText,
                showTo: this.commentAction,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          }

          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.getComments();
        }
      } else {
        this.$alert.warning("Comments Text Required");
      }
    },

    showComments(ticket) {
      // console.log(ticket);
      this.selectedProcess = this.workflowRequestList.find(
        (item) => item.processId === Number(ticket.processId)
      );
      this.getComments(ticket.processId);
      this.selectedTicket = ticket;
      if (ticket.currentActionUser === this.$store.state.session.email) {
        this.selectedNode = "inbox";
      } else {
        this.selectedNode = "sent";
      }
      this.commentsSheet = true;
    },

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        ((origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2)
      ) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    isTenantGOTOComments() {
      if (Object.keys(this.selectedProcess).length) {
        let origin = location.origin;
        if (
          (origin === "https://app.ezofis.com1" ||
            origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2 &&
          this.selectedProcess.jiraIssueInfo.id
        ) {
          return true;
        }
      }
      return false;
    },

    lastActionDuration(comment) {
      const date = comment instanceof Date ? comment : new Date(comment);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    async documentShare() {
      this.selectedFileList = [];
      this.attachmentList.forEach((item) => {
        if (item.checked) {
          this.selectedFileList.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      this.emailSharingSheet = true;
    },

    async documentMerge() {
      let fileIds = [];
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() === "pdf") {
          fileIds.push(item.id);
        }
      });
      let input = {
        ids: fileIds,
      };
      const { error } = await workflow.documentMerge(
        this.workflowId,
        this.selectedProcess.processId,
        this.selectedProcess.transactionId,
        this.workflow.repositoryId,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Document merge process initiated");
    },

    documentAction(action) {
      if (action.id === "Email_Sharing") {
        //
      } else if (action.id === "Print") {
        if (this.selectedFile.name) {
          let ext = this.selectedFile.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.selectedFile.id);
          } else {
            this.$refs.myFiles.contentWindow.print();
          }
        }
        this.filePrintSession();
      } else if (action.id === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        let action = "";
        if (this.selectedNode === "inbox") {
          action = "&action=all";
        }
        let domainURL = "";
        if (pdfSupport(ext)) {
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            action = "";
            domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          } else if (
            origin === "https://trial.ezofis.com" &&
            !otherFilePDFSupport(ext)
          ) {
            domainURL = "https://trial.ezofis.com/docsviewer/index.html";
          } else if (origin === "https://app.ezofis.com1") {
            domainURL =
              "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
          } else {
            domainURL = `${origin}/PDFViewer/web/viewer.html`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          domainURL = "http://52.172.32.88/DocsViewer/index.html";
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflowId}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}${action}`;
      }
    },

    getModel(processId) {
      this.formModel = {};
      this.itemModel = {};
      this.workflowRequestList.forEach((process) => {
        if (process.processId == processId && process.id == this.workflow.id) {
          // console.log(process);
          this.headerTitle = `${process.requestNo} - ${process.stage}`;
          if (process.formData) {
            let keys = Object.keys(process.formData.fields);
            let newValues = {};
            keys.forEach((key) => {
              newValues[key] = this.validateTypeValue(
                process.formData.fields[key]
              );
            });
            this.formModel = newValues;
          } else if (process.itemData) {
            this.itemModel = process.itemData;
          }
        }
      });
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    async showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        this.attachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.commentsSheet = true;
      } else if (tab === "HISTORY" && this.selectedProcess.processId) {
        this.historyProcessId = this.selectedProcess.processId;
      } else if (tab === "PRINT") {
        window.print();
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.addTask();
        }
      } else if (tab === "VALIDATION") {
        // this.$emit("validation", this.selectedProcess.processId);
      } else if (tab === "PAYMENT_TABLE") {
        // this.$emit("paymentTable");
      } else if (tab === "MAIL") {
        this.getMailTransactions();
        this.mailTransactionSheet = true;
      }
    },

    openProcess(initiate = false) {
      this.actions = [];
      // this.form = {};
      // this.formId = "";
      // this.getRepository();
      this.formEditAccess = false;
      if (initiate) {
        this.tabs.find((tab) => {
          tab.count = 0;
        });
        // this.$emit("update:selectedProcess", {});
        // this.$emit("update:taskEntries", []);
        this.commentsList = [];
        this.attachmentList = [];
        // this.initiateProcess = true;
        this.formModel = {};
        this.signList = [];
        this.formEditAccess = false;
        this.formEditControls = [];
        this.formVisibilityAccess = true;
        this.formSecureControls = [];
        let wflowJson = JSON.parse(this.workflow.flowJson);
        let startBlock = wflowJson.blocks.find(
          (block) => block.type === "START"
        );
        if (startBlock) {
          if (startBlock.settings.initiateMode === "MANUAL") {
            this.headerTitle = `${startBlock.settings.label}`;
            let rules = wflowJson.rules.find(
              (rule) => rule.fromBlockId === startBlock.id
            );
            if (rules) {
              this.actions.push(rules.proceedAction);
            }
          }
          if (startBlock.settings.formEditAccess) {
            if (startBlock.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (startBlock.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (startBlock.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = startBlock.settings.formEditControls;
            }
          }
          if (startBlock.settings.formVisibilityAccess) {
            this.formVisibilityAccess = true;
            this.formSecureControls = [];
          } else {
            this.formVisibilityAccess = false;
            this.formSecureControls = startBlock.settings.formSecureControls;
          }

          if (startBlock.settings.fileSettings) {
            if (startBlock.settings.fileSettings.fileCheckList.length) {
              let documentCheckList =
                startBlock.settings.fileSettings.fileCheckList;
              documentCheckList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
              this.docWarning = false;
              if (documentCheckList.length) {
                documentCheckList.forEach((row) => {
                  if (row.required && !row.attach) {
                    this.docWarning = true;
                  }
                });
              }
              // this.$emit("update:checkList", documentCheckList);
            }
          } else {
            // this.$emit("update:checkList", []);
          }
        }
      } else {
        if (this.selectedNode === "inbox") {
          let wflowJson = JSON.parse(this.workflow.flowJson);
          let actionBlock = wflowJson.blocks.find(
            (block) => block.id === this.selectedProcess.activityId
          );
          if (actionBlock) {
            if (actionBlock.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (actionBlock.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (actionBlock.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = actionBlock.settings.formEditControls;
            }
            if (actionBlock.settings.formVisibilityAccess) {
              this.formVisibilityAccess = true;
              this.formSecureControls = [];
            } else {
              this.formVisibilityAccess = false;
              actionBlock.settings.formSecureControls.forEach((control) => {
                try {
                  let object = JSON.parse(control);
                  if (object.userId === this.$store.state.session.id) {
                    this.formSecureControls.push(...object.formFields);
                  }
                } catch (e) {
                  this.formSecureControls.push(control);
                }
              });
            }

            if (actionBlock.settings.fileSettings) {
              if (actionBlock.settings.fileSettings.fileCheckList.length) {
                let documentCheckList =
                  actionBlock.settings.fileSettings.fileCheckList;
                documentCheckList.forEach((row) => {
                  row.uid = this.$nano.id();
                  row.attach = false;
                });
                this.docWarning = false;
                if (documentCheckList.length) {
                  documentCheckList.forEach((row) => {
                    if (row.required && !row.attach) {
                      this.docWarning = true;
                    }
                  });
                }
                // this.$emit("update:checkList", documentCheckList);
              }
            } else {
              // this.$emit("update:checkList", []);
            }
          }
        } else {
          this.formEditAccess = true;
        }
        // this.initiateProcess = false;
        // this.getModel(this.hasSelectedProcess);
        this.actions = this.processActions;
        let origin = location.origin;
        if (
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          origin === "http://localhost:8080"
        ) {
          // this.$emit("validation", this.hasSelectedProcess, false);
        }
      }

      if (this.selectedNode === "inbox") {
        this.processActions = [];
        this.processActionsAddOn = [];
        let origin = location.origin;
        var wflowJson = JSON.parse(this.workflow.flowJson);
        if (
          (origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net" ||
            origin === "http://localhost:8080") &&
          this.selectedProcess.activityId === "psyCrYXtKfcs-EJmrYIhv"
        ) {
          // console.log(wflowJson);
          wflowJson.rules.forEach((rule) => {
            if (
              rule.fromBlockId === this.selectedProcess.activityId &&
              rule.proceedAction === "Force Close"
            ) {
              this.processActions.push(rule.proceedAction);
              this.processActionsAddOn.push({
                action: rule.proceedAction,
                remarks: rule.remarks || false,
                passwordAccess: rule.passwordAccess || false,
                confirm: rule.confirm || false,
                signature: rule.signature || false,
              });
            }
          });
        } else {
          // console.log(wflowJson);
          wflowJson.rules.forEach((rule) => {
            if (rule.fromBlockId === this.selectedProcess.activityId) {
              if (this.processActions.indexOf(rule.proceedAction) < 0) {
                this.processActions.push(rule.proceedAction);
                this.processActionsAddOn.push({
                  action: rule.proceedAction,
                  remarks: rule.remarks || false,
                  passwordAccess: rule.passwordAccess || false,
                  confirm: rule.confirm || false,
                  signature: rule.signature || false,
                });
              }
            }
          });
        }

        wflowJson.blocks.forEach((block) => {
          if (block.id === this.selectedProcess.activityId) {
            //console.log(block.settings);
            if (
              this.selectedProcess.lastAction === "Forward" &&
              block.settings.forwardedUserAction === "REPLY"
            ) {
              this.processActions = [];
              this.processActionsAddOn = [];
              this.processActions.push(
                "Reply to " + this.selectedProcess.transaction_createdByEmail
              );
              this.processActionsAddOn.push({
                action:
                  "Reply to " + this.selectedProcess.transaction_createdByEmail,
                remarks: true,
                passwordAccess: false,
                confirm: false,
                signature: false,
              });
              this.customReply = true;
            } else {
              if (
                block.settings.userReply &&
                this.processActions.indexOf("Reply") === -1
              ) {
                this.processActions.push(
                  "Reply to " + this.selectedProcess.lastActionStageName
                );
                this.userReply = true;
              }
              if (
                block.settings.toInitiator &&
                this.processActions.indexOf("To Requester") === -1
              ) {
                this.processActions.push("To Requester");
                this.toRequester = true;
              }
              if (block.settings.internalForward) {
                let origin = location.origin;
                if (
                  (origin === "https://app.ezofis.com1" ||
                    origin === "https://appuat.ezofis.com") &&
                  this.$store.state.session.tenantId === 2
                ) {
                  this.processActions.push("Assign");
                } else {
                  this.processActions.push("Forward");
                }
                this.internalForward.proceed = true;
                this.internalForward.users = [];
                this.internalForward.groups = [];
                if (block.settings.internalForwardUser) {
                  if (block.settings.internalForwardUser.length) {
                    block.settings.internalForwardUser.forEach((user) => {
                      let userFilter = this.userList.filter(
                        (row) => row.value == user
                      );
                      if (userFilter.length) {
                        this.internalForward.users.push({
                          id: this.$nano.id(),
                          label: userFilter[0].label,
                          value: user,
                        });
                      }
                    });
                  }
                }
                if (block.settings.internalForwardGroup) {
                  if (block.settings.internalForwardGroup.length) {
                    block.settings.internalForwardGroup.forEach((group) => {
                      let groupFilter = this.groupList.filter(
                        (row) => row.value == group
                      );
                      if (groupFilter.length) {
                        this.internalForward.groups.push({
                          id: this.$nano.id(),
                          label: groupFilter[0].label,
                          value: group,
                        });
                      }
                    });
                  }
                }
              }
            }

            this.userSignature = block.settings.userSignature;
            if (block.settings.fileSettings) {
              if (block.settings.fileSettings.fileCheckList.length) {
                this.checkList = block.settings.fileSettings.fileCheckList;
                this.checkList.forEach((row) => {
                  row.uid = this.$nano.id();
                  row.attach = false;
                });
              }
            }
          }
        });
        if (this.selectedProcess.userType) {
          if (this.selectedProcess.userType === "SUPERUSER") {
            //this.processActions = [];
            this.processActions.push("Force Close");
          }
        }
      } else if (this.selectedNode === "sent") {
        let origin = location.origin;
        if (
          (origin === "https://app.ezofis.com1" ||
            origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2
        ) {
          if (
            this.selectedProcess.transaction_createdBy ===
            this.$store.state.session.id
          ) {
            this.processActions = [];
            this.processActions.push("Rollback");
          }
        } else if (
          origin === "http://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          if (this.selectedWorkflow.id === 6) {
            if (
              this.selectedProcess.transaction_createdBy ===
              this.$store.state.session.id
            ) {
              this.processActions = [];
              this.processActions.push("Rollback");
            }
          }
        }
      }

      this.actions = this.processActions;

      // if (this.workflow.wFormId) {
      //   this.formId = String(this.workflow.wFormId);
      //   this.form = JSON.parse(this.wform.formJson);
      //   if (!initiate) {
      //     if (this.form.settings.general.layout === "CARD") {
      //       this.form.settings.general.layout = "TAB";
      //     }
      //   }
      // }
      // this.processSheet = true;
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    addTask() {
      this.selectedTask = {};
      this.taskMode = "NEW";
      this.addTaskSheet = true;
    },

    viewTask(id) {
      let filterTask = this.taskList.filter((row) => row.formEntryId === id);
      if (filterTask.length) {
        this.selectedTask = filterTask[0].entryInfo[0];
        this.selectedTask = {
          id: filterTask[0].formEntryId,
          ...filterTask[0].entryInfo[0],
        };
      }
      this.taskMode = "EDIT";
      this.addTaskSheet = true;
    },

    async getTaskForm() {
      this.taskFormList = [];
      this.addTaskEnabled = false;
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.addTaskEnabled = true;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length) {
            if (this.taskFormList.length === 1) {
              this.taskFormId = this.taskFormList[0].id;
              this.taskRepositoryId = this.taskFormList[0].repositoryId;
              let formDet = await this.getTaskFormById(this.taskFormId);
              this.addTaskForm = JSON.parse(formDet.formJson);
            }
          } else {
            //this.$alert.error("Task form not found");
          }
        }
      }
    },

    async getTaskFormById(formId) {
      const { error, payload } = await form.getForm(formId);
      if (error) {
        this.$alert.error(error);
        return;
      }
      return payload;
    },

    async getTaskFormEntry(formId, entryId) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getFormEntry(formId, entryId);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];

        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let taskName = "",
          taskAssign = "",
          startDate = "",
          endDate = "",
          priority = "",
          status = "";
        formFields.forEach((field) => {
          switch (field.label) {
            case "Task Name":
              taskName = payload[0][field.id];
              break;
            case "Task Assign":
              taskAssign = payload[0][field.id];
              break;
            case "Start Date":
              startDate = payload[0][field.id];
              break;
            case "End Date":
              endDate = payload[0][field.id];
              break;
            case "Priority":
              priority = payload[0][field.id];
              break;
            case "Task Status":
              status = payload[0][field.id];
              break;
          }
        });
        this.taskEntries.push({
          id: payload[0].itemid,
          name: taskName,
          assigned: taskAssign,
          startDate: startDate,
          endDate: endDate,
          priority: priority,
          status: status,
        });
      }
    },

    addTaskEntry(formId, entryId) {
      if (this.selectedProcess.processId) {
        this.insertTaskEntry(formId, entryId);
      } else {
        this.taskEntryId.push({
          formId: formId,
          formEntryId: entryId,
        });
        this.getTaskFormEntry(formId, entryId);
      }
    },

    async getTaskList() {
      this.taskEntries = [];
      let taskFilterBy = [];
      const panels = [
        ...this.addTaskForm.panels,
        ...this.addTaskForm.secondaryPanels,
      ];
      let formFields = [];
      for (const panel of panels) {
        formFields.push(...panel.fields);
      }
      let taskOwnerField = formFields.find(
        (field) => field.label === "Task Owner"
      );
      if (taskOwnerField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskOwnerField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      let taskAssignField = formFields.find(
        (field) => field.label === "Task Assign"
      );
      if (taskAssignField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskAssignField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getTaskEntries(
        this.selectedWorkflow.id,
        this.selectedProcess.processId,
        {
          filterBy: [
            {
              filters: [
                {
                  criteria: "createdBy",
                  condition: "IS_EQUALS_TO",
                  value: this.$store.state.session.id,
                },
              ],
            },
            ...taskFilterBy,
          ],
        }
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.taskList = payload;
        payload.forEach((row) => {
          let taskName = "",
            taskAssign = "",
            startDate = "",
            endDate = "",
            priority = "",
            status = "";
          formFields.forEach((field) => {
            switch (field.label) {
              case "Task Name":
                taskName = row.entryInfo[0][field.id];
                break;
              case "Task Assign":
                taskAssign = row.entryInfo[0][field.id];
                break;
              case "Start Date":
                startDate = row.entryInfo[0][field.id];
                break;
              case "End Date":
                endDate = row.entryInfo[0][field.id];
                break;
              case "Priority":
                priority = row.entryInfo[0][field.id];
                break;
              case "Task Status":
                status = row.entryInfo[0][field.id];
                break;
            }
          });
          this.taskEntries.push({
            id: row.entryInfo[0].itemid,
            name: taskName,
            assigned: taskAssign,
            startDate: startDate,
            endDate: endDate,
            priority: priority,
            status: status,
          });
        });
      }
    },

    async insertTaskEntry(formId, entryId) {
      this.$store.commit("showLoadingBar");
      const { error } = await workflow.insertTaskEntry({
        workflowId: this.selectedWorkflow.id,
        processId: this.selectedProcess.processId,
        transactionId: this.selectedProcess.transactionId,
        wFormId: formId,
        formEntryId: entryId,
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getTaskList();
    },

    async getMailTransactions() {
      this.mailTransactionList = [];
      this.tabs.find((tab) => {
        if (tab.value === "MAIL") {
          tab.count = 0;
        }
      });
      let filter = { filterBy: [] };
      const { error, payload } = await workflow.getMailTransactions(
        this.workflow.id,
        this.selectedProcess.processId,
        filter
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.mailTransactionList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "MAIL") {
            tab.count = this.mailTransactionList.length;
          }
        });
      }
    },

    validateRequest(action) {
      this.saveAction = action;
      if (this.itemModel.itemId) {
        this.processRequest();
      }
    },

    async processRequest(forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        let origin = location.origin;
        if (
          (origin === "https://app.ezofis.com1" ||
            origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2
        ) {
          if (this.saveAction === "Assign" && this.internalForward.proceed) {
            this.processForwardGroupAction = false;
            this.processForwardModal = true;
            return;
          }
        }
        if (this.saveAction === "Forward" && this.internalForward.proceed) {
          this.processForwardGroupAction = false;
          this.processForwardModal = true;
          return;
        }
      }

      if (addOnCheck) {
        if (this.saveAction === "Save") {
          this.actionConfirm = true;
          this.actionConfirmAlert = true;
          return;
        } else {
          let actionAddOn = this.processActionsAddOn.find(
            (row) => row.action === this.saveAction
          );
          if (actionAddOn) {
            if (Object.keys(actionAddOn).length) {
              this.actionRemarks = actionAddOn.remarks;
              this.actionPassword = actionAddOn.passwordAccess;
              this.actionConfirm = actionAddOn.confirm;
              if (this.actionPassword) {
                this.actionPasswordConfirm = true;
                return;
              }
              if (this.actionRemarks) {
                this.actionComments = true;
                this.advancedCommentsSheet = true;
                return;
              }
              if (this.actionConfirm) {
                this.actionConfirmAlert = true;
                return;
              }
            }
          }
        }
      }

      let inputData = {};
      let origin = location.origin;

      if (this.userReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "UserReply";
      } else if (this.toRequester && this.saveAction === "To Requester") {
        this.saveAction = "ToInitiator";
      }
      if (this.customReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "Reply";
        this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
      }
      if (this.formId) {
        if (
          (origin === "https://app.ezofis.com1" ||
            origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2 &&
          this.saveAction === "Rollback"
        ) {
          if (this.workflow.id === 1) {
            this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
          }
        } else if (
          origin === "http://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          if (this.workflow.id === 6) {
            this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
          }
        }
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review:
            this.saveAction === "Save" || this.saveAction === "CustomVerify"
              ? ""
              : this.saveAction,
          formData: {
            formId: this.formId,
            formEntryId: this.selectedProcess.formData.formEntryId,
            fields: this.workflowFormModel,
          },
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      } else if (this.itemModel) {
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {},
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      }
      let saveOnly = false;
      if (
        this.saveAction === "CustomVerify" &&
        (origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          origin === "http://localhost:8080")
      ) {
        saveOnly = true;
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      // console.log(inputData);

      this.$store.commit("showLoadingBarSave");
      const { error } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature(this.selectedProcess);
      }

      if (saveOnly) {
        //this.$alert.info(`${this.selectedProcess.requestNo} data saved, awaiting your action to submit`);
        // this.$emit("paymentTable");
      } else {
        if (inputData.review === "") {
          this.$alert.info(
            `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
          );
        } else if (inputData.review.toLowerCase().indexOf("reject") > -1) {
          this.$alert.danger(
            `${this.selectedProcess.requestNo} Request Rejected Successfully`
          );
        } else {
          this.$alert.success(
            `${this.selectedProcess.requestNo} Request Processed`
          );
        }
        this.forwardUsers = [];
        this.forwardGroups = [];
        // this.$emit("update:customReply", false);
        // this.$emit("update:userReply", false);
        // this.$emit("update:toRequester", false);
        // this.$emit("update:selectedProcess", {});
        this.closeProcessSheet();
        // this.$emit("refresh", this.workflow.id);
      }
    },

    async saveProcessSignature(request) {
      let input = {
        signBinary: this.signImg.split(",")[1],
        fileType: "png",
      };
      const { error } = await workflow.saveProcessSignature(
        this.workflow.id,
        request.processId,
        request.transactionId,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signImg = "";
    },

    closeProcessSheet() {
      this.processSheet = false;
    },

    saveMetaData(modal) {
      let data = [];
      this.repositoryField.forEach((field) => {
        data.push({
          name: field.name,
          value: modal[field.name],
          type: field.dataType,
        });
      });
      this.uploadAttachments(this.selectedUploadFile, JSON.stringify(data));
    },

    getFormColumns() {
      let filterColumns = [];
      if (this.form) {
        const panels = [...this.form.panels, ...this.form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        formFields.forEach((field) => {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            (field.label || field.type === "TABLE") &&
            field.settings.general.visibility !== "DISABLE"
          ) {
            filterColumns.push({
              id: field.id,
              label: field.label,
              isVisible: true,
              display: "",
            });
          }
        });
      }
      this.columns = filterColumns;
    },

    searchKanban(search, list, index) {
      console.log(list, search);
      if (search) {
        // let tempRecipes = this.kanbanList[index].tickets.filter((item) => {
        //   return item.requestNo.toUpperCase().includes(search.toUpperCase());
        // });
        // console.log(tempRecipes);
        let columns = [
          "attachmentList",
          "commentsList",
          "completedStatus",
          "currentActionDate",
          "currentActionUser",
          "currentTransactionId",
          "nodeName",
          "processId",
          "requestNo",
          "slaTime",
        ];

        // console.log(columns);

        // let data = this.kanbanDataList[index].tickets.filter((row) => {
        this.kanbanList[index].tickets.forEach((row) => {
          for (let cell in row) {
            let index1 = columns.indexOf(cell);
            if (index1 >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                return;
              }
            }
          }
        });
        // console.log(data);

        // this.kanbanList[index].tickets = data;
      } else {
        this.kanbanList[index].tickets = this.kanbanDataList[index].tickets;
      }
      // console.log(this.kanbanList);
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#process-sheet {
  .list {
    height: 100%;
    border: 1px solid transparent;
    padding: 5px;
    border-radius: 10px;
    background: #dfe6e9;
    // width: 100%;
  }

  .cards {
    height: 100%;
    // border: 1px solid #fff;
    padding: 5px;
    border-radius: 4px;
    background: white;
    border: 1px solid #fff;

    // width: 100%;
  }

  #action-btn.linkActions {
    width: 28px !important;
    height: 28px !important;
  }

  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 10px;
    background: $secondary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .workflow-name {
    width: 150px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000 !important;
    font-weight: 500;
    text-align: justify;
  }

  .workflow-name:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
  }

  #global-search {
    width: 90%;
    height: 26px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0px 12px;
    color: var(--icon-color);
    border: 1px solid var(--divider-color);
    float: right;
    // background-color: var(--body-bg-color);

    .label {
      @extend .text-sm;
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }

  .taskKanbanRow {
    // display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 100%;
  }
}

#sheet .attachmentList,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }
}

#modal #processHistory {
  #history {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    #attachments {
      width: 98%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 5px;
      background: #fcfafa;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
#single-choice-field.actions .options-wrapper {
  padding-top: 8px !important;
}
#single-choice-field.actions.noborder .options-wrapper {
  border-top: 0px !important;
  padding-top: 0px !important;
}
</style>
