<template>
  <div id="list">
    <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />
    <table>
      <thead v-if="tableHeader">
        <tr>
          <!-- avatar -->

          <th class="sticky icon" style="left: 0"></th>
          <slot v-if="dummyData"></slot>
          <!-- ... -->

          <!-- title -->

          <th v-if="title" class="sticky" style="left: 36px">
            <slot name="titleHead" :title="title.label">
              {{ title.label }}
            </slot>
          </th>

          <!-- ... -->

          <!-- columns -->

          <th v-for="column in _columns" :key="column.id">
            {{ column.label }}
          </th>

          <!-- ... -->

          <!-- actions -->

          <th class="sticky" style="right: 0"></th>

          <!-- ... -->
        </tr>
      </thead>

      <tbody>
        <template v-for="item in items">
          <tr
            :key="item.id"
            :style="{
              'border-left': actionColor(item.lastAction),
            }"
            @click="expandSubWorkflow(item)"
          >
            <!-- avatar -->

            <td class="sticky icon" style="left: 0; width: 36px">
              <div class="row items-center justify-center">
                <slot name="avatar" :item="item">
                  <template v-if="item">
                    <template v-if="item.iconTooltip">
                      <BaseIcon
                        v-tooltip.top="item.iconTooltip"
                        :name="item.icon"
                        :color="item.color || 'secondary'"
                      />
                    </template>
                    <template v-else>
                      <BaseIcon
                        :name="item.icon"
                        :color="item.color || 'secondary'"
                      />
                    </template>
                  </template>
                </slot>
              </div>
            </td>

            <!-- ... -->

            <!-- title -->

            <td v-if="title" class="sticky" style="left: 36px">
              <slot name="title" :item="item" :title="item[title.name]">
                <div class="row">
                  <!-- <BaseIcon
                    v-if="item.subWorkflow.length"
                    v-tooltip.top="'Expand'"
                    :name="
                      item.displaySubTable
                        ? 'eva-arrow-downward'
                        : 'eva-arrow-forward'
                    "
                    color="primary"
                    class="col-auto q-mr-sm"
                  /> -->
                  <div
                    v-tooltip.top="item[title.name]"
                    class="font-medium q-mr-xl link-text col"
                    @click="openSheet(item.id)"
                  >
                    {{ item[title.name] }}
                  </div>
                </div>
              </slot>
            </td>

            <!-- ... -->

            <!-- columns -->

            <td v-for="column in _columns" :key="column.id">
              <template v-if="column.type === 'TABLE'">
                <template v-if="item[column.name]">
                  <template v-if="JSON.parse(item[column.name]).length">
                    <BaseActionButton
                      v-tooltip.top="'Table Data'"
                      icon="mdi-table"
                      is-flat
                      no-border
                      @click.stop="showTable(column.label, item[column.name])"
                    />
                  </template>
                  <template v-else>
                    <BaseActionButton
                      icon="mdi-table"
                      is-flat
                      no-border
                      is-disabled
                    />
                  </template>
                </template>
              </template>
              <template v-else-if="column.type === 'FILE_UPLOAD'">
                <div v-if="item[column.name]" class="ellipsis medium">
                  <div
                    v-for="(file, index) in JSON.parse(item[column.name])"
                    :key="index"
                    class="row"
                  >
                    <FileIcon
                      :mime-type="fileType(file.fileName)"
                      class="mini-avatar col-auto q-mr-sm"
                    />
                    <span
                      class="text-secondary text-underline col"
                      @click="viewFile(file, item)"
                      >{{ file.fileName }}</span
                    >
                  </div>
                </div>
              </template>
              <template v-else-if="column.type === 'PHONE_NUMBER'">
                <template v-if="item[column.name]">
                  <template v-if="JSON.parse(item[column.name]).phoneNo">
                    {{
                      `${JSON.parse(item[column.name]).code} ${
                        JSON.parse(item[column.name]).phoneNo
                      }`
                    }}
                    <BaseIcon
                      v-if="JSON.parse(item[column.name]).verified"
                      v-tooltip.top="'Verified'"
                      name="mdi-check-all"
                      color="green"
                      class="q-ml-sm"
                    />
                  </template>
                </template>
              </template>
              <template v-else>
                <slot
                  :name="column.name"
                  :value="item[column.name]"
                  :item="item"
                >
                  {{ removeTags(item[column.name]) || "-" }}
                </slot>
              </template>
            </td>

            <!-- ... -->

            <!-- actions -->

            <td class="sticky icon" style="right: 0">
              <slot name="actions" :item="item">
                <template v-if="item">
                  <div class="row justify-end no-wrap">
                    <template v-if="item">
                      <BaseActionButton
                        v-for="tab in item.tabs"
                        :key="tab.id"
                        v-tooltip.left="tab.label"
                        is-flat
                        no-border
                        :icon="tab.icon"
                        color="primary"
                        class="tabButtons q-mr-xs"
                        size="16px"
                        @click.stop="$emit('actions', tab.value, item.id)"
                      >
                        <span v-if="item[tab.name]" class="badge"
                          >{{ item[tab.name] }}
                        </span>
                      </BaseActionButton>
                    </template>
                    <BaseActionButton
                      v-tooltip.left="'Expand'"
                      is-flat
                      no-border
                      icon="mdi-open-in-new"
                      color="primary"
                      class="tabButtons q-mr-xs"
                      size="16px"
                      @click="openSheet(item.id)"
                    />
                  </div>
                </template>
              </slot>
            </td>

            <!-- ... -->
          </tr>
          <template v-if="item && item.displaySubTable && item.subWorkflow">
            <template v-if="item.subWorkflow.length">
              <tr :key="'subrow' + item.id">
                <td :colspan="_columns.length + 3">
                  <table
                    :key="`subtable${item.id}`"
                    class="subWorkflowTable"
                    style="border: 1px solid var(--divider-color)"
                  >
                    <thead v-if="tableHeader">
                      <tr>
                        <!-- avatar -->

                        <th class="sticky icon" style="left: 0"></th>
                        <slot v-if="dummyData"></slot>
                        <!-- ... -->

                        <!-- title -->

                        <th v-if="title" class="sticky" style="left: 36px">
                          <slot name="titleHead" :title="title.label">
                            {{ title.label }}
                          </slot>
                        </th>

                        <!-- ... -->

                        <!-- columns -->

                        <th
                          v-for="column in _subWorkflowColumns"
                          :key="column.id"
                        >
                          {{ column.label }}
                        </th>

                        <!-- ... -->

                        <!-- actions -->

                        <th v-if="false" class="sticky" style="right: 0"></th>

                        <!-- ... -->
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="subItem in item.subWorkflow">
                        <tr
                          :key="`subbody${subItem.id}`"
                          :style="{
                            background: subItem.inbox
                              ? darkTheme
                                ? 'rgb(60, 50, 31)'
                                : 'rgb(255 228 178)'
                              : '',
                            'border-left': actionColor(item.lastAction),
                          }"
                        >
                          <td
                            class="sticky icon"
                            style="left: 0; width: 36px"
                            :style="{
                              background: subItem.inbox
                                ? darkTheme
                                  ? 'rgb(60, 50, 31)'
                                  : 'rgb(255 228 178)'
                                : '',
                            }"
                          >
                            <div class="row items-center justify-center">
                              <slot name="avatar" :item="subItem">
                                <template v-if="subItem.iconTooltip">
                                  <BaseIcon
                                    v-tooltip.top="subItem.iconTooltip"
                                    :name="subItem.icon"
                                    :color="subItem.color || 'secondary'"
                                  />
                                </template>
                                <template v-else>
                                  <BaseIcon
                                    :name="subItem.icon"
                                    :color="subItem.color || 'secondary'"
                                  />
                                </template>
                              </slot>
                            </div>
                          </td>

                          <td
                            v-if="title"
                            class="sticky"
                            style="left: 36px"
                            :style="{
                              background: subItem.inbox
                                ? darkTheme
                                  ? 'rgb(60, 50, 31)'
                                  : 'rgb(255 228 178)'
                                : '',
                            }"
                          >
                            <slot
                              name="title"
                              :subItem="subItem"
                              :title="subItem.subWorkflowName"
                            >
                              <div
                                v-tooltip.top="subItem.requestNo"
                                class="font-medium q-mr-xl link-text"
                                @click="openSubSheet(subItem)"
                              >
                                {{ subItem.requestNo }}
                              </div>
                            </slot>
                          </td>

                          <td
                            v-for="column in _subWorkflowColumns"
                            :key="column.id"
                          >
                            <slot
                              :name="column.name"
                              :value="subItem[column.name]"
                              :item="subItem"
                            >
                              {{ subItem[column.name] }}
                            </slot>
                          </td>

                          <td
                            v-if="false"
                            class="sticky icon"
                            style="right: 0"
                            :style="{
                              background: subItem.inbox
                                ? darkTheme
                                  ? 'rgb(60, 50, 31)'
                                  : 'rgb(255 228 178)'
                                : '',
                            }"
                          >
                            <slot name="actions" :subItem="subItem">
                              <template v-if="subItem">
                                <div class="row justify-end no-wrap">
                                  <template v-if="subItem">
                                    <BaseActionButton
                                      v-for="tab in subItem.tabs"
                                      :key="tab.id"
                                      v-tooltip.left="tab.label"
                                      is-flat
                                      no-border
                                      :icon="tab.icon"
                                      color="primary"
                                      class="tabButtons q-mr-xs"
                                      size="16px"
                                      @click.stop="
                                        $emit(
                                          'subactions',
                                          tab.value,
                                          item,
                                          subItem
                                        )
                                      "
                                    >
                                      <span
                                        v-if="subItem[tab.name]"
                                        class="badge"
                                        >{{ subItem[tab.name] }}
                                      </span>
                                    </BaseActionButton>
                                  </template>
                                  <BaseActionButton
                                    v-tooltip.left="'Expand'"
                                    is-flat
                                    no-border
                                    icon="mdi-open-in-new"
                                    color="primary"
                                    class="tabButtons q-mr-xs"
                                    size="16px"
                                    @click="openSubSheet(subItem)"
                                  />
                                </div>
                              </template>
                            </slot>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>

    <Sheet
      v-model="processSheet"
      width="100vw"
      :has-footer="false"
      :no-padding="true"
      @input="processSheet = false"
    >
      <!-- title -->

      <template #title>"{{ request.requestNo }}" process details</template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-if="!formView"
          v-tooltip.top="'Form View'"
          is-flat
          :icon="'mdi-form-select'"
          class="q-ml-sm q-mr-sm"
          @click="openForm"
        />

        <BaseActionButton
          v-else
          v-tooltip.top="'Details View'"
          is-flat
          :icon="'mdi-table-column'"
          class="q-ml-sm q-mr-sm"
          @click="openTable"
        />
      </template>

      <template #default>
        <div :key="request.processId + 'det'" class="row">
          <div class="col">
            <div
              class="col-12 q-ma-md"
              :class="{ 'q-dark': darkTheme, 'q-light': !darkTheme }"
            >
              <q-stepper
                ref="stepper"
                alternative-labels
                color="primary"
                animated
              >
                <q-step
                  v-for="(level, index) in stageLevel"
                  :key="level.id"
                  :name="index"
                  :title="level.label"
                  :prefix="index + 1"
                  :done="currentStep >= index + 1"
                >
                </q-step>
              </q-stepper>
            </div>
            <div id="requestDetails" class="col-12">
              <div class="row justify-center">
                <div class="col q-pa-md">
                  <div class="row items-center">
                    <table class="submittedData q-mb-sm">
                      <tbody>
                        <tr v-if="request.createdByName">
                          <td colspan="4">
                            Requested By: {{ request.createdByName }}
                          </td>
                        </tr>
                        <tr>
                          <th v-if="request.raisedBy">Requested User Email</th>
                          <th>Requested Date</th>
                          <th>Last Acted By</th>
                          <th>Last Acted On</th>
                        </tr>
                        <tr>
                          <td v-if="request.raisedBy">
                            {{ request.raisedBy }}
                          </td>
                          <td>
                            {{ $day.format(request.raisedAt) }}
                          </td>
                          <td>{{ request.transaction_createdByEmail }}</td>
                          <td>
                            {{ $day.format(request.transaction_createdAt) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <BaseScrollbar height="calc(100vh - 320px)">
                      <template v-if="requestFormData.panels">
                        <table class="mainData col-12">
                          <template v-for="panel in requestFormData.panels">
                            <tr
                              :key="panel.name"
                              style="cursor: pointer"
                              @click="
                                panel.panelsExpandView = !panel.panelsExpandView
                              "
                            >
                              <td style="color: #643094" colspan="2">
                                <BaseIcon
                                  v-if="!panel.panelsExpandView"
                                  name="eva-arrow-forward-outline"
                                  size="20px"
                                  color="primary"
                                  class="icon"
                                />

                                <BaseIcon
                                  v-else
                                  name="eva-arrow-downward-outline"
                                  size="20px"
                                  color="primary"
                                  class="icon"
                                />
                                {{ panel.name || "No Title" }}
                              </td>
                            </tr>
                            <template v-if="panel.panelsExpandView">
                              <template v-for="(value, key) in panel.fields">
                                <template v-if="value && value != '[]'">
                                  <template v-if="isVisible(key)">
                                    <template
                                      v-if="validateType(key) !== 'TABLE'"
                                    >
                                      <tr :key="key">
                                        <td class="tblHeader ellipsis small">
                                          {{ getLabel(key) }}
                                        </td>
                                        <td class="ellipsis">
                                          <template
                                            v-if="
                                              validateType(key) ===
                                              'FILE_UPLOAD'
                                            "
                                          >
                                            <div
                                              v-if="JSON.parse(value).length"
                                            >
                                              <div
                                                v-for="file in JSON.parse(
                                                  value
                                                )"
                                                :key="file.fileName"
                                                class="q-mb-xs"
                                              >
                                                <div class="row items-center">
                                                  <div
                                                    class="col row items-center"
                                                    @click="
                                                      viewFile(file, value)
                                                    "
                                                  >
                                                    <FileIcon
                                                      :mime-type="
                                                        fileType(file.fileName)
                                                      "
                                                      class="mini-avatar q-mr-sm"
                                                    />
                                                    <span
                                                      class="cursor-pointer text-underline text-secondary"
                                                      >{{ file.fileName }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </template>
                                          <template
                                            v-else-if="
                                              validateType(key) === 'DATE'
                                            "
                                          >
                                            {{ $day.format(value, false) }}
                                          </template>
                                          <template
                                            v-else-if="
                                              validateType(key) === 'SIGNATURE'
                                            "
                                          >
                                            <img
                                              :src="value"
                                              style="height: 60px"
                                            />
                                          </template>
                                          <template
                                            v-else-if="validateValue(value)"
                                          >
                                            {{ removeTags(value) || "-" }}
                                          </template>
                                          <template v-else>
                                            <a :href="value" target="_blank">
                                              {{ value || "-" }}
                                            </a>
                                          </template>
                                        </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-else-if="JSON.parse(value).length"
                                    >
                                      <tr
                                        v-if="getLabel(key)"
                                        :key="'trlabel' + key"
                                      >
                                        <td
                                          colspan="2"
                                          class="tblHeader ellipsis small"
                                        >
                                          <FormFieldLabel
                                            :key="'label' + key"
                                            :label="getLabel(key)"
                                            class="q-mt-sm"
                                          />
                                        </td>
                                      </tr>
                                      <tr :key="'trtable' + key">
                                        <td colspan="2">
                                          <BaseScrollbar
                                            :key="'scroll' + key"
                                            style="max-height: 200px"
                                          >
                                            <table
                                              :key="'table-' + key"
                                              class="sub-table q-mt-sm q-mb-sm"
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    v-for="column in tableColumns(
                                                      value,
                                                      key
                                                    )"
                                                    :key="column.id"
                                                  >
                                                    {{ column.label }}
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr
                                                  v-for="(
                                                    row, index
                                                  ) in tableValue(value)"
                                                  :key="index"
                                                >
                                                  <td
                                                    v-for="column in tableColumns(
                                                      value,
                                                      key
                                                    )"
                                                    :key="column.id"
                                                  >
                                                    <template
                                                      v-if="
                                                        Array.isArray(
                                                          row[column.id]
                                                        ) &&
                                                        row[column.id].length &&
                                                        row[column.id][0].itemId
                                                      "
                                                    >
                                                      <div
                                                        v-for="file in row[
                                                          column.id
                                                        ]"
                                                        :key="file.itemId"
                                                        class="row items-center"
                                                      >
                                                        <span
                                                          class="q-mr-sm fileIcon"
                                                          ><FileIcon
                                                            :mime-type="
                                                              fileType(
                                                                file.fileName
                                                              )
                                                            "
                                                            class="mini-avatar"
                                                        /></span>
                                                        <span
                                                          class="cursor-pointer text-underline text-secondary"
                                                          @click="
                                                            viewFile(file, {})
                                                          "
                                                          >{{
                                                            file.fileName
                                                          }}</span
                                                        >
                                                      </div>
                                                    </template>
                                                    <template v-else>
                                                      {{ row[column.id] }}
                                                    </template>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <!-- <table
                                            :key="'table' + key"
                                            class="sub-table q-mt-sm q-mb-sm"
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  v-for="(
                                                    field_item, field_key
                                                  ) in tableValue(value)[0]"
                                                  :key="field_key"
                                                >
                                                  {{
                                                    getLabel(field_key, "table")
                                                  }}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                v-for="(
                                                  field_item, field_key
                                                ) in tableValue(value)"
                                                :key="field_key"
                                              >
                                                <td
                                                  v-for="(
                                                    row_value, row_key
                                                  ) in field_item"
                                                  :key="row_key"
                                                >
                                                  {{ row_value }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table> -->
                                          </BaseScrollbar>
                                        </td>
                                      </tr>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </template>
                          </template>
                        </table>
                      </template>
                    </BaseScrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-auto q-mr-sm leftBorder">
            <template v-for="tab in tabs">
              <BaseActionButton
                :key="tab.id"
                v-tooltip.left="tab.label"
                is-flat
                no-border
                :icon="tab.icon"
                color="primary"
                class="tabButtons"
                @click="$emit('actions', tab.value, request.processId)"
              >
                <span v-if="tab.count" class="badge">{{ tab.count }} </span>
              </BaseActionButton>
            </template>
          </div>
        </div>
      </template>
    </Sheet>

    <Modal
      v-model="expandTableModal"
      width="60vw"
      height="80vh"
      @input="expandTableModal = false"
    >
      <!-- title -->

      <template #title>{{ tableTitle }}</template>

      <!-- ... -->

      <template #default>
        <div id="tableData">
          <table>
            <thead>
              <tr>
                <th v-for="(value, key) in tableData[0]" :key="key">
                  {{ key }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in tableData" :key="index">
                <td v-for="(value, key) in row" :key="key">
                  <template
                    v-if="
                      Array.isArray(value) && value.length && value[0].itemId
                    "
                  >
                    <div
                      v-for="file in value"
                      :key="file.itemId"
                      class="row items-center"
                    >
                      <span class="q-mr-sm fileIcon"
                        ><FileIcon
                          :mime-type="fileType(file.fileName)"
                          class="mini-avatar"
                      /></span>
                      <span
                        class="cursor-pointer text-underline text-secondary"
                        @click="viewFile(file, {})"
                        >{{ file.fileName }}</span
                      >
                    </div>
                  </template>
                  <template v-else>
                    {{ value }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sheet from "@/components/common/popup/Sheet.vue";
import Modal from "@/components/common/popup/Modal.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import { workflow } from "@/api/factory.js";
import FileIcon from "@/components/common/FileIcon.vue";

export default {
  name: "List",

  components: {
    Sheet,
    Modal,
    FormFieldLabel,
    FileIcon,
  },

  props: {
    columns: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },

    form: {
      type: Object,
      default: () => {},
    },

    list: {
      type: Array,
      default: () => [],
    },

    processLevel: {
      type: Array,
      default: () => [],
    },

    noBackground: {
      type: Boolean,
      default: false,
    },

    tableHeader: {
      type: Boolean,
      default: true,
    },

    formView: {
      type: Boolean,
      default: false,
    },

    workflow: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loadingBarContent: false,
      processSheet: false,
      request: {},
      stageLevel: [],
      currentStep: 0,
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
      ],
      expandTableModal: false,
      tableTitle: "",
      tableData: [],
      dummyData: true,
      requestFormData: {},
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    _columns() {
      return this.columns.filter(
        (column) => column.type !== "TITLE" && column.isVisible
      );
    },

    title() {
      return this.columns.find((column) => column.type === "TITLE");
    },

    _subWorkflowColumns() {
      let tableColumns = [
        {
          id: this.$nano.id(),
          name: "stage",
          label: "Stage",
          type: "SINGLE_SELECT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedBy",
          label: "Raised By",
          type: "SINGLE_SELECT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedAt",
          label: "Raised On",
          type: "DATE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
      ];
      return tableColumns;
    },
  },

  watch: {
    formView(newVal) {
      if (newVal === false) {
        this.openTable();
      } else if (newVal === true) {
        this.processSheet = false;
        this.openForm();
      }
    },
  },

  methods: {
    computedBackgroundColor() {
      const color =
        this.darkTheme !== undefined
          ? this.darkTheme
            ? "#1c313a"
            : "#e0f5fa"
          : "#e0f5fa";
      return color;
    },

    expandSubWorkflow(item) {
      item.displaySubTable = !item.displaySubTable;
      this.dummyData = !this.dummyData;
    },

    openSubSheet(item) {
      this.$emit("opensubprocess", item);
    },

    openForm() {
      this.$emit("update:formView", true);
      this.$emit("open", this.request);
    },

    actionColor(action) {
      if (this.darkTheme) {
        if (!action) {
          return "";
        }
        if (action.toLowerCase().indexOf("reply") !== -1) {
          return "5px solid yellow";
        } else if (action.toLowerCase().indexOf("forward") !== -1) {
          return "5px solid orange";
        } else if (action.toLowerCase().indexOf("approve") !== -1) {
          return "5px solid green";
        } else if (action.toLowerCase().indexOf("reject") !== -1) {
          return "5px solid red";
        } else {
          return "";
        }
      } else {
        if (!action) {
          return "";
        }
        if (action.toLowerCase().indexOf("reply") !== -1) {
          return "5px solid yellow";
        } else if (action.toLowerCase().indexOf("forward") !== -1) {
          return "5px solid orange";
        } else if (action.toLowerCase().indexOf("approve") !== -1) {
          return "5px solid green";
        } else if (action.toLowerCase().indexOf("reject") !== -1) {
          return "5px solid red";
        } else {
          return "";
        }
      }
    },

    async openTable() {
      this.$emit("update:formView", false);
      this.loadingBarContent = true;
      await this.getAttachments();
      await this.getComments();
      await this.processHistory();
      this.processSheet = true;
      this.loadingBarContent = false;
    },

    transformFormDataToPanelsArray(formData, panels) {
      const transformedFormData = {
        formId: formData.formId,
        formEntryId: formData.formEntryId,
        panels: [],
      };

      let wflowJson = JSON.parse(this.workflow.flowJson);
      let secureControls = [];
      wflowJson.blocks.forEach((block) => {
        // console.log(block.settings.initiateBy);
        if (block.settings.initiateBy.indexOf("PORTAL") > -1) {
          // console.log(block.settings.formSecureControls);
          if (
            block.settings.formSecureControls &&
            block.settings.formSecureControls.length
          ) {
            secureControls.push(...block.settings.formSecureControls);
          }
        }
      });
      // console.log(secureControls);

      panels.forEach((panel, index) => {
        // console.log(secureControls.indexOf(panel.id));
        if (secureControls.indexOf(panel.id) === -1) {
          const panelTitle = panel.settings.title;
          const panelFields = panel.fields;

          const panelData = {
            name: panelTitle,
            panelsExpandView: index === 0,
            fields: {},
          };

          panelFields.forEach((field) => {
            const fieldId = field.id;
            const fieldValue = formData.fields[fieldId] || "";
            panelData.fields[fieldId] = fieldValue;
          });

          transformedFormData.panels.push(panelData);
        }
      });

      return transformedFormData;
    },

    async openSheet(id) {
      this.request = this.list.find((item) => item.processId === id);
      if (this.request.inbox) {
        this.$emit("update:formView", true);
        this.$emit("open", this.request);
      } else {
        this.$emit("update:formView", false);
        this.loadingBarContent = true;
        await this.getAttachments();
        await this.getComments();
        await this.processHistory();
        this.processSheet = true;
        this.loadingBarContent = false;
      }
      this.requestFormData = await this.transformFormDataToPanelsArray(
        this.request.formData,
        this.form.panels
      );
    },

    validateType(key) {
      let fieldType = "";
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        let formField = panel.fields.find((field) => field.id === key);
        if (formField) {
          fieldType = formField.type;
        }
      });
      return fieldType;
    },

    getLabel(id, type) {
      let controlLabel = "";
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (type === "table") {
            const column = field.settings.specific.tableColumns.find(
              (column) => column.id === id
            );
            if (column) {
              controlLabel = column.label;
            }
          } else {
            if (field.id === id && field.settings.general.hideLabel === false) {
              controlLabel = field.label;
              return;
            }
          }
        }
      });
      return controlLabel;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    tableValue(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },

    tableColumns(value, key) {
      // console.log(key);

      let fields = [];
      for (const panel of this.form.panels) {
        fields.push(...panel.fields);
      }

      let field = fields.find((column) => column.id === key);
      let tblColumns = [];
      // console.log(fields, field);
      field.settings.specific.tableColumns.forEach((col) => {
        if (col.settings.general.visibility !== "DISABLE") {
          tblColumns.push(col);
        }
      });
      let columns = [];
      let _tblColumns = [];

      for (let key1 in this.tableValue(value)[0]) {
        columns.push(key1);
      }

      tblColumns.forEach((col) => {
        // console.log(columns.indexOf(col.id));
        if (columns.indexOf(col.id) > -1) {
          _tblColumns.push(col);
        }
      });

      return _tblColumns;
    },

    viewFile(file, item) {
      file.createdBy = item.createdBy;
      file.createdAt = item.createdAt;
      if (!file.name) {
        file.name = file.fileName;
      }
      if (!file.id) {
        file.id = file.itemId;
      }
      this.$emit("viewFile", file);
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    showTable(name, value) {
      this.tableData = [];
      this.tableTitle = name;
      let data = JSON.parse(value);
      for (let item of data) {
        let row = {};
        for (let key of Object.keys(item)) {
          const label = this.getLabel(key, "table");
          row[label] = item[key];
        }
        this.tableData.push(row);
      }
      this.expandTableModal = true;
    },

    async getAttachments() {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getAttachments(
        this.request.id,
        this.request.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = payload.length;
          }
        });
      }
    },

    async getComments() {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        this.request.id,
        this.request.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = payload.length;
          }
        });
      }
    },

    async processHistory() {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.processHistory(
        this.request.id,
        this.request.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.stageLevel = [];
        this.currentStep = 0;
        let activityId = "";
        payload.forEach((row) => {
          if (row.actionStatus !== 2 && activityId !== row.activityId) {
            activityId = row.activityId;
            this.stageLevel.push({
              id: row.activityId,
              label: row.stage,
              status: row.status,
            });
            if (
              ((row.actionUser || row.subWorkflowHistory) && row.status) ||
              (row.actionUser === null && !row.subWorkflowHistory)
            ) {
              this.currentStep += 1;
            }
          }
          // if (row.actionStatus !== 2) {
          //   if (
          //     this.stageLevel.filter((stage) => stage.id === row.activityId)
          //       .length === 0
          //   ) {
          //     this.stageLevel.push({
          //       id: row.activityId,
          //       label: row.stage,
          //       status: row.status,
          //     });
          //     if (
          //       ((row.actionUser || row.subWorkflowHistory) &&
          //         row.status &&
          //         row.status !== "Forward") ||
          //       (row.actionUser === null && !row.subWorkflowHistory)
          //     ) {
          //       this.currentStep += 1;
          //     }
          //   } else if (row.status) {
          //     if (
          //       this.stageLevel.filter(
          //         (stage) =>
          //           stage.id === row.activityId && stage.status !== row.status
          //       ).length
          //     ) {
          //       this.currentStep += 1;
          //     } else {
          //       this.currentStep -= 1;
          //     }
          //   } else {
          //     let index = this.stageLevel.findIndex(
          //       (stage) => stage.id === row.activityId
          //     );
          //     if (index > -1) {
          //       this.stageLevel.splice(index, this.stageLevel.length - index);
          //       this.stageLevel.push({
          //         id: row.activityId,
          //         label: row.stage,
          //         status: row.status,
          //       });
          //       if (
          //         (row.actionUser && row.status && row.status !== "Forward") ||
          //         row.actionUser === null
          //       ) {
          //         this.currentStep = index + 1;
          //       } else {
          //         this.currentStep = index;
          //       }
          //     }
          //   }
          // }
        });
      }
    },

    isVisible(key) {
      const columns = this._columns;
      const columnIdx = columns.findIndex((column) => column.name === key);

      if (columnIdx > -1) {
        return columns[columnIdx].isVisible;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
#list {
  .icon-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.5rem;
    outline: none;
  }

  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -20px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  table {
    table-layout: fixed;
    max-width: 100%;
    &:not(.subWorkflowTable) {
      min-width: calc(100% - 1px);
    }
    border-collapse: collapse;
    white-space: nowrap;

    thead {
      tr {
        height: 36px;

        th {
          @extend .text-xs;
          padding: 8px 8px 16px;
          color: var(--icon-color-inverted);
          text-align: left;
          font-weight: normal;
          background-color: var(--body-bg-color);

          &.sticky {
            background-color: var(--body-bg-color);
            position: sticky;
          }
        }
      }
    }

    tbody {
      tr {
        border-top: 1px solid var(--divider-color);

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          border-top: none;
        }

        td {
          padding: 8px;
          height: 52px;

          &.sticky {
            background-color: var(--component-bg-color);
            position: sticky;
            z-index: 1;
          }

          :deep .stat {
            text-decoration: underline;

            &:hover {
              color: var(--secondary);
            }
          }

          .link-text {
            color: #007bff; /* Set the text color to a standard link color (blue in this case) */
            text-decoration: underline; /* Underline the text to indicate it's a link */
            cursor: pointer; /* Change the cursor to a hand icon on hover, indicating it's clickable */
          }

          .link-text:hover {
            color: #0056b3; /* Change the text color on hover for better user feedback */
          }
        }
      }

      .ellipsis:hover {
        width: 200px;
        overflow: visible;
        white-space: normal;
        word-break: break-all;
      }
    }
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .text-underline {
    text-decoration: underline;
  }
}
#sheet {
  #requestDetails {
    table {
      &.submittedData {
        background: $primary-1;
      }
      &.mainData {
        table-layout: fixed;
      }
      width: 100%;
      border-radius: 10px;
      border: 1px solid var(--divider-color);

      tr {
        height: 36px;

        th {
          color: $primary-11;
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
        font-weight: 500;
      }

      th:not(:last-child),
      td:not(:last-child) {
        border-right: 1px solid var(--divider-color);
      }

      tr:not(:last-child) {
        td,
        th {
          border-bottom: 1px solid var(--divider-color);
        }
      }

      .tblHeader {
        color: $primary-11;
        font-weight: 500;
        &.small {
          width: 200px;
        }
      }
    }

    .ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .mini-avatar {
      width: 18px;
      height: 18px;
    }

    .text-underline {
      text-decoration: underline;
    }
  }

  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }

  .row .q-dark .q-stepper__title {
    color: white;
  }
  .row .q-light {
    .q-stepper--horizontal .q-stepper__line:before,
    .q-stepper--horizontal .q-stepper__line:after {
      background: rgb(0 0 0 / 12%);
    }
  }

  .row .q-dark {
    .q-stepper--horizontal .q-stepper__line:before,
    .q-stepper--horizontal .q-stepper__line:after {
      background: #0074ff;
    }
  }

  .row .q-stepper.q-stepper--horizontal {
    background: var(--component-bg-color) !important;
  }
}

#modal #tableData {
  table {
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid var(--divider-color);
    border-radius: 10px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th {
      border-bottom: 1px solid var(--divider-color);
    }

    tr:not(:first-child) td {
      border-top: 1px solid var(--divider-color); /* Add top border to the first row */
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color); /* Add top border to the first row */
    }
  }
}

.mini-avatar {
  width: 18px !important;
  height: 18px !important;
}

.text-underline {
  text-decoration: underline;
}

// #portalList {
//   #list {
//     table {
//       th:hover,
//       tr {
//         td:nth-child(2) {
//           overflow: hidden !important;
//           white-space: unset !important;
//         }
//       }
//     }
//   }
// }
</style>
