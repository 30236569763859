<template>
  <div id="entity-browse">
    <Portal to="primary-action">
      <BaseButton
        v-if="showUploadFiles"
        icon="eva-upload"
        label="upload files"
        class="q-ml-md"
        @click="uploadFiles"
      />
    </Portal>
    <keep-alive>
      <Portal to="action-bar">
        <ItemActions
          v-if="!isLoading"
          entity="entity"
          :hide-actions="activeView === 'GRID' ? ['sort', 'group'] : ['group']"
          :columns.sync="repositoryColumns"
          :filter-columns="repositoryColumns"
          :group-by.sync="groupBy"
          :order.sync="sortBy.order"
          :filter-by.sync="filterBy"
          :selected-filter="breadcrumbs"
          :active-view.sync="activeView"
          :criteria.sync="sortBy.criteria"
          :thumbnail-view="entityType === 'FILE'"
          :search-content="true"
          :content-search.sync="contentSearch"
          :repository-fields="true"
          :workflow-initiate="workflows.length ? true : false"
          :initiate-access="initiateAccess"
          :goto-inbox="workflows.length ? true : false"
          module="repository"
          :module-id="repositoryId"
          :download-all="itemSelected"
          :simple-search-applyed.sync="simpleSearchApplyed"
          :visible-download="download"
          :visible-print="print"
          :visible-email="email"
          :visible-delete="deleteFile"
          @update="applyFilter"
          @refresh="refreshData"
          @search="searchRow"
          @viewColumns="saveViewColumns"
          @initiate="initiateRequest"
          @inbox="gotoInbox"
          @export="export_Data"
          @downloadAll="downloadAll"
          @printAll="printAll"
          @emailAll="emailAll"
          @deleteFileAction="deleteFileListAll"
        />
      </Portal>
    </keep-alive>

    <div v-if="!isLoading" class="content">
      <ItemActionChips
        :sort-by="sortBy"
        :columns="columns"
        :group-by="groupBy"
        :filter-by="filterBy"
        :content-search="contentSearch"
        :simple-search-applyed="simpleSearchApplyed"
        @clearSortBy="clearSortBy"
        @clearGroupBy="clearGroupBy"
        @clearFilterBy="clearFilterBy"
        @clearContentSearch="clearContentSearch"
      />

      <!-- breadcrumbs -->
      <div class="col-12">
        <div>
          <Breadcrumbs
            :breadcrumbs="breadcrumbs"
            @click="handleBreadcrumbClick"
            @reset="homeRepository"
          />
        </div>
        <q-space />
      </div>
      <!-- ... -->

      <EntityGrid
        v-if="activeView === 'GRID'"
        :mode="mode"
        :columns="columns"
        :entities="_entities"
        :hide-file-sheet="hideFileSheet"
        :delete-folder-access="deleteFolderAccess"
        @select="openRepository"
        @view="openFile"
        @more="showEntityDetails"
        @delete="showDeleteConfirmation"
        @restore="showRestoreConfirmation"
        @minimumFileSheet="minimumFileSheet"
        @revise="initiateRequest"
        @deleteFolder="showDeleteConfirmation"
      />

      <EntityList
        v-if="activeView === 'LIST'"
        :mode="mode"
        :entities="_entitiesFile"
        :columns="columns"
        :repository-id="repositoryId"
        :workflow-initiate="workflows.length ? true : false"
        :has-meta-data-edit="hasMetaDataEdit"
        :select-all="overallSelectAll"
        :visible-download="download"
        :visible-print="print"
        :visible-email="email"
        :checkbox="checkbox"
        :visible-delete="deleteFile"
        @select="openRepository"
        @view="openFile"
        @more="showEntityDetails"
        @delete="showDeleteConfirmation"
        @restore="showRestoreConfirmation"
        @minimumFileSheet="minimumFileSheet"
        @revise="initiateRequest"
        @download="fileDownload"
        @print="filePrint"
        @email="emailShare"
        @showCheckin="showCheckinUploadFile"
        @openMetaDataEdit="openMetaDataEdit"
        @update-selected-all="updateSelectAll"
        @deleteFile="deleteFileList"
      />

      <EntityThumbnail
        v-if="activeView === 'THUMBNAIL'"
        :mode="mode"
        :files="_entitiesFile"
        :columns="columns"
        :repository-id="repositoryId"
        @select="openRepository"
        @view="openFile"
        @more="showEntityDetails"
        @delete="showDeleteConfirmation"
        @restore="showRestoreConfirmation"
        @minimumFileSheet="minimumFileSheet"
      />
    </div>

    <Pagination
      v-if="!isLoading"
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
      :file-sheet="hideFileSheet"
      @loadAll="loadAll"
    />

    <EntityDetails
      v-if="isEntityDetailsVisible"
      v-model="isEntityDetailsVisible"
      :columns="columns"
      :entity="selectedEntity"
      :entity-type="entityType"
    />

    <ConfirmDeleteEntity
      v-model="isDeleteConfirmationVisible"
      :entity="selectedEntity"
      :entity-list="selectedDelete"
      :entity-type="entityType"
      @delete="selectedDelete.length ? deleteFiles() : deleteFolder()"
    />

    <ConfirmRestoreEntity
      v-model="isRestoreConfirmationVisible"
      :entity="selectedEntity"
      :entity-type="entityType"
      @restore="deleteFolder"
    />

    <FileSheet
      v-model="isFileSheetVisible"
      :file="selectedFile"
      :repository-field="repositoryField"
      @show-files="showFilesSheet"
      @input="refreshData"
    />

    <FileList
      v-model="isFilesSheetVisible"
      :file-list="entities.length ? entities[0].value : []"
      :selected-files="selectedFile"
      @select="openFile"
    />

    <FilePassword
      :value="showFilePasswordModal"
      :repository-id="repositoryId"
      :item-id="selectedFile.id ? selectedFile.id : 0"
      @close="openFileSheet"
      @closeModal="showFilePasswordModal = false"
    ></FilePassword>

    <InitiateProcess
      v-model="initiateProcessSheet"
      :item-file.sync="selectedFile"
      :repository-id="Number(repositoryId)"
      :repository-details="repositoryDetails"
      :repository-field="repositoryField"
      :workflow="workflows.length ? workflows[0] : {}"
    ></InitiateProcess>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="Number(repositoryId)"
      :file-list.sync="selectedFileList"
      @selecting="emailSave"
    ></EmailSharing>

    <Sheet
      v-model="showCheckinModal"
      width="470px"
      has-footer
      @input="$emit('input', false)"
    >
      <template #title> Upload File </template>

      <template #default>
        <!-- <BaseScrollbar height="calc(100vh - 219px)"> -->
        <Dropzone
          v-if="!filesToUpload.length"
          :show-workspace-repository="false"
          :workspace-id="Number($route.query.workspaceId)"
          :repository-id="Number(repositoryId)"
          :has-multiple="false"
          @upload="queueFilesForUpload"
        />

        <FormFieldError
          v-if="filesError"
          class="q-mx-md"
          error="Please upload file"
        />

        <UploadingFileList
          v-if="filesToUpload.length"
          :files="filesToUpload"
          :show-progress="showProgress"
          :show-delete-btn="showDeleteBtn"
          @delete="deleteUploadFile"
        />
        <div class="q-px-md">
          <ValidationObserver ref="checkin">
            <!-- field -->

            <ValidationProvider
              v-slot="{ errors }"
              name="comment"
              :rules="{ required: true }"
            >
              <TextAreaField
                v-model="comments"
                label="Purpose for checking in"
                is-mandatory
                :error="errors[0]"
                class="q-mb-lg"
              />
            </ValidationProvider>

            <!-- ... -->
          </ValidationObserver>
        </div>
      </template>

      <template #footer>
        <BaseButton is-flat label="clear" class="q-mr-sm" @click="reset" />

        <BaseButton label="save" @click="uploadFile" />
      </template>
    </Sheet>

    <Modal
      v-model="openMetaDataEditModal"
      has-footer
      width="440px"
      height="390px"
      @input="closeModal"
    >
      <!-- title -->

      <template #title>{{ selectColumn.label }} Edit</template>

      <!-- ... -->

      <template #default>
        <ValidationObserver ref="form">
          <TextField
            :value="selectRow[selectColumn.name]"
            label="Old value"
            is-readonly
            class="q-mb-lg"
          />
          <!-- <ValidationProvider
            v-slot="{ errors }"
            name="New value"
            :rules="{ required: true }"
          >
            <TextField
              v-model="newValue"
              label="New value"
              is-mandatory
              :error="errors[0]"
              class="q-my-lg"
            />
          </ValidationProvider> -->

          <RepositoryField
            v-model="newValue"
            :field="selectField"
            :repository-id="repositoryId"
            label="New Value"
          />
          <FormFieldError
            v-if="newValueError"
            error="New value should not be empty"
          />
        </ValidationObserver>
      </template>

      <!-- footer -->

      <template #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          @click="closeModal"
        />

        <BaseButton label="save" @click="saveMetaData" />
      </template>

      <!-- ... -->
    </Modal>
  </div>
</template>

<script>
import { repository, workflow, menu } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import EntityGrid from "./components/EntityGrid.vue";
import EntityList from "./components/EntityList.vue";
import EntityThumbnail from "./components/EntityThumbnail.vue";
import EntityDetails from "./components/EntityDetails.vue";
import ConfirmDeleteEntity from "./components/ConfirmDeleteEntity.vue";
import ConfirmRestoreEntity from "./components/ConfirmRestoreEntity.vue";
import FileSheet from "./components/FileSheet.vue";
import FileList from "./components/FileList.vue";
import formatBytes from "@/helpers/format-bytes.js";
import Breadcrumbs from "./components/Breadcrumbs.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import FilePassword from "./components/FilePassword";
import InitiateProcess from "./components/InitiateProcess";
import EmailSharing from "@/components/common/EmailSharing.vue";
import { exportData } from "@/helpers/export-excel-data";
import Sheet from "@/components/common/popup/Sheet.vue";
import Dropzone from "@/views/upload-and-index/views/uploader/components/Dropzone";
import UploadingFileList from "@/views/upload-and-index/views/uploader/components/UploadingFileList";
import { fileSupport } from "@/helpers/file-format.js";
import axios from "axios";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import RepositoryField from "@/views/upload-and-index/views/indexer/components/repository-fields/components/RepositoryField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import Modal from "@/components/common/popup/Modal.vue";

export default {
  name: "RepositoryBrowseAndTrash",

  components: {
    Portal,
    ItemActions,
    EntityGrid,
    EntityList,
    EntityThumbnail,
    EntityDetails,
    Pagination,
    ConfirmDeleteEntity,
    ConfirmRestoreEntity,
    FileSheet,
    FileList,
    Breadcrumbs,
    ItemActionChips,
    FilePassword,
    InitiateProcess,
    EmailSharing,
    Sheet,
    Dropzone,
    UploadingFileList,
    TextAreaField,
    ValidationObserver,
    ValidationProvider,
    FormFieldError,
    RepositoryField,
    TextField,
    Modal,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },

    fullFileSheet: {
      type: Object,
      default: () => {},
    },

    hideFileSheet: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      workspaceId: 0,
      repositoryId: 0,
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
          fieldId: 0,
        },
        {
          id: this.$nano.id(),
          name: "level",
          label: "Level",
          type: "NORMAL",
          isVisible: false,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.NUMBER,
          isFilter: false,
          fieldId: 0,
        },
        {
          id: this.$nano.id(),
          name: "files",
          label: "Files",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
          fieldId: 0,
        },
        {
          id: this.$nano.id(),
          name: "size",
          label: "Size",
          type: "NORMAL",
          isVisible: false,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
          fieldId: 0,
        },
        {
          id: this.$nano.id(),
          name: "version",
          label: "Version",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
          fieldId: 0,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
          isFilter: true,
          fieldId: 0,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
          isFilter: true,
          fieldId: 0,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
          fieldId: 0,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
          isFilter: false,
          fieldId: 0,
        },
      ],
      repositoryColumns: [],
      entities: [],
      activeView: "LIST",
      selectedEntity: {},
      isEntityDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "ASC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      isFileSheetVisible: false,
      parentNodeId: 0,
      breadcrumbs: [],
      repositoryDetails: {},
      repositoryField: [],
      selectedFile: {},
      currentLevel: 0,
      entitiesData: [],
      isFilesSheetVisible: false,
      maxLevel: 0,
      fieldlist: "[]",
      EntitiesList: [],
      columnsGrid: [],
      showFilePasswordModal: false,
      options: {},
      entitiesListData: [],
      initiateAccess: false,
      workflows: [],
      initiateProcessSheet: false,
      emailSharingSheet: false,
      selectedFileList: [],
      contentSearch: {
        contentSearchValue: "",
        fuzzy: 0,
        searchType: 1,
      },
      simpleSearchApplyed: false,

      showCheckinModal: false,
      filesToUpload: [],
      comments: "",
      showProgress: false,
      filesError: false,
      showDeleteBtn: true,
      selectColumn: {},
      selectRow: {},
      openMetaDataEditModal: false,
      newValue: "",
      selectField: {},
      newValueError: false,
      hasMetaDataEdit: false,
      overallSelectAll: false,
      itemSelected: false,
      isAllSelected: false,
      download: false,
      print: false,
      email: false,
      checkbox: false,
      deleteFile: false,
      selectedDelete: [],
      deleteFileListsAll: [],
      deleteFolderAccess: false,
    };
  },

  computed: {
    _entities() {
      return this.entities.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((entity) => ({
          id: entity.id,
          name: entity.name,
          level: entity.level,
          type: entity.type,
          files: entity.itemsCount,
          size: "", //entity.size ? formatBytes(entity.size) : "",
          version: Number(entity.fileVersion),
          maxVersion: Number(entity.maxVersion),
          icon: "eva-folder",
          isPassword: entity.hasPassword,
          passwordCreatedBy: entity.encryptedBy,
          createdBy: entity.createdBy,
          createdAt: this.$day.format(entity.createdAt),
          modifiedBy: entity.modifiedBy,
          modifiedAt: entity.modifiedAt
            ? this.$day.format(entity.modifiedAt)
            : "",
          stageStatus: entity.stageStatus,
          revision: entity.revision,
          isNewVersion: entity.isNewVersion,
          checkinStatus: entity.checkinStatus,
          comments: entity.comments,
        })),
      }));
    },

    _entitiesFile() {
      return this.entities.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((entity) => ({
          id: entity.id,
          name: entity.name,
          link: true,
          level: entity.level,
          type: entity.type,
          files: entity.itemsCount,
          size: entity.size ? formatBytes(entity.size) : "",
          version: Number(entity.fileVersion),
          maxVersion: Number(entity.maxVersion),
          icon: "eva-folder",
          isPassword: entity.hasPassword,
          passwordCreatedBy: entity.encryptedBy,
          createdBy: entity.createdBy,
          createdAt: this.$day.format(entity.createdAt),
          modifiedBy: entity.modifiedBy,
          modifiedAt: entity.modifiedAt
            ? this.$day.format(entity.modifiedAt)
            : "",
          stageStatus: entity.stageStatus,
          revision: entity.revision,
          isNewVersion: entity.isNewVersion,
          checkinStatus: entity.checkinStatus,
          comments: entity.comments,
          checkinBy: entity.checkinBy,
          ...this.getFoldersFieldsData(entity),
        })),
      }));
    },

    entityType() {
      if (!this.entities.length) {
        return "FOLDER";
      }

      return this.entities[0].value.length
        ? this.entities[0].value[0].type
        : "";
    },

    showUploadFiles() {
      let origin = location.origin;
      if (
        (origin === "http://52.172.32.88" ||
          origin === "https://demo.ezofis.com" ||
          origin === "http://localhost:8080") &&
        this.repositoryId === "1136"
      ) {
        return false;
      } else if (
        origin === "https://edmsuat.sobhaapps.com" &&
        this.repositoryId === "80"
      ) {
        return false;
      } else if (
        origin === "https://edms.sobhaapps.com" &&
        this.repositoryId === "80"
      ) {
        return false;
      }
      if (this.$store.state.profileMenus.length)
        return this.$store.state.profileMenus.find((row) => row.folders).Menu[
          "Upload File"
        ];

      return true;
    },

    _repositoryField() {
      return this.repositoryField.map((field) => ({
        ...field,
        options: field.options ? JSON.parse(field.options) : [],
      }));
    },
  },

  watch: {
    fullFileSheet: {
      deep: true,
      handler() {
        if (this.fullFileSheet) {
          // this.selectedFile = this.fullFileSheet;
          this.selectedFile = this.entities[0].value.find(
            (file) => file.id === this.fullFileSheet.id
          );
          this.isFileSheetVisible = true;
        }
      },
    },

    activeView: {
      immediate: true,
      deep: true,
      handler() {
        this.entities = [];
        if (this.repositoryId) {
          if (this.activeView === "GRID") {
            this.getEntities();
          } else {
            this.getEntitiesList();
          }
        }
      },
    },

    $route: {
      immediate: true,
      deep: true,
      async handler() {
        this.defaultView();
        this.workspaceId = this.$route.query.workspaceId;
        this.repositoryId = this.$route.query.repositoryId;
        await this.getRepository();
        await this.getWorkflows();
        this.activeView === "GRID"
          ? this.getEntities()
          : this.getEntitiesList();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },

    repositoryId() {
      if (this.repositoryId) {
        this.getFolderSecurity();
      }
    },
  },

  async mounted() {
    // this.workspaceId = this.$route.query.workspaceId;
    // this.repositoryId = this.$route.query.repositoryId;
    //await this.getRepository();
    this.$watch(
      (vm) => [vm.mode, vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () =>
        this.activeView === "GRID"
          ? this.getEntities()
          : this.getEntitiesList(),
      {
        immediate: true,
        deep: true,
      }
    );
  },

  created() {
    //this.getFolderSecurity();
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      // let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      let subMenu1 = this.$route.meta.subMenu;
      console.log(JSON.stringify(options));
      console.log(subMenu);
      console.log(subMenu1);
      if (Object.keys(options).length) {
        if (options[subMenu1]) {
          let view = options[subMenu1].find((item) => item.subMenu === subMenu);
          if (view) {
            if (this.activeView !== view.view) {
              this.activeView = view.view;
            }
          }
        }
      }
    },

    findEntity(entity, entityId) {
      const selected = this._entities.find(
        (_entity) => _entity.data === entity
      );
      return selected.data.find((entity) => entity.id === entityId);
    },

    showEntityDetails(entity, entityId) {
      this.selectedEntity = this.findEntity(entity, entityId);
      this.isEntityDetailsVisible = true;
    },

    deleteFileList(itemId) {
      this.selectedDelete = [];
      this.isDeleteConfirmationVisible = true;
      let deleteFileLists = this.entities[0].value.find(
        (file) => file.id === itemId
      );
      let deleteFileObject = { ...deleteFileLists };
      this.selectedDelete.push(deleteFileObject);
    },

    deleteFileListAll() {
      this.selectedDelete = [];
      this.isDeleteConfirmationVisible = true;
      this._entitiesFile.forEach((group) => {
        group.data.forEach((item) => {
          if (item.selected) {
            let deleteFileLists = { id: item.id, name: item.name };
            this.selectedDelete.push(deleteFileLists);
          }
        });
      });
    },

    async deleteFiles() {
      this.$store.commit("showLoadingBar");
      this.selectedDelete.forEach((item) => {
        let deleteFileLists = item.id;
        this.deleteFileListsAll.push(deleteFileLists);
      });
      const { error, payload } = await repository.fileDelete(
        this.repositoryId,
        this.deleteFileListsAll
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.status === 200) {
        this.$alert.success(`file deleted successfully`);
        this.getEntitiesList();
      }
      this.$emit("close", false);
    },

    showDeleteConfirmation(entity, entityId) {
      this.selectedEntity = this.findEntity(entity, entityId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(entity, entityId) {
      this.selectedEntity = this.findEntity(entity, entityId);
      this.isRestoreConfirmationVisible = true;
    },

    openRepository(entityId, entity) {
      this.selectedEntity = this.findEntity(entity, entityId);
      this.parentNodeId = entityId;
      if (this.parentNodeId === 0) {
        this.repository();
      } else {
        if (this.currentPage > 1) {
          this.currentPage = 1;
        } else {
          this.getEntities();
        }
      }
    },

    uploadFiles() {
      this.$router.push({
        name: "uploadAndIndex",
        query: this.$route.query,
      });
    },

    handleBreadcrumbClick(breadcrumb) {
      if (breadcrumb.type === "REPOSITORY") {
        this.breadcrumbs = [];
        this.parentNodeId = 0;
        this.currentLevel = 1;
      } else {
        let breadcrumbsList = [];
        let match = 0;
        this.currentLevel = breadcrumb.level + 1;
        this.breadcrumbs.map((entity) => {
          if (match) {
            return;
          }
          breadcrumbsList.push(entity);
          if (entity.id === breadcrumb.id) {
            match = entity.id;
            return;
          }
        });
        this.selectedEntity = {};
        this.breadcrumbs = breadcrumbsList;
        this.parentNodeId = match;
        if (this.activeView === "THUMBNAIL") {
          //this.activeView = "GRID";
        }
      }
      if (this.parentNodeId == 0) {
        this.columns.shift();
      }

      if (this.activeView === "GRID") {
        this.getEntities("back");
      } else {
        if (breadcrumb.type === "REPOSITORY") {
          this.breadcrumbs.push({
            id: this.repositoryId,
            label: this.repositoryDetails.name,
            entity: this.selectedEntity,
            type: "REPOSITORY",
          });
        }
        this.filterBy = [];
        this.getEntitiesList();
      }
    },

    homeRepository() {
      this.$router.push({
        name: "repositories-browse",
        //query: { workspaceId: String(this.workspaceId) },
      });
      this.filterBy = [];
    },

    openFile(itemId) {
      this.selectedFile = this.entities[0].value.find(
        (file) => file.id === itemId
      );
      if (this.selectedFile.hasPassword) {
        this.showFilePasswordModal = true;
      } else {
        this.isFileSheetVisible = true;
        this.isFilesSheetVisible = false;
      }
    },

    /* api functions */

    async getRepository() {
      this.overallSelectAll = false;
      this.repositoryColumns = [];
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
      if (payload.options) {
        this.options = payload.options;
      }

      if (this.options.download === "true") {
        this.download = true;
      }

      if (this.options.emailSharing === "true") {
        this.email = true;
      }

      if (this.options.print === "true") {
        this.print = true;
      }

      if (this.options.deleteFile === "true") {
        this.deleteFile = true;
      }

      if (this.options.deleteFolder === "true") {
        this.deleteFolderAccess = true;
      }

      if (this.print || this.email || this.download || this.deleteFile) {
        this.checkbox = true;
      }

      this.repositoryField = this.repositoryDetails.fields;
      this.maxLevel = this.repositoryDetails.fields.filter(
        (field) => field.level != 0
      ).length;
      if (this.repositoryDetails) {
        this.repositoryColumns = [];
        if (this.repositoryDetails.fields) {
          this.repositoryDetails.fields.forEach((field) => {
            this.repositoryColumns.push({
              id: this.$nano.id(),
              name: field.name,
              label: field.name,
              fieldId: field.id,
              type: "NORMAL",
              isVisible: field.visible,
              isSortable: true,
              isGroupable: false,
              isFilter: true,
              dataType: field.dataType,
              display: "",
              apiLoad: true,
            });
          });
        }
      }
    },

    applyFilter(filter) {
      if (this.activeView === "GRID") {
        this.getEntities(filter);
      } else if (this.activeView === "LIST") {
        this.getEntitiesList();
      }
    },

    loadAll() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    async getEntities(filter) {
      if (!Object.keys(this.repositoryDetails).length) {
        return;
      }
      if (Array.isArray(filter)) this.filterBy = filter;
      this.$store.commit("showLoadingBarPage");
      let input = {};
      if (filter) {
        let level = 1;
        if (Array.isArray(filter)) {
          this.breadcrumbs.forEach((tab) => {
            if (tab.fieldName) {
              filter.forEach((group) => {
                let index = group.filters.findIndex(
                  (item) => item.criteria === tab.fieldName
                );
                if (index !== -1) {
                  level += 1;
                  return;
                }
              });
            }
          });
        } else {
          level = this.currentLevel;
        }

        input = {
          repositoryId: this.repositoryId,
          parentNodeId: this.parentNodeId,
          sortBy: this.sortBy,
          groupBy: this.groupBy,
          filterBy: Array.isArray(filter) ? filter : this.filterBy,
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage,
          mode: this.mode,
          level: level,
          ...this.contentSearch,
        };
      } else {
        input = {
          repositoryId: this.repositoryId,
          parentNodeId: this.parentNodeId,
          sortBy: this.sortBy,
          groupBy: this.groupBy,
          filterBy: Array.isArray(filter) ? filter : this.filterBy,
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage,
          mode: this.mode,
        };
      }

      const { error, payload } = await repository.getEntities(input);

      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.parentNodeId === 0 && this.repositoryDetails) {
        this.breadcrumbs = [];
        this.breadcrumbs.push({
          id: this.repositoryId,
          label: this.repositoryDetails.name,
          entity: this.selectedEntity,
          type: "REPOSITORY",
        });
      } else if (this.selectedEntity.type === "FOLDER") {
        let isIndex = this.breadcrumbs.findIndex((item) => {
          return item.id === this.selectedEntity.id;
        });

        let hasName = this.repositoryField.find(
          (item) => item.level === this.selectedEntity.level
        );
        if (isIndex === -1) {
          this.breadcrumbs.push({
            id: this.selectedEntity.id,
            label: this.selectedEntity.name,
            entity: this.selectedEntity,
            type: "FOLDER",
            fieldName: hasName.name,
            level: this.selectedEntity.level,
          });
        }
      }

      this.totalItems = payload.meta?.totalItems || 0;
      if (payload.data.length) {
        if (payload.data[0].value.length) {
          if (payload.data[0].value[0].level) {
            this.currentLevel = payload.data[0].value[0].level;
          }
        }
      } else {
        this.totalItems = 0;
      }
      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.entities = [];
        this.entitiesData = [];
      } else {
        this.entities = payload.data;
        this.entitiesData = payload.data;
      }
    },

    async getEntitiesList() {
      if (!Object.keys(this.repositoryDetails).length) {
        return;
      }
      let filterCondition = [
        {
          filters: [],
          groupCondition: "",
        },
      ];

      if (this.breadcrumbs.length === 0) {
        this.breadcrumbs.push({
          id: this.repositoryId,
          label: this.repositoryDetails.name,
          entity: this.selectedEntity,
          type: "REPOSITORY",
        });
      }

      if (this.breadcrumbs.length > 1) {
        this.breadcrumbs.forEach((row) => {
          if (row.type != "REPOSITORY") {
            filterCondition[0].filters.push({
              criteria: row.fieldName,
              condition: "IS_EQUALS_TO",
              value: row.label,
            });
          }
        });
      } else {
        filterCondition[0].filters.push({
          criteria: "isdeleted",
          condition: "IS_EQUALS_TO",
          value: "0",
        });
      }

      let input = {};
      input = {
        repositoryId: this.repositoryId,
        parentNodeId: this.parentNodeId,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy.length ? this.filterBy : filterCondition,
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
        mode: this.mode,
        level: this.currentLevel,
        ...this.contentSearch,
      };
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await repository.getEntities(input);
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$store.commit("hideLoadingBarPage");
      let additionalInfoColumns = [];
      let origin = location.origin;
      if (
        ((origin === "http://52.172.32.88" ||
          origin === "https://demo.ezofis.com" ||
          origin === "http://localhost:8080") &&
          this.repositoryId === "1136") ||
        ((origin === "https://edmsuat.sobhaapps.com" ||
          origin === "http://localhost:8080") &&
          this.repositoryId === "80") ||
        (origin === "https://edms.sobhaapps.com" && this.repositoryId === "80")
      ) {
        additionalInfoColumns = [];
        if (this.workflows.length) {
          additionalInfoColumns.push({
            id: this.$nano.id(),
            name: "revise",
            label: "Submit Revision",
            type: "REVISE",
            isVisible: true,
            isSortable: false,
            isGroupable: false,
            dataType: DataType.SHORT_TEXT,
            isFilter: false,
            fieldId: 0,
            addInfo: true,
          });
        }
        additionalInfoColumns.push({
          id: this.$nano.id(),
          name: "status",
          label: "Workflow Status",
          type: "STATUS",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
          fieldId: 0,
          addInfo: true,
        });
        additionalInfoColumns.push({
          id: this.$nano.id(),
          name: "revision",
          label: "Revision",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
          fieldId: 0,
          addInfo: true,
        });
      }

      this.columns = this.columns.filter(
        (field) => field.fieldId === 0 && !field.addInfo
      );
      let listColumns = [];
      this.repositoryDetails.fields.forEach((field) => {
        if (field.visible) {
          listColumns.push({
            id: this.$nano.id(),
            name: field.name,
            label: field.name,
            type: "NORMAL",
            isVisible: field.visible,
            isSortable: true,
            isGroupable: false,
            isFilter: true,
            dataType: field.dataType,
            fieldId: field.id,
          });
        }
      });
      this.columns = [
        ...additionalInfoColumns,
        ...listColumns,
        ...this.columns,
      ];
      this.entities = payload.data;
      this.entitiesData = payload.data;
      this.entitiesListData = payload.data;

      this.totalItems = payload.meta?.totalItems || 0;
    },

    async deleteFolder() {
      this.$store.commit("showLoadingBarPage");

      const { error } = await repository.updateEntity(
        this.$store.state.session.tenantId,
        this.repositoryId,
        this.selectedEntity.id
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(
        `Entity "${this.selectedEntity.name}" deleted successfully`
      );

      this.getEntities();
    },

    async getRepositoryField() {
      const { error, payload } = await repository.getRepositoryField({
        criteria: "repositoryId",
        value: this.repositoryId,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryField = payload;
    },

    /* ... */

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "ASC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    minimumFileSheet(file) {
      if (file.isPassword) {
        this.selectedFile = file;
        this.showFilePasswordModal = true;
      } else {
        if (this.activeView === "LIST") {
          this.isFileSheetVisible = true;
          this.isFilesSheetVisible = false;
        } else {
          this.$emit("minimumFileSheet", file);
        }
      }
    },

    refreshData() {
      if (this.activeView === "GRID") {
        this.getEntities();
      } else {
        this.getEntitiesList();
      }
    },

    searchRow(search) {
      if (this.activeView === "GRID") {
        if (search) {
          this.entities = this.entitiesData.map(({ key, value }) => ({
            key: key,
            value: value.filter((row) => {
              for (let cell in row) {
                let index = this.columns.findIndex((column) => {
                  return column.name === cell;
                });

                if (index >= 0 && row[cell]) {
                  if (
                    String(row[cell])
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return row;
                  }
                }
              }
            }),
          }));
        } else {
          this.entities = this.entitiesData;
        }
      } else {
        if (search) {
          let columns = this.columns;
          columns.push({
            dataType: "SHORT_TEXT",
            fieldId: 0,
            id: this.$nano.id(),
            isGroupable: false,
            isSortable: false,
            isVisible: false,
            label: "fieldlist",
            name: "fieldlist",
            type: "NORMAL",
          });
          this.entities = this.entitiesListData.map(({ key, value }) => ({
            key: key,
            value: value.filter((row) => {
              for (let cell in row) {
                let index = columns.findIndex((column) => {
                  return column.name === cell;
                });

                if (index >= 0 && row[cell]) {
                  if (
                    String(row[cell])
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return row;
                  }
                }
              }
            }),

            // value: value.reduce((acc, element) => {
            //   let obj = {
            //     ...element,
            //     fieldlist: JSON.stringify(
            //       JSON.parse(element.fieldlist).filter((subElement) =>
            //         subElement.value
            //           .toLowerCase()
            //           .includes(search.toLowerCase())
            //       )
            //     ),
            //   };
            //   // console.log(obj);
            //
            //   if (JSON.parse(obj.fieldlist).length) {
            //     acc.push(obj);
            //   }
            // return acc;
            // }, []),
          }));
        } else {
          this.entities = this.entitiesListData;
        }
      }
    },

    showFilesSheet() {
      this.isFilesSheetVisible = true;
    },

    openFileSheet() {
      this.showFilePasswordModal = false;
      if (this.activeView === "GRID") {
        this.$emit("minimumFileSheet", this.selectedFile);
      } else {
        this.isFileSheetVisible = true;
        this.isFilesSheetVisible = false;
      }
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) this.filterBy = [];
      if (this.activeView === "GRID") {
        this.getEntities();
      } else {
        this.getEntitiesList();
      }
    },

    async saveViewColumns(columns) {
      this.repositoryDetails.fields.forEach((field) => {
        let viewColumn = columns.find((col) => col.Fieldid == field.id);
        if (viewColumn) {
          field.visible = false;
        } else {
          field.visible = true;
        }
      });
      const { error } = await repository.setViewColumns({
        repositoryId: this.$route.query.repositoryId,
        fieldList: columns,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success("Updated successfully");
      this.getEntitiesList();
    },

    getFoldersFieldsData(entity) {
      const data = {};
      if (entity.fieldlist) {
        let fieldList = JSON.parse(entity.fieldlist);
        this.repositoryColumns.forEach((field) => {
          let fieldRow = fieldList.filter((row) => row.fieldId === field.name);
          if (fieldRow.length) {
            data[field.name] = fieldRow[0].value;
          }
        });
      }
      return data;
    },

    clearContentSearch() {
      this.contentSearch = {
        contentSearchValue: "",
        fuzzy: 0,
        searchType: 1,
      };
      if (this.activeView === "GRID") {
        this.getEntities();
      } else {
        this.getEntitiesList();
      }
    },

    gotoInbox() {
      this.$router.push({ name: "workflows-inbox" });
    },

    initiateRequest(itemId = 0) {
      this.selectedFile = {};
      if (itemId) {
        this.selectedFile = this.entities[0].value.find(
          (file) => file.id === itemId
        );
      }
      this.initiateProcessSheet = true;
    },

    fileDownload(itemId) {
      let link = "";
      link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.repositoryId}/${itemId}/2`;
      window.open(link, "_blank");
    },

    filePrint(itemId) {
      let link = "";
      link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.repositoryId}/${itemId}/2`;
      window.open(link, "_blank");
      this.filePrintSession(itemId);
    },

    emailShare(item) {
      this.selectedFileList = [];
      this.selectedFileList.push({
        id: item.id,
        name: item.name,
      });
      this.emailSharingSheet = true;
    },

    emailAll() {
      this.selectedFileList = [];
      this._entitiesFile.forEach((group) => {
        group.data.forEach((item) => {
          if (item.selected) {
            this.selectedFileList.push({ id: item.id, name: item.name });
          }
        });
      });
      this.emailSharingSheet = true;
    },

    emailSave() {
      this.getRepository();
      this.itemSelected = false;
      this.isAllSelected = false;
      this.selectedFileList = [];
    },

    async filePrintSession(itemId) {
      const { error } = await menu.filePrintSession({
        repositoryId: this.repositoryId,
        itemId: itemId,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getWorkflows() {
      this.workflows = [];
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getWorkflows({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "initiatedBy",
                condition: "CONTAINS",
                value: "Document",
                dataType: "",
              },
              {
                criteria: "flowstatus",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
              {
                criteria: "repositoryId",
                condition: "IS_EQUALS_TO",
                value: this.repositoryId,
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 0,
        currentPage: 0,
        hasSecurity: false,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          data[0].value.forEach(async (workflow) => {
            if (workflow.id === 2092) {
              let workflowDet = await this.getWorkflowById(workflow.id);
              let flowJson = JSON.parse(workflowDet.flowJson);
              this.initiateAccess = false;
              if (
                flowJson.settings.general.initiateUsing.type === "DOCUMENT_FORM"
              ) {
                this.initiateAccess = true;
              }
              let startBlock = flowJson.blocks.find(
                (block) => block.type === "START"
              );
              if (startBlock) {
                if (startBlock.settings.initiateBy.indexOf("USER") > -1) {
                  let initiateRights = false;
                  if (
                    startBlock.settings.users.indexOf(
                      this.$store.state.session.id
                    ) > -1 ||
                    startBlock.settings.users.indexOf("0") > -1
                  ) {
                    initiateRights = true;
                  }
                  if (
                    startBlock.settings.initiateBy.indexOf("DOMAIN_USER") > -1
                  ) {
                    let domain = this.$store.state.session.email
                      .split("@")
                      .pop();
                    if (startBlock.settings.userDomains.indexOf(domain) > -1) {
                      initiateRights = true;
                    }
                  }
                  if (initiateRights) {
                    let review = "";
                    let startRule = flowJson.rules.find(
                      (rule) => rule.fromBlockId === startBlock.id
                    );
                    if (startRule) {
                      review = startRule.proceedAction;
                    }
                    this.workflows.push({
                      id: workflow.id,
                      name: workflow.name,
                      review: review,
                      initiateBy: workflow.initiatedBy,
                      formId: workflow.wFormId,
                      flowJson: workflow.flowJson,
                      repositoryId: this.repositoryId,
                      generatePDF: startBlock.settings.generatePDF ? 1 : 0,
                    });
                  }
                }
              }
            }
          });
        }
      }
    },

    async getWorkflowById(id) {
      const { error, payload } = await workflow.getWorkflow(id);
      if (error) {
        this.$alert.error(error);
        return;
      }
      return payload;
    },

    async export_Data() {
      this.$store.commit("showLoadingBarPage");
      if (this.activeView === "GRID") {
        let input = {
          repositoryId: this.repositoryId,
          parentNodeId: this.parentNodeId,
          sortBy: this.sortBy,
          groupBy: this.groupBy,
          filterBy: this.filterBy,
          currentPage: 0,
          itemsPerPage: 0,
          mode: this.mode,
          level: this.currentLevel,
          ...this.contentSearch,
        };

        const { error, payload } = await repository.getEntities(input);

        this.$store.commit("hideLoadingBarPage");
        if (error) {
          this.$alert.error(error);
          return;
        }
        const { meta, data } = payload;
        if (meta.totalItems && data.length) {
          let exportRecords = [];
          data[0].value.forEach((entity) => {
            exportRecords.push({
              Name: entity.name,
              Level: entity.level,
              Type: entity.type,
              "No.of Files": entity.itemsCount,
              Size: entity.size ? formatBytes(entity.size) : "",
              Version: Number(entity.fileVersion),
              "Created By": entity.createdBy,
              "Created At": this.$day.format(entity.createdAt),
              "Last Modified By": entity.modifiedBy,
              "Last Modified At": this.$day.format(entity.modifiedAt),
            });
          });
          let exportName =
            this.repositoryDetails.name + " Folder List - Report";
          exportData(exportRecords, exportName);
        } else {
          //this.$alert.info("No data found for export");
        }
      } else {
        let filterCondition = [
          {
            filters: [],
            groupCondition: "",
          },
        ];

        filterCondition[0].filters.push({
          criteria: "isdeleted",
          condition: "IS_EQUALS_TO",
          value: "0",
        });
        let input = {
          repositoryId: this.repositoryId,
          parentNodeId: this.parentNodeId,
          sortBy: this.sortBy,
          groupBy: this.groupBy,
          filterBy: this.filterBy.length ? this.filterBy : filterCondition,
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage,
          mode: this.mode,
          level: this.currentLevel,
        };
        this.$store.commit("hideLoadingBarPage");
        const { error, payload } = await repository.getEntities(input);
        if (error) {
          this.$alert.error(error);
          return;
        }
        const { meta, data } = payload;
        if (meta.totalItems && data.length) {
          let exportRecords = [];
          data[0].value.forEach((entity) => {
            exportRecords.push({
              Name: entity.name,
              Type: entity.type,
              Size: entity.size ? formatBytes(entity.size) : "",
              Version: Number(entity.fileVersion),
              ...this.getFoldersFieldsData(entity),
              "Created By": entity.createdBy,
              "Created At": this.$day.format(entity.createdAt),
              "Last Modified By": entity.modifiedBy,
              "Last Modified At": this.$day.format(entity.modifiedAt),
            });
          });
          let exportName =
            this.repositoryDetails.name + " Document List - Report";
          exportData(exportRecords, exportName);
        } else {
          //this.$alert.info("No data found for export");
        }
      }
    },

    showCheckinUploadFile(item) {
      this.showCheckinModal = true;
      this.itemId = item.id;
    },

    queueFilesForUpload(files) {
      this.filesError = false;
      this.filesToUpload.push(...files);
    },

    async uploadFile() {
      const isValid = await this.$refs.checkin.validate();

      if (!this.filesToUpload.length) {
        this.filesError = true;
        return;
      }

      if (!isValid) {
        return;
      }
      let file = this.filesToUpload[0];
      let index = 0;
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("repositoryId", this.repositoryId);
      formData.append("itemId", this.itemId);
      formData.append("comments", this.comments);

      let filename = file.name;
      const ext = filename.split(".").pop();

      if (!fileSupport(ext)) {
        this.filesToUpload.splice(index, 1);
        this.$alert.info("This file format is not supported");
        return;
      }
      this.showProgress = true;
      this.showDeleteBtn = false;

      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
          method: "POST",
          data: formData,
          headers: {
            Token: this.$store.state.identity.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            // console.log(progressEvent);
            const uploadedPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            file.uploadedPercentage = uploadedPercentage;
          },
        });

        const { status } = response;
        if (status !== 200) {
          throw "Invalid response";
        }

        this.$alert.success("file updated successfully");
        this.reset();
        this.showCheckinModal = false;

        this.refreshData();
      } catch (error) {
        console.error(error);
        this.$alert.error(`error uploading file - ${file.name}`);
      }

      // remove file
    },

    reset() {
      this.filesToUpload = [];
      this.comments = "";
    },

    deleteUploadFile(index) {
      this.filesToUpload.splice(index, 1);
    },

    openMetaDataEdit(data, column) {
      // console.log(data, column);
      // if (this.repositoryId) this.getFolderSecurity();

      if (this.repositoryId && this.hasMetaDataEdit) {
        this.selectRow = data;
        this.selectColumn = column;
        this.selectField = this._repositoryField.find(
          (item) => item.name === column.name
        );

        if (this.selectField) this.openMetaDataEditModal = true;
      }
    },

    async getFolderSecurity() {
      const { error, payload } = await repository.getRepositorySecurity(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        if (payload.userOptions) {
          const userOptions = payload.userOptions.map((userOption) => ({
            id: userOption.id,
            userName: userOption.email,
            userId: userOption.userId,
            options: JSON.parse(userOption.options),
          }));

          const groupOptions = payload.groupOptions.map((groupOption) => ({
            id: groupOption.id,
            groupId: groupOption.groupId,
            options: JSON.parse(groupOption.options),
          }));

          let options = userOptions.find(
            (user) => user.userId === this.$store.state.session.id
          );
          let hasUserEdit = false;
          if (options && options.options) {
            if (options.options.metadataEdit) {
              hasUserEdit = true;
            } else {
              hasUserEdit = false;
            }
          } else {
            hasUserEdit = false;
          }

          let hasGroupEdit = false;
          if (this.$store.state.session.groups) {
            this.$store.state.session.groups.forEach((group) => {
              let options1 = groupOptions.find(
                (user) => user.groupId === group.id
              );
              if (options1 && options1.options) {
                if (options1.options.metadataEdit) {
                  hasGroupEdit = true;
                }
              }
            });
          }

          if (hasUserEdit || hasGroupEdit) {
            this.hasMetaDataEdit = true;
          } else {
            this.hasMetaDataEdit = false;
          }
        }
      }
    },

    closeModal() {
      this.openMetaDataEditModal = false;
      this.selectRow = {};
      this.selectColumn = {};
      this.newValue = "";
    },

    async saveMetaData() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      if (!this.newValue) {
        this.newValueError = true;
        return;
      }

      this.$store.commit("showLoadingBar");
      let settings = [
        {
          fieldId: this.selectColumn.fieldId,
          oldValue: this.selectRow[this.selectColumn.name],
          newValue: this.newValue,
        },
      ];

      let input = {
        repositoryId: this.repositoryId,
        itemId: this.selectRow.id,
        settings: JSON.stringify(settings),
      };
      const { error, payload } = await repository.saveMetaData(input);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) this.$alert.success("Metadata edit successfully");
      this.closeModal();
      this.refreshData();
    },

    updateSelectAll(value) {
      if (!value) {
        const newValue = this._entitiesFile.some((group) =>
          group.data.some((item) => item.selected === true)
        );
        this.itemSelected = newValue;
        this.isAllSelected = value;
      } else {
        this.itemSelected = value;
        this.isAllSelected = value;
      }
    },

    downloadAll() {
      this._entitiesFile.forEach((group) => {
        group.data.forEach((item) => {
          if (item.selected) {
            let link = "";

            link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.repositoryId}/${item.id}/2`;

            window.open(link, "_blank");
          }
        });
      });
      this.getRepository();
      this.itemSelected = false;
      this.isAllSelected = false;
    },

    printAll() {
      this._entitiesFile.forEach((group) => {
        group.data.forEach((item) => {
          if (item.selected) {
            let link = "";
            link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.repositoryId}/${item.id}/2`;
            window.open(link, "_blank");
            this.filePrintSession(item.id);
          }
        });
      });
      this.getRepository();
      this.itemSelected = false;
      this.isAllSelected = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#entity-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
