<template>
  <div id="text-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="isFocused"
      :is-clearable="_isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      :highlight="highlight"
      @clear="handleClear"
    >
      <div class="row items-center">
        <div class="col row items-center currency">
          <div
            ref="select"
            class="col select"
            style="min-height: 46px"
            @click="showOptionPicker"
          >
            <!-- :style="{ minHight: minHeight }" -->
            <div v-if="currency" class="value ellipsis">
              {{ currency }}
            </div>

            <input
              v-if="!currency"
              ref="searchQuery"
              v-model="searchQuery"
              type="text"
              placeholder="Currency"
              class="q-px-sm"
              @focus="isFocused = true"
              @keypress.enter="handleKeypress"
            />
            <!-- @blur="
            {
              isFocused = false;
              handleBlur();
            }
          "
          @keypress.enter="handleKeypress"
          @input="handleSearch" -->
          </div>

          <ActionButton icon="eva-chevron-down" @click="showOptionPicker" />

          <ActionButton
            v-if="value.currency"
            icon="eva-close"
            @click.stop="handleCurrency"
          />

          <BaseSeparator is-vertical has-inset class="q-ml-sm" />
        </div>
        <!-- option picker -->

        <q-menu
          v-model="optionPicker"
          :target="$refs.wrapper"
          fit
          no-focus
          no-refocus
          no-parent-event
          transition-show="scale"
          transition-hide="scale"
        >
          <OptionsPicker
            :value="value.currency"
            :options="_options"
            :new-option="newOption"
            @select="handleSelect"
          />
        </q-menu>

        <!-- ... -->
        <input
          type="number"
          :value="value.value"
          :autofocus="autoFocus"
          :placeholder="placeholder"
          class="q-ml-sm col"
          @focus="isFocused = true"
          @blur="isFocused = false"
          @input.stop="handleInput"
          @click="$emit('click')"
          @keypress.enter="$emit('enter')"
          @focusout="handleFocusout"
          @mouseleave="handleFocusout"
        />
      </div>
    </FormFieldWrapper>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import ActionButton from "@/components/common/form/field-wrapper/components/ActionButton.vue";
import OptionsPicker from "@/components/common/form/select-field/OptionsPicker.vue";
import { isEmpty, lowerCase, cloneDeep, isEqual } from "lodash-es";
import { common } from "@/api/factory.js";

export default {
  name: "CurrencyAmountField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    ActionButton,
    OptionsPicker,
  },

  props: {
    // value: {
    //   type: String,
    //   default: "",
    // },

    value: {
      type: Object,
      default: () => ({
        currency: "",
        value: "",
      }),
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    autoFocus: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    newOption: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isFocused: false,
      openLabelInput: false,
      labelError: "",
      searchQuery: "",
      optionPicker: false,
      optionsClone: [],
    };
  },

  computed: {
    _isClearable() {
      if (!this.isClearable) {
        return false;
      }

      return !!this.value.value;
    },

    _placeholder() {
      if (this.value.currency) {
        return "";
      }

      return this.placeholder || "Select";
    },

    currency() {
      if (!this.value.currency || isEmpty(this.optionsClone)) {
        return "";
      }

      const option = this.optionsClone.find(
        (option) => option.value === this.value.currency
      );

      if (!option) {
        return "";
      }

      return option.label;
    },

    _options() {
      if (!this.searchQuery) {
        return this.optionsClone;
      }

      if (this.$route.name === "sql-browse" && this.label === "Table Name") {
        return this.optionsClone;
      }

      return this.optionsClone.filter((option) =>
        lowerCase(option.label).includes(lowerCase(this.searchQuery))
      );
    },
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEqual(this.options, this.optionsClone)) {
          if (this.value.currency) {
            let optValue = this.options.find(
              (row) => row.value === this.value.currency
            );
            if (!optValue) {
              this.$emit("input", {
                currency: "",
                value: "",
              });
              // this.$emit("input", "");
            }
          }
          this.optionsClone = cloneDeep(this.options);
        }
        if (!this.optionsClone.length) {
          this.getCurrencyList();
        }
      },
    },

    optionPicker() {
      if (!this.optionPicker) {
        this.searchQuery = "";
      }
    },
  },

  methods: {
    handleInput(evt) {
      // this.$emit("input", evt.target.value);

      this.$emit("input", {
        currency: this.value.currency,
        value: evt.target.value,
      });
    },

    handleClear() {
      // this.$emit("input", "");
      this.$emit("input", {
        currency: this.value.currency,
        value: "",
      });
    },

    handleCurrency() {
      // this.$emit("input", "");
      this.$emit("input", {
        currency: "",
        value: this.value.value,
      });
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    showOptionPicker() {
      this.optionPicker = true;
    },

    handleSelect(optionValue) {
      // this.$emit("input", optionValue);
      this.value.currency = optionValue;
      this.optionPicker = false;

      this.$emit("input", {
        currency: this.value.currency,
        value: this.value.value,
      });
    },

    handleKeypress() {
      if (!this.searchQuery) {
        return;
      }

      if (this._options.length) {
        let optionIndex = this._options.find(
          (opt) => opt.value.toLowerCase() === this.searchQuery.toLowerCase()
        );
        if (optionIndex) {
          this.handleSelect(optionIndex.value);
          this.$refs.searchQuery.blur();
          return;
        }
      }

      if (this.newOption) {
        const newOption = {
          id: this.$nano.id(),
          label: this.searchQuery,
          value: this.searchQuery,
        };
        this.optionsClone.push(newOption);

        this.handleSelect(this.searchQuery);
        this.searchQuery = "";
        this.$refs.searchQuery.blur();
      }
      this.$emit("enter");
    },

    handleFocusout(evt) {
      this.$emit("focusOut", evt.target.value);
    },

    async getCurrencyList() {
      let input = {
        sortBy: {
          criteria: "country",
          order: "ASC",
        },
        groupBy: "",
        filterBy: [],
        currentPage: 0,
        itemsPerPage: 0,
        mode: "BROWSE",
      };
      const { error, payload } = await common.getCurrencyList(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.meta.totalItems) {
          if (payload.data.length) {
            if (payload.data[0].value.length) {
              payload.data[0].value.forEach((row) => {
                this.optionsClone.push({
                  id: this.$nano.id(),
                  label: `${row.country} (${row.code})`,
                  value: row.code,
                  image: row.flag,
                });
              });
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#text-field {
  input {
    min-height: 46px;
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }

  .select {
    min-height: 46px;
    display: flex;
    align-items: center;

    .value {
      white-space: nowrap;
      font-weight: 500;
      padding-left: 8px;
    }
  }

  .currency {
    background: aliceblue;
  }
}
</style>
