<template>
  <div id="time-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
        :transform="transform"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="_isFocused"
      :is-clearable="isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      :highlight="highlight"
      action-icon="eva-clock-outline"
      @click="toggleTimePicker"
      @clear="handleClear"
    >
      <input
        v-imask="timeMask"
        type="text"
        :value="time"
        :placeholder="_placeholder"
        class="q-px-sm text"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @click.stop=""
        @accept="handleAccept"
        @complete="handleComplete"
      />

      <!-- time picker -->

      <q-menu
        v-model="timePicker"
        :target="$refs.wrapper"
        no-refocus
        no-parent-event
        transition-show="scale"
        transition-hide="scale"
      >
        <TimePicker
          :value="value"
          :time-format="timeFormat"
          @input="handleInput"
        />
      </q-menu>

      <!-- ... -->
    </FormFieldWrapper>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";
import timeMask from "./time-mask.js";
import timeMask24 from "./time24-mask.js";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import TimePicker from "./TimePicker.vue";
import { isValidTime } from "@/helpers/time-validator.js";

export default {
  name: "TimeField",

  components: { FormFieldLabel, FormFieldWrapper, FormFieldError, TimePicker },

  directives: {
    imask: IMaskDirective,
  },

  props: {
    value: {
      type: String,
      default: "",
      validator: isValidTime,
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    highlight: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    timeFormat: {
      type: String,
      default: "12",
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      timePicker: false,
      time: "",
      timeMask: "",
      openLabelInput: false,
      labelError: "",
    };
  },

  computed: {
    _placeholder() {
      if (this.placeholder) {
        return this.placeholder;
      } else if (this.timeFormat === "12") {
        return "HH:MM AA";
      } else if (this.timeFormat === "24") {
        return "HH:MM";
      }
      return this.placeholder || "HH:MM AA";
    },

    _isFocused() {
      return this.isFocused || this.timePicker;
    },

    isClearable() {
      return !!this.value;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.time = this.value;
      },
    },

    timeMask: {
      immediate: true,
      handler() {
        if (this.timeFormat === "12") {
          this.timeMask = timeMask;
        } else {
          this.timeMask = timeMask24;
        }
      },
    },
  },

  methods: {
    toggleTimePicker() {
      this.timePicker = !this.timePicker;
    },

    handleInput(value) {
      // console.log(value);
      this.$emit("input", value);
      // this.$emit("input", value.split(" ")[0]);
    },

    handleAccept(evt) {
      this.time = evt.detail.value.toUpperCase();
    },

    handleComplete(evt) {
      this.$emit("input", evt.detail.value.toUpperCase());
    },

    handleClear() {
      this.timePicker = false;
      this.$emit("input", "");
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#time-field {
  input.text {
    min-height: 46px;
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }
}
</style>
