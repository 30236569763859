<template>
  <BaseScrollbar
    :style="formView === 'CARD' ? 'max-height: calc(100vh - 235px)' : ''"
  >
    <div id="panel" :style="{ padding: noPadding ? '0px' : '16px' }">
      <div v-if="title" class="title">{{ title }}</div>
      <div v-if="description" class="description">{{ description }}</div>

      <BaseSeparator v-if="title || description" class="q-my-md" />
      <div class="row q-col-gutter-md">
        <template v-if="Object.keys(fieldsModel).length">
          <template v-if="dummyData"></template>
          <template v-for="field in fields">
            <component
              :is="getField(field.type)"
              v-if="checkVisibility(field.id)"
              :key="field.id"
              v-model="fieldsModel[field.id]"
              :form-id="formId"
              :field="field"
              :rules="rules"
              :secondary-panels="secondaryPanels"
              :form-settings="formSettings"
              :is-readonly="checkReadOnly(field.id)"
              :parent-control="checkParentControl(field.id)"
              :filter="field.filters"
              :enable-controls="enableControls"
              :visible-controls="visibleControls"
              :highlight="checkHighlight(field.label)"
              :hide-controls="hideControls"
              :form-upload-files="formUploadFiles"
              :parent-date-control="checkDateParentControl(field.id)"
              :parent-date-options-control="
                checkDateParentOptionsControl(field.id)
              "
              :parent-select-options="checkDateOptionsControl(field.id)"
              :date-filter="field.dateFilter"
              :edit-controls="formEditControls"
              :date-filter-options="field.dateFilterOptions"
              :prediction="checkPrediction(field.label)"
              :fields="fields"
              :parent-field-master-controls="parentFieldMasterControls"
              :parent-field-folder-controls="parentFieldFolderControls"
              :update-value-from-search="updateValueFromSearch[field.id]"
              :has-change-table-row="
                hasChangeTableRow ? hasChangeTableRow[field.id] : []
              "
              :fields-model="fieldsModel"
              :has-inbox="hasInbox"
              :repository-details="repositoryDetails"
              :lookup-parent-control="checkLookupParentControl(field.id)"
              :lookup-filter="field.lookupFilter"
              :lookup-filter-in-table="field.lookupFilterInTable"
              @filterBy="filterChildControlValues"
              @clearFilter="clearFilter"
              @updateVisibleControls="updateVisibleControls"
              @verify="dataVerify(field.id, field.label)"
              @mandatoryControls="mandatoryControls"
              @uploadFiles="uploadFiles"
              @fillChildDateControl="fillChildDateControl($event, field)"
              @deletedFiles="deletedFiles"
              @readonlyControls="setReadonlyControls"
              @fillChildDateUsingOptions="
                fillChildDateUsingOptions($event, field)
              "
              @fillDateFieldInPanel="fillDateFieldInPanel"
              @showQr="showQr"
              @updateFormSearch="updateFormSearch"
              @scanQRCode="scanQRCode"
              @getParentColumnValue="getParentColumnValue"
              @uploadFilesInTable="uploadFilesInTable"
              @deletedFilesInTable="deletedFilesInTable"
              @viewFile="viewFile"
              @address="updateAddressFieldValues"
              @getParentColumnValueInTable="getParentColumnValueInTable"
              @showQrInTable="showQrInTable"
              @lookupControlFilter="lookupControlFilter"
            />
          </template>
        </template>
        <template v-else>
          <template v-for="field in fields">
            <component
              :is="getField(field.type)"
              v-if="checkVisibility(field.id)"
              :key="field.id"
              :value="getFieldValue(field.type)"
              :form-id="formId"
              :field="field"
              :rules="rules"
              :secondary-panels="secondaryPanels"
              :form-settings="formSettings"
              :is-readonly="checkReadOnly(field.id)"
              :parent-control="checkParentControl(field.id)"
              :filter="field.filters"
              :enable-controls="enableControls"
              :visible-controls="visibleControls"
              :hide-controls="hideControls"
              :form-upload-files="formUploadFiles"
              :parent-date-control="checkDateParentControl(field.id)"
              :date-filter="field.dateFilter"
              :edit-controls="formEditControls"
              :date-filter-options="field.dateFilterOptions"
              :parent-date-options-control="
                checkDateParentOptionsControl(field.id)
              "
              :parent-select-options="checkDateOptionsControl(field.id)"
              :prediction="checkPrediction(field.label)"
              :fields="fields"
              :parent-field-master-controls="parentFieldMasterControls"
              :parent-field-folder-controls="parentFieldFolderControls"
              :update-value-from-search="updateValueFromSearch[field.id]"
              :has-change-table-row="
                hasChangeTableRow ? hasChangeTableRow[field.id] : []
              "
              :fields-model="fieldsModel"
              :has-inbox="hasInbox"
              :repository-details="repositoryDetails"
              :lookup-parent-control="checkLookupParentControl(field.id)"
              :lookup-filter="field.lookupFilter"
              :lookup-filter-in-table="field.lookupFilterInTable"
              @filterBy="filterChildControlValues"
              @clearFilter="clearFilter"
              @updateVisibleControls="updateVisibleControls"
              @mandatoryControls="mandatoryControls"
              @uploadFiles="uploadFiles"
              @fillChildDateControl="fillChildDateControl($event, field)"
              @deletedFiles="deletedFiles"
              @readonlyControls="setReadonlyControls"
              @fillChildDateUsingOptions="
                fillChildDateUsingOptions($event, field)
              "
              @fillDateFieldInPanel="fillDateFieldInPanel"
              @showQr="showQr"
              @updateFormSearch="updateFormSearch"
              @scanQRCode="scanQRCode"
              @getParentColumnValue="getParentColumnValue"
              @uploadFilesInTable="uploadFilesInTable"
              @deletedFilesInTable="deletedFilesInTable"
              @viewFile="viewFile"
              @address="updateAddressFieldValues"
              @getParentColumnValueInTable="getParentColumnValueInTable"
              @showQrInTable="showQrInTable"
              @lookupControlFilter="lookupControlFilter"
            />
          </template>
        </template>
      </div>
      <QRReader :value="showQRRead" :form-id="formId" @input="qrReadValue" />
    </div>
  </BaseScrollbar>
</template>

<script>
import LabelFieldWrapper from "./fields/LabelFieldWrapper.vue";
import ShortTextFieldWrapper from "./fields/ShortTextFieldWrapper.vue";
import LongTextFieldWrapper from "./fields/LongTextFieldWrapper.vue";
import NumberFieldWrapper from "./fields/NumberFieldWrapper.vue";
import CounterFieldWrapper from "./fields/CounterFieldWrapper.vue";
import CalculatedFieldWrapper from "./fields/CalculatedFieldWrapper.vue";
import DateFieldWrapper from "./fields/DateFieldWrapper.vue";
import TimeFieldWrapper from "./fields/TimeFieldWrapper.vue";
import DateTimeFieldWrapper from "./fields/DateTimeFieldWrapper.vue";
import SingleSelectFieldWrapper from "./fields/SingleSelectFieldWrapper.vue";
import MultiSelectFieldWrapper from "./fields/MultiSelectFieldWrapper.vue";
import SingleChoiceFieldWrapper from "./fields/SingleChoiceFieldWrapper.vue";
import MultipleChoiceFieldWrapper from "./fields/MultipleChoiceFieldWrapper.vue";
import ChipsFieldWrapper from "./fields/ChipsFieldWrapper.vue";
import ParagraphFieldWrapper from "./fields/ParagraphFieldWrapper.vue";
import FileUploadFieldWrapper from "./fields/FileUploadFieldWrapper.vue";
import DividerFieldWrapper from "./fields/DividerFieldWrapper.vue";
import TableFieldWrapper from "./fields/TableFieldWrapper.vue";
import FillInTheBlanksFieldWrapper from "./fields/FillInTheBlanksFieldWrapper.vue";
import MatrixFieldWrapper from "./fields/MatrixFieldWrapper.vue";
import ImageFieldWrapper from "./fields/ImageFieldWrapper.vue";
import SignatureFieldWrapper from "./fields/SignatureFieldWrapper.vue";
import RatingFieldWrapper from "./fields/RatingFieldWrapper.vue";
import TabsFieldWrapper from "./fields/TabsFieldWrapper.vue";
import PopupFieldWrapper from "./fields/PopupFieldWrapper.vue";
import NestedListFieldWrapper from "./fields/NestedListFieldWrapper.vue";
import EmailAuthenticationFieldWrapper from "./fields/EmailAuthenticationFieldWrapper.vue";
import MobileAuthenticationFieldWrapper from "./fields/MobileAuthenticationFieldWrapper.vue";
import LoginAuthenticationFieldWrapper from "./fields/LoginAuthenticationFieldWrapper.vue";
import TextBuilderFieldWrapper from "./fields/TextBuilderFieldWrapper.vue";
import PasswordFieldWrapper from "./fields/PasswordFieldWrapper.vue";
import UrlFieldWrapper from "./fields/UrlFieldWrapper.vue";
import CurrencyAmountFieldWrapper from "./fields/CurrencyAmountFieldWrapper.vue";
import CurrencyFieldWrapper from "./fields/CurrencyFieldWrapper.vue";
import CountryCodeFieldWrapper from "./fields/CountryCodeFieldWrapper.vue";
import PhoneNumberFieldWrapper from "./fields/PhoneNumberFieldWrapper.vue";
import { upperFirst, camelCase } from "lodash-es";
import { custom, form } from "@/api/factory.js";
import QRReader from "@/components/common/QRReader.vue";
import { isEmpty } from "lodash-es";

export default {
  name: "RenderPanel",

  components: {
    LabelFieldWrapper,
    ShortTextFieldWrapper,
    LongTextFieldWrapper,
    NumberFieldWrapper,
    CounterFieldWrapper,
    CalculatedFieldWrapper,
    DateFieldWrapper,
    TimeFieldWrapper,
    DateTimeFieldWrapper,
    SingleSelectFieldWrapper,
    MultiSelectFieldWrapper,
    SingleChoiceFieldWrapper,
    MultipleChoiceFieldWrapper,
    ChipsFieldWrapper,
    ParagraphFieldWrapper,
    FileUploadFieldWrapper,
    DividerFieldWrapper,
    FillInTheBlanksFieldWrapper,
    TableFieldWrapper,
    MatrixFieldWrapper,
    ImageFieldWrapper,
    SignatureFieldWrapper,
    RatingFieldWrapper,
    TabsFieldWrapper,
    PopupFieldWrapper,
    NestedListFieldWrapper,
    EmailAuthenticationFieldWrapper,
    MobileAuthenticationFieldWrapper,
    LoginAuthenticationFieldWrapper,
    TextBuilderFieldWrapper,
    PasswordFieldWrapper,
    UrlFieldWrapper,
    CurrencyFieldWrapper,
    CountryCodeFieldWrapper,
    QRReader,
    CurrencyAmountFieldWrapper,
    PhoneNumberFieldWrapper,
  },

  props: {
    formId: {
      type: String,
      default: "0",
    },

    title: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    fields: {
      type: Array,
      required: true,
    },

    formControls: {
      type: Array,
      required: true,
    },

    selectedProcess: {
      type: Object,
      default: () => {},
    },

    fieldsModel: {
      type: Object,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    noPadding: {
      type: Boolean,
      default: false,
    },

    formSettings: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    formEditControls: {
      type: Array,
      default: () => [],
    },

    readonlyControls: {
      type: Array,
      default: () => [],
    },

    formVisibilityAccess: {
      type: Boolean,
      default: true,
    },

    formSecureControls: {
      type: Array,
      default: () => [],
    },

    editPanelControls: {
      type: Boolean,
      default: false,
    },

    enableControls: {
      type: Object,
      default: () => {},
    },

    visibleControls: {
      type: Array,
      default: () => [],
    },

    dataValidationValues: {
      type: Array,
      default: () => [],
    },

    formUploadFiles: {
      type: Array,
      default: () => [],
    },

    ocrResult: {
      type: Array,
      default: () => [],
    },

    externalLink: {
      type: Boolean,
      default: false,
    },

    portalId: {
      type: Number,
      default: 0,
    },

    formView: {
      type: String,
      default: "",
    },

    hasChangeTableRow: {
      type: Object,
      default: () => {},
    },

    repositoryDetails: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      hideControls: [],
      portal: {},
      parentFieldMasterControls: [],
      parentFieldFolderControls: [],
      updateValueFromSearch: {},
      showQRRead: false,
      hasInbox: false,
      dummyData: false,
    };
  },

  computed: {
    rules() {
      return this.formSettings.rules;
    },
  },

  watch: {
    fields: {
      immediate: true,
      handler() {
        if (this.fields.length) {
          this.parentFieldMasterControls = [];
          this.parentFieldFolderControls = [];
          this.fields.forEach((field) => {
            field.filter = 0;
            field.dateFilter = 0;
            let lastIndex = this.parentFieldFolderControls.length - 1;

            // console.log(
            //   lastIndex,
            //   this.parentFieldFolderControls.length - 1,
            //   "lastIndex"
            // );
            if (!this.parentFieldFolderControls.length) {
              if (field.settings.specific.repositoryFieldParent) {
                this.parentFieldFolderControls.push({
                  fieldId: field.id,
                  label: field.label,
                  type: field.type,
                  parentFieldId: field.settings.specific.repositoryFieldParent,
                });
              }
            } else if (lastIndex >= 0) {
              // console.log(
              //   this.parentFieldFolderControls[
              //     this.parentFieldFolderControls.length - 1
              //   ].label,

              //   "parentFieldFolderControls"
              // );
              let parentField =
                this.parentFieldFolderControls[
                  this.parentFieldFolderControls.length - 1
                ].fieldId;
              let parents = this.fields.find(
                (con) =>
                  con.settings.specific.repositoryFieldParent === parentField
              );
              //console.log(parents, "parents");
              // parentField === field.settings.specific.repositoryFieldParent
              if (parents) {
                //console.log(parents.label, "parents.label");
                this.parentFieldFolderControls.push({
                  fieldId: parents.id,
                  label: parents.label,
                  type: parents.type,
                  parentFieldId:
                    parents.settings.specific.repositoryFieldParent,
                });
              } else {
                if (field.settings.specific.repositoryFieldParent) {
                  let already = this.parentFieldFolderControls.findIndex(
                    (folder) => {
                      return (
                        folder.fieldId ===
                        field.settings.specific.repositoryFieldParent
                      );
                    }
                  );
                  if (already === -1) {
                    let otherParents = this.fields.find(
                      (con) =>
                        field.settings.specific.repositoryFieldParent === con.id
                    );
                    if (otherParents) {
                      this.parentFieldFolderControls.push({
                        fieldId: otherParents.id,
                        label: otherParents.label,
                        type: otherParents.type,
                        parentFieldId:
                          otherParents.settings.specific.repositoryFieldParent,
                      });
                    }
                  }
                }

                //console.log(field.label, "field.label");
              }
            }
            if (field.settings.specific.masterFormParentColumn) {
              this.parentFieldMasterControls.push({
                fieldId: field.id,
                label: field.label,
                type: field.type,
                parentFieldId: field.settings.specific.masterFormParentColumn,
              });
            }
          });
        }
      },
    },

    selectedProcess: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEmpty(this.selectedProcess)) {
          this.hasInbox = this.selectedProcess.inbox;
        }
      },
    },
  },

  methods: {
    getField(fieldType) {
      return `${upperFirst(camelCase(fieldType))}FieldWrapper`;
    },

    getFieldValue(fieldType) {
      switch (fieldType) {
        case "MULTI_SELECT":
        case "MULTIPLE_CHOICE":
        case "FILE_UPLOAD":
        case "IMAGE_UPLOAD":
        case "TABLE":
        case "MATRIX":
        case "CHIPS":
        case "NESTED_LIST":
          return [];
        case "COUNTER":
        case "CALCULATED":
        case "RATING":
          return 0;
        case "FILL_IN_THE_BLANKS":
        case "POPUP":
        case "TABS":
          return {};
        default:
          return "";
      }
    },

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    checkReadOnly(fieldId) {
      if (this.readonlyControls.indexOf(fieldId) > -1) {
        return true;
      } else if (this.isReadonly) {
        return this.isReadonly;
      } else if (this.formEditControls.length) {
        if (
          typeof this.formEditControls[0] === "object" ||
          typeof this.formEditControls[0] === "string"
        ) {
          // console.log(this.formEditControls);
          let formEditControls = [];
          let singleEditControl = [];
          if (typeof this.formEditControls[0] === "object") {
            formEditControls = this.formEditControls;
          } else {
            this.formEditControls.forEach((edit) => {
              if (typeof edit === "string") {
                if (this.isJsonString(edit)) {
                  formEditControls.push(JSON.parse(edit));
                } else {
                  singleEditControl.push(edit);
                }
              }
            });
          }
          if (formEditControls.length) {
            let hasEmail = formEditControls.find(
              (item) => item.userId === this.$store.state.session.id
            );
            // console.log(hasEmail, hasEmail.formFields);
            if (hasEmail && hasEmail.formFields.length) {
              let formfields = [];
              hasEmail.formFields.forEach((form) => {
                let table = form.split("@");
                // console.log(table);
                if (table.length === 2) {
                  // console.log(table[0].trim(), fieldId, "same");
                  if (table[0].trim() === fieldId) {
                    // return true;
                    formfields.push(table[0].trim());
                  }
                } else {
                  if (form === fieldId) {
                    // return true;
                    formfields.push(form);
                  } else {
                    // return false;
                  }
                }
              });

              // console.log(formfields, "length");
              if (formfields.length) {
                return !formfields.includes(fieldId);
              } else {
                return true;
              }

              // return !hasEmail.formFields.includes(fieldId);
            } else {
              return false;
            }
          } else if (singleEditControl.length) {
            // console.log(singleEditControl);
            return !singleEditControl.includes(fieldId);
          }
        } else {
          return !this.formEditControls.includes(fieldId);
        }
        // return false;
      } else if (this.editPanelControls) {
        return false;
      } else {
        let field = this.fields.find((item) => item.id === fieldId);
        if (field) {
          if (field.settings.general.visibility === "READ_ONLY") {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      // return !this.formEditControls.includes(fieldId);
    },

    checkVisibility(fieldId) {
      if (this.enableControls) {
        let index = this.enableControls.listControls.findIndex(
          (row) => row === fieldId
        );
        let showControlIndex = this.visibleControls.findIndex(
          (row) => row === fieldId
        );
        if (index > -1 && showControlIndex < 0) {
          return false;
        }
      } else if (this.formVisibilityAccess) {
        return true;
      } else if (this.formSecureControls.length === 0) {
        return true;
      }
      if (typeof this.formSecureControls[0] === "object") {
        let hasEmail = this.formSecureControls.find(
          (item) => item.userId === this.$store.state.session.id
        );
        if (hasEmail && hasEmail.formFields.length) {
          return !hasEmail.formFields.includes(fieldId);
        } else {
          return true;
        }
      } else {
        return !this.formSecureControls.includes(fieldId);
      }

      // return !this.formSecureControls.includes(fieldId);
    },

    checkParentControl(fieldId) {
      let parentFieldFound = false;
      this.formControls.forEach((field) => {
        if (field.settings.specific.masterFormParentColumn) {
          if (fieldId === field.settings.specific.masterFormParentColumn) {
            parentFieldFound = true;
            return;
          }
        } else if (field.settings.specific.repositoryFieldParent) {
          if (fieldId === field.settings.specific.repositoryFieldParent) {
            parentFieldFound = true;
            return;
          }
        }

        if (field.type === "TABLE") {
          let columns = field.settings.specific.tableColumns;
          columns.forEach((column) => {
            if (column.settings.specific.masterFormParentColumn) {
              if (fieldId === column.settings.specific.masterFormParentColumn) {
                parentFieldFound = true;
                return;
              }
            } else if (column.settings.specific.repositoryFieldParent) {
              if (fieldId === column.settings.specific.repositoryFieldParent) {
                parentFieldFound = true;
                return;
              }
            }
          });
        }
      });
      return parentFieldFound;
    },

    filterChildControlValues(field, isManualChange, parentTrigger) {
      //console.log(JSON.stringify(field));
      this.formControls.forEach((rowField) => {
        if (rowField.settings.specific.masterFormParentColumn) {
          // let currentPanelFields = this.fields.find(
          //   (row) => row.id === rowField.id
          // );
          if (field.id === rowField.settings.specific.masterFormParentColumn) {
            if (!rowField.filterBy) {
              // this.$set(rowField, "filterBy", []);
              // this.$set(rowField, "filters", 0);
            }
            if (this.selectedProcess) {
              if (!Object.keys(this.selectedProcess).length && !parentTrigger) {
                if (
                  rowField.type === "MULTI_SELECT" ||
                  rowField.type === "MULTI_CHOICE" ||
                  rowField.type === "TABLE"
                ) {
                  this.fieldsModel[rowField.id] = [];
                } else {
                  this.fieldsModel[rowField.id] = "";
                }
              }
            }

            let proceed = true;
            if ("isSameMaster" in rowField.settings.specific) {
              if (!rowField.settings.specific.isSameMaster) {
                proceed = false;
              }
            }

            if (proceed) {
              rowField.filterBy = [
                {
                  criteria: field.settings.specific.masterFormColumn,
                  condition: "IS_EQUALS_TO",
                  value: this.fieldsModel[field.id],
                  dataType: "",
                },
              ];
            } else {
              let filterBy = [];
              if (rowField.settings.specific.masterFormConditionColumn.length) {
                let conditions =
                  rowField.settings.specific.masterFormConditionColumn;
                conditions.forEach((master) => {
                  filterBy.push({
                    criteria: master.masterColumn,
                    condition: "IS_EQUALS_TO",
                    value: this.fieldsModel[master.formColumn],
                    dataType: "",
                  });
                });
              }

              rowField.filterBy = filterBy;
            }
            console.log(rowField.filterBy, rowField.label, "rowField.filterBy");
            //console.log(rowField.label);
            // console.log(field.settings.specific.masterFormColumn);
            // console.log(this.fieldsModel[field.id]);
            //rowField.filter = rowField.filter ? !rowField.filter : true;
            // let filters = rowField.filters ? rowField.filters + 1 : 1;
            // rowField.filters = filters;
            rowField.filters = this.$nano.id();
            rowField.isManualChange = isManualChange;
            this.dummyData = !this.dummyData;
          }
        } else if (rowField.settings.specific.repositoryFieldParent) {
          if (field.id === rowField.settings.specific.repositoryFieldParent) {
            if (!rowField.filterBy) {
              // this.$set(rowField, "filterBy", []);
              // this.$set(rowField, "filters", 0);
            }
            rowField.filterBy = [];
            if (this.selectedProcess) {
              if (!Object.keys(this.selectedProcess).length) {
                if (
                  rowField.type === "MULTI_SELECT" ||
                  rowField.type === "MULTI_CHOICE" ||
                  rowField.type === "TABLE"
                ) {
                  this.fieldsModel[rowField.id] = [];
                } else {
                  this.fieldsModel[rowField.id] = "";
                }
              }
            }
            rowField.filterBy.push({
              criteria: field.settings.specific.repositoryField,
              condition: "IS_EQUALS_TO",
              value: this.fieldsModel[field.id],
              dataType: field.type,
              fieldId: field.id,
            });
            // console.log(rowField.label);
            // console.log(rowField.filter);
            // rowField.filter = rowField.filter ? !rowField.filter : true;
            // rowField.filters = rowField.filters + 1;
            // let filters = rowField.filters ? rowField.filters + 1 : 1;
            // rowField.filters = filters;
            rowField.filters = this.$nano.id();
            rowField.isManualChange = isManualChange;
            this.dummyData = !this.dummyData;
          }
        }
      });
    },

    filterChildControlValuesInTable(field, isManualChange) {
      this.formControls.forEach((rowField) => {
        if (rowField.type === "TABLE") {
          let columns = rowField.settings.specific.tableColumns;
          columns.forEach((column) => {
            if (column.settings.specific.masterFormParentColumn) {
              if (
                field.id === column.settings.specific.masterFormParentColumn
              ) {
                if (!column.filterBy) {
                  //this.$set(column, "filterBy", []);
                }
                column.filterBy = [
                  {
                    criteria: field.settings.specific.masterFormColumn,
                    condition: "IS_EQUALS_TO",
                    value: this.fieldsModel[field.id],
                    dataType: "",
                  },
                ];
                column.isManualChange = isManualChange;
              }
            } else if (column.settings.specific.repositoryFieldParent) {
              if (field.id === column.settings.specific.repositoryFieldParent) {
                if (!column.filterBy) {
                  //this.$set(column, "filterBy", []);
                }

                column.filterBy = [
                  {
                    criteria: field.settings.specific.repositoryField,
                    condition: "IS_EQUALS_TO",
                    value: this.fieldsModel[field.id],
                    dataType: "",
                  },
                ];
              }
            }
          });
        }
      });
    },

    clearFilter() {
      // this.fields.filter((rowField) => {
      //   delete rowField.filterBy;
      //   delete rowField.filter;
      // });
    },

    updateVisibleControls(value) {
      value.forEach((field) => {
        if (Object.prototype.hasOwnProperty.call(this.fieldsModel, field)) {
          // this.fieldsModel[field] = "";
          let childField = this.formControls.find(
            (rowField) => rowField.id === field
          );
          childField.filter = childField.filter + 1;
          childField.filterBy = [];
        }
      });

      this.hideControls.forEach((field) => {
        if (Object.prototype.hasOwnProperty.call(this.fieldsModel, field)) {
          let fieldControl = this.formControls.find(
            (control) => control.id === field
          );
          this.fieldsModel[field] = this.getFieldValue(fieldControl.type);
          this.changeControlValue(field, "");
        }
      });
      this.hideControls = [];
      this.$emit("update:visibleControls", value);
    },

    changeControlValue(fieldId, value) {
      if (this.enableControls) {
        if (Object.keys(this.enableControls).length) {
          if (this.enableControls.controls.length) {
            let index = this.enableControls.controls.findIndex(
              (row) => row.id === fieldId
            );
            if (index > -1) {
              let showControls = this.visibleControls;
              let enabled = [];
              this.enableControls.controls[index].parentControls.forEach(
                (control) => {
                  if (control.value === value) {
                    showControls.push(...control.childControls);
                    enabled.push(...control.childControls);
                  } else {
                    control.childControls.forEach((child) => {
                      if (
                        showControls.indexOf(child) > -1 &&
                        enabled.indexOf(child) === -1
                      ) {
                        this.hideControls.push(child);
                        showControls.splice(showControls.indexOf(child), 1);
                      }
                    });
                  }
                }
              );
            }
          }
        }
      }
    },

    checkHighlight(fieldName) {
      if (this.dataValidationValues.length) {
        let status = this.dataValidationValues.find(
          (row) => row.fieldName == fieldName
        )?.status;
        if (status === "Matched" || status === "Verified") {
          return "green";
        } else if (status === "" || status === "Paritally Matched") {
          return "orange";
        } else if (status === "Not Matched") {
          return "orange";
        }
      } else if (this.ocrResult.length) {
        let value = this.ocrResult.find((item) => item.name === fieldName);
        if (value && value.prediction) {
          return value.prediction === "Expired" ? "pink" : "pink";
        } else {
          return "";
        }
      }
      return "";
    },

    async dataVerify(fieldId, fieldName) {
      if (this.dataValidationValues.length) {
        let field = this.dataValidationValues.find(
          (row) => row.fieldName == fieldName
        );
        if (field) {
          field.formValue = this.fieldsModel[fieldId];
          field.status = "Verified";
          const { error } = await custom.updateDataValidation(field);
          if (error) {
            this.$alert.error(error);
            return;
          }
        }
      }
    },

    mandatoryControls(controls, settings, immediate, fieldId) {
      this.$emit("mandatoryControls", controls, settings, immediate, fieldId);
    },

    uploadFiles(value, controls, fieldId, aiSettings) {
      this.$emit("uploadFiles", value, controls, fieldId, aiSettings);
    },

    checkDateParentControl(fieldId) {
      let parentFieldFound = false;
      this.formControls.forEach((field) => {
        if (
          field.settings.specific.parentDateField &&
          field.settings.specific.defaultValue === "PARENT_FIELD"
        ) {
          if (fieldId === field.settings.specific.parentDateField) {
            parentFieldFound = true;
            return;
          }
        }
      });
      return parentFieldFound;
    },

    fillChildDateControl(value, field) {
      this.formControls.forEach((rowField) => {
        if (rowField.settings.specific.parentDateField) {
          if (field.id === rowField.settings.specific.parentDateField) {
            if (this.selectedProcess) {
              if (!Object.keys(this.selectedProcess).length) {
                rowField.filterBy = [
                  {
                    criteria: field.settings.specific.masterFormColumn,
                    condition: "IS_EQUALS_TO",
                    value: this.fieldsModel[field.id],
                    dataType: "",
                  },
                ];
                //rowField.filter = rowField.filter ? !rowField.filter : true;
                rowField.dateFilter = rowField.dateFilter + 1;
                rowField.parentFieldValue = value;
              }
            }
          }
        }
      });
    },

    deletedFiles(ids, files) {
      this.$emit("deletedFiles", ids, files);
    },

    setReadonlyControls(controls, settings, immediate, fieldId) {
      this.$emit("setReadonlyControls", controls, settings, immediate, fieldId);
    },

    checkDateParentOptionsControl(fieldId) {
      let parentFieldFound = false;

      this.formControls.forEach((field) => {
        if (
          field.settings.specific.parentOptionField &&
          field.settings.specific.defaultValue === "PARENT_FIELD_OPTIONS" &&
          field.settings.specific.dateFieldOptionSettings.length
        ) {
          let index = field.settings.specific.dateFieldOptionSettings.findIndex(
            (date) => {
              return date.dateField === fieldId;
            }
          );
          if (index >= 0) {
            parentFieldFound = true;
            return;
          }
        }
      });
      return parentFieldFound;
    },

    checkDateOptionsControl(fieldId) {
      // console.log(fieldId);

      let hasParent = this.formControls.find(
        (field) => field.settings.specific.parentOptionField === fieldId
      );

      if (hasParent) {
        return true;
      } else {
        return false;
      }
    },

    fillChildDateUsingOptions(value, field) {
      this.formControls.forEach((rowField) => {
        if (
          rowField.settings.specific.parentOptionField &&
          rowField.settings.specific.defaultValue === "PARENT_FIELD_OPTIONS" &&
          rowField.settings.specific.dateFieldOptionSettings.length
        ) {
          let dateIndex =
            rowField.settings.specific.dateFieldOptionSettings.findIndex(
              (date) => {
                return date.dateField === field.id;
              }
            );

          let optionIndex =
            rowField.settings.specific.dateFieldOptionSettings.findIndex(
              (date) => {
                return date.option === value;
              }
            );

          // console.log(dateIndex, rowField, optionIndex, "options");
          if (dateIndex >= 0) {
            //rowField.filter = rowField.filter ? !rowField.filter : true;
            rowField.dateFilterOptions = !rowField.dateFilterOptions;
            rowField.parentOptionsSettings = {
              dateValue: value,
              optionField:
                this.fieldsModel[rowField.settings.specific.parentOptionField],
            };
            // rowField.parentFieldValue = value;
          } else {
            if (optionIndex >= 0) {
              //rowField.filter = rowField.filter ? !rowField.filter : true;
              rowField.dateFilterOptions = !rowField.dateFilterOptions;
              rowField.parentOptionsSettings = {
                dateValue:
                  this.fieldsModel[
                    rowField.settings.specific.dateFieldOptionSettings[
                      optionIndex
                    ].dateField
                  ],
                optionField: value,
              };
              // rowField.parentFieldValue = value;
            }
          }
        }
      });
    },

    fillDateFieldInPanel(dateId, fieldId) {
      if (this.fieldsModel[dateId]) {
        this.fieldsModel[fieldId] = this.fieldsModel[dateId];
      }
    },

    checkPrediction(label) {
      let value = this.ocrResult.find((item) => item.name === label);
      if (value) {
        return value.prediction;
      } else {
        return "";
      }
    },

    showQr(id, settings) {
      this.$emit("showQr", id, settings);
    },

    updateFormSearch(value, field) {
      if (isEmpty(this.selectedProcess)) {
        let formId = "";
        if (field.settings.specific.hasSameForm === "YES") {
          formId = this.formId;
        } else {
          formId = field.settings.specific.searchFormId;
        }
        this.getFormSearchValue(value, field, formId);
      }
    },

    async getFormSearchValue(value, field, formId) {
      if (this.externalLink) {
        this.$emit("loading", true);
      }
      this.$store.commit("showLoadingBar");

      let filters = [];
      if (field.settings.specific.hasSameForm === "YES") {
        filters.push({
          id: this.$nano.id(),
          criteria: field.id,
          criteriaArray: [field.id],
          condition: "IS_EQUALS_TO",
          value: value,
          arrayValue: [],
          dataType: field.type,
        });
      } else {
        filters.push({
          id: this.$nano.id(),
          criteria: field.settings.specific.masterSyncField,
          criteriaArray: [field.settings.specific.masterSyncField],
          condition: "IS_EQUALS_TO",
          value: value,
          arrayValue: [],
          dataType: field.type,
        });
      }

      const { error, payload } = await form.getFormEntries(formId, {
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            id: this.$nano.id(),
            filters: filters,
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
        portalId: this.portalId,
      });

      this.$store.commit("hideLoadingBar");
      if (this.externalLink) {
        this.$emit("loading", false);
      }

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      if (meta.totalItems) {
        if (data.length) {
          let model = data[0].value[0];
          this.updateValueFromSearch = {};
          if (field.settings.specific.hasSameForm === "YES") {
            if (field.settings.specific.formSyncField) {
              if (field.settings.specific.formSyncField.length) {
                let fields = field.settings.specific.formSyncField;
                fields.forEach((fieldId) => {
                  if (fieldId in this.fieldsModel) {
                    if (!this.fieldsModel[fieldId]) {
                      if (this.isJsonString(model[fieldId])) {
                        this.fieldsModel[fieldId] = JSON.parse(model[fieldId]);
                        this.$set(
                          this.updateValueFromSearch,
                          fieldId,
                          JSON.parse(model[fieldId])
                        );
                      } else {
                        this.fieldsModel[fieldId] = model[fieldId];
                        this.$set(
                          this.updateValueFromSearch,
                          fieldId,
                          model[fieldId]
                        );
                      }
                    }
                  }
                });
              } else {
                for (let key in model) {
                  if (key in this.fieldsModel) {
                    if (!this.fieldsModel[key]) {
                      if (this.isJsonString(model[key])) {
                        this.fieldsModel[key] = JSON.parse(model[key]);
                        this.$set(
                          this.updateValueFromSearch,
                          key,
                          JSON.parse(model[key])
                        );
                      } else {
                        this.fieldsModel[key] = model[key];
                        this.$set(this.updateValueFromSearch, key, model[key]);
                      }
                    }
                  }
                }
              }
            } else {
              for (let key in model) {
                if (key in this.fieldsModel) {
                  if (this.isJsonString(model[key])) {
                    if (!this.fieldsModel[key]) {
                      this.fieldsModel[key] = JSON.parse(model[key]);
                      this.$set(
                        this.updateValueFromSearch,
                        key,
                        JSON.parse(model[key])
                      );
                    } else {
                      this.fieldsModel[key] = model[key];
                      this.$set(this.updateValueFromSearch, key, model[key]);
                    }
                  }
                }
              }
            }

            this.$emit("formSaveType", "UPDATE", model.itemid);
          } else {
            console.log(field.settings.specific.masterFormSyncSettings);
            let settings = field.settings.specific.masterFormSyncSettings;
            if (settings && settings.length) {
              settings.forEach((control) => {
                if (!this.fieldsModel[control.formField]) {
                  if (this.isJsonString(model[control.masterField])) {
                    this.fieldsModel[control.formField] = JSON.parse(
                      model[control.masterField]
                    );
                    this.$set(
                      this.updateValueFromSearch,
                      control.masterField,
                      JSON.parse(model[control.masterField])
                    );
                  } else {
                    this.fieldsModel[control.formField] =
                      model[control.masterField];
                    this.$set(
                      this.updateValueFromSearch,
                      control.masterField,
                      model[control.masterField]
                    );
                  }
                }
              });

              console.log(this.fieldsModel);
            }
          }
        }
      } else {
        this.$alert.info("No matches were found");
        if (field.settings.specific.hasSameForm === "YES") {
          let fields = field.settings.specific.formSyncField;
          if (fields && fields.length) {
            fields.forEach((fieldId) => {
              if (fieldId in this.fieldsModel) {
                if (typeof this.fieldsModel[fieldId] === "object") {
                  this.fieldsModel[fieldId] = [];
                  this.updateValueFromSearch[fieldId] = [];
                } else {
                  this.fieldsModel[fieldId] = "";
                  this.updateValueFromSearch[fieldId] = "";
                }
              }
            });
          } else {
            for (let fieldId in this.fieldsModel) {
              if (fieldId !== field.id) {
                if (typeof this.fieldsModel[fieldId] === "object") {
                  this.fieldsModel[fieldId] = [];
                  this.updateValueFromSearch[fieldId] = [];
                } else {
                  this.fieldsModel[fieldId] = "";
                  this.updateValueFromSearch[fieldId] = "";
                }
              }
            }
          }
          this.$emit("formSaveType", "ADD", 0);
        }
      }
    },

    scanQRCode() {
      this.showQRRead = true;
    },

    qrReadValue(qrData) {
      console.log(qrData);
      this.showQRRead = false;
      let origin = location.origin;
      if (qrData) {
        if (this.isJsonString(qrData)) {
          const qrObj = JSON.parse(qrData);
          console.log(qrObj);
          for (const keyName in qrObj) {
            if (keyName !== "URL") {
              let col = this.formControls.find((row) => row.label === keyName);

              if (col) {
                this.fieldsModel[col.id] = qrObj[keyName];
                this.$set(this.updateValueFromSearch, col.id, qrObj[keyName]);
                console.log(this.updateValueFromSearch);
              }
            }
          }
        } else {
          if (
            (origin === "https://trial.ezofis.com" &&
              this.$store.state.session.tenantId === 13) ||
            (origin === "https://app.ezofis.com" &&
              this.$store.state.session.tenantId === 3) ||
            (origin === "http://localhost:8080" &&
              (this.$store.state.session.tenantId === 13 ||
                this.$store.state.session.tenantId === 3))
          ) {
            let qr = qrData.split("|");
            console.log(qr);
            qr.forEach((value) => {
              if (value) {
                // console.log(value.split("="));
                let data = value.split("=");
                if (data.length === 2) {
                  if (data[0] === "B") {
                    let batchNo = this.formControls.find(
                      (item) => item.label === "Batch Number"
                    );
                    if (batchNo) {
                      this.fieldsModel[batchNo.id] = data[1];
                    }
                  } else if (data[0] === "C") {
                    let productionDate = this.formControls.find(
                      (item) => item.label === "ProductionDate"
                    );
                    if (productionDate) {
                      this.fieldsModel[productionDate.id] = data[1];
                    }
                  } else if (data[0] === "D") {
                    let expireDate = this.formControls.find(
                      (item) => item.label === "ExpireDate"
                    );
                    if (expireDate) {
                      this.fieldsModel[expireDate.id] = data[1];
                    }
                  } else if (data[0] === "E") {
                    let productionName = this.formControls.find(
                      (item) => item.label === "PRODUCT NAME"
                    );
                    if (productionName) {
                      this.fieldsModel[productionName.id] = data[1];
                    }
                  } else if (data[0] === "F") {
                    let storageTank = this.formControls.find(
                      (item) => item.label === "Storage Tank"
                    );
                    if (storageTank) {
                      this.fieldsModel[storageTank.id] = data[1];
                    }
                  } else if (data[0] === "G") {
                    let packaging = this.formControls.find(
                      (item) => item.label === "Carton"
                    );
                    if (packaging) {
                      this.fieldsModel[packaging.id] = data[1];
                    }
                  }
                } else if (data.length === 1) {
                  let idCode = this.formControls.find(
                    (item) => item.label === "ID CODE"
                  );
                  if (idCode) {
                    this.fieldsModel[idCode.id] = data[0];
                    let parent = this.formControls.find(
                      (item) =>
                        item.settings.specific.masterFormParentColumn ===
                        idCode.id
                    );
                    console.log(parent, "parent");
                    if (parent) {
                      this.filterChildControlValues(idCode, "handle");
                    }
                  }
                }
              }
            });
          }
        }
      }
    },

    getParentColumnValue(fieldId) {
      if (isEmpty(this.selectedProcess) || this.hasInbox) {
        if (this.fieldsModel[fieldId]) {
          let field = this.formControls.find((item) => item.id === fieldId);
          if (field) {
            this.filterChildControlValues(field, undefined, true);
          }
        }
      }
    },

    getParentColumnValueInTable(fieldId) {
      if (isEmpty(this.selectedProcess) || this.hasInbox) {
        if (this.fieldsModel[fieldId]) {
          let field = this.formControls.find((item) => item.id === fieldId);
          if (field) {
            this.filterChildControlValuesInTable(field);
          }
        }
      }
    },

    uploadFilesInTable(value) {
      this.$emit("uploadFilesInTable", value);
    },

    deletedFilesInTable(value) {
      this.$emit("deletedFilesInTable", value);
    },

    viewFile(file) {
      this.$emit("viewFile", file);
    },

    updateAddressFieldValues(field) {
      this.formControls.forEach((rowField) => {
        field.forEach((addressField) => {
          addressField.selectfield.forEach((selectFieldId) => {
            if (selectFieldId === rowField.id) {
              this.fieldsModel[selectFieldId] = addressField.value || " ";
            }
          });
        });
      });
    },

    showQrInTable(value) {
      this.$emit("showQrInTable", value);
    },

    checkLookupParentControl(fieldId) {
      let parentFieldFound = false;
      this.formControls.forEach((field) => {
        if (
          field.settings.lookupSettings &&
          field.settings.lookupSettings.parentField
        ) {
          if (fieldId === field.settings.lookupSettings.parentField) {
            parentFieldFound = true;
            return;
          }
        }

        if (field.type === "TABLE") {
          let columns = field.settings.specific.tableColumns;
          columns.forEach((column) => {
            if (
              column.settings.lookupSettings &&
              column.settings.lookupSettings.parentField
            ) {
              if (fieldId === column.settings.lookupSettings.parentField) {
                parentFieldFound = true;
                return;
              }
            }
          });
        }
      });
      return parentFieldFound;
    },

    lookupControlFilter(field, isManualChange) {
      console.log(field, isManualChange);
      let parentFieldFilter = false;
      let tableType = this.formControls.filter((type) => type.type === "TABLE");
      // console.log(tableType, "isManualChange");
      if (tableType.length) {
        tableType.forEach((table) => {
          // console.log(table, "table");
          let columns = table.settings.specific.tableColumns;
          // console.log(columns, field, "columns");
          let hasParentField = columns.findIndex((column) => {
            return field.id === column.settings.lookupSettings?.parentField;
          });
          // console.log(hasParentField, "hasParentField");
          if (hasParentField > -1) {
            parentFieldFilter = true;
          }
        });
      }
      console.log(parentFieldFilter, "parentFieldFilter");
      if (parentFieldFilter) {
        this.lookupControlFilterInTable(field, isManualChange);
      }
      this.formControls.forEach((rowField) => {
        // console.log(rowField.settings.lookupSettings, rowField.label);
        if (
          rowField.settings.lookupSettings &&
          rowField.settings.lookupSettings.parentField
        ) {
          if (field.id === rowField.settings.lookupSettings.parentField) {
            if (
              rowField.settings.lookupSettings &&
              rowField.settings.lookupSettings.columnName
            ) {
              // console.log(rowField.label, "isManualChange");
              let filter = this.formControls.find(
                (lookup) => lookup.id === field.id
              );
              if (
                filter &&
                filter.settings.lookupSettings &&
                filter.settings.lookupSettings.columnName
              ) {
                rowField.lookupFilterBy = [
                  {
                    criteria: filter.settings.lookupSettings.columnName,
                    condition: "IS_EQUALS_TO",
                    value: this.fieldsModel[field.id],
                    dataType: filter.type,
                  },
                ];

                rowField.lookupFilter = this.$nano.id();
                rowField.isManualChange = isManualChange;
              }
            }
          }
        }
      });
    },

    lookupControlFilterInTable(field, isManualChange) {
      this.formControls.forEach((rowField) => {
        if (rowField.type === "TABLE") {
          let columns = rowField.settings.specific.tableColumns;
          columns.forEach((column) => {
            // console.log(rowField.settings.lookupSettings, rowField.label);
            if (
              column.settings.lookupSettings &&
              column.settings.lookupSettings.parentField
            ) {
              if (field.id === column.settings.lookupSettings.parentField) {
                if (
                  column.settings.lookupSettings &&
                  column.settings.lookupSettings.columnName
                ) {
                  // console.log(rowField.label, "isManualChange");
                  let filter = this.formControls.find(
                    (lookup) => lookup.id === field.id
                  );
                  if (
                    filter &&
                    filter.settings.lookupSettings &&
                    filter.settings.lookupSettings.columnName
                  ) {
                    column.lookupFilterBy = [
                      {
                        criteria: filter.settings.lookupSettings.columnName,
                        condition: "IS_EQUALS_TO",
                        value: this.fieldsModel[field.id],
                        dataType: filter.type,
                      },
                    ];

                    rowField.lookupFilterInTable = this.$nano.id();
                    column.isManualChange = isManualChange;
                    console.log(column.lookupFilterBy, "column.lookupFilterBy");
                  }
                }
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#panel {
  background-color: var(--component-bg-color);
  border-radius: 4px;

  .title {
    @extend .text-md;
    font-weight: 600;
    text-transform: capitalize;
  }

  .description {
    @extend .text-sm;
    color: var(--icon-color-inverted);
  }
}
</style>
