<template>
  <Sheet
    :value="value"
    width="50vw"
    height="70vh"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title> Lookup Connect </template>

    <!-- ... -->

    <template #default>
      <div class="q-pa-md">
        <ValidationObserver ref="form">
          <div class="field-wrapper">
            <ValidationProvider
              v-slot="{ errors }"
              name="Settings Name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="name"
                label="Settings Name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <!-- <ValidationProvider
              v-slot="{ errors }"
              name="Type"
              :rules="{ required: true }"
            >
              <SelectField
                v-model="type"
                label="Type"
                is-mandatory
                :options="types"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider> -->

            <ValidationProvider
              v-slot="{ errors }"
              name="Type"
              :rules="{ required: true }"
            >
              <SingleChoiceField
                v-model="type"
                is-mandatory
                label="Type"
                :options="types"
                :error="errors[0]"
                class="q-mb-md singleChoice"
              />
            </ValidationProvider>

            <ValidationProvider
              v-if="type === 'TABLE'"
              v-slot="{ errors }"
              name="Table Name"
              :rules="validationRule"
            >
              <SelectField
                v-model="tableName"
                label="Table Name"
                is-mandatory
                :options="tables"
                :error="errors[0]"
                class="q-mb-md"
                @search="updateTableOptions"
                @enter="getDatabaseList(searchName, '=')"
              />
              <AsyncSelectField
                v-if="false"
                v-model="tableName"
                is-mandatory
                label="Table Name"
                class="q-mb-md"
                :error="errors[0]"
                :api-path="apiPath"
              />
            </ValidationProvider>
            <ValidationProvider
              v-if="type === 'VIEW'"
              v-slot="{ errors }"
              name="Table Name"
              :rules="validationRule"
            >
              <SelectField
                v-model="viewName"
                label="View Name"
                is-mandatory
                :options="views"
                :error="errors[0]"
                class="q-mb-md"
                @search="updateTableOptions"
                @enter="getDatabaseList(searchName, '=')"
              />
            </ValidationProvider>

            <BaseScrollbar class="q-pb-sm">
              <!-- <table id="lookup">
                <thead>
                  <tr>
                    <th class="medium">Column</th>
                    <th class="medium">Value</th>
                    <th class="extra-small">
                      <BaseActionButton
                        no-border
                        icon="eva-plus"
                        is-flat
                        color="secondary"
                        @click="addRow"
                      />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(row, index) in columns" :key="row.id">
                    <td>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Column"
                        :rules="{ required: true }"
                      >
                        <SelectField
                          v-model="row.columnName"
                          :options="columnList"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </td>
                    <td>
                      <TextField v-model="row.value" />
                    </td>
                    <td>
                      <BaseActionButton
                        no-border
                        icon="eva-trash-outline"
                        is-flat
                        color="red"
                        @click="deleteRow(index)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table> -->
              <Filters
                v-if="columnList.length"
                :columns="columnList"
                :filter-by.sync="columnsCondition"
                :edit-columns="lookup"
              />
            </BaseScrollbar>

            <BaseButton
              label="Run Query"
              color="secondary"
              style="float: right"
              class="q-mt-md"
              @click="runQuery"
            />
            <!-- <div v-if="queryResult.length">
              <FormFieldLabel label="Result" class="q-mt-md" />

              <table class="q-mt-lg">
                <thead>
                  <tr>
                    <th
                      v-for="(value1, key) in queryResult[0]"
                      :key="key"
                      class="medium"
                    >
                      {{ key }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(res, index) in queryResult" :key="index">
                    <td v-for="(value1, key) in queryResult[0]" :key="key">
                      {{ res[key] }}
                    </td>
                   
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
        </ValidationObserver>
      </div>

      <Modal
        v-model="queryResultModal"
        width="60vw"
        @input="queryResultModal = false"
      >
        <!-- title -->

        <template #title>Query Result</template>

        <!-- ... -->

        <template #default>
          <div id="query-result">
            <table class="q-mr-md">
              <thead>
                <tr>
                  <th v-for="(value1, key) in queryResult[0]" :key="key">
                    {{ key }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(res, index) in queryResult" :key="index">
                  <td v-for="(value1, key) in queryResult[0]" :key="key">
                    {{ res[key] }}
                  </td>
                  <!-- <td>{{ res }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </Modal>
    </template>

    <template #footer>
      <BaseButton
        :label="lookup.id ? 'Update' : 'Save'"
        color="primary"
        @click="connectLookUp"
      />
    </template>
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { connector } from "@/api/factory.js";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import DataType from "@/constants/data-type.js";
import Filters from "@/components/common/Filters.vue";
import AsyncSelectField from "@/components/common/form/async-select-field/AsyncSelectField.vue";
// import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import Modal from "@/components/common/popup/Modal.vue";

export default {
  name: "LookupConnect",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    SelectField,
    SingleChoiceField,
    Filters,
    AsyncSelectField,
    // FormFieldLabel,
    Modal,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    lookup: {
      type: Object,
      default: () => {},
    },

    connectorId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      name: "",
      type: "TABLE",
      types: [
        {
          id: this.$nano.id(),
          label: "Table",
          value: "TABLE",
        },
        {
          id: this.$nano.id(),
          label: "View",
          value: "VIEW",
        },
      ],
      tableName: "",
      tables: [],
      viewName: "",
      views: [],
      columns: [
        {
          id: this.$nano.id(),
          columnName: "",
          value: "",
        },
      ],
      columnList: [],
      columnsCondition: [],
      queryResult: [],
      queryResultModal: false,
      searchName: "",
    };
  },

  computed: {
    validationRule() {
      let rule = { required: true };
      if (this.type === "TABLE") {
        return rule;
      } else if (this.type === "VIEW") {
        return rule;
      } else {
        return { required: false };
      }
    },

    apiPath() {
      return `/repository/1/uniqueColumnValues`;
      // return `/repository/${this.$route.query.repositoryId}/uniqueColumnValues`;
    },

    buttonDisabled() {
      if (this.lookup.id) {
        if (
          this.lookup.conditionDataPoint.filterBy.length ===
          this.columnsCondition[0].filters.length
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return !this.queryResult.length;
      }
      // return false;
    },
  },

  watch: {
    lookup: {
      immediate: true,
      handler() {
        this.reset();
        if (this.lookup.id) {
          this.name = this.lookup.name;
          this.tableName = this.lookup.tableName;
          if (this.lookup.conditionDataPoint) {
            let condition = JSON.parse(this.lookup.conditionDataPoint);
            if (condition && condition.filterBy) {
              this.columnsCondition = condition.filterBy;
            }
          }
          if (this.tableName) {
            this.type = "TABLE";
            this.getDatabaseList(this.tableName, "", "edit");
          }
        }
      },
    },

    type: {
      // immediate: true,
      handler() {
        if (this.type) {
          // this.getDatabaseList("");
        }
      },
    },

    tableName: {
      immediate: true,
      async handler() {
        if (this.tableName) {
          if (this.lookup.id) {
            if (this.tableName !== this.lookup.tableName) {
              this.columnsCondition = [];
            }
          } else {
            this.columnsCondition = [];
          }
          await this.getColumns();
        }
      },
    },

    viewName: {
      immediate: true,
      async handler() {
        if (this.viewName) {
          if (this.lookup.id) {
            if (this.viewName !== this.lookup.tableName) {
              this.columnsCondition = [];
            }
          } else {
            this.columnsCondition = [];
          }
          await this.getColumns(this.viewName);
        }
      },
    },
  },

  methods: {
    closeSheet() {
      this.$emit("close");
    },

    reset() {
      this.name = "";
      this.tableName = "";
      this.viewName = "";
      this.columnsCondition = [];
      this.views = [];
      this.queryResult = [];
      this.columnList = [];
    },

    updateTableOptions(name) {
      this.searchName = name;
      this.getDatabaseList(name);
    },

    async getDatabaseList(name, operator, fromEdit) {
      this.tables = [];
      this.views = [];
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getDatabaseList({
        connectorId: this.connectorId,
        type: this.type,
        tableName: name,
        queryOperator: operator ? "=" : "CONTAINS",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);

        return;
      }
      // this.tables = [];
      if (payload.length) {
        payload.forEach((item) => {
          if (this.type === "TABLE") {
            this.tables.push({
              id: this.$nano.id(),
              label: item.table_name,
              value: item.table_name,
            });
          } else if (this.type === "VIEW") {
            this.views.push({
              id: this.$nano.id(),
              label: item.view_name,
              value: item.view_name,
            });
          }
        });
      } else {
        if (fromEdit && this.lookup && this.lookup.id) {
          this.type = "VIEW";
          this.viewName = this.tableName;
          this.getDatabaseList(this.tableName, "");
        }
      }
    },

    async getColumns(viewName) {
      this.columnList = [];
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getColumns({
        connectorId: this.connectorId,
        tableName: viewName || this.tableName,
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload);
      // this.tables = payload;
      payload.forEach((item) => {
        this.columnList.push({
          id: this.$nano.id(),
          label: item.COLUMN_NAME,
          value: item.COLUMN_NAME,
          dataType: DataType.SHORT_TEXT,
        });
      });
    },

    addRow() {
      this.columns.push({
        id: this.$nano.id(),
        columnName: "",
        value: "",
      });
    },

    deleteRow(index) {
      this.columns.splice(index, 1);
    },

    async runQuery() {
      this.$store.commit("showLoadingBar");
      let condition = {
        filterBy: this.columnsCondition,
      };

      let responseMapping = {};
      let payloadMapping = {};
      for (const panel of this.columnsCondition) {
        panel.filters.forEach((item) => {
          this.$set(payloadMapping, item.criteria, `${item.value}`);
        });
      }
      this.columnList.forEach((item) => {
        this.$set(responseMapping, `${item.label}`, `${item.label}`);
      });

      let input = {
        connectorId: this.connectorId,
        hubPoint: this.type === "TABLE" ? this.tableName : this.viewName,
        conditionDataPoint: JSON.stringify(condition),
        responseMapping: JSON.stringify(responseMapping),
        payloadMapping: JSON.stringify(payloadMapping),
      };
      // console.log(input);

      const { error, payload } = await connector.testQuery(input);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload);
      if (payload.data.length) {
        this.queryResultModal = true;
        this.queryResult = payload.data[0].value;
      } else {
        this.$alert.warning("no result found");
      }
    },

    async connectLookUp() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.$store.commit("showLoadingBar");
      let columns = [];
      for (const column of this.columnsCondition) {
        column.filters.forEach((item) => {
          columns.push({
            id: this.$nano.id(),
            key: item.criteria,
            value: item.value,
          });
        });
      }

      let outDataPoint = [];

      if (this.lookup.id) {
        if (this.queryResult.length) {
          for (let key in this.queryResult[0]) {
            outDataPoint.push(key);
          }
        } else if (this.lookup.outDataPoint) {
          outDataPoint = this.lookup.outDataPoint;
        } else {
          this.columnList.forEach((column) => {
            outDataPoint.push(column.value);
          });
        }
      } else {
        if (this.queryResult.length) {
          for (let key in this.queryResult[0]) {
            outDataPoint.push(key);
          }
        } else {
          this.columnList.forEach((column) => {
            outDataPoint.push(column.value);
          });
        }
      }

      let input = {
        name: this.name,
        connectorId: this.connectorId,
        hubPoint: this.type === "TABLE" ? this.tableName : this.viewName,
        inDataPoint: JSON.stringify(columns),
        outDataPoint: JSON.stringify(outDataPoint),
        conditionDataPoint: JSON.stringify({ filterBy: this.columnsCondition }),
        executionType: "select",
      };

      if (this.lookup.id) {
        const { error } = await connector.updateLookUp(this.lookup.id, input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        const { error } = await connector.connectLookUp(input);
        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        // console.log(payload);
      }
      if (this.lookup.id) {
        this.$alert.success(`Lookup edited successfully`);
      } else {
        this.$alert.success(`Lookup connected successfully`);
      }
      this.closeSheet();
      this.$emit("save");
    },
  },
};
</script>
<style lang="scss">
#query-result {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;
    // margin-right: 10px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

#single-choice-field.singleChoice {
  .options-wrapper {
    border-top: none !important;
    padding-top: 0px !important;
  }
}
</style>
