<template>
  <div id="advance-view">
    <div class="row items-center">
      <template v-if="hasChangeRow"></template>
      <template v-for="(row, idx) in rows">
        <div
          :key="idx"
          class="col-6 q-pt-sm file-list"
          @mouseover="
            showMouseOver = true;
            mouseOverIndex = idx;
          "
          @mouseleave="
            showMouseOver = false;
            mouseOverIndex = null;
          "
        >
          <div v-for="column in tableColumns" :key="column.id">
            <template
              v-if="column.type === 'FILE_UPLOAD' && tableFieldsModel[idx]"
            >
              <div v-if="tableFieldsModel[idx][column.id].length">
                <div
                  v-for="file in tableFieldsModel[idx][column.id]"
                  :key="file.fileId"
                  class="row items-center q-mb-sm"
                >
                  <div class="col">
                    <div class="row items-center">
                      <!-- <img src="@/assets/file.jpg" /> -->
                      <template v-if="file.fileId">
                        <div class="col-auto shadow-3 thumb-img q-mr-sm">
                          <img :src="thumbnailLink(file)" class="" />
                        </div>
                        <div class="col">
                          <div
                            class="fileName title-2"
                            @mouseover="showFileThumbnail(file, $event)"
                            @mouseleave="hideThumbnail(file)"
                            @click="editRow(file, idx)"
                          >
                            {{ file.name }}
                          </div>
                          <div class="row q-mt-xs description">
                            <div
                              v-for="(col, index) in tableColumns"
                              :key="col.id"
                              class="description"
                            >
                              <template v-if="col.type !== 'FILE_UPLOAD'">
                                <template v-if="index < 4">
                                  {{ col.label }} :
                                  {{ tableFieldsModel[idx][col.id] }}
                                  <span v-if="index < 3" class="q-mx-xs"
                                    >/</span
                                  >
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="file.itemId">
                        <div class="col-auto shadow-3 thumb-img q-mr-sm">
                          <img :src="thumbnailLink(file)" class="" />
                        </div>
                        <div class="col">
                          <div
                            class="fileName"
                            @mouseover="showFileThumbnail(file, $event)"
                            @mouseleave="hideThumbnail(file)"
                            @click="editRow(file, idx)"
                          >
                            {{ file.fileName }}
                          </div>
                          <div class="row q-mt-xs description">
                            <div
                              v-for="(col, index) in tableColumns"
                              :key="col.id"
                              class="description"
                            >
                              <template v-if="col.type !== 'FILE_UPLOAD'">
                                <template v-if="index < 4">
                                  {{ col.label }} :
                                  {{ tableFieldsModel[idx][col.id] }}
                                  <span v-if="index < 3" class="q-mx-xs"
                                    >/</span
                                  >
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div
                    v-if="showMouseOver && mouseOverIndex === idx"
                    class="col-auto"
                  >
                    <BaseActionButton
                      v-if="!isReadonly"
                      v-tooltip.top="'delete row'"
                      icon="eva-trash-outline"
                      color="red"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="deleteRow(row, column.id)"
                    />
                    <!-- <q-chip
                      dense
                      outline
                      clickable
                      color="grey"
                      text-color="white"
                      class="cursor-pointer"
                      @click="getFileData(file)"
                    >
                      Info
                    </q-chip> -->
                  </div>
                </div>

                <div
                  v-show="showThumbnail"
                  class="shadow-3 fixed-left thumbnail-img"
                  :style="{ top: mouseY, left: mouseX }"
                >
                  <img
                    v-show="isLoaded"
                    :src="thumbnailUrl"
                    @load="onImgLoad"
                  />

                  <q-spinner
                    v-if="!isLoaded"
                    color="primary-3"
                    size="48px"
                    class="spinner"
                  />
                </div>
              </div>
              <div v-else>No File</div>
            </template>
          </div>
        </div>
      </template>

      <template v-if="!rows">
        <div class="col-12 no-file q-pa-md">
          <div class="row justify-center q-mb-sm">
            <BaseIcon name="mdi-file-find-outline" size="32px" />
          </div>
          <div class="row justify-center title-2 text-primary">
            You have no files yet
          </div>
        </div>
      </template>
    </div>

    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>
  </div>
</template>
<script>
import { lowerCase, isEmpty } from "lodash-es";
// import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import { repository } from "@/api/factory.js";
import FileDetails from "@/views/workflows/views/workflows-inbox/components/FileDetails.vue";

export default {
  name: "AdvanceTableField",

  components: {
    // CheckboxField,
    FileDetails,
  },

  props: {
    tableColumns: {
      type: Array,
      default: () => [],
    },

    rows: {
      type: Number,
      default: 0,
    },

    showColumnTotal: {
      type: Boolean,
      default: false,
    },

    tableRowsType: {
      type: String,
      default: "",
    },

    tableFixedRowLabels: {
      type: Array,
      default: () => [],
    },

    tableFieldsModel: {
      type: Array,
      default: () => [],
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    repositoryDetails: {
      type: Object,
      default: () => {},
    },

    selectedRow: {
      type: Array,
      default: () => [],
    },

    hasChangeRow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectAll: false,
      // selectedRow: [],
      thumbnailUrl: "",
      showThumbnail: false,
      itemId: "",
      mouseX: "",
      mouseY: "",
      isLoaded: false,
      fileData: [],
      fileDetailsSheet: false,
      mouseOverIndex: null,
      showMouseOver: false,
    };
  },

  methods: {
    getClass(columnSize) {
      return lowerCase(columnSize);
    },

    getLabel(rowIdx, columnId) {
      if (isEmpty(this.tableFixedRowLabels)) {
        return "";
      }

      return this.tableFixedRowLabels[rowIdx][columnId];
    },

    getSerialNumber(idx, columnId) {
      this.tableFieldsModel[idx][columnId] = idx + 1;
      return idx + 1;
    },

    selectRow(rowIndex, columnId, value) {
      //   if (this.tableRowChooseType === "SINGLE_ROW") {
      //     this.tableFieldsModel.forEach((row) => {
      //       row[columnId] = false;
      //     });
      //   }
      this.tableFieldsModel[rowIndex][columnId] = !value;
    },

    deleteRow(row, fileFieldId) {
      this.$emit("deleteRow", row, fileFieldId);
    },

    getTableColumnSum(columnId) {
      console.log(columnId);
    },

    addRow() {
      this.$emit("addRow");
    },

    editRow(row, index) {
      let data = {
        row: row,
        index: index,
      };
      this.$emit("editRow", data);
    },

    thumbnailLink(file) {
      if (file.fileId) {
        let type = 1;
        if (this.repositoryDetails.fieldsType === "STATIC") {
          type = 2;
        }
        return `${process.env.VUE_APP_API_URL}/uploadAndIndex/viewThumbnail/${this.$store.state.session.tenantId}/${file.fileId}/${type}`;
      } else if (file.itemId) {
        return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${file.repositoryId}/${file.itemId}/1`;
      }
    },

    handleSelectAll(value, index) {
      console.log(value);
      // this.selectedRow = [];
      if (value) {
        this.selectedRow.push(index);
      } else {
        console.log(this.selectedRow.includes(index));

        if (this.selectedRow.includes(index)) {
          this.selectedRow.splice(index, 1);
        }
      }

      console.log(this.selectedRow);
      this.$emit("update:selectedRow", this.selectedRow);
    },

    showFileThumbnail(file, event) {
      //let ext = file.name.split(".").pop();
      //if (ext.toLowerCase() === "pdf") {
      let bodyHeight = this.$q.screen.height;
      // console.log(event.pageX, this.$q.screen.width);
      // console.log(bodyHeight, event.pageY);
      if (bodyHeight - event.pageY < 200) {
        this.mouseY = bodyHeight - 200 - 50 + "px";
      } else {
        this.mouseY = event.pageY + "px";
      }
      if (event.pageX > 700) {
        this.mouseX = "811px";
      } else {
        this.mouseX = "237px";
      }
      // this.itemId = file.id 811px;
      if (file.fileId) {
        let type = 1;
        if (this.repositoryDetails.fieldsType === "STATIC") {
          type = 2;
        }
        this.thumbnailUrl = `${process.env.VUE_APP_API_URL}/uploadAndIndex/viewThumbnail/${this.$store.state.session.tenantId}/${file.fileId}/${type}`;
      } else if (file.itemId) {
        this.thumbnailUrl = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${file.repositoryId}/${file.itemId}/1`;
      }
      this.showThumbnail = true;
      //}
    },

    hideThumbnail() {
      this.thumbnailUrl = "";
      this.showThumbnail = false;
      this.isLoaded = false;
    },

    onImgLoad() {
      this.isLoaded = true;
    },

    async getFileData(file) {
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.itemId,
      });
      //this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      if (file.fileData) {
        this.fileData = file.fileData;
      } else {
        this.fileData = payload;
      }
      this.fileDetailsSheet = true;
    },
  },
};
</script>

<style lang="scss">
#advance-view {
  .custom-table {
    // min-width: 900px;

    text-indent: initial;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;

    -webkit-border-horizontal-spacing: 0px !important;
    -webkit-border-vertical-spacing: 10px !important;
  }

  .custom-table thead {
    display: table-header-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
  }

  .custom-table,
  .advance-table thead th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: start !important;
    unicode-bidi: isolate;

    &.small {
      width: 70px;
    }

    &.medium {
      width: 140px;
    }

    &.large {
      width: 210px;
    }

    &.actions {
      width: 20px;
      &.qr-icon {
        width: 60px;
      }
    }

    .required {
      color: red;
      margin-left: 3px;
    }
  }

  .custom-table thead tr,
  .custom-table thead th {
    border-top: none;
    border-bottom: none !important;
    color: var(--primary);
    text-transform: uppercase;
    padding: 0px 10px;
    // padding: 15px 1px;
  }
  .custom-table tbody th,
  .custom-table tbody td {
    /// color: #777;
    font-weight: 400;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
    // font-weight: 300;
  }
  .custom-table tbody th small,
  .custom-table tbody td small {
    color: #b3b3b3;
    font-weight: 300;
  }
  .custom-table tbody tr:not(.spacer) {
    border-radius: 7px;
    overflow: hidden;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }
  .custom-table tbody tr:not(.spacer):hover {
    -webkit-box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
  }
  .custom-table tbody tr th,
  .custom-table tbody tr td {
    background: #eee;
    border: none;
    padding: 10px;
  }
  .custom-table tbody tr th:first-child,
  .custom-table tbody tr td:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  .custom-table tbody tr th:last-child,
  .custom-table tbody tr td:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .thumb-img {
    height: 65px;
    width: 69px;
    background: white;
    text-align: center;
    border-radius: 4px;

    img {
      width: 100%;
      border-radius: 3px;
    }
  }

  .fileName {
    color: $secondary;
  }

  .fileName:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .thumbnail-img {
    height: 200px;
    width: 200px;
    border-radius: 10px;
    background: white;
    text-align: center;
    /// position: absolute;

    img {
      width: 100%;
      border-radius: 3px;
    }

    .spinner {
      height: 100%;
    }
  }

  .advance-table {
    border-collapse: collapse;
    width: 100%;
  }

  .advance-table td,
  .advance-table th {
    padding: 15px;
  }

  //.advance-table tr:nth-child(even) {
  //background-color: var(--divider-color);
  //}

  .advance-table tr:hover {
    background-color: var(--divider-color);
  }
  .advance-table tr {
    // padding: 0px 8px;
    border: 1px solid var(--divider-color);
  }

  .advance-table th {
    background: var(--divider-color);
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
  }

  .description {
    @extend .text-sm;
    color: var(--icon-color);
  }

  .file-list:hover {
    background: var(--divider-color);
    border: 1px solid var(--divider-color);
    border-radius: 5px;
  }

  .no-file {
    background: var(--divider-color);
    border: 1px solid var(--divider-color);
    border-radius: 5px;
  }
}
</style>
