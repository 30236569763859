<template>
  <div id="otp-field">
    <template v-if="clockTick && time">
      <div v-if="!signUp" class="label q-mt-md" style="text-align: center">
        Enter the code generated in your {{ otpType }} to proceed!
      </div>
      <div v-else>
        <!-- <div class="title">Email Verification</div> -->
        <p class="label q-mt-md">
          Please check your email for the verification code. Enter it below to
          verify your account.
        </p>
      </div>
      <div class="col q-pa-md" style="text-align: center">
        <input
          v-model="OTPNumbers[0]"
          :autofocus="true"
          class="otpinput"
          min="0"
          max="9"
          maxlength="1"
          pattern="[0-9]"
          type="tel"
          @keypress="isNumber"
          @keypress.enter="validate(OTPNumbers)"
          @keyup="
            OTPNumbers[0] ? $event.target.nextElementSibling.focus() : false
          "
          @paste="onPaste"
        />
        <input
          v-model="OTPNumbers[1]"
          class="otpinput"
          min="0"
          max="9"
          maxlength="1"
          pattern="[0-9]"
          type="tel"
          @keypress="isNumber"
          @keypress.enter="validate(OTPNumbers)"
          @keyup="
            OTPNumbers[1] ? $event.target.nextElementSibling.focus() : false
          "
          @keyup.backspace="$event.target.previousElementSibling.focus()"
          @paste="onPaste"
        />
        <input
          v-model="OTPNumbers[2]"
          class="otpinput"
          min="0"
          max="9"
          maxlength="1"
          pattern="[0-9]"
          type="tel"
          @keypress="isNumber"
          @keypress.enter="validate(OTPNumbers)"
          @keyup="
            OTPNumbers[2] ? $event.target.nextElementSibling.focus() : false
          "
          @keyup.backspace="$event.target.previousElementSibling.focus()"
          @paste="onPaste"
        />
        <input
          v-model="OTPNumbers[3]"
          class="otpinput"
          min="0"
          max="9"
          maxlength="1"
          pattern="[0-9]"
          type="tel"
          @keypress="isNumber"
          @keypress.enter="validate(OTPNumbers)"
          @keyup="
            OTPNumbers[3] ? $event.target.nextElementSibling.focus() : false
          "
          @keyup.backspace="$event.target.previousElementSibling.focus()"
          @paste="onPaste"
        />
        <input
          v-model="OTPNumbers[4]"
          class="otpinput"
          min="0"
          max="9"
          maxlength="1"
          pattern="[0-9]"
          type="tel"
          @keypress="isNumber"
          @keypress.enter="validate(OTPNumbers)"
          @keyup="
            OTPNumbers[4] ? $event.target.nextElementSibling.focus() : false
          "
          @keyup.backspace="$event.target.previousElementSibling.focus()"
          @paste="onPaste"
        />
        <input
          v-model="OTPNumbers[5]"
          class="otpinput"
          min="0"
          max="9"
          maxlength="1"
          pattern="[0-9]"
          type="tel"
          @keypress="isNumber"
          @keypress.enter="validate(OTPNumbers)"
          @paste="onPaste"
          @keyup.backspace="$event.target.previousElementSibling.focus()"
        />
      </div>

      <BaseButton
        label="Continue"
        :is-loading="isLoading && error === ''"
        class="confirm q-mb-sm"
        @click="validate(OTPNumbers)"
      />
      <div class="column items-center label" style="font-weight: bold">
        <p>{{ time ? timeleft : "" }}</p>
      </div>
      <div class="column items-center label q-mt-sm">
        <p>
          Didn't get the code?
          <span
            style="color: #00a2c7; cursor: pointer"
            @click="validate('Resend')"
          >
            Resend</span
          >
        </p>
        <p
          v-if="signUp"
          class="q-mt-sm"
          style="color: #00a2c7; cursor: pointer"
          @click="validate('NewAccount')"
        >
          Try with different account
        </p>
      </div>
    </template>
    <div
      v-if="clockTick && time === 0"
      class="column items-center label q-mt-sm"
    >
      <p>
        <span style="color: red">OTP has expired!</span>
        <span
          class="col-auto"
          style="color: #00a2c7; cursor: pointer"
          @click="validate('Resend')"
        >
          Resend</span
        >
      </p>
      <p
        v-if="signUp"
        class="q-mt-sm"
        style="color: #00a2c7; cursor: pointer"
        @click="validate('NewAccount')"
      >
        Try with different account
      </p>
    </div>
    <FormFieldError
      v-if="clockTick && time"
      class="column items-center q-mt-sm"
      :error="error"
    />
  </div>
</template>
<script>
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "EmailAuthentication",

  components: { FormFieldError },

  props: {
    otpType: {
      type: String,
      default: "email",
    },

    otpVerify: {
      type: Boolean,
      default: true,
    },

    clockTick: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    signUp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      time: 300,
      timer: null,
      OTPNumbers: [],
      isLoading: false,
    };
  },

  computed: {
    timeleft() {
      return `${this.minutes}:${this.seconds}`;
    },
    minutes() {
      return String(Math.floor(this.time / 60)).padStart(2, "0");
    },
    seconds() {
      return String(this.time % 60).padStart(2, "0");
    },
  },

  watch: {
    clockTick: {
      immediate: true,
      handler() {
        if (this.clockTick) {
          this.reset();
        }
      },
    },
  },

  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onPaste(evt) {
      this.OTPNumbers = evt.clipboardData.getData("text").split("");
    },

    decrement() {
      if (this.time > 0) {
        this.time--;
        return;
      }
      this.$emit("update:error", "");
      this.OTPNumbers = [];
      this.isLoading = false;
      clearInterval(this.timer);
    },

    reset() {
      this.OTPNumbers = [];
      clearInterval(this.timer);
      this.time = 300;
      this.timer = null;
      this.timer = setInterval(this.decrement, 1000);
    },

    validate(value) {
      if (value == "NewAccount") {
        this.$emit("validate", value);
      } else if (value == "Resend") {
        this.$emit("validate", "");
      }
      if (this.OTPNumbers.length === 6) {
        this.isLoading = true;
        this.$emit("validate", value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#otp-field {
  .title {
    @extend .text-lg;
    font-weight: bold;
    color: var(--title-1-color);
    margin-bottom: 12px;
  }
  .label {
    color: var(--icon-color);
    font-weight: 500;
  }
  .otpinput {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  p {
    margin-bottom: 0px !important;
  }

  .confirm {
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
