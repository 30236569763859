<template>
  <div id="checklist-builder" class="field">
    <template v-if="action">
      <div class="text-red q-mb-md">
        {{ text }}
      </div>
    </template>

    <BaseScrollbar>
      <div>
        <table>
          <thead>
            <tr>
              <th class="medium">Group</th>
              <th class="large">Options</th>
              <th v-if="editField.id == undefined" class="action">
                <BaseActionButton
                  is-flat
                  class="q-ml-xs"
                  color="secondary"
                  icon="eva-plus-outline"
                  no-border
                  @click="addRow"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, index) in folderSecurity" :key="index">
              <td>
                <SelectField
                  v-model="folderSecurity[index]['groupId']"
                  class="col"
                  :options="filterOptions(index)"
                  :is-clearable="false"
                  :is-readonly="editField.id !== undefined"
                />
              </td>
              <td>
                <MultiSelectField
                  v-model="folderSecurity[index]['accessibilities']"
                  class="col"
                  :options="accessibilities"
                  :is-clearable="false"
                  @input="updateAccessibilities(index)"
                />
              </td>
              <td v-if="editField.id == undefined" class="action">
                <BaseActionButton
                  is-flat
                  color="red"
                  icon="eva-close-outline"
                  no-border
                  @click="removeRow(index)"
                />
              </td>
            </tr>
            <template v-if="folderSecurity.length > 10">
              <tr>
                <td class="text-bold">Group</td>
                <td class="text-bold">Options</td>
                <td>
                  <BaseActionButton
                    is-flat
                    class="q-ml-xs"
                    color="secondary"
                    icon="eva-plus-outline"
                    no-border
                    @click="addRow"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";

export default {
  name: "GroupTable",

  components: {
    SelectField,
    MultiSelectField,
  },

  props: {
    list: {
      type: Array,
      default: () => [],
    },

    addField: {
      type: Array,
      default: () => [],
    },

    editField: {
      type: Object,
      default: () => {},
    },

    action: {
      type: Boolean,
      default: false,
    },

    text: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      accessibilities: [
        {
          id: this.$nano.id(),
          label: "View",
          value: "view",
        },
        {
          id: this.$nano.id(),
          label: "Metadata Edit",
          value: "metadataEdit",
        },
        {
          id: this.$nano.id(),
          label: "Document Edit",
          value: "documentEdit",
        },
        {
          id: this.$nano.id(),
          label: "Password",
          value: "password",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Vault",
        //   value: "vault",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "Hide Pages",
        //   value: "hidePages",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "Alert",
        //   value: "alert",
        // },
        {
          id: this.$nano.id(),
          label: "Check In & Out",
          value: "checkInAndOut",
        },
        {
          id: this.$nano.id(),
          label: "Delete File",
          value: "deleteFile",
        },
        {
          id: this.$nano.id(),
          label: "Delete Folder",
          value: "deleteFolder",
        },
        {
          id: this.$nano.id(),
          label: "Email Sharing",
          value: "emailSharing",
        },
        {
          id: this.$nano.id(),
          label: "Print",
          value: "print",
        },
        {
          id: this.$nano.id(),
          label: "Download",
          value: "download",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Links",
        //   value: "links",
        // },
        {
          id: this.$nano.id(),
          label: "Task",
          value: "task",
        },
        {
          id: this.$nano.id(),
          label: "Document Sign Request",
          value: "requestForSign",
        },
      ],
      folderSecurity: [],
    };
  },

  computed: {
    filterOptions() {
      return (index) => {
        const selectedIds = this.folderSecurity
          .slice(0, index) // Get selected values in previous rows
          .map((row) => row.groupId);

        const unmatchedOptions = this.list.filter((option) => {
          // Check if the option's value is not in the selected groupIds
          return !selectedIds.includes(option.value);
        });

        return unmatchedOptions;
      };
    },
  },

  watch: {
    // editField: {
    //   immediate: true,
    //   deep: true,
    //   handler() {
    //     if (!this.editField) {
    //       if (this.addField.length === 0) {
    //         this.addRow();
    //       }
    //     }
    //   },
    // },
    folderSecurity: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("update:addField", this.folderSecurity);
      },
    },
  },

  mounted() {
    if (Object.keys(this.editField).length !== 0) {
      // Extract true options and assign them to the accessibilities array
      const accessibilities = this.extractTrueOptions(this.editField.options);

      if (!this.editField.options.documentEdit) {
        this.editField.options.documentEdit = false;
      }
      if (!this.editField.options.task) {
        this.editField.options.task = false;
      }
      if (!this.editField.options.requestForSign) {
        this.editField.options.requestForSign = false;
      }
      if (!this.editField.options.deleteFolder) {
        this.editField.options.deleteFolder = false;
      }

      // Assign the editField value to folderSecurity
      this.folderSecurity = [
        {
          id: this.editField.id,
          groupId: this.editField.groupId,
          accessibilities: accessibilities, // Assign the extracted options
          options: this.editField.options,
        },
      ];
    } else {
      // Otherwise, add a default row
      this.addRow();
    }
  },

  methods: {
    addRow() {
      // if (Object.keys(this.editField).length === 0) {
      //   // if (this.addField.length === 0) {}
      // }
      const model = {
        id: this.$nano.id(),
        groupId: 0,
        accessibilities: ["view"],
        options: {
          view: true,
          metadataEdit: false,
          documentEdit: false,
          password: false,
          vault: false,
          hidePages: false,
          alert: false,
          checkInAndOut: false,
          deleteFile: false,
          deleteFolder: false,
          emailSharing: false,
          print: false,
          download: false,
          links: false,
          task: false,
          requestForSign: false,
        },
      };
      this.folderSecurity.push(model);
    },

    removeRow(rowIdx) {
      this.folderSecurity.splice(rowIdx, 1);
    },

    updateAccessibilities(index) {
      const accessibilities = this.folderSecurity[index].accessibilities;
      const options = this.folderSecurity[index].options;

      // Initialize all accessibilities to false
      for (const key in options) {
        if (Object.prototype.hasOwnProperty.call(options, key)) {
          options[key] = false;
        }
      }

      // Set matched accessibilities to true
      accessibilities.forEach((access) => {
        if (Object.prototype.hasOwnProperty.call(options, access)) {
          options[access] = true;
        }
      });
    },

    extractTrueOptions(options) {
      const accessibilities = [];
      for (const key in options) {
        if (
          options.hasOwnProperty.call(options, key) &&
          options[key] === true
        ) {
          accessibilities.push(key);
        }
      }
      return accessibilities;
    },
  },
};
</script>

<style lang="scss" scoped>
#checklist-builder {
  .option-wrapper {
    border-top: 1px solid var(--divider-color);
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 200px;
      }

      &.large {
        width: 450px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      .autoMargin {
        margin: auto;
      }
    }
  }
}
</style>
y
