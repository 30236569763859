<template>
  <div id="repository-builder">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title>
        <template>Secure Folder/ Repository</template>
      </template>

      <template #description>
        <template> Some of the fields or non-editable </template>
      </template>

      <!-- ... -->

      <!-- action bar -->

      <ActionBar v-model="tab" :tabs="tabs" :mode="mode" />

      <!-- ... -->

      <!-- content -->

      <BaseScrollbar height="calc(100vh - 126px)" width="100vw">
        <div class="row q-pb-xl">
          <ValidationObserver ref="form">
            <!-- repository details -->

            <RepositoryDetails
              v-if="
                tab === 'REPOSITORY_DETAILS' ||
                tab === 'RULES_CONDITIONS' ||
                tab === 'RETENTIONS'
              "
              :ref="tab ? 'RULES_CONDITIONS' : 'REPOSITORY_DETAILS'"
              :mode="mode"
              :name.sync="name"
              :is-active="isActiveTab"
              @click="tab ? 'RULES_CONDITIONS' : 'REPOSITORY_DETAILS'"
            />

            <!-- ... -->

            <BaseSeparator has-left-inset />

            <!-- security group -->

            <AssignGroup
              v-if="tab === 'REPOSITORY_DETAILS'"
              ref="REPOSITORY_DETAILS"
              :mode="mode"
              :repository-id="repositoryId"
              :group="groupSecurity"
              :is-active="tab === 'REPOSITORY_DETAILS'"
              @refresh="getFolderSecurity"
              @click="tab = 'REPOSITORY_DETAILS'"
            />

            <!-- ... -->

            <BaseSeparator has-left-inset />

            <!-- security user -->

            <AssignUser
              v-if="tab === 'REPOSITORY_DETAILS'"
              ref="REPOSITORY_DETAILS"
              :mode="mode"
              :repository-id="repositoryId"
              :user="userSecurity"
              :is-active="tab === 'REPOSITORY_DETAILS'"
              @refresh="getFolderSecurity"
              @click="tab = 'REPOSITORY_DETAILS'"
            />

            <!-- ... -->

            <!-- security user -->

            <RulesAndConditions
              v-if="tab === 'RULES_CONDITIONS'"
              ref="RULES_CONDITIONS"
              :mode="mode"
              :fields="fields"
              :repository-id="repositoryId"
              :rule="documentSecurity"
              :is-active="tab === 'RULES_CONDITIONS'"
              @refresh="getFileSecurity"
              @click="tab = 'RULES_CONDITIONS'"
            />

            <!-- ... -->

            <!-- security user -->

            <Retentions
              v-if="tab === 'RETENTIONS'"
              ref="RETENTIONS"
              :mode="mode"
              :fields="fields"
              :repository-id="repositoryId"
              :retentions="retentions"
              :is-active="tab === 'RETENTIONS'"
              @refresh="getFileRetentions"
              @click="tab = 'RETENTIONS'"
            />

            <!-- ... -->

            <!-- advance security -->

            <WorkInProgress
              v-if="tab === 'ADVANCED_SETTINGS'"
              feature="Advance Settings"
            />

            <!-- ... -->

            <!-- reports security -->

            <WorkInProgress
              v-if="tab === 'REPORTS_SETTINGS'"
              feature="Reports Settings"
            />

            <!-- ... -->
          </ValidationObserver>

          <!-- <q-space /> -->
        </div>
      </BaseScrollbar>

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import ActionBar from "@/components/common/item-builder/ActionBar.vue";
import { ValidationObserver } from "vee-validate";

import RepositoryDetails from "./components/RepositoryDetails.vue";
import AssignGroup from "./components/AssignGroup.vue";
import AssignUser from "./components/AssignUser.vue";
import WorkInProgress from "@/components/common/state/WorkInProgress.vue";
import RulesAndConditions from "./components/RulesAndConditions.vue";
import Retentions from "./components/Retentions.vue";

import { repository } from "@/api/factory.js";

export default {
  name: "RepositorySecure",

  components: {
    ItemBuilder,
    ValidationObserver,
    ActionBar,
    RepositoryDetails,
    AssignGroup,
    AssignUser,
    WorkInProgress,
    RulesAndConditions,
    Retentions,
  },

  props: {
    repositoryId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mode: "Secure",
      tab: "REPOSITORY_DETAILS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "folder",
          value: "REPOSITORY_DETAILS",
        },
        {
          id: this.$nano.id(),
          label: "rules/conditions",
          value: "RULES_CONDITIONS",
        },
        {
          id: this.$nano.id(),
          label: "retentions",
          value: "RETENTIONS",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "advanced",
        //   value: "ADVANCED_SETTINGS",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "reports",
        //   value: "REPORTS_SETTINGS",
        // },
      ],
      isLoading: false,

      // workspace details

      workspaceId: "",

      // repository details

      name: "",
      fields: [],

      // security details
      userSecurity: [],
      groupSecurity: [],
      documentSecurity: [],

      // retentions
      retentions: [],
    };
  },

  computed: {
    isActiveTab() {
      if (
        this.tab === "REPOSITORY_DETAILS" ||
        this.tab === "RULES_CONDITIONS"
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {
    repositoryId: {
      immediate: true,
      handler() {
        if (this.repositoryId) {
          this.mode = "SECURE";
          this.getRepository();
          this.getFolderSecurity();
          this.getFileSecurity();
          this.getFileRetentions();
        }
      },
    },
  },

  methods: {
    closeBuilder() {
      this.$router.back();
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.workspaceId = payload.workspace?.id || 0;

      this.name = payload.name;
      this.description = payload.description;

      this.fields = payload.fields.map((field) => ({
        _id: this.$nano.id(), // add _id
        ...field,
        isFilter: true,
        apiLoad: true,
      }));
      // console.log("fields", this.fields);
    },

    async getFolderSecurity() {
      this.userSecurity = [];
      this.groupSecurity = [];
      const { error, payload } = await repository.getRepositorySecurity(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload === null) {
        console.log("No security data available");
        return;
      }

      const userOptions = payload.userOptions.map((userOption) => ({
        id: userOption.id,
        userName: userOption.email,
        userId: userOption.userId,
        options: JSON.parse(userOption.options),
      }));
      this.userSecurity = userOptions;

      const groupOptions = payload.groupOptions.map((groupOption) => ({
        id: groupOption.id,
        groupName: groupOption.groupName,
        groupId: groupOption.groupId,
        options: JSON.parse(groupOption.options),
      }));
      this.groupSecurity = groupOptions;
    },

    async getFileSecurity() {
      this.documentSecurity = [];
      const { error, payload } = await repository.getDocumentSecurity(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload === null) {
        console.log("No security data available");
        return;
      }

      this.documentSecurity =
        payload &&
        payload.map((file) => ({
          id: file.id,
          name: file.name,
          conditions: JSON.parse(file.conditionJson),
          filterBy: JSON.parse(file.conditionJson),
          userId_Array: file.userId_Array,
          groupId_Array: file.groupId_Array,
          // userId_Array: file.users.map((user) => user.id),
          // groupId_Array: file.groups.map((group) => group.id),
          visibility: file.visibility,
        }));
    },

    async getFileRetentions() {
      this.retentions = [];
      const { error, payload } = await repository.getRetentions({
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "repositoryId",
                condition: "IS_EQUALS_TO",
                value: this.repositoryId,
              },
              {
                criteria: "itemId",
                condition: "IS_EQUALS_TO",
                value: 0,
              },
            ],
            groupCondition: "",
          },
        ],
        currentPage: 1,
        itemsPerPage: 50,
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload === null) {
        console.log("No security data available");
        return;
      }

      this.retentions =
        payload &&
        payload.map((rule) => ({
          id: rule.id,
          name: rule.name,
          action: rule.action,
          fieldId: rule.fieldId,
          durationType: rule.durationType,
          duration: rule.duration,
          conditionJson: JSON.parse(rule.conditionJson),
          filterBy: JSON.parse(rule.conditionJson),
          notifyJson: rule.notifyJson,
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
#repository-builder {
  height: 100vh;
}
</style>
