<template>
  <AppLayout>
    <ViewWrapper
      title="Connection"
      tab="integration-builder"
      :breadcrumbs="breadcrumbs"
      @quickHelp="showQuickHelp"
    >
      <!-- header -->

      <template #header-actions>
        <BaseButton
          v-if="!addConnectionHide"
          icon="eva-plus"
          label="add connection"
          class="q-ml-md"
          @click="addConnectionEntry"
        />
      </template>

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->

      <div id="integration-builder-browse">
        <Portal to="action-bar">
          <ItemActions
            v-if="actionItems"
            item="connection entry"
            :columns.sync="filterAppColumns"
            :group-by.sync="groupBy"
            :order.sync="sortBy.order"
            :filter-by.sync="filterBy"
            :active-view.sync="activeView"
            :criteria.sync="sortBy.criteria"
            class="q-mb-sm"
            @refresh="getRows"
            @search="searchRow"
          />
        </Portal>

        <div class="content">
          <ItemActionChips
            :sort-by="sortBy"
            :columns="filterAppColumns"
            :group-by="groupBy"
            :filter-by="filterBy"
            @clearSortBy="clearSortBy"
            @clearGroupBy="clearGroupBy"
          />

          <ConnectionEntryGrid
            v-if="activeView === 'GRID'"
            :mode="mode"
            :items="items"
            :columns="filterAppColumns"
            @edit="editConnectionEntry"
            @more="viewConnectionEntry"
            @delete="showDeleteConfirmation"
            @restore="showRestoreConfirmation"
            @select="getLookupData"
          />

          <ConnectionEntryList
            v-if="activeView === 'LIST'"
            :mode="mode"
            :items="items"
            :columns="filterAppColumns"
            @edit="editConnectionEntry"
            @more="viewConnectionEntry"
            @delete="showDeleteConfirmation"
            @restore="showRestoreConfirmation"
            @select="getLookupData"
          />
        </div>

        <Pagination
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
          @loadAll="loadAll"
        />

        <ConnectionDetails
          v-if="isConnectionDetailsVisible"
          v-model="isConnectionDetailsVisible"
          :columns="filterAppColumns"
          :entry="selectedEntry"
          @edit="editConnectionEntry"
          @delete="showDeleteConfirmation"
        />

        <ConfirmDeleteConnectionEntry
          v-model="isDeleteConfirmationVisible"
          :app-name="selectedEntry?.name"
          @delete="updateEntry"
        />

        <ConfirmRestoreConnectionEntry
          v-model="isRestoreConfirmationVisible"
          :app-name="selectedEntry?.name"
          @restore="updateEntry"
        />

        <JiraConnect
          v-if="app === 'jira'"
          :value="jiraConnectSheet"
          :entry="selectedEntry"
          @close="closeConnectionSheet"
        ></JiraConnect>

        <SMTPConnect
          v-if="app === 'smtp'"
          :value="smtpConnectSheet"
          :entry="selectedEntry"
          @close="closeConnectionSheet"
        ></SMTPConnect>

        <SQLConnect
          v-if="app === 'Sql'"
          :value="sqlConnectSheet"
          :entry="selectedEntry"
          @close="closeConnectionSheet"
        ></SQLConnect>

        <APIConnection
          v-if="app === 'API'"
          :value="apiConnectSheet"
          :entry="selectedEntry"
          @close="closeConnectionSheet"
        ></APIConnection>

        <SlackConnect
          v-if="app === 'slack'"
          :value="slackConnectSheet"
          :entry="selectedEntry"
          @close="closeConnectionSheet"
        ></SlackConnect>

        <TeamsConnect
          v-if="app === 'teams'"
          :value="teamsConnectSheet"
          :entry="selectedEntry"
          @close="closeConnectionSheet"
        ></TeamsConnect>
      </div>
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import { integration, connector } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import ConnectionEntryGrid from "./components/ConnectionEntryGrid.vue";
import ConnectionEntryList from "./components/ConnectionEntryList.vue";
import ConfirmDeleteConnectionEntry from "./components/ConfirmDeleteConnectionEntry.vue";
import ConfirmRestoreConnectionEntry from "./components/ConfirmRestoreConnectionEntry.vue";
import JiraConnect from "./components/JiraConnect";
import ConnectionDetails from "./components/ConnectionDetails.vue";
import SMTPConnect from "@/views/integration/views/integration-builder/components/SMTPConnect";
import SQLConnect from "@/views/integration/views/integration-builder/components/SQLConnect";
import APIConnection from "@/views/integration/views/integration-builder/components/APIConnection";
import SlackConnect from "./components/SlackConnect.vue";
import TeamsConnect from "./components/TeamsConnect.vue";
export default {
  name: "IntegrationBuilder",

  components: {
    AppLayout,
    ViewWrapper,
    QuickHelp,
    Portal,
    ItemActionChips,
    ItemActions,
    ConnectionEntryGrid,
    ConnectionEntryList,
    Pagination,
    ConfirmDeleteConnectionEntry,
    ConfirmRestoreConnectionEntry,
    JiraConnect,
    ConnectionDetails,
    SMTPConnect,
    SQLConnect,
    APIConnection,
    SlackConnect,
    TeamsConnect,
  },

  props: {
    app: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mode: "BROWSE",
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "settings",
          route: "settings",
        },
        {
          id: this.$nano.id(),
          label: "integration",
          route: "integration-browse",
        },
        {
          id: this.$nano.id(),
          label: "browse",
          route: "",
        },
      ],
      quickHelp: false,
      columns: [
        {
          id: this.$nano.id(),
          app: "jira",
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "jira",
          name: "type",
          label: "Type",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "jira",
          name: "email",
          label: "Email",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "jira",
          name: "apiUrl",
          label: "API URL",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "smtp",
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "smtp",
          name: "email",
          label: "Email",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "smtp",
          name: "emailProvider",
          label: "Email provider",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },

        {
          id: this.$nano.id(),
          app: "smtp",
          name: "clientId",
          label: "Client id",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "smtp",
          name: "tenantAuthId",
          label: "TenantAuth id",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "smtp",
          name: "enableSSLIcon",
          label: "Enable SSL",
          type: "ICON",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "smtp",
          name: "preferenceIcon",
          label: "Default",
          type: "ICON",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "smtp",
          name: "incomingVerified",
          label: "Incoming Status",
          type: "ICON",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "smtp",
          name: "outgoingVerified",
          label: "Outgoing Status",
          type: "ICON",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "Sql",
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "Sql",
          name: "desc",
          label: "Desc",
          type: "SUBTITLE",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "Sql",
          name: "connectionString",
          label: "Connection String",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "API",
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "API",
          name: "desc",
          label: "Desc",
          type: "SUBTITLE",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "API",
          name: "connectorType",
          label: "Type",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "API",
          name: "connectionString",
          label: "Connection String",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "slack",
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "slack",
          name: "clientId",
          label: "Client Id",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "teams",
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "teams",
          name: "clientId",
          label: "Client Id",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "teams",
          name: "tenantId",
          label: "Tenant Id",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "Whatsapp",
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "Whatsapp",
          name: "responseBody",
          label: "Response Body",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "",
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "",
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
        {
          id: this.$nano.id(),
          app: "",
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          app: "",
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
      ],
      activeView: "LIST",
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      rowItems: [],
      rowItemsData: [],
      selectedEntry: {},
      actionItems: false,
      jiraConnectSheet: false,
      isConnectionDetailsVisible: false,
      smtpConnectSheet: false,
      sqlConnectSheet: false,
      sqlConnectionId: 0,
      apiConnectSheet: false,
      apiConnectionId: 0,
      slackConnectSheet: false,
      slackConnectionId: 0,
      teamsConnectSheet: false,
      teamsConnectionId: 0,
      addConnectionHide: false,
    };
  },

  computed: {
    items() {
      let rows = [];
      switch (this.app) {
        case "jira":
          rows = this.rowItems.map(({ key, value }) => ({
            key: this.groupBy,
            value: key,
            data: value.map((entry) => ({
              id: entry.id,
              name: entry.cloudName,
              type: entry.email ? "CLOUD" : "ONPREMISE",
              email: entry.email,
              apiUrl: entry.apiUrl,
              apiKey: entry.apiKey,
              createdBy: entry.createdByEmail,
              createdAt: this.$day.format(entry.createdAt),
              modifiedBy: entry.modifiedByEmail,
              modifiedAt: this.$day.format(entry.modifiedAt),
              icon: "mdi-application-cog",
            })),
          }));
          break;
        case "smtp":
          rows = this.rowItems.map(({ key, value }) => ({
            key: this.groupBy,
            value: key,
            data: value.map((entry) => ({
              id: entry.settingId,
              name: entry.settingName,
              email: entry.emailId,
              emailProvider: entry.emailProvider,
              password: entry.password,
              isOutgoingVerified: entry.outgoingVerified == 1 ? true : false,
              isIncomingVerified: entry.incomingVerified == 1 ? true : false,
              isOutgoing: entry.outgoingVerified != 0 ? true : false,
              isIncoming: entry.incomingVerified != 0 ? true : false,
              iconColor: {
                outgoingVerified: entry.outgoingVerified == 1 ? "green" : "red",
                incomingVerified: entry.incomingVerified == 1 ? "green" : "red",
              },
              iconTooltip: {
                outgoingVerified:
                  entry.outgoingVerified == 1 ? "Verified" : "Not Verified",
                incomingVerified:
                  entry.incomingVerified == 1 ? "Verified" : "Not Verified",
                enableSSLIcon: entry.enableSSL ? "Enabled" : "Not Enabled",
                preferenceIcon: entry.preference ? "Enabled" : "Not Enabled",
              },
              outgoingVerified:
                entry.outgoingVerified == 1
                  ? "mdi-check-circle-outline"
                  : entry.outgoingVerified == 2
                  ? "mdi-alert-circle-outline"
                  : "",
              incomingVerified:
                entry.incomingVerified == 1
                  ? "mdi-check-circle-outline"
                  : entry.incomingVerified == 2
                  ? "mdi-alert-circle-outline"
                  : "",
              clientId: entry.clientId,
              tenantAuthId: entry.tenantAuthId,
              enableSSL: entry.enableSSL ? true : false,
              enableSSLIcon: entry.enableSSL ? "mdi-check" : "",
              preference: entry.preference ? true : false,
              preferenceIcon: entry.preference ? "mdi-check" : "",
              createdBy: entry.createdByEmail,
              clientSecret: entry.clientSecret,
              createdAt: this.$day.format(entry.createdAt),
              modifiedBy: entry.modifiedByEmail,
              modifiedAt: this.$day.format(entry.modifiedAt),
              icon: "mdi-application-cog",
            })),
          }));
          break;
        case "Sql":
          rows = this.rowItems.map(({ key, value }) => ({
            key: this.groupBy,
            value: key,
            data: value.map((entry) => ({
              id: entry.id,
              name: entry.name,
              desc: "",
              connectionString: JSON.parse(entry.credentialJson)[
                "connectionString"
              ],
              createdBy: entry.createdByEmail,
              createdAt: this.$day.format(entry.createdAt),
              modifiedBy: entry.modifiedByEmail,
              modifiedAt: this.$day.format(entry.modifiedAt),
              icon: "mdi-database",
            })),
          }));
          break;
        case "API":
          rows = this.rowItems.map(({ key, value }) => ({
            key: this.groupBy,
            value: key,
            data: value.map((entry) => ({
              id: entry.id,
              name: entry.name,
              desc: "",
              connectorType: entry.connectorType,
              connectionString: entry.credentialJson,
              createdBy: entry.createdByEmail,
              createdAt: this.$day.format(entry.createdAt),
              modifiedBy: entry.modifiedByEmail,
              modifiedAt: this.$day.format(entry.modifiedAt),
              icon: "eva-layers-outline",
            })),
          }));
          break;
        case "slack":
          rows = this.rowItems.map(({ key, value }) => ({
            key: this.groupBy,
            value: key,
            data: value.map((entry) => ({
              id: entry.id,
              name: entry.name,
              clientId: JSON.parse(entry.credentialJson).clientId,
              credentialJson: entry.credentialJson,
              createdBy: entry.createdByEmail,
              createdAt: this.$day.format(entry.createdAt),
              modifiedBy: entry.modifiedByEmail,
              modifiedAt: this.$day.format(entry.modifiedAt),
              icon: "mdi-slack",
            })),
          }));
          break;
        case "teams":
          rows = this.rowItems.map(({ key, value }) => ({
            key: this.groupBy,
            value: key,
            data: value.map((entry) => ({
              id: entry.id,
              name: entry.name,
              clientId: JSON.parse(entry.credentialJson).clientId,
              tenantId: JSON.parse(entry.credentialJson).tenantid,
              credentialJson: entry.credentialJson,
              createdBy: entry.createdByEmail,
              createdAt: this.$day.format(entry.createdAt),
              modifiedBy: entry.modifiedByEmail,
              modifiedAt: this.$day.format(entry.modifiedAt),
              icon: "mdi-microsoft-teams",
            })),
          }));
          break;
        case "Whatsapp":
          rows = this.rowItems.map(({ key, value }) => ({
            key: this.groupBy,
            value: key,
            data: value.map((entry) => ({
              id: entry.id,
              name: entry.name,
              clientId: JSON.parse(entry.credentialJson).clientId,
              tenantId: JSON.parse(entry.credentialJson).tenantid,
              credentialJson: entry.credentialJson,
              createdBy: entry.createdByEmail,
              createdAt: this.$day.format(entry.createdAt),
              modifiedBy: entry.modifiedByEmail,
              modifiedAt: this.$day.format(entry.modifiedAt),
              responseBody: entry.responseBody,
              icon: "mdi-whatsapp",
            })),
          }));
          break;
      }

      return rows;
    },

    filterAppColumns() {
      return this.columns.filter(
        (row) => row.app === this.app || row.app === ""
      );
    },
  },

  mounted() {
    this.$watch(
      (vm) => [
        vm.mode,
        vm.sortBy,
        vm.groupBy,
        vm.filterBy,
        vm.currentPage,
        vm.itemsPerPage,
      ],
      () => this.getRows(),
      { deep: true, immediate: true }
    );
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    showDeleteConfirmation(entryId) {
      this.selectedEntry = this.findEntry(entryId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(entryId) {
      this.selectedEntry = this.findEntry(entryId);
      this.isRestoreConfirmationVisible = true;
    },

    findEntry(entryId) {
      const entries = [];
      this.items.forEach((item) => entries.push(...item.data));
      return entries.find((entry) => entry.id === entryId);
    },

    openConnectionSheet() {
      switch (this.app) {
        case "jira":
          this.jiraConnectSheet = true;
          break;
        case "smtp":
          this.smtpConnectSheet = true;
          break;
        case "Sql":
          this.sqlConnectSheet = true;
          break;
        case "API":
          this.apiConnectSheet = true;
          break;
        case "slack":
          this.slackConnectSheet = true;
          break;
        case "teams":
          this.teamsConnectSheet = true;
          break;
      }
    },

    closeConnectionSheet() {
      switch (this.app) {
        case "jira":
          this.jiraConnectSheet = false;
          break;
        case "smtp":
          this.smtpConnectSheet = false;
          break;
        case "Sql":
          this.sqlConnectSheet = false;
          break;
        case "API":
          this.apiConnectSheet = false;
          break;
        case "slack":
          this.slackConnectSheet = false;
          break;
        case "teams":
          this.teamsConnectSheet = false;
          break;
      }
      this.getRows();
    },

    addConnectionEntry() {
      this.selectedEntry = {};
      this.openConnectionSheet();
    },

    editConnectionEntry(entryId) {
      this.selectedEntry = this.findEntry(entryId);
      this.openConnectionSheet();
    },

    viewConnectionEntry(entryId) {
      this.selectedEntry = this.findEntry(entryId);
      this.isConnectionDetailsVisible = true;
    },

    searchRow(search) {
      if (search) {
        this.connectionEntries = this.connectionEntriesData.map(
          ({ key, value }) => ({
            key: key,
            value: value.filter((row) => {
              for (let cell in row) {
                let index = this.columns.findIndex((column) => {
                  return column.app === this.app && column.name === cell;
                });
                if (index >= 0 && row[cell]) {
                  if (
                    String(row[cell])
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return row;
                  }
                }
              }
            }),
          })
        );
      } else {
        this.rowItems = this.rowItemsData;
      }
      this.totalItems = this.rowItems[0].value.length;
    },

    loadAll() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    getRows() {
      switch (this.app) {
        case "jira":
          this.getJiraConnectionEntries();
          break;
        case "smtp":
          this.getSMTPConnectionEntries();
          break;
        case "Sql":
          this.getSQLConnectionEntries();
          break;
        case "API":
          this.getAPIConnectionEntries();
          break;
        case "slack":
          this.getSlackConnectionEntries();
          break;
        case "teams":
          this.getTeamsConnectionEntries();
          break;
        case "Whatsapp":
          this.getWhatsappConnectionEntries();
          break;
      }
    },

    updateEntry(payload) {
      switch (this.app) {
        case "jira":
          this.updateJiraEntry(payload);
          break;
        case "smtp":
          this.updateSMTPEntry(payload);
          break;
        case "Sql":
          this.updateSQLEntry(payload);
          break;
        case "API":
          this.updateSQLEntry(payload);
          break;
        case "slack":
          this.updateSlackEntry(payload);
          break;
        case "teams":
          this.updateTeamsEntry(payload);
          break;
        case "Whatsapp":
          this.updateWhatsappEntry(payload);
          break;
      }
    },

    async getJiraConnectionEntries() {
      this.actionItems = true;
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await integration.getJiraConnection({
        mode: this.mode,
        filterBy: [
          {
            filters: [
              {
                criteria: "cloudName",
                condition: "IS_EQUALS_TO",
                value: this.app,
              },
            ],
          },
        ],
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      //const { data, meta } = payload;

      let data = [
        {
          key: "",
          value: payload,
        },
      ];

      this.rowItems = data || [];
      this.rowItemsData = data || [];
      //this.totalItems = meta?.totalItems || 0;
      this.totalItems = payload.length || 0;
    },

    async updateJiraEntry(payload) {
      this.$store.commit("showLoadingBarPage");
      const { error } = await integration.updateJiraConnection(
        this.selectedEntry.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`connection deleted`);
      this.getRows();
    },

    async getSMTPConnectionEntries() {
      this.actionItems = true;
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await integration.getSMTPConnection({
        mode: this.mode,
        filterBy: [],
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      //const { data, meta } = payload;

      let data = [
        {
          key: "",
          value: payload,
        },
      ];

      this.rowItems = data || [];
      this.rowItemsData = data || [];
      //this.totalItems = meta?.totalItems || 0;
      this.totalItems = payload.length || 0;
    },

    async updateSMTPEntry(payload) {
      this.$store.commit("showLoadingBarPage");
      payload = { Isdeleted: true };
      const { error } = await integration.updateSMTPConnection(
        this.selectedEntry.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`connection deleted`);
      this.getRows();
    },

    async getSQLConnectionEntries() {
      this.actionItems = true;

      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await connector.getConnection({
        filterBy: [],
        mode: this.mode,
      });
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      const result = payload.filter(
        (item) =>
          item.createdBy === this.$store.state.session.id &&
          item.connectorType === "SQL"
      );
      let data = [
        {
          key: "",
          value: result,
        },
      ];

      this.rowItems = data || [];
      this.rowItemsData = data || [];
      //this.totalItems = meta?.totalItems || 0;
      this.totalItems = result.length || 0;
    },

    getLookupData(id) {
      // console.log(id);
      if (this.app === "Sql") {
        this.sqlConnectionId = id;
        this.$router.push({
          name: "sql-browse",
          query: { id },
        });
      } else if (this.app === "API") {
        this.apiConnectionId = id;
        this.$router.push({
          name: "api-browse",
          query: { id },
        });
      } else if (this.app === "slack") {
        this.slackConnectionId = id;
        this.$router.push({
          name: "slack-browse",
          query: { id },
        });
      } else if (this.app === "teams") {
        this.teamsConnectionId = id;
        this.$router.push({
          name: "teams-browse",
          query: { id },
        });
      } else if (this.app === "Whatsapp") {
        this.teamsConnectionId = id;
        this.$router.push({
          name: "whatsapp-browse",
          query: { id },
        });
      }
    },

    async updateSQLEntry(payload) {
      this.$store.commit("showLoadingBarPage");
      const { error } = await connector.updateConnector(
        this.selectedEntry.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`connection deleted`);
      this.getRows();
    },

    async getAPIConnectionEntries() {
      this.actionItems = true;

      const { error, payload } = await connector.getConnection({
        filterBy: [],
        mode: this.mode,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const result = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "oauth2"
        );
        let data = [
          {
            key: "",
            value: result,
          },
        ];

        this.rowItems = data || [];
        this.rowItemsData = data || [];
        //this.totalItems = meta?.totalItems || 0;
        this.totalItems = result.length || 0;
      }
    },

    async getSlackConnectionEntries() {
      this.actionItems = true;

      const { error, payload } = await connector.getConnection({
        filterBy: [
          {
            filters: [
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.id,
                // dataType: "SINGLE_SELECT",
              },
              {
                criteria: "connectorType",
                condition: "IS_EQUALS_TO",
                value: "slack",
                // dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: this.mode,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const result = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "slack"
        );

        let data = [
          {
            key: "",
            value: result,
          },
        ];

        this.rowItems = data || [];
        this.rowItemsData = data || [];
        //this.totalItems = meta?.totalItems || 0;
        this.totalItems = result.length || 0;
      }
    },

    async updateSlackEntry(payload) {
      this.$store.commit("showLoadingBarPage");
      const { error } = await connector.updateConnector(
        this.selectedEntry.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`connection deleted`);
      this.getRows();
    },

    async getTeamsConnectionEntries() {
      this.actionItems = true;

      const { error, payload } = await connector.getConnection({
        filterBy: [
          {
            filters: [
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.id,
                // dataType: "SINGLE_SELECT",
              },
              {
                criteria: "connectorType",
                condition: "IS_EQUALS_TO",
                value: "teams",
                // dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: this.mode,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        // console.log(payload);

        const result = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "teams"
        );

        console.log(result, "teams");

        let data = [
          {
            key: "",
            value: result,
          },
        ];

        this.rowItems = data || [];
        this.rowItemsData = data || [];
        //this.totalItems = meta?.totalItems || 0;
        this.totalItems = result.length || 0;
      }
    },

    async updateTeamsEntry(payload) {
      this.$store.commit("showLoadingBarPage");
      const { error } = await connector.updateConnector(
        this.selectedEntry.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`connection deleted`);
      this.getRows();
    },
    async getWhatsappConnectionEntries() {
      this.addConnectionHide = true;
      this.actionItems = true;

      const { error, payload } = await connector.getConnection({
        filterBy: [
          {
            filters: [
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.id,
                // dataType: "SINGLE_SELECT",
              },
              {
                criteria: "connectorType",
                condition: "IS_EQUALS_TO",
                value: "whatsapp",
                // dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: this.mode,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload, "whatssapp");
      if (payload.length) {
        // console.log(payload);

        const result = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "whatsapp"
        );

        //console.log(result, "teams");

        let data = [
          {
            key: "",
            value: result,
          },
        ];
        //console.log(data, "data");

        this.rowItems = data || [];
        this.rowItemsData = data || [];
        //this.totalItems = meta?.totalItems || 0;
        this.totalItems = result.length || 0;
        // console.log(this.rowItems, "data1");
        // console.log(this.rowItemsData, "data2");
        // console.log(this.totalItems, "data3");
      }
    },

    async updateWhatsappEntry(payload) {
      this.$store.commit("showLoadingBarPage");
      const { error } = await connector.updateConnector(
        this.selectedEntry.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`connection deleted`);
      this.getRows();
    },
  },
};
</script>
<style lang="scss" scoped>
#integration-builder-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
