<template>
  <div class="relative-position">
    <!-- form header -->

    <FormHeader
      :form-settings="form.settings"
      :is-loading="isLoading"
      @settings="showSettings"
      @preview="showFormPreview"
      @save="saveForm"
    />

    <!-- ... -->

    <!-- form preview -->

    <FormPreview
      v-model="isFormPreviewVisible"
      :form-id="formId"
      :form-settings="form.settings"
      :panels="form.panels"
      :secondary-panels="form.secondaryPanels"
    />

    <!-- ... -->

    <!-- panel type -->

    <PanelType :value="activePanelType" @input="switchPanelType" />

    <!-- ... -->

    <!-- primary panels -->

    <Panels
      v-show="activePanelType === 'PRIMARY_PANELS'"
      :panels="form.panels"
      :secondary-panels="form.secondaryPanels"
      :panel-type="activePanelType"
      :form-settings="form.settings"
      :lookup-settings="lookupSettings"
      @update="updatePanels"
    />

    <!-- ... -->

    <!-- secondary panels -->

    <Panels
      v-show="activePanelType === 'SECONDARY_PANELS'"
      :panels="form.secondaryPanels"
      :panel-type="activePanelType"
      :form-settings="form.settings"
      @update="updateSecondaryPanels"
    />

    <!-- ... -->
  </div>
</template>

<script>
import FormHeader from "./components/FormHeader.vue";
import FormPreview from "./components/FormPreview.vue";
import PanelType from "./components/PanelType.vue";
import Panels from "./components/panels/Panels.vue";

export default {
  name: "Form",

  components: {
    FormHeader,
    FormPreview,
    PanelType,
    Panels,
  },

  props: {
    formId: {
      type: String,
      default: "0",
    },

    form: {
      type: Object,
      required: true,
    },

    activePanelType: {
      type: String,
      default: "",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    lookupSettings: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isFormPreviewVisible: false,
    };
  },

  methods: {
    switchPanelType(type) {
      this.$emit("switch-panel-type", type);
    },

    showSettings() {
      this.$emit("settings");
    },

    showFormPreview() {
      this.isFormPreviewVisible = true;
    },

    updatePanels(panels) {
      this.$emit("update-panels", panels);
    },

    updateSecondaryPanels(panels) {
      this.$emit("update-secondary-panels", panels);
    },

    saveForm() {
      this.$emit("save");
    },
  },
};
</script>

<style lang="scss" scoped></style>
