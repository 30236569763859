<template>
  <div id="uploaded-file-list">
    <FileList
      :has-files="hasFiles"
      :has-selected-files="hasSelectedFiles"
      :number-of-files="files.length"
      class="q-mb-md"
    >
      <!-- label -->

      <template #label>
        <span v-if="label" class="label">{{ label }}</span>
        <span v-else>Uploaded</span>
      </template>

      <!-- ... -->

      <!-- actions -->

      <template #actions-maximized>
        <template v-if="hasSelectedFiles">
          <!-- <BaseActionButton
          v-show="showSplitBtn"
          v-tooltip.top="'split'"
          is-flat
          icon="mdi-text-box-minus-outline"
          :class="{ 'q-mr-sm': showMergeBtn }"
          @click.stop="$emit('split')"
        /> -->

          <BaseActionButton
            v-show="showMergeBtn"
            v-tooltip.top="'merge'"
            is-flat
            icon="mdi-text-box-plus-outline"
            @click.stop="
              {
                isSelectAll = false;
                $emit('merge');
              }
            "
          />

          <!-- <BaseSeparator
          v-if="showSplitBtn || showMergeBtn"
          has-inset
          is-vertical
          class="q-mx-md"
        /> -->

          <BaseSeparator
            v-if="showMergeBtn"
            has-inset
            is-vertical
            class="q-mx-md"
          />

          <BaseActionButton
            v-show="showAutoProcessBtn"
            v-tooltip.top="'move to autoprocess'"
            is-flat
            icon="mdi-robot-outline"
            class="q-mr-sm"
            @click.stop="
              {
                isSelectAll = false;
                $emit('auto-process');
              }
            "
          />

          <BaseActionButton
            v-show="showManualIndexBtn"
            v-tooltip.top="'move to manual index'"
            is-flat
            icon="mdi-label-multiple-outline"
            @click.stop="
              {
                isSelectAll = false;
                $emit('manual-index');
              }
            "
          />

          <BaseSeparator has-inset is-vertical class="q-mx-md" />

          <BaseActionButton
            v-tooltip:red.top="'Delete'"
            is-flat
            color="red"
            icon="eva-trash-2-outline"
            class="q-mr-md"
            @click.stop="
              {
                isSelectAll = false;
                $emit('delete');
              }
            "
          />
        </template>
        <div v-if="files.length" class="row">
          <div class="col">
            <!-- <div class="label">Select All</div> -->
          </div>

          <BaseIcon
            v-tooltip.top="'select all'"
            :name="
              isSelectAll
                ? 'eva-checkmark-circle-2'
                : 'eva-radio-button-off-outline'
            "
            :color="isSelectAll ? 'secondary' : 'grey'"
            class="q-mx-sm cursor-pointer col-auto"
            @click.stop="selectAllFile"
          />
        </div>
      </template>

      <!-- ... -->

      <!-- files -->

      <template #default>
        <FileListItem
          v-for="file in files"
          :key="file.id"
          :file="file"
          :is-selected="isFileSelected(file)"
          @view="$emit('view', file)"
          @selectFile="fileSelect(file)"
        >
          <template #info>
            <div v-if="file.isSplitted" class="splitted">splitted</div>
            <div v-if="file.isMerged" class="merged">merged</div>
          </template>

          <!-- status -->

          <template #status>
            <BaseIcon name="mdi-circle-small" />
            <div :class="getStatusColor(file.uploadedFrom)">
              {{ file.uploadedFrom }}
            </div>
          </template>

          <!-- ... -->

          <!-- actions -->

          <BaseIcon
            :name="
              isFileSelected(file)
                ? 'eva-checkmark-circle-2'
                : 'eva-radio-button-off-outline'
            "
            :is-inactive="!isFileSelected(file)"
            color="secondary"
            class="q-mr-sm"
            @click.stop="$emit('select', file)"
          />

          <BaseActionButton
            is-flat
            no-border
            icon="eva-more-vertical"
            @click.stop=""
          >
            <q-menu
              transition-show="scale"
              transition-hide="scale"
              anchor="bottom end"
              self="top right"
              content-class="q-py-sm"
            >
              <ListItem
                v-for="action in getActions(file)"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="$emit(action.value, file)"
              />
            </q-menu>
          </BaseActionButton>

          <!-- ... -->
        </FileListItem>
      </template>

      <!-- ... -->
    </FileList>
  </div>
</template>

<script>
import FileList from "@/views/upload-and-index/components/FileList.vue";
import FileListItem from "@/views/upload-and-index/components/FileListItem.vue";
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "UploadedFileList",

  components: { FileList, FileListItem, ListItem },

  props: {
    files: {
      type: Array,
      required: true,
    },

    selectedFiles: {
      type: Array,
      required: true,
    },

    indexingType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      actions: [
        {
          id: this.$nano.id(),
          icon: "mdi-text-box-minus-outline",
          label: "Split",
          value: "split",
        },
        {
          id: this.$nano.id(),
          icon: "eva-eye-outline",
          label: "View",
          value: "view",
        },
        {
          id: this.$nano.id(),
          icon: "mdi-robot-outline",
          label: "Auto Process",
          value: "auto-process",
        },
        {
          id: this.$nano.id(),
          icon: "mdi-label-multiple-outline",
          label: "Manual Index",
          value: "manual-index",
        },
        {
          id: this.$nano.id(),
          icon: "eva-trash-2-outline",
          label: "Delete",
          value: "delete",
        },
      ],
      isSelectAll: false,
    };
  },

  computed: {
    hasFiles() {
      return !!this.files.length;
    },

    hasSelectedFiles() {
      return !!this.selectedFiles.length;
    },

    label() {
      if (!this.selectedFiles.length) {
        return "";
      }

      const chunk = this.selectedFiles.length === 1 ? "file" : "files";
      return `${this.selectedFiles.length} ${chunk} selected`;
    },

    getFileTypes() {
      const groupedFileTypes = this.selectedFiles.reduce((acc, cur) => {
        const fileType = this.getFileType(cur.name);
        acc[fileType] = acc[fileType] + 1 || 1;
        return acc;
      }, {});

      return Object.keys(groupedFileTypes);
    },

    showSplitBtn() {
      if (this.selectedFiles.length === 0) {
        return false;
      }

      const fileTypes = this.getFileTypes;
      return fileTypes.every((type) =>
        ["pdf", "doc", "docx", "ppt", "pptx", "xls", "xlsx"].includes(type)
      );
    },

    showMergeBtn() {
      if (this.selectedFiles.length <= 1) {
        return false;
      }

      const fileTypes = this.getFileTypes;
      return fileTypes.every((type) =>
        [
          "pdf",
          "tiff",
          "tif",
          "doc",
          "docx",
          "ppt",
          "pptx",
          "xls",
          "xlsx",
        ].includes(type)
      );
    },

    showManualIndexBtn() {
      let flag = true;
      if (this.selectedFiles.length) {
        this.selectedFiles.forEach((file) => {
          if (file.repository.fieldsType === "DYNAMIC") {
            flag = false;
          }
        });
      }
      return flag;
    },

    showAutoProcessBtn() {
      if (this.selectedFiles.length === 0 || this.indexingType === "MANUAL") {
        return false;
      }

      const fileTypes = this.getFileTypes;
      return fileTypes.every((type) =>
        ["pdf", "tif", "tiff", "png", "jpeg", "jpg"].includes(type)
      );
    },
  },

  methods: {
    getStatusColor(status) {
      switch (status) {
        case "Web":
          return "text-green";
        case "Mobile":
          return "text-secondary";
        case "Capture":
          return "text-orange";
        case "Plugin":
          return "text-light-blue";
      }
    },

    isFileSelected(file) {
      return !!this.selectedFiles.find((_file) => _file.id === file.id);
    },

    getFileType(fileName) {
      return fileName.split(".").reverse()[0].slice(0, 4).toLowerCase();
    },

    getActions(file) {
      if (file.repository.fieldsType === "DYNAMIC") {
        return this.actions.filter((row) => row.value !== "manual-index");
      } else {
        let filterActions = this.actions;
        const fileType = this.getFileType(file.name);
        let splitRequired = [
          "pdf",
          "doc",
          "docx",
          "ppt",
          "pptx",
          "xls",
          "xlsx",
        ].includes(fileType);
        if (!splitRequired) {
          filterActions = filterActions.filter((row) => row.value !== "split");
        }
        let autoProcessRequired = [
          "pdf",
          "tif",
          "tiff",
          "png",
          "jpeg",
          "jpg",
        ].includes(fileType);
        if (!autoProcessRequired || this.indexingType === "MANUAL") {
          filterActions = filterActions.filter(
            (row) => row.value !== "auto-process"
          );
        }
        return filterActions;
      }
    },

    selectAllFile() {
      this.isSelectAll = !this.isSelectAll;
      this.$emit("selectAll", this.isSelectAll, "UPLOADED");
    },

    fileSelect(file) {
      this.$emit("selectedFile", file);
    },
  },
};
</script>

<style lang="scss" scoped>
#uploaded-file-list {
  .label,
  .status {
    color: var(--secondary);
  }

  .splitted,
  .merged {
    background-color: var(--orange);
    color: white;
    padding: 0px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    margin-left: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .splitted {
    background-color: var(--orange);
  }

  .merged {
    background-color: var(--light-green);
  }
}
</style>
