<template>
  <FormSection
    :icon="isActive ? 'mdi-briefcase' : 'mdi-briefcase-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Workspace Details</template>
    <template #description>
      Select the digital workspace of your choice to create a new root folder
      and associate it with.
    </template>

    <!-- workspace -->

    <ValidationProvider
      v-slot="{ errors }"
      name="workspace"
      :rules="{ required: true }"
    >
      <SelectField
        :value="workspaceId"
        label="workspace"
        is-mandatory
        :options="workspaces"
        :is-readonly="mode === 'EDIT'"
        :error="errors[0]"
        new-option
        class="q-mb-lg"
        :placeholder="'Choose or add a new workspace'"
        @input="updateWorkspace"
      />
    </ValidationProvider>

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import FormSection from "@/components/common/item-builder/FormSection.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

import { workspace } from "@/api/factory.js";

export default {
  name: "WorkspaceDetails",

  components: {
    FormSection,
    ValidationProvider,
    SelectField,
  },

  props: {
    workspaceId: {
      type: [String, Number],
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  data() {
    return {
      workspaces: [],
    };
  },

  created() {
    this.getWorkspaces();
  },

  methods: {
    updateWorkspace(workspaceId) {
      if (typeof workspaceId === "string" && workspaceId) {
        this.$emit("update:workspaceId", workspaceId);
        this.addWorkspace(workspaceId);
      } else {
        this.$emit("update:workspaceId", workspaceId);
      }
    },

    /* api functions */

    async getWorkspaces() {
      const { error, payload } = await workspace.getWorkspaceList();

      if (error) {
        this.$alert.error("Error fetching workspaces");
        return;
      }

      this.workspaces =
        payload &&
        payload.map((workspace) => ({
          id: this.$nano.id(),
          label: workspace.value,
          value: workspace.id,
        }));
    },

    async addWorkspace(name) {
      const { payload, error } = await workspace.createWorkspace({
        name: name,
        description: "",
        color: "",
        userId_Array: [],
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.id) {
          this.workspaces.push({
            id: this.$nano.id(),
            label: name,
            value: payload.id,
          });
          this.$emit("update:workspaceId", payload.id);
        }
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped></style>
