<template>
  <div id="signature-field">
    <FormFieldLabel
      v-if="label"
      :label="label"
      :is-mandatory="isMandatory"
      :tooltip="tooltip"
      :transform="transform"
    />

    <FormFieldWrapper
      :is-focused="isDragging"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!_error"
      class="q-px-sm"
    >
      <!-- signature preview -->

      <div v-if="signatureSrc" class="signature-preview">
        <div class="signature">
          <img :src="signatureSrc" alt="signature" />
        </div>

        <!-- actions -->

        <div v-if="!isReadonly && !isDisabled" class="actions">
          <BaseActionButton
            v-tooltip.bottom="'edit signature'"
            is-flat
            icon="eva-edit-outline"
            class="q-mr-sm"
            @click="editSignature"
          />

          <BaseActionButton
            v-tooltip.bottom="'delete signature'"
            is-flat
            icon="eva-trash-2-outline"
            @click="removeSignature"
          />
        </div>

        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- signature hint -->

      <div v-else class="dropzone">
        <div class="hint">
          <BaseIcon name="mdi-draw" size="72px" inherit-color class="icon" />

          <div class="title">
            <span class="link" @click="showSignaturePad">Draw</span>
            or
            <span class="link" @click="browseSignature">Upload</span>
            your signature
          </div>
          <div class="description">We accept PNG, JPG & JPEG</div>
        </div>

        <input
          ref="dropzone"
          type="file"
          accept=".jpg,.jpeg,.png"
          @dragenter="isDragging = true"
          @dragleave="isDragging = false"
          @drop="isDragging = false"
          @input="handleInput"
        />
      </div>

      <!-- ... -->
    </FormFieldWrapper>

    <FormFieldError v-if="_error" :error="_error" />

    <SignatureCropper
      v-model="isSignatureCropperVisible"
      :image="signatureSrc"
      :aspect-ratio="21 / 12"
      :output-width="210"
      :output-height="120"
      @save="saveSignature"
    />

    <SignaturePad v-model="isSignaturePadVisible" @save="saveSignature" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import SignatureCropper from "@/components/common/form/image-field/ImageCropper.vue";
import SignaturePad from "./SignaturePad.vue";
import { lowerCase } from "lodash-es";

export default {
  name: "SignatureField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    SignatureCropper,
    SignaturePad,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    maxFileSize: {
      type: Number,
      default: 0.5,
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      isDragging: false,
      signatureSrc: "",
      signatureError: "",
      isSignatureCropperVisible: false,
      isSignaturePadVisible: false,
    };
  },

  computed: {
    _error() {
      return this.signatureError || this.error;
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (this.value) {
          this.signatureSrc = this.value;
        }
      },
    },
  },

  methods: {
    handleInput(e) {
      const file = e.target.files[0];
      const fileType = file.type.split("/")[1];
      const fileSize = file.size;

      const isSupportedFileType = ["png", "jpg", "jpeg"].includes(
        lowerCase(fileType)
      );

      if (!isSupportedFileType) {
        this.signatureError = "unsupported file type";
        return;
      }

      const maxFileSizeInBytes = this.maxFileSize * 1024 * 1024;

      if (fileSize > maxFileSizeInBytes) {
        this.signatureError = `file size should be less than or equals ${this.maxFileSize} mb`;
        return;
      }

      this.signatureError = "";

      const reader = new FileReader();
      reader.onload = (event) => {
        this.signatureSrc = event.target.result;
        this.$emit("input", this.signatureSrc);
        this.editSignature();
      };
      reader.readAsDataURL(file);
    },

    browseSignature() {
      this.$refs.dropzone.click();
    },

    showSignaturePad() {
      this.isSignaturePadVisible = true;
    },

    editSignature() {
      this.isSignatureCropperVisible = true;
    },

    saveSignature(signature) {
      this.signatureSrc = signature;
      this.isSignatureCropperVisible = false;

      this.$emit("input", signature);
    },

    removeSignature() {
      this.signatureSrc = "";
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
#signature-field {
  .signature-preview {
    padding: 16px 0px;

    .signature {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      img {
        width: 210px;
        height: auto;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }
  }

  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    &.is-dragging {
      border: 1px dashed var(--secondary);

      .icon {
        color: var(--secondary);
      }
    }

    input {
      display: none;
    }

    .hint {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        color: var(--body-text-color-inverted);
        margin-bottom: 16px;
      }

      .title {
        font-weight: 600;
        margin-bottom: 8px;
        text-align: center;

        .link {
          color: var(--secondary);
          cursor: pointer;
        }
      }

      .description {
        @extend .text-sm;
        color: var(--icon-color-inverted);
        text-align: center;
      }
    }
  }
}
</style>
