<template>
  <div>
    <div id="alert">
      <q-icon
        :name="icon"
        size="36px"
        :color="type === 'reject' || type === 'deleted' ? 'red' : 'white'"
      />

      <div class="content">
        <div class="title" :class="type">{{ type }}</div>
        <div class="message" :class="type">{{ message }}</div>
      </div>
    </div>

    <template v-if="fields.length">
      <BaseSeparator class="q-my-sm separator" />
      <BaseScrollbar style="max-height: 200px" class="q-pa-xs">
        <div
          v-for="(error, index) in fields"
          :key="index"
          class="row items-center q-mb-sm"
        >
          <div class="col-auto q-mr-sm">
            <q-icon
              name="eva-checkmark-circle-2-outline"
              color="white"
              size="24px"
            />
          </div>
          <div class="col">{{ error }}</div>
        </div>
      </BaseScrollbar>
    </template>
  </div>
</template>

<script>
export default {
  name: "Alert",

  props: {
    type: {
      type: String,
      required: true,
    },

    message: {
      type: String,
      required: true,
    },

    fields: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    icon() {
      switch (this.type) {
        case "success":
          return "eva-checkmark-circle-2";
        case "info":
          return "eva-info";
        case "warning":
          return "eva-alert-circle";
        case "error":
          return "eva-close-circle";
        case "reject":
          return "eva-close-circle";
        case "deleted":
          return "eva-trash-2-outline";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#alert {
  display: flex;
  align-items: center;
  cursor: pointer;

  .content {
    flex: 1;
    margin-left: 16px;

    .title {
      @extend .text-md;
      font-weight: 600;
      color: $white;
      text-transform: capitalize;
    }

    .title.reject,
    .title.deleted {
      color: red !important;
    }

    .message {
      color: $white;
    }

    .message.reject,
    .message.deleted {
      color: red !important;
    }
  }
}

.separator {
  height: 2;
  background-color: $white !important;
}
</style>
