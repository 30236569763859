<template>
  <div v-if="expand" id="workfolw_report_chart">
    <div
      style="
        cursor: pointer;
        border-bottom: 1px solid var(--divider-color);
        margin-bottom: 5px;
        margin-top: 10px;
      "
      class="row q-pb-sm"
      @click.stop="expand = false"
    >
      <span class="col-auto"
        ><BaseIcon
          v-tooltip.top="findIcon.iconTootip"
          :name="findIcon.icon"
          :color="findIcon.color"
          class="q-mr-sm q-pb-sm"
          size="25px" /></span
      ><span
        class="col text-base font-bold text-uppercase items-center text-black ellipsis"
        style="cursor: pointer; border-right: 0px"
        >{{ findValue.name }}
        <div
          class="q-mt-xs text-base font-bold text-uppercase items-center ellipsis"
          style="
            color: #475569;
            width: 100%;
            cursor: pointer;
            border-right: 0px;
          "
        >
          {{ findValue.description }}
        </div></span
      >
      <div v-if="filterContains.length" class="icon-container">
        <BaseActionButton
          is-flat
          no-border
          icon="eva-eye"
          color="primary"
          class="q-ml-sm col-auto icon"
        />
        <div class="iconcontent" @click.stop="">
          <div
            v-for="(item, index) in filterContains"
            :key="'model' + index"
            class="col-12 q-mr-sm row items-center filerItem"
            style="width: 100%"
          >
            <span
              class="q-ml-sm q-mr-xs q-mt-sm q-mb-sm text-base font-bold text-black items-center"
              style="cursor: pointer; border-right: 0px"
            >
              {{ item.label }}</span
            >
          </div>
        </div>
      </div>
      <BaseActionButton
        v-tooltip.top="'refresh'"
        is-flat
        no-border
        icon="eva-refresh"
        class="q-ml-sm col-auto"
        @click.stop="refresh"
      />

      <BaseActionButton
        v-tooltip.top="'expand'"
        is-flat
        no-border
        :icon="
          expand
            ? 'eva-arrow-ios-upward-outline'
            : 'eva-arrow-ios-downward-outline'
        "
        class="q-ml-sm col-auto"
      />
      <BaseActionButton
        v-tooltip.bottom="'UnPin'"
        icon="mdi-pin"
        color="secondary"
        no-border
        is-flat
        size="20px col-auto"
        rotate="rotate-45"
        @click.stop="deleteItem"
      />
    </div>
    <div v-if="loadingBarContent">
      <ProgressBar
        :container-height="'75vh'"
        :container-color="'white'"
        :progress-bars="progressBars"
      />
    </div>
    <div v-else class="chart chartBox">
      <div class="row text-capitalize items-center header">
        <div class="col-8">
          {{ filterItem.workflowStatus }}
        </div>
        <div class="col-4">
          <!-- <BaseActionButton
            v-if="item.dashboard"
            icon="mdi-pin"
            color="secondary"
            no-border
            is-flat
            size="20px"
            rotate="rotate-45"
            @click="$emit('isDelete')"
          />
          <BaseActionButton
            v-else
            icon="mdi-pin"
            :color="'gray'"
            no-border
            is-flat
            size="20px"
            rotate="rotate-45"
            @click="saveFilter(item, 'report')"
          /> -->
        </div>
      </div>
      <div v-if="filterListData.type === 'reportCount'" class="column">
        <template>
          <div class="text-primary col self-center q-mt-lg q-mb-sm count">
            {{ findValue.description }}
          </div>
        </template>
        <template>
          <div class="text-primary col self-center q-mt-lg q-mb-sm count">
            {{ count || 0 }}
          </div>
        </template>
      </div>
      <div v-if="filterListData.type === 'Pie'">
        <div
          v-for="(item, key) in pieChartDatas"
          :key="'dropdown-pie' + key"
          class="col-4 q-pr-sm q-pb-sm"
          style="height: 350px"
        >
          <PieChart
            :title="item.name"
            :x-data="item.xAxis"
            :y-data="item.yAxis"
            :options="pieChartOptions"
            :dashboard="item.dashboard"
            :dashboard-view="true"
            @pin="saveFilter(item, 'Pie')"
          />
        </div>
      </div>
      <div v-if="filterListData.type === 'bar'">
        <div
          v-for="(item, index) in barChartDatas"
          :key="'overall-bar' + index"
          class="col-4 q-pr-sm q-pb-sm"
          style="height: 350px"
        >
          <BarChart
            :title="item.key"
            :x-data="item.xAxis"
            :y-data="item.yAxis"
            :is-horizontal="true"
            :is-stacked="true"
            :options="barChartOptions"
            :dashboard="item.dashboard"
            :dashboard-view="true"
            @pin="saveFilter(item, 'bar')"
          />
        </div>
      </div>

      <template v-if="filterListData.type === 'stage'">
        <div id="stage" class="col-6 q-pr-sm q-mb-sm">
          <div
            class="font-bold q-pa-sm row"
            style="border-bottom: 1px solid var(--divider-color)"
          >
            <span class="col">Workflow Stage Distribution</span>
            <!--  -->
          </div>
          <table id="stageTable" class="q-mt-sm">
            <thead>
              <tr>
                <!-- columns -->

                <th v-for="column in stageColumns" :key="column.id">
                  <template v-if="column.type === 'ICON'">
                    <BaseIcon :name="column.label" inherit-color />
                  </template>
                  <template v-else>
                    {{ column.label }}
                  </template>
                </th>

                <!-- ... -->
              </tr>
            </thead>

            <tbody>
              <template v-for="item in stageCount">
                <tr :key="item.id">
                  <!-- columns -->

                  <td v-for="column in stageColumns" :key="column.id">
                    <slot
                      :name="column.name"
                      :value="item[column.name]"
                      :item="item"
                    >
                      <template v-if="column.name === 'icon'">
                        <BaseIcon
                          :name="item[column.name]"
                          :color="item.color"
                        />
                      </template>
                      <template v-else>
                        {{ item[column.name] }}
                      </template>
                    </slot>
                  </td>

                  <!-- ... -->
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </template>

      <div v-if="filterListData.type === 'fullchart'">
        <div class="col-4 q-pr-sm q-pb-sm" style="height: 350px">
          <div class="col-12 q-pr-sm q-mb-sm">
            <div class="chart">
              <div class="row item-center q-body q-mb-sm">
                <div
                  class="col-auto q-mr-xs row cursor-pointer header"
                  :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                  style="width: 100%"
                  @click="
                    chartDataDrilldownStage.month = '';
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount();
                  "
                >
                  Month
                </div>
                <div
                  v-if="chartDataDrilldownStage.month"
                  class="col-auto cursor-pointer header"
                  :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount(chartDataDrilldownStage.month);
                  "
                >
                  / {{ chartDataDrilldownStage.month }}
                </div>
                <div
                  v-if="chartDataDrilldownStage.week"
                  class="col-auto q-ml-xs header"
                  @click="chartDataDrilldownStage.week = ''"
                >
                  / {{ chartDataDrilldownStage.week }}
                </div>
              </div>
              <vue-highcharts
                ref="refWorkflowChart"
                :highcharts="Highcharts"
                :options="chartData"
              ></vue-highcharts>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConfirmDelete
      v-model="isDeleteConfirmationVisible"
      :delete-data="deleteData"
      :label="findValuename"
      @delete="$emit('delete')"
    />
  </div>

  <div v-else class="col-12">
    <div
      id="workfolw_report_chart"
      style="cursor: pointer"
      class="text-base font-bold text-uppercase items-center text-black row"
      @click="findItem"
    >
      <span class="col-auto"
        ><BaseIcon
          v-tooltip.top="findIcon.iconTootip"
          :name="findIcon.icon"
          :color="findIcon.color"
          class="q-mr-sm q-pb-sm"
          size="25px" /></span
      ><span
        class="col text-base font-bold text-uppercase items-center text-black ellipsis"
        style="cursor: pointer; border-right: 0px"
        >{{ findValuename }}
        <div
          class="q-mt-xs text-base font-bold text-uppercase items-center ellipsis"
          style="
            color: #475569;
            width: 100%;
            cursor: pointer;
            border-right: 0px;
          "
        >
          {{ findValue.description }}
        </div></span
      >
      <div v-if="filterContains.length" class="icon-container">
        <BaseActionButton
          is-flat
          no-border
          icon="eva-eye"
          color="primary"
          class="q-ml-sm col-auto icon"
        />
        <div class="iconcontent" @click.stop="">
          <div
            v-for="(item, index) in filterContains"
            :key="'model' + index"
            class="col-12 q-mr-sm row items-center filerItem"
            style="width: 100%"
          >
            <span
              class="q-ml-sm q-mr-xs q-mt-sm q-mb-sm text-base font-bold text-black items-center"
              style="cursor: pointer; border-right: 0px"
            >
              {{ item.label }}</span
            >
          </div>
        </div>
      </div>
      <BaseActionButton
        v-tooltip.top="'refresh'"
        is-flat
        no-border
        icon="eva-refresh"
        class="q-ml-sm col-auto"
        @click.stop="refresh"
      />

      <BaseActionButton
        v-tooltip.top="'open'"
        is-flat
        no-border
        icon="eva-arrow-ios-downward-outline"
        class="q-ml-sm col-auto"
        @click.stop="findItem"
      />
      <BaseActionButton
        v-tooltip.bottom="'UnPin'"
        icon="mdi-pin"
        color="secondary"
        no-border
        is-flat
        size="20px"
        rotate="rotate-45"
        class="col-auto"
        @click.stop="deleteItem"
      />
      <ConfirmDelete
        v-model="isDeleteConfirmationVisible"
        :label="findValuename"
        :delete-data="deleteData"
        @delete="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
import { overview, report } from "@/api/factory.js";
import BarChart from "@/components/common/charts/BarChart.vue";
import PieChart from "@/components/common/charts/PieChart.vue";
import Exporting from "highcharts/modules/exporting.js";
import Drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts";
import VueHighcharts from "vue2-highcharts";
import ConfirmDelete from "@/components/common/display/item-actions/components/UnpinDashboard.vue";
import ProgressBar from "@/views/dashboard/components/MobileLoadingBar.vue";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import { Chart, LinearScale } from "chart.js";
import ExportinSankey from "highcharts/modules/sankey.js";

Drilldown(Highcharts);
Exporting(Highcharts);
ExportinSankey(Highcharts);

Chart.register(LinearScale, SankeyController, Flow);

export default {
  name: "DashboardWorkflowOverview",

  components: {
    BarChart,
    PieChart,
    VueHighcharts,
    ConfirmDelete,
    ProgressBar,
  },

  props: {
    filterItem: {
      type: Object,
      required: true,
    },
    listedData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      expand: false,
      workflowId: 0,
      count: 0,
      pieChartData: [],
      pieChartDatas: [],
      pieChartOptions: {
        chart: {
          height: "85%",
        },
      },
      barChartData: [],
      barChartDatas: [],
      barChartOptions: {
        chart: {
          height: "85%",
        },
      },
      Highcharts: Highcharts,
      chartData: {
        chart: {
          type: "spline",
          height: "330px",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          // categories: [],
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yAxis: {
          title: {
            text: "Count",
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: ["#bfa0fe"],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name && e.point.y) {
                    if (this.chartDataDrilldownStage.month === "") {
                      this.chartDataDrilldownStage.month = e.point.name;
                      this.getWorkflowChartCount(e.point.name);
                    } else if (this.chartDataDrilldownStage.week === "") {
                      this.chartDataDrilldownStage.week = e.point.name;
                      this.getWorkflowChartCount(
                        this.chartDataDrilldownStage.month,
                        e.point.name.replace("Week ", "")
                      );
                    }
                  }
                },
              },
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          // {
          //   name: "Running",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0],
          // },
          // {
          //   name: "Completed",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 21, 11],
          // },
        ],
        drilldown: {},
      },
      chartDataDrilldownStage: { month: "", week: "" },
      findIcon: {},
      deleteData: {},
      isDeleteConfirmationVisible: false,
      sucess: false,
      loadingBarContent: false,
      progressBars: [{ width: "30%" }, { width: "50%" }, { width: "70%" }],
      findValuename: "",
      filterContains: [],
      stageCount: [],
      stageColumns: [
        {
          id: this.$nano.id(),
          name: "icon",
          label: "mdi-state-machine",
          type: "ICON",
        },
        {
          id: this.$nano.id(),
          name: "workflowStage",
          label: "Workflow Stages",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "currentInbox",
          label: "Current Inbox",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "<15 Days",
          label: "<15 Days",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "<30 Days",
          label: "<30 Days",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "<45 Days",
          label: "<45 Days",
          type: "SHORT_TEXT",
        },
      ],
      workflowStage: [],
      filterListData: {},
    };
  },

  computed: {},

  watch: {
    filterItem: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filterItem) {
          this.findValue = this.filterItem;
          this.expand = this.findValue.expand;
          if (this.expand) {
            this.findItem();
          }
          if (this.findValue.label) {
            this.findValuename = this.removeTimestampFromString(
              this.findValue.label
            );
          }
          let parse = JSON.parse(this.findValue.filterJson);
          let findIcons = parse[0].type;
          this.typeData = findIcons;

          console.log(parse, "parse");
          if (findIcons === "reportCount") {
            this.findIcon = {
              icon: "mdi-finance",
              iconTootip: "Workflow Report Request",
              color: "orange",
            };
          } else if (findIcons === "Pie") {
            this.findIcon = {
              icon: "mdi-chart-pie",
              iconTootip: "Workflow Report Pie Chart",
              color: "purple",
            };
          } else if (findIcons === "fullchart") {
            this.findIcon = {
              icon: "mdi-chart-line",
              iconTootip: "Workflow Report Line Chart",
              color: "yellow",
            };
            this.iconTootip = "Payment";
          } else if (findIcons === "bar") {
            this.findIcon = {
              icon: "mdi-chart-bar",
              iconTootip: "Workflow Report Bar Chart",
              color: "green",
            };
            this.iconTootip = "Completed";
          } else if (findIcons === "stage") {
            this.findIcon = {
              icon: "mdi-state-machine",
              iconTootip: "Workflow Stage Report",
              color: "purple",
            };
            this.iconTootip = "Completed";
          }
        }
      },
    },

    // workspaceId: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     if (this.workspaceId) {
    //       this.getRepositories();
    //     }
    //   },
    // },

    // filterId: {
    //   deep: true,
    //   immediate: true,
    //   async handler() {
    //     if (this.filterId === "-1") {
    //       this.showFilterList = true;
    //       this.getFilterData();
    //     } else if (this.filterId) {
    //       this.showFilterList = false;
    //       this.filterList();
    //       this.selectedIds = this.filterId;
    //     } else if (!this.filterId) {
    //       this.showFilterList = false;
    //       this.repositoryReportList = [];
    //     }
    //   },
    // },
  },

  created() {},

  methods: {
    deleteItem() {
      this.listedData.map((item) => {
        if (item.name === this.findValue.label) {
          console.log(item, "itemd");

          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },
    removeTimestampFromString(str) {
      return str
        .replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/, "")
        .trim();
    },
    refresh() {
      console.log(this.stageColumns, "stageColumns");
      if (this.workflowId === 0) {
        this.getUserOverallCount();
      }
      if (this.workflowId !== 0 && this.filterListData.type === "reportCount") {
        this.getWorkflowCount();
      }
      if (
        (this.workflowId !== 0 && this.filterListData.type === "Pie") ||
        (this.workflowId !== 0 && this.filterListData.type === "bar")
      ) {
        this.filterListData.formFields;
        this.getChartByColumns();
      }

      if (this.workflowId !== 0 && this.filterListData.type === "fullchart") {
        this.getWorkflowChartCount();
      }

      // if (this.workflowId !== 0 && this.filterListData.type === "sankey") {
      //   this.formFields = this.filterListData.formFields;
      //   this.sankeyChartsFilter = this.filterListData.filters;
      //    this.overviewChart();
      //    this.overviewTableReport();
      // }

      if (this.workflowId !== 0 && this.filterListData.type === "stage") {
        this.getCountsByStage();
      }
    },

    handleOpen() {
      this.expand = true;
      this.findItem();
    },

    openItem() {
      this.expand = true; // Set expand to true
      this.findItem(); // Reuse your data logic
    },

    closeItem() {
      this.expand = false; // Set expand to false
    },

    async findItem() {
      this.expand = true;
      console.log(this.listedData, "this.listedData");
      this.listedData.map((item) => {
        if (item.name === this.findValue.label) {
          this.expand = true;
          const parsedArray = JSON.parse(item.filterJson);
          const filters = JSON.parse(parsedArray[0].filters);
          console.log(parsedArray, "parsedArray");
          this.filterListData = {
            name: item.name,
            description: item.description,
            key: parsedArray[0].key,
            type: parsedArray[0].type,
            rId: parsedArray[0].uId,
            chartFilter: parsedArray[0].chartFilter,
            formFields: parsedArray[0].formField,
            filterFrom: item.filterFrom,
            filters: filters,
            stage: parsedArray[0].stage,
          };
        }
      });
      this.filterFrom = this.filterListData.filterFrom;
      this.filterFrom = this.filterListData.filterFrom;
      this.workflowId = Number(this.filterListData.rId);
      this.workflowStage = this.filterListData.stage;
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      if (this.workflowId === 0) {
        this.getUserOverallCount();
      }
      if (this.workflowId !== 0 && this.filterListData.type === "reportCount") {
        this.getWorkflowCount();
      }
      if (
        (this.workflowId !== 0 && this.filterListData.type === "Pie") ||
        (this.workflowId !== 0 && this.filterListData.type === "bar")
      ) {
        this.filterListData.formFields;
        this.getChartByColumns();
      }

      if (this.workflowId !== 0 && this.filterListData.type === "fullchart") {
        this.getWorkflowChartCount();
      }

      if (this.workflowId !== 0 && this.filterListData.type === "sankey") {
        this.formFields = this.filterListData.formFields;
        this.sankeyChartsFilter = this.filterListData.filters;
        await this.overviewChart();
        await this.overviewTableReport();
      }

      if (this.workflowId !== 0 && this.filterListData.type === "stage") {
        this.getCountsByStage();
      }
    },

    async getUserOverallCount() {
      this.workflowId = 0;

      this.loadingBarContent = true;
      const { error, payload } = await report.getOverallCount(0);
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      const key = this.filterListData.key;
      const inboxCount = payload[key];
      this.count = inboxCount;
    },

    async getWorkflowCount() {
      this.loadingBarContent = true;

      const { error, payload } = await overview.getRequestCountByUserId(
        this.workflowId
      );
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload, "paying");
      const key = this.filterListData.key;
      const inboxCount = payload[key];
      this.count = inboxCount;
    },

    async getChartByColumns() {
      this.barChartData = [];
      this.pieChartData = [];
      const { error, payload } = await report.getChartByColumns(
        this.workflowId,
        this.filterListData.chartFilter
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        const payloadWithLabels = Object.keys(payload).reduce((acc, key) => {
          const label = this.filterListData.formFields[key.replace(/"/g, "")];
          if (label) {
            acc[label] = payload[key];
          }
          return acc;
        }, {});
        Object.keys(payload).forEach((category) => {
          const categoryName = category.replace(/"/g, "");

          payload[category].forEach((entry) => {
            entry.name = entry[categoryName] ? entry[categoryName] : "";
            delete entry[categoryName];
          });
        });
        if (this.filterListData.type === "bar") {
          Object.keys(payloadWithLabels).forEach((key) => {
            let xAxis = [],
              yAxisSeries1 = [],
              yAxisSeries2 = [];
            payloadWithLabels[key].forEach((row1) => {
              xAxis.push(row1.name);
              yAxisSeries1.push(row1.running);
              yAxisSeries2.push(row1.completed);
            });

            this.barChartData.push({
              key: `${key} - Report Chart`,
              xAxis: xAxis,
              yAxis: [
                {
                  name: "Running",
                  data: yAxisSeries1,
                },
                {
                  name: "Completed",
                  data: yAxisSeries2,
                },
              ],
            });
          });
          console.log(this.barChartData, "chert");
          const str = this.findValue.description;
          console.log(str, "str");
          const foundObject = this.barChartData.find(
            (item) => item.key === str
          );
          this.barChartDatas = [foundObject];
        }
        if (this.filterListData.type === "Pie") {
          Object.keys(payloadWithLabels).forEach((key) => {
            payloadWithLabels[key].forEach((row) => {
              this.pieChartData.push({
                name: `${key} - ${row.name} - Report Chart`,
                xAxis: ["Running", "Completed"],
                yAxis: [row.running, row.completed],
              });
            });
          });
        }
        console.log(this.pieChartData, "chart");
        const str = this.findValue.description;
        console.log(str, "str");
        const foundObject = this.pieChartData.find((item) => item.name === str);
        this.pieChartDatas = [foundObject];
        console.log(this.pieChartDatas.xAxis, "chart");
      }
    },
    async getWorkflowChartCount(month = "", weekno = "") {
      this.pinedchart = false;
      this.loadingBarContent = true;
      this.chartData.series = [];
      const weekNumber = weekno.replace(/\D/g, "");
      let input = {
        year: new Date().getFullYear(),
        month: month,
        weekno: weekNumber,
      };
      const { error, payload } = await report.getWorkflowChart(
        this.workflowId,
        input
      );

      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload, "payloading");

      if (payload) {
        if (payload.completed && payload.initiated) {
          let yAxis = [];
          // this.chartData.xAxis.categories = Object.keys(payload.completed);

          yAxis.push({
            name: "Running",
            data: Object.values(payload.initiated).map(Number),
          });

          yAxis.push({
            name: "Completed",
            data: Object.values(payload.completed).map(Number),
          });

          this.chartData.series = yAxis;

          let lineCharts = this.$refs.refWorkflowChart;
          try {
            lineCharts.removeSeries();
          } catch (e) {
            //
          }

          lineCharts.addSeries(this.chartData.series[0]);
          lineCharts.addSeries(this.chartData.series[1]);
        }
      }

      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.description,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));
      List.forEach((item) => {
        let id = item.filterJson[0].uId;
        let type = item.filterJson[0].type;
        if (id === this.workflowId && type === "fullchart") {
          this.pinedchart = true;
        }
      });
    },
    async getCountsByStage() {
      this.stageCount = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await report.getCountsByStage(this.workflowId);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.workflowStage.forEach((stage) => {
        const existingEntry = this.stageCount.find(
          (entry) => entry.workflowStage === stage.label
        );

        if (!existingEntry && stage.type === "START") {
          this.stageCount.push({
            stageId: stage.key,
            workflowStage: stage.label,
            icon: stage.icon,
            type: stage.type,
            color: stage.color,
            currentInbox: 0,
            ["<15 Days"]: 0,
            ["<30 Days"]: 0,
            ["<45 Days"]: 0,
          });
        }
      });

      this.workflowStage.forEach((stage) => {
        const existingEntry = this.stageCount.find(
          (entry) => entry.workflowStage === stage.label
        );

        if (!existingEntry && stage.type !== "START" && stage.type !== "END") {
          this.stageCount.push({
            stageId: stage.key,
            workflowStage: stage.label,
            icon: stage.icon,
            type: stage.type,
            color: stage.color,
            currentInbox: 0,
            ["<15 Days"]: 0,
            ["<30 Days"]: 0,
            ["<45 Days"]: 0,
          });
        }
      });

      this.workflowStage.forEach((stage) => {
        const existingEntry = this.stageCount.find(
          (entry) => entry.workflowStage === stage.label
        );

        if (!existingEntry && stage.type === "END") {
          this.stageCount.push({
            stageId: stage.key,
            workflowStage: stage.label,
            icon: stage.icon,
            type: stage.type,
            color: stage.color,
            currentInbox: 0,
            ["<15 Days"]: 0,
            ["<30 Days"]: 0,
            ["<45 Days"]: 0,
          });
        }
      });

      if (payload) {
        this.workflowStage.forEach((stage) => {
          const matchingKey = payload.find(
            (item) => stage.key === item.activityId
          );
          matchingKey.label = stage.label;

          this.stageCount.forEach((countItem) => {
            if (matchingKey.label === countItem.workflowStage) {
              countItem.currentInbox += matchingKey.currentInbox || 0;
              countItem["<15 Days"] += matchingKey["< 15 Days"] || 0;
              countItem["<30 Days"] += matchingKey["< 30 Days"] || 0;
              countItem["<45 Days"] += matchingKey["< 45 Days"] || 0;
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#workfolw_report_chart {
  background-color: white;
  padding: 14px;
  border-radius: 10px;
  width: 99%;
  margin: 5px;
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 300px);
  }
  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }

  .backGround {
    border: 2px;
    border-radius: 10px;
    background-color: #87dcf626;
    margin-bottom: 8px;
  }
  .count {
    color: rgb(145, 6, 209);
    font-weight: 800;
    font-size: 22px;
  }
  #stage {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);

    #stageTable {
      border: 1px solid var(--divider-color);
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
        font-weight: 500;
      }

      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }
    }
  }
}
</style>
