var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"folder-fields"}},[(_vm.fieldsType === 'STATIC')?[(_vm.checkTenant)?[_c('Draggable',{model:{value:(_vm.fields),callback:function ($$v) {_vm.fields=$$v},expression:"fields"}},_vm._l((_vm.fields),function(field,index){return _c('div',{key:field._id,staticClass:"field row items-center"},[_c('BaseIcon',{staticClass:"drag-indicator",attrs:{"name":"drag_indicator","size":"16px"}}),_c('div',{staticClass:"col row"},[_c('BaseIcon',{attrs:{"name":index === _vm.fields.length - 1
                  ? 'eva-file-text-outline'
                  : 'mdi-folder',"color":index === _vm.fields.length - 1 ? 'secondary' : 'amber'}}),_c('div',{staticClass:"q-ml-md"},[_vm._v(_vm._s(field.name))])],1),_c('div',{staticStyle:{"width":"140px"}},[_vm._v(_vm._s(field.dataType))]),_c('div',{staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(field.isMandatory ? "Yes" : "No")+" ")]),_c('BaseActionButton',{directives:[{name:"tooltip",rawName:"v-tooltip:secondary.left",value:('edit field'),expression:"'edit field'",arg:"secondary",modifiers:{"left":true}}],attrs:{"is-flat":"","color":"secondary","icon":"eva-edit-outline","no-border":""},on:{"click":function($event){return _vm.handleEdit(field._id)}}}),_c('BaseActionButton',{directives:[{name:"tooltip",rawName:"v-tooltip:red.right",value:('delete field'),expression:"'delete field'",arg:"red",modifiers:{"right":true}}],attrs:{"is-flat":"","color":"red","icon":"eva-close-outline","no-border":""},on:{"click":function($event){return _vm.handleDelete(field._id)}}})],1)}),0)]:_vm._l((_vm.fields),function(field,index){return _c('div',{key:field._id,staticClass:"field row items-center drag-false"},[_c('div',{staticClass:"col row"},[_c('BaseIcon',{attrs:{"name":index === _vm.fields.length - 1
                ? 'eva-file-text-outline'
                : 'mdi-folder',"color":index === _vm.fields.length - 1 ? 'secondary' : 'amber'}}),_c('div',{staticClass:"q-ml-md"},[_vm._v(_vm._s(field.name))])],1),_c('div',{staticStyle:{"width":"140px"}},[_vm._v(_vm._s(field.dataType))]),_c('div',{staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(field.isMandatory ? "Yes" : "No")+" ")]),_c('BaseActionButton',{directives:[{name:"tooltip",rawName:"v-tooltip:secondary.left",value:('edit field'),expression:"'edit field'",arg:"secondary",modifiers:{"left":true}}],attrs:{"is-flat":"","color":"secondary","icon":"eva-edit-outline","no-border":""},on:{"click":function($event){return _vm.handleEdit(field._id)}}}),_c('BaseActionButton',{directives:[{name:"tooltip",rawName:"v-tooltip:red.right",value:('delete field'),expression:"'delete field'",arg:"red",modifiers:{"right":true}}],attrs:{"is-flat":"","color":"red","icon":"eva-close-outline","no-border":""},on:{"click":function($event){return _vm.handleDelete(field._id)}}})],1)})]:_vm._l((_vm.fields),function(field,index){return _c('div',{key:field._id,staticClass:"field row items-center dynamic"},[_c('BaseIcon',{staticClass:"drag-indicator",attrs:{"name":"drag_indicator","size":"16px"}}),_c('div',{staticClass:"col row"},[_c('BaseIcon',{attrs:{"name":index === _vm.fields.length - 1
              ? 'eva-file-text-outline'
              : 'mdi-folder',"color":index === _vm.fields.length - 1 ? 'secondary' : 'amber'}}),_c('div',{staticClass:"q-ml-md"},[_vm._v(_vm._s(field.name))])],1),_c('div',{staticStyle:{"width":"120px"}},[_vm._v(_vm._s(field.dataType))]),_c('div',{staticStyle:{"width":"120px"}},[_vm._v(_vm._s(field.isMandatory ? "Yes" : "No"))])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }