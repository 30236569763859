<template>
  <div class="container">
    <!-- header -->
    <div class="header">
      <div>
        <div class="row">
          <div class="col text-center">
            <div class="title-1">Upload file</div>
            <div class="description">
              Selected files have been uploaded to the scanned directory.
            </div>
          </div>
          <ThemeSwitcher class="q-ml-sm theme-switch" :no-border="false" />
        </div>

        <!-- content  -->
        <div class="content">
          <BaseScrollbar height="calc(100vh - 211px)" class="q-pa-xs">
            <div class="upload-section">
              <div v-if="!fileUploaded" class="upload-content">
                <h6>{{ fieldName }}</h6>
                <p>
                  This page will be expire in
                  <span style="color: red">{{ remainTime }}</span>
                </p>
                <BaseSheetLoading v-if="loadingBarSave" />
                <!-- <p>Some description</p> -->
                <div class="file-upload">
                  <input
                    id="file-input"
                    type="file"
                    :multiple="isMultiple"
                    hidden
                    @change="handleFileUpload"
                  />
                  <label for="file-input" class="file-label">
                    <div class="file-icon">
                      <BaseIcon
                        name="eva-cloud-upload-outline"
                        size="20px"
                        color="primary"
                        class="icon"
                      />
                    </div>
                    <p>Select file</p>
                  </label>
                </div>
                <!-- <p>or</p> -->
                <div>
                  <div class="or-section"><span>(or)</span></div>
                </div>

                <div class="file-upload">
                  <input
                    id="camera-input"
                    type="file"
                    :multiple="isMultiple"
                    accept="image/*"
                    capture="environment"
                    hidden
                    @change="handleFileUpload"
                  />

                  <label for="camera-input" class="file-label">
                    <div class="file-icon">
                      <BaseIcon
                        name="mdi-camera"
                        size="20px"
                        color="primary"
                        class="icon"
                      />
                    </div>
                    <p>Capture image</p>
                  </label>
                </div>

                <!-- <div class="file-preview">
                  <img v-if="isImage" :src="imageSrc" class="preview-image" />
                  <p v-else-if="fileName" class="fileName">
                    <FileIcon
                      :mime-type="fileType(fileName)"
                      class="mini-avatar q-mr-sm"
                    />
                    {{ fileName }}
                  </p>
                </div> -->

                <FormFieldLabel
                  v-if="filesToUpload.length"
                  label="Attachment List"
                  class="q-mb-md"
                />

                <FileListItem
                  v-for="(attach, index) in filesToUpload"
                  :key="attach.id"
                  :file="attach"
                  class="file"
                >
                  <BaseActionButton
                    v-tooltip:red.top="'Delete'"
                    is-flat
                    color="red"
                    icon="eva-trash-2-outline"
                    class="q-mr-md"
                    @click.stop="deleteFiles(index)"
                  />
                </FileListItem>
                <div v-if="isUploading" class="loading-bar">
                  <q-circular-progress
                    show-value
                    font-size="12px"
                    :value="uploadProgress"
                    size="lg"
                    :thickness="0.22"
                    color="primary"
                    track-color="grey-3"
                    class="q-mr-sm"
                  >
                    {{ uploadProgress }}%
                  </q-circular-progress>
                </div>
              </div>
              <template v-if="fileUploaded">
                <StateWrapper
                  v-if="timeOut"
                  icon="mdi-file-clock-outline"
                  title="URL expired"
                  description="Generate a new QR to upload documents"
                  style="margin-top: 80vh !important"
                />
                <StateWrapper
                  v-else
                  icon="mdi-file-check-outline"
                  title="Documents uploaded"
                  description="Generate a new QR to upload documents"
                  style="margin-top: 80vh !important"
                />
              </template>
            </div>
          </BaseScrollbar>
          <button
            v-if="!fileUploaded"
            class="continue-button"
            @click="uploadFileInMobile"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <ImageCropper
      v-model="isImageCropperVisible"
      :image="originalImage"
      @save="saveImage"
    />

    <Confirm
      v-model="qrUploaded"
      icon="eva-file-text-outline"
      icon-color="red"
      :has-footer="false"
      :close-icon="false"
    >
      <template #title> File uploaded</template>

      <template #default>
        <div class="q-py-md">
          <p>Your File has been uploaded successfully.</p>
        </div>
      </template>
    </Confirm>
  </div>
</template>

<script>
import ThemeSwitcher from "@/layouts/app/components/toolbar/components/ThemeSwitcher.vue";
import { auth, repository } from "@/api/factory.js";
import axios from "axios";
import { fileSupport } from "@/helpers/file-format.js";
import ImageCropper from "@/components/common/form/image-field/ImageCropper.vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
// import FileIcon from "@/components/common/FileIcon.vue";
import Confirm from "@/components/common/popup/Confirm.vue";
import FileListItem from "@/views/upload-and-index/components/FileListItem.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import { base64ToFile } from "@/helpers/base64-to-file.js";
import { decrypt } from "@/helpers/crypto";
import { isEmpty } from "lodash-es";
export default {
  name: "MobileUpload",

  components: {
    ThemeSwitcher,
    ImageCropper,
    StateWrapper,
    // FileIcon,
    Confirm,
    FileListItem,
    FormFieldLabel,
  },
  props: {
    tenantId: {
      type: String,
      required: true,
    },

    userId: {
      type: String,
      required: true,
    },

    uniqueId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      file: null,
      filePreview: null,
      isImage: false,
      fileName: "",
      isUploading: false,
      uploadProgress: 0,
      attachmentList: [],
      imageSrc: "",
      isImageCropperVisible: false,
      repositoryId: 0,
      url: "",
      upload: false,
      fieldId: "",
      originalImage: "",
      fieldName: "",
      fileUploaded: false,
      timeOut: false,
      remainingTime: 180,
      remainTime: "03:00",
      qrUploaded: false,
      isMultiple: false,
      settings: {},
      files: [],
      filesToUpload: [],
      formFields: [],
      loadingBarSave: false,
      qrTableInput: "",
    };
  },

  watch: {
    userId: {
      immediate: true,
      handler: "getSession",
    },

    isImageCropperVisible() {
      if (!this.isImageCropperVisible) {
        console.log(this.filesToUpload);
        let fileImage = this.filesToUpload.filter(
          (file) => !file.isEdited && file.isImage
        );
        // console.log(fileImage, "fileImage1");
        setTimeout(() => {
          if (fileImage.length) {
            this.fileName = fileImage[0].name;
            this.originalImage = fileImage[0].image;
            this.isImageCropperVisible = true;
          }
        }, 300);
      }
    },
  },

  methods: {
    saveImage(croppedImage) {
      // console.log(croppedImage, "croppedImage");
      // this.imageSrc = croppedImage;
      const newFile = base64ToFile(croppedImage, this.file.name);
      // this.file = newFile;
      let hasImage = this.filesToUpload.findIndex((file) => {
        return file.name === this.fileName;
      });
      // console.log(hasImage, "hasImage");
      if (hasImage >= 0) {
        this.filesToUpload[hasImage].file = newFile;
        this.filesToUpload[hasImage].isEdited = true;
      }
      this.fileName = "";
      this.originalImage = "";

      // let fileImage = this.filesToUpload.filter(
      //   (file) => !file.isEdited && file.isImage
      // );
      // console.log(fileImage, "fileImage");
      // if (fileImage.length) {
      //   this.fileName = fileImage[0].name;
      //   this.originalImage = fileImage[0].image;
      //   this.isImageCropperVisible = true;
      // } else {
      //   this.isImageCropperVisible = false;
      // }

      // this.filePreview = croppedImage;
      // this.$emit("input", croppedImage);
    },

    base64ToFile(base64String, fileName) {
      const arr = base64String.split(",");
      const mimeType = arr[0].match(/:(.*?);/)[1];
      const byteString = atob(arr[1]);

      const n = byteString.length;
      const uint8Array = new Uint8Array(n);

      for (let i = 0; i < n; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      return new File([uint8Array], fileName, { type: mimeType });
    },

    async getSession() {
      this.loadingBarSave = true;
      console.log(this.tenantId, this.userId, this.uniqueId);
      const { error } = await auth.externalLogin(this.tenantId, this.userId);
      if (error) {
        this.$alert.info("User Account Not Found");
        return;
      }
      await this.getProcess();
      this.loadingBarSave = false;

      // this.$store.commit("hideLoadingBarSave");
    },

    async getProcess() {
      const { error, payload } = await repository.getQrFileUploadById(
        this.uniqueId
      );
      console.log(error, payload, "error, payload");

      if (error) {
        this.upload = true;
        this.$alert.info("form details Not Found");
        return;
      }
      if (payload.status) {
        this.upload = true;
        this.$alert.info("file already uploaded");
        this.fileUploaded = true;
        return;
      }
      let jsonData = JSON.parse(payload.jsonData);
      console.log(jsonData, "jsonData");
      this.repositoryId = jsonData[0].repositoryId;
      this.fieldId = jsonData[0].fieldId;
      this.fieldName = jsonData[0].fieldName;
      this.url = jsonData[0].url;
      this.timeOut = jsonData[0].expired;
      this.time = jsonData[0].time;
      this.settings = jsonData[0].settings;
      this.formFields = jsonData[0].formFields;
      this.qrTableInput = jsonData[0].qrTableInput;
      // console.log(this.settings, "this.settings");

      if (this.settings) {
        if (this.settings.settings.specific.allowMultipleFiles) {
          this.isMultiple = this.settings.settings.specific.allowMultipleFiles;
        }
      }

      if (this.timeOut) {
        this.fileUploaded = true;
        this.$alert.info("QR Expired");
        return;
      } else {
        this.getRemainTime();
      }
    },
    getRemainTime() {
      this.remainingTime = 180;

      const countdown = setInterval(() => {
        let currentTime = new Date().getTime();
        let timeDifference = (currentTime - this.time) / 1000;

        if (timeDifference >= 180) {
          clearInterval(countdown);
          this.remainTime = "00:00";
          this.fileUploaded = true;
          this.timeOut = true;
          this.$alert.info("QR Expired");
          return;
        }

        this.remainingTime = 180 - Math.floor(timeDifference);

        let minutes = Math.floor(this.remainingTime / 60);
        let seconds = this.remainingTime % 60;

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.remainTime = `${minutes}:${seconds}`;
      }, 1000);
    },
    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },
    async handleFileUpload(event) {
      this.originalImage = "";
      this.files = [];
      const file = event.target.files[0];
      this.files = event.target.files;

      if (!file) return;

      // const reader = new FileReader();

      // const fileData = await new Promise((resolve, reject) => {
      //   reader.onload = (e) => {
      //     resolve(e.target.result);
      //   };
      //   reader.onerror = reject;

      //   reader.readAsDataURL(file);
      // });

      for (let file of this.files) {
        const fileType = file.name.split(".").reverse()[0];
        const reader = new FileReader();

        const image = await new Promise((resolve, reject) => {
          reader.onload = (e) => {
            resolve(e.target.result);
          };
          reader.onerror = reject;

          reader.readAsDataURL(file);
        });
        let isImage = file.type.startsWith("image/");
        this.filesToUpload.push({
          id: this.$nano.id(),
          name: file.name,
          type: fileType.slice(0, 4),
          size: file.size,
          createdAt: new Date(),
          status: "UPLOADING",
          file: file,
          uploadedPercentage: 0,
          image: image,
          isEdited: false,
          isImage: isImage,
        });

        // console.log(file.type.startsWith("image/"));
      }
      let imageFile = this.filesToUpload.filter((file) => file.isImage);
      if (imageFile.length) {
        this.fileName = imageFile[0].name;
        this.originalImage = imageFile[0].image;
        this.isImageCropperVisible = true;
      }

      // this.filePreview = fileData;
      // console.log(file.name, "file.name");

      // this.files.push(file);

      this.file = file;
      // this.fileName = file.name;
      // console.log(file.type.startsWith("image/"), "file.type.startsWith(img)");
      // this.isImage = file.type.startsWith("image/");

      // console.log(file, "file");

      // if (this.isImage) {
      //   this.originalImage = this.filePreview;
      //   this.imageSrc = this.filePreview;
      //   this.isImageCropperVisible = true;
      // } else {
      //   this.filePreview = true;
      //   this.imageSrc = "";
      // }

      // console.log(this.filePreview, "this.filePreview");
      this.getProcess();
    },

    uploadFileInMobile() {
      let control = this.settings.settings.validation.assignOtherControls;
      let aiSettings = this.settings.settings.aiSettings;
      let hasAI = false;

      if (!isEmpty(aiSettings)) {
        if (aiSettings.formControlValidate) {
          if (aiSettings.fileDataValidate.length) {
            if (aiSettings.fileDataValidate.includes("EXPIRY_DATE")) {
              hasAI = true;
            }
          }
        }
      }

      this.isUploading = true;
      this.uploadProgress = 20;

      let formFields = [];
      if (control && control.length) {
        control.forEach((field) => {
          if (!isEmpty(this.qrTableInput)) {
            let name = this.formFields.find(
              (item) => item.value === this.qrTableInput.tableId
            );
            if (name) {
              let columns = name.settings.specific.tableColumns;
              let hasColumn = columns.find((item) => item.id === field);
              if (hasColumn) {
                formFields.push(`${hasColumn.label}, ${hasColumn.type}`);
              }
            }
          } else {
            let name = this.formFields.find((item) => item.value === field);
            if (name) {
              formFields.push(`${name.label}, ${name.type}`);
            }
          }
        });
      }

      let validateType = 0;

      if (formFields && formFields.length && !hasAI) {
        validateType = 1;
      } else if (!formFields.length && hasAI) {
        validateType = 2;
      } else if (formFields && formFields.length && hasAI) {
        validateType = 3;
      }
      console.log(validateType, "validateType");

      if (validateType) {
        this.getOCRResult(validateType, formFields);
      } else {
        this.uploadFile();
      }
    },

    async getOCRResult(validateType, formFields) {
      for (let file of this.filesToUpload) {
        const formData = new FormData();
        formData.append("file", file.file);
        formData.append("repositoryId", this.repositoryId);

        formData.append("formFields", JSON.stringify(formFields));

        formData.append("validateType", validateType);

        let filename = file.name;
        const ext = filename.split(".").pop();

        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        // console.log(formData, "formData");

        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/uploadforStaticMetadata`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });

          this.$store.commit("hideLoadingBar");
          console.log(response, "response");
          const { status, data } = response;
          // e.target.value = "";
          if (status !== 200) {
            throw "Invalid response";
          }
          // console.log(data);

          let list = decrypt(
            data,
            this.$store.state.identity.key,
            this.$store.state.identity.iv
          );
          console.log(list);

          let ocrList = JSON.parse(list);
          let ocr = JSON.parse(ocrList);
          var ocrFile = JSON.parse(ocr);
          console.log(ocrFile, "ocrFile");

          file.fileDataValidate = JSON.stringify(ocrFile.aiResult);
          var prediction = true;
          if (ocrFile.aiResult) {
            var issues = ocrFile.aiResult;
            if (!isEmpty(issues)) {
              let status = issues.prediction.replace(/[^a-zA-Z ]/g, "");

              if (
                status.toLowerCase() === "valid" ||
                status.toLowerCase() === "not expired" ||
                status.toLowerCase() === "not_expired"
              ) {
                prediction = true;
              } else {
                prediction = false;
              }
            }
          }
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
        if (prediction) {
          this.uploadFile(ocrFile);
        } else {
          if (issues) {
            this.$alert.error(
              `The uploaded document has expired  ( ${issues.name}: ${issues.value})`
            );
          }
        }
      }
    },

    async uploadFile(ocrFile) {
      this.getProcess();
      if (!this.file) return;
      if (this.upload) {
        //this.$alert.info("file already uploaded");
        this.fileUploaded = true;
        return;
      }
      this.isUploading = true;
      if (!ocrFile) {
        this.uploadProgress = 0;
      }
      // Simulate file upload
      const interval = setInterval(() => {
        if (this.uploadProgress < 90) {
          this.uploadProgress += 10;
        } else {
          clearInterval(interval);
        }
      }, 500);
      // const file = this.file;
      let fields = this.file.name;
      // console.log(this.files, "files");

      for (let file of this.filesToUpload) {
        const formData = new FormData();
        formData.append("file", file.file);
        formData.append("repositoryId", this.repositoryId);
        formData.append("filename", file.name);
        if (fields) {
          // this.fileData = JSON.parse(fields);
          // formData.append("filename", this.file.name);
        }

        let filename = file.name;
        const ext = filename.split(".").pop();

        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        // console.log(formData, "formData");
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBar");
          console.log(response, "response");
          const { status, data } = response;
          // e.target.value = "";
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          // let fileDataValidate = filesList.OCRfieldlist;
          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.rowIndex,
            ocrFile: ocrFile,
            // fileDataValidate: fileDataValidate,
          });

          console.log(this.attachmentList, "this.attachmentList");
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
      // if (this.attachmentList.length === this.filesToUpload.length) {
      await this.updateJson();
      // }
    },
    async updateJson() {
      // console.log(this.repositoryId, this.url, this.attachmentList);
      let input = {
        id: this.uniqueId,
        url: this.url,
        jsonData: JSON.stringify([
          {
            repositoryId: this.repositoryId,
            fieldId: this.fieldId,
            attachmentList: this.attachmentList,
            settings: this.settings,
            qrTableInput: this.qrTableInput,
          },
        ]),
        status: 1,
      };
      const { error, payload } = await repository.updateJson(
        this.uniqueId,
        input
      );
      // if (this.attachmentList.length === this.filesToUpload.length) {
      this.uploadProgress = 100;
      this.isUploading = false;
      this.qrUploaded = true;
      this.fileUploaded = true;
      // }
      console.log(error, payload, "error, payload");
      //this.$alert.success(`file uploaded successfully!`);

      // location.reload();
      // this.$alert.success(`Document attached`);
    },

    deleteFiles(index) {
      this.filesToUpload.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 16px;
  border-radius: 4px;
  width: 100%;
  background-color: var(--component-bg-color);
  border-top: 4px solid var(--primary);
  display: flex;
  height: 100%;
  justify-content: center;

  .description {
    color: var(--icon-color-inverted);
  }
  .theme-switch {
    position: absolute;
    right: 40px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: auto;
  height: 100vh;
  background: linear-gradient(135deg, #dec2e7, #b2ebf2);
  overflow-y: hidden;
}
.upload-content {
  width: 100%;
}
.content {
  max-width: 400px;
  width: 100%;
  height: 85%;
}
h6 {
  margin: 0px;
}

.upload-section {
  text-align: center;
  width: 100%;
  overflow-y: auto;
  height: 77%;
  display: flex;
  align-items: center;
  margin: 30px 0px;
  justify-content: center;
}

.file-upload {
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.file-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-icon {
  font-size: 2em;
}

.camera-button {
  // background-color: #aa86c06e;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.continue-button {
  background-color: var(--secondary);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 1em;
  cursor: pointer;
  width: 100%;
}

.file-preview {
  text-align: center;
  margin: 20px 0;
}

.preview-image {
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
  margin: 10px 0;
}

.loading-bar {
  margin-top: 20px;
}
.or-section {
  display: flex;
  justify-content: center;
  color: #cdd5dc;
  margin: 15px 0 15px 0;
}
.or-section:after,
.or-section:before {
  content: "........................";
  flex: 1;
  height: 1px;
  margin: -3px 10px;
}
.mini-avatar {
  width: 18px !important;
  height: 18px !important;
}
.fileName {
  align-items: center;
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .header {
    width: 50%;
    .theme-switch {
      position: absolute;
      right: 26.5%;
    }
  }
}
</style>
<style lang="scss">
#file-list-item {
  padding: 12px 0px !important;
}
</style>
