<template>
  <div id="options-builder">
    <!-- options type -->

    <SingleChoiceField
      v-if="showOptionsType"
      is-mandatory
      label="Options type"
      :value="optionsType"
      :options-per-line="1"
      :options="_optionsTypes"
      class="q-pa-md"
      @input="updateOptionsType"
    />

    <!-- ... -->

    <!-- master table -->

    <div v-if="optionsType === 'MASTER'" class="q-pa-md">
      <FormFieldLabel label="master table" is-mandatory class="field-label" />
      <div class="row q-col-gutter-sm">
        <!-- master table -->

        <ValidationProvider
          v-slot="{ errors }"
          name="table"
          :rules="{ required: true }"
          class="col"
        >
          <SelectField
            is-mandatory
            placeholder="Table"
            :error="errors[0]"
            :value="masterFormId"
            :is-clearable="true"
            :options="masterForms"
            @input="updateMasterFormId"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- column -->

        <ValidationProvider
          v-slot="{ errors }"
          name="column"
          :rules="{ required: true }"
          class="col"
        >
          <SelectField
            is-mandatory
            placeholder="Column"
            :error="errors[0]"
            :is-clearable="true"
            :value="masterFormColumn"
            :options="masterFormColumns"
            @input="updateMasterFormColumn"
          />
        </ValidationProvider>

        <!-- ... -->
      </div>

      <!-- same master -->

      <SingleChoiceField
        v-if="masterFormId"
        label="same master"
        :value="isSameMaster"
        :options-per-line="2"
        :options="binaryOptions"
        class="q-pt-md"
        @input="updateSameMaster"
      />

      <!-- ... -->

      <!-- column -->

      <!-- <ValidationProvider
        v-if="!isSameMaster && masterFormId"
        v-slot="{ errors }"
        name="condition column"
        :rules="{ required: !isSameMaster }"
        class="col"
      >
        <SelectField
          is-mandatory
          label="condition column"
          :error="errors[0]"
          :is-clearable="true"
          :value="masterFormConditionColumn"
          :options="masterFormColumns"
          class="q-pt-md"
          @input="updateMasterFormConditionColumn"
        />
      </ValidationProvider> -->

      <div v-if="!isSameMaster && masterFormId" class="q-pt-md col-12">
        <FormFieldLabel label="condition column" class="" is-mandatory />
        <BaseScrollbar>
          <div id="condition-column">
            <table>
              <thead>
                <tr>
                  <th class="medium">Form Column</th>
                  <th class="large">Master Column</th>
                  <th class="action">
                    <BaseActionButton
                      is-flat
                      class="q-ml-xs"
                      color="secondary"
                      icon="eva-plus-outline"
                      no-border
                      @click="addRowInConditionColumn"
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(row, index) in masterFormConditionColumn"
                  :key="index"
                >
                  <td>
                    <div class="row">
                      <SelectField
                        v-model="row.formColumn"
                        class="col"
                        :options="panelFields()"
                        :is-clearable="false"
                        @input="updateMasterFormConditionColumn"
                      />
                    </div>
                  </td>
                  <td>
                    <SelectField
                      v-model="row.masterColumn"
                      :options="masterFormColumns"
                      @input="updateMasterFormConditionColumn"
                    />
                  </td>
                  <td class="action">
                    <BaseActionButton
                      is-flat
                      color="red"
                      icon="eva-close-outline"
                      no-border
                      @click="removeRowInConditionColumn(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <FormFieldLabel
        label="Parent Field (Filter based on parent field value selection)"
        is-mandatory
        class="field-label q-pt-md"
      />

      <div class="">
        <SelectField
          is-mandatory
          placeholder="Parent Field"
          :value="masterFormParentColumn"
          :is-clearable="true"
          :options="panelFields()"
          @input="updateMasterFormParentColumn"
        />
      </div>
    </div>

    <!-- repository table -->

    <div v-if="optionsType === 'REPOSITORY'" class="q-pa-md">
      <FormFieldLabel label="folder table" is-mandatory class="field-label" />
      <div class="row q-col-gutter-sm">
        <!-- folder table -->

        <ValidationProvider
          v-slot="{ errors }"
          name="table"
          :rules="{ required: true }"
          class="col"
        >
          <SelectField
            is-mandatory
            placeholder="Table"
            :error="errors[0]"
            :value="repositoryId"
            :is-clearable="true"
            :options="repositoryList"
            @input="updateRepositoryId"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- column -->

        <ValidationProvider
          v-slot="{ errors }"
          name="column"
          :rules="{ required: true }"
          class="col"
        >
          <SelectField
            is-mandatory
            placeholder="Column"
            :error="errors[0]"
            :is-clearable="true"
            :value="repositoryField"
            :options="repositoryFields"
            @input="updateRepositoryField"
          />
        </ValidationProvider>

        <!-- ... -->
      </div>

      <FormFieldLabel
        label="Parent Field (Filter based on parent field value selection)"
        is-mandatory
        class="field-label q-pt-md"
      />

      <div class="">
        <SelectField
          is-mandatory
          placeholder="Parent Field"
          :value="repositoryFieldParent"
          :is-clearable="true"
          :options="panelFields()"
          @input="updateRepositoryFieldParent"
        />
      </div>
    </div>

    <!-- predefined table -->

    <div v-if="optionsType === 'PREDEFINED'" class="q-pa-md">
      <FormFieldLabel
        label="predefined table"
        is-mandatory
        class="field-label"
      />
      <div class="row q-col-gutter-sm">
        <!-- predefined table -->

        <ValidationProvider
          v-slot="{ errors }"
          name="table"
          :rules="{ required: true }"
          class="col"
        >
          <SelectField
            is-mandatory
            placeholder="Table"
            :error="errors[0]"
            :value="predefinedTable"
            :is-clearable="false"
            :options="predefinedTables"
            @input="updatePredefinedTable"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- column -->

        <ValidationProvider
          v-slot="{ errors }"
          name="column"
          :rules="{ required: true }"
          class="col"
        >
          <SelectField
            is-mandatory
            placeholder="Column"
            :error="errors[0]"
            :is-clearable="false"
            :value="predefinedTableColumn"
            :options="filterPredefinedTableColumns"
            @input="updatePredefinedTableColumn"
          />
        </ValidationProvider>

        <!-- ... -->
      </div>
    </div>

    <!-- ... -->

    <!-- specific currency -->
    <div v-if="optionsType === 'SPECIFIC_CURRENCY'" class="q-pa-md">
      <MultiSelectField
        label="Choose the currency"
        is-mandatory
        placeholder="Choose Currency"
        :value="specificCurrencyOptions"
        :is-clearable="true"
        :options="currencyList"
        @input="updateSpecificCurrencyOptions"
      />
    </div>
    <!-- ... -->

    <!-- custom options-->

    <ValidationProvider
      v-if="optionsType === 'CUSTOM'"
      v-slot="{ errors }"
      name="options"
      :rules="{ required: true }"
      class="col"
    >
      <TextAreaField
        :value="customOptions"
        is-mandatory
        label="options"
        placeholder="Enter your options here or copy and paste"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomOptions"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- separate options using -->

    <SingleChoiceField
      v-if="optionsType === 'CUSTOM'"
      label="separate options using"
      :value="separateOptionsUsing"
      :options-per-line="3"
      :options="separateOptions"
      class="q-pa-md"
      @input="updateSeparateOptionsUsing"
    />

    <!-- ... -->

    <!-- new option -->

    <SingleChoiceField
      v-if="showOptionsType"
      label="allow users to add new options"
      :value="allowToAddNewOptions"
      :options-per-line="3"
      :options="binaryOptions"
      class="q-pa-md"
      @input="updateAllowToAddNewOptions"
    />

    <!-- ... -->

    <!-- options per line -->

    <SingleChoiceField
      v-if="showOptionsPerLine"
      label="options per line"
      :value="optionsPerLine"
      :options-per-line="0"
      :options="optionsPerLineOptions"
      class="q-pa-md"
      @input="updateOptionsPerLine"
    />

    <!-- ... -->

    <!-- default value -->
    <SingleChoiceField
      v-if="showDefaultValue"
      :value="defaultValueInSelectField"
      label="fill value"
      :options="defaultValueOptions"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateDefaultValueInSelectField"
    />
    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import { form, repository, common } from "@/api/factory.js";

export default {
  name: "OptionsBuilder",

  components: {
    ValidationProvider,
    SingleChoiceField,
    SelectField,
    MultiSelectField,
    FormFieldLabel,
    TextAreaField,
  },

  props: {
    fieldType: {
      type: String,
      required: true,
    },

    optionsType: {
      type: String,
      required: true,
    },

    masterFormId: {
      type: Number,
      required: true,
    },

    masterFormColumn: {
      type: String,
      required: true,
    },

    masterFormParentColumn: {
      type: String,
      required: true,
    },

    repositoryId: {
      type: Number,
      required: true,
    },

    repositoryField: {
      type: String,
      required: true,
    },

    repositoryFieldParent: {
      type: String,
      required: true,
    },

    predefinedTable: {
      type: String,
      required: true,
    },

    predefinedTableColumn: {
      type: String,
      required: true,
    },

    customOptions: {
      type: String,
      required: true,
    },

    separateOptionsUsing: {
      type: String,
      required: true,
    },

    allowToAddNewOptions: {
      type: Boolean,
      required: true,
    },

    optionsPerLine: {
      type: Number,
      required: true,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    settingsFor: {
      type: String,
      default: "",
    },

    fieldId: {
      type: String,
      default: "",
    },

    defaultValueInSelectField: {
      type: String,
      default: "NONE",
    },

    isSameMaster: {
      type: Boolean,
      default: true,
    },

    masterFormConditionColumn: {
      type: Array,
      default: () => [],
    },

    specificCurrencyOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      optionsTypes: [
        {
          id: this.$nano.id(),
          label: "Use unique column values as options",
          value: "EXISTING",
          for: ["SINGLE_SELECT", "MULTI_SELECT"],
        },
        {
          id: this.$nano.id(),
          label: "Use values from a master table as options",
          value: "MASTER",
          for: [
            "SINGLE_SELECT",
            "MULTI_SELECT",
            "SINGLE_CHOICE",
            "MULTIPLE_CHOICE",
          ],
        },
        {
          id: this.$nano.id(),
          label: "Load all currency code",
          value: "ALL_CURRENCY",
          for: ["CURRENCY_AMOUNT"],
        },
        {
          id: this.$nano.id(),
          label: "Load specific currency code",
          value: "SPECIFIC_CURRENCY",
          for: ["CURRENCY_AMOUNT"],
        },
        {
          id: this.$nano.id(),
          label: "Use values from a folder data as options",
          value: "REPOSITORY",
          for: ["SINGLE_SELECT", "MULTI_SELECT"],
        },
        {
          id: this.$nano.id(),
          label: "Use values from a predefined table as options",
          value: "PREDEFINED",
          for: ["SINGLE_SELECT", "MULTI_SELECT"],
        },
        {
          id: this.$nano.id(),
          label: "Use custom options",
          value: "CUSTOM",
          for: [
            "SINGLE_SELECT",
            "MULTI_SELECT",
            "SINGLE_CHOICE",
            "MULTIPLE_CHOICE",
          ],
        },
      ],
      binaryOptions: [
        {
          id: this.$nano.id(),
          label: "Yes",
          value: true,
        },
        {
          id: this.$nano.id(),
          label: "No",
          value: false,
        },
      ],
      separateOptions: [
        {
          id: this.$nano.id(),
          label: "Newline",
          value: "NEWLINE",
        },
        {
          id: this.$nano.id(),
          label: "Comma",
          value: "COMMA",
        },
      ],
      masterForms: [],
      masterFormColumns: [],
      repositoryList: [],
      repositoryFields: [],
      predefinedTables: [
        {
          id: this.$nano.id(),
          label: "Workspace",
          child: "Repository",
          value: "Workspace",
        },
        {
          id: this.$nano.id(),
          label: "Folder",
          child: "",
          value: "Repository",
        },
        {
          id: this.$nano.id(),
          label: "User",
          child: "",
          value: "User",
        },
        {
          id: this.$nano.id(),
          label: "Workflow",
          child: "",
          value: "Workflow",
        },
      ],
      predefinedTableColumns: [
        {
          id: this.$nano.id(),
          label: "Workspace Name",
          table: "Workspace",
          value: "name",
        },
        {
          id: this.$nano.id(),
          label: "Folder Name",
          table: "Repository",
          value: "name",
        },
        {
          id: this.$nano.id(),
          label: "First Name",
          table: "User",
          value: "firstName",
        },
        {
          id: this.$nano.id(),
          label: "Email",
          table: "User",
          value: "email",
        },
        {
          id: this.$nano.id(),
          label: "Username",
          table: "User",
          value: "userName",
        },
        {
          id: this.$nano.id(),
          label: "Phone Number",
          table: "User",
          value: "phoneNo",
        },
        {
          id: this.$nano.id(),
          label: "Department",
          table: "User",
          value: "department",
        },
        {
          id: this.$nano.id(),
          label: "Job Title",
          table: "User",
          value: "jobTitle",
        },
        {
          id: this.$nano.id(),
          label: "Workflow Name",
          table: "Workflow",
          value: "name",
        },
      ],
      optionsPerLineOptions: [
        {
          id: this.$nano.id(),
          label: "Auto",
          value: 0,
        },
        {
          id: this.$nano.id(),
          label: 1,
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: 2,
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: 3,
          value: 3,
        },
        {
          id: this.$nano.id(),
          label: 4,
          value: 4,
        },
        {
          id: this.$nano.id(),
          label: 6,
          value: 6,
        },
      ],
      defaultValueOptions: [
        {
          id: this.$nano.id(),
          label: "Logged in user's email",
          value: "USER_EMAIL",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Logged in user's first name",
          value: "USER_NAME",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Logged in user's name",
          value: "LOGIN_NAME",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "None",
          value: "NONE",
          for: ["SHORT_TEXT"],
        },
      ],
      currencyList: [],
    };
  },

  computed: {
    showOptionsType() {
      return [
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
      ].includes(this.fieldType);
    },

    showOptionsPerLine() {
      return ["SINGLE_CHOICE", "MULTIPLE_CHOICE"].includes(this.fieldType);
    },

    filterPredefinedTableColumns() {
      return this.predefinedTableColumns.filter(
        (col) => col.table === this.predefinedTable
      );
    },

    _optionsTypes() {
      return this.optionsTypes.filter((option) =>
        option.for.includes(this.fieldType)
      );
    },

    showDefaultValue() {
      if (this.fieldType !== "CURRENCY_AMOUNT") {
        if (
          this.optionsType === "MASTER" ||
          this.optionsType === "REPOSITORY" ||
          this.optionsType === "PREDEFINED"
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
  },

  created() {
    this.getMasterForms();
    this.getRepository();

    if (!this.masterFormConditionColumn.length) {
      this.addRowInConditionColumn();
    }

    if (this.fieldType === "CURRENCY_AMOUNT") {
      this.getCurrencyList();
    }
  },

  methods: {
    updateOptionsType(optionsType) {
      this.updateMasterFormId(0);
      this.updateMasterFormColumn("");
      this.updateMasterFormParentColumn("");
      this.updateRepositoryId(0);
      this.updateRepositoryField("");
      this.updateRepositoryFieldParent("");
      this.updatePredefinedTable("");
      this.updatePredefinedTableColumn("");
      this.updateCustomOptions("");
      this.$parent.$emit("update:optionsType", optionsType);
    },

    updateMasterFormId(masterFormId) {
      this.$parent.$emit("update:masterFormId", masterFormId);
      if (masterFormId) {
        this.getFormFields(masterFormId);
      }
    },

    updateMasterFormColumn(masterFormColumn) {
      this.$parent.$emit("update:masterFormColumn", masterFormColumn);
    },

    updateMasterFormParentColumn(masterFormParentColumn) {
      this.$parent.$emit(
        "update:masterFormParentColumn",
        masterFormParentColumn
      );
    },

    updateRepositoryId(repositoryId) {
      this.$parent.$emit("update:repositoryId", repositoryId);
      if (repositoryId) {
        this.getRepositoryFields(repositoryId);
      }
    },

    updateRepositoryField(repositoryField) {
      this.$parent.$emit("update:repositoryField", repositoryField);
    },

    updateRepositoryFieldParent(repositoryFieldParent) {
      this.$parent.$emit("update:repositoryFieldParent", repositoryFieldParent);
    },

    updatePredefinedTable(predefinedTable) {
      this.$parent.$emit("update:predefinedTable", predefinedTable);
    },

    updatePredefinedTableColumn(predefinedTableColumn) {
      this.$parent.$emit("update:predefinedTableColumn", predefinedTableColumn);
    },

    updateCustomOptions(options) {
      this.$parent.$emit("update:customOptions", options);
    },

    updateSeparateOptionsUsing(option) {
      this.$parent.$emit("update:separateOptionsUsing", option);
    },

    updateAllowToAddNewOptions(allowToAddNewOptions) {
      this.$parent.$emit("update:allowToAddNewOptions", allowToAddNewOptions);
    },

    updateOptionsPerLine(option) {
      this.$parent.$emit("update:optionsPerLine", option);
    },

    panelFields() {
      if (!this.panels) return [];
      let fieldList = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }
      let parentFields = [];
      fieldList.forEach((field) => {
        if (this.settingsFor === "NORMAL") {
          if (field.id !== this.fieldId) {
            if (
              field.type === "SINGLE_SELECT" ||
              field.type === "MULTI_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE"
            ) {
              if (field.settings.specific.masterFormId) {
                if (this.isSameMaster) {
                  if (
                    field.settings.specific.masterFormId === this.masterFormId
                  ) {
                    parentFields.push({
                      id: this.$nano.id(),
                      label: field.label,
                      value: field.id,
                    });
                  }
                } else {
                  if (field.settings.specific.masterFormId) {
                    parentFields.push({
                      id: this.$nano.id(),
                      label: field.label,
                      value: field.id,
                    });
                  }
                }
              } else if (field.settings.specific.repositoryId) {
                if (
                  field.settings.specific.repositoryId === this.repositoryId
                ) {
                  parentFields.push({
                    id: this.$nano.id(),
                    label: field.label,
                    value: field.id,
                  });
                }
              }
            }
          }
        } else if (this.settingsFor === "TABLE") {
          if (field.type === "TABLE") {
            field.settings.specific.tableColumns.forEach((tblField) => {
              // console.log(tblField, this.fieldId);
              if (tblField.id !== this.fieldId) {
                if (
                  tblField.type === "SINGLE_SELECT" ||
                  tblField.type === "MULTI_SELECT" ||
                  tblField.type === "SINGLE_CHOICE" ||
                  tblField.type === "MULTIPLE_CHOICE"
                ) {
                  if (
                    tblField.settings.specific.masterFormId ===
                    this.masterFormId
                  ) {
                    parentFields.push({
                      id: this.$nano.id(),
                      label: tblField.label,
                      value: tblField.id,
                    });
                  }
                }
              }
            });
          }

          if (field.id !== this.fieldId) {
            if (
              field.type === "SINGLE_SELECT" ||
              field.type === "MULTI_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE"
            ) {
              if (field.settings.specific.masterFormId) {
                if (
                  field.settings.specific.masterFormId === this.masterFormId
                ) {
                  parentFields.push({
                    id: this.$nano.id(),
                    label: field.label,
                    value: field.id,
                  });
                }
              }
            }
          }
        }
      });
      return parentFields;
    },

    async getRepositoryFields(repositoryId) {
      this.repositoryFields = [];
      let fields = await this.getRepositoryById(repositoryId);
      if (fields) {
        fields.forEach((field) => {
          this.repositoryFields.push({
            id: this.$nano.id(),
            label: field.name,
            value: field.name,
          });
        });
      }
    },

    async getRepositoryById(repositoryId) {
      const { error, payload } = await repository.getRepository(repositoryId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        return payload.fields;
      }
    },

    async getMasterForms() {
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "MASTER",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching master form list");
        return;
      }

      this.masterForms = [];
      const { data } = payload;
      if (data.length) {
        this.masterForms = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: form.id,
        }));
      }

      if (this.masterFormId) {
        this.getFormFields(this.masterFormId);
      }
    },

    async getFormFields(formId) {
      this.masterFormColumns = [];
      const { error, payload } = await form.getForm(formId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let form = JSON.parse(payload.formJson);
        let masterfields = [];
        const panels = [...form.panels, ...form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          masterfields.push(...panel.fields);
        }

        masterfields.forEach((field) => {
          if (field.type !== "DIVIDER") {
            this.masterFormColumns.push({
              id: field.id,
              label: field.label,
              value: field.id,
            });
          }
        });
      }
    },

    async getRepository() {
      const { error, payload } = await repository.getRepositories({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: [
          {
            id: this.$nano.id(),
            filters: [
              {
                criteria: "fieldsType",
                condition: "IS_EQUALS_TO",
                value: "STATIC",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error("Error fetching repository list");
        return;
      }
      if (payload) {
        if (payload.data.length) {
          if (payload.data[0].value.length) {
            this.repositoryList = payload.data[0].value.map((row) => ({
              id: this.$nano.id(),
              label: row.name,
              value: row.id,
              fields: row.fields,
            }));
          }
        }
      }
      if (this.repositoryId) {
        this.getRepositoryFields(this.repositoryId);
      }
    },

    updateDefaultValueInSelectField(value) {
      this.$parent.$emit("update:defaultValueInSelectField", value);
    },

    updateSameMaster(value) {
      this.$parent.$emit("update:isSameMaster", value);
    },

    updateMasterFormConditionColumn() {
      this.$parent.$emit(
        "update:masterFormConditionColumn",
        this.masterFormConditionColumn
      );
    },

    addRowInConditionColumn() {
      const model = {
        id: this.$nano.id(),
        formColumn: "",
        masterColumn: "",
      };

      this.masterFormConditionColumn.push(model);
    },

    removeRowInConditionColumn(index) {
      this.masterFormConditionColumn.splice(index, 1);
      this.$parent.$emit(
        "update:masterFormConditionColumn",
        this.masterFormConditionColumn
      );
    },

    updateSpecificCurrencyOptions(value) {
      this.$parent.$emit("update:specificCurrencyOptions", value);
    },

    async getCurrencyList() {
      this.currencyList = [];
      let input = {
        sortBy: {
          criteria: "country",
          order: "ASC",
        },
        groupBy: "",
        filterBy: [],
        currentPage: 0,
        itemsPerPage: 0,
        mode: "BROWSE",
      };
      const { error, payload } = await common.getCurrencyList(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.meta.totalItems) {
          if (payload.data.length) {
            if (payload.data[0].value.length) {
              payload.data[0].value.forEach((row) => {
                this.currencyList.push({
                  id: this.$nano.id(),
                  label: `${row.country} (${row.code})`,
                  value: row.code,
                  image: row.flag,
                });
              });
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#options-builder {
  .field-label {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 10px;
  }

  #condition-column {
    table {
      table-layout: fixed;
      width: 100%;
      border-collapse: collapse;

      tr {
        height: 48px;
      }

      th:not(.action) {
        padding: 4px 8px;
        text-align: left;
        font-weight: 500;
        text-transform: capitalize;

        &.small {
          width: 80px;
        }

        &.medium {
          width: 150px;
        }

        &.large {
          width: 210px;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        &.action {
          width: 36px;
        }
      }

      td {
        padding: 4px;
        vertical-align: top;
      }
    }
  }
}
</style>
