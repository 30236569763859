<template>
  <div id="form-builder-wrapper">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title> Form {{ _tab }} </template>

      <template #description>
        {{ _description }}
      </template>

      <template #header>
        <div class="row items-center justify-center">
          <Tabs v-model="tab" :tabs="tabs" is-full-width class="tabs" />
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          icon="eva-question-mark"
          color="secondary"
          class="q-mr-sm"
          @click="showQuickHelp"
        />
      </template>

      <!-- ... -->

      <!-- content -->

      <FormBuilder
        v-if="tab === 'BUILDER'"
        :form="form"
        :is-loading="isLoading"
        :lookup-settings="lookupSettings"
        :form-id="formId"
        @update-panels="updatePanels"
        @update-secondary-panels="updateSecondaryPanels"
        @settings="tab = 'SETTINGS'"
        @save="saveForm"
      />

      <FormSettings
        v-if="tab === 'SETTINGS'"
        :form="form"
        @update="updateSettings"
      />

      <FormPublish
        v-if="tab === 'PUBLISH'"
        :form-id="formId"
        :form-settings="form.settings"
        @update="updateSettings"
      />

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import FormBuilder from "./form-builder/FormBuilder.vue";
import FormSettings from "./form-settings/FormSettings.vue";
import FormPublish from "./form-publish/FormPublish.vue";
import { capitalize, lowerCase } from "lodash-es";
import { form, connector } from "@/api/factory.js";
import taskForm from "@/helpers/task-form";

export default {
  name: "FormBuilderWrapper",

  components: {
    ItemBuilder,
    Tabs,
    QuickHelp,
    FormBuilder,
    FormSettings,
    FormPublish,
  },

  mixins: [taskForm],

  props: {
    formId: {
      type: String,
      default: "",
    },

    lookupSettings: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      quickHelp: false,
      isLoading: false,
      tab: "BUILDER",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Builder",
          value: "BUILDER",
          description: "Create custom forms and collect data effortlessly",
        },
        {
          id: this.$nano.id(),
          label: "Settings",
          value: "SETTINGS",
          description: "Customize and manage your form settings",
        },
        {
          id: this.$nano.id(),
          label: "Publish",
          value: "PUBLISH",
          description: "Publish and deploy your form in action",
        },
      ],
      form: {
        uid: this.$nano.id(),
        panels: [],
        secondaryPanels: [],
        settings: {
          general: {
            name: "Form - " + this.$day.newDateTime(),
            description: "",
            layout: "CLASSIC",
            type: "WORKFLOW",
          },
          rules: [],
          publish: {
            publishOption: "DRAFT",
            publishSchedule: "",
            unpublishSchedule: "",
          },
          hubLinkIds: [],
        },
        isDeleted: false,
      },
      hubLinkDetails: [],
    };
  },

  computed: {
    _tab() {
      return capitalize(lowerCase(this.tab));
    },

    _description() {
      let currentTab = this.tabs.filter((item) => item.value === this.tab);
      return currentTab[0].description;
    },
  },

  watch: {
    formId: {
      immediate: true,
      handler() {
        if (this.formId) {
          this.mode = "EDIT";
          this.getForm();
        }
      },
    },
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    closeBuilder() {
      this.$router.back();
    },

    updatePanels(panels) {
      this.form.panels = panels;
    },

    updateSecondaryPanels(panels) {
      this.form.secondaryPanels = panels;
    },

    updateSettings(settings) {
      this.form.settings = settings;
      this.tab = "BUILDER";

      if (this.form.settings.general.type === "TASK") {
        // console.log(this.taskForm.panels);
        if (this.form.panels.length == 0) {
          this.form.panels = this.taskForm.panels;
        } else if (this.form.panels[0].fields.length == 0) {
          this.form.panels[0].fields = this.taskForm.panels[0].fields;
        } else {
          if (this.form.panels[0].fields.length) {
            // this.taskForm.panels[0].fields.forEach((item) => {
            //   this.form.panels[0].fields.push(item);
            // });
            this.form.panels[0].fields = this.taskForm.panels[0].fields;
          }
        }
      } else if (this.form.settings.general.type === "SLA") {
        // console.log(this.taskForm.panels);
        if (this.form.panels.length == 0) {
          this.form.panels = this.slaForm.panels;
        } else if (this.form.panels[0].fields.length == 0) {
          this.form.panels[0].fields = this.slaForm.panels[0].fields;
        } else {
          if (this.form.panels[0].fields.length) {
            // this.slaForm.panels[0].fields.forEach((item) => {
            //   this.form.panels[0].fields.push(item);
            // });
            this.form.panels[0].fields = this.slaForm.panels[0].fields;
          }
        }
      } else if (this.form.settings.general.type === "Feedback Form") {
        // console.log(this.taskForm.panels);
        if (this.form.panels.length == 0) {
          this.form.panels = this.feedbackForm.panels;
        } else if (this.form.panels[0].fields.length == 0) {
          this.form.panels[0].fields = this.feedbackForm.panels[0].fields;
        } else {
          if (this.form.panels[0].fields.length) {
            // this.feedbackForm.panels[0].fields.forEach((item) => {
            //   this.form.panels[0].fields.push(item);
            // });
            this.form.panels[0].fields = this.feedbackForm.panels[0].fields;
          }
        }
      } else {
        // this.form.panels = [];
      }
    },

    async saveForm() {
      let fieldsCount = 0;
      this.form.panels.forEach((panel) => (fieldsCount += panel.fields.length));

      if (!fieldsCount) {
        this.$alert.warning("Please add fields to continue");
        return;
      }

      this.form.panels.forEach((panel) => {
        panel.fields.forEach((field) => {
          delete field.filterBy;
          delete field.filter;
          delete field.lookupFilterBy;
          delete field.lookupFilter;
          if (
            (origin === "https://trial.ezofis.com" &&
              this.$store.state.session.tenantId === 13) ||
            (origin === "https://app.ezofis.com" &&
              this.$store.state.session.tenantId === 3)
          ) {
            if (field.type === "TABLE") {
              field.settings.specific.tableColumns.forEach((tableField) => {
                if (
                  tableField.label === "Start Downtime" ||
                  tableField.label === "End Downtime"
                ) {
                  tableField.settings.validation.timeRange = "NONE";
                  tableField.settings.validation.minimum = "";
                  tableField.settings.validation.maximum = "";
                }
              });
            } else {
              if (
                field.label === "Start Production Time" ||
                field.label === "End Production Time" ||
                field.label === "Start Filling Time" ||
                field.label === "Stop Filling Time"
              ) {
                field.settings.validation.timeRange = "NONE";
                field.settings.validation.minimum = "";
                field.settings.validation.maximum = "";
              }
            }
          }
        });
      });

      if (this.lookupSettings.length && this.formId) {
        this.hubLinkDetails = [];
        this.lookupSettings.forEach((lookup, index) => {
          if (
            this.form.settings.hubLinkIds &&
            this.form.settings.hubLinkIds.length
          ) {
            let hub = this.form.settings.hubLinkIds.find(
              (hublink) => hublink.jsonId === lookup.id
            );
            if (hub) {
              this.hubLinkDetails.push({
                field: lookup,
                hubId: hub.hubLinkId,
              });
              this.lookupSettings.splice(index, 1);
            }
          }
        });
        if (this.lookupSettings.length && this.formId) {
          await this.addLookupSettings(this.formId, "edit");
        }

        if (this.hubLinkDetails.length && this.formId) {
          await this.updateLookupSettings(this.formId);
        }
      }

      this.formId ? this.updateForm() : this.createForm();

      // console.log(this.lookupSettings);
    },

    /* api functions */

    async createForm() {
      this.isLoading = true;
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.createForm(this.form);

      if (error) {
        if (error !== "error creating form") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        this.$store.commit("hideLoadingBar");
        this.isLoading = false;
        return;
      }

      if (this.lookupSettings.length && payload) {
        await this.addLookupSettings(payload);
      } else {
        this.$alert.success("New form created");
        this.closeBuilder();
      }
    },

    async getForm() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getForm(this.formId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.form = JSON.parse(payload.formJson);

      if (!this.form.settings.hubLinkIds) {
        this.form.settings.hubLinkIds = [];
      }
    },

    async updateForm(formId) {
      this.isLoading = true;

      this.$store.commit("showLoadingBar");
      // console.log(this.form);
      const { error } = await form.updateForm(this.formId || formId, this.form);

      this.$store.commit("hideLoadingBar");

      if (error) {
        if (error !== "error updating form") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        this.isLoading = false;

        return;
      }
      if (formId) {
        this.$alert.success("New form created");
      } else {
        this.$alert.success("Form saved successfully");
      }
      this.closeBuilder();
    },

    async addLookupSettings(id, edit) {
      let hubLinkIds = [];

      for (let setting of this.lookupSettings) {
        // console.log(id, setting);
        let payloadMapping = {};
        let responseMapping = {};

        let columnName = setting.settings.lookupSettings.columnName;
        let hubName = setting.settings.lookupSettings.hubName;

        let parentField = setting.settings.lookupSettings.parentField;

        if (columnName) {
          this.$set(payloadMapping, `${setting.id}`, `${columnName}`);

          this.$set(responseMapping, `${setting.id}`, `${columnName}`);
        }
        if (parentField) {
          // this.$set(responseMapping, `${columnName}`, `${parentField}`);
        }

        const input = {
          name: this.form.settings.general.name,
          hubId: hubName,
          repositoryId: 0,
          formId: Number(id),
          payloadMapping: JSON.stringify(payloadMapping),
          responseMapping: JSON.stringify(responseMapping),
        };

        const { error, payload } = await connector.addHubLink(input);
        if (error) {
          this.$alert.error(error);
          return;
        }
        console.log(payload, "payload");
        if (payload) {
          let output = JSON.parse(payload);
          hubLinkIds.push({
            jsonId: setting.id,
            hubLinkId: output.id,
          });
        }
      }

      if (this.lookupSettings.length === hubLinkIds.length) {
        this.form.settings.hubLinkIds.push(...hubLinkIds);
        if (!edit) {
          this.updateForm(id);
        }
      }
    },

    async updateLookupSettings(id) {
      for (let setting of this.hubLinkDetails) {
        // console.log(id, setting);
        let payloadMapping = {};
        let responseMapping = {};

        let columnName = setting.field.settings.lookupSettings.columnName;
        let hubName = setting.field.settings.lookupSettings.hubName;

        let parentField = setting.field.settings.lookupSettings.parentField;

        if (columnName) {
          this.$set(payloadMapping, `${setting.field.id}`, `${columnName}`);

          this.$set(responseMapping, `${setting.field.id}`, `${columnName}`);
        }
        if (parentField) {
          // this.$set(responseMapping, `${columnName}`, `${parentField}`);
        }

        const input = {
          name: this.form.settings.general.name,
          hubId: hubName,
          repositoryId: 0,
          formId: Number(id),
          payloadMapping: JSON.stringify(payloadMapping),
          responseMapping: JSON.stringify(responseMapping),
        };

        const { error, payload } = await connector.updateHubLink(
          setting.hubId,
          input
        );
        if (error) {
          this.$alert.error(error);
          return;
        }
        console.log(payload, "payload");
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#form-builder-wrapper {
  height: 100vh;

  .tabs {
    width: 240px;
    border-bottom: 1px solid var(--divider-color);
  }
}
</style>
