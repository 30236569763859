<template>
  <Sheet
    :value="value"
    has-footer
    no-padding
    width="820px"
    @input="closeSettings"
  >
    <!-- title -->

    <template #title>Block Settings - {{ block?.type }}</template>

    <!-- ... -->

    <!-- form -->

    <template #default>
      <div id="tab-settings" class="row">
        <div class="content">
          <BaseScrollbar height="calc(100vh - 120px)">
            <ValidationObserver ref="form">
              <!-- general settings -->
              <template v-if="activeTab === 'GENERAL'">
                <!-- label -->

                <TextField
                  v-model="label"
                  is-mandatory
                  label="label"
                  class="field"
                />

                <!-- ... -->

                <template v-if="blockType === 'START'">
                  <!-- initiate mode -->

                  <InitiateMode
                    :initiate-mode.sync="initiateMode"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- initiate by -->

                  <ValidationProvider
                    v-slot="{ errors }"
                    name="initiate by"
                    :rules="{ required: true }"
                  >
                    <InitiateBy
                      v-model="initiateBy"
                      :initiate-mode="initiateMode"
                      :error="errors[0]"
                      :users.sync="users"
                      :user-list="userListWithAny"
                      :user-domains.sync="userDomains"
                      :user-domain-list="userDomainList"
                      :groups.sync="groups"
                      :group-list="groupList"
                      :mail-settings-list="mailSettingsList"
                      :mail-domain-list="mailDomainList"
                      :user-email-list="userEmailList"
                      :mail-trigger-conditions.sync="mailTriggerConditions"
                      :has-mail-subject.sync="hasMailSubject"
                      :has-mail-content.sync="hasMailContent"
                      :mail-initiate.sync="mailInitiate"
                      :mail-initiate-new="mailInitiate"
                      :master-form-list="masterFormList"
                      :master-form-id.sync="masterFormId"
                      class="field"
                    />
                  </ValidationProvider>

                  <!-- ... -->

                  <!-- users -->
                  <template v-if="false">
                    <Users
                      v-if="initiateBy.includes('USER') && false"
                      :users.sync="users"
                      :user-list="userListWithAny"
                      class="field"
                    />

                    <!-- ... -->

                    <!-- domain users -->
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="domain"
                      :rules="{ domain: true }"
                    >
                      <MultiSelectField
                        v-if="initiateBy.includes('DOMAIN_USER')"
                        v-model="userDomains"
                        label="Domain Name"
                        is-mandatory
                        :options="userDomainList"
                        new-option
                        :is-clearable="true"
                        class="field"
                        placeholder="example.com"
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <!-- ... -->

                    <!-- groups -->

                    <Groups
                      v-if="initiateBy.includes('GROUP')"
                      :groups.sync="groups"
                      :group-list="groupList"
                      class="field"
                    />

                    <!-- ... -->

                    <!-- EmailInitiator -->

                    <EmailInitiator
                      v-if="
                        initiateBy.includes('EMAIL') &&
                        initiateMode === 'AUTOMATIC'
                      "
                      v-model="mailInitiate"
                      :mail-settings-list="mailSettingsList"
                      :mail-domain-list="mailDomainList"
                      :user-email-list="userEmailList"
                      :mail-trigger-conditions.sync="mailTriggerConditions"
                      :has-mail-subject.sync="hasMailSubject"
                      :has-mail-content.sync="hasMailContent"
                      class="field"
                    />

                    <!-- ... -->

                    <!-- Master Form -->

                    <SelectField
                      v-if="initiateBy.includes('MASTER_FORM')"
                      v-model="masterFormId"
                      is-mandatory
                      label="Master Form"
                      :options="masterFormList"
                      :is-clearable="true"
                      class="field"
                    />
                    <!-- ... -->
                  </template>
                  <!-- Integration Tool -->

                  <SelectField
                    v-if="initiateBy.includes('EXTERNAL_SERVICE')"
                    v-model="externalService"
                    label="Integrated Tool"
                    :options="[]"
                    :is-clearable="true"
                    class="field"
                  />
                  <!-- ... -->
                </template>

                <template v-if="blockType === 'INTERNAL_ACTOR'">
                  <!-- action by -->

                  <!-- <ValidationProvider
            v-slot="{ errors }"
            name="action by"
            :rules="{ required: true }"
          >
            <ActionBy v-model="actionBy" :error="errors[0]" class="field" />
          </ValidationProvider> -->

                  <ActionBy
                    v-model="actionBy"
                    :users.sync="users"
                    :user-list="userList"
                    :groups.sync="groups"
                    :group-list="groupList"
                    class="field"
                  />

                  <!-- ... -->
                  <template v-if="false">
                    <!-- users -->

                    <Users
                      v-if="actionBy.includes('USER')"
                      :users.sync="users"
                      :user-list="userList"
                      class="field"
                    />

                    <!-- ... -->

                    <!-- groups -->

                    <Groups
                      v-if="actionBy.includes('GROUP')"
                      :groups.sync="groups"
                      :group-list="groupList"
                      class="field"
                    />

                    <!-- ... -->
                  </template>
                  <!-- manager -->

                  <CheckboxField
                    v-model="manager"
                    label="Manager"
                    description="An internal user with manager’s profile"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- to requester -->

                  <CheckboxField
                    v-model="toRequester"
                    label="To Requester"
                    description="The user who starts this workflow"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- User from Master Table -->
                  <CheckboxField
                    v-if="conditionMasterFormId"
                    v-model="hasMasterUser"
                    label="User from Master Table"
                    description="Select user from master table"
                    class="field"
                  />
                  <template v-if="hasMasterUser">
                    <SelectField
                      v-model="conditionMasterFormId"
                      label="Master Form"
                      :is-readonly="conditionMasterFormId ? true : false"
                      :options="masterFormList"
                      :is-clearable="true"
                      class="field"
                    />

                    <MultiSelectField
                      v-model="masterUser"
                      label="Master Column [Should be User's Email]"
                      :options="masterColumns"
                      :is-clearable="true"
                      class="field"
                    />
                  </template>
                  <!-- ... -->

                  <!-- dynamic user -->

                  <MultiSelectField
                    v-model="dynamicUser"
                    label="Dynamic User [Form Input]"
                    :options="formColumns"
                    :is-clearable="true"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- acted activity -->

                  <SelectField
                    v-model="actedActivity"
                    label="Acted Activity"
                    :options="activityList"
                    :is-clearable="true"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- coordinator -->

                  <MultiSelectField
                    v-model="coordinator"
                    label="Coordinator"
                    :options="userList"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- internal forward -->

                  <CheckboxField
                    v-model="internalForward"
                    label="Internal Forward"
                    description="Forward to an internal user"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- internal forward user -->

                  <TextField
                    v-if="internalForward"
                    v-model="forwardLabel"
                    label="Forwarded User Stage Name"
                    class="field"
                  />

                  <!-- partial approve -->

                  <SingleChoiceField
                    v-if="internalForward"
                    v-model="forwardedUserAction"
                    label="Forwarded User Action"
                    :options-per-line="1"
                    :options="forwardedUserActions"
                    class="field"
                  />

                  <!-- ... -->

                  <MultiSelectField
                    v-if="internalForward"
                    v-model="internalForwardUser"
                    label="Forward Users"
                    :options="userList"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- internal forward group -->

                  <MultiSelectField
                    v-if="internalForward"
                    v-model="internalForwardGroup"
                    label="Forward Groups"
                    :options="groupList"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- partial approve -->

                  <SingleChoiceField
                    v-model="partialApprove"
                    label="Multiple Actor's Approval"
                    :options-per-line="1"
                    :options="multiApproverOption"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- approval action -->

                  <SelectField
                    v-if="!partialApprove"
                    v-model="fullApprovalAction"
                    is-mandatory
                    label="If all actor's not approved by the same action, then proceed with"
                    :options="proceedActionList"
                    :is-clearable="true"
                    class="field"
                  />

                  <!-- ... -->
                </template>

                <template
                  v-if="blockType === 'START' || blockType === 'INTERNAL_ACTOR'"
                >
                  <!-- workflow initiative-->
                  <div class="field">
                    <CheckboxField
                      v-model="workflowInitiator"
                      label="Workflow Initiator"
                      description="Connect the workflow"
                      class="q-mb-md"
                    />

                    <SelectField
                      v-if="workflowInitiator"
                      v-model="workflowName"
                      label="Workflow List"
                      :options="workflows"
                      class="q-mb-md"
                    />
                  </div>
                  <!-- .... -->
                </template>

                <template v-if="blockType === 'EXTERNAL_ACTOR'">
                  <FormFieldLabel
                    label="External User Action By"
                    class="action-header"
                    is-mandatory
                  />

                  <!-- external user form input -->

                  <MultiSelectField
                    v-model="externalUser"
                    label="External User [Form Input]"
                    :options="formColumns"
                    :is-clearable="true"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- external user -->

                  <MultiSelectField
                    v-model="externalUserMail"
                    label="External User Mail"
                    new-option
                    :options="externalUserMailList"
                    class="field"
                  />

                  <!-- ... -->
                </template>

                <!-- EmailInitiator -->
                <template v-if="blockType === 'EMAIL_WATCHER'">
                  <EmailInitiator
                    v-model="mailInitiate"
                    :mail-settings-list="mailSettingsList"
                    :mail-domain-list="mailDomainList"
                    :user-email-list="userEmailList"
                    :mail-trigger-conditions.sync="mailTriggerConditions"
                    :has-mail-subject.sync="hasMailSubject"
                    :has-mail-content.sync="hasMailContent"
                    class="field"
                  />
                </template>
                <!-- ... -->

                <!-- workflow connector -->
                <template v-if="blockType === 'WORKFLOW_CONNECTOR'">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="workflow"
                    :rules="{ required: true }"
                  >
                    <SelectField
                      v-model="workflowName"
                      label="Workflow List"
                      :options="workflows"
                      :is-mandatory="true"
                      :error="errors[0]"
                      class="field"
                    />
                  </ValidationProvider>
                </template>
                <!-- .... -->

                <template v-if="blockType !== 'WORKFLOW_CONNECTOR'">
                  <!-- document mandatory -->

                  <CheckboxField
                    v-model="documentRequired"
                    label="Document Required"
                    description="A minimum of one document is required for submission."
                    class="field"
                  />

                  <!-- ... -->
                </template>

                <template v-if="blockType !== 'WORKFLOW_CONNECTOR'">
                  <!-- pdf generate -->

                  <CheckboxField
                    v-if="blockType !== 'START'"
                    v-model="generatePDF"
                    label="Save form as PDF"
                    description="Save the form as PDF at the end of the activity"
                    class="field"
                  />

                  <MultiSelectField
                    v-if="blockType !== 'START' && generatePDF"
                    v-model="generatePDFFields"
                    label="Specify which fields are required in the PDF"
                    :options="
                      formColumns.length ? _formColumns : repositoryFields
                    "
                    :is-clearable="true"
                    class="field"
                  />

                  <CheckboxField
                    v-if="blockType !== 'START'"
                    v-model="generateCSV"
                    label="Save form as CSV"
                    description="Save the form as CSV at the end of the activity"
                    class="field"
                  />

                  <MultiSelectField
                    v-if="generateCSV"
                    v-model="generateCSVFields"
                    label="Specify which fields are required in the CSV"
                    :options="
                      formColumns.length ? _formColumns : repositoryFields
                    "
                    :is-clearable="true"
                    class="field"
                  />

                  <!-- ... -->
                </template>

                <template v-if="blockType === 'END'">
                  <!-- Master Entry -->

                  <CheckboxField
                    v-model="masterEntry"
                    label="Save in master entry"
                    description="Save the request information in master entry at the end of the process"
                    class="field"
                  />

                  <!-- ... -->

                  <!-- Master Entry -->
                  <CheckboxField
                    v-model="reopen"
                    label="Reopen Request"
                    description="Reopen a previously closed request. This allows you to address any additional issues or updates needed"
                    class="field"
                  />
                  <!-- ... -->

                  <template v-if="reopen">
                    <SelectField
                      v-model="reopenBlockId"
                      is-mandatory
                      label="Reopen Stage"
                      :options="activityList"
                      :is-clearable="true"
                      class="field"
                    />
                  </template>
                </template>

                <template
                  v-if="
                    blockType !== 'END' &&
                    blockType !== 'EMAIL_WATCHER' &&
                    blockType !== 'WORKFLOW_CONNECTOR'
                  "
                >
                  <CheckboxField
                    v-model="userSignature"
                    label="User Signature Required"
                    description="Draw/Upload E-Sign for the action"
                    class="field"
                  />
                </template>

                <template
                  v-if="blockType === 'CONDITION' || blockType === 'RULES'"
                >
                  <CheckboxField
                    v-model="MLConditions"
                    label="Add to ML"
                    description="Update this condition to the machine learning engine"
                    class="field"
                  />
                </template>

                <template v-if="blockType === 'CONDITION'">
                  <CheckboxField
                    v-model="standardCondition"
                    label="Standard"
                    description="Configure the condition based on the request entries"
                    class="field"
                  />
                </template>

                <template v-if="blockType == 'CONDITION' && standardCondition">
                  <ConditionBuilder
                    :conditions.sync="conditions"
                    :group-logic.sync="groupLogic"
                    :fields="
                      formColumns.length ? formColumns : repositoryFields
                    "
                    class="field"
                  />
                </template>

                <template v-if="blockType === 'CONDITION'">
                  <CheckboxField
                    v-model="masterCondition"
                    label="Advanced"
                    description="Configure the condition to compare with master entries"
                    class="field"
                  />
                </template>

                <template v-if="blockType == 'CONDITION' && masterCondition">
                  <SelectField
                    v-model="conditionMasterFormId"
                    is-mandatory
                    label="Master Form"
                    :options="masterFormList"
                    :is-clearable="true"
                    class="field"
                  />
                  <MasterColumnConditionBuilder
                    :conditions.sync="masterConditions"
                    :group-logic.sync="masterGroupLogic"
                    :fields="
                      formColumns.length ? formColumns : repositoryFields
                    "
                    :master-column="masterColumns"
                    class="field"
                  />
                </template>

                <template v-if="blockType == 'RULES'">
                  <CheckboxField
                    v-model="mlCompareMaster"
                    label="Compare Master Data"
                    description="Compare the data with master form entries"
                    class="field"
                    @input="mlCompareValues"
                  />
                  <template v-if="mlCompareMaster">
                    <SelectField
                      v-model="mlMasterFormId"
                      is-mandatory
                      label="Master Form"
                      :options="masterFormList"
                      :is-clearable="true"
                      class="field"
                      @input="getFormFields(mlMasterFormId)"
                    />
                    <div class="field">
                      <FormFieldLabel label="Compare Fields" is-mandatory />
                      <table>
                        <thead>
                          <tr>
                            <th>Master Fields</th>
                            <th>Workflow Form Fields</th>
                            <th class="action">
                              <BaseActionButton
                                is-flat
                                class="q-ml-xs"
                                color="secondary"
                                icon="eva-plus-outline"
                                no-border
                                @click="addRow"
                              />
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(row, index) in mlCompareFields"
                            :key="index"
                          >
                            <td>
                              <SelectField
                                v-model="row.masterField"
                                :options="masterColumns"
                                :is-clearable="true"
                              />
                            </td>
                            <td>
                              <SelectField
                                v-model="row.workflowFormField"
                                :options="workflowFormColumns"
                                :is-clearable="true"
                              />
                            </td>
                            <td class="action">
                              <BaseActionButton
                                is-flat
                                color="red"
                                icon="eva-close-outline"
                                no-border
                                @click="removeRow(index)"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <SelectField
                      v-model="mlPredictionField"
                      is-mandatory
                      label="Prediction Field"
                      :options="masterColumns"
                      :is-clearable="true"
                      class="field"
                    />
                  </template>

                  <RuleBuilder
                    :rule-conditions.sync="ruleConditions"
                    :fields="workflowFormColumns"
                    class="field"
                  />
                </template>

                <template v-if="blockType == 'DATA_COMPARE'">
                  <CheckboxField
                    v-model="masterFormCompare"
                    label="Compare Master Data"
                    description="Compare the data with master form entries"
                    class="field"
                    @input="compareValues"
                  />
                  <template v-if="masterFormCompare">
                    <SelectField
                      v-model="masterFormId"
                      is-mandatory
                      label="Master Form"
                      :options="masterFormList"
                      :is-clearable="true"
                      class="field"
                      @input="getFormFields(masterFormId)"
                    />
                    <SelectField
                      v-model="syncFieldInForm"
                      is-mandatory
                      label="Sync field in form"
                      :options="formFields"
                      :is-clearable="true"
                      class="field"
                    />
                    <SelectField
                      v-model="syncFieldInRepository"
                      is-mandatory
                      label="sync field in repository"
                      :options="repositoryFields"
                      :is-clearable="true"
                      class="field"
                    />
                  </template>
                </template>
              </template>

              <!-- ... -->

              <!-- connection settings -->
              <template v-if="blockType !== 'END'">
                <!-- connections -->
                <template v-if="activeTab === 'CONNECTION'">
                  <Connections
                    class="field"
                    :value="connections"
                    :user-reply.sync="userReply"
                    :to-initiator.sync="toInitiator"
                    :block-type="blockType"
                    @remove="removeRule"
                  />
                </template>
                <!-- ... -->
              </template>
              <!-- ... -->

              <!-- security access -->

              <template
                v-if="
                  blockType === 'START' ||
                  blockType === 'INTERNAL_ACTOR' ||
                  blockType === 'EXTERNAL_ACTOR'
                "
              >
                <template v-if="activeTab === 'SECURITYACCESS'">
                  <SecurityAccess
                    :value="security"
                    :form-columns="formColumns"
                    :users="users"
                    :user-list="userList"
                    :block-type="blockType"
                  />
                </template>
              </template>
              <!-- ... -->

              <template
                v-if="
                  blockType === 'START' ||
                  blockType === 'INTERNAL_ACTOR' ||
                  blockType === 'EXTERNAL_ACTOR'
                "
              >
                <template v-if="activeTab === 'CHECKLIST'">
                  <Checklist
                    :value="fileSettings"
                    :workflow-id="workflowId"
                    :block-id="blockId"
                    :form-fields="workflowFormColumns"
                    :user-list="userList"
                  >
                  </Checklist>
                </template>
              </template>

              <!-- notification settings -->

              <template
                v-if="
                  blockType === 'INTERNAL_ACTOR' ||
                  blockType === 'EXTERNAL_ACTOR' ||
                  blockType === 'START' ||
                  blockType === 'END'
                "
              >
                <template v-if="activeTab === 'NOTIFICATION'">
                  <Notifications
                    :value="notifications"
                    class="field"
                    :user-list="userList"
                    :group-list="groupList"
                    :workflow-id="workflowId"
                    :block-id="blockId"
                    :form-columns="workflowFormColumns"
                    :nodes="workflowNodes"
                  />
                </template>
              </template>

              <!-- ... -->

              <!-- sla settings -->

              <template
                v-if="
                  blockType === 'INTERNAL_ACTOR' ||
                  blockType === 'EXTERNAL_ACTOR'
                "
              >
                <template v-if="activeTab === 'SLA'">
                  <SLABuilder
                    :value="sla"
                    :user-list="userList"
                    :activity-list="stageList"
                    :block="block"
                    :form-id="workflow.settings.general.initiateUsing.formId"
                  />
                </template>
              </template>
              <!-- ... -->

              <!-- AI prediction settings -->

              <template
                v-if="
                  blockType === 'INTERNAL_ACTOR' ||
                  blockType === 'EXTERNAL_ACTOR'
                "
              >
                <template v-if="activeTab === 'AI_PREDICTION'">
                  <AIPrediction
                    :value="aiPrediction"
                    :form-fields="_formColumns"
                  />
                </template>
              </template>
              <!-- ... -->
            </ValidationObserver>

            <!-- Document reminder settings -->

            <template
              v-if="
                blockType === 'INTERNAL_ACTOR' ||
                blockType === 'EXTERNAL_ACTOR' ||
                blockType === 'END'
              "
            >
              <template v-if="activeTab === 'DOCUMENT_REMINDER'">
                <DocumentReminder
                  :value="documentReminder"
                  :form-fields="_formColumns"
                  :user-list="userList"
                />
              </template>
            </template>

            <!-- ... -->
          </BaseScrollbar>
        </div>

        <!-- tabs -->

        <div class="tabs">
          <BaseActionButton
            v-for="tab in tabs"
            :key="tab.id"
            v-tooltip.left="tab.label"
            :is-flat="activeTab !== tab.value"
            :icon="tab.icon"
            :color="activeTab === tab.value ? 'primary' : 'gray'"
            class="q-mb-md"
            @click="activeTab = tab.value"
          />
        </div>

        <!-- ... -->
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        label="cancel"
        is-flat
        class="q-mr-sm"
        @click="closeSettings"
      />

      <BaseButton label="save" @click="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import { cloneDeep, isEmpty } from "lodash-es";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import InitiateMode from "./components/InitiateMode.vue";
import InitiateBy from "./components/InitiateBy.vue";
import ActionBy from "./components/ActionBy.vue";
import Users from "./components/Users.vue";
import Groups from "./components/Groups.vue";
import Connections from "./components/Connections.vue";
import Checklist from "./components/Checklist.vue";
import Notifications from "./components/Notifications.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import ConditionBuilder from "@/components/common/condition-builder/ConditionBuilder.vue";
import RuleBuilder from "@/components/common/rule-builder/RuleBuilder.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import SLABuilder from "./components/SLABuilder.vue";
import SecurityAccess from "./components/SecurityAccess.vue";
import {
  user,
  group,
  form,
  repository,
  integration,
  workflow,
} from "@/api/factory.js";
import MasterColumnConditionBuilder from "./components/MasterColumnConditionBuilder";
// import { merge } from "lodash-es";
import EmailInitiator from "./components/EmailInitiator.vue";
import AIPrediction from "./components/AIPredictionSettings.vue";
import DocumentReminder from "./components/DocumentReminderSettings.vue";

export default {
  name: "BlockSettings",

  components: {
    Sheet,
    ValidationObserver,
    ValidationProvider,
    TextField,
    InitiateMode,
    InitiateBy,
    ActionBy,
    Users,
    Groups,
    Connections,
    Checklist,
    Notifications,
    CheckboxField,
    SelectField,
    MultiSelectField,
    FormFieldLabel,
    ConditionBuilder,
    RuleBuilder,
    SingleChoiceField,
    SLABuilder,
    SecurityAccess,
    MasterColumnConditionBuilder,
    EmailInitiator,
    AIPrediction,
    DocumentReminder,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    workflow: {
      type: Object,
      default: () => {},
    },

    workflowId: {
      type: String,
      default: "",
    },

    block: {
      type: Object,
      default: () => {},
    },

    blocks: {
      type: Array,
      default: () => [],
    },

    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tabs: [
        {
          id: this.$nano.id(),
          label: "general",
          value: "GENERAL",
          icon: "eva-options-2-outline",
        },
        {
          id: this.$nano.id(),
          label: "connection",
          value: "CONNECTION",
          icon: "mdi-sitemap",
        },
        {
          id: this.$nano.id(),
          label: "security-access",
          value: "SECURITYACCESS",
          icon: "mdi-shield",
        },
        {
          id: this.$nano.id(),
          label: "checklist",
          value: "CHECKLIST",
          icon: "mdi-file-plus",
        },
        {
          id: this.$nano.id(),
          label: "notification",
          value: "NOTIFICATION",
          icon: "mdi-bell",
        },
        {
          id: this.$nano.id(),
          label: "SLA",
          value: "SLA",
          icon: "mdi-timer-sand",
        },
        {
          id: this.$nano.id(),
          label: "AI Prediction",
          value: "AI_PREDICTION",
          icon: "mdi-auto-fix",
        },
        {
          id: this.$nano.id(),
          label: "Document Reminder",
          value: "DOCUMENT_REMINDER",
          icon: "mdi-file-clock",
        },
      ],
      activeTab: "GENERAL",
      blockId: "",
      blockType: "",
      label: "",
      initiateMode: "",
      initiateBy: [],
      actionBy: [],
      userListWithAny: [],
      userList: [],
      groupList: [],
      userDomainList: [],
      users: [],
      userDomains: [],
      groups: [],
      manager: false,
      toRequester: false,
      dynamicUser: [],
      formColumns: [],
      workflowFormColumns: [],
      actedActivity: "",
      activityList: [],
      stageList: [],
      externalUser: [],
      externalUserMail: [],
      externalUserMailList: [],
      coordinator: [],
      internalForward: false,
      forwardLabel: "",
      forwardedUserAction: "DEFAULT",
      internalForwardUser: [],
      internalForwardGroup: [],
      partialApprove: true,
      fullApprovalAction: "",
      security: {
        formEditAccess: "",
        formEditControls: [],
        formVisibilityAccess: true,
        formSecureControls: [],
      },
      documentRequired: false,
      generatePDF: false,
      generatePDFFields: [],
      generateCSV: false,
      generateCSVFields: [],
      masterEntry: false,
      userSignature: false,
      MLConditions: false,
      conditions: [],
      groupLogic: "",
      conditionFields: [],
      masterFormId: "",
      masterFormList: [],
      externalService: "",
      ruleConditions: [],
      copyRuleConditions: [],
      notifications: {},
      formFields: [],
      masterFormCompare: false,
      repositoryFields: [],
      syncFieldInForm: "",
      syncFieldInRepository: "",
      multiApproverOption: [
        {
          id: this.$nano.id(),
          label: "Partial Approve",
          description: "Need anyone actor's approval",
          value: true,
        },
        {
          id: this.$nano.id(),
          label: "Full Approve",
          description: "Need other actor's approval",
          value: false,
        },
      ],

      proceedActionList: [],
      sla: {
        settings: {
          slaType: "STANDARD",
          slaMasterFormId: 0,
          workflowFormSLAFieldId: "",
          emailNotify: true,
          smsNotify: false,
        },
        rules: [],
      },
      fileSettings: {
        fileCheckList: [],
        partial: false,
      },
      workflowNodes: [],
      connections: [],
      standardCondition: false,
      masterCondition: false,
      masterColumns: [],
      masterConditions: [],
      masterGroupLogic: "",
      conditionMasterFormId: 0,
      hasMasterUser: false,
      masterUser: [],
      userReply: false,
      toInitiator: false,
      forwardedUserActions: [
        {
          id: this.$nano.id(),
          label: "Forward with Actions",
          description:
            "Forward tickets with all configured actions associated with this stage",
          value: "DEFAULT",
        },
        {
          id: this.$nano.id(),
          label: "Forward with Reply Only",
          description:
            "Users are restricted to only providing replies when sending a ticket back",
          value: "REPLY",
        },
      ],
      mlCompareMaster: false,
      mlMasterFormId: 0,
      mlCompareFields: [],
      mlPredictionField: "",
      mailInitiate: {
        mailSettingsId: 0,
        conditions: {
          hasAttachment: false,
          attachment: {
            filename: "",
            fileType: "",
            ocrData: "",
          },
          mailSubject: "",
          mailContent: "",
          fromAddress: [],
          fromDomain: [],
        },
      },
      mailSettingsList: [],
      mailTriggerConditions: [],
      mailDomainList: [],
      userEmailList: [],
      hasMailSubject: false,
      hasMailContent: false,
      workflows: [],
      workflowName: "",
      workflowInitiator: false,
      aiPrediction: {},
      reopen: false,
      reopenBlockId: "",
      documentReminder: {
        documentList: [],
      },
    };
  },

  computed: {
    _formColumns() {
      if (this.formColumns.length) {
        return this.formColumns.filter((row) => row.type !== "PANEL");
      }
      return [];
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.connections = [];

        if (!isEmpty(this.block) && this.value) {
          this.actionBy = [];
          this.activeTab = "GENERAL";
          this.blockId = this.block.id;
          this.blockType = this.block.type;
          this.label = this.block.settings.label;
          this.initiateMode = this.block.settings.initiateMode;
          this.initiateBy = this.block.settings.initiateBy;
          this.users = this.block.settings.users;
          this.groups = this.block.settings.groups;
          this.userDomains = this.block.settings.userDomains || [];
          this.userDomains.forEach((row) => {
            this.userDomainList.push({
              id: this.$nano.id(),
              label: row,
              value: row,
            });
          });
          if (this.block.settings.users.length) {
            this.actionBy.push("USER");
          }
          if (this.block.settings.initiateBy.indexOf("DOMAIN_USER")) {
            this.actionBy.push("DOMAIN_USER");
          }
          if (this.block.settings.groups.length) {
            this.actionBy.push("GROUP");
          }
          this.manager = this.block.settings.manager;
          this.toRequester = this.block.settings.toRequester;
          this.dynamicUser = this.block.settings.dynamicUser || [];
          this.actedActivity = this.block.settings.actedActivity;
          this.externalUser = this.block.settings.externalUser || [];
          this.externalUserMail = this.block.settings.externalUserMail || [];
          this.externalUserMailList = [];
          if (this.externalUserMail.length) {
            this.externalUserMail.forEach((mail) => {
              this.externalUserMailList.push({
                id: this.$nano.id(),
                label: mail,
                value: mail,
              });
            });
          }
          this.coordinator = this.block.settings.coordinator;
          this.internalForward = this.block.settings.internalForward;
          this.forwardLabel = this.block.settings.forwardLabel || "";
          this.forwardedUserAction =
            this.block.settings.forwardedUserAction || "DEFAULT";
          this.internalForwardUser = this.block.settings.internalForwardUser;
          this.internalForwardGroup = this.block.settings.internalForwardGroup;
          if (this.block.settings.partialApprove === undefined) {
            this.partialApprove = true;
            this.fullApprovalAction = "";
          } else {
            this.partialApprove = this.block.settings.partialApprove;
            this.fullApprovalAction = this.block.settings.fullApprovalAction;
          }
          if (this.block.settings.formEditAccess === undefined) {
            this.security.formEditAccess = "FULL";
          } else {
            this.security.formEditAccess = this.block.settings.formEditAccess;
          }
          let formEditControls = [];
          if (this.block.settings.formEditControls) {
            if (
              this.blockType === "START" ||
              this.block.settings.users.length < 2
            ) {
              this.block.settings.formEditControls.forEach((control) => {
                if (typeof control === "string") {
                  formEditControls.push(control);
                }
              });
            } else {
              this.block.settings.formEditControls.forEach((control) => {
                if (typeof control === "object") {
                  if (control.formFields.length) {
                    formEditControls.push(control);
                  }
                } else if (typeof control === "string") {
                  formEditControls.push(JSON.parse(control));
                  // formEditControls.push(control);
                }
              });
            }
          }
          this.security.formEditControls = formEditControls;
          this.security.formVisibilityAccess =
            this.block.settings.formVisibilityAccess === undefined
              ? true
              : this.block.settings.formVisibilityAccess;

          let formSecureControls = [];
          if (this.block.settings.formSecureControls) {
            if (
              this.blockType === "START" ||
              this.block.settings.users.length < 2
            ) {
              this.block.settings.formSecureControls.forEach((control) => {
                if (typeof control === "string") {
                  formSecureControls.push(control);
                }
              });
            } else {
              this.block.settings.formSecureControls.forEach((control) => {
                if (typeof control === "object") {
                  formSecureControls.push(control);
                } else if (typeof control === "string") {
                  formSecureControls.push(JSON.parse(control));
                }
              });
            }
          }
          this.security.formSecureControls = formSecureControls;
          this.documentRequired = this.block.settings.documentRequired || false;
          this.generatePDF = this.block.settings.generatePDF;
          this.generatePDFFields = this.block.settings.generatePDFFields || [];
          this.generateCSV = this.block.settings.generateCSV || false;
          this.generateCSVFields = this.block.settings.generateCSVFields || [];
          this.masterEntry = this.block.settings.masterEntry;
          this.reopen = this.block.settings.reopen || false;
          this.reopenBlockId = this.block.settings.reopenBlockId || "";
          this.userSignature = this.block.settings.userSignature;
          this.MLConditions = this.block.settings.MLConditions;
          this.standardCondition = this.block.settings.standardCondition;
          this.masterCondition = this.block.settings.masterCondition;
          this.masterFormId = this.block.settings.masterFormId;
          if (this.block.settings.conditions) {
            this.conditions = this.block.settings.conditions.condition;
            this.groupLogic =
              this.block.settings.conditions.groupLogic || "ALL";
          } else {
            this.conditions = [];
            this.groupLogic = "ALL";
          }
          this.activityList = [];
          this.stageList = [];
          this.blocks.forEach((row) => {
            if (row.type === "CONDITION" && row.settings.masterCondition) {
              this.conditionMasterFormId =
                row.settings.masterConditions.masterFormId;
            }
            if (
              row.type === "START" ||
              row.type === "INTERNAL_ACTOR" ||
              row.type === "EXTERNAL_ACTOR"
            ) {
              this.activityList.push({
                id: row.id,
                label: row.settings.label,
                value: row.id,
              });
            }
            if (row.id !== this.block.id) {
              this.stageList.push({
                id: row.id,
                label: row.settings.label,
                value: row.id,
              });
            }
          });

          if (this.block.settings.masterConditions) {
            this.masterConditions =
              this.block.settings.masterConditions.condition;
          } else {
            this.masterConditions = [];
          }
          if (this.block.settings.masterColumnId) {
            this.masterUser = this.block.settings.masterColumnId;
            this.hasMasterUser = true;
          } else {
            this.masterUser = [];
            this.hasMasterUser = false;
          }

          this.ruleConditions = this.block.settings.ruleConditions;
          this.copyRuleConditions = this.block.settings.ruleConditions;

          if (this.block.settings.notifications) {
            if (
              this.block.settings.notifications["acknowledgement"] &&
              this.block.settings.notifications["inboxNotification"]
            ) {
              this.notifications = Object.keys(
                this.block.settings.notifications
              ).length
                ? this.block.settings.notifications
                : {
                    acknowledgement: {
                      notify: false,
                      subject: "",
                      subjectContent: "",
                      body: "",
                      bodyContent: "",
                      attachment: false,
                      emailAttachment: false,
                      slackAttachment: false,
                      teamsAttachment: false,
                      emailNotify: false,
                      smsNotify: false,
                      smsContent: "",
                      slackNotify: false,
                      slackContent: "",
                      slackContentCopy: "",
                      slackConnectorId: 0,
                      slackHubId: 0,
                      teamsNotify: false,
                      teamsContent: "",
                      teamsContentCopy: "",
                      teamsConnectorId: 0,
                      teamsHubId: 0,
                      whatsappNotify: false,
                      whatsappContent: "",
                      whatsappContentCopy: "",
                      whatsappConnectorId: 0,
                      whatsappAttachment: false,
                    },
                    inboxNotification: {
                      notify: false,
                      subject: "",
                      subjectContent: "",
                      body: "",
                      bodyContent: "",
                      attachment: false,
                      actionButtons: false,
                      emailNotify: false,
                      smsNotify: false,
                      smsContent: "",
                      slackNotify: false,
                      slackContent: "",
                      slackContentCopy: "",
                      slackConnectorId: 0,
                      slackHubId: 0,
                      teamsNotify: false,
                      teamsContent: "",
                      teamsContentCopy: "",
                      teamsConnectorId: 0,
                      teamsHubId: 0,
                      emailAttachment: false,
                      slackAttachment: false,
                      teamsAttachment: false,
                      whatsappNotify: false,
                      whatsappContent: "",
                      whatsappContentCopy: "",
                      whatsappConnectorId: 0,
                      whatsappAttachment: false,
                    },
                    markCCField: {
                      notify: false,
                      subject: "",
                      subjectContent: "",
                      body: "",
                      bodyContent: "",
                      attachment: false,
                      fields: [],
                    },
                    processStage: {
                      notify: false,
                      subject: "",
                      subjectContent: "",
                      body: "",
                      bodyContent: "",
                      attachment: false,
                      alertProcessStage: [],
                    },
                    userGroup: {
                      notify: false,
                      subject: "",
                      subjectContent: "",
                      body: "",
                      bodyContent: "",
                      attachment: false,
                      alertUser: [],
                      alertGroup: [],
                    },
                  };

              if (
                !this.block.settings.notifications["markCCField"] &&
                !this.block.settings.notifications["processStage"] &&
                !this.block.settings.notifications["userGroup"]
              ) {
                this.$set(this.notifications, "markCCField", {
                  notify: false,
                  subject: "",
                  subjectContent: "",
                  body: "",
                  bodyContent: "",
                  attachment: false,
                  fields: [],
                });
                this.$set(this.notifications, "processStage", {
                  notify: false,
                  subject: "",
                  subjectContent: "",
                  body: "",
                  bodyContent: "",
                  attachment: false,
                  alertProcessStage: [],
                });
                this.$set(this.notifications, "userGroup", {
                  notify: false,
                  subject: "",
                  subjectContent: "",
                  body: "",
                  bodyContent: "",
                  attachment: false,
                  alertUser: [],
                  alertGroup: [],
                });
              }
            } else {
              this.notifications = {
                acknowledgement: {
                  notify: false,
                  subject: "",
                  subjectContent: "",
                  body: "",
                  bodyContent: "",
                  attachment: false,
                  emailNotify: false,
                  smsNotify: false,
                  smsContent: "",
                  slackNotify: false,
                  slackContent: "",
                  slackContentCopy: "",
                  slackConnectorId: 0,
                  slackHubId: 0,
                  teamsNotify: false,
                  teamsContent: "",
                  teamsContentCopy: "",
                  teamsConnectorId: 0,
                  teamsHubId: 0,
                  emailAttachment: false,
                  slackAttachment: false,
                  teamsAttachment: false,
                  whatsappNotify: false,
                  whatsappContent: "",
                  whatsappContentCopy: "",
                  whatsappConnectorId: 0,
                  whatsappAttachment: false,
                },
                inboxNotification: {
                  notify: false,
                  subject: "",
                  subjectContent: "",
                  body: "",
                  bodyContent: "",
                  attachment: false,
                  actionButtons: false,
                  emailNotify: false,
                  smsNotify: false,
                  smsContent: "",
                  slackNotify: false,
                  slackContent: "",
                  slackContentCopy: "",
                  slackConnectorId: 0,
                  slackHubId: 0,
                  teamsNotify: false,
                  teamsContent: "",
                  teamsContentCopy: "",
                  teamsConnectorId: 0,
                  teamsHubId: 0,
                  emailAttachment: false,
                  slackAttachment: false,
                  teamsAttachment: false,
                  whatsappNotify: false,
                  whatsappContent: "",
                  whatsappContentCopy: "",
                  whatsappConnectorId: 0,
                  whatsappAttachment: false,
                },
                markCCField: {
                  notify: false,
                  subject: "",
                  subjectContent: "",
                  body: "",
                  bodyContent: "",
                  attachment: false,
                  fields: [],
                },
                processStage: {
                  notify: false,
                  subject: "",
                  subjectContent: "",
                  body: "",
                  bodyContent: "",
                  attachment: false,
                  alertProcessStage: [],
                },
                userGroup: {
                  notify: false,
                  subject: "",
                  subjectContent: "",
                  body: "",
                  bodyContent: "",
                  attachment: false,
                  alertUser: [],
                  alertGroup: [],
                },
              };
            }
          } else {
            this.notifications = {
              acknowledgement: {
                notify: false,
                subject: "",
                subjectContent: "",
                body: "",
                bodyContent: "",
                attachment: false,
                emailNotify: false,
                smsNotify: false,
                smsContent: "",
                slackNotify: false,
                slackContent: "",
                slackContentCopy: "",
                slackConnectorId: 0,
                slackHubId: 0,
                teamsNotify: false,
                teamsContent: "",
                teamsContentCopy: "",
                teamsConnectorId: 0,
                teamsHubId: 0,
                emailAttachment: false,
                slackAttachment: false,
                teamsAttachment: false,
                whatsappNotify: false,
                whatsappContent: "",
                whatsappContentCopy: "",
                whatsappConnectorId: 0,
                whatsappAttachment: false,
              },
              inboxNotification: {
                notify: false,
                subject: "",
                subjectContent: "",
                body: "",
                bodyContent: "",
                attachment: false,
                actionButtons: false,
                emailNotify: false,
                smsNotify: false,
                smsContent: "",
                slackNotify: false,
                slackContent: "",
                slackContentCopy: "",
                slackConnectorId: 0,
                slackHubId: 0,
                teamsNotify: false,
                teamsContent: "",
                teamsContentCopy: "",
                teamsConnectorId: 0,
                teamsHubId: 0,
                emailAttachment: false,
                slackAttachment: false,
                teamsAttachment: false,
                whatsappNotify: false,
                whatsappContent: "",
                whatsappContentCopy: "",
                whatsappConnectorId: 0,
                whatsappAttachment: false,
              },
              markCCField: {
                notify: false,
                subject: "",
                subjectContent: "",
                body: "",
                bodyContent: "",
                attachment: false,
                fields: [],
              },
              processStage: {
                notify: false,
                subject: "",
                subjectContent: "",
                body: "",
                bodyContent: "",
                attachment: false,
                alertProcessStage: [],
              },
              userGroup: {
                notify: false,
                subject: "",
                subjectContent: "",
                body: "",
                bodyContent: "",
                attachment: false,
                alertUser: [],
                alertGroup: [],
              },
            };
          }

          this.syncFieldInForm = this.block.settings.syncFieldInForm;
          this.syncFieldInRepository =
            this.block.settings.syncFieldInRepository;
          this.masterFormCompare = this.masterFormId ? true : false;
          if (this.masterFormId) {
            this.getFormFields(this.masterFormId);
          }

          if (this.block.settings.slaSettings) {
            this.sla.settings = this.block.settings.slaSettings || {
              slaType: "STANDARD",
              slaMasterFormId: 0,
              workflowFormSLAFieldId: "",
              emailNotify: true,
              smsNotify: false,
            };
            if (this.sla.settings.slaType === "ADVANCED") {
              this.sla.rules = [];
            } else {
              this.sla.settings.slaType = "STANDARD";
              this.sla.settings.slaMasterFormId = 0;
              this.sla.settings.workflowFormSLAFieldId = "";
              if (this.block.settings.slaRules.length) {
                let slaRules = this.block.settings.slaRules.map((field) => {
                  if (typeof field.settingsJson === "string") {
                    field.settingsJson = JSON.parse(field.settingsJson);
                  }
                  return field;
                });
                this.sla.rules = slaRules;
              } else {
                this.sla.rules = [];
              }
            }
          } else {
            this.sla.settings = {
              slaType: "STANDARD",
              slaMasterFormId: 0,
              workflowFormSLAFieldId: "",
              emailNotify: true,
              smsNotify: false,
            };
            this.sla.rules = [];
          }

          if (this.block.settings.fileSettings) {
            this.fileSettings = this.block.settings.fileSettings;
          } else {
            this.fileSettings = {
              fileCheckList: [],
              partial: false,
            };
          }
          this.proceedActionList = [];
          this.rules.forEach((rule) => {
            if (rule.fromBlockId === this.block.id) {
              this.blocks.forEach((node) => {
                if (node.id === rule.toBlockId) {
                  this.connections.push({
                    ...rule,
                    label: node.settings.label,
                    type: node.type,
                    blockType: this.block.type,
                    proceedAction: rule.proceedAction,
                    remarks: rule.remarks || false,
                    passwordAccess: rule.passwordAccess || false,
                    confirm: rule.confirm || false,
                    signature: rule.signature || false,
                  });
                  this.proceedActionList.push({
                    id: this.$nano.id(),
                    label: rule.proceedAction,
                    value: rule.proceedAction,
                  });
                }
              });
            }
          });

          this.userReply = this.block.settings.userReply || false;
          this.toInitiator = this.block.settings.toInitiator || false;

          this.mlCompareMaster = this.block.settings.mlCompareMaster || false;

          if (this.block.settings.mlMasterFormId) {
            this.mlMasterFormId = this.block.settings.mlMasterFormId || 0;
            this.getFormFields(this.mlMasterFormId);
            this.mlCompareFields = this.block.settings.mlCompareFields || [];
            this.mlPredictionField =
              this.block.settings.mlPredictionField || "";
          }
          if (this.mlCompareFields.length === 0) {
            this.mlCompareValues();
          }
          // console.log(this.mailInitiate);
          this.mailInitiate = {
            mailSettingsId: 0,
            conditions: {
              hasAttachment: false,
              attachment: {
                filename: "",
                fileType: "",
                ocrData: "",
              },
              mailSubject: "",
              mailContent: "",
              fromAddress: [],
              fromDomain: [],
            },
          };
          this.hasMailContent = false;
          this.hasMailSubject = false;
          let mailSettings = this.block.settings.mailInitiate;
          if (mailSettings && mailSettings.mailSettingsId) {
            // console.log(this.block.settings.mailInitiate);

            this.mailInitiate.mailSettingsId = mailSettings.mailSettingsId;
            this.mailInitiate.conditions.hasAttachment =
              mailSettings.conditions.hasAttachment;
            this.mailTriggerConditions = [];
            if (mailSettings.conditions.hasAttachment) {
              if (mailSettings.conditions.attachment.filename.length) {
                this.mailInitiate.conditions.attachment.filename =
                  mailSettings.conditions.attachment.filename[0].value;
              }
              if (mailSettings.conditions.attachment.fileType.length) {
                this.mailInitiate.conditions.attachment.fileType =
                  mailSettings.conditions.attachment.fileType[0].value;
              }
              if (mailSettings.conditions.attachment.ocrData.length) {
                this.mailInitiate.conditions.attachment.ocrData =
                  mailSettings.conditions.attachment.ocrData[0].value;
              }
            }
            if (mailSettings.conditions.mailContent.length) {
              this.hasMailContent = true;

              this.mailInitiate.conditions.mailContent =
                mailSettings.conditions.mailContent[0].value;
            }
            if (mailSettings.conditions.mailSubject.length) {
              this.hasMailSubject = true;
              // this.mailTriggerConditions.push("MAIL_SUBJECT");
              this.mailInitiate.conditions.mailSubject =
                mailSettings.conditions.mailSubject[0].value;
            }
            // console.log(mailSettings);
            mailSettings.conditions.fromAddress.forEach((row) => {
              this.userEmailList.push({
                id: this.$nano.id(),
                label: row,
                value: row,
              });
            });
            mailSettings.conditions.fromDomain.forEach((row) => {
              this.mailDomainList.push({
                id: this.$nano.id(),
                label: row,
                value: row,
              });
            });

            this.mailInitiate.conditions.fromAddress =
              mailSettings.conditions.fromAddress;
            this.mailInitiate.conditions.fromDomain =
              mailSettings.conditions.fromDomain;
          } else {
            // console.log(this.mailInitiate, "elsse");
            // this.mailInitiate;
            this.mailInitiate = {
              mailSettingsId: 0,
              conditions: {
                hasAttachment: false,
                attachment: {
                  filename: "",
                  fileType: "",
                  ocrData: "",
                },
                mailSubject: "",
                mailContent: "",
                fromAddress: [],
                fromDomain: [],
              },
            };
          }

          this.workflowName = this.block.settings.subWorkflowId;
          this.workflowInitiator = this.block.settings.workflowInitiator;

          if (this.block.settings.aiPrediction) {
            this.aiPrediction = this.block.settings.aiPrediction;
          } else {
            this.aiPrediction = {};
          }

          if (this.block.settings.documentReminderSettings) {
            if (
              this.block.settings.documentReminderSettings.documentList.length
            ) {
              this.documentReminder =
                this.block.settings.documentReminderSettings;
            }
          }
        }
      },
    },

    workflow: {
      async handler() {
        if (this.workflow) {
          await this.getForm();
          await this.getRepositoryFields();
          this.getWorkflowNodes();
        }
      },
    },

    "workflow.settings.general.initiateUsing.formId": {
      // deep: true,
      handler() {
        if (
          this.workflow.settings.general.initiateUsing.formId &&
          !this.workflowId
        ) {
          this.getForm();
        }
      },
    },

    initiateMode() {
      if (
        this.blockType === "START" &&
        (this.initiateMode === "AUTOMATIC" ||
          this.initiateMode === "MANUAL_AUTOMATIC")
      ) {
        this.initiateBy = this.initiateBy.filter(
          (actor) => actor !== "USER" && actor !== "GROUP"
        );

        this.users = [];
        this.groups = [];
      }
    },

    initiateBy() {
      if (this.initiateBy && this.blockType === "START") {
        if (
          !this.initiateBy.includes("USER") &&
          !this.initiateBy.includes("GROUP")
        ) {
          this.users = [];
          this.groups = [];
        } else {
          if (!this.initiateBy.includes("USER")) {
            this.users = [];
          }
          if (!this.initiateBy.includes("GROUP")) {
            this.groups = [];
          }
        }
      }
    },

    partialApprove: {
      immediate: true,
      handler() {
        this.proceedActionList = [];
        if (!this.partialApprove) {
          if (isEmpty(this.block)) return [];
          this.rules.forEach((rule) => {
            if (rule.fromBlockId === this.block.id) {
              this.blocks.forEach((node) => {
                if (node.id === rule.toBlockId) {
                  this.proceedActionList.push({
                    id: this.$nano.id(),
                    label: rule.proceedAction,
                    value: rule.proceedAction,
                  });
                }
              });
            }
          });
        }
      },
    },

    conditionMasterFormId() {
      if (this.conditionMasterFormId) {
        this.getFormFields(this.conditionMasterFormId);
      }
    },
  },

  created() {
    this.getUsers();
    this.getGroups();
    this.getMasterForms();
    this.getSMTPConnection();
    this.getWorkflows();
  },

  methods: {
    closeSettings() {
      this.$emit("input", false);
    },

    updateValue(option) {
      const _value = [...this.value];

      if (_value.includes(option)) {
        const optionIdx = _value.indexOf(option);
        _value.splice(optionIdx, 1);
      } else {
        _value.push(option);
      }

      this.$emit("input", _value);
    },

    removeRule(rule) {
      this.connections = this.connections.filter((row) => row.id !== rule.id);
      this.$emit("removeConnection", rule);
    },

    compareValues() {
      if (!this.masterFormCompare) {
        this.syncFieldInForm = "";
        this.syncFieldInRepository = "";
        this.masterFormId = 0;
        this.formFields = [];
      }
    },

    mlCompareValues() {
      this.mlCompareFields = [];
      if (this.mlCompareMaster) {
        this.addRow();
      }
    },

    addRow() {
      const model = {
        id: this.$nano.id(),
        masterField: "",
        workflowFormField: "",
      };

      this.mlCompareFields.push(model);
    },

    removeRow(rowIdx) {
      this.mlCompareFields.splice(rowIdx, 1);
    },

    /* api functions */

    async getUsers() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.userListWithAny.push({
        id: this.$nano.id(),
        label: "Any",
        value: "0",
      });

      this.userList =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value || user.loginName,
          value: user.id,
        }));
      this.userListWithAny.push(...this.userList);
    },

    async getGroups() {
      const { error, payload } = await group.getGroupList();

      if (error) {
        this.$alert.error("Error fetching groups");
        return;
      }

      this.groupList =
        payload &&
        payload.map((group) => ({
          id: this.$nano.id(),
          label: group.value,
          value: group.id,
        }));
    },

    async getMasterForms() {
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "MASTER",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching master form list");
        return;
      }

      this.masterFormList = [];
      const { data } = payload;
      if (data.length) {
        this.masterFormList = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: form.id,
        }));
      }
    },

    async getForm() {
      this.formColumns = [];
      this.workflowFormColumns = [];
      if (this.workflow.settings.general.initiateUsing.formId) {
        const { error, payload } = await form.getForm(
          this.workflow.settings.general.initiateUsing.formId
        );

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload) {
          let form = JSON.parse(payload.formJson);
          this.fields = [];
          const panels = [...form.panels, ...form.secondaryPanels];

          if (!panels.length) {
            return;
          }
          let panelIndex = 1;
          for (const panel of panels) {
            if (panel.fields.length) {
              this.fields.push(...panel.fields);
              this.formColumns.push({
                id: panel.id,
                label: panel.settings.title
                  ? panel.settings.title + " (Panel)"
                  : "PANEL-" + panelIndex,
                value: panel.id,
                type: "PANEL",
              });
              panelIndex += 1;
            }
          }
          this.workflowFormColumns = [];
          this.fields.forEach((field) => {
            if (field.type !== "DIVIDER") {
              if (field.type === "TABLE") {
                this.formColumns.push({
                  id: field.id,
                  label: field.label ? field.label + " (TABLE)" : field.type,
                  value: field.id,
                });

                field.settings.specific.tableColumns.forEach((column) => {
                  this.formColumns.push({
                    id: column.id,
                    label: column.label
                      ? column.label + " (TABLE - COLUMN)"
                      : column.type,
                    value: column.id,
                  });
                });

                this.workflowFormColumns.push({
                  id: field.id,
                  label: field.label ? field.label : field.type,
                  value: field.id,
                  type: field.type,
                });
              } else {
                this.formColumns.push({
                  id: field.id,
                  label: field.label ? field.label + " (Control)" : field.type,
                  value: field.id,
                });
                this.workflowFormColumns.push({
                  id: field.id,
                  label: field.label ? field.label : field.type,
                  value: field.id,
                  type: field.type,
                });
              }
            }
          });
          this.getRuleConditionFields();
        }
      }
    },

    async getRuleConditionFields() {
      let masterFormId = 0;
      this.workflow.blocks.forEach((block) => {
        if (block.type === "START") {
          if (
            block.settings.initiateMode === "AUTOMATIC" ||
            block.settings.initiateMode === "MANUAL_AUTOMATIC"
          ) {
            let masterForm = block.settings.initiateBy.filter(
              (item) => item === "MASTER_FORM"
            );
            if (masterForm) {
              masterFormId = block.settings.masterFormId;
            }
          }
        }
      });
      if (masterFormId) {
        const { error, payload } = await form.getForm(masterFormId);

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload) {
          let form = JSON.parse(payload.formJson);
          let masterfields = [];
          const panels = [...form.panels, ...form.secondaryPanels];

          if (!panels.length) {
            return;
          }
          for (const panel of panels) {
            masterfields.push(...panel.fields);
          }

          masterfields.forEach((field) => {
            if (field.type !== "DIVIDER") {
              this.conditionFields.push({
                id: field.id,
                label: field.label || field.type,
                type: field.type,
              });
            }
          });
        }
      }
    },

    async getFormFields(formId) {
      this.formFields = [];
      this.masterColumns = [];
      const { error, payload } = await form.getForm(formId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let form = JSON.parse(payload.formJson);
        let masterfields = [];
        const panels = [...form.panels, ...form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          masterfields.push(...panel.fields);
        }

        masterfields.forEach((field) => {
          if (field.type !== "DIVIDER") {
            this.formFields.push({
              id: field.id,
              label: field.label,
              value: field.label,
            });
            this.masterColumns.push({
              id: field.id,
              label: field.label,
              value: field.id,
            });
          }
        });
      }
    },

    async getRepositoryFields() {
      if (this.workflow.settings.general.initiateUsing.type === "DOCUMENT") {
        this.$store.commit("showLoadingBar");
        const { error, payload } = await repository.getRepository(
          this.workflow.settings.general.initiateUsing.repositoryId
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }

        if (!payload) {
          return;
        }
        payload.fields.forEach((field) => {
          this.repositoryFields.push({
            id: field.id,
            label: field.name,
            value: field.name,
          });
        });
      }
    },

    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      this.rules.forEach((rule) => {
        let ruleConnect = this.connections.find(
          (line) =>
            line.fromBlockId === rule.fromBlockId &&
            line.toBlockId === rule.toBlockId
        );
        if (ruleConnect) {
          rule.proceedAction = ruleConnect.proceedAction;
          rule.remarks = ruleConnect.remarks;
          rule.passwordAccess = ruleConnect.passwordAccess;
          rule.confirm = ruleConnect.confirm;
          rule.signature = ruleConnect.signature;
        }
      });
      const block = cloneDeep(this.block);
      block.settings.label = this.label;
      block.settings.initiateMode = this.initiateMode;
      block.settings.initiateBy = this.initiateBy;
      block.settings.users = this.users;
      block.settings.userDomains = this.userDomains;
      block.settings.groups = this.groups;
      block.settings.manager = this.manager;
      block.settings.toRequester = this.toRequester;
      block.settings.dynamicUser = this.dynamicUser;
      block.settings.actedActivity = this.actedActivity;
      block.settings.externalUser = this.externalUser;
      block.settings.externalUserMail = this.externalUserMail;
      block.settings.coordinator = this.coordinator;
      block.settings.internalForward = this.internalForward;
      block.settings.forwardLabel = this.forwardLabel;
      block.settings.forwardedUserAction = this.forwardedUserAction;
      block.settings.internalForwardUser = this.internalForwardUser;
      block.settings.internalForwardGroup = this.internalForwardGroup;
      block.settings.partialApprove = this.partialApprove;
      block.settings.fullApprovalAction = this.fullApprovalAction;
      block.settings.formEditAccess = this.security.formEditAccess;
      let editControls = [];
      if (this.blockType !== "START" && this.users.length > 1) {
        this.security.formEditControls.forEach((control) => {
          if (typeof control === "object") {
            if (control.formFields.length) {
              editControls.push(JSON.stringify(control));
            }
          }
        });
      } else {
        editControls = this.security.formEditControls;
      }
      block.settings.formEditControls = editControls;
      block.settings.formVisibilityAccess = this.security.formVisibilityAccess;
      let secureControls = [];
      if (this.blockType !== "START" && this.users.length > 1) {
        this.security.formSecureControls.forEach((control) => {
          if (typeof control === "object") {
            if (control.formFields.length) {
              secureControls.push(JSON.stringify(control));
            }
          }
        });
      } else {
        secureControls = this.security.formSecureControls;
      }
      block.settings.formSecureControls = secureControls;
      block.settings.documentRequired = this.documentRequired;
      block.settings.generatePDF = this.generatePDF;
      block.settings.generatePDFFields = this.generatePDFFields;
      block.settings.generateCSV = this.generateCSV;
      block.settings.generateCSVFields = this.generateCSVFields;
      block.settings.masterEntry = this.masterEntry;
      block.settings.reopen = this.reopen;
      block.settings.reopenBlockId = this.reopenBlockId;
      block.settings.userSignature = this.userSignature;
      block.settings.MLConditions = this.MLConditions;
      block.settings.standardCondition = this.standardCondition;
      block.settings.masterCondition = this.masterCondition;
      block.settings.masterFormId = this.masterFormId;
      block.settings.conditions = {
        groupLogic: this.groupLogic,
        condition: this.conditions,
      };
      block.settings.masterConditions = {
        masterFormId: this.conditionMasterFormId,
        condition: this.masterConditions,
      };
      if (this.conditionMasterFormId) {
        block.settings.masterFormId = this.conditionMasterFormId;
        block.settings.masterColumnId = this.masterUser;
      }
      block.settings.ruleConditions = this.ruleConditions;
      block.settings.notifications = this.notifications;
      block.settings.syncFieldInForm = this.syncFieldInForm;
      block.settings.syncFieldInRepository = this.syncFieldInRepository;
      if (
        JSON.stringify(this.ruleConditions) !==
        JSON.stringify(this.copyRuleConditions)
      ) {
        if (this.workflow.modifiedBlockIds.indexOf(this.block.id) === -1) {
          this.workflow.modifiedBlockIds.push(this.block.id);
          this.workflow.blockStatus = 1;
        }
      }
      block.settings.slaSettings = this.sla.settings;
      if (this.sla.settings.slaType === "STANDARD") {
        this.sla.settings.slaMasterFormId = 0;
        this.sla.settings.workflowFormSLAFieldId = "";
        let level = 1;
        if (this.sla.rules.length) {
          this.sla.rules = this.sla.rules.filter(
            (rule) =>
              rule.name != "" &&
              rule.duration != "" &&
              (rule.settingsJson.notifyUsers.length ||
                rule.settingsJson.escalateUsers.length ||
                rule.settingsJson.escalateStage ||
                rule.settingsJson.initiator)
          );
        }
        if (this.sla.rules.length) {
          this.sla.rules.forEach((rule) => {
            rule.level = level;
            level += 1;
            rule.settingsJson = JSON.stringify(rule.settingsJson);
          });
        }
      } else if (this.sla.settings.slaType === "ADVANCED") {
        this.sla.rules = [];
        this.sla.rules.push({
          name: "SLA Master Policy",
          level: 1,
          masterFormId: this.sla.settings.slaMasterFormId,
          fieldId: this.sla.settings.workflowFormSLAFieldId,
        });
      }
      console.log(this.sla.rules);
      block.settings.slaRules = this.sla.rules;
      console.log(block.settings.slaRules);

      block.settings.fileSettings = this.fileSettings;
      block.settings.userReply = this.userReply;
      block.settings.toInitiator = this.toInitiator;

      block.settings.mlCompareMaster = this.mlCompareMaster;
      block.settings.mlMasterFormId = this.mlMasterFormId;
      block.settings.mlCompareFields = this.mlCompareFields;
      block.settings.mlPredictionField = this.mlPredictionField;

      if (
        (this.initiateBy.includes("EMAIL") &&
          this.initiateMode === "AUTOMATIC") ||
        this.blockType === "EMAIL_WATCHER"
      ) {
        let mailInitiate = this.mailInitiate;

        if (mailInitiate.conditions.hasAttachment) {
          if (this.mailInitiate.conditions.attachment.filename) {
            mailInitiate.conditions.attachment.filename = [
              {
                criteria: "filename",
                condition: "CONTAINS",
                value: this.mailInitiate.conditions.attachment.filename,
              },
            ];
          } else {
            mailInitiate.conditions.attachment.filename = [];
          }

          if (this.mailInitiate.conditions.attachment.fileType) {
            mailInitiate.conditions.attachment.fileType = [
              {
                criteria: "fileType",
                condition: "CONTAINS",
                value: this.mailInitiate.conditions.attachment.fileType,
              },
            ];
          } else {
            mailInitiate.conditions.attachment.fileType = [];
          }

          if (this.mailInitiate.conditions.attachment.ocrData) {
            mailInitiate.conditions.attachment.ocrData = [
              {
                criteria: "ocrData",
                condition: "CONTAINS",
                value: this.mailInitiate.conditions.attachment.ocrData,
              },
            ];
          } else {
            mailInitiate.conditions.attachment.ocrData = [];
          }
        } else {
          mailInitiate.conditions.attachment = {
            filename: [],
            fileType: [],
            ocrData: [],
          };
        }

        if (this.hasMailSubject) {
          if (this.mailInitiate.conditions.mailSubject) {
            mailInitiate.conditions.mailSubject = [
              {
                criteria: "mailSubject",
                condition: "CONTAINS",
                value: this.mailInitiate.conditions.mailSubject,
              },
            ];
          } else {
            mailInitiate.conditions.mailSubject = [];
          }
        } else {
          mailInitiate.conditions.mailSubject = [];
        }

        if (this.hasMailContent) {
          if (this.mailInitiate.conditions.mailContent) {
            mailInitiate.conditions.mailContent = [
              {
                criteria: "mailContent",
                condition: "CONTAINS",
                value: this.mailInitiate.conditions.mailContent,
              },
            ];
          } else {
            mailInitiate.conditions.mailContent = [];
          }
        } else {
          mailInitiate.conditions.mailContent = [];
        }

        // console.log(mailInitiate);

        block.settings.mailInitiate = mailInitiate;
      }

      if (this.blockType === "WORKFLOW_CONNECTOR") {
        block.settings.subWorkflowId = this.workflowName;
        // console.log(block.settings.subWorkflowId);
      } else if (this.workflowInitiator) {
        block.settings.workflowInitiator = this.workflowInitiator;
        block.settings.subWorkflowId = this.workflowName;
      } else {
        block.settings.subWorkflowId = 0;
      }

      if (this.aiPrediction) {
        block.settings.aiPrediction = this.aiPrediction;
      }

      if (this.documentReminder) {
        if (this.documentReminder.documentList.length) {
          block.settings.documentReminderSettings = this.documentReminder;
        }
      }

      this.$emit("save", block);
      this.closeSettings();
    },

    getWorkflowNodes() {
      this.workflowNodes = [];
      this.workflow.blocks.forEach((item) => {
        this.workflowNodes.push({
          id: this.$nano.id(),
          label: item.settings.label,
          value: item.id,
        });
      });
    },

    async getSMTPConnection() {
      const { error, payload } = await integration.getSMTPConnection({
        filterBy: [],
      });
      if (payload.length) {
        this.mailSettingsList =
          payload &&
          payload.map((name) => ({
            id: this.$nano.id(),
            label: name.settingName,
            value: name.settingId,
          }));
      }
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getWorkflows() {
      this.$store.commit("showLoadingBarPage");

      let filters = [
        {
          filters: [
            {
              id: this.$nano.id(),
              criteria: "flowStatus",
              condition: "IS_EQUALS_TO",
              value: "PUBLISHED",
            },
          ],
          groupCondition: "",
        },
      ];

      const { error, payload } = await workflow.getWorkflows({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: filters,
        itemsPerPage: 0,
        currentPage: 0,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;

      this.workflows = [];
      // console.log(payload);
      if (data[0].value.length) {
        this.workflows = data[0].value.map((item) => ({
          id: this.$nano.id(),
          label: item.name,
          value: item.id,
        }));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  margin: 16px 16px 16px 16px;
}

.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}

.action-header {
  border-bottom: 1px solid var(--divider-color);
  padding-bottom: 10px;
  margin-left: 16px;
}

#tab-settings {
  .field {
    margin: 16px 16px 24px 16px;
  }

  .content {
    flex: 1;
    border-right: 1px solid var(--divider-color);
  }

  .tabs {
    width: 68px;
    padding: 16px;
  }
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  tr {
    height: 48px;
  }

  th:not(.action) {
    padding: 4px 8px;
    text-align: left;
    font-weight: 500;
    text-transform: capitalize;
  }

  th,
  td {
    border: 1px solid var(--divider-color);
    &.action {
      width: 48px;
    }
  }

  td {
    padding: 4px;
    .autoMargin {
      margin: auto;
    }
  }
  .custom-center {
    text-align: center;
  }
}

.connector {
  margin: 16px 16px 0px 16px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--divider-color);
}
</style>
