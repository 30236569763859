import { createToastInterface, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import Alert from "@/components/common/alert/Alert.vue";

const options = {
  position: POSITION.BOTTOM_RIGHT,
  icon: false,
  closeButton: false,
  draggable: false,
};

const toast = createToastInterface(options);

export default {
  install(Vue) {
    Vue.prototype.$alert = {
      success(message) {
        return toast.success({
          component: Alert,
          props: { type: "success", message },
        });
      },

      info(message) {
        return toast.info({
          component: Alert,
          props: { type: "info", message },
        });
      },

      warning(message, fields) {
        return toast.warning({
          component: Alert,
          props: { type: "warning", message, fields },
        });
      },

      error(message) {
        console.log(message);
        // return toast.error({
        //   component: Alert,
        //   props: { type: "error", message },
        // });
      },

      danger(message) {
        return toast.error({
          component: Alert,
          props: { type: "reject", message },
        });
      },

      delete(message) {
        return toast.error({
          component: Alert,
          props: { type: "deleted", message },
        });
      },
    };
  },
};
