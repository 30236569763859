<template>
  <div id="pagination">
    <div class="row items-center">
      <div class="q-mr-sm q-ml-sm">
        Showing <span class="font-semibold">{{ itemFrom }}</span> to
        <span class="font-semibold">{{ itemTo }}</span> out of
        <span class="font-semibold">{{ totalItems }}</span> entries
      </div>

      <BaseActionButton
        v-if="totalItems > itemsPerPage"
        v-tooltip:secondary.top="'load all entries'"
        is-flat
        icon="eva-cloud-download-outline"
        color="secondary"
        @click="showLoadAllConfirmation"
      />

      <Confirm
        :value="isLoadAllConfirmationVisible"
        icon="mdi-alert-circle"
        icon-color="orange"
        @input="handleNo"
      >
        <template #title>Load all entries?</template>

        <template #default>
          <div class="q-py-md">
            <p>
              Are you sure you want to load all the
              <b>"{{ totalItems }}"</b> entries. Loading all the entries at once
              will take some time depends on your network speed.
            </p>

            <p>
              Recomends to load <b>"50"</b> or <b>"100"</b> entries at once for
              smooth experience.
            </p>
          </div>
        </template>

        <template #footer>
          <BaseButton
            is-flat
            label="no"
            class="q-mr-sm"
            color="orange"
            @click="handleNo"
          />
          <BaseButton label="yes" color="orange" @click="handleYes" />
        </template>
      </Confirm>
    </div>

    <div v-if="!fileSheet" class="pages">
      <q-pagination
        direction-links
        icon-prev="eva-chevron-left"
        icon-next="eva-chevron-right"
        :color="color"
        :max-pages="5"
        :max="totalPages"
        :value="currentPage"
        :active-color="bgColor"
        @input="updateCurrentPage"
      ></q-pagination>
    </div>

    <div v-if="!fileSheet" class="items-per-page">
      <div>Items per page:</div>
      <div class="font-semibold q-pl-xs">{{ _itemsPerPage }}</div>
      <BaseIcon name="eva-chevron-down" />

      <q-menu
        fit
        :offset="[0, 24]"
        transition-show="scale"
        transition-hide="scale"
      >
        <ListItem
          v-for="option in options"
          :key="option"
          :icon="
            itemsPerPage === option
              ? 'eva-radio-button-on'
              : 'eva-radio-button-off'
          "
          :is-selected="itemsPerPage === option"
          :label="option"
          @click="updateItemsPerPage(option)"
        />
      </q-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ListItem from "@/components/common/ListItem.vue";
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "Pagination",

  components: { ListItem, Confirm },

  props: {
    currentPage: {
      type: Number,
      required: true,
    },

    totalItems: {
      type: Number,
      required: true,
    },

    itemsPerPage: {
      type: Number,
      default: 50,
    },

    fileSheet: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoadAllConfirmationVisible: false,
      options: [50, 100, 250, 500, 1000, "All"],
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    color() {
      return this.darkTheme ? "gray-4" : "gray-8";
    },

    bgColor() {
      return this.darkTheme ? "secondary-5" : "secondary";
    },

    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    itemFrom() {
      if (!this.totalItems) {
        return 0;
      }

      return this.currentPage * this.itemsPerPage - this.itemsPerPage + 1;
    },

    itemTo() {
      const _itemTo = this.currentPage * this.itemsPerPage;
      return Math.min(_itemTo, this.totalItems);
    },

    _itemsPerPage() {
      if (this.itemsPerPage === this.totalItems) {
        return "All";
      }

      return this.itemsPerPage;
    },
  },

  methods: {
    showLoadAllConfirmation() {
      this.isLoadAllConfirmationVisible = true;
    },

    handleNo() {
      this.isLoadAllConfirmationVisible = false;
    },

    handleYes() {
      this.isLoadAllConfirmationVisible = false;
      this.$emit("loadAll");
    },

    updateCurrentPage(currentPage) {
      this.$emit("update:currentPage", currentPage);
    },

    updateItemsPerPage(itemsPerPage) {
      if (itemsPerPage === "All") {
        this.$emit("loadAll");
      } else {
        this.$emit("update:itemsPerPage", itemsPerPage);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  border-top: 1px solid var(--divider-color);

  .items-per-page {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
