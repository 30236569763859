<template>
  <FormSection
    :icon="isActive ? 'mdi-bell-ring' : 'mdi-bell-ring-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Notifications</template>
    <template #description>
      Send Notification To the Logi Portal Url for New Users And Updates
      Exsisting Master Entries
    </template>

    <!-- login notification types -->
    <div class="row">
      <CheckboxField
        v-model="authentication.notification"
        label="Enable Notification To External User"
        description="Sends Notification to Login Portal Url For new User and Updates Existing Master Entries"
        class="q-pt-md col"
        @input="updateNotification"
      />
      <div
        v-if="authentication.notification"
        class="col-auto q-pl-md"
        style="margin: auto"
      >
        <BaseActionButton
          v-tooltip.top="'settings'"
          is-flat
          no-border
          icon="eva-settings-outline"
          color="gray"
          @click="notificationSheet = true"
        >
        </BaseActionButton>
      </div>
    </div>
    <Sheet v-model="notificationSheet" has-footer width="650px">
      <!-- title -->

      <template #title>Notification Settings</template>

      <!-- ... -->

      <template #default>
        <BaseScrollbar height="calc(100vh - 200px)">
          <!-- Mail Content -->

          <div>
            <FormFieldLabel label="Mail Subject" class="field q-ma-md" />
            <TextEditor
              v-model="authentication.mailSubject"
              :hide-tool-bar="false"
              :form-columns="formFields"
              :plain-text="true"
            ></TextEditor>

            <FormFieldLabel label="Mail Content" class="field q-ma-md" />
            <TextEditor
              v-model="authentication.mailContent"
              :form-columns="formFields"
            ></TextEditor>
          </div>
          <!-- ... -->

          <!-- Mail Content -->
        </BaseScrollbar>
      </template>

      <!-- footer -->
      <template #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          @click="notificationSheet = false"
        />
        <BaseButton label="save" @click="notificationSheet = false" />
      </template>
      <!-- ... -->
    </Sheet>
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextEditor from "./components/TextEditor.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";

export default {
  name: "NotificationSettings",

  components: {
    FormSection,
    CheckboxField,
    Sheet,
    TextEditor,
    FormFieldLabel,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    authentication: {
      type: Object,
      default: () => {},
    },

    formFields: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      notificationSheet: false,
    };
  },

  methods: {
    updateNotification(value) {
      this.authentication.notification = value;
      this.$emit("update:authentication", this.authentication);
    },
  },
};
</script>

<style lang="scss" scoped></style>
