<template>
  <div class="card">
    <div class="q-pa-xs">
      <BaseScrollbar height="calc(100vh - 215px)" class="q-pa-sm">
        <template v-for="(row, idx) in commentsList">
          <q-chat-message
            :key="idx"
            :name="
              row.createdBy === $store.state.session.id
                ? 'me'
                : row.createdByEmail
            "
            :text="commentsText(row)"
            text-html
            :sent="row.createdBy === $store.state.session.id"
            :stamp="lastCommentDuration(row)"
            :bg-color="bgCommentsColor(row)"
            class="q-ma-sm"
          />
        </template>
      </BaseScrollbar>
    </div>
    <div class="col q-pa-sm borderTop">
      <div class="row">
        <div class="col">
          <TextAreaField
            v-model="commentText"
            auto-focus
            placeholder="Type a new comment..."
          />
        </div>
        <div class="col-auto" style="margin: auto">
          <BaseActionButton
            v-tooltip.left="'Post Comments'"
            icon="eva-paper-plane-outline"
            color="primary-11"
            class="q-ml-sm commentAction"
            size="16px"
            rotate-class="rotate-45"
            @click="saveComment"
          />
          <BaseActionButton
            v-tooltip.left="'Advanced Comments'"
            icon="mdi-comment-text-multiple"
            color="primary-11"
            class="q-ml-sm q-mt-sm commentAction"
            size="16px"
            @click="advancedCommentsSheet = true"
          />
        </div>
      </div>
    </div>
    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>
  </div>
</template>
<script>
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import { repository } from "@/api/factory.js";
export default {
  name: "FileComments",

  components: {
    TextAreaField,
    TextBuilderField,
    Modal,
  },

  props: {
    repositoryId: {
      type: Number,
      default: 0,
    },

    itemId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      commentText: "",
      commentsList: [],
      advancedCommentsSheet: false,
      advancedCommentText: "",
    };
  },

  created() {
    this.getComments();
  },

  methods: {
    commentsText(row) {
      if (row.processId) {
        return [
          `<b>${row.workflowName} : ${row.requestNo}</b><br/> ${row.comments}`,
        ];
      } else if (row.checkinStatus === 2) {
        return [`<b>CheckOut:</b><br/> ${row.comments}`];
      } else if (row.checkinStatus === 1) {
        return [`<b>CheckIn:</b><br/> ${row.comments}`];
      }
      return [row.comments];
    },

    bgCommentsColor(row) {
      if (row.processId) {
        return "orange-1";
      } else if (row.checkinStatus === 2) {
        return "red-1";
      } else if (row.checkinStatus === 1) {
        return "green-1";
      } else if (row.createdBy === this.$store.state.session.id) {
        return "primary-1";
      }
      return "secondary-1";
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    async getComments() {
      this.commentsList = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await repository.getFileComments(
        this.repositoryId,
        this.itemId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
      }
    },

    async saveComment() {
      if (this.commentText) {
        this.$store.commit("showLoadingBar");
        const { error } = await repository.insertFileComments(
          this.repositoryId,
          this.itemId,
          {
            comments: this.commentText,
          }
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        this.commentText = "";
        this.getComments();
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText && this.advancedCommentText != "<p></p>") {
        this.$store.commit("showLoadingBar");
        const { error } = await repository.insertFileComments(
          this.repositoryId,
          this.itemId,
          {
            comments: this.advancedCommentText,
          }
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        this.advancedCommentText = "";
        this.advancedCommentsSheet = false;
        this.getComments();
      } else {
        this.$alert.warning("Comments Text Required");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #fff;
  border-radius: 6px;
  height: calc(100vh - 125px);
  border: 1px solid #fff;
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }
}
</style>
