<template>
  <div id="general-settings">
    <!-- hide label -->

    <SingleChoiceField
      v-if="showHideLabel"
      :value="hideLabel"
      label="hide field label"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateHideLabel"
    />

    <!-- ... -->

    <!-- label -->

    <ValidationProvider
      v-if="showLabel"
      v-slot="{ errors }"
      name="label"
      :rules="{ required: !hideLabel, checkDuplicate: { target: labelError } }"
    >
      <TextField
        :value="label"
        is-mandatory
        label="label"
        :is-disabled="hideLabel"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateLabel"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- url -->

    <ValidationProvider
      v-if="fieldType === 'URL'"
      v-slot="{ errors }"
      name="link"
      :rules="{
        required: !hideLabel,
        url: true,
      }"
    >
      <TextField
        :value="url"
        is-mandatory
        label="link"
        :is-disabled="hideLabel"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateUrl"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- size -->

    <SingleChoiceField
      v-if="showSize"
      :value="size"
      label="size"
      :options="sizeOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateSize"
    />

    <!-- ... -->

    <!-- visibility -->

    <SingleChoiceField
      v-if="showVisibility"
      :value="visibility"
      label="visibility"
      :options="visibilityOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateVisibility"
    />

    <!-- ... -->

    <!-- placeholder -->

    <TextField
      v-if="showPlaceholder"
      :value="placeholder"
      label="placeholder"
      class="q-pa-md"
      @input="updatePlaceholder"
    />

    <!-- ... -->

    <!-- tooltip -->

    <TextAreaField
      v-if="!hideLabel && showTooltip && fieldType !== 'PASSWORD'"
      :value="tooltip"
      label="tooltip"
      class="q-pa-md"
      @input="updateTooltip"
    />

    <!-- ... -->

    <!-- divider type -->

    <SingleChoiceField
      v-if="fieldType === 'DIVIDER'"
      :value="dividerType"
      label="divider type"
      :options="dividerTypes"
      :options-per-line="4"
      class="q-pa-md"
      @input="updateDividerType"
    />

    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";

export default {
  name: "GeneralSettings",

  components: {
    ValidationProvider,
    TextField,
    TextAreaField,
    SingleChoiceField,
  },

  props: {
    fieldType: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    size: {
      type: String,
      default: "",
    },

    visibility: {
      type: String,
      default: "",
    },

    hideLabel: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: "",
    },

    tooltip: {
      type: String,
      default: "",
    },

    dividerType: {
      type: String,
      default: "",
    },

    settingsFor: {
      type: String,
      default: "",
    },

    panels: {
      type: Array,
      default: () => [],
    },

    url: {
      type: String,
      default: "",
    },

    fieldId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      sizeOptions: [
        {
          id: this.$nano.id(),
          label: "Small",
          value: "col-4",
        },
        {
          id: this.$nano.id(),
          label: "Medium",
          value: "col-6",
        },
        {
          id: this.$nano.id(),
          label: "Large",
          value: "col-12",
        },
      ],
      visibilityOptions: [
        {
          id: this.$nano.id(),
          label: "Normal",
          value: "NORMAL",
        },
        {
          id: this.$nano.id(),
          label: "Read only",
          value: "READ_ONLY",
        },
        {
          id: this.$nano.id(),
          label: "Disable",
          value: "DISABLE",
        },
      ],
      binaryOptions: [
        {
          id: this.$nano.id(),
          label: "Yes",
          value: true,
        },
        {
          id: this.$nano.id(),
          label: "No",
          value: false,
        },
      ],
      dividerTypes: [
        {
          id: this.$nano.id(),
          label: "Solid",
          value: "SOLID",
        },
        {
          id: this.$nano.id(),
          label: "Dashed",
          value: "DASHED",
        },
        {
          id: this.$nano.id(),
          label: "Dotted",
          value: "DOTTED",
        },
        {
          id: this.$nano.id(),
          label: "Double",
          value: "DOUBLE",
        },
      ],
      labelError: "",
    };
  },

  computed: {
    showHideLabel() {
      if (
        ["FILL_IN_THE_BLANKS", "TABLE", "MATRIX"].includes(this.settingsFor)
      ) {
        return false;
      }

      return ![
        "LABEL",
        "DIVIDER",
        "FILL_IN_THE_BLANKS",
        "PARAGRAPH",
        "TABS",
        "POPUP",
        "LOGIN_AUTHENTICATION",
        "URL",
      ].includes(this.fieldType);
    },

    showLabel() {
      if (this.settingsFor === "MATRIX") {
        return false;
      }

      return ![
        "DIVIDER",
        "FILL_IN_THE_BLANKS",
        "PARAGRAPH",
        "TABS",
        "LOGIN_AUTHENTICATION",
      ].includes(this.fieldType);
    },

    showTooltip() {
      if (["FILL_IN_THE_BLANKS", "MATRIX"].includes(this.settingsFor)) {
        return false;
      }

      return ![
        "DIVIDER",
        "FILL_IN_THE_BLANKS",
        "PARAGRAPH",
        "TABS",
        "POPUP",
        "LOGIN_AUTHENTICATION",
      ].includes(this.fieldType);
    },

    showSize() {
      if (
        ["MATRIX", "TABLE", "FILL_IN_THE_BLANKS", "NESTED_LIST"].includes(
          this.settingsFor
        )
      )
        return false;

      return [
        "LABEL",
        "SHORT_TEXT",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "CALCULATED",
        "DATE",
        "TIME",
        "DATE_TIME",
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "IMAGE",
        "SIGNATURE",
        "RATING",
        "PASSWORD",
        "CURRENCY",
        "FILE_UPLOAD",
        "COUNTRY_CODE",
        "CURRENCY_AMOUNT",
        "PHONE_NUMBER",
      ].includes(this.fieldType);
    },

    showVisibility() {
      if (this.settingsFor === "MATRIX") {
        return false;
      }

      return [
        "SHORT_TEXT",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "DATE",
        "TIME",
        "DATE_TIME",
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "TEXT_BUILDER",
        "PASSWORD",
        "CURRENCY",
        "COUNTRY_CODE",
        "CURRENCY_AMOUNT",
        "PHONE_NUMBER",
      ].includes(this.fieldType);
    },

    showPlaceholder() {
      if (["TABLE", "FILL_IN_THE_BLANKS"].includes(this.settingsFor)) {
        return false;
      }

      return [
        "SHORT_TEXT",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "CALCULATED",
        "DATE",
        "TIME",
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "CHIPS",
        "TEXT_BUILDER",
        "PASSWORD",
        "CURRENCY",
        "COUNTRY_CODE",
        "CURRENCY_AMOUNT",
        "PHONE_NUMBER",
      ].includes(this.fieldType);
    },
  },

  watch: {
    hideLabel: {
      immediate: true,
      handler() {
        if (this.hideLabel) {
          //this.updateLabel("");
        }
      },
    },
  },

  methods: {
    updateLabel(label) {
      label = label.trim();
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (
            field.id !== this.fieldId &&
            field.label.toLowerCase() === label.toLowerCase()
          ) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", label);
    },

    updateHideLabel(option) {
      this.$emit("update:hideLabel", option);
    },

    updateSize(size) {
      this.$emit("update:size", size);
    },

    updateVisibility(option) {
      this.$emit("update:visibility", option);
    },

    updatePlaceholder(placeholder) {
      this.$emit("update:placeholder", placeholder);
    },

    updateTooltip(tooltip) {
      this.$emit("update:tooltip", tooltip);
    },

    updateDividerType(type) {
      this.$emit("update:dividerType", type);
    },

    updateUrl(url) {
      this.$emit("update:url", url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
