<template>
  <div id="table-field">
    <div class="row items-center">
      <div class="col">
        <div class="row items-center">
          <span
            v-if="!openLabelInput && label"
            class="label"
            @click="handleOpenInput"
          >
            <FormFieldLabel
              v-if="label"
              :label="label"
              :is-mandatory="isMandatory"
              :tooltip="tooltip"
              :transform="transform"
            />
          </span>

          <input
            v-if="openLabelInput"
            ref="focusMe"
            :value="label"
            type="text"
            autofocus
            class="label1"
            :class="!labelError ? 'q-mb-sm' : ''"
            @input="handleLabelInput"
            @mouseleave="handleFocusOut(label)"
          />

          <BaseActionButton
            v-if="selectedRow.length"
            v-tooltip.top="'delete row'"
            is-flat
            color="red"
            icon="eva-trash-2-outline"
            class="col-auto q-mx-sm"
            no-border
            @click="deleteRow(row)"
          />

          <BaseActionButton
            v-if="selectedRow.length === 1"
            v-tooltip.top="'edit row'"
            is-flat
            color="secondary"
            icon="eva-edit-outline"
            no-border
            class="col-auto q-mr-sm"
            @click="editRow(selectedRow[0])"
          />
        </div>
      </div>
      <div v-if="hasFileUploadControl" class="col-auto">
        <div class="row">
          <BaseButton
            v-if="!hasFileUploadControl && !isReadonly"
            :label="tableLabel"
            class="col-auto q-mr-sm q-mb-sm"
            @click="addRow('handleClick')"
          />
          <BaseButton
            v-if="hasFileUploadControl && !isReadonly"
            label="Upload"
            class="col-auto q-mr-sm q-mb-sm"
            @click="addRow('handleClick')"
          />
          <BaseActionButton
            v-tooltip.bottom="'normal view'"
            is-flat
            no-border
            class="col-auto q-mr-sm"
            :icon="normalView ? 'eva-list-outline' : 'eva-list-outline'"
            :color="normalView ? 'secondary' : 'gray'"
            @click="getNormalView()"
          />
          <BaseActionButton
            v-tooltip.bottom="'Advance view'"
            is-flat
            no-border
            class="col-auto"
            :icon="advanceView ? 'eva-menu-2-outline' : 'eva-menu-2-outline'"
            :color="advanceView ? 'secondary' : 'gray'"
            @click="getAdvanceView()"
          />
        </div>
      </div>
    </div>

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <!-- table -->

    <BaseScrollbar class="q-pb-sm" :class="{ 'is-disabled': isDisabled }">
      <table v-if="normalView" id="normal-table">
        <thead>
          <tr>
            <th
              v-for="column in _tableColumns"
              :key="column.id"
              :class="getClass(column.size)"
            >
              {{ column.label }}
              <span
                v-if="column.settings.validation.fieldRule === 'REQUIRED'"
                class="required"
              >
                *
              </span>
              <BaseIcon
                v-if="column.settings.general.tooltip"
                v-tooltip.top="column.settings.general.tooltip"
                inherit-color
                name="eva-question-mark-circle"
                class="info-icon"
              />
            </th>
            <th
              v-if="
                tableRowsType === 'ON_DEMAND' &&
                !isReadonly &&
                !hasChangeTableRow.length
              "
              class="actions"
              :class="qrValue ? 'qr-icon' : ''"
            >
              <div class="row items-center justify-center">
                <!-- v-if="!qrValue" -->

                <BaseActionButton
                  v-if="!hasChangeTableRow.length"
                  v-tooltip.bottom="'add row'"
                  is-flat
                  color="secondary"
                  icon="eva-plus"
                  no-border
                  class="col-auto"
                  @click="addRow('handleClick')"
                />

                <BaseActionButton
                  v-if="qrValue && !isReadonly"
                  v-tooltip.bottom="'scan QR'"
                  is-flat
                  color="secondary"
                  icon="mdi-qrcode-scan"
                  no-border
                  class="col-auto"
                  @click="showQRRead = true"
                />
              </div>
            </th>
            <th
              v-if="tableRowsType === 'FIXED' && !isReadonly"
              class="actions"
              :class="qrValue ? 'qr-icon' : ''"
            >
              <div class="row items-center justify-center">
                <BaseActionButton
                  v-if="qrValue && !isReadonly"
                  v-tooltip.bottom="'scan QR'"
                  is-flat
                  color="secondary"
                  icon="mdi-qrcode-scan"
                  no-border
                  class="col-auto"
                  @click="showQRRead = true"
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, idx) in rows" :key="row">
            <td v-for="column in _tableColumns" :key="column.id">
              <!-- label -->

              <div
                v-if="column.type === 'LABEL'"
                :key="column.id"
                class="label"
              >
                {{ getLabel(idx, column.id) }}
              </div>

              <!-- ... -->

              <!-- label -->

              <div
                v-if="column.type === 'SERIAL_NUMBER'"
                :key="column.id"
                class="label"
              >
                {{ getSerialNumber(idx, column.id) }}
              </div>

              <!-- ... -->

              <!-- calculated field -->

              <CalculatedFieldWrapper
                v-else-if="column.type === 'CALCULATED'"
                :key="column.id"
                :value="tableCalculatedFieldsModel[idx][column.id]"
                :field="column"
              />

              <!-- ... -->

              <!-- calculated field -->

              <PopupFieldWrapper
                v-else-if="column.type === 'POPUP'"
                :ref="`popup - ${column.id}`"
                :key="column.id"
                v-model="tableFieldsModel[idx][column.id]"
                :field="column"
                :is-readonly="isReadonly"
                :secondary-panels="secondaryPanels"
                :form-settings="formSettings"
              />

              <!-- ... -->

              <div
                v-else-if="column.type === 'SELECTION'"
                class="row items-center justify-center"
                style="height: 46px"
              >
                <BaseIcon
                  v-model="tableFieldsModel[idx][column.id]"
                  :name="
                    tableFieldsModel[idx][column.id]
                      ? 'eva-checkmark-circle-2'
                      : 'eva-radio-button-off-outline'
                  "
                  :color="
                    tableFieldsModel[idx][column.id] ? 'secondary' : 'grey'
                  "
                  class="cursor-pointer"
                  @click="
                    selectRow(idx, column.id, tableFieldsModel[idx][column.id])
                  "
                />
              </div>

              <!-- other fields -->

              <component
                :is="getField(column.type)"
                v-else
                :key="column.id"
                v-model="tableFieldsModel[idx][column.id]"
                :field="column"
                :form-id="formId"
                :is-readonly="checkReadonly(idx, column.id)"
                :parent-control="checkParentControl(column.id)"
                :filter="tableFieldsModelOption[idx][column.id]?.filter"
                :filter-by="tableFieldsModelOption[idx][column.id]?.filterBy"
                :row-index="idx"
                :list-options="tableFieldsModelOption[idx][column.id]?.options"
                :form-settings="formSettings"
                :is-mandatory="checkMandatory(idx, column.id)"
                settings-for="TABLE"
                :lookup-filter="
                  tableFieldsModelOption[idx][column.id]?.lookupFilter
                "
                :lookup-filter-by="
                  tableFieldsModelOption[idx][column.id]?.lookupFilterBy
                "
                :lookup-parent-control="checkLookupParentControl(column.id)"
                :lookup-filter-in-table="lookupFilterInTable"
                @updateOptions="updateOptions"
                @filterBy="filterChildControlValues"
                @readonlyControls="setReadonlyControls"
                @mandatoryControls="setMandatoryControls"
                @uploadFiles="uploadFiles($event, idx)"
                @deletedFiles="deletedFiles($event, idx)"
                @showQr="showQr($event, idx)"
                @viewFile="viewFile"
                @lookupControlFilter="lookupControlFilter"
              />
              <!-- :is-mandatory="modelOption[idx][column.id]?.isMandatory" -->

              <!-- ... -->
            </td>

            <!-- actions -->

            <td
              v-if="
                tableRowsType === 'ON_DEMAND' &&
                !isReadonly &&
                !hasChangeTableRow.length
              "
            >
              <div class="row items-center justify-center" style="height: 46px">
                <BaseActionButton
                  is-flat
                  color="red"
                  icon="eva-trash-2-outline"
                  no-border
                  @click="deleteRow(row)"
                />
              </div>
            </td>

            <td v-if="tableRowsType === 'FIXED' && !isReadonly"></td>

            <!-- ... -->
          </tr>

          <tr v-if="showColumnTotal">
            <td
              v-for="column in tableColumns"
              :key="column.id"
              class="text-center"
            >
              <div v-if="column.settings.specific.showColumnTotal">
                <NumberField
                  :value="getTableColumnSum(column.id)"
                  is-readonly
                />
              </div>
            </td>
            <td v-if="tableRowsType === 'ON_DEMAND' && !isReadonly"></td>
          </tr>
        </tbody>
      </table>

      <AdvanceView
        v-if="advanceView"
        :table-columns="tableColumns"
        :rows="rows"
        :show-column-total="showColumnTotal"
        :table-rows-type="tableRowsType"
        :table-fixed-row-labels="tableFixedRowLabels"
        :table-fields-model="tableFieldsModel"
        :is-readonly="isReadonly"
        :repository-details="repositoryDetails"
        :selected-row.sync="selectedRow"
        :has-change-row="dummyData"
        @addRow="addRow('handleClick')"
        @deleteRow="deleteRow"
        @editRow="editRow"
      />
    </BaseScrollbar>

    <!-- ... -->

    <QRReader :value="showQRRead" :form-id="formId" @input="qrReadValue" />

    <FormFieldError v-if="error" :error="error" />

    <AddRow
      v-if="showAddRowModal"
      v-model="showAddRowModal"
      :columns="tableColumns"
      :add-type.sync="hasAddRow"
      :rows="tableFieldsModel"
      :row-index.sync="rowIndexInSheet"
      :repository-details="repositoryDetails"
      :is-readonly="isReadonly"
      :readonly-controls-list="readonlyControlsList"
      :mandatory-controls-list="mandatoryControlsList"
      :table-fields-model-option="tableFieldsModelOption"
      :form-settings="formSettings"
      @close="closeAddRowModal"
      @save="saveRow"
      @uploadFiles="uploadFiles($event, rowIndexInSheet)"
      @clearRow="clearRow"
      @updateOptions="updateOptions"
      @filterChildControlValues="filterChildControlValues"
      @setMandatoryControls="setMandatoryControls"
      @setReadonlyControls="setReadonlyControls"
      @deletedFiles="deletedFiles"
      @showQr="showQr"
      @saveQR="saveQR"
    />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import ShortTextFieldWrapper from "@/components/common/form/nested-fields/ShortTextFieldWrapper.vue";
import SingleSelectFieldWrapper from "@/components/common/form/nested-fields/SingleSelectFieldWrapper.vue";
import MultiSelectFieldWrapper from "@/components/common/form/nested-fields/MultiSelectFieldWrapper.vue";
import NumberFieldWrapper from "@/components/common/form/nested-fields/NumberFieldWrapper.vue";
import CounterFieldWrapper from "@/components/common/form/nested-fields/CounterFieldWrapper.vue";
import CalculatedFieldWrapper from "@/components/common/form/nested-fields/CalculatedFieldWrapper.vue";
import DateFieldWrapper from "@/components/common/form/nested-fields/DateFieldWrapper.vue";
import TimeFieldWrapper from "@/components/common/form/nested-fields/TimeFieldWrapper.vue";
import ChipsFieldWrapper from "@/components/common/form/nested-fields/ChipsFieldWrapper.vue";
import FileUploadFieldWrapper from "@/components/common/form/nested-fields/FileUploadFieldWrapper.vue";
import PopupFieldWrapper from "@/components/common/form/nested-fields/PopupFieldWrapper.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import QRReader from "@/components/common/QRReader.vue";
// import { form } from "@/api/factory.js";
import SingleChoiceFieldWrapper from "@/components/common/form/nested-fields/SingleChoiceFieldWrapper";
import MultipleChoiceFieldWrapper from "@/components/common/form/nested-fields/MultiChoiceFieldWrapper";
import ImageFieldWrapper from "@/components/common/form/nested-fields/ImageFieldWrapper";
import CurrencyAmountFieldWrapper from "@/components/common/form/nested-fields/CurrencyAmountFieldWrapper";
import PhoneNumberFieldWrapper from "@/components/common/form/nested-fields/PhoneNumberFieldWrapper.vue";

import {
  cloneDeep,
  isEmpty,
  lowerCase,
  upperFirst,
  camelCase,
} from "lodash-es";
import { evaluateFormula } from "@/helpers/formula.js";
import AdvanceView from "./AdvanceView.vue";
import AddRow from "./AddRow.vue";

export default {
  name: "TableField",

  components: {
    FormFieldLabel,
    FormFieldError,
    ShortTextFieldWrapper,
    NumberFieldWrapper,
    CounterFieldWrapper,
    CalculatedFieldWrapper,
    DateFieldWrapper,
    TimeFieldWrapper,
    ChipsFieldWrapper,
    SingleSelectFieldWrapper,
    MultiSelectFieldWrapper,
    FileUploadFieldWrapper,
    PopupFieldWrapper,
    NumberField,
    QRReader,
    SingleChoiceFieldWrapper,
    MultipleChoiceFieldWrapper,
    ImageFieldWrapper,
    AdvanceView,
    AddRow,
    CurrencyAmountFieldWrapper,
    PhoneNumberFieldWrapper,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    tableColumns: {
      type: Array,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    tableRowChooseType: {
      type: String,
      default: "",
    },

    tableRowsType: {
      type: String,
      default: "ON_DEMAND",
    },

    tableFixedRowCount: {
      type: Number,
      default: 0,
    },

    tableFixedRowLabels: {
      type: Array,
      default: () => [],
    },

    calculations: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },

    qrValue: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    hasMasterTableChange: {
      type: Boolean,
      default: false,
    },

    mandatoryRow: {
      type: Array,
      default: () => [],
    },

    hasChangeTableRow: {
      type: Array,
      default: () => [],
    },

    fieldsModel: {
      type: Object,
      default: () => {},
    },

    transform: {
      type: String,
      default: "transform",
    },

    repositoryDetails: {
      type: Object,
      default: () => {},
    },

    lookupFilterInTable: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFocused: false,
      rows: 0,
      tableFieldsModel: [],
      tableFieldsModelOption: [],
      tableChoice: [
        {
          id: this.$nano.id(),
          label: "",
          value: false,
        },
      ],
      showQRRead: false,
      autoDelete: false,
      copyArrayJson: "",
      openLabelInput: false,
      labelError: "",
      readonlyControlsList: [],
      mandatoryControlsList: [],
      modelOption: [],
      hasRowChange: false,
      normalView: true,
      advanceView: false,
      showAddRowModal: false,
      rowIndexInSheet: null,
      hasAddRow: false,
      selectedRow: [],
      dummyData: false,
      hasChangeNormalView: false,
    };
  },

  computed: {
    showColumnTotal() {
      for (let column of this.tableColumns) {
        if (column.settings.specific.showColumnTotal) {
          return true;
        }
      }

      return false;
    },

    tableCalculatedFieldsModel() {
      let model = cloneDeep(this.tableFieldsModel);
      model = model.map((row, idx) => {
        for (const column in row) {
          const key = column;
          const _column = this.tableColumns.find((column) => column.id === key);
          if (
            _column.type === "POPUP" &&
            _column.settings.specific.mappedFieldId
          ) {
            row[column] = this.$refs[`popup - ${key}`][idx].$children[0].label;
          }

          if (_column.type === "CALCULATED" && !isEmpty(this.calculations)) {
            const calculation = this.calculations.find(
              (_calculation) => _calculation.columnId === _column.id
            );
            if (calculation) {
              let calcValue = evaluateFormula(
                calculation.formula,
                row,
                this.tableColumns,
                this.value,
                idx,
                this.fieldsModel
              );
              row[column] =
                calcValue === 0 ? 0 : parseFloat(calcValue.toFixed(2));
              this.tableFieldsModel[idx][column] = row[column];
            }
          }
        }

        return row;
      });

      return model;
    },

    _tableColumns() {
      let tblColumns = [];
      this.tableColumns.forEach((col) => {
        if (col.settings.general.visibility !== "DISABLE") {
          tblColumns.push(col);
        }
      });
      return tblColumns;
    },

    tableLabel() {
      return `Add ${this.label}`;
    },

    hasFileUploadControl() {
      let origin = location.origin;
      if (
        ((origin === "https://trial.ezofis.com" ||
          origin === "http://localhost:8080") &&
          this.$store.state.session.tenantId === 23) ||
        (origin === "https://app.ezofis.com" &&
          this.$store.state.session.tenantId === 7) ||
        this.$store.state.session.tenantId === 1
      ) {
        let data = this.tableColumns.filter(
          (item) => item.type === "FILE_UPLOAD"
        );
        if (data.length) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    tableFieldsModel: {
      deep: true,
      handler() {
        let ocrControl = this.readonlyControlsList.filter(
          (item) => item.tableControl
        );

        ocrControl.forEach((ocr) => {
          if (this.tableFieldsModel[ocr.rowIndex]) {
            let upload = this.tableFieldsModel[ocr.rowIndex][ocr.uploadFieldId];
            if (upload.length && ocr.isReadonly) {
              // console.log(upload, ocr);
              // console.log(upload[ocr.fileIndex]);
              if (
                upload[ocr.fileIndex] &&
                Object.prototype.hasOwnProperty.call(
                  upload[ocr.fileIndex],
                  "fileId"
                )
              ) {
                if (upload[ocr.fileIndex].fileId) {
                  ocr.isReadonly = false;
                }
              }
            }
          }
        });
        this.$emit("input", this.tableFieldsModel);
      },
    },

    tableColumns: {
      deep: true,
      handler: "resetModel",
    },

    tableRowsType() {
      this.addRows();
    },

    tableFixedRowCount() {
      if (this.tableFixedRowCount > this.rows) {
        const rowsToAdd = this.tableFixedRowCount - this.rows;

        for (let i = 1; i <= rowsToAdd; i++) {
          this.addRow();
        }
      }

      if (this.tableFixedRowCount < this.rows) {
        const rowsToRemove = this.rows - this.tableFixedRowCount;

        for (let i = 1; i <= rowsToRemove; i++) {
          this.rows--;
          this.tableFieldsModel.pop();
        }
      }
    },

    hasMasterTableChange: {
      handler() {
        if (this.value.length) {
          this.tableFieldsModel = cloneDeep(this.value);
          this.rows = this.value.length;
          const modelOption = {};
          const settings = {};
          this.tableFieldsModelOption = [];
          this.modelOption = [];
          this.tableFieldsModel.forEach((field) => {
            console.log(field);
            for (let column of this.tableColumns) {
              modelOption[column.id] = {
                id: column.id,
                filterBy: [],
                filter: 0,
                options: [],
              };
              settings[column.id] = {
                id: column.id,
                isReadonly: false,
                isMandatory: false,
              };
            }
            this.tableFieldsModelOption.push(modelOption);
            this.modelOption.push(settings);
          });
          // return;
          // this.modelOption.forEach((row, index) => {
          //   console.log(row, index);
          //   console.log(this.mandatoryRow);
          //   for (let col in row) {
          //     let columns = this.mandatoryRow.find((item) => item[col] === col);
          //     console.log(columns, col);
          //     if (columns) {
          //       console.log(columns[col], "col");

          //       this.modelOption[index][columns.col].isMandatory = true;
          //     }
          //   }
          // });

          this.tableFieldsModel.forEach((row, index) => {
            if (this.tableColumns.length === this._tableColumns.length) {
              if (index === 0) {
                for (let col in row) {
                  let columns = this.tableColumns.find(
                    (item) => item.id === col
                  );
                  // console.log(columns);
                  let settings = columns.settings.validation.mandatorySettings;
                  let settings1 = columns.settings.validation.readonlySettings;
                  if (settings && settings.length) {
                    // console.log(row[col], "row[col]");
                    let rowValue = typeof row[col] === "string";
                    if (rowValue) {
                      var controls = settings.find(
                        (control) => control.value.trim() === row[col].trim()
                      );
                    }
                    // console.log(controls);
                    if (controls) {
                      this.setMandatoryControls(
                        controls.controls,
                        settings,
                        "immediate",
                        columns.id,
                        index
                      );
                    } else {
                      this.setMandatoryControls(
                        [],
                        settings,
                        "immediate",
                        columns.id,
                        index
                      );
                    }
                  }
                  if (settings1 && settings1.length) {
                    let rowValue = typeof row[col] === "string";
                    if (rowValue) {
                      var controls1 = settings1.find(
                        (control) => control.value.trim() === row[col].trim()
                      );
                    }
                    // console.log(controls, "read");
                    if (controls1) {
                      this.setReadonlyControls(
                        controls1.controls,
                        settings,
                        "immediate",
                        columns.id,
                        index
                      );
                    } else {
                      this.setReadonlyControls(
                        [],
                        settings,
                        "immediate",
                        columns.id,
                        index
                      );
                    }
                  }
                }
              }
            } else {
              for (let col in row) {
                let columns = this.tableColumns.find((item) => item.id === col);
                // console.log(columns);
                let settings = columns.settings.validation.mandatorySettings;
                let settings1 = columns.settings.validation.readonlySettings;
                if (settings && settings.length) {
                  // console.log(row[col], "row[col]");
                  let rowValue = typeof row[col] === "string";
                  if (rowValue) {
                    var controls2 = settings.find(
                      (control) => control.value.trim() === row[col].trim()
                    );
                  }
                  // console.log(controls);
                  if (controls2) {
                    this.setMandatoryControls(
                      controls2.controls,
                      settings,
                      "immediate",
                      columns.id,
                      index
                    );
                  } else {
                    this.setMandatoryControls(
                      [],
                      settings,
                      "immediate",
                      columns.id,
                      index
                    );
                  }
                }
                if (settings1 && settings1.length) {
                  let rowValue = typeof row[col] === "string";
                  if (rowValue) {
                    var controls3 = settings1.find(
                      (control) => control.value.trim() === row[col].trim()
                    );
                  }
                  // console.log(controls, "read");
                  if (controls3) {
                    this.setReadonlyControls(
                      controls3.controls,
                      settings,
                      "immediate",
                      columns.id,
                      index
                    );
                  } else {
                    this.setReadonlyControls(
                      [],
                      settings,
                      "immediate",
                      columns.id,
                      index
                    );
                  }
                }
              }
            }
          });
        } else {
          this.tableFieldsModel = [];
          this.rows = this.value.length;

          this.tableFieldsModelOption = [];
          this.modelOption = [];

          // if (!this.qrValue) {
          //   this.addRows();
          // }
          this.addRows();
        }
      },
    },

    hasChangeTableRow: {
      handler() {
        if (this.hasChangeTableRow.length) {
          this.tableFieldsModel = cloneDeep(this.hasChangeTableRow);
          this.rows = this.hasChangeTableRow.length;
          const modelOption = {};
          const settings = {};
          this.tableFieldsModel.forEach((field) => {
            console.log(field);
            for (let column of this.tableColumns) {
              modelOption[column.id] = {
                id: column.id,
                filterBy: [],
                filter: 0,
                options: [],
              };

              settings[column.id] = {
                id: column.id,
                isMandatory: false,
                isReadonly: false,
              };
            }
            this.tableFieldsModelOption.push(modelOption);
            this.modelOption.push(settings);
          });
        }
      },
    },

    hasFileUploadControl: {
      immediate: true,
      handler() {
        if (this.hasFileUploadControl) {
          this.advanceView = true;
          this.normalView = false;
        }
      },
    },

    lookupFilterInTable: {
      immediate: true,
      handler() {
        console.log(this.lookupFilterInTable, "lookupFilterInTable");
      },
    },
  },

  created() {
    if (this.value.length) {
      this.tableFieldsModel = cloneDeep(this.value);
      this.rows = this.value.length;
      this.tableFieldsModel.forEach((field) => {
        console.log(field);
        const modelOption = {};
        const settings = {};
        for (let column of this.tableColumns) {
          modelOption[column.id] = {
            id: column.id,
            filterBy: [],
            filter: 0,
            options: [],
          };

          settings[column.id] = {
            id: column.id,
            isMandatory: false,
            isReadonly: false,
          };
        }
        this.tableFieldsModelOption.push(modelOption);
        this.modelOption.push(settings);
      });
      return;
    }
    // if (!this.qrValue) {
    //   this.addRows();
    // }
    this.addRows();
  },

  methods: {
    getClass(columnSize) {
      return lowerCase(columnSize);
    },

    addRows() {
      this.tableFieldsModel = [];
      this.rows = 0;

      if (this.tableRowsType === "FIXED" && this.tableFixedRowCount) {
        for (let i = 1; i <= this.tableFixedRowCount; i++) {
          this.addRow();
        }
        return;
      }

      this.addRow();
    },

    getField(fieldType) {
      return `${upperFirst(camelCase(fieldType))}FieldWrapper`;
    },

    getInitialValue(columnType) {
      // console.log(columnType);
      switch (columnType) {
        case "MULTI_SELECT":
          return [];
        case "FILE_UPLOAD":
        case "CHIPS":
          return [];
        case "COUNTER":
        case "CALCULATED":
          return 0;
        case "POPUP":
          return {};
        case "SELECTION":
          return false;
        default:
          return "";
      }
    },

    addModel() {
      const model = {};
      const modelOption = {};
      const settings = {};

      for (let column of this.tableColumns) {
        // model[column.id] = this.getInitialValue(column.type);
        model[column.id] = this.getDefaultValue(column);
        modelOption[column.id] = {
          id: column.id,
          filterBy: [],
          filter: 0,
          options: [],
        };

        settings[column.id] = {
          id: column.id,
          isMandatory: false,
          isReadonly: false,
        };
      }

      this.tableFieldsModel.push(model);
      this.tableFieldsModelOption.push(modelOption);
      this.modelOption.push(settings);
    },

    resetModel() {
      this.tableFieldsModel = [];

      for (let i = 1; i <= this.rows; i++) {
        this.addModel();
      }
    },

    addRow(handleClick) {
      if (this.hasFileUploadControl) {
        if (handleClick) {
          this.showAddRowModal = true;
          this.hasAddRow = true;
          // if (!this.rows) {
          this.rowIndexInSheet = !this.rows ? 0 : this.rows;
          // }

          // if(!this.rows) {[[[]
          //   this.rowIndexInSheet = 0;
          // } else {
          //   this.rowIndexInSheet = this.rows;
          // }
        } else {
          //   this.rows++;
          // this.addModel();
        }
      } else {
        this.rows++;
        this.addModel();
      }
    },

    deleteRow(row, fileFieldId) {
      const rowIdx = row - 1;
      console.log(rowIdx, fileFieldId, this.tableFieldsModel[rowIdx], "delete");

      if (fileFieldId && this.tableFieldsModel[rowIdx]) {
        let hasFile = this.tableFieldsModel[rowIdx][fileFieldId];
        if (hasFile.length) {
          let deleteFiles = [];
          hasFile.forEach((file) => {
            if (file.itemId) {
              deleteFiles.push({
                id: file.itemId,
                jsonId: fileFieldId,
                index: 0,
              });
            } else {
              deleteFiles.push({
                id: file.fileId,
                jsonId: fileFieldId,
                index: 0,
                initiate: true,
              });
            }
          });
          let emitValue = {
            deletedFiles: deleteFiles,
            settings: "",
          };
          this.deletedFiles(emitValue, rowIdx);
        }
        let ocrControl = this.readonlyControlsList.filter(
          (item) => item.tableControl
        );

        ocrControl.forEach((ocr) => {
          if (this.tableFieldsModel[ocr.rowIndex]) {
            let upload = this.tableFieldsModel[ocr.rowIndex][ocr.uploadFieldId];
            if (upload.length && ocr.isReadonly) {
              // console.log(upload, ocr);
              // console.log(upload[ocr.fileIndex]);
              if (
                upload[ocr.fileIndex] &&
                Object.prototype.hasOwnProperty.call(
                  upload[ocr.fileIndex],
                  "fileId"
                )
              ) {
                if (upload[ocr.fileIndex].fileId) {
                  ocr.isReadonly = false;
                }
              }
            }
          }
        });
      }
      this.tableFieldsModel.splice(rowIdx, 1);
      this.tableFieldsModelOption.splice(rowIdx, 1);
      // let table = [];
      // this.tableFieldsModel.forEach((row, index) => {
      //   table.push(row);
      //   this.tableColumns.forEach((column) => {
      //     if (this.checkParentControl(column.id)) {
      //       this.filterChildControlValues(column, index);
      //     }
      //   });
      // });
      // this.tableFieldsModel = [];
      // this.tableFieldsModel = table;
      this.modelOption.splice(rowIdx, 1);
      this.rows--;
      let mandatory = this.mandatoryControlsList.findIndex((item) => {
        return item.rowIndex === rowIdx;
      });
      if (mandatory >= 0) this.mandatoryControlsList.splice(mandatory, 1);
      let readonly = this.readonlyControlsList.findIndex((item) => {
        return item.rowIndex === rowIdx;
      });
      if (readonly >= 0) this.readonlyControlsList.splice(readonly, 1);
    },

    getLabel(rowIdx, columnId) {
      if (isEmpty(this.tableFixedRowLabels)) {
        return "";
      }

      return this.tableFixedRowLabels[rowIdx][columnId];
    },

    getTableColumnSum(columnId) {
      if (!this.tableFieldsModel.length) {
        return 0;
      }
      let rows = this.tableFieldsModel.filter((row) => row[columnId]).length;
      if (rows) {
        if (
          this.copyArrayJson !== JSON.stringify(this.tableFieldsModel) &&
          !this.autoDelete
        ) {
          this.autoDelete = true;
          this.addRow();
        } else if (this.autoDelete && this.tableFieldsModel.length > 1) {
          this.autoDelete = false;

          if (!this.hasChangeNormalView) {
            this.deleteRow(this.tableFieldsModel.length);
          }
          this.copyArrayJson = JSON.stringify(this.tableFieldsModel);
        }
      }
      // console.log(columnId, rows);
      let type = this.tableColumns.find((col) => col.id === columnId);
      if (type.type === "CURRENCY_AMOUNT") {
        let value = 0;

        this.tableFieldsModel.forEach((row) => {
          if (!isEmpty(row[columnId])) {
            if (row[columnId].value) {
              let currency = row[columnId].value;
              let value1 = currency.replace(new RegExp(",", "g"), "");
              // console.log(value1, Number(value1), "value1");
              value += Number(value1);
            }
          }
        });
        // console.log(value);
        return value;
        //   return this.tableFieldsModel.reduce(
        //   (acc, cur) => acc + Number(cur[columnId]),
        //   0
        // );
      }
      return this.tableFieldsModel.reduce(
        (acc, cur) => acc + Number(cur[columnId]),
        0
      );
    },

    selectRow(rowIndex, columnId, value) {
      if (this.tableRowChooseType === "SINGLE_ROW") {
        this.tableFieldsModel.forEach((row) => {
          row[columnId] = false;
        });
      }
      this.tableFieldsModel[rowIndex][columnId] = !value;
    },

    checkParentControl(fieldId) {
      let parentFieldFound = false;
      this.tableColumns.forEach((field) => {
        if (field.settings.specific.masterFormParentColumn) {
          if (fieldId === field.settings.specific.masterFormParentColumn) {
            parentFieldFound = true;
            return;
          }
        }
      });
      this.tableColumns.forEach((field) => {
        if (field.settings.specific.repositoryFieldParent) {
          if (fieldId === field.settings.specific.repositoryFieldParent) {
            parentFieldFound = true;
            return;
          }
        }
      });
      return parentFieldFound;
    },

    filterChildControlValues(field, rowIndex, value, isManualChange) {
      this.tableColumns.filter((rowField) => {
        if (rowField.settings.specific.masterFormParentColumn) {
          if (field.id === rowField.settings.specific.masterFormParentColumn) {
            this.tableFieldsModelOption[rowIndex][rowField.id].filterBy = [
              {
                criteria: field.settings.specific.masterFormColumn,
                condition: "IS_EQUALS_TO",
                value: value || this.tableFieldsModel[rowIndex][field.id],
                dataType: "",
              },
            ];
            this.tableFieldsModelOption[rowIndex][rowField.id].filter =
              this.tableFieldsModelOption[rowIndex][rowField.id].filter + 1;
            rowField.isManualChange = isManualChange;
          }
        } else if (rowField.settings.specific.repositoryFieldParent) {
          if (field.id === rowField.settings.specific.repositoryFieldParent) {
            this.tableFieldsModelOption[rowIndex][rowField.id].filterBy = [
              {
                criteria: field.settings.specific.repositoryField,
                condition: "IS_EQUALS_TO",
                value: this.tableFieldsModel[rowIndex][field.id],
                dataType: "",
              },
            ];
            this.tableFieldsModelOption[rowIndex][rowField.id].filter =
              this.tableFieldsModelOption[rowIndex][rowField.id].filter + 1;
            rowField.isManualChange = isManualChange;
          }
        }
      });
    },

    updateOptions(field, rowIndex, options) {
      this.tableFieldsModelOption[rowIndex][field.id].options = options;
    },

    async qrReadValue(qrData) {
      this.showQRRead = false;
      if (qrData) {
        const qrObj = JSON.parse(qrData);
        let filter = [];
        for (const keyName in qrObj) {
          if (keyName !== "URL") {
            let col = this.tableColumns.find((row) => row.label === keyName);
            if (col) {
              filter.push({
                criteria: col.id,
                condition: "IS_EQUALS_TO",
                value: qrObj[keyName],
              });
            }
          }
        }
        // this.$store.commit("showLoadingBar");

        // const { error, payload } = await form.getFormEntries(this.formId, {
        //   filterBy: [{ filters: filter }],
        // });

        // this.$store.commit("hideLoadingBar");

        // if (error) {
        //   this.$alert.error(error);
        //   return;
        // }
        // const { data } = payload;
        // console.log(data);
        // if (data.length) {
        //   this.$alert.info("Already used");
        // } else {
        let idx = this.tableFieldsModel.length - 1;
        this.tableFieldsModel.forEach((row) => {
          for (const column in row) {
            const key = column;
            const _column = this.tableColumns.find(
              (column) => column.id === key
            );
            for (const keyName in qrObj) {
              if (_column.label === keyName) {
                if (
                  _column.type === "NUMBER" ||
                  _column.type === "CALCULATED"
                ) {
                  this.tableFieldsModel[idx][column] = Number(qrObj[keyName]);
                } else {
                  this.tableFieldsModel[idx][column] = qrObj[keyName];
                }
              }
            }
          }
        });
        if (this.tableRowsType === "ON_DEMAND") {
          this.addRow();
        }
        // }
      }
    },

    getDefaultValue(field, files) {
      const defaultValue = field.settings.specific.defaultValue;

      if (defaultValue === "USER_EMAIL") {
        return this.$store.state.session.email;
      }

      if (defaultValue === "USER_NAME") {
        let name = "";
        if (this.$store.state.session.firstName) {
          name = this.$store.state.session.firstName;
        }
        if (this.$store.state.session.lastName) {
          name += " " + this.$store.state.session.lastName;
        }
        return name;
      }

      if (defaultValue === "LOGIN_NAME") {
        let name = "";
        if (this.$store.state.session.loginName) {
          name = this.$store.state.session.loginName;
        }
        return name;
      }

      if (defaultValue === "CURRENT_DATE") {
        return this.$day.newDate();
      }

      if (defaultValue === "CURRENT_TIME") {
        return this.$day.newTime();
      }

      if (defaultValue === "CURRENT_DATE_TIME") {
        return this.$day.newDateTime();
      }

      if (defaultValue === "AUTO_GENERATE") {
        const prefix = field.settings.specific.autoGenerateValue.prefix;
        const suffixFormat = field.settings.specific.autoGenerateValue.suffix;

        const suffixFormats = {
          DATE_TIME: "YYYYMMDDhhmmss",
          DATE: "YYYYMMDD",
          TIME: "hhmmss",
        };

        const suffix = this.$day.customFormat(suffixFormats[suffixFormat]);

        return `${prefix} - ${suffix}`;
      }
      if (defaultValue === "CUSTOM") {
        return (
          field.settings.specific.customDefaultValue ||
          this.getEmptyDefaultValue(field.type, files)
        );
      }
    },

    getEmptyDefaultValue(fieldType, files) {
      switch (fieldType) {
        case "MULTI_SELECT":
          return [];
        case "TABLE" && files:
          return files;
        case "MULTIPLE_CHOICE":
        case "FILE_UPLOAD":
        case "IMAGE_UPLOAD":
        case "TABLE" && !files:
        case "MATRIX":
        case "CHIPS":
        case "NESTED_LIST":
          return [];
        case "COUNTER":
        case "CALCULATED":
        case "RATING":
          return 0;
        case "FILL_IN_THE_BLANKS":
        case "POPUP":
        case "TABS":
          return {};
        case "CURRENCY_AMOUNT":
          return {
            currency: "",
            value: "",
          };
        case "PHONE_NUMBER":
          return {
            code: "",
            phoneNo: "",
          };
        default:
          return "";
      }
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    checkReadonly(index, fieldId) {
      if (this.isReadonly) {
        return true;
      } else {
        if (this.readonlyControlsList.length) {
          let readonly = this.readonlyControlsList.find(
            (list) => list.rowIndex === index && list.id === fieldId
          );
          // console.log(readonly);
          if (readonly) {
            return readonly.isReadonly;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },

    setReadonlyControls(controls, settings, immediate, fieldId, rowIndex) {
      //console.log(controls, settings, immediate, fieldId, rowIndex);
      if (controls.length) {
        controls.forEach((item) => {
          let columns = this.tableColumns.findIndex((column) => {
            return column.id === item;
          });
          // console.log(columns, "index");
          if (columns > -1) {
            // this.tableFieldsModelOption[rowIndex][item].isReadOnly = true;
            // this.readonlyControlsList.push({
            //   rowIndex: rowIndex,
            //   fieldsIndex: columns,
            //   id: item,
            //   fieldId: fieldId,
            // });
            if (this.readonlyControlsList.length) {
              let readonly = this.readonlyControlsList.find(
                (list) =>
                  list.rowIndex === rowIndex &&
                  list.fieldId === fieldId &&
                  list.id === item
              );

              if (!readonly) {
                this.readonlyControlsList.push({
                  rowIndex: rowIndex,
                  fieldsIndex: columns,
                  id: item,
                  fieldId: fieldId,
                  isReadonly: true,
                });
              } else {
                readonly.isReadonly = true;
              }
            } else {
              this.readonlyControlsList.push({
                rowIndex: rowIndex,
                fieldsIndex: columns,
                id: item,
                fieldId: fieldId,
                isReadonly: true,
              });
            }
            this.tableFieldsModel[rowIndex][item] = "";
          }
        });
      } else {
        if (this.readonlyControlsList.length) {
          this.readonlyControlsList.forEach((control, index) => {
            // console.log(control.fieldId, control.rowIndex);
            if (fieldId === control.fieldId && rowIndex === control.rowIndex) {
              // this.tableFieldsModelOption[rowIndex][
              //   control.id
              // ].isReadOnly = false;

              this.readonlyControlsList[index].isReadonly = false;
            }
          });
          // this.readonlyControlsList = [];
        }
      }
    },

    setMandatoryControls(controls, settings, immediate, fieldId, rowIndex) {
      //console.log(controls, settings, immediate, fieldId, rowIndex);
      if (controls.length) {
        controls.forEach((item) => {
          let columns = this.tableColumns.findIndex((column) => {
            return column.id === item;
          });

          if (columns > -1) {
            // this.modelOption[rowIndex][item].isMandatory = true;
            if (this.mandatoryControlsList.length) {
              let mandatory = this.mandatoryControlsList.find(
                (list) => list.rowIndex === rowIndex && list.fieldId === fieldId
              );
              // console.log(mandatory);
              if (!mandatory) {
                this.mandatoryControlsList.push({
                  rowIndex: rowIndex,
                  fieldsIndex: columns,
                  id: item,
                  fieldId: fieldId,
                  isMandatory: true,
                });
              } else {
                mandatory.isMandatory = true;
              }
            } else {
              this.mandatoryControlsList.push({
                rowIndex: rowIndex,
                fieldsIndex: columns,
                id: item,
                fieldId: fieldId,
                isMandatory: true,
              });
            }
          }
        });
      } else {
        if (this.mandatoryControlsList.length) {
          this.mandatoryControlsList.forEach((control, index) => {
            if (fieldId === control.fieldId && rowIndex === control.rowIndex) {
              // this.modelOption[rowIndex][control.id].isMandatory = false;
              // this.mandatoryControlsList.splice(index, 1);
              this.mandatoryControlsList[index].isMandatory = false;
            }
          });
        }
      }
    },

    checkMandatory(index, fieldId) {
      if (this.mandatoryControlsList.length) {
        let mandatory = this.mandatoryControlsList.find(
          (list) => list.rowIndex === index && list.id === fieldId
        );
        // console.log(mandatory);
        if (mandatory) {
          return mandatory.isMandatory;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    getSerialNumber(idx, columnId) {
      this.tableFieldsModel[idx][columnId] = idx + 1;
      return idx + 1;
    },

    showQr(id, index) {
      let emitValue = {
        fieldId: id,
        rowIndex: index,
      };
      this.$emit("showQr", emitValue);
    },

    deletedFiles(ids, index) {
      let emitValue = {
        fileIds: ids.deletedFiles,
        settings: ids.settings,
        rowIndex: index,
      };
      console.log(ids, "settings");
      if (ids.settings.controls) {
        ids.settings.controls.forEach((id) => {
          this.tableFieldsModel[index][id] = "";

          let indexed = this.readonlyControlsList.findIndex((item) => {
            return item.id === id && item.tableControl;
          });
          if (indexed > -1) {
            this.readonlyControlsList.splice(indexed, 1);
          }
        });
      }
      this.$emit("deletedFiles", emitValue);
    },

    uploadFiles(files, index) {
      let emitValue = {
        files: files,
        rowIndex: index,
      };
      if (files.assignControls) {
        if (files.assignControls.length) {
          let attachments = this.tableFieldsModel[index][files.fieldId];
          // console.log(attachments, "attachments");
          attachments.forEach((file, fileIndex) => {
            files.assignControls.forEach((fieldId) => {
              this.readonlyControlsList.push({
                rowIndex: index,
                id: fieldId,
                isReadonly: true,
                tableControl: true,
                uploadFieldId: files.fieldId,
                fileIndex: fileIndex,
              });
            });
          });
        }
      }

      this.$emit("uploadFiles", emitValue);
    },

    viewFile(file) {
      this.$emit("viewFile", file);
    },

    saveQR(row) {
      if (row) {
        this.rows++;
        const modelOption = {};
        const settings = {};

        for (let column of this.tableColumns) {
          if (column.type !== "FILE_UPLOAD") {
            if (!row[column.id]) {
              row[column.id] = this.getDefaultValue(column);
            }
          }

          modelOption[column.id] = {
            id: column.id,
            filterBy: [],
            filter: 0,
            options: [],
          };

          settings[column.id] = {
            id: column.id,
            isMandatory: false,
            isReadonly: false,
          };
        }
        this.tableFieldsModel[this.rowIndexInSheet] = row;
        this.tableFieldsModelOption.push(modelOption);
        this.modelOption.push(settings);
      }
    },

    saveRow(row, files) {
      // console.log("row", row, files, this.rowIndexInSheet);
      let proceed = true;

      this.dummyData = !this.dummyData;
      if (
        files &&
        this.tableFieldsModel.length &&
        this.tableFieldsModel[this.rowIndexInSheet] &&
        this.tableFieldsModel[this.rowIndexInSheet][files.fieldId].length
      ) {
        this.advanceView = false;
        // console.log(
        //   "proceed",
        //   this.tableFieldsModel[this.rowIndexInSheet],
        //   row
        // );
        proceed = false;
        this.tableFieldsModel[this.rowIndexInSheet] = row;

        this.advanceView = true;
        this.dummyData = !this.dummyData;
      }
      if (proceed) {
        this.rows++;
        const model = {};
        const modelOption = {};
        const settings = {};

        for (let column of this.tableColumns) {
          // model[column.id] = this.getInitialValue(column.type);

          if (files) {
            model[column.id] = this.getDefaultValue(column, files);
          } else {
            if (column.type !== "FILE_UPLOAD") {
              row[column.id] = this.getDefaultValue(column);
            }
          }

          modelOption[column.id] = {
            id: column.id,
            filterBy: [],
            filter: 0,
            options: [],
          };

          settings[column.id] = {
            id: column.id,
            isMandatory: false,
            isReadonly: false,
          };
        }

        this.tableFieldsModel.push(row);
        this.tableFieldsModelOption.push(modelOption);
        this.modelOption.push(settings);
      }
    },

    editRow(index) {
      console.log(index);
      this.rowIndexInSheet = index.index;
      this.showAddRowModal = true;
      this.hasAddRow = false;
    },

    async clearRow(fileFieldId, rowIndex) {
      // console.log(fileFieldId, rowIndex);
      if (fileFieldId && rowIndex) {
        // rowIndex.forEach((number) => {

        for (let rowIdx of rowIndex) {
          if (fileFieldId && this.tableFieldsModel[rowIdx]) {
            let hasFile = this.tableFieldsModel[rowIdx][fileFieldId];
            let deleteFiles = [];
            if (hasFile.length) {
              hasFile.forEach((file) => {
                if (file.itemId) {
                  deleteFiles.push({
                    id: file.itemId,
                    jsonId: fileFieldId,
                    index: 0,
                  });
                } else {
                  deleteFiles.push({
                    id: file.fileId,
                    jsonId: fileFieldId,
                    index: 0,
                    initiate: true,
                  });
                }
              });
            }
            let ocrControl = this.readonlyControlsList.filter(
              (item) => item.tableControl
            );

            ocrControl.forEach((ocr) => {
              if (this.tableFieldsModel[ocr.rowIndex]) {
                let upload =
                  this.tableFieldsModel[ocr.rowIndex][ocr.uploadFieldId];
                if (upload.length && ocr.isReadonly) {
                  // console.log(upload, ocr);
                  // console.log(upload[ocr.fileIndex]);
                  if (
                    upload[ocr.fileIndex] &&
                    Object.prototype.hasOwnProperty.call(
                      upload[ocr.fileIndex],
                      "fileId"
                    )
                  ) {
                    if (upload[ocr.fileIndex].fileId) {
                      ocr.isReadonly = false;
                    }
                  }
                }
              }
            });

            let emitValue = {
              fileIds: deleteFiles,
              settings: "",
              rowIndex: rowIdx,
            };
            this.$emit("deletedFiles", emitValue);
          }
          // this.deletedFiles(emitValue, rowIdx);

          let mandatory = this.mandatoryControlsList.findIndex((item) => {
            return item.rowIndex === rowIdx;
          });
          if (mandatory >= 0) this.mandatoryControlsList.splice(mandatory, 1);
          let readonly = this.readonlyControlsList.findIndex((item) => {
            return item.rowIndex === rowIdx;
          });
          if (readonly >= 0) this.readonlyControlsList.splice(readonly, 1);
        }

        rowIndex.forEach((rowIdx) => {
          this.tableFieldsModel.splice(rowIdx, 1);
          this.tableFieldsModelOption.splice(rowIdx, 1);

          this.modelOption.splice(rowIdx, 1);
          this.rows--;
        });
      }
    },

    lookupControlFilter(field, rowIndex) {
      this.tableColumns.filter((rowField) => {
        console.log(rowField.settings.lookupSettings, rowField.label);
        if (
          rowField.settings.lookupSettings &&
          rowField.settings.lookupSettings.parentField
        ) {
          if (field.id === rowField.settings.lookupSettings.parentField) {
            if (
              rowField.settings.lookupSettings &&
              rowField.settings.lookupSettings.columnName
            ) {
              let filter = this.tableColumns.find(
                (lookup) => lookup.id === field.id
              );
              console.log(filter, rowField.label, "rowField.label");
              if (
                filter &&
                filter.settings.lookupSettings &&
                filter.settings.lookupSettings.columnName
              ) {
                this.tableFieldsModelOption[rowIndex][
                  rowField.id
                ].lookupFilterBy = [
                  {
                    criteria: filter.settings.lookupSettings.columnName,
                    condition: "IS_EQUALS_TO",
                    value: this.tableFieldsModel[rowIndex][field.id],
                    dataType: filter.type,
                  },
                ];
                this.tableFieldsModelOption[rowIndex][
                  rowField.id
                ].lookupFilter = this.$nano.id();
              }
            }
          }
        }
      });
    },

    checkLookupParentControl(fieldId) {
      let parentFieldFound = false;
      this.tableColumns.forEach((field) => {
        if (
          field.settings.lookupSettings &&
          field.settings.lookupSettings.parentField
        ) {
          if (fieldId === field.settings.lookupSettings.parentField) {
            parentFieldFound = true;
            return;
          }
        }
      });

      return parentFieldFound;
    },

    getNormalView() {
      this.advanceView = false;
      this.hasChangeNormalView = true;
      this.normalView = true;
    },

    getAdvanceView() {
      this.advanceView = true;

      this.normalView = false;
    },

    closeAddRowModal() {
      this.showAddRowModal = false;
      this.dummyData = !this.dummyData;
    },
  },
};
</script>

<style lang="scss">
#table-field {
  #normal-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 140px;
      }

      &.large {
        width: 210px;
      }

      &.actions {
        width: 48px;
        &.qr-icon {
          width: 60px;
        }
      }

      .required {
        color: red;
        margin-left: 3px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
    }

    td {
      padding: 4px;
      vertical-align: top;
    }

    td .label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 4px;
      color: var(--icon-color);
    }
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }

  .info-icon {
    margin-left: 8px;
    cursor: pointer;
    color: var(--icon-color-inverted);
    margin-top: -2px;
  }
}
</style>
