<template>
  <div id="notification-builder">
    <!-- Notifications -->

    <FormFieldLabel label="Notifications" is-mandatory />

    <div class="options-wrapper">
      <div class="row items-center">
        <div class="col-5 q-mr-sm">
          <CheckboxField
            v-model="value.acknowledgement.notify"
            label="Acknowledgement to actors"
            description="Send an acknowledgement to the actor"
            class="field"
          />
        </div>
        <div v-if="value.acknowledgement.notify" class="col-auto">
          <BaseActionButton
            v-tooltip.top="'settings'"
            is-flat
            no-border
            icon="eva-settings-outline"
            color="gray"
            @click="acknowledgeSettings()"
          >
          </BaseActionButton>
        </div>
      </div>

      <div class="row items-center">
        <div class="col-5 q-mr-sm">
          <CheckboxField
            v-model="value.inboxNotification.notify"
            label="Notify respondents"
            description="Send a notification to the respondents"
            class="field"
          />
        </div>
        <div v-if="value.inboxNotification.notify" class="col-auto">
          <BaseActionButton
            v-tooltip.top="'settings'"
            is-flat
            no-border
            icon="eva-settings-outline"
            color="gray"
            @click="notifySettings()"
          >
          </BaseActionButton>
        </div>
      </div>

      <div class="row items-center">
        <div class="col-5 q-mr-sm">
          <CheckboxField
            v-model="value.markCCField.notify"
            label="Mark CC"
            description="Send a notification to the mark cc"
            class="field"
          />
        </div>
        <div v-if="value.markCCField.notify" class="col-auto">
          <BaseActionButton
            v-tooltip.top="'settings'"
            is-flat
            no-border
            icon="eva-settings-outline"
            color="gray"
            @click="showMarkCCFieldSettings = true"
          >
          </BaseActionButton>
        </div>
      </div>

      <div class="row items-center">
        <div class="col-5 q-mr-sm">
          <CheckboxField
            v-model="value.processStage.notify"
            label="Process Stage"
            description="Send a notification to the process stage"
            class="field"
          />
        </div>
        <div v-if="value.processStage.notify" class="col-auto">
          <BaseActionButton
            v-tooltip.top="'settings'"
            is-flat
            no-border
            icon="eva-settings-outline"
            color="gray"
            @click="showProcessStageSettings = true"
          >
          </BaseActionButton>
        </div>
      </div>

      <div class="row items-center">
        <div class="col-5 q-mr-sm">
          <CheckboxField
            v-model="value.userGroup.notify"
            label="User and Group"
            description="Send a notification to the user and group"
            class="field"
          />
        </div>
        <div v-if="value.userGroup.notify" class="col-auto">
          <BaseActionButton
            v-tooltip.top="'settings'"
            is-flat
            no-border
            icon="eva-settings-outline"
            color="gray"
            @click="showUserGroupSettings = true"
          >
          </BaseActionButton>
        </div>
      </div>
    </div>

    <!-- ... -->

    <!-- Document Share -->

    <template v-if="value.inboxNotification">
      <FormFieldLabel
        label="Document Sharing List"
        class="field"
        is-mandatory
      />

      <div>
        <table>
          <thead>
            <tr>
              <th class="medium">Name</th>
              <th class="small">QR Stick</th>
              <th class="small">Attach</th>
              <th class="action">
                <BaseActionButton
                  is-flat
                  class="q-ml-xs"
                  color="secondary"
                  icon="eva-plus-outline"
                  no-border
                  @click="addRow"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, index) in value.fileList" :key="index">
              <td>
                <TextField
                  v-model="value.fileList[index]['name']"
                  :is-clearable="true"
                />
              </td>
              <td class="custom-center">
                <CheckboxField v-model="value.fileList[index]['qrStick']" />
              </td>
              <td class="">
                <template v-if="value.fileList[index]['template']">
                  <div class="row">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      is-flat
                      icon="mdi-file-download"
                      color="primary"
                      no-border
                      class="autoMargin"
                      @click="download(value.fileList[index]['id'])"
                    />
                    <BaseActionButton
                      v-tooltip.top="'Remove'"
                      is-flat
                      icon="mdi-delete"
                      color="red"
                      no-border
                      class="autoMargin"
                      @click="value.fileList[index]['template'] = false"
                    />
                  </div>
                </template>
                <template v-else>
                  <BaseActionButton
                    v-tooltip.top="'Attach Template'"
                    is-flat
                    color="secondary"
                    icon="mdi-upload"
                    class="autoMargin"
                    @click="attachTemplate(index)"
                  />
                </template>
              </td>
              <td class="action">
                <BaseActionButton
                  is-flat
                  color="red"
                  icon="eva-close-outline"
                  no-border
                  @click="removeRow(index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <input
          ref="attachments"
          type="file"
          class="hidden"
          @change="uploadAttachments"
        />
      </div>
    </template>

    <!-- ... -->

    <div v-if="false" class="options-wrapper">
      <FormFieldLabel label="Email Alert" class="field" is-mandatory />

      <MultiSelectField
        v-model="value.fields"
        label="Mark CC [Form Field]"
        :options="formColumns"
        class="field"
      />

      <MultiSelectField
        v-model="value.alertProcessStage"
        label="Process Stage"
        :options="nodes"
        class="field"
      />

      <MultiSelectField
        v-model="value.alertUser"
        label="User"
        :options="userList"
        class="field"
      />

      <MultiSelectField
        v-model="value.alertGroup"
        label="Group"
        :options="groupList"
        class="field"
      />
    </div>

    <!-- ... -->

    <Sheet
      :value="showAcknowledgeActionsSettings"
      no-padding
      width="820px"
      has-footer
      @input="showAcknowledgeActionsSettings = false"
    >
      <!-- title -->

      <template #title>Acknowledge Actions Settings</template>

      <!-- ... -->

      <template #default>
        <div id="mail-settings" class="row">
          <div class="content">
            <BaseScrollbar height="calc(100vh - 120px)">
              <!-- Mail Content -->

              <div class="options-wrapper">
                <div v-show="activeTab === 'EMAIL'">
                  <template v-if="activeTab === 'EMAIL'">
                    <CheckboxField
                      v-model="value.acknowledgement.emailNotify"
                      label="Email"
                      description="Send an notification via email"
                      class="field"
                    />
                    <template v-if="value.acknowledgement.emailNotify">
                      <slot v-if="changeTab"></slot>
                      <FormFieldLabel label="Mail Subject" class="field" />
                      <div v-if="changeTab"></div>
                      <TextEditor
                        v-model="acknowledgeMailSubject"
                        :hide-tool-bar="false"
                        :form-columns="formColumns"
                      ></TextEditor>
                      <slot v-if="changeTab"></slot>

                      <FormFieldLabel label="Mail Content" class="field" />
                      <slot v-if="changeTab1"></slot>
                      <div v-if="changeTab1"></div>
                      <TextEditor
                        v-model="acknowledgeMailContent"
                        :form-columns="formColumns"
                      ></TextEditor>
                      <slot v-if="changeTab1"></slot>

                      <CheckboxField
                        v-model="value.acknowledgement.emailAttachment"
                        label="Include attachments"
                        description="Include attachments while sending the notification"
                        class="field"
                      />
                    </template>
                  </template>
                </div>
                <div v-show="activeTab === 'SLACK'">
                  <template v-if="activeTab === 'SLACK'">
                    <CheckboxField
                      v-model="value.acknowledgement.slackNotify"
                      label="Slack"
                      description="Send an notification via slack"
                      class="field"
                    />

                    <template v-if="value.acknowledgement.slackNotify">
                      <div class="row field">
                        <SelectField
                          v-model="value.acknowledgement.slackConnectorId"
                          label="Slack connector name"
                          :options="slackConnectorList"
                          class="col q-mr-md"
                        />

                        <SelectField
                          v-model="value.acknowledgement.slackHubId"
                          label="Channel name"
                          :options="slackChannelList"
                          class="col"
                        />
                      </div>

                      <FormFieldLabel label="slack content" class="field" />
                      <slot v-if="changeTab"></slot>
                      <div v-if="changeTab"></div>
                      <TextEditor
                        v-model="acknowledgeSlackContent"
                        :hide-tool-bar="false"
                        :form-columns="formColumns"
                      ></TextEditor>

                      <CheckboxField
                        v-model="value.acknowledgement.slackAttachment"
                        label="Include attachments"
                        description="Include attachments while sending the notification"
                        class="field"
                      />
                    </template>
                  </template>
                </div>

                <div v-show="activeTab === 'TEAMS'">
                  <template v-if="activeTab === 'TEAMS'">
                    <CheckboxField
                      v-model="value.acknowledgement.teamsNotify"
                      label="Teams"
                      description="Send an notification via teams"
                      class="field"
                    />

                    <template v-if="value.acknowledgement.teamsNotify">
                      <div class="row field">
                        <SelectField
                          v-model="value.acknowledgement.teamsConnectorId"
                          label="Teams connector name"
                          :options="teamsConnectorList"
                          class="col q-mr-md"
                        />

                        <SelectField
                          v-model="value.acknowledgement.teamsHubId"
                          label="Channel name"
                          :options="teamsHubList"
                          class="col"
                        />
                      </div>

                      <FormFieldLabel label="teams content" class="field" />
                      <div v-if="changeTab"></div>
                      <TextEditor
                        v-model="acknowledgeTeamsContent"
                        :hide-tool-bar="false"
                        :form-columns="formColumns"
                      ></TextEditor>

                      <CheckboxField
                        v-model="value.acknowledgement.teamsAttachment"
                        label="Include attachments"
                        description="Include attachments while sending the notification"
                        class="field"
                      />
                    </template>
                  </template>
                </div>

                <div v-show="activeTab === 'WHATSAPP'">
                  <template v-if="activeTab === 'WHATSAPP'">
                    <CheckboxField
                      v-model="value.acknowledgement.whatsappNotify"
                      label="Whatsapp"
                      description="Send an notification via whatsapp"
                      class="field"
                    />

                    <template v-if="value.acknowledgement.whatsappNotify">
                      <div class="row field">
                        <SelectField
                          v-model="value.acknowledgement.whatsappConnectorId"
                          label="Whatsapp connector name"
                          :options="whatsappConnectorList"
                          class="col q-mr-md"
                        />

                        <SelectField
                          v-model="value.acknowledgement.whatsappHubId"
                          label="Channel name"
                          :options="whatsappHubList"
                          class="col"
                        />
                      </div>

                      <FormFieldLabel label="whatsapp content" class="field" />
                      <div v-if="changeTab"></div>
                      <TextEditor
                        v-model="acknowledgeWhatsappContent"
                        :hide-tool-bar="false"
                        :form-columns="formColumns"
                      ></TextEditor>

                      <CheckboxField
                        v-model="value.acknowledgement.whatsappAttachment"
                        label="Include attachments"
                        description="Include attachments while sending the notification"
                        class="field"
                      />
                    </template>
                  </template>
                </div>

                <div v-show="activeTab === 'APPLICATION'">
                  <template v-if="activeTab === 'APPLICATION'">
                    <CheckboxField
                      v-model="value.acknowledgement.appNotify"
                      label="Application"
                      description="Receive notifications in the app"
                      class="field"
                    />
                  </template>
                </div>
              </div>
              <!-- ... -->

              <!-- Mail Content -->
            </BaseScrollbar>
          </div>

          <!-- tabs -->

          <div class="tabs">
            <BaseActionButton
              v-for="tab in tabs"
              :key="tab.id"
              v-tooltip.left="tab.label"
              :is-flat="activeTab !== tab.value"
              :icon="tab.icon"
              :color="activeTab === tab.value ? 'secondary' : 'gray'"
              class="q-mb-md"
              @click="changeTabAcknowlege(tab.value)"
            />
          </div>

          <!-- ... -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="cancel"
          is-flat
          color="primary"
          class="q-mr-sm"
          @click="showAcknowledgeActionsSettings = false"
        />

        <BaseButton
          label="save"
          color="primary"
          @click="saveAcknowledgementContent"
        />
      </template>
    </Sheet>

    <Sheet
      :value="showNotifyRespondentSettings"
      no-padding
      width="820px"
      has-footer
      @input="showNotifyRespondentSettings = false"
    >
      <!-- title -->

      <template #title>Notify Respondents Settings</template>

      <!-- ... -->

      <template #default>
        <div id="mail-settings" class="row">
          <div class="content">
            <BaseScrollbar height="calc(100vh - 120px)">
              <!-- Mail Content -->

              <div class="options-wrapper">
                <div v-show="activeTab === 'EMAIL'">
                  <template v-if="activeTab === 'EMAIL'">
                    <CheckboxField
                      v-model="value.inboxNotification.emailNotify"
                      label="Email"
                      description="Send an notification via email"
                      class="field"
                    />
                    <template v-if="value.inboxNotification.emailNotify">
                      <FormFieldLabel label="Mail Subject" class="field" />
                      <div v-if="changeTab"></div>
                      <TextEditor
                        v-model="respondentsMailSubject"
                        :hide-tool-bar="false"
                        :form-columns="formColumns"
                      ></TextEditor>

                      <FormFieldLabel label="Mail Content" class="field" />
                      <div v-if="changeTab1"></div>
                      <TextEditor
                        v-model="respondentsMailContent"
                        :form-columns="formColumns"
                      ></TextEditor>

                      <CheckboxField
                        v-model="value.inboxNotification.actionButtons"
                        label="Allow respondents"
                        description="Allow respondents to act on their own request"
                        class="field"
                      />

                      <CheckboxField
                        v-model="value.inboxNotification.emailAttachment"
                        label="Include attachments"
                        description="Include attachments while sending the notification"
                        class="field"
                      />
                    </template>
                  </template>
                </div>

                <div v-show="activeTab === 'SLACK'">
                  <template v-if="activeTab === 'SLACK'">
                    <CheckboxField
                      v-model="value.inboxNotification.slackNotify"
                      label="Slack"
                      description="Send an notification via slack"
                      class="field"
                    />

                    <template v-if="value.inboxNotification.slackNotify">
                      <div class="row">
                        <SelectField
                          v-model="value.inboxNotification.slackConnectorId"
                          label="Slack connector name"
                          :options="slackConnectorList"
                          class="col field"
                        />

                        <SelectField
                          v-model="value.inboxNotification.slackHubId"
                          label="Channel name"
                          :options="slackChannelList"
                          class="col field"
                        />
                      </div>

                      <FormFieldLabel label="slack content" class="field" />
                      <div v-if="changeTab"></div>
                      <TextEditor
                        v-model="respondentsSlackContent"
                        :hide-tool-bar="false"
                        :form-columns="formColumns"
                      ></TextEditor>

                      <CheckboxField
                        v-model="value.inboxNotification.slackAttachment"
                        label="Include attachments"
                        description="Include attachments while sending the notification"
                        class="field"
                      />
                    </template>
                  </template>
                </div>

                <div v-show="activeTab === 'TEAMS'">
                  <template v-if="activeTab === 'TEAMS'">
                    <CheckboxField
                      v-model="value.inboxNotification.teamsNotify"
                      label="Teams"
                      description="Send an notification via teams"
                      class="field"
                    />

                    <template v-if="value.inboxNotification.teamsNotify">
                      <div class="row field">
                        <SelectField
                          v-model="value.inboxNotification.teamsConnectorId"
                          label="Teams connector name"
                          :options="teamsConnectorList"
                          class="col q-mr-md"
                        />

                        <SelectField
                          v-model="value.inboxNotification.teamsHubId"
                          label="Channel name"
                          :options="teamsHubList"
                          class="col"
                        />
                      </div>

                      <FormFieldLabel label="teams content" class="field" />
                      <div v-if="changeTab"></div>
                      <TextEditor
                        v-model="respondentsTeamsContent"
                        :hide-tool-bar="false"
                        :form-columns="formColumns"
                      ></TextEditor>

                      <CheckboxField
                        v-model="value.inboxNotification.teamsAttachment"
                        label="Include attachments"
                        description="Include attachments while sending the notification"
                        class="field"
                      />
                    </template>
                  </template>
                </div>

                <div v-show="activeTab === 'WHATSAPP'">
                  <template v-if="activeTab === 'WHATSAPP'">
                    <CheckboxField
                      v-model="value.inboxNotification.whatsappNotify"
                      label="Whatsapp"
                      description="Send an notification via whatsapp"
                      class="field"
                    />

                    <template v-if="value.inboxNotification.whatsappNotify">
                      <div class="row field">
                        <SelectField
                          v-model="value.inboxNotification.whatsappConnectorId"
                          label="Whatsapp connector name"
                          :options="whatsappConnectorList"
                          class="col q-mr-md"
                        />

                        <SelectField
                          v-model="value.inboxNotification.whatsappHubId"
                          label="Channel name"
                          :options="whatsappHubList"
                          class="col"
                        />
                      </div>

                      <FormFieldLabel label="whatsapp content" class="field" />
                      <div v-if="changeTab"></div>
                      <TextEditor
                        v-model="respondentsWhatsappContent"
                        :hide-tool-bar="false"
                        :form-columns="formColumns"
                      ></TextEditor>

                      <CheckboxField
                        v-model="value.inboxNotification.whatsappAttachment"
                        label="Include attachments"
                        description="Include attachments while sending the notification"
                        class="field"
                      />
                    </template>
                  </template>
                </div>

                <div v-show="activeTab === 'APPLICATION'">
                  <template v-if="activeTab === 'APPLICATION'">
                    <CheckboxField
                      v-model="value.acknowledgement.appNotify"
                      label="Application"
                      description="Receive notifications in the app"
                      class="field"
                    />
                  </template>
                </div>
              </div>
              <!-- ... -->

              <!-- Mail Content -->
            </BaseScrollbar>
          </div>

          <!-- tabs -->

          <div class="tabs">
            <BaseActionButton
              v-for="tab in tabs"
              :key="tab.id"
              v-tooltip.left="tab.label"
              :is-flat="activeTab !== tab.value"
              :icon="tab.icon"
              :color="activeTab === tab.value ? 'secondary' : 'gray'"
              class="q-mb-md"
              @click="changeInboxNotifyTab(tab.value)"
            />
          </div>

          <!-- ... -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="cancel"
          is-flat
          color="primary"
          class="q-mr-sm"
          @click="showNotifyRespondentSettings = false"
        />

        <BaseButton
          label="save"
          color="primary"
          @click="saveNotifyRespondentContent"
        />
      </template>
    </Sheet>

    <Sheet
      :value="showMarkCCFieldSettings"
      no-padding
      width="820px"
      has-footer
      @input="showMarkCCFieldSettings = false"
    >
      <!-- title -->

      <template #title>Mark CC Field Settings</template>

      <!-- ... -->

      <template #default>
        <div id="mail-settings" class="row">
          <div class="content">
            <BaseScrollbar height="calc(100vh - 120px)">
              <!-- Mail Content -->

              <div class="options-wrapper">
                <MultiSelectField
                  v-model="value.markCCField.fields"
                  label="Mark CC [Form Field]"
                  :options="formColumns"
                  class="field"
                />

                <FormFieldLabel label="Mail Subject" class="field" />
                <TextEditor
                  v-model="markCCMailSubject"
                  :hide-tool-bar="false"
                  :form-columns="formColumns"
                ></TextEditor>

                <FormFieldLabel label="Mail Content" class="field" />
                <TextEditor
                  v-model="markCCMailContent"
                  :form-columns="formColumns"
                ></TextEditor>

                <CheckboxField
                  v-model="value.markCCField.attachment"
                  label="Include attachments"
                  description="Include attachments while sending the notification"
                  class="field"
                />

                <CheckboxField
                  v-model="value.markCCField.appNotify"
                  label="Application"
                  description="Receive notifications in the app"
                  class="field"
                />
              </div>
              <!-- ... -->

              <!-- Mail Content -->
            </BaseScrollbar>
          </div>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="cancel"
          is-flat
          color="primary"
          class="q-mr-sm"
          @click="showMarkCCFieldSettings = false"
        />

        <BaseButton
          label="save"
          color="primary"
          @click="saveMarkCCFieldSettings"
        />
      </template>
    </Sheet>

    <Sheet
      :value="showProcessStageSettings"
      no-padding
      width="820px"
      has-footer
      @input="showProcessStageSettings = false"
    >
      <!-- title -->

      <template #title>Process Stage Settings</template>

      <!-- ... -->

      <template #default>
        <div id="mail-settings" class="row">
          <div class="content">
            <BaseScrollbar height="calc(100vh - 120px)">
              <!-- Mail Content -->

              <div class="options-wrapper">
                <MultiSelectField
                  v-model="value.processStage.alertProcessStage"
                  label="Process Stage"
                  :options="nodes"
                  class="field"
                />

                <FormFieldLabel label="Mail Subject" class="field" />
                <TextEditor
                  v-model="processStageMailSubject"
                  :hide-tool-bar="false"
                  :form-columns="formColumns"
                ></TextEditor>

                <FormFieldLabel label="Mail Content" class="field" />
                <TextEditor
                  v-model="processStageMailContent"
                  :form-columns="formColumns"
                ></TextEditor>

                <CheckboxField
                  v-model="value.processStage.attachment"
                  label="Include attachments"
                  description="Include attachments while sending the notification"
                  class="field"
                />

                <CheckboxField
                  v-model="value.processStage.appNotify"
                  label="Application"
                  description="Receive notifications in the app"
                  class="field"
                />
              </div>
              <!-- ... -->

              <!-- Mail Content -->
            </BaseScrollbar>
          </div>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="cancel"
          is-flat
          color="primary"
          class="q-mr-sm"
          @click="showProcessStageSettings = false"
        />

        <BaseButton
          label="save"
          color="primary"
          @click="saveProcessStageSettings"
        />
      </template>
    </Sheet>

    <Sheet
      :value="showUserGroupSettings"
      no-padding
      width="820px"
      has-footer
      @input="showUserGroupSettings = false"
    >
      <!-- title -->

      <template #title>User and group Settings</template>

      <!-- ... -->

      <template #default>
        <div id="mail-settings" class="row">
          <div class="content">
            <BaseScrollbar height="calc(100vh - 120px)">
              <!-- Mail Content -->

              <div class="options-wrapper">
                <MultiSelectField
                  v-model="value.userGroup.alertUser"
                  label="User"
                  :options="userList"
                  class="field"
                />

                <MultiSelectField
                  v-model="value.userGroup.alertGroup"
                  label="Group"
                  :options="groupList"
                  class="field"
                />

                <FormFieldLabel label="Mail Subject" class="field" />
                <TextEditor
                  v-model="userGroupMailSubject"
                  :hide-tool-bar="false"
                  :form-columns="formColumns"
                ></TextEditor>

                <FormFieldLabel label="Mail Content" class="field" />
                <TextEditor
                  v-model="userGroupMailContent"
                  :form-columns="formColumns"
                ></TextEditor>

                <CheckboxField
                  v-model="value.userGroup.attachment"
                  label="Include attachments"
                  description="Include attachments while sending the notification"
                  class="field"
                />

                <CheckboxField
                  v-model="value.userGroup.appNotify"
                  label="Application"
                  description="Receive notifications in the app"
                  class="field"
                />
              </div>
              <!-- ... -->

              <!-- Mail Content -->
            </BaseScrollbar>
          </div>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="cancel"
          is-flat
          color="primary"
          class="q-mr-sm"
          @click="showUserGroupSettings = false"
        />

        <BaseButton
          label="save"
          color="primary"
          @click="saveUserGroupSettings"
        />
      </template>
    </Sheet>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import axios from "axios";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextEditor from "./components/TextEditor";
import { fileSupport } from "@/helpers/file-format.js";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { connector } from "@/api/factory.js";

export default {
  name: "Notifications",

  components: {
    FormFieldLabel,
    CheckboxField,
    MultiSelectField,
    TextField,
    Sheet,
    TextEditor,
    SelectField,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    userList: {
      type: Array,
      default: () => [],
    },

    groupList: {
      type: Array,
      default: () => [],
    },

    workflowId: {
      type: String,
      default: "",
    },

    blockId: {
      type: String,
      default: "",
    },

    formColumns: {
      type: Array,
      default: () => [],
    },

    nodes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      repositoryFields: [],
      showAcknowledgeActionsSettings: false,
      showNotifyRespondentSettings: false,
      acknowledgeMailSubject: {
        text: "",
        content: "",
      },
      acknowledgeMailContent: {
        text: "",
        content: "",
      },
      respondentsMailSubject: {
        text: "",
        content: "",
      },
      respondentsMailContent: {
        text: "",
        content: "",
      },
      showMarkCCFieldSettings: false,
      showProcessStageSettings: false,
      showUserGroupSettings: false,
      markCCMailSubject: {
        text: "",
        content: "",
      },
      markCCMailContent: {
        text: "",
        content: "",
      },
      processStageMailSubject: {
        text: "",
        content: "",
      },
      processStageMailContent: {
        text: "",
        content: "",
      },
      userGroupMailSubject: {
        text: "",
        content: "",
      },
      userGroupMailContent: {
        text: "",
        content: "",
      },
      slackConnectorList: [],
      slackChannelList: [],

      acknowledgeSlackContent: {
        text: "",
        content: "",
      },
      respondentsSlackContent: {
        text: "",
        content: "",
      },
      tabs: [
        {
          id: this.$nano.id(),
          label: "email",
          value: "EMAIL",
          icon: "eva-email-outline",
        },
        {
          id: this.$nano.id(),
          label: "slack",
          value: "SLACK",
          icon: "mdi-slack",
        },
        {
          id: this.$nano.id(),
          label: "teams",
          value: "TEAMS",
          icon: "mdi-microsoft-teams",
        },
        {
          id: this.$nano.id(),
          label: "whatsapp",
          value: "WHATSAPP",
          icon: "mdi-whatsapp",
        },
        {
          id: this.$nano.id(),
          label: "application",
          value: "APPLICATION",
          icon: "mdi-application",
        },
      ],
      activeTab: "EMAIL",
      teamsConnectorList: [],
      teamsHubList: [],
      acknowledgeTeamsContent: {
        text: "",
        content: "",
      },
      respondentsTeamsContent: {
        text: "",
        content: "",
      },
      changeTab: true,
      acknowledgeMailSubjectCopy: {
        text: "",
        content: "",
      },
      changeTab1: false,
      whatsappConnectorList: [],
      whatsappHubList: [],
      whatsappNotifyHubList: [],
      acknowledgeWhatsappContent: {
        text: "",
        content: "",
      },
      respondentsWhatsappContent: {
        text: "",
        content: "",
      },
    };
  },

  watch: {
    value: {
      immediate: true,
      // deep: true,
      handler: function () {
        if (this.value) {
          this.acknowledgeMailSubject.text = this.value.acknowledgement.subject;
          this.acknowledgeMailSubject.content =
            this.value.acknowledgement.subjectContent;
          this.acknowledgeMailContent.text = this.value.acknowledgement.body;
          this.acknowledgeMailContent.content =
            this.value.acknowledgement.bodyContent;
          this.respondentsMailSubject.text =
            this.value.inboxNotification.subject;
          this.respondentsMailSubject.content =
            this.value.inboxNotification.subjectContent;
          this.respondentsMailContent.text = this.value.inboxNotification.body;
          this.respondentsMailContent.content =
            this.value.inboxNotification.bodyContent;
          // console.log(this.acknowledgeMailSubject);

          this.markCCMailSubject.text = this.value.markCCField.subject;
          this.markCCMailSubject.content =
            this.value.markCCField.subjectContent;

          this.markCCMailContent.text = this.value.markCCField.body;
          this.markCCMailContent.content = this.value.markCCField.bodyContent;

          this.processStageMailSubject.text = this.value.processStage.subject;
          this.processStageMailSubject.content =
            this.value.processStage.subjectContent;

          this.processStageMailContent.text = this.value.processStage.body;
          this.processStageMailContent.content =
            this.value.processStage.bodyContent;

          this.userGroupMailSubject.text = this.value.userGroup.subject;
          this.userGroupMailSubject.content =
            this.value.userGroup.subjectContent;

          this.userGroupMailContent.text = this.value.userGroup.body;
          this.userGroupMailContent.content = this.value.userGroup.bodyContent;

          if (this.value.acknowledgement?.slackNotify) {
            this.acknowledgeSlackContent.text =
              this.value.acknowledgement.slackContentCopy;
            if (this.value.acknowledgement.slackContent) {
              this.acknowledgeSlackContent.content = `<p>${this.value.acknowledgement.slackContent}</p>`;
            }
          }

          if (this.value.inboxNotification?.slackNotify) {
            this.respondentsSlackContent.text =
              this.value.inboxNotification.slackContentCopy;
            if (this.value.inboxNotification.slackContent) {
              this.respondentsSlackContent.content = `<p>${this.value.inboxNotification.slackContent}</p>`;
            }
          }

          // teams

          console.log(
            this.value.acknowledgement.teamsContent,
            this.value.acknowledgement.slackContent
          );

          if (this.value.acknowledgement?.teamsNotify) {
            this.acknowledgeTeamsContent.text =
              this.value.acknowledgement.teamsContentCopy;
            if (this.value.acknowledgement.teamsContent) {
              this.acknowledgeTeamsContent.content = `<p>${this.value.acknowledgement.teamsContent}</p>`;
            }
          }

          if (this.value.inboxNotification?.teamsNotify) {
            this.respondentsTeamsContent.text =
              this.value.inboxNotification.teamsContentCopy;
            if (this.value.inboxNotification.teamsContent) {
              this.respondentsTeamsContent.content = `<p>${this.value.inboxNotification.teamsContent}</p>`;
            }
          }

          if (this.value.acknowledgement?.whatsappNotify) {
            this.acknowledgeWhatsappContent.text =
              this.value.acknowledgement.whatsappContentCopy;
            if (this.value.acknowledgement.whatsappContent) {
              this.acknowledgeWhatsappContent.content = `<p>${this.value.acknowledgement.whatsappContent}</p>`;
            }
          }

          if (this.value.inboxNotification?.whatsappNotify) {
            this.respondentsWhatsappContent.text =
              this.value.inboxNotification.whatsappContentCopy;
            if (this.value.inboxNotification.whatsappContent) {
              this.respondentsWhatsappContent.content = `<p>${this.value.inboxNotification.whatsappContent}</p>`;
            }
          }
        }
      },
    },

    "value.acknowledgement.slackConnectorId": {
      deep: true,
      immediate: true,
      handler() {
        if (this.value.acknowledgement.slackConnectorId) {
          this.getSlackChannelList(this.value.acknowledgement.slackConnectorId);
        }
      },
    },

    "value.inboxNotification.slackConnectorId": {
      deep: true,
      immediate: true,
      handler() {
        if (this.value.inboxNotification.slackConnectorId) {
          this.getSlackChannelList(
            this.value.inboxNotification.slackConnectorId
          );
        }
      },
    },

    "value.acknowledgement.teamsConnectorId": {
      deep: true,
      immediate: true,
      handler() {
        if (this.value.acknowledgement.teamsConnectorId) {
          this.getTeamsHubList(this.value.acknowledgement.teamsConnectorId);
        }
      },
    },

    "value.inboxNotification.teamsConnectorId": {
      deep: true,
      immediate: true,
      handler() {
        if (this.value.inboxNotification.teamsConnectorId) {
          this.getTeamsHubList(this.value.inboxNotification.teamsConnectorId);
        }
      },
    },

    "value.acknowledgement.whatsappConnectorId": {
      deep: true,
      immediate: true,
      handler() {
        if (this.value.acknowledgement.whatsappConnectorId) {
          this.getWhatsAppHubList(
            this.value.acknowledgement.whatsappConnectorId
          );
        }
      },
    },

    "value.inboxNotification.whatsappConnectorId": {
      deep: true,
      immediate: true,
      handler() {
        if (this.value.inboxNotification.whatsappConnectorId) {
          this.getWhatsAppHubList(
            this.value.inboxNotification.whatsappConnectorId
          );
        }
      },
    },

    activeTab() {
      if (this.activeTab) {
        this.changeTab = !this.changeTab;
        this.changeTab1 = !this.changeTab1;
      }
    },
  },

  created() {
    this.getSlackConnection();
    this.getTeamsConnection();
    this.getWhatsappConnection();

    if (!this.value.fileList) {
      this.value.fileList = [];
      this.addRow();
      return;
    }
    if (this.value.fileList.length === 0) {
      this.addRow();
    }
  },

  methods: {
    addRow() {
      const model = {
        id: 0,
        name: "",
        qrStick: false,
        template: false,
      };

      this.value.fileList.push(model);
    },

    removeRow(rowIdx) {
      this.value.fileList.splice(rowIdx, 1);
    },

    attachTemplate(index) {
      this.rowIndex = index;
      this.$refs.attachments.click();
    },

    download(id) {
      window.open(
        `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${id}`
      );
    },

    saveAcknowledgementContent() {
      this.value.acknowledgement.subject = this.acknowledgeMailSubject.text;
      this.value.acknowledgement.subjectContent =
        this.acknowledgeMailSubject.content;
      this.value.acknowledgement.body = this.acknowledgeMailContent.text;
      this.value.acknowledgement.bodyContent =
        this.acknowledgeMailContent.content;

      //slack
      if (this.acknowledgeSlackContent.content !== "<p></p>") {
        this.value.acknowledgement.slackContent = this.removeTags(
          this.acknowledgeSlackContent.content
        );
        this.value.acknowledgement.slackContentCopy =
          this.acknowledgeSlackContent.text;
      } else {
        this.value.acknowledgement.slackContent = "";
        this.value.acknowledgement.slackContentCopy = "";
      }

      // teams
      if (this.acknowledgeTeamsContent.content !== "<p></p>") {
        this.value.acknowledgement.teamsContent = this.removeTags(
          this.acknowledgeTeamsContent.content
        );
        this.value.acknowledgement.teamsContentCopy =
          this.acknowledgeTeamsContent.text;
      } else {
        this.value.acknowledgement.teamsContent = "";
        this.value.acknowledgement.teamsContentCopy = "";
      }

      // whatsapp
      if (this.acknowledgeWhatsappContent.content !== "<p></p>") {
        this.value.acknowledgement.whatsappContent = this.removeTags(
          this.acknowledgeWhatsappContent.content
        );
        this.value.acknowledgement.whatsappContentCopy =
          this.acknowledgeWhatsappContent.text;
      } else {
        this.value.acknowledgement.whatsappContent = "";
        this.value.acknowledgement.whatsappContentCopy = "";
      }

      this.$emit("input", this.value);
      this.showAcknowledgeActionsSettings = false;
    },

    saveNotifyRespondentContent() {
      this.value.inboxNotification.subject = this.respondentsMailSubject.text;
      this.value.inboxNotification.subjectContent =
        this.respondentsMailSubject.content;
      this.value.inboxNotification.body = this.respondentsMailContent.text;
      this.value.inboxNotification.bodyContent =
        this.respondentsMailContent.content;

      // slack
      if (this.respondentsSlackContent.content !== "<p></p>") {
        this.value.inboxNotification.slackContent = this.removeTags(
          this.respondentsSlackContent.content
        );
        this.value.inboxNotification.slackContentCopy =
          this.respondentsSlackContent.text;
      } else {
        this.value.inboxNotification.slackContent = "";
        this.value.inboxNotification.slackContentCopy = "";
      }

      // teams
      if (this.respondentsTeamsContent.content !== "<p></p>") {
        this.value.inboxNotification.teamsContent = this.removeTags(
          this.respondentsTeamsContent.content
        );
        this.value.inboxNotification.teamsContentCopy =
          this.respondentsTeamsContent.text;
      } else {
        this.value.inboxNotification.teamsContent = "";
        this.value.inboxNotification.teamsContentCopy = "";
      }

      // whatsapp
      if (this.respondentsWhatsappContent.content !== "<p></p>") {
        this.value.inboxNotification.whatsappContent = this.removeTags(
          this.respondentsWhatsappContent.content
        );
        this.value.inboxNotification.whatsappContentCopy =
          this.respondentsWhatsappContent.text;
      } else {
        this.value.inboxNotification.whatsappContent = "";
        this.value.inboxNotification.whatsappContentCopy = "";
      }

      this.$emit("input", this.value);
      this.showNotifyRespondentSettings = false;
    },

    async uploadAttachments(e) {
      const file = e.target.files[0];
      const formData = new FormData();
      if (this.workflowId) {
        let row = {
          id: this.value.fileList[this.rowIndex]["id"],
          name: this.value.fileList[this.rowIndex]["name"],
          required: this.value.fileList[this.rowIndex]["qrStick"],
          type: "",
        };
        formData.append("workflowId", this.workflowId);
        formData.append("activityId", this.blockId);
        formData.append("fileCheckList", JSON.stringify(row));
      }
      formData.append("file", file);

      let filename = e.target.files[0].name;
      const ext = filename.split(".").pop();
      if (!fileSupport(ext)) {
        this.$alert.info("This file format is not supported");
        return;
      }
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/workflow/uploadChecklist`,
          method: "POST",
          data: formData,
          headers: {
            Token: this.$store.state.identity.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        const { status, data } = response;
        if (status !== 200) {
          throw "Invalid response";
        }
        console.log(data);
        this.$alert.success(`Template attached`);
        this.value.fileList[this.rowIndex]["id"] = Number(data);
        this.value.fileList[this.rowIndex]["template"] = true;
        this.rowIndex = null;
        e.target.value = "";
      } catch (error) {
        console.error(error);
        this.$alert.error(`error attaching file`);
      }
    },

    saveMarkCCFieldSettings() {
      this.value.markCCField.subject = this.markCCMailSubject.text;
      this.value.markCCField.subjectContent = this.markCCMailSubject.content;
      this.value.markCCField.body = this.markCCMailContent.text;
      this.value.markCCField.bodyContent = this.markCCMailContent.content;
      this.$emit("input", this.value);
      this.showMarkCCFieldSettings = false;
    },

    saveProcessStageSettings() {
      this.value.processStage.subject = this.processStageMailSubject.text;
      this.value.processStage.subjectContent =
        this.processStageMailSubject.content;
      this.value.processStage.body = this.processStageMailContent.text;
      this.value.processStage.bodyContent =
        this.processStageMailContent.content;
      this.$emit("input", this.value);
      this.showProcessStageSettings = false;
    },

    saveUserGroupSettings() {
      this.value.userGroup.subject = this.userGroupMailSubject.text;
      this.value.userGroup.subjectContent = this.userGroupMailSubject.content;
      this.value.userGroup.body = this.userGroupMailContent.text;
      this.value.userGroup.bodyContent = this.userGroupMailContent.content;
      this.$emit("input", this.value);
      this.showUserGroupSettings = false;
    },

    acknowledgeSettings() {
      this.activeTab = "EMAIL";
      if (this.value.acknowledgement.attachment) {
        this.value.acknowledgement.emailAttachment = true;
      }
      if (
        this.value.acknowledgement.body ||
        this.value.acknowledgement.subject
      ) {
        this.value.acknowledgement.emailNotify = true;
      }
      this.showAcknowledgeActionsSettings = true;
    },

    notifySettings() {
      this.activeTab = "EMAIL";
      if (this.value.inboxNotification.attachment) {
        this.value.inboxNotification.emailAttachment = true;
      }
      if (
        this.value.inboxNotification.body ||
        this.value.inboxNotification.subject
      ) {
        this.value.inboxNotification.emailNotify = true;
      }
      this.showNotifyRespondentSettings = true;
    },

    async getSlackConnection() {
      const { error, payload } = await connector.getConnection({
        filterBy: [
          {
            filters: [
              {
                criteria: "connectorType",
                condition: "IS_EQUALS_TO",
                value: "slack",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.slackConnectorList =
          payload &&
          payload.map((slack) => ({
            id: this.$nano.id(),
            label: slack.name,
            value: slack.id,
          }));
      }
    },

    async getSlackChannelList(id) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getLookUp({
        filterBy: [
          {
            filters: [
              {
                criteria: "connectorId",
                condition: "IS_EQUALS_TO",
                value: Number(id),
                // dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.slackChannelList =
          payload &&
          payload.map((slack) => ({
            id: this.$nano.id(),
            label: slack.connectorHub.name,
            value: slack.connectorHub.id,
          }));
      }
    },

    async getTeamsConnection() {
      console.log("payload");
      const { error, payload } = await connector.getConnection({
        filterBy: [
          {
            filters: [
              {
                criteria: "connectorType",
                condition: "IS_EQUALS_TO",
                value: "teams",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.teamsConnectorList =
          payload &&
          payload.map((slack) => ({
            id: this.$nano.id(),
            label: slack.name,
            value: slack.id,
          }));
      }
    },

    async getTeamsHubList(id) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getLookUp({
        filterBy: [
          {
            filters: [
              {
                criteria: "connectorId",
                condition: "IS_EQUALS_TO",
                value: Number(id),
                // dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.teamsHubList =
          payload &&
          payload.map((teams) => ({
            id: this.$nano.id(),
            label: teams.connectorHub.name,
            value: teams.connectorHub.id,
          }));
      }
    },

    removeTags(value) {
      if (!value) return "";
      if (this.activeTab === "SLACK" || this.activeTab === "TEAMS") {
        return value
          .replace(new RegExp("<p>", "g"), "")
          .replace(new RegExp("</p>", "g"), "\n");
      }
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    changeTabAcknowlege(value) {
      this.activeTab = value;
      // console.log(this.acknowledgeSlackContent, this.acknowledgeMailSubject);
      // console.log(this.acknowledgeTeamsContent, this.acknowledgeMailContent);
      if (this.activeTab === "EMAIL") {
        let sub = JSON.stringify(this.acknowledgeMailSubject);
        this.acknowledgeMailSubject = JSON.parse(sub);

        let con = JSON.stringify(this.acknowledgeMailContent);
        this.acknowledgeMailContent = JSON.parse(con);
      }

      if (this.activeTab === "SLACK") {
        let con = JSON.stringify(this.acknowledgeSlackContent);
        this.acknowledgeSlackContent = JSON.parse(con);
      }
    },

    changeInboxNotifyTab(value) {
      this.activeTab = value;

      if (this.activeTab === "EMAIL") {
        let sub = JSON.stringify(this.respondentsMailSubject);
        this.respondentsMailSubject = JSON.parse(sub);

        let con = JSON.stringify(this.respondentsMailContent);
        this.respondentsMailContent = JSON.parse(con);
      }

      if (this.activeTab === "SLACK") {
        let con = JSON.stringify(this.respondentsSlackContent);
        this.respondentsSlackContent = JSON.parse(con);
      }
    },

    async getWhatsappConnection() {
      const { error, payload } = await connector.getConnection({
        filterBy: [
          {
            filters: [
              {
                criteria: "connectorType",
                condition: "IS_EQUALS_TO",
                value: "whatsapp",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.whatsappConnectorList =
          payload &&
          payload.map((whatsapp) => ({
            id: this.$nano.id(),
            label: whatsapp.name,
            value: whatsapp.id,
          }));
      }
    },

    async getWhatsAppHubList(id) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getLookUp({
        filterBy: [
          {
            filters: [
              {
                criteria: "connectorId",
                condition: "IS_EQUALS_TO",
                value: Number(id),
                // dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.whatsappHubList =
          payload &&
          payload.map((whatsapp) => ({
            id: this.$nano.id(),
            label: whatsapp.connectorHub.name,
            value: whatsapp.connectorHub.id,
          }));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#notification-builder {
  .options-wrapper {
    border-top: 1px solid var(--divider-color);
  }

  .field {
    margin-top: 16px;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 50px;
      }

      &.medium {
        width: 350px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      .autoMargin {
        margin: auto;
      }
    }
    .custom-center {
      text-align: center;
    }
  }
}

#mail-settings {
  .field {
    margin: 16px 16px 24px 16px;
  }

  .content {
    flex: 1;
    border-right: 1px solid var(--divider-color);
  }

  .options-wrapper {
    border-top: 1px solid var(--divider-color);
  }

  .tabs {
    width: 68px;
    padding: 16px;
  }
}
</style>
<style lang="scss">
#notification-builder {
  .custom-center {
    #checkbox-field .option {
      display: unset !important;
    }
  }
}
</style>
