<template>
  <div>
    <div
      id="company-logo"
      :class="
        $store.state.session.role === 'ADMINISTRATOR' ? 'cursor-pointer' : ''
      "
      @click="
        $store.state.session.role === 'ADMINISTRATOR'
          ? (changeCompanyLogo = true)
          : ''
      "
    >
      <img :src="logoURL" />
    </div>
    <Modal
      v-model="changeCompanyLogo"
      width="30vw"
      height="60vh"
      has-footer
      @input="changeCompanyLogo = false"
    >
      <!-- title -->

      <template #title> Change Company Logo</template>

      <!-- ... -->

      <template #default>
        <div id="changePhoto">
          <ValidationProvider
            v-slot="{ errors }"
            name="upload image"
            :rules="{ required: true }"
          >
            <ImageField
              v-model="uploadImage"
              label="upload image"
              :is-mandatory="true"
              :error="errors[0]"
            />
          </ValidationProvider>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="cancel"
          color="red"
          class="q-mr-md"
          @click="closeModal"
        />
        <BaseButton label="Update" color="primary" @click="updateImage" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Modal from "@/components/common/popup/Modal.vue";
import { ValidationProvider } from "vee-validate";
import ImageField from "@/components/common/form/image-field/ImageField.vue";
import { user } from "@/api/factory.js";

export default {
  name: "CompanyLogo",

  components: { Modal, ValidationProvider, ImageField },

  data() {
    return {
      changeCompanyLogo: false,
      uploadImage: "",
      logo: "",
      logoURL: `${process.env.VUE_APP_API_URL}/tenant/logo/${this.$store.state.session.tenantId}`,
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    logoHeight() {
      return "24px";
    },
  },

  methods: {
    async updateImage() {
      this.$store.commit("showLoadingBar");
      let input = {
        base64File: this.uploadImage.split(",")[1],
      };
      const { error } = await user.uploadCompanyLogo(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Company logo updated");

      this.$store.commit("hideLoadingBar");

      this.changeCompanyLogo = false;
      this.uploadImage = "";
      this.logoURL = `${process.env.VUE_APP_API_URL}/tenant/logo/${
        this.$store.state.session.tenantId
      }?${this.$nano.id()}`;
    },

    async closeModal() {
      this.changeCompanyLogo = false;
      this.uploadImage = "";
    },
  },
};
</script>

<style lang="scss" scoped>
#company-logo {
  display: flex;
  align-items: center;
  border: 1px solid var(--divider-color);
  padding: 4px;
  border-radius: 10px;
  height: 40px;
  max-width: 110px;
  min-width: 80px;
  &:hover {
    background: #f9f8f8;
  }

  img {
    height: 100%;
    width: 100%;
  }
}
</style>
