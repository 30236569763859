<template>
  <div>
    <!-- <BaseScrollbar height="calc(100vh - 124px)"> -->
    <template v-if="showWorkspaceRepository">
      <WorkspaceList v-model="workspaceId" />
      <RepositoryList v-model="repositoryId" :workspace-id="workspaceId" />
    </template>
    <div
      id="dropzone"
      :class="[{ 'is-dragging': isDragging }, { 'is-indexing': tabIndexing }]"
    >
      <div class="hint">
        <BaseIcon
          name="eva-cloud-upload-outline"
          size="128px"
          inherit-color
          class="icon"
        />

        <div class="title">Drag & Drop your files here to upload</div>
        <div class="description">
          Simply dragging and dropping them from the user's computer or device
          onto a designated area on the screen
        </div>

        <OrDivider class="full-width" />
        <BaseButton v-if="tabIndexing" icon="eva-plus" label="Add more files" />
        <BaseButton v-else label="browse files" />
      </div>

      <input
        ref="dropzone"
        type="file"
        :multiple="hasMultiple"
        @dragenter="isDragging = true"
        @dragleave="isDragging = false"
        @drop="isDragging = false"
        @change="handleChange"
      />
    </div>
    <!-- </BaseScrollbar> -->
  </div>
</template>

<script>
import WorkspaceList from "@/views/upload-and-index/components/WorkspaceList.vue";
import RepositoryList from "@/views/upload-and-index/components/RepositoryList.vue";
import OrDivider from "@/components/common/OrDivider.vue";
import { mapState } from "vuex";
import { fileSupport } from "@/helpers/file-format.js";

export default {
  name: "Dropzone",

  components: { WorkspaceList, RepositoryList, OrDivider },

  props: {
    workspaceId: {
      type: Number,
      required: true,
    },

    repositoryId: {
      type: Number,
      required: true,
    },

    hasMultiple: {
      type: Boolean,
      default: true,
    },

    showWorkspaceRepository: {
      type: Boolean,
      default: true,
    },

    tabIndexing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDragging: false,
    };
  },

  computed: {
    ...mapState(["session"]),

    fileSizeLimit() {
      if (!this.session.license) {
        return 104857600;
      }

      return 104857600;
    },
  },

  methods: {
    handleChange(e) {
      // console.log(e);
      const files = e.target.files;
      const validFiles = [];
      const invalidFileTypes = ["exe"];

      if (!files.length) {
        return;
      }

      let totalFileSize = 0;
      if (files.length > 50) {
        this.$alert.warning(`Maximum file count exceeded. Limit upto 50`);
        return;
      }

      for (let file of files) {
        totalFileSize += file.size;
        if (file.size > this.fileSizeLimit) {
          this.$alert.warning(`${file.name} - Maximum file size exceeded`);
          return;
        }

        const fileType = file.name.split(".").reverse()[0];
        if (invalidFileTypes.includes(fileType)) {
          this.$alert.warning(`${file.name} - Invalid file type`);
          return;
        }

        if (!fileSupport(fileType)) {
          this.$alert.warning(`${file.name} - Invalid file type`);

          return;
        }

        validFiles.push({
          id: this.$nano.id(),
          name: file.name,
          type: fileType.slice(0, 4),
          size: file.size,
          createdAt: "",
          status: "UPLOADING",
          file: file,
          uploadedPercentage: 0,
        });
      }

      if (totalFileSize > this.fileSizeLimit) {
        this.$alert.warning(`Maximum file size exceeded. Limit upto 100 MB`);
        return;
      }

      this.$refs.dropzone.value = "";
      this.$emit("upload", validFiles);
    },
  },
};
</script>

<style lang="scss" scoped>
#dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  margin: 16px;
  border-radius: 4px;
  border: 1px dashed var(--divider-color);
  &:not(.is-indexing) {
    height: max(400px, calc(100vh - 356px));
  }

  &.is-dragging {
    border: 1px dashed var(--secondary);

    .icon {
      color: var(--secondary);
    }
  }

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }

  .hint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      color: var(--body-text-color-inverted);
    }

    .title {
      @extend .title-2;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .description {
      color: var(--icon-color);
      text-align: center;
    }
  }
}
</style>
