var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"uploaded-file-list"}},[_c('FileList',{staticClass:"q-mb-md",attrs:{"has-files":_vm.hasFiles,"has-selected-files":_vm.hasSelectedFiles,"number-of-files":_vm.files.length},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.label)?_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_c('span',[_vm._v("Uploaded")])]},proxy:true},{key:"actions-maximized",fn:function(){return [(_vm.hasSelectedFiles)?[_c('BaseActionButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMergeBtn),expression:"showMergeBtn"},{name:"tooltip",rawName:"v-tooltip.top",value:('merge'),expression:"'merge'",modifiers:{"top":true}}],attrs:{"is-flat":"","icon":"mdi-text-box-plus-outline"},on:{"click":function($event){$event.stopPropagation();{
              _vm.isSelectAll = false;
              _vm.$emit('merge');
            }}}}),(_vm.showMergeBtn)?_c('BaseSeparator',{staticClass:"q-mx-md",attrs:{"has-inset":"","is-vertical":""}}):_vm._e(),_c('BaseActionButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAutoProcessBtn),expression:"showAutoProcessBtn"},{name:"tooltip",rawName:"v-tooltip.top",value:('move to autoprocess'),expression:"'move to autoprocess'",modifiers:{"top":true}}],staticClass:"q-mr-sm",attrs:{"is-flat":"","icon":"mdi-robot-outline"},on:{"click":function($event){$event.stopPropagation();{
              _vm.isSelectAll = false;
              _vm.$emit('auto-process');
            }}}}),_c('BaseActionButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.showManualIndexBtn),expression:"showManualIndexBtn"},{name:"tooltip",rawName:"v-tooltip.top",value:('move to manual index'),expression:"'move to manual index'",modifiers:{"top":true}}],attrs:{"is-flat":"","icon":"mdi-label-multiple-outline"},on:{"click":function($event){$event.stopPropagation();{
              _vm.isSelectAll = false;
              _vm.$emit('manual-index');
            }}}}),_c('BaseSeparator',{staticClass:"q-mx-md",attrs:{"has-inset":"","is-vertical":""}}),_c('BaseActionButton',{directives:[{name:"tooltip",rawName:"v-tooltip:red.top",value:('Delete'),expression:"'Delete'",arg:"red",modifiers:{"top":true}}],staticClass:"q-mr-md",attrs:{"is-flat":"","color":"red","icon":"eva-trash-2-outline"},on:{"click":function($event){$event.stopPropagation();{
              _vm.isSelectAll = false;
              _vm.$emit('delete');
            }}}})]:_vm._e(),(_vm.files.length)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"}),_c('BaseIcon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('select all'),expression:"'select all'",modifiers:{"top":true}}],staticClass:"q-mx-sm cursor-pointer col-auto",attrs:{"name":_vm.isSelectAll
              ? 'eva-checkmark-circle-2'
              : 'eva-radio-button-off-outline',"color":_vm.isSelectAll ? 'secondary' : 'grey'},on:{"click":function($event){$event.stopPropagation();return _vm.selectAllFile.apply(null, arguments)}}})],1):_vm._e()]},proxy:true},{key:"default",fn:function(){return _vm._l((_vm.files),function(file){return _c('FileListItem',{key:file.id,attrs:{"file":file,"is-selected":_vm.isFileSelected(file)},on:{"view":function($event){return _vm.$emit('view', file)},"selectFile":function($event){return _vm.fileSelect(file)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [(file.isSplitted)?_c('div',{staticClass:"splitted"},[_vm._v("splitted")]):_vm._e(),(file.isMerged)?_c('div',{staticClass:"merged"},[_vm._v("merged")]):_vm._e()]},proxy:true},{key:"status",fn:function(){return [_c('BaseIcon',{attrs:{"name":"mdi-circle-small"}}),_c('div',{class:_vm.getStatusColor(file.uploadedFrom)},[_vm._v(" "+_vm._s(file.uploadedFrom)+" ")])]},proxy:true}],null,true)},[_c('BaseIcon',{staticClass:"q-mr-sm",attrs:{"name":_vm.isFileSelected(file)
              ? 'eva-checkmark-circle-2'
              : 'eva-radio-button-off-outline',"is-inactive":!_vm.isFileSelected(file),"color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select', file)}}}),_c('BaseActionButton',{attrs:{"is-flat":"","no-border":"","icon":"eva-more-vertical"},on:{"click":function($event){$event.stopPropagation();}}},[_c('q-menu',{attrs:{"transition-show":"scale","transition-hide":"scale","anchor":"bottom end","self":"top right","content-class":"q-py-sm"}},_vm._l((_vm.getActions(file)),function(action){return _c('ListItem',{key:action.id,attrs:{"icon":action.icon,"label":action.label},on:{"click":function($event){return _vm.$emit(action.value, file)}}})}),1)],1)],1)})},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }