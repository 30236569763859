<template>
  <div>
    <div v-if="expand" id="repository_overview-dashboard">
      <div
        style="
          cursor: pointer;
          padding-bottom: 14px;
          border-bottom: 1px solid var(--divider-color);
        "
        class="row"
        @click.stop="expand = false"
      >
        <span class="col-auto"
          ><BaseIcon
            v-tooltip.top="'repositoryOverView'"
            :name="'eva-archive-outline'"
            :color="'secondary'"
            class="q-mr-sm q-pb-sm"
            size="20px"
        /></span>
        <div class="col">
          <span class="q-pt-xxl text-base font-bold items-center text-black"
            >{{ findValuename }}
            <div
              class="q-mt-xs text-base font-bold items-center"
              style="color: #475569; width: 100%"
            >
              {{ findValue.description }}
            </div>
          </span>
        </div>
        <!-- <span class="col-5"  style="width: 58%">
          <div class="row">
            <div class="col-5 q-mr-sm items-center row filerItem " @click.stop="stopEvent">
              <span
                class="q-ml-sm q-mt-sm q-mb-sm ellipsis"
                style="cursor: pointer; max-width: 55px; border-right: 0px"
              >
                <span class="text-base font-bold text-black items-center">{{
                  displayFromDate
                }}</span></span
              >
              <span
                class="q-mt-sm q-mb-sm ellipsis"
                style="cursor: pointer; max-width: 40px; border-right: 0px"
              >
                <span class="text-base font-bold text-black items-center"
                  >to</span
                ></span
              >
              <span
                class="q-mr-sm q-ml-xs q-mt-sm q-mb-sm ellipsis"
                style="cursor: pointer; max-width: 55px; border-right: 0px"
              >
                <span
                  class="text-base font-bold text-black items-center text-align-right"
                  >{{ displayToDate }}</span
                ></span
              >
            </div>
          </div>
        </span> -->

        <div v-if="displayFromDate" class="icon-container">
          <BaseActionButton
            is-flat
            no-border
            icon="eva-eye"
            color="primary"
            class="q-ml-xs col-auto icon"
          />
          <div class="iconcontent" @click.stop="">
            <div class="col-12 q-mr-sm row items-center">
              <span
                class="q-ml-sm q-mr-xs q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center filerItem"
                style="cursor: pointer; max-width: 100px; border-right: 0px"
              >
                {{ displayFromDate }}</span
              >
              <span
                class="q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center"
                style="cursor: pointer; max-width: 20px; border-right: 0px"
              >
                To</span
              >
              <span
                class="q-mr-sm q-ml-sm q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center text-align-right filerItem"
                style="cursor: pointer; max-width: 100px; border-right: 0px"
              >
                {{ displayToDate }}</span
              >
            </div>
          </div>
        </div>

        <BaseActionButton
          v-tooltip.top="'refresh'"
          is-flat
          no-border
          icon="eva-refresh"
          class="q-ml-sm col-auto"
          @click.stop="refresh"
        />
        <!-- <BaseActionButton
          v-tooltip.top="'export'"
          is-flat
          no-border
          icon="mdi-table-arrow-right"
          class="q-ml-sm"
          @click.stop="export_Data"
        /> -->
        <BaseActionButton
          v-tooltip.top="'close'"
          is-flat
          no-border
          icon="eva-arrow-ios-upward-outline"
          class="q-ml-sm col-auto"
          @click.stop="expand = false"
        />
        <BaseActionButton
          v-tooltip.top="'unpin'"
          icon="mdi-pin"
          color="secondary"
          class="col-auto"
          no-border
          is-flat
          @click.stop="deleteItem"
        />
      </div>

      <div class="chart chartBox">
        <div v-if="filterListData.type === 'uploadFiles'" class="column">
          <div
            class="col-12"
            style="
              border-radius: 10px;
              padding: 0px 20px 0px 20px;
              margin-top: 10px;
            "
          >
            <OverviewGrid
              :credits="credits"
              :chart-view="false"
              :dashboard-view="true"
              @pinRecentActivity="saveFilter('uploadFiles')"
            ></OverviewGrid>

            <!-- <RecentGrid></RecentGrid> -->
          </div>
        </div>
        <div v-if="filterListData.type === 'recentActivity'">
          <div
            class="col-12"
            style="
              border-radius: 10px;
              padding: 0px 15px 0px 15px;
              margin-top: 10px;
            "
          >
            <div
              id="detailsGrid"
              class="col-6"
              style="width: 100%; margin-left: 5px"
            >
              <div class="grid">
                <div
                  class="col row items-center text-base col q-pa-sm font-bold text-uppercase heading"
                  style="padding: 16px; width: 100%"
                >
                  <div style="width: 100%">Recent Activity</div>
                </div>

                <!--Grid 2-->
                <BaseScrollbar height="234px">
                  <table style="width: 100%">
                    <thead>
                      <tr>
                        <th class="th text-center">Activity On</th>
                        <th class="th text-center" style="width: 20%">
                          Activity
                        </th>
                        <th class="th text-center">Activity By</th>
                        <th
                          class="th text-center"
                          style="width: 30%; border-right: 0px"
                        >
                          File Name
                        </th>
                      </tr>
                    </thead>

                    <tbody style="width: 100%">
                      <tr
                        v-for="(activityRow, index) in recentActivity"
                        :key="index"
                      >
                        <td class="td">
                          {{ $day.format(activityRow.activityOn) }}
                        </td>
                        <td class="td" style="width: 20%">
                          {{ activityRow.activityName }}
                        </td>
                        <td class="td">
                          {{ activityRow.activityBy || "-" }}
                        </td>

                        <td
                          class="td font-medium ellipsis"
                          style="
                            cursor: pointer;
                            text-decoration: underline;
                            max-width: 200px;
                            border-right: 0px;
                          "
                          @click="openFile(activityRow.itemId)"
                        >
                          {{ activityRow.ifileName }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </BaseScrollbar>
                <!--grid 2-->
              </div>
            </div>
            <FileSheet
              v-model="isFileSheetVisible"
              :repository="true"
              :file="selectedFile"
              :repository-field="[]"
              :options="{}"
            />
            <!-- <RecentGrid></RecentGrid> -->
          </div>
        </div>
        <div v-if="filterListData.type === 'TotalFiles'">
          <div
            id="detailsGrid"
            class="col-12"
            style="width: 100%; margin-left: 0%"
          >
            <div class="grid">
              <!--heading-->
              <div class="row items-center1 heading">
                <div class="col row items-center">
                  <div class="text-base col q-pa-sm font-bold text-uppercase">
                    Total Files Uploaded
                  </div>
                </div>
                <div class="row items-center" style="margin-right: 5px">
                  <div
                    v-if="repositoryId"
                    class="row items-center"
                    style="cursor: pointer"
                    @click="uploadFiles"
                  >
                    <BaseActionButton
                      v-tooltip.top="'Upload File'"
                      size="20px"
                      icon="mdi-plus"
                      class="icons"
                    /><span
                      class="text-base col q-pa-xs font-bold text-uppercase"
                      >Upload Files</span
                    >
                  </div>
                </div>
              </div>
              <!--heading-->
              <!--Grid 1-->

              <div class="row items-center1 content">
                <div class="col row items-center">
                  <div class="text-base col q-pa-sm font-bold text-uppercase">
                    <apexchart
                      type="bar"
                      height="150"
                      width="100%"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </div>
              </div>

              <div class="row items-center1" style="border-radius: 10px">
                <div
                  class="text-base col q-pa-sm font-bold text-uppercase"
                  style="
                    color: rgb(156 223 229);
                    border-right: 1px solid var(--divider-color);
                    height: 73px;
                  "
                >
                  Standard
                  <div
                    class="text-base col q-pa-sm font-bold text-uppercase text-black"
                  >
                    {{ credits.STANDARD || 0 }}
                  </div>
                </div>
                <div
                  class="text-base col q-pa-sm font-bold text-uppercase"
                  style="color: rgb(207 172 239)"
                >
                  Advanced
                  <div
                    class="text-base col q-pa-sm font-bold text-uppercase text-black"
                  >
                    {{ credits.ADVANCED || 0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="filterListData.type === 'repositoryChart'">
          <div
            id="chart"
            class="col-12 q-pr-sm q-mb-sm"
            style="padding: 0px 17px 0px 12px; margin-top: 5px"
          >
            <div class="chartview">
              <div class="row item-center q-body q-mb-sm">
                <div
                  class="col-auto q-mr-xs cursor-pointer header"
                  :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.month = '';
                    chartDataDrilldownStage.week = '';
                    getOcrType();
                  "
                >
                  <strong
                    >{{ weekValue }} {{ monthValue }} {{ yearValue }}
                  </strong>
                  <!-- <div v-if="!dashboardView" style="width: 5%">
                  <BaseActionButton
                    v-if="dashboard"
                    icon="mdi-pin"
                    color="secondary"
                    no-border
                    is-flat
                    size="20px"
                    rotate="rotate-45"
                    @click="$emit('isDelete')"
                  />
                  <BaseActionButton
                    v-else
                    icon="mdi-pin"
                    color="gray"
                    no-border
                    is-flat
                    size="20px"
                    rotate="rotate-45"
                    @click="saveFilter('repositoryChart')"
                  />
                </div> -->
                </div>
                <div
                  v-if="chartDataDrilldownStage.month"
                  class="col-auto cursor-pointer header"
                  :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.week = '';
                    getOcrType(chartDataDrilldownStage.month);
                  "
                >
                  / {{ chartDataDrilldownStage.month }}
                </div>
                <div
                  v-if="chartDataDrilldownStage.week"
                  class="col-auto q-ml-xs header"
                  @click="chartDataDrilldownStage.week = ''"
                >
                  / {{ chartDataDrilldownStage.week }}
                </div>
              </div>
              <div>
                <vue-highcharts
                  ref="refWorkflowChart"
                  :highcharts="Highcharts"
                  :options="chartData"
                ></vue-highcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-12">
      <div
        id="repository_overview-dashboard"
        style="cursor: pointer"
        class="text-base font-bold items-center text-black row"
        @click="findItem"
      >
        <span class="col-auto"
          ><BaseIcon
            v-tooltip.top="'repositoryOverView'"
            :name="'eva-archive-outline'"
            :color="'secondary'"
            class="q-mr-sm q-pb-sm"
            size="20px"
        /></span>
        <div class="col">
          <span class="q-pt-xxl text-base font-bold items-center text-black"
            >{{ findValuename }}
            <div
              class="q-mt-xs text-base font-bold items-center"
              style="color: #475569; width: 100%"
            >
              {{ findValue.description }}
            </div>
          </span>
        </div>
        <!-- <span class="col-5"  style="width: 58%">
          <div class="row">
            <div class="col-5 q-mr-sm items-center row filerItem" @click.stop="stopEvent">
              <span
                class="q-ml-sm q-mt-sm q-mb-sm ellipsis"
                style="cursor: pointer; max-width: 55px; border-right: 0px"
              >
                <span class="text-base font-bold text-black items-center">{{
                  displayFromDate
                }}</span></span
              >
              <span
                class="q-mt-sm q-mb-sm ellipsis"
                style="cursor: pointer; max-width: 40px; border-right: 0px"
              >
                <span class="text-base font-bold text-black items-center"
                  >to</span
                ></span
              >
              <span
                class="q-mr-sm q-ml-sm q-mt-sm q-mb-sm ellipsis"
                style="cursor: pointer; max-width: 55px; border-right: 0px"
              >
                <span
                  class="text-base font-bold text-black items-center text-align-right"
                  >{{ displayToDate }}</span
                ></span
              >
            </div>
          </div>
        </span> -->

        <div v-if="displayFromDate" class="icon-container">
          <BaseActionButton
            is-flat
            no-border
            icon="eva-eye"
            color="primary"
            class="q-ml-xs col-auto icon"
          />
          <div class="iconcontent" @click.stop="">
            <div class="col-12 q-mr-sm row items-center">
              <span
                class="q-ml-sm q-mr-xs q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center filerItem"
                style="cursor: pointer; max-width: 100px; border-right: 0px"
              >
                {{ displayFromDate }}</span
              >
              <span
                class="q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center"
                style="cursor: pointer; max-width: 20px; border-right: 0px"
              >
                To</span
              >
              <span
                class="q-mr-sm q-ml-sm q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center text-align-right filerItem"
                style="cursor: pointer; max-width: 100px; border-right: 0px"
              >
                {{ displayToDate }}</span
              >
            </div>
          </div>
        </div>

        <BaseActionButton
          v-tooltip.top="'refresh'"
          is-flat
          no-border
          icon="eva-refresh"
          class="q-ml-sm col-auto"
          @click.stop="refresh"
        />
        <BaseActionButton
          v-tooltip.top="'open'"
          is-flat
          no-border
          icon="eva-arrow-ios-downward-outline"
          class="q-ml-sm col-auto"
          @click.stop="findItem"
        />
        <BaseActionButton
          v-tooltip.top="'unpin'"
          icon="mdi-pin"
          color="secondary"
          class="col-auto"
          no-border
          is-flat
          @click.stop="deleteItem"
        />
      </div>
    </div>
    <ConfirmDelete
      v-model="isDeleteConfirmationVisible"
      :label="findValuename"
      :delete-data="deleteData"
      @delete="$emit('delete')"
    />
  </div>
</template>

<script>
import OverviewGrid from "@/views/repositories/views/repository-overview/OverviewGrid.vue";
import { repository } from "@/api/factory.js";
import FileSheet from "@/views/repositories/views/repository-browse-and-trash/components/FileSheet.vue";
import VueHighcharts from "vue2-highcharts";
import Exporting from "highcharts/modules/exporting.js";
import Drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts";
import ConfirmDelete from "@/components/common/display/item-actions/components/UnpinDashboard.vue";

Drilldown(Highcharts);
Exporting(Highcharts);
export default {
  name: "DashboardWorkflowOverview",

  components: { OverviewGrid, FileSheet, VueHighcharts, ConfirmDelete },

  props: {
    item: {
      type: Object,
      required: true,
    },
    listedData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      expand: false,
      credits: {},
      repositoryId: 0,
      recentActivity: [],
      recentCount: 5,
      selectedFile: {},
      isFileSheetVisible: false,
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        colors: ["#d4a4dd", "#9cf1f7"],
        stroke: {
          width: 1,
          colors: ["#d4a4dd", "#9cf1f7"],
        },
        title: {
          text: "",
        },
        xaxis: {
          categories: ["Total Files"],
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " " + "files";
            },
          },
        },
        fill: {
          opacity: 1,
          colors: ["#d4a4dd", "#9cf1f7"],
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      workspaceId: 0,
      Highcharts: Highcharts,
      chartData: {
        chart: {
          type: "spline",
          height: "330px",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "Count",
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: ["#d4a4dd", "#858084", "#9cf1f7"],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name && e.point.y) {
                    if (this.chartDataDrilldownStage.month === "") {
                      this.chartDataDrilldownStage.month = e.point.name;
                      this.getOcrType(e.point.name);
                    } else if (this.chartDataDrilldownStage.week === "") {
                      this.chartDataDrilldownStage.week = e.point.name;
                      this.getOcrType(
                        this.chartDataDrilldownStage.month,
                        e.point.name.replace("Week ", "")
                      );
                    }
                  }
                },
              },
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
        drilldown: {},
      },
      chartDataDrilldownStage: { month: "", week: "" },
      yearValue: "",
      monthValue: "",
      weekValue: "",
      findValuename: "",
      keyValue: "",
      fromDate: "",
      todate: "",
      displayFromDate: "",
      displayToDate: "",
      deleteData: {},
      isDeleteConfirmationVisible: false,
      filterListData: {},
    };
  },

  computed: {},

  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        if (this.item) {
          this.findValue = this.item;
          this.expand = this.findValue.expand;
          console.log(this.item, "itemed");
          let filter = JSON.parse(this.item.filter);
          this.displayFromDate = filter[0].fromDate;
          this.displayToDate = filter[0].toDate;
          if (this.findValue.label) {
            this.findValuename = this.removeTimestampFromString(
              this.findValue.label
            );
          }
        }
      },
    },
    credits: {
      deep: true,
      handler() {
        if (this.credits) {
          this.series = [];
          this.series.push({
            name: "Advanced",
            data: [this.credits.ADVANCED || 0],
          });
          this.series.push({
            name: "Standard",
            data: [this.credits.STANDARD || 0],
          });
        }
      },
    },

    // workspaceId: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     if (this.workspaceId) {
    //       this.getRepositories();
    //     }
    //   },
    // },

    // filterId: {
    //   deep: true,
    //   immediate: true,
    //   async handler() {
    //     if (this.filterId === "-1") {
    //       this.showFilterList = true;
    //       this.getFilterData();
    //     } else if (this.filterId) {
    //       this.showFilterList = false;
    //       this.filterList();
    //       this.selectedIds = this.filterId;
    //     } else if (!this.filterId) {
    //       this.showFilterList = false;
    //       this.repositoryReportList = [];
    //     }
    //   },
    // },
  },

  created() {},

  methods: {
    stopEvent() {
      // console.log(this.workflowList)
    },
    refresh() {
      if (this.filterListData.type === "repositoryChart") {
        this.getchart();
      } else if (this.filterListData.type === "recentActivity") {
        this.getRecendActivity();
      } else if (this.filterListData.type === "TotalFiles") {
        this.getRepositoryOverView();
      } else if (this.filterListData.type === "uploadFiles") {
        this.getData();
      }
    },
    removeTimestampFromString(str) {
      return str
        .replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/, "")
        .trim();
    },
    deleteItem() {
      this.listedData.map((item) => {
        if (item.name === this.findValue.label) {
          console.log(item, "itemd");

          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },
    handleOpen() {
      this.findItem();
      this.expand = true;
    },
    async findItem() {
      console.log(this.listedData, "this.listedData");
      this.listedData.map((item) => {
        if (item.name === this.findValue.label) {
          this.expand = true;
          const parsedArray = JSON.parse(item.filterJson);
          const filters = JSON.parse(parsedArray[0].filters);
          console.log(parsedArray, "parsedArray");
          this.filterListData = {
            name: item.name,
            type: parsedArray[0].type,
            todate: parsedArray[0].toDate,
            fromDate: parsedArray[0].fromDate,
            keyValue: parsedArray[0].keyValue,
            rId: parsedArray[0].uId,
            wId: parsedArray[0].wId,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });
      console.log(this.filterListData, "data");
      this.fromDate = this.filterListData.fromDate;
      this.todate = this.filterListData.todate;
      this.keyValue = String(this.filterListData.keyValue);

      this.filterFrom = this.filterListData.filterFrom;
      this.repositoryId = Number(this.filterListData.rId);
      this.workspaceId = Number(this.filterListData.wId);
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      //   await this.getRepositoryOverView();

      if (this.filterListData.type === "repositoryChart") {
        await this.getchart();
      } else if (this.filterListData.type === "recentActivity") {
        await this.getRecendActivity();
      } else if (this.filterListData.type === "TotalFiles") {
        await this.getRepositoryOverView();
      } else if (this.filterListData.type === "uploadFiles") {
        await this.getData();
      }
    },
    async getchart() {
      let keyValue = this.keyValue;
      if (keyValue === "") {
        keyValue = "month";
      }
      this.WeekNumber = new Date(this.fromDate).getDate();
      this.dates = new Date(this.todate).getDate();
      this.chartData.xAxis.categories.splice(0);
      let date;
      if (keyValue === "7days") {
        date = new Date(this.todate);
      } else if (keyValue === "quarter") {
        date = new Date(this.todate);
      } else if (keyValue === "week") {
        date = new Date(this.todate);
      } else {
        date = new Date(this.fromDate);
      }
      let tempDate = new Date(date);
      tempDate.setDate(1);
      let firstDayOfMonth = tempDate.getDay();
      let startOffset = 7 - firstDayOfMonth;
      tempDate.setDate(tempDate.getDate() + startOffset);
      let dayDifference = date.getDate() - tempDate.getDate();
      let weekNumber = Math.ceil((dayDifference + 1) / 7) + 1;
      this.weekno = weekNumber;
      let year;
      let month;
      if (keyValue === "7days") {
        year = new Date(this.todate).getFullYear();
        month = new Date(this.todate).getMonth();
      } else if (keyValue === "quarter") {
        year = new Date(this.todate).getFullYear();
        month = new Date(this.todate).getMonth();
        date = new Date(this.todate);
      } else if (keyValue === "week") {
        year = new Date(this.todate).getFullYear();
        month = new Date(this.todate).getMonth();
        date = new Date(this.todate);
      } else {
        year = new Date(this.fromDate).getFullYear();
        month = new Date(this.fromDate).getMonth();
      }
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let monthName = months[month];
      let input;
      if (keyValue === "Currentmonth") {
        (this.yearValue = "-" + year),
          (this.monthValue = monthName),
          (this.weekValue = ""),
          (input = { year: year, month: monthName, weekno: "" });
      } else if (keyValue === "month") {
        (this.yearValue = "-" + year),
          (this.monthValue = monthName),
          (this.weekValue = ""),
          (input = { year: year, month: monthName, weekno: "" });
      } else if (keyValue === "currentyear") {
        (this.yearValue = " Year -" + year),
          (this.monthValue = ""),
          (this.weekValue = ""),
          (input = { year: year, month: "", weekno: "" });
      } else if (keyValue === "year") {
        (this.yearValue = " Year -" + year),
          (this.monthValue = ""),
          (this.weekValue = ""),
          (input = { year: year, month: "", weekno: "" });
      } else if (keyValue === "today") {
        (this.yearValue = "-" + year),
          (this.monthValue = "-" + monthName),
          (this.weekValue = this.dates),
          (input = { year: year, month: monthName, weekno: this.weekno });
      } else if (keyValue === "yesterday") {
        (this.yearValue = "-" + year),
          (this.monthValue = "-" + monthName),
          (this.weekValue = +this.dates),
          (input = { year: year, month: monthName, weekno: this.weekno });
      } else if (keyValue === "7days") {
        (this.yearValue = this.dates + "-" + monthName + "-" + year),
          (this.monthValue =
            this.WeekNumber + "-" + monthName + "-" + year + "  " + "to"),
          (this.weekValue = ""),
          (input = { year: year, month: monthName, weekno: this.weekno });
      } else if (keyValue === "week") {
        (this.yearValue = this.dates + "-" + monthName + "-" + year),
          (this.monthValue =
            this.WeekNumber + "-" + monthName + "-" + year + "  " + "to"),
          (this.weekValue = ""),
          (input = { year: year, month: monthName, weekno: this.weekno });
      } else if (keyValue === "quarter") {
        (this.weekValue = ""),
          (this.monthValue = ""),
          (this.yearValue = year),
          (input = { year: year, month: "", weekno: "" });
      }
      this.columns = [];
      //   this.$store.commit("showLoadingBar");
      this.chartData.series = [];
      const { error, payload } = await repository.getOcrType(
        this.repositoryId || 0,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let yAxis = [];
        let data = JSON.parse(payload.data);
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const currentMonthIndex = new Date(this.todate).getMonth();
        let advancedData = [];
        let manualData = [];
        let standardData = [];
        for (let i = 0; i < 3; i++) {
          let index = (currentMonthIndex - i + 12) % 12;
          let monthName = months[index];
          advancedData.push(Number(data.ADVANCED[monthName]) || 0);
          manualData.push(Number(data.MANUAL[monthName]) || 0);
          standardData.push(Number(data.STANDARD[monthName]) || 0);
        }
        advancedData.reverse();
        manualData.reverse();
        standardData.reverse();
        if (keyValue === "quarter") {
          yAxis.push({
            name: "Advanced",
            data: advancedData.map((item) => Number(item)),
          });

          yAxis.push({
            name: "Manual",
            data: manualData.map((item) => Number(item)),
          });
          yAxis.push({
            name: "Standard",
            data: standardData.map((item) => Number(item)),
          });
          this.chartData.series = yAxis;
          let lineCharts = this.$refs.refWorkflowChart;
          try {
            lineCharts.removeSeries();
          } catch (e) {
            //
          }

          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          const currentMonthIndex = new Date(2024, 5, 30).getMonth();
          let beforeThreeMonths = [];
          for (let i = 0; i < 3; i++) {
            let index = (currentMonthIndex - i + 12) % 12;
            let monthName = months[index].slice(0, 3); //
            beforeThreeMonths.unshift(monthName);
          }
          this.chartData.xAxis.categories.push(...beforeThreeMonths);
          this.quaterData = this.chartData.xAxis.categories;
          (this.weekValue = this.quaterData[0] + ","),
            (this.monthValue = this.quaterData[1] + ","),
            (this.yearValue = this.quaterData[2] + ","),
            lineCharts.addSeries(this.chartData.series[0]);
          lineCharts.addSeries(this.chartData.series[1]);
          lineCharts.addSeries(this.chartData.series[2]);
        } else {
          yAxis.push({
            name: "Advanced",
            data: Object.values(data.ADVANCED).map(Number),
          });
          yAxis.push({
            name: "Manual",
            data: Object.values(data.MANUAL).map(Number),
          });
          yAxis.push({
            name: "Standard",
            data: Object.values(data.STANDARD).map(Number),
          });
          this.chartData.series = yAxis;
          let lineCharts = this.$refs.refWorkflowChart;
          try {
            lineCharts.removeSeries();
          } catch (e) {
            //
          }
          for (let [key, value] of Object.entries(data.ADVANCED)) {
            let ChartSeries = { key, value };

            this.chartData.xAxis.categories.push([ChartSeries.key.toString()]);
          }
          lineCharts.addSeries(this.chartData.series[0]);
          lineCharts.addSeries(this.chartData.series[1]);
          lineCharts.addSeries(this.chartData.series[2]);
        }
      }
    },

    async getData() {
      const input = { fromDate: this.fromDate, toDate: this.todate };
      this.repositoryReportList = [];
      this.repositoryReportListData = [];
      this.list = [];
      const { error, payload } = await repository.getRepositoryOverView(
        this.repositoryId || 0,
        input
      );
      this.credits = JSON.parse(payload.data);
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    uploadFiles() {
      let workspaceId = this.workspaceId;
      let repositoryId = this.repositoryId;
      this.$router.push({
        name: "uploadAndIndex",
        query: { workspaceId, repositoryId },
      });
    },
    openFile(itemId) {
      let selected = this.recentActivity.find((file) => file.itemId === itemId);
      this.selectedFile = {
        name: selected.ifileName,
        createdAt: this.$day.format(selected.activityOn),
        createdBy: selected.activityBy,
        repositoryId: this.repositoryId,
        id: selected.itemId,
        size: "",
      };
      this.isFileSheetVisible = true;
    },
    async getRecendActivity() {
      this.recentActivity = [];
      const { error, payload } = await repository.getRecendActivity(
        this.repositoryId || 0,
        this.recentCount
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      let activity = JSON.parse(payload.data);
      this.recentActivity = activity.data[0].value;
    },
    async getRepositoryOverView() {
      this.getOcrType();
      this.columns = [];
      let currentDate = new Date();
      let firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        2
      );
      let formattedFirstDayOfMonth = firstDayOfMonth
        .toISOString()
        .split("T")[0];

      let lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      );
      let formattedLastDayOfMonth = lastDayOfMonth.toISOString().split("T")[0];
      this.fromDate = formattedFirstDayOfMonth;
      this.todate = formattedLastDayOfMonth;
      const date = {
        fromDate: this.fromDate,
        toDate: this.todate,
      };
      const { error, payload } = await repository.getRepositoryOverView(
        this.repositoryId || 0,
        date
      );
      this.credits = JSON.parse(payload.data);
      console.log(this.credits, "credits");
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getOcrType() {
      this.columns = [];
      this.chartData.series = [];
      let month = new Date().getMonth();
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let monthName = months[month].toString();
      let input = {
        year: new Date().getFullYear(),
        month: monthName,
        weekno: "",
      };
      (this.yearValue = "-" + input.year),
        (this.monthValue = input.month),
        (this.weekValue = "");
      const { error, payload } = await repository.getOcrType(
        this.repositoryId || 0,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let yAxis = [];

        let data = JSON.parse(payload.data);
        yAxis.push({
          name: "Advanced",
          data: Object.values(data.ADVANCED).map(Number),
        });
        yAxis.push({
          name: "Manual",
          data: Object.values(data.MANUAL).map(Number),
        });
        yAxis.push({
          name: "Standard",
          data: Object.values(data.STANDARD).map(Number),
        });
        this.chartData.series = yAxis;
        let lineCharts = this.$refs.refWorkflowChart;
        try {
          lineCharts.removeSeries();
        } catch (e) {
          //
        }
        for (let [key, value] of Object.entries(data.ADVANCED)) {
          let ChartSeries = { key, value };

          this.chartData.xAxis.categories.push([ChartSeries.key.toString()]);
        }
        lineCharts.addSeries(this.chartData.series[0]);
        lineCharts.addSeries(this.chartData.series[1]);
        lineCharts.addSeries(this.chartData.series[2]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#repository_overview-dashboard {
  background-color: white;
  padding: 14px;
  border-radius: 10px;
  width: 99.5%;
  margin: 5px;
  .content {
    padding-top: 8px;
  }
  .icon-container {
    display: inline-block;
    position: relative;
  }

  .icon {
    font-size: 24px;
    cursor: pointer;
  }

  .iconcontent {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    width: max-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateX(-10px);
  }

  .icon:hover + .iconcontent {
    display: block;
    opacity: 1;
    transform: translateX(0);
  }
  .filerItem {
    background-color: rgb(234 235 185);
    border-radius: 20px;
    padding: 8px;
  }
  .backGround {
    border: 2px;
    border-radius: 10px;
    background-color: #87dcf626;
    margin-bottom: 8px;
  }
  #detailsGrid {
    border-radius: 10px;
    background-color: var(--component-bg-color);
    padding: 10px;
    .grid {
      width: 100%;
      border: 1px solid var(--divider-color);
      border-radius: 10px;
      .description {
        color: $primary-11 !important;
      }
      .heading {
        background-color: var(--body-bg-color);
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid var(--divider-color);
        padding: 10px;
      }
      .content {
        border-bottom: 1px solid var(--divider-color);
        height: 162px;
      }
      .icons {
        width: 20%;
        background-color: #643094 !important;
      }
      .fileName:hover {
        color: blue;
      }
    }
    .th {
      color: #00bcd4;
      border-right: 1px solid var(--divider-color);
      border-bottom: 1px solid var(--divider-color);
      width: 25%;
      padding: 8px;
    }
    .td {
      border-right: 1px solid var(--divider-color);
      width: 25%;
      border-bottom: 1px solid var(--divider-color);
      padding: 8px;
    }
  }
  .ellipsis:hover {
    word-wrap: break-word;
    white-space: initial;
    color: #00bcd4;
    overflow: visible;
  }
}
</style>
