<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="validationRules"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <CurrencyAmountField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      transform=""
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :error="errors[0]"
      :highlight="highlight"
      :options="options"
      :new-option="allowToAddNewOptions"
      @input="handleInput"
      @verify="$emit('verify')"
      @focusOut="handleFocusOut"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
// import TextField from "@/components/common/form/text-field/TextField.vue";
import { axiosCrypto } from "@/api/axios.js";
import CurrencyAmountField from "@/components/common/form/currency-field/CurrencyAmountField.vue";
import { common } from "@/api/factory.js";

export default {
  name: "CurrencyAmountFieldWrapper",

  components: { ValidationProvider, CurrencyAmountField },

  props: {
    // value: {
    //   type: String,
    //   required: true,
    // },
    value: {
      type: Object,
      required: true,
      // default: () => ({
      //   currency: "",
      //   value: "",
      // }),
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    filter: {
      type: Number,
      default: 0,
    },

    formId: {
      type: String,
      default: "0",
    },

    formSettings: {
      type: Object,
      required: true,
    },

    // options: {
    //   type: Array,
    //   default: () => [],
    // },
  },

  data() {
    return {
      position: 0,
      value1: "",
      options: [],
    };
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.isMandatory && !this._isReadonly) {
        rules.required = true;
      }

      if (this.contentRule === "BOTH") {
        rules.currency = true;
      } else if (this.contentRule === "COMMAS") {
        rules.commas = true;
      } else if (this.contentRule === "DECIMAL") {
        rules.decimal = true;
      } else {
        rules.number = true;
      }

      return rules;
    },

    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    contentRule() {
      return this.field.settings.validation.contentRule;
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    allowToAddNewOptions() {
      return this.field.settings.specific.allowToAddNewOptions;
    },

    _value1() {
      if (this.contentRule === "COMMAS" || this.contentRule === "BOTH") {
        let amount = this.value.split(".");
        let value = amount[0]
          .replace(new RegExp(",", "g"), "")
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // if()
        // console.log(value);
        if (amount[1]) {
          let value1 = value.replace(new RegExp(",", "g"), "");
          let format = `${Number(value1)}.${amount[1]}`;
          // console.log(format);
          let price = Number(format).toFixed(2);
          let price1 = price
            .replace(new RegExp(",", "g"), "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return price1;
        }
        return value;
      } else if (this.contentRule === "DECIMAL") {
        let decimal = this.value.split(".");
        // console.log(decimal.length, decimal);
        if (decimal.length == 2) {
          let price = this.value;
          if (decimal[1]) {
            price = Number(this.value).toFixed(2);
          }

          return price;
        } else {
          return this.value;
        }
      }

      return this.value;
    },
  },

  watch: {
    filter: {
      // immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          }
        }
      },
    },

    value() {
      this.value1 = this.value.value;
    },

    field: {
      immediate: true,
      // deep: true,
      handler() {
        if (!this.value) {
          if (this.field.settings.specific.masterFormParentColumn) {
            this.$emit(
              "getParentColumnValue",
              this.field.settings.specific.masterFormParentColumn
            );
          }
        }
      },
    },
  },

  created() {
    const optionsType = this.field.settings.specific.optionsType;
    if (optionsType === "CUSTOM") {
      this.options = this.getCustomOptions();
      if (this.value.currency) {
        let optVal = this.options.find(
          (row) => row.label === this.value.currency
        );
        if (!optVal) {
          if (this.value.currency) {
            this.options.push({
              id: this.$nano.id(),
              label: this.value.currency,
              value: this.value.currency,
            });
          }
        }
      }
    } else if (optionsType === "MASTER") {
      this.getMasterEntries(
        this.field.settings.specific.masterFormId,
        this.field.settings.specific.masterFormColumn
      );
    } else if (
      this.formId &&
      optionsType === "EXISTING" &&
      this.formSettings.publish.publishOption === "PUBLISHED"
    ) {
      this.getOptionFromEntries(this.field);
    } else if (
      optionsType === "ALL_CURRENCY" ||
      optionsType === "SPECIFIC_CURRENCY"
    ) {
      this.getCurrencyList(optionsType);
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    getCustomOptions() {
      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";

      let customOptions = this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
      return customOptions;
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          const parsedArray = [];
          options.forEach((item) => {
            try {
              const jsonString = item.trim();
              const array = JSON.parse(jsonString);
              array.forEach((value) => {
                if (!parsedArray.includes(value)) {
                  parsedArray.push(value);
                }
              });
            } catch (error) {
              if (!parsedArray.includes(item)) {
                parsedArray.push(item);
              }
            }
          });
          this.options = parsedArray.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        }
        let optVal = this.options.find(
          (row) => row.label === this.value.currency
        );
        if (!optVal) {
          if (this.value.currency) {
            this.options.push({
              id: this.$nano.id(),
              label: this.value.currency,
              value: this.value.currency,
            });
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getMasterEntriesFilter(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: field.filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          if (options.length === 1) {
            this.handleInput(options[0]);
          } else {
            this.handleInput(0);
          }
          this.$emit("clearFilter");
        } else {
          this.handleInput(0);
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getOptionFromEntries(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${this.formId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.id,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          console.log(options);
          options.forEach((option) => {
            if (option) {
              let currency = JSON.parse(option);
              console.log(currency);

              if (currency.currency) {
                let index = this.options.findIndex((unique) => {
                  return unique.label === currency.currency;
                });
                if (index === -1) {
                  this.options.push({
                    id: this.$nano.id(),
                    label: currency.currency,
                    value: currency.currency,
                  });
                }
              }
            }
          });
        }
        let optVal = this.options.find(
          (row) => row.label === this.value.currency
        );
        if (!optVal) {
          if (this.value.currency) {
            this.options.push({
              id: this.$nano.id(),
              label: this.value.currency,
              value: this.value.currency,
            });
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    currencyValidation() {
      if (this.value.value) {
        let amountValue = this.value.value;
        if (this.contentRule === "COMMAS" || this.contentRule === "BOTH") {
          let amount = amountValue.split(".");
          let value = amount[0]
            .replace(new RegExp(",", "g"), "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          // if()
          // console.log(value);
          if (amount[1]) {
            let value1 = value.replace(new RegExp(",", "g"), "");
            let format = `${Number(value1)}.${amount[1]}`;
            // console.log(format);
            let price = Number(format).toFixed(2);
            let price1 = price
              .replace(new RegExp(",", "g"), "")
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return price1;
          }
          // console.log(value, "field");
          return value;
        } else if (this.contentRule === "DECIMAL") {
          let decimal = amountValue.split(".");
          // console.log(decimal.length, decimal);
          if (decimal.length == 2) {
            let price = amountValue;
            if (decimal[1]) {
              price = Number(amountValue).toFixed(2);
            }

            return price;
          } else {
            return amountValue;
          }
        } else {
          return this.value.value;
        }
      }

      return "";
    },

    handleFocusOut() {
      this.value.value = this.currencyValidation();
    },

    async getCurrencyList(optionType) {
      this.currencyList = [];
      let input = {
        sortBy: {
          criteria: "country",
          order: "ASC",
        },
        groupBy: "",
        filterBy: [],
        currentPage: 0,
        itemsPerPage: 0,
        mode: "BROWSE",
      };
      const { error, payload } = await common.getCurrencyList(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.meta.totalItems) {
          if (payload.data.length) {
            if (payload.data[0].value.length) {
              if (optionType === "SPECIFIC_CURRENCY") {
                let filterOptions =
                  this.field.settings.specific.specificCurrencyOptions;
                payload.data[0].value.forEach((row) => {
                  if (filterOptions.indexOf(row.code) > -1) {
                    this.options.push({
                      id: this.$nano.id(),
                      label: `${row.country} (${row.code})`,
                      value: row.code,
                      image: row.flag,
                    });
                  }
                });
              } else {
                payload.data[0].value.forEach((row) => {
                  this.options.push({
                    id: this.$nano.id(),
                    label: `${row.country} (${row.code})`,
                    value: row.code,
                    image: row.flag,
                  });
                });
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
