import { render, staticRenderFns } from "./AuthenticationSettings.vue?vue&type=template&id=89d399e2&scoped=true&"
import script from "./AuthenticationSettings.vue?vue&type=script&lang=js&"
export * from "./AuthenticationSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89d399e2",
  null
  
)

export default component.exports