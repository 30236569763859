<template>
  <div id="code-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />
    <div class="row">
      <div class="col">
        <FormFieldWrapper
          ref="wrapper"
          :is-focused="isFocused"
          :is-clearable="_isClearable"
          :is-readonly="isReadonly"
          :is-disabled="isDisabled"
          :has-error="!!error"
          :highlight="highlight"
          @clear="handleClear"
        >
          <div class="row items-center">
            <div class="col-4">
              <!-- <div
            ref="select"
            class="col select"
            style="min-height: 46px"
            @click="showOptionPicker"
          >
            <div v-if="code" class="value">
              {{ code }}
            </div>

            <input
              v-if="!code"
              ref="searchQuery"
              v-model="searchQuery"
              type="text"
              placeholder="select"
              class="q-px-sm"
              @focus="isFocused = true"
              @keypress.enter="handleKeypress"
            />
          </div>

          <ActionButton icon="eva-chevron-down" @click="showOptionPicker" />

          <BaseSeparator is-vertical has-inset class="q-ml-sm" /> -->

              <!-- Selected Item -->
              <div
                class="dropdown-selected row items-center code"
                @click="showDropdown"
              >
                <template v-if="!searching">
                  <span
                    v-if="selectedCountryCode"
                    :class="`fi fi-${selectedCountryCode.toLowerCase()}`"
                  ></span
                  ><span v-if="selectedCountryCode" class="col q-ml-sm">
                    {{ "+" + callingCode }}</span
                  ><span v-else class="col">
                    <input
                      type="text"
                      :placeholder="_placeholder"
                      class="col"
                    />
                  </span>
                </template>
                <template v-else>
                  <input
                    ref="search"
                    v-model="searchQuery"
                    type="text"
                    :placeholder="_placeholder"
                    class="col"
                  />
                </template>

                <ActionButton icon="eva-chevron-down" @click="showDropdown" />
                <BaseSeparator is-vertical has-inset class="q-ml-sm" />
              </div>
            </div>

            <q-menu
              v-model="isDropdownOpen"
              :target="$refs.wrapper"
              fit
              no-focus
              no-refocus
              no-parent-event
              transition-show="scale"
              transition-hide="scale"
            >
              <OptionsPicker
                v-if="false"
                :value="value.code"
                :options="_options"
                :new-option="newOption"
                @select="handleSelect"
              />

              <div class="dropdown-menu">
                <BaseScrollbar
                  height="256px"
                  class="q-py-sm"
                  @scroll="handleScroll"
                >
                  <div
                    v-for="country in filteredCountries"
                    :key="country.alpha2Code"
                    class="dropdown-item"
                    @click="selectCountry(country)"
                  >
                    <!-- row items-center cursor-pointer q-pa-sm q-pbn-md option-list -->
                    <span
                      :class="`fi fi-${country.alpha2Code.toLowerCase()}`"
                      class="q-mr-sm"
                    ></span>
                    {{ country.name }} {{ "(" + country.callingCode + ")" }}
                  </div>

                  <div
                    v-if="searchQuery && !filteredCountries.length"
                    class="dropdown-item no-results"
                  >
                    No results for "{{ searchQuery }}"
                  </div>
                </BaseScrollbar>
              </div>
            </q-menu>

            <input
              type="number"
              :value="value.phoneNo"
              :autofocus="autoFocus"
              :placeholder="placeholder"
              class="q-px-sm col q-mr-sm"
              @focus="isFocused = true"
              @blur="isFocused = false"
              @input.stop="handleInput"
              @click="$emit('click')"
              @keypress.enter="$emit('enter')"
              @focusout="handleFocusout"
              @mouseleave="handleFocusout"
            />
          </div>
        </FormFieldWrapper>
      </div>
      <div v-if="verificationRequired" class="col-auto" style="margin: auto">
        <div v-if="isOtpVerified || value.verified">
          <BaseIcon
            v-tooltip.top="'Verified'"
            name="mdi-check-all"
            color="green"
            class="q-pl-md"
          />
        </div>
        <div
          v-else-if="!showCodeInput && !isLoading"
          class="cursor-pointer text-green text-bold q-pl-md"
          style="text-decoration: underline"
          @click="handleSendVerification"
        >
          <span> Send Verification </span>
        </div>
        <q-spinner v-if="isLoading" color="green" size="24px" class="q-mx-lg" />
      </div>
    </div>
    <div v-if="showCodeInput" class="q-mt-md text-center verify-panel">
      <div>Enter The Code Generated In Your Phone Number</div>
      <div class="row q-mt-md justify-center q-mb-md">
        <template v-for="(digit, index) in otpArray">
          <input
            :key="index"
            :ref="'otpInput' + index"
            v-model="otpArray[index]"
            type="number"
            maxlength="1"
            class="otp-input q-ml-sm text-center"
            @input="handleOtpInput(index, $event)"
            @keydown.backspace="handleOtpBackspace(index)"
            @paste="handleOtpPaste($event)"
          />
        </template>
      </div>
      <BaseButton
        v-if="isTimerActive"
        label="Verify OTP"
        color="green"
        style="margin: auto"
        :disabled="!isOtpComplete"
        @click="verifyOTP"
      />

      <div class="resend-section q-mt-md">
        <div v-if="isResendDisabled" class="q-mb-sm">
          {{ formattedTimer }}
        </div>
        <span
          v-if="!isResendDisabled"
          class="text-green cursor-pointer text-bold q-mt-sm"
          style="text-decoration: underline"
          :disabled="isResendDisabled"
          @click="resendCode"
        >
          Resend Code
        </span>
        <div v-if="isOtpInvalid" class="text-red">Invalid OTP</div>
      </div>
    </div>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import ActionButton from "@/components/common/form/field-wrapper/components/ActionButton.vue";
import OptionsPicker from "@/components/common/form/select-field/OptionsPicker.vue";
import { debounce } from "lodash-es";
import { common } from "@/api/factory.js";

export default {
  name: "PhoneNumberField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    ActionButton,
    OptionsPicker,
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        code: "",
        phoneNo: "",
        verified: false,
      }),
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    autoFocus: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    newOption: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: () => [],
    },

    verificationRequired: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isFocused: false,
      openLabelInput: false,
      labelError: "",
      searchQuery: "",
      optionPicker: false,
      optionsClone: [],
      countries: [],

      isDropdownOpen: false,
      selectedCountryCode: "",
      selectedCountryName: "",

      callingCode: 0,
      searching: false,
      dropdownEvent: false,
      verifiedPhoneNumber: null,
      isTimerCompleted: false,
      isPhoneEntered: true,
      isOtpVerified: false,
      showCodeInput: false,
      otpArray: Array(6).fill(""),
      timer: 180,
      isResendDisabled: true,
      otpDuration: 180,
      To_number: "",
      getOtpValue: "",
      OtpValue: "",
      isOtpInvalid: false,
      isTimerActive: true,
      isLoading: false,
      formattedTimer: "",
    };
  },

  computed: {
    _isClearable() {
      if (!this.isClearable) {
        return false;
      }

      if (this.value.code || this.value.phoneNo) {
        return true;
      }

      return false;
    },

    _placeholder() {
      if (this.value.code) {
        return "";
      }

      return this.placeholder || "Select";
    },

    filteredCountries() {
      if (!this.searchQuery) {
        return this.countries;
      }
      return this.countries.filter(
        (country) =>
          country.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          country.callingCode
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
      );
    },

    isVerified() {
      return (
        `${this.value.code}${this.value.phoneNo}` ===
        `${this.value.code}${this.value.phoneNo}`
      );
    },

    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
        this.isPhoneEntered = newValue.trim() !== "";
      },
    },

    isOtpComplete() {
      return this.otpArray.every((digit) => digit !== "");
    },
  },

  watch: {
    "value.code": {
      immediate: true,
      async handler() {
        if (this.value.code) {
          try {
            const response = await fetch("https://restcountries.com/v2/all");
            const data = await response.json();
            this.countries = data.map((country) => ({
              name: country.name,
              alpha2Code: country.alpha2Code,
              callingCode: country.callingCodes
                ? country.callingCodes[0]
                : "N/A",
            }));
          } catch (error) {
            console.error("Error fetching country data:", error);
          }
          //console.log(this.countries, "dates");
          let filterData = this.countries.filter(
            (item) => item.callingCode === String(this.value.code)
          );
          // console.log(filterData, "filterData");
          if (filterData.length) {
            this.selectedCountryCode = filterData[0].alpha2Code;
            this.selectedCountryName = filterData[0].name;
            this.callingCode = filterData[0].callingCode;
          }
        } else {
          this.selectedCountryCode = "";
          this.selectedCountryName = "";
          this.callingCode = 0;
        }
      },
    },

    selectedCountryCode: {
      immediate: true,
      async handler() {
        if (this.selectedCountryCode !== " ") {
          this.searching = false;
          this.searchQuery = "";
        }
      },
    },

    otpArray: {
      deep: true,
      handler(newOtpArray) {
        this.otpValue = newOtpArray.join(""); // Combine digits into a single OTP
      },
    },
  },

  async created() {
    try {
      const response = await fetch("https://restcountries.com/v2/all");
      const data = await response.json();
      this.countries = data.map((country) => ({
        name: country.name,
        alpha2Code: country.alpha2Code,
        callingCode: country.callingCodes ? country.callingCodes[0] : "N/A",
      }));
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  },

  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },

  methods: {
    handleInput(evt) {
      this.$emit("input", {
        code: this.value.code,
        phoneNo: evt.target.value,
        verified: this.isOtpVerified,
      });
    },

    handleClear() {
      this.$emit("input", {
        code: "",
        phoneNo: "",
        verfied: false,
      });
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    showOptionPicker() {
      this.optionPicker = true;
    },

    handleSelect(optionValue) {
      this.value.code = optionValue;
      this.optionPicker = false;

      this.$emit("input", {
        code: this.value.code,
        phoneNo: this.value.phoneNo,
        verified: this.value.verified,
      });
    },

    handleKeypress() {
      if (!this.searchQuery) {
        return;
      }

      if (this._options.length) {
        let optionIndex = this._options.find(
          (opt) => opt.value.toLowerCase() === this.searchQuery.toLowerCase()
        );
        if (optionIndex) {
          this.handleSelect(optionIndex.value);
          this.$refs.searchQuery.blur();
          return;
        }
      }

      if (this.newOption) {
        const newOption = {
          id: this.$nano.id(),
          label: this.searchQuery,
          value: this.searchQuery,
        };
        this.optionsClone.push(newOption);

        this.handleSelect(this.searchQuery);
        this.searchQuery = "";
        this.$refs.searchQuery.blur();
      }
      this.$emit("enter");
    },

    handleFocusout(evt) {
      this.$emit("focusOut", evt.target.value);
    },

    showDropdown() {
      this.searching = true;
      this.isDropdownOpen = true;
      this.dropdownEvent = true;
    },

    selectCountry(country) {
      this.searching = false;
      this.searchQuery = "";
      this.selectedCountryCode = country.alpha2Code;
      this.selectedCountryName = country.name;
      this.isDropdownOpen = false;
      this.callingCode = country.callingCode;

      this.$emit("input", {
        code: this.callingCode,
        phoneNo: this.value.phoneNo,
      });
    },

    handleScroll: debounce(function (e) {
      const scrollHeight = e.target.scrollHeight;
      const scrollTop = e.target.scrollTop;
      const clientHeight = e.target.clientHeight;

      const hasReachedBottom = scrollHeight - scrollTop === clientHeight;

      if (hasReachedBottom) {
        this.$emit("loadMore");
      }
    }, 300),

    handleOutsideClick(event) {
      const dropdown = this.$refs.dropdownMenu;
      const search = this.$refs.search;
      if (!this.dropdownEvent) {
        if (search && dropdown) {
          if (
            !search.contains(event.target) &&
            !dropdown.contains(event.target)
          ) {
            this.searching = false;
            this.isDropdownOpen = false;
          }
        }
      } else {
        this.dropdownEvent = false;
      }
    },

    // new
    handleOtpInput(index, event) {
      const value = event.target.value;
      if (value && index < this.otpArray.length - 1) {
        this.$refs[`otpInput${index + 1}`][0]?.focus();
      }
    },

    handleOtpBackspace(index) {
      if (!this.otpArray[index] && index > 0) {
        this.$refs[`otpInput${index - 1}`][0]?.focus();
      }
    },

    handleOtpPaste(event) {
      const pasteData = event.clipboardData.getData("text").split("");
      pasteData.forEach((char, i) => {
        if (i < this.otpArray.length) {
          this.$set(this.otpArray, i, char);
        }
      });
      const lastIndex = Math.min(
        pasteData.length - 1,
        this.otpArray.length - 1
      );
      this.$refs[`otpInput${lastIndex}`][0]?.focus();
    },
    verifyOTP() {
      if (this.getOtpValue === this.otpValue) {
        this.showCodeInput = false;
        this.isOtpVerified = true;
        this.verifiedPhoneNumber = this.value.phoneNo;
        this.showCodeInput = false;
        this.$emit("input", {
          code: this.value.code,
          phoneNo: this.value.phoneNo,
          verified: this.isOtpVerified,
        });
      } else {
        this.isOtpInvalid = true;
      }
    },
    async handleSendVerification() {
      this.isLoading = true;
      this.isOtpInvalid = false;
      const To_number = `+${this.value.code}${this.value.phoneNo}`;
      const { error, payload } = await common.sendSms({
        To_number,
      });
      if (error) {
        this.isLoading = false;
        this.$alert.error(error);
        return;
      }
      this.getOtpValue = payload.output;
      this.isLoading = false;
      this.showCodeInput = true;
      this.startTimer();
    },

    handlePhoneInput(event) {
      this.modelValue = event.target.value;
    },

    resendCode() {
      this.otpArray = Array(6).fill("");
      this.clearOtpInputs();
      this.handleSendVerification();
      this.startTimer();
    },

    clearOtpInputs() {
      this.otpArray.forEach((_, index) => {
        const inputField = this.$refs[`otpInput${index}`];
        if (inputField) {
          inputField.value = ""; // Clear the input field
        }
      });
    },

    startTimer() {
      this.timer = 30;
      const startTime = Date.now(); // Record the start time
      const endTime = startTime + this.otpDuration * 1000; // Calculate the end time

      this.isResendDisabled = true;
      this.isTimerActive = true;
      const interval = setInterval(() => {
        const currentTime = Date.now(); // Get the current time
        const remainingTime = Math.max(
          0,
          Math.floor((endTime - currentTime) / 1000)
        ); // Calculate remaining time in seconds

        this.timer = remainingTime;

        const minutes = Math.floor(this.timer / 60);
        const seconds = this.timer % 60;
        this.formattedTimer = `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;

        if (remainingTime <= 0) {
          clearInterval(interval);
          this.isResendDisabled = false;
          this.isTimerActive = false;
        }
      }, 1000); // Use a smaller interval for precision
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~flag-icons/css/flag-icons.min.css";

#code-field {
  input {
    min-height: 46px;
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }

  .select {
    min-height: 46px;
    display: flex;
    align-items: center;

    .value {
      white-space: nowrap;
      font-weight: 500;
      padding-left: 8px;
    }
  }

  .code {
    background: aliceblue;
  }

  // #country-code {
  .dropdown-selected {
    //  display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 46px;
    border-radius: 4px;
    //  background-color: #fff;
    padding-left: 10px;
    // padding: 10px 0px 10px 10px;
  }

  .dropdown-icon {
    margin-left: auto;
  }

  .dropdown-items {
    overflow-y: auto;
  }

  .search-input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 5px;
    box-sizing: border-box;
  }

  .ellipsis {
    display: inline-block;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    position: absolute;
    background: white;
    z-index: 1;
  }
  .required {
    color: red;
    margin-left: 3px;
  }
  //  }

  .option-list:hover {
    background-color: #f0f0f0;
  }

  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .verify-panel {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--body-bg-color);
  }
}
</style>
<style>
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
  color: var(--secondary);
}

.dropdown-item span {
  margin-right: 8px;
  font-size: 1.5em;
}

.no-results {
  font-style: italic;
  color: #999;
  padding: 10px;
}
</style>
