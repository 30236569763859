<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="validationRules"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <CounterField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      transform=""
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :error="errors[0]"
      :highlight="highlight"
      @input="handleInput"
      @verify="$emit('verify')"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "CounterFieldWrapper",

  components: { ValidationProvider, CounterField },

  props: {
    value: {
      type: Number,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    filter: {
      type: [Number, String],
      default: 0,
    },
  },

  computed: {
    validationRules() {
      const rules = { integer: true };

      if (this.isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      const minimum = this.field.settings.validation.minimum;
      if (minimum) {
        rules.min_value = minimum;
      }

      const maximum = this.field.settings.validation.maximum;
      if (maximum) {
        rules.max_value = maximum;
      }

      return rules;
    },

    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },
  },

  watch: {
    filter: {
      // immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          }
        }
      },
    },

    field: {
      immediate: true,
      // deep: true,
      handler() {
        if (!this.value) {
          if (this.field.settings.specific.masterFormParentColumn) {
            this.$emit(
              "getParentColumnValue",
              this.field.settings.specific.masterFormParentColumn
            );
          }
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    async getMasterEntriesFilter(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: field.filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          if (options.length === 1) {
            this.handleInput(options[0]);
          } else {
            this.handleInput(0);
          }
          this.$emit("clearFilter");
        } else {
          this.handleInput(0);
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
