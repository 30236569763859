<template>
  <div>
    <BaseActionButton
      v-tooltip.bottom="'notification'"
      is-flat
      icon="mdi-bell-outline"
      no-border
      @click="openNotification"
    >
      <Pulse v-if="alertNotification" class="pulse" />
    </BaseActionButton>

    <Sheet
      v-model="notificationSheet"
      width="40vw"
      @input="notificationSheet = false"
    >
      <!-- title -->

      <template #title> Notifications </template>

      <!-- ... -->

      <template #actions>
        <ExpandableSearch class="q-mr-sm" @search="searchRow" />

        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getNotifications()"
        />

        <BaseActionButton
          v-tooltip.top="'clear all'"
          icon="mdi-notification-clear-all"
          color="red"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="clearAllNotifications()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper" class="notificationData">
        <template v-if="notifyData.length">
          <div
            v-for="item in notifyData"
            :key="item.id"
            class="notificationContent"
            :class="item.readStatus === false ? 'unread' : ''"
          >
            <div class="entry" @click="openForm(item)">
              <!-- <BaseIcon
                v-if="item.category"
                :name="iconName"
                :color="iconColor"
              /> -->
              <div class="header">{{ item.title }}</div>

              <div class="content">
                <div class="changes-meta">
                  <div class="ellipsis">{{ item.createdByEmail }}</div>
                  <BaseIcon name="mdi-circle-small" class="q-mx-xs" />
                  <div class="ellipsis">{{ $day.format(item.createdAt) }}</div>
                  <div class="action">
                    <BaseActionButton
                      v-tooltip.top="'delete'"
                      icon="mdi-trash-can-outline"
                      color="red"
                      is-flat
                      size="20px"
                      class="q-mr-sm"
                      @click="deleteNotification(item.id)"
                    />
                  </div>
                </div>
                <div class="changes-meta">
                  <template
                    v-if="
                      item.status == 'COMPLETED' || item.status == 'SUCCESS'
                    "
                  >
                    <div class="text-green">{{ item.status }}</div>
                  </template>
                  <template v-else-if="item.status == 'PROCESSING'">
                    <div class="text-orange">{{ item.status }}</div>
                  </template>
                  <template v-else-if="item.status == 'FAILED'">
                    <div class="text-red">{{ item.status }}</div>
                  </template>
                  <template v-else>
                    <div class="text-secondary">{{ item.status }}</div>
                  </template>
                  <BaseIcon name="mdi-circle-small" class="q-mx-xs" />
                  <template v-if="validateURL(item.remarks)">
                    <a :href="item.remarks" target="_blank" class="ellipsis">{{
                      item.remarks
                    }}</a>
                  </template>
                  <template v-else>
                    <span class="font-semibold">{{ item.remarks }}</span>
                  </template>
                </div>
                <div class="changes-meta">
                  <!-- <BaseIcon name="mdi-circle-small" class="q-mx-xs" /> -->
                  <div class="action">{{ duration(item.createdAt) }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div v-else class="no-history">No data found</div>
      </div>
      <!-- ... -->
    </Sheet>

    <Sheet
      v-model="processSheet"
      width="100vw"
      has-footer
      no-padding
      @input="processSheet = false"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <!-- ... -->

      <template #actions>
        <BaseButton
          v-if="hasWflowInitiator"
          :label="subWorkflowName"
          class="q-mr-sm"
          color="secondary"
          @click="initiateSubWorkflow"
        />
      </template>

      <!-- content -->

      <div id="content-wrapper" class="row">
        <div v-if="formId" id="form-view" class="col">
          <!-- <div class="header">
              <div class="col">
                <div class="title-1">{{ formName }}</div>
                <div class="description">{{ formDescription }}</div>
              </div>
            </div> -->
          <!-- form -->
          <BaseScrollbar height="calc(100vh - 145px)">
            <RenderForm
              v-if="formId"
              :form-id="formId"
              :panels="panels"
              :secondary-panels="secondaryPanels"
              :form-settings="formSettings"
              :form-model="formModel"
              :save-action.sync="saveAction"
              :workflow-form-model.sync="workflowFormModel"
              :is-readonly="formEditAccess"
              :form-edit-controls="formEditControls"
              :form-visibility-access="formVisibilityAccess"
              :form-secure-controls="formSecureControls"
              :enable-controls="_enableControls"
            />

            <div v-if="signList.length" id="approvalSignature" class="q-pa-md">
              <div class="title q-mb-sm">Approval Signature</div>

              <BaseSeparator class="q-my-sm" />

              <div class="row">
                <template v-for="sign in signList">
                  <div :key="sign.id" class="col-3 q-mb-sm">
                    <img class="imgCenter col" :src="signLink(sign.id)" />
                    <div class="text row">
                      <span class="col-12 text-bold">{{ sign.stage }}</span>
                      <template v-if="sign.review">
                        <span
                          v-if="sign.review.toLowerCase() === 'approve'"
                          class="col-12 text-bold text-positive"
                          >Approved
                        </span>
                        <span
                          v-else-if="sign.review.toLowerCase() === 'reject'"
                          class="col-12 text-bold text-red"
                          >Rejected
                        </span>
                        <span v-else class="col-12 text-bold text-secondary">{{
                          sign.review
                        }}</span>
                      </template>
                      <span class="col-12">{{ sign.createdByEmail }}</span>
                      <span class="col-12">{{
                        $day.format(sign.createdAt)
                      }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <SignatureField
              v-if="userSignature"
              v-model="signImg"
              label="Signature"
              class="q-pa-md"
            />
          </BaseScrollbar>
        </div>

        <div v-if="itemModel.itemId" class="col">
          <div class="fileFrame">
            <div class="file-list">
              <iframe
                id="file-frame"
                ref="myFiles"
                :src="itemLink()"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>

        <div class="col-auto q-mr-sm leftBorder">
          <template v-for="tab in tabs">
            <BaseActionButton
              v-if="checkTabAccess(tab.value)"
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- footer -->
      <template #footer>
        <div v-if="actions.length" class="footer row">
          <BaseButton
            label="Save"
            class="q-ml-md"
            color="secondary"
            @click="validateRequest('Save')"
          />
          <template v-for="action in actions">
            <BaseButton
              v-if="
                action === 'Approve' ||
                action === 'Complete' ||
                action === 'Rightsize'
              "
              :key="action"
              :label="action"
              color="positive"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="
                action === 'Reject' ||
                action === 'Terminate' ||
                action === 'Force Close'
              "
              :key="action"
              :label="action"
              color="red"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="action === 'Forward' || action === 'Reply'"
              :key="action"
              :label="action"
              color="orange"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else
              :key="action"
              :label="action"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
          </template>
        </div>
      </template>
      <!-- ... -->
    </Sheet>

    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="selectedProcess.processId ? true : false"
      :upload="true"
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <!-- <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          @click="getAttachments"
        /> -->

        <!-- <BaseActionButton
          v-tooltip.top="'upload documents'"
          icon="mdi-upload"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="$refs.attachments.click()"
        /> -->
      </template>

      <template #upload>
        <div
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template #subActions>
        <template v-if="selectedProcess.processId && attachmentList.length">
          <CheckboxField
            v-model="selectAll"
            class="col-auto q-pt-sm q-pb-sm"
            @input="fileSelectAll"
          />
          Select All
        </template>

        <BaseActionButton
          v-if="selectedProcess.processId && selectedFileCount && false"
          v-tooltip.top="'mail share'"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="emailSharingSheet = true"
        />

        <BaseActionButton
          v-if="selectedProcess.processId && selectedFileCount > 1 && false"
          v-tooltip.top="'merge documents'"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 250px)">
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadAttachments"
          />
          <template v-for="row in checkList">
            <template v-if="row.name">
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row">
                <div v-if="selectedProcess.processId" class="col-auto">
                  <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                  <BaseIcon
                    :name="
                      file.checked
                        ? 'eva-checkmark-circle-2'
                        : 'eva-radio-button-off-outline'
                    "
                    color="secondary"
                    class="q-mr-sm"
                    @click="file.checked = !file.checked"
                  />
                </div>
                <div v-if="file.formJsonId" class="col-auto">
                  <BaseIcon
                    v-tooltip:green.top="getLabel(file.formJsonId)"
                    name="mdi-form-select"
                    color="primary"
                    class="q-mr-sm"
                  />
                </div>
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openFile(file)">
                  {{ file.name }}
                </div>
                <div class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'Metadata'"
                    icon="eva-file-text-outline"
                    color="secondary"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="getFileData(file)"
                  />
                </div>
                <div class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'delete'"
                    icon="eva-trash-2-outline"
                    color="red"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="attachmentDelete(file)"
                  />
                </div>
              </div>

              <div class="meta">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- created by -->

                <template v-if="file.createdBy">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ file.createdByEmail }}</div>
                </template>

                <!-- ... -->

                <!-- size -->

                <template v-if="file.size">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ fileSize(file.size) }}</div>
                </template>

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>

    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="
            isTenantArmgroup() ? 'calc(100vh - 190px)' : 'calc(100vh - 150px)'
          "
        >
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div class="col q-pa-sm borderTop">
        <div class="row">
          <SingleChoiceField
            v-if="isTenantArmgroup()"
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="col q-mb-sm actions"
          />
        </div>
        <div class="row">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm commentAction"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm commentAction"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Tasks </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          @click="getTaskList"
        />

        <BaseActionButton
          v-tooltip.top="'Add Task'"
          icon="mdi-plus"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="addTask"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="task in taskEntries">
          <div :key="task.id" class="taskEntries">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-auto q-mr-md"
                  style="margin-top: 3px"
                  @click="viewTask(task.id)"
                >
                  {{ task.name }}
                </div>
                <q-chip
                  size="10px"
                  :color="getColor(task.priority)"
                  text-color="white"
                >
                  {{ task.priority }}
                </q-chip>
                <q-chip
                  size="10px"
                  :color="getColor(task.status)"
                  text-color="white"
                >
                  {{ task.status }}
                </q-chip>
              </div>
            </div>

            <div class="meta row">
              <div class="col-12">Assigned To: {{ task.assigned }}</div>
              <div class="col-12">
                Start Date: {{ $day.format(task.startDate, false) }}
                <BaseIcon name="mdi-circle-small" />
                End Date: {{ $day.format(task.endDate, false) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <SingleChoiceField
          v-if="isTenantArmgroup()"
          v-model="commentAction"
          :options="commentActions"
          :options-per-line="2"
          class="actions q-mr-sm noborder"
        />
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Confirm
      v-model="errorModal"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          errorModal = false;
          errorMessage = '';
        }
      "
    >
      <template #title>Error Message</template>

      <template #default>
        <div class="q-py-md">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="red"
          @click="
            {
              errorModal = false;
              errorMessage = '';
            }
          "
        />
      </template>
    </Confirm>

    <Confirm
      v-model="actionPasswordConfirm"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="actionPasswordConfirm = false"
    >
      <template #title> Action Password</template>

      <template #default>
        <ValidationObserver ref="passwordForm">
          <!-- password -->

          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="actionUserPassword"
              is-mandatory
              :is-readonly="isLoading"
              :error="errors[0]"
              :auto-focus="true"
              class="q-my-lg"
              @enter="validatePassword"
            />
          </ValidationProvider>

          <!-- ... -->
        </ValidationObserver>
      </template>

      <template #footer>
        <BaseButton
          label="validate"
          :is-loading="isLoading"
          @click="validatePassword"
        />
      </template>
    </Confirm>

    <Confirm
      v-model="alertModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="closeAlertModal"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the request without attaching the
            required documents.
          </p>

          <!-- <p class="text-secondary">
            After submitting, you can upload the required documents from sent
            list once you have them.
          </p> -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="closeAlertModal"
        />
        <BaseButton label="yes" color="primary" @click="proceedSubmission" />
      </template>
    </Confirm>

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />

    <Sheet v-model="subProcessSheet" width="30vw" @input="closeSubProcessSheet">
      <!-- title -->

      <template #title> Linked Request </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="(process, index) in subProcessTickets">
          <div :key="index" class="subProcess">
            <div class="title row">
              <div class="col-auto">
                <BaseIcon
                  name="o_account_tree"
                  class="q-mr-sm"
                  color="primary-4"
                />
              </div>
              <div
                class="col ellipsis text-underline"
                @click="openSubProcess(process)"
              >
                {{ process.requestNo }}
              </div>
              <q-chip
                v-if="process.flowStatus === '1'"
                size="10px"
                color="green"
                text-color="white"
              >
                Completed
              </q-chip>
              <q-chip v-else size="10px" color="orange" text-color="white">
                Running
              </q-chip>
            </div>

            <div class="meta row">
              <div class="col row">
                <div>{{ process.name }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.stage }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.duration }} ago</div>
              </div>
            </div>
            <div v-if="false" class="col-auto q-mr-sm q-pt-md leftBorder">
              <template v-for="tab in subTabs">
                <BaseActionButton
                  :key="tab.id"
                  v-tooltip.left="tab.label"
                  is-flat
                  no-border
                  :icon="tab.icon"
                  color="primary"
                  class="linkActions"
                  size="16px"
                  @click="subProcessShowLinks(tab.value, process)"
                >
                  <span
                    v-if="
                      tab.value === 'ATTACHMENTS' && process.attachmentCount
                    "
                    class="badge"
                    >{{ process.attachmentCount }}
                  </span>

                  <span
                    v-if="tab.value === 'COMMENTS' && process.commentsCount"
                    class="badge"
                    >{{ process.commentsCount }}
                  </span>
                </BaseActionButton>
              </template>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <SubWorkflowProcess
      v-if="subWorkflowId || subProcessData.id"
      :workflow-id="subWorkflowId || subProcessData.id"
      :initiate-sub-workflow-process="initiateSubWorkflowProcess"
      :main-workflow-id="workflowId"
      :main-process-id="selectedProcess.processId"
      :sub-process-link="subProcessLink"
      :selected-process="subProcessData"
      :open-sub-process="openSubProcessSheet"
      :main-form-fields="formFields"
      :main-form-model="formModel"
      :sub-process-id.sync="subProcessId"
      @closeProcessSheet="closeSubProcess"
    />

    <FormEntryHistory
      v-if="showFormEntryHistoryModal"
      :value.sync="showFormEntryHistoryModal"
      :form-id="workflow.wFormId"
      :entry-id="selectedProcess.formData.formEntryId"
    />

    <Attachments
      v-if="showAttachmentSheet"
      :value.sync="showAttachmentSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="'inbox'"
      :initiate-process="false"
      :form-check-list="false"
      :check-list="checkList"
      :wform="wform"
      :repository-details="{}"
      :workflow-model="{}"
      :form-upload-files="[]"
      :repository-field="[]"
      :columns="[]"
      :attachments.sync="attachmentList"
      :workflow-id="workflow.id"
      @attachmentCount="attachmentCount"
    />

    <Comments
      v-if="showCommentsSheet"
      :value.sync="showCommentsSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="'inbox'"
      :initiate-process="false"
      :attachment-list="attachmentList"
      :workflow-id="workflow.id"
      :file-names="[]"
      :comments.sync="commentsList"
      :display-internal-public="false"
      @commentsCount="commentsCount"
    />

    <AddTask
      v-if="taskFormId"
      :value="addTaskSheet"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="taskFormId"
      :repository-id="taskRepositoryId"
      :task-model="selectedTask"
      :workflow-task="true"
      :mode="taskMode"
      @close="addTaskSheet = false"
      @addEntry="addTaskEntry"
    ></AddTask>
  </div>
</template>

<script>
import Pulse from "@/components/common/Pulse.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import {
  common,
  form,
  workflow,
  report,
  auth,
  integration,
  menu,
} from "@/api/factory.js";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import SignatureField from "@/components/common/form/signature-field/SignatureField.vue";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
  fileSupport,
} from "@/helpers/file-format.js";
import formatBytes from "@/helpers/format-bytes.js";
import Modal from "@/components/common/popup/Modal.vue";
import axios from "axios";
import Confirm from "@/components/common/popup/Confirm.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import ListItem from "@/components/common/ListItem.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import SubWorkflowProcess from "@/views/workflows/views/workflows-inbox/components/components/SubWorkflow.vue";
import FormEntryHistory from "@/components/common/workflow/FormEntryHistory.vue";
import Attachments from "@/components/common/workflow/Attachments.vue";
import Comments from "@/components/common/workflow/Comments.vue";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask";

export default {
  name: "Notifications",

  components: {
    Pulse,
    Sheet,
    RenderForm,
    SignatureField,
    Modal,
    Confirm,
    TextBuilderField,
    PasswordField,
    CheckboxField,
    TextAreaField,
    SingleChoiceField,
    FileIcon,
    ListItem,
    ExpandableSearch,
    ProcessHistory,
    SubWorkflowProcess,
    FormEntryHistory,
    Attachments,
    Comments,
    AddTask,
  },

  data() {
    return {
      notificationSheet: false,
      notifyData: [],
      notifyCopyData: [],
      iconColor: "secondary",
      workflowId: 0,
      processId: 0,
      workflow: {},
      workflowRequestList: [],
      formId: "",
      form: null,
      formFields: [],
      formModel: {},
      formEditAccess: false,
      formEditControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      workflowFormModel: {},
      saveAction: "",
      attachmentList: [],
      attachmentSheet: false,
      commentText: "",
      commentsList: [],
      commentsSheet: false,
      selectedProcess: {},
      selectedFile: {},
      fileSheet: false,
      checkList: [],
      rowIndex: null,
      hideFileSheet: false,
      processSheet: false,
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      title: "NO DATA",
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Data Validation",
          value: "VALIDATION",
          icon: "mdi-file-compare",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Sub Process",
          value: "SUB_PROCESS",
          icon: "eva-link-2-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Form Entry History",
          value: "FORM_ENTRY_HISTORY",
          icon: "mdi-file-document",
          count: 0,
        },
      ],
      taskSheet: false,
      headerTitle: "",
      taskFormId: 0,
      taskRepositoryId: 0,
      addTaskForm: {},
      addTaskSheet: false,
      taskEntryId: [],
      taskEntries: [],
      taskEntryList: [],
      taskMode: "",
      selectedTask: {},
      filterBy: [],
      processActions: [],
      processActionsAddOn: [],
      advancedCommentsSheet: false,
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 2,
      advancedCommentText: "",
      actionText: "",
      actionRemarks: false,
      actionPassword: false,
      actionPasswordConfirm: false,
      actionUserPassword: "",
      isLoading: false,
      internalForward: {
        proceed: false,
        users: [],
        groups: [],
      },
      userSignature: false,
      signImg: "",
      signList: [],
      actions: [],
      itemModel: {},
      checkListRowIndex: null,
      selectAll: false,
      processForwardGroupAction: false,
      processForwardModal: false,
      forwardUsers: [],
      forwardGroups: [],
      errorMessage: "",
      alertModal: false,
      errorModal: false,
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      addTaskEnabled: false,
      notifyLoad: null,
      alertNotification: false,
      historyProcessId: 0,
      hasWflowInitiator: false,
      subWorkflowId: 0,
      initiateSubWorkflowProcess: false,
      subProcessSheet: false,
      subProcessData: {},
      subTabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
      ],
      subProcessLink: "",
      openSubProcessSheet: false,
      subProcessTickets: [],
      subProcessId: 0,
      workflowList: [],
      showFormEntryHistoryModal: false,
      showAttachmentSheet: false,
      wform: {},
      showCommentsSheet: false,
    };
  },

  computed: {
    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.form) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.form) {
        return {};
      }
      return this.form.settings;
    },

    checkListTemplate() {
      if (this.checkList.length) {
        return this.checkList.filter((row) => row.template).length > 0;
      }
      return false;
    },

    selectedFileCount() {
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (controls.indexOf(control) < 0) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },
  },

  watch: {
    selectedProcess: {
      immediate: true,
      handler() {
        if (this.selectedProcess.processId) {
          this.attachmentList = [];
          this.getComments();
          this.getAttachments();
          this.getProcessSignature();
        }
      },
    },

    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          this.checkRequiredDocument();
        }
      },
    },

    subWorkflowId() {
      if (this.subWorkflowId) {
        let subWorkflow = this.workflowList.find(
          (workflow) => workflow.value == this.subWorkflowId
        );
        if (subWorkflow) {
          this.subWorkflowName = `Initiate - ${subWorkflow.label}`;
          this.hasWflowInitiator = false;
          let wflowJson = subWorkflow.flowJson;
          let startBlock = wflowJson.blocks.find(
            (block) => block.type === "START"
          );

          if (startBlock) {
            if (
              startBlock.settings.initiateMode === "MANUAL" &&
              subWorkflow.initiatedBy !== "DOCUMENT"
            ) {
              if (
                startBlock.settings.users.indexOf(
                  this.$store.state.session.id
                ) > -1 ||
                startBlock.settings.users.indexOf("0") > -1
              ) {
                this.hasWflowInitiator = true;
              }

              if (startBlock.settings.initiateBy.indexOf("DOMAIN_USER") > -1) {
                let domain = this.$store.state.session.email.split("@").pop();

                if (startBlock.settings.userDomains.indexOf(domain) > -1) {
                  this.hasWflowInitiator = true;
                }
              }
            }
          }
        } else {
          this.hasWflowInitiator = false;
        }
      }
    },

    taskEntries: {
      immediate: true,
      handler() {
        this.tabs.find((tab) => {
          if (tab.value === "TASK") {
            tab.count = this.taskEntries.length;
          }
        });
      },
    },
  },

  created() {
    this.getNotifications();
    let origin = location.origin;
    if (origin.indexOf("http://localhost") > 0) {
      this.notifyLoad = setInterval(() => {
        this.getNotifications(true);
      }, 30000);
    }
  },

  methods: {
    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    isTenantArmgroup() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        return true;
      }
      return false;
    },

    checkTabAccess(value) {
      if (value === "TASK") {
        return this.addTaskEnabled;
      } else if (value === "VALIDATION") {
        if (this.isTenantArmgroup()) {
          return true;
        } else {
          return false;
        }
      } else if (value === "FORM_ENTRY_HISTORY") {
        if (this.formId) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },

    duration(duration) {
      const date = duration instanceof Date ? duration : new Date(duration);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    validateURL(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return urlPattern.test(value);
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            }
          } else if (origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/1`;
          }
        } else {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2`;
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
        }
      }
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    download(id) {
      window.open(
        `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${id}`
      );
    },

    downloadAll() {
      this.checkList.forEach((row) => {
        if (row.id && row.template) {
          window.open(
            `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${row.id}`
          );
        }
      });
    },

    attachTemplate(uid) {
      this.rowIndex = uid;
      this.$refs.attachments.click();
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    checkDocument() {
      this.docWarning = false;
      if (this.trackRequest) {
        return;
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          if (row.required && !row.attach) {
            this.docWarning = true;
          }
        });
      }
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    closeProcessSheet() {
      this.processSheet = false;
    },

    openForm(item) {
      if (item.category === "WORKFLOW") {
        let values = JSON.parse(item.inputJson);
        this.workflowId = Number(values.workflowId);
        this.processId = Number(values.processId);
        this.filterBy = [
          {
            id: this.$nano.id(),
            filters: [
              {
                id: this.$nano.id(),
                criteria: "requestNo",
                condition: "IS_EQUALS_TO",
                value: item.title.split(": ")[1],
              },
            ],
          },
        ];
        this.getSubWorkflow();
        this.getWorkflowData();
      } else if (item.category === "TASK") {
        console.log(item);
      }
    },

    openNotification() {
      this.alertNotification = false;
      this.notifyLoad = setInterval(() => {
        this.getNotifications(true);
      }, 30000);
      this.clearAllNotifications(2);
      this.getUserWorkflow();
      this.notificationSheet = true;
    },

    getColor(label) {
      if (label === "Closed" || label === "High") {
        return "red";
      } else if (label === "In Progress" || label === "Medium") {
        return "warning";
      } else if (label === "Open" || label === "Low") {
        return "secondary";
      } else if (label === "ReOpen") {
        return "primary";
      } else {
        return "primary";
      }
    },

    getLabel(fieldId) {
      let controlLabel = "";
      if (this.form) {
        this.form.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          let field = panel.fields.find((field) => field.id === fieldId);
          if (field) {
            controlLabel = field.label ? field.label : field.type;
            return;
          }
        });
      }
      return controlLabel;
    },

    addTask() {
      this.selectedTask = {};
      this.taskMode = "NEW";
      this.addTaskSheet = true;
    },

    viewTask(id) {
      let filterTask = this.taskList.filter((row) => row.formEntryId === id);
      if (filterTask.length) {
        this.selectedTask = filterTask[0].entryInfo[0];
        this.selectedTask = {
          id: filterTask[0].formEntryId,
          ...filterTask[0].entryInfo[0],
        };
      }
      this.taskMode = "EDIT";
      this.addTaskSheet = true;
    },

    addTaskEntry(formId, entryId) {
      if (this.selectedProcess.processId) {
        this.insertTaskEntry(formId, entryId);
      } else {
        this.taskEntryId.push({
          formId: formId,
          formEntryId: entryId,
        });
        this.getTaskFormEntry(formId, entryId);
      }
    },

    async getNotifications(load = false) {
      let input = {
        filterBy: [
          {
            filters: [
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.id,
              },
            ],
          },
        ],
        mode: "Browse",
      };
      if (!load) {
        this.$store.commit("showLoadingBar");
      }
      const { error, payload } = await common.getNotifications(input);
      if (!load) {
        this.$store.commit("hideLoadingBar");
      }
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        let notifyCheck = payload.filter((row) => row.readStatus === false);
        if (notifyCheck.length) {
          this.alertNotification = true;
          clearInterval(this.notifyLoad);
        }
        this.notifyData = payload;
        this.notifyCopyData = payload;
      }
    },

    async clearAllNotifications(status = 1) {
      let id = this.$store.state.session.id;

      const { error } = await common.clearAllNotifications(id, status);

      if (error) {
        this.$alert.error(error);
        return;
      }

      //this.$alert.success("Notifications cleared succesfully");
      this.getNotifications();
    },

    async deleteNotification(id) {
      let input = { isDeleted: true };
      const { error } = await common.deleteNotification(id, input);

      if (error) {
        this.$alert.error(error);
        return;
      }

      //this.$alert.success("Notification deleted succesfully");
      this.getNotifications();
    },

    async getTaskForm() {
      this.taskFormList = [];
      this.addTaskEnabled = false;
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.addTaskEnabled = true;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length) {
            if (this.taskFormList.length === 1) {
              this.taskFormId = this.taskFormList[0].id;
              this.taskRepositoryId = this.taskFormList[0].repositoryId;
              let formDet = await this.getTaskFormById(this.formId);
              this.addTaskForm = JSON.parse(formDet.formJson);
            }
          } else {
            //this.$alert.info("Task form not found");
          }
        }
      }
    },

    async getTaskFormById(formId) {
      const { error, payload } = await form.getForm(formId);
      if (error) {
        this.$alert.error(error);
        return;
      }
      return payload;
    },

    async getWorkflow() {
      this.checkList = [];
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workflow.getWorkflow(this.workflowId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.workflow = payload;
      if (this.workflow.wFormId) {
        this.formId = String(this.workflow.wFormId);
        await this.getForm();
      }

      await this.getTaskForm();
    },

    async getWorkflowData() {
      this.$store.commit("showLoadingBar");
      this.workflowRequestList = [];
      this.list = [];
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: this.filterBy,
        itemsPerPage: 10,
        currentPage: 1,
        mode: "",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.data.length) {
        this.selectedProcess = payload.data[0].value[0];
        await this.getWorkflow();
        this.openProcess();
      }
    },

    async getForm() {
      this.formFields = [];
      if (this.formId) {
        this.$store.commit("showLoadingBar");

        const { error, payload } = await form.getForm(this.formId);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        let formJson = JSON.parse(payload.formJson);
        this.wform = payload;
        this.form = formJson;
        const panels = [...formJson.panels, ...formJson.secondaryPanels];

        if (this.form.settings.general.layout === "CARD") {
          this.form.settings.general.layout = "TAB";
        }

        if (!panels.length) {
          return;
        }
        let fields = [];
        for (const panel of panels) {
          fields.push(...panel.fields);
        }

        fields.forEach((field) => {
          this.formFields.push({
            id: this.$nano.id(),
            label: field.label,
            value: field.id,
            dataType: field.type === "DATE" ? field.type : "SHORT_TEXT",
          });
        });
      }
    },

    async openProcess() {
      this.formModel = {};
      this.itemModel = {};
      this.formEditAccess = true;
      this.formEditControls = [];
      this.processActions = [];
      this.processActionsAddOn = [];
      this.headerTitle = `${this.selectedProcess.requestNo} - ${this.selectedProcess.stage}`;

      if (this.selectedProcess.formData) {
        let keys = Object.keys(this.selectedProcess.formData.fields);
        let newValues = {};
        keys.forEach((key) => {
          newValues[key] = this.validateTypeValue(
            this.selectedProcess.formData.fields[key]
          );
        });
        this.formModel = newValues;
      } else if (this.selectedProcessrocess.itemData) {
        this.itemModel = process.itemData;
      }
      if (
        this.selectedProcess.activityUserEmail ===
        this.$store.state.session.email
      ) {
        let wflowJson = JSON.parse(this.workflow.flowJson);
        wflowJson.rules.forEach((rule) => {
          if (rule.fromBlockId === this.selectedProcess.activityId) {
            if (this.processActions.indexOf(rule.proceedAction) < 0) {
              this.processActions.push(rule.proceedAction);
              this.processActionsAddOn.push({
                action: rule.proceedAction,
                remarks: rule.remarks || false,
                passwordAccess: rule.passwordAccess || false,
                signature: rule.signature || false,
              });
            }
          }
        });
        this.internalForward = {
          proceed: false,
          users: [],
          groups: [],
        };
        this.userSignature = false;
        wflowJson.blocks.forEach((block) => {
          if (block.id === this.selectedProcess.activityId) {
            if (block.settings.internalForward) {
              this.processActions.push("Forward");
              this.internalForward.proceed = true;
              this.internalForward.users = [];
              this.internalForward.groups = [];
            }
            if (block.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (block.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (block.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = block.settings.formEditControls;
            }
            if (block.settings.formVisibilityAccess) {
              this.formVisibilityAccess = true;
              this.formSecureControls = [];
            } else {
              this.formVisibilityAccess = false;
              this.formSecureControls = block.settings.formSecureControls;
            }
            if (
              wflowJson.settings.general.processNumberPrefix.indexOf("[") > -1
            ) {
              let requestNumberFormat = JSON.parse(
                wflowJson.settings.general.processNumberPrefix
              );
              this.readonlyControls = [];
              let requestColumn = requestNumberFormat.filter(
                (row) => row.key === "formColumn"
              );
              if (requestColumn.length) {
                requestColumn.forEach((row) => {
                  this.readonlyControls.push(row.value);
                  if (this.formEditControls.indexOf(row.value) > -1) {
                    this.formEditControls.splice(
                      this.formEditControls.indexOf(row.value),
                      1
                    );
                  }
                });
              }
            }
            this.userSignature = block.settings.userSignature;
            if (block.settings.fileSettings) {
              if (block.settings.fileSettings.fileCheckList.length) {
                this.checkList = block.settings.fileSettings.fileCheckList;
                this.checkList.forEach((row) => {
                  row.uid = this.$nano.id();
                  row.attach = false;
                });
              }
            }
            if (block.settings.workflowInitiator) {
              this.subWorkflowId = block.settings.subWorkflowId;
            }
          }
        });

        if (this.selectedProcess.userType) {
          if (this.selectedProcess.userType === "SUPERUSER") {
            this.processActions.push("Force Close");
          }
        }
      }
      if (this.form.settings.general.layout === "CARD") {
        this.form.settings.general.layout = "TAB";
      }
      this.actions = this.processActions;
      this.processSheet = true;
      this.getTaskList();
    },

    validateRequest(action) {
      this.saveAction = action;
      if (this.itemModel.itemId) {
        this.processRequest();
      }
    },

    checkRequiredDocument() {
      if (this.saveAction === "Save" || this.saveAction === "Force Close") {
        this.proceedSubmission();
      } else {
        let docRequired = this.checkList.filter(
          (row) => row.required && !row.attach
        );

        if (docRequired.length) {
          if (this.docWarning) {
            this.alertModal = true;
          } else {
            this.$alert.warning("Mandatory documents required");
            this.saveAction = "";
            this.workflowFormModel = {};
          }
        } else {
          this.proceedSubmission();
        }
      }
    },

    proceedSubmission() {
      this.alertModal = false;
      this.processRequest();
    },

    closeAlertModal() {
      this.alertModal = false;
      this.saveAction = "";
      this.workflowFormModel = {};
    },

    async processRequest(forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        if (this.saveAction === "Forward" && this.internalForward.proceed) {
          this.processForwardGroupAction = false;
          this.processForwardModal = true;
          return;
        }
      }

      if (addOnCheck) {
        if (this.saveAction === "Save") {
          this.actionConfirm = true;
          this.actionConfirmAlert = true;
          return;
        } else {
          let actionAddOn = this.processActionsAddOn.find(
            (row) => row.action === this.saveAction
          );

          if (Object.keys(actionAddOn).length) {
            this.actionRemarks = actionAddOn.remarks;
            this.actionPassword = actionAddOn.passwordAccess;
            if (this.actionPassword) {
              this.actionPasswordConfirm = true;
              return;
            }
            if (this.actionRemarks) {
              this.actionComments = true;
              this.advancedCommentsSheet = true;
              return;
            }
          }
        }
      }

      let inputData = {};
      if (this.formId) {
        inputData = {
          workflowId: this.workflowId,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {
            formId: this.formId,
            formEntryId: this.selectedProcess.formData.formEntryId,
            fields: this.workflowFormModel,
          },
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      } else if (this.itemModel) {
        inputData = {
          workflowId: this.workflowId,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {},
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      }
      this.saveAction = "";
      this.workflowFormModel = {};

      this.$store.commit("showLoadingBarSave");
      const { error } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature(this.selectedProcess);
      }

      if (inputData.review === "") {
        this.$alert.info(
          `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
        );
      } else if (inputData.review.toLowerCase().indexOf("reject") > -1) {
        this.$alert.danger(
          `${this.selectedProcess.requestNo} Request Rejected Successfully`
        );
      } else {
        this.$alert.success(
          `${this.selectedProcess.requestNo} Request Processed`
        );
      }

      this.forwardUsers = [];
      this.forwardGroups = [];
      this.closeProcessSheet();
      this.$emit("refresh", this.workflowId);
    },

    showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        //this.attachmentSheet = true;
        this.showAttachmentSheet = true;
      } else if (tab === "COMMENTS") {
        //this.commentsSheet = true;
        this.showCommentsSheet = true;
      } else if (tab === "HISTORY" && this.selectedProcess.processId) {
        this.historyProcessId = this.selectedProcess.processId;
      } else if (tab === "PRINT") {
        if (this.selectedProcess.formData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/form/generatePdfPrint/${this.$store.state.session.tenantId}/${this.workflowId}/${this.selectedProcess.processId}/${this.formId}/${this.selectedProcess.formData.formEntryId}/1`
          );
        } else if (this.selectedProcess.itemData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedProcess.itemData.repositoryId}/${this.selectedProcess.itemData.itemId}/2`
          );
        }
        //window.print();
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.$alert.info("Task not found");
        }
      } else if (tab === "SUB_PROCESS") {
        this.subProcessSheet = true;
      } else if (tab === "FORM_ENTRY_HISTORY") {
        this.showFormEntryHistoryModal = true;
      }
    },

    searchRow(search) {
      if (search) {
        this.notifyData = this.notifyCopyData.filter((row) => {
          for (let cell in row) {
            if (
              String(row[cell]).toLowerCase().includes(search.toLowerCase())
            ) {
              return row;
            }
          }
        });
      } else {
        this.notifyData = this.notifyCopyData;
      }
    },

    attachmentCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = count;
        }
      });
    },

    commentsCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = count;
        }
      });
    },

    async getComments() {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        this.workflowId,
        this.selectedProcess.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.selectedProcess.processId) {
          this.$store.commit("showLoadingBar");
          const { error, payload } = await workflow.insertProcessComment(
            this.workflowId,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.commentText,
              showTo: this.commentAction,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          if (this.selectedProcess.jiraIssueInfo.id) {
            this.sendCommentsToJira(
              this.commentText,
              this.selectedProcess.jiraIssueInfo.id,
              payload
            );
          }
          this.commentText = "";
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.commentText = "";
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText && this.advancedCommentText != "<p></p>") {
        if (this.selectedProcess.processId) {
          this.$store.commit("showLoadingBar");
          const { error, payload } = await workflow.insertProcessComment(
            this.workflowId,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.advancedCommentText,
              showTo: this.commentAction,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          if (this.selectedProcess.jiraIssueInfo.id) {
            this.sendCommentsToJira(
              this.advancedCommentText,
              this.selectedProcess.jiraIssueInfo.id,
              payload
            );
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
        }
        if (this.actionComments) {
          this.processRequest(false, false);
        }
      } else {
        this.$alert.warning("Comments Text Required");
      }
    },

    async sendCommentsToJira(commentsText, issueId, commentId) {
      this.$store.commit("showLoadingBar");
      let input = {
        cloudIntegrationId: this.jiraAccounts[0].id,
        url: this.selectedProcess.jiraIssueInfo.self,
        data_onpremise: {
          body: commentsText,
        },
        issueId: issueId,
        ezCommentId: commentId,
      };

      const { error, payload } = await integration.insertJiraIssueComments(
        input
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error("error posting comments to jira");
        let msg = JSON.parse(error);
        this.errorMessage = msg.errorMessages;
        this.errorModal = true;
        return;
      }
      console.log(payload);
    },

    async uploadAttachments(e) {
      if (this.selectedProcess.processId) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("workflowId", this.workflowId);
        formData.append("repositoryId", this.workflow.repositoryId);
        formData.append("processId", this.selectedProcess.processId);
        formData.append("transactionId", this.selectedProcess.transactionId);
        formData.append("fields", "");
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.checkListRowIndex) {
              row.attach = true;
              return;
            }
          });
        }
        e.target.value = "";
        this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.workflow.repositoryId);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });

          const { status, data } = response;
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.checkListRowIndex,
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.checkListRowIndex) {
                row.attach = true;
                return;
              }
            });
          }
          e.target.value = "";
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments() {
      this.checkList = [];
      if (this.selectedProcess.processId) {
        let workflowJson = JSON.parse(this.workflow.flowJson);
        let block = workflowJson.blocks.find(
          (block) => block.id === this.selectedProcess.activityId
        );
        if (block) {
          if (block.settings.fileSettings) {
            if (block.settings.fileSettings.fileCheckList.length) {
              this.checkList = block.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
              this.checkDocument();
            }
          }
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = 0;
          }
        });
        let count = this.attachmentList.length;
        this.$store.commit("showLoadingBar");
        const { error, payload } = await workflow.getAttachments(
          this.workflowId,
          this.selectedProcess.processId
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.length) {
          if (count !== payload.length) {
            this.attachmentList = payload.map((item) => {
              return {
                ...item,
                checked: false,
              };
            });
          }
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        } else {
          this.attachmentList = [];
        }
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            let filterAttach = this.attachmentList.filter((file) => {
              let filename = file.name.substr(0, file.name.lastIndexOf("."));
              if (row.name === filename) {
                return file;
              }
            });
            if (filterAttach.length) {
              row.attach = true;
            } else {
              row.attach = false;
            }
          });
        }
      }
    },

    async saveProcessSignature(request) {
      let input = {
        signBinary: this.signImg.split(",")[1],
        fileType: "png",
      };
      const { error } = await workflow.saveProcessSignature(
        this.workflowId,
        request.processId,
        request.transactionId,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signImg = "";
    },

    async getProcessSignature() {
      this.signList = [];
      const { error, payload } = await workflow.getProcessSignature(
        this.workflowId,
        this.processId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signList = payload;
    },

    signLink(id) {
      return `${process.env.VUE_APP_API_URL}/workflow/signView/${this.$store.state.session.tenantId}/${this.workflowId}/${id}`;
    },

    async validatePassword() {
      const areAllFieldsValid = await this.$refs.passwordForm.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.isLoading = true;
      const { error } = await auth.validatePassword({
        password: this.actionUserPassword,
      });
      this.isLoading = false;
      if (error) {
        console.log(error);
        this.$alert.warning(error);
        return;
      }
      this.actionUserPassword = "";
      this.actionPasswordConfirm = false;
      if (this.actionRemarks) {
        this.actionComments = true;
        this.advancedCommentsSheet = true;
      } else {
        this.actionRemarks = false;
        this.actionPassword = false;
        this.processRequest(false, false);
      }
    },

    async getTaskList() {
      this.taskEntries = [];
      let taskFilterBy = [];
      const panels = [
        ...this.addTaskForm.panels,
        ...this.addTaskForm.secondaryPanels,
      ];
      let formFields = [];
      for (const panel of panels) {
        formFields.push(...panel.fields);
      }
      let taskOwnerField = formFields.find(
        (field) => field.label === "Task Owner"
      );
      if (taskOwnerField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskOwnerField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      let taskAssignField = formFields.find(
        (field) => field.label === "Task Assign"
      );
      if (taskAssignField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskAssignField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getTaskEntries(
        this.workflowId,
        this.selectedProcess.processId,
        {
          filterBy: [
            {
              filters: [
                {
                  criteria: "createdBy",
                  condition: "IS_EQUALS_TO",
                  value: this.$store.state.session.id,
                },
              ],
            },
            ...taskFilterBy,
          ],
        }
      );
      //this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.taskList = payload;
        payload.forEach((row) => {
          let taskName = "",
            taskAssign = "",
            startDate = "",
            endDate = "",
            priority = "",
            status = "";
          formFields.forEach((field) => {
            switch (field.label) {
              case "Task Name":
                taskName = row.entryInfo[0][field.id];
                break;
              case "Task Assign":
                taskAssign = row.entryInfo[0][field.id];
                break;
              case "Start Date":
                startDate = row.entryInfo[0][field.id];
                break;
              case "End Date":
                endDate = row.entryInfo[0][field.id];
                break;
              case "Priority":
                priority = row.entryInfo[0][field.id];
                break;
              case "Task Status":
                status = row.entryInfo[0][field.id];
                break;
            }
          });
          this.taskEntries.push({
            id: row.entryInfo[0].itemid,
            name: taskName,
            assigned: taskAssign,
            startDate: startDate,
            endDate: endDate,
            priority: priority,
            status: status,
          });
        });
      }
    },

    async getTaskFormEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");

      const { error, payload } = await form.getFormEntry(formId, entryId);
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];

        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let taskName = "",
          taskAssign = "",
          startDate = "",
          endDate = "",
          priority = "",
          status = "";
        formFields.forEach((field) => {
          switch (field.label) {
            case "Task Name":
              taskName = payload[0][field.id];
              break;
            case "Task Assign":
              taskAssign = payload[0][field.id];
              break;
            case "Start Date":
              startDate = payload[0][field.id];
              break;
            case "End Date":
              endDate = payload[0][field.id];
              break;
            case "Priority":
              priority = payload[0][field.id];
              break;
            case "Task Status":
              status = payload[0][field.id];
              break;
          }
        });
        this.taskEntries.push({
          id: payload[0].itemid,
          name: taskName,
          assigned: taskAssign,
          startDate: startDate,
          endDate: endDate,
          priority: priority,
          status: status,
        });
      }
    },

    async insertTaskEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");
      const { error } = await workflow.insertTaskEntry({
        workflowId: this.selectedWorkflow.id,
        processId: this.selectedProcess.processId,
        transactionId: this.selectedProcess.transactionId,
        wFormId: formId,
        formEntryId: entryId,
      });
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getTaskList();
    },

    documentAction(action) {
      if (action.id === "Email_Sharing") {
        //
      } else if (action.id === "Print") {
        if (this.selectedFile.name) {
          let ext = this.selectedFile.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.selectedFile.id);
          } else {
            this.$refs.myFiles.contentWindow.print();
          }
        }
        this.filePrintSession();
      } else if (action.id === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getSubWorkflow() {
      this.tabs.find((tab) => {
        if (tab.value === "SUB_PROCESS") {
          tab.count = 0;
        }
      });

      const { error, payload } = await workflow.getSubWorkflow(
        this.workflowId,
        this.processId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload, "payload");

      if (payload.length) {
        this.getSubProcessDetails(payload[0].subWorkflowId, payload);
      }
    },

    async getSubProcessDetails(wId, data) {
      this.subProcessTickets = [];
      let filterBy = [];
      data.forEach((row) => {
        filterBy.push({
          filters: [
            {
              criteria: "processId",
              condition: "IS_EQUALS_TO",
              value: row.subProcessId,
              dataType: "SHORT_TEXT",
            },
          ],
          groupCondition: "or",
        });
      });
      if (filterBy.length) {
        filterBy[0].groupCondition = "";
      }
      const { error, payload } = await report.getWorkflowData(wId, {
        filterBy: filterBy,
        itemsPerPage: 0,
        currentPage: 0,
        mode: "",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
      if (payload.meta.totalItems) {
        console.log(payload);
        let process = [];
        data.forEach((sub) => {
          let hasProcess = payload.data[0].value.find(
            (item) => item.processId === Number(sub.subProcessId)
          );
          console.log(hasProcess);
          if (hasProcess) {
            process.push(hasProcess);
          }
        });

        if (process.length) {
          this.subProcessTickets = process;
        }
        this.tabs.find((tab) => {
          if (tab.value === "SUB_PROCESS") {
            tab.count = process.length;
          }
        });
      }
    },

    subProcessShowLinks(value, process) {
      this.subProcessLink = "";
      this.subProcessData = process;
      this.subProcessLink = value;
    },

    openSubProcess(process) {
      this.subProcessData = process;
      this.openSubProcessSheet = true;
    },

    closeSubProcess() {
      this.openSubProcessSheet = false;
      this.subProcessData = {};
      this.initiateSubWorkflowProcess = false;
      // this.subProcessTickets = [];
    },

    initiateSubWorkflow() {
      this.initiateSubWorkflowProcess = true;
      //this.closeProcessSheet();
    },

    async getUserWorkflow() {
      this.workflowList = [];
      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.getUserWorkflow();

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        payload.forEach((workflow) => {
          this.workflowList.push({
            id: this.$nano.id(),
            label: workflow.name,
            value: workflow.id,
            initiatedBy: workflow.initiatedBy,
            formId: workflow.wFormId,
            repositoryId: workflow.repositoryId,
            flowJson: "", //JSON.parse(workflow.flowJson),
          });
        });
      }
      this.$store.commit("hideLoadingBarSave");
    },

    closeSubProcessSheet() {
      this.subProcessSheet = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pulse {
  // position: absolute;
  // top: 4px;
  // right: 4px;

  position: fixed;
  top: 12px;
  right: 110px;
}

.notificationData {
  .notificationContent {
    border-bottom: 1px solid var(--divider-color);
    padding: 10px;
  }
  .notificationContent:hover {
    background-color: var(--hover-bg-color);
    cursor: pointer;
  }
  .no-history {
    font-weight: 500;
    padding: 16px 0px;
    text-align: center;
  }

  .entry {
    padding-left: 24px;
    position: relative;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0px;
      top: 6px;
      background-color: var(--secondary);
      border-radius: 100%;
    }

    .header {
      color: var(--title-1-color);
      font-weight: 600;
      margin-bottom: 4px;
    }

    .content {
      .changes-meta {
        display: flex;
        align-items: center;
        @extend .text-sm;
        color: var(--icon-color);
        margin-bottom: 5px;

        .action {
          margin-left: auto;
        }
      }

      .change {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-left: -6px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .unread {
    background: $primary-1;
  }
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }
}

#sheet .attachmentList,
#sheet .taskEntries,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #approvalSignature {
    background-color: var(--component-bg-color);
    border-radius: 4px;

    .title {
      @extend .text-md;
      font-weight: 600;
      text-transform: capitalize;
    }

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
    .text {
      text-align: center;
    }

    .imgCenter {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      height: 100px;
    }
  }
}

#modal {
  #action-btn.copyActions {
    width: 24px !important;
    height: 24px !important;
  }
}

#sheet {
  #form-view {
    background-color: var(--body-bg-color);
    margin: auto;
    padding: 16px;

    .header {
      padding: 16px;
      border-radius: 4px;
      background-color: var(--component-bg-color);
      border-top: 4px solid var(--primary);
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      .description {
        @extend .text-sm;
        color: var(--icon-color-inverted);
      }
    }

    .footer {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 0;
      bottom: 16px;
      width: 100%;

      .label {
        @extend .text-sm;
        color: var(--icon-color-inverted);
        margin-right: 4px;
      }

      img {
        height: 16px;
        width: auto;
      }
    }
  }
}

#modal #processHistory {
  #history {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    #attachments {
      width: 98%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 5px;
      background: #fcfafa;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }
}

#sheet .subProcess {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}
</style>
