<template>
  <div class="reports">
    <BaseScrollbar height="calc(100vh - 265px)">
      <template v-if="workflowId">
        <!-- child routes -->
        <div id="search">
          <!-- pie chart -->
          <div class="row items-start q-col-gutter-sm q-my-sm">
            <template v-for="(item, index) in workflowCount">
              <template v-if="item.visible">
                <div
                  :key="'workflowcount' + workflowId + '' + index"
                  class="col"
                >
                  <div class="chart chartBox">
                    <div class="row text-capitalize items-center header">
                      <div class="col">
                        {{ item.workflowStatus }}
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-if="item.dashboard"
                          icon="mdi-pin"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          rotate="rotate-45"
                          @click="deleteItem(item, 'report')"
                        />

                        <BaseActionButton
                          v-else
                          icon="mdi-pin"
                          :color="'gray'"
                          no-border
                          is-flat
                          size="20px"
                          rotate="rotate-45"
                          @click="saveFilter(item, 'report')"
                        />
                      </div>
                    </div>
                    <div class="column">
                      <template v-if="item.matchingKey === 'totalRequests'">
                        <div
                          class="text-primary col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'completedCount'">
                        <div
                          class="text-primary col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'inboxCount'">
                        <div
                          class="text-orange col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'processCount'">
                        <div
                          class="text-green col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaOverdue'">
                        <div
                          class="text-red col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaProcessing'">
                        <div
                          class="text-green col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaAchieved'">
                        <div
                          class="text-green col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <!-- pie chart -->
        </div>
        <!-- child routes -->

        <div class="row q-mb-sm">
          <div class="col-6">
            <div id="inbox" class="q-mr-xs">
              <table id="inboxTable">
                <tbody>
                  <tr style="border-bottom: 1px solid">
                    <td>
                      <div
                        class="q-pl-sm text-bold cursor-pointer underline"
                        @click="gotoInbox"
                      >
                        Inbox
                        <span class="text-blue"> ({{ inboxCount }})</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BaseScrollbar height="350px">
                        <table id="">
                          <tbody>
                            <template v-for="group in groupKey">
                              <tr :key="group.name">
                                <!-- columns -->
                                <td style="cursor: pointer">
                                  <div v-if="group.name" class="row">
                                    <div class="group-by col-auto q-mb-sm">
                                      <div class="col-auto">
                                        <div class="groupLabel">
                                          {{
                                            group.name +
                                            " (" +
                                            group.totalCount +
                                            ")"
                                          }}
                                        </div>
                                      </div>
                                      <BaseIcon
                                        :name="
                                          group.expand
                                            ? 'mdi-chevron-down'
                                            : 'mdi-chevron-up'
                                        "
                                        class="cursor-pointer q-ml-sm"
                                        @click="group.expand = !group.expand"
                                      />
                                    </div>
                                  </div>
                                  <template
                                    v-for="request in groupRequestList(
                                      group.name
                                    )"
                                  >
                                    <div
                                      v-if="request.visible && group.expand"
                                      :key="group.name + '' + request.processId"
                                      class="list q-mb-xs q-pa-sm"
                                      @click="showRequest(request)"
                                    >
                                      <div class="row">
                                        <q-icon
                                          name="mdi-email-outline"
                                          color="primary"
                                          class="q-mr-sm"
                                          size="22px"
                                        />

                                        <span class="col-auto underline">{{
                                          request.requestNo
                                        }}</span>
                                        <BaseIcon
                                          name="mdi-circle-small"
                                          color="secondary"
                                        />
                                        <span class="col-auto ellipsis">{{
                                          request.stage
                                        }}</span>
                                        <BaseIcon
                                          name="mdi-circle-small"
                                          color="secondary"
                                        />
                                        <span class="col">{{
                                          lastActionDuration(request)
                                        }}</span>
                                        <BaseActionButton
                                          v-tooltip.top="'history'"
                                          icon="mdi-history"
                                          color="primary"
                                          is-flat
                                          no-border
                                          class="col-auto q-mr-sm actions"
                                          size="22px"
                                          @click.stop="
                                            $emit('history', request)
                                          "
                                        />

                                        <BaseActionButton
                                          v-tooltip.top="'attachments'"
                                          is-flat
                                          no-border
                                          icon="mdi-attachment"
                                          color="primary"
                                          class="col-auto q-mr-sm actions"
                                          size="22px"
                                          @click.stop="
                                            $emit('attachments', request)
                                          "
                                        >
                                          <span
                                            v-if="request.attachmentCount"
                                            class="badge"
                                            >{{ request.attachmentCount }}
                                          </span>
                                        </BaseActionButton>
                                        <BaseActionButton
                                          v-tooltip.top="'comments'"
                                          is-flat
                                          no-border
                                          icon="mdi-comment-text-outline"
                                          color="primary"
                                          class="col-auto q-mr-xs actions"
                                          size="22px"
                                          @click.stop="
                                            $emit('comments', request)
                                          "
                                        >
                                          <span
                                            v-if="request.commentsCount"
                                            class="badge"
                                            >{{ request.commentsCount }}
                                          </span>
                                        </BaseActionButton>
                                      </div>
                                    </div>
                                  </template>
                                </td>

                                <!-- ... -->
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </BaseScrollbar>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-6">
            <div class="q-ml-xs card">
              <div class="q-pa-sm text-bold">Last Excel Import Report</div>
              <div class="q-mt-sm" style="height: 350px">
                <table class="tbl-import-status">
                  <tbody>
                    <tr>
                      <td class="text-bold text-secondary">Import Date</td>
                      <td>{{ masterFileProcessTable.importDate || "-" }}</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-secondary">
                        Total Imported Rows
                      </td>
                      <td>{{ masterFileProcessTable.totalRows || "-" }}</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-secondary">
                        <!-- Newly Discovered Resource IDs -->
                        Inserted row count
                      </td>
                      <td>{{ masterFileProcessTable.inserted || "-" }}</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-secondary">
                        Updated row count
                      </td>
                      <td>{{ masterFileProcessTable.updated || "-" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 q-pr-sm q-mb-sm">
            <div class="chart">
              <div class="row item-center q-body q-mb-sm">
                <div
                  class="col-auto q-mr-xs row cursor-pointer header"
                  :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                  style="width: 100%"
                  @click="
                    chartDataDrilldownStage.month = '';
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount();
                  "
                >
                  <div class="col">Month</div>
                  <div class="col-auto q-pa-sm">
                    <BaseActionButton
                      icon="mdi-pin"
                      :color="pinedchart ? 'secondary' : 'gray'"
                      no-border
                      is-flat
                      size="20px"
                      rotate="rotate-45"
                      @click.stop="
                        pinedchart
                          ? deleteItem(chartItem, 'fullchart')
                          : saveFilter(chartItem, 'fullchart')
                      "
                    />
                  </div>
                </div>
                <div
                  v-if="chartDataDrilldownStage.month"
                  class="col-auto cursor-pointer header"
                  :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount(chartDataDrilldownStage.month);
                  "
                >
                  / {{ chartDataDrilldownStage.month }}
                </div>
                <div
                  v-if="chartDataDrilldownStage.week"
                  class="col-auto q-ml-xs header"
                  @click="chartDataDrilldownStage.week = ''"
                >
                  / {{ chartDataDrilldownStage.week }}
                </div>
              </div>
              <vue-highcharts
                ref="refWorkflowChart"
                :highcharts="Highcharts"
                :options="chartData"
              ></vue-highcharts>
            </div>
          </div>

          <div
            v-for="(item, index) in barChartData"
            :key="'overall-bar' + index"
            class="col-4 q-pr-sm q-pb-md"
            style="height: 350px"
          >
            <BarChart
              :title="item.key"
              :x-data="item.xAxis"
              :y-data="item.yAxis"
              :is-horizontal="true"
              :is-stacked="true"
              :options="barChartOptions"
              :dashboard="item.dashboard"
              @pin="saveFilter(item, 'bar')"
              @isDelete="deleteItem(item, 'bar')"
            />
          </div>

          <div
            v-for="(item, key) in pieChartData"
            :key="'dropdown-pie' + key"
            class="col-4 q-pr-sm q-pb-md"
            style="height: 350px"
          >
            <PieChart
              :title="item.name"
              :x-data="item.xAxis"
              :y-data="item.yAxis"
              :options="pieChartOptions"
              :dashboard="item.dashboard"
              @pin="saveFilter(item, 'Pie')"
              @isDelete="deleteItem(item, 'Pie')"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <!-- child routes -->
        <div id="search">
          <!-- pie chart -->
          <div class="row items-start q-col-gutter-sm q-my-sm">
            <template v-for="(item, index) in workflowCount">
              <template v-if="item.visible">
                <div :key="'workflowcount' + index" class="col">
                  <div class="chart chartBox">
                    <div class="row text-capitalize items-center header">
                      <div class="col">
                        {{ item.workflowStatus }}
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-if="item.dashboard"
                          icon="mdi-pin"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          rotate="rotate-45"
                          @click="deleteItem(item, 'report')"
                        />

                        <BaseActionButton
                          v-else
                          icon="mdi-pin"
                          :color="'gray'"
                          no-border
                          is-flat
                          size="20px"
                          rotate="rotate-45"
                          @click="saveFilter(item, 'report')"
                        />
                      </div>
                    </div>
                    <div class="column">
                      <template v-if="item.matchingKey === 'totalRequests'">
                        <div
                          class="text-primary col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'completedCount'">
                        <div
                          class="text-primary col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'inboxCount'">
                        <div
                          class="text-orange col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'processCount'">
                        <div
                          class="text-green col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaOverdue'">
                        <div
                          class="text-red col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaProcessing'">
                        <div
                          class="text-orange col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaAchieved'">
                        <div
                          class="text-green col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <!-- pie chart -->
        </div>
        <!-- child routes -->

        <div class="row">
          <template v-if="myInboxCount">
            <div id="inbox" class="col-6 q-mr-sm q-mb-sm">
              <table id="inboxTable">
                <tbody>
                  <tr style="border-bottom: 1px solid">
                    <td>
                      <div class="row">
                        <div
                          class="col-auto q-pl-sm text-bold cursor-pointer underline"
                          style="margin: auto"
                          @click="gotoInbox"
                        >
                          All Inbox
                          <span class="text-blue"> ({{ myInboxCount }})</span>
                        </div>
                        <div class="col">
                          <BaseActionButton
                            v-tooltip.top="'refresh'"
                            icon="mdi-refresh"
                            color="secondary"
                            no-border
                            is-flat
                            size="20px"
                            class="q-mr-sm"
                            style="float: right"
                            @click="getMyInboxList"
                          />
                        </div>
                      </div>
                    </td>
                    <!-- <td>
                      <div class="row">Met SLA</div>
                      <div class="text-bold text-center">{{ metSla }}</div>
                    </td>
                    <td>
                      <div class="row">Exceed SLA</div>
                      <div class="text-bold text-center">{{ exceedSla }}</div>
                    </td> -->
                  </tr>
                  <tr>
                    <td colspan="3">
                      <BaseScrollbar height="350px">
                        <table id="">
                          <tbody>
                            <template v-for="group in groupKey">
                              <tr v-if="group.name" :key="group.name">
                                <!-- columns -->

                                <td style="cursor: pointer">
                                  <div class="row">
                                    <div class="group-by col-auto q-mb-sm">
                                      <div class="col-auto">
                                        <div class="groupLabel">
                                          {{
                                            group.name +
                                            " (" +
                                            group.totalCount +
                                            ")"
                                          }}
                                        </div>
                                      </div>
                                      <BaseIcon
                                        :name="
                                          group.expand
                                            ? 'mdi-chevron-down'
                                            : 'mdi-chevron-up'
                                        "
                                        class="cursor-pointer q-ml-sm"
                                        @click="group.expand = !group.expand"
                                      />
                                    </div>
                                  </div>
                                  <template
                                    v-for="request in groupRequestList(
                                      group.name
                                    )"
                                  >
                                    <div
                                      v-if="request.visible && group.expand"
                                      :key="group.name + '' + request.processId"
                                      class="list q-mb-xs q-pa-sm"
                                      @click="showRequest(request)"
                                    >
                                      <div class="row">
                                        <q-icon
                                          name="mdi-email-outline"
                                          color="primary"
                                          class="q-mr-sm"
                                          size="22px"
                                        />

                                        <span class="col-auto underline">{{
                                          request.requestNo
                                        }}</span>
                                        <BaseIcon
                                          name="mdi-circle-small"
                                          color="secondary"
                                        />
                                        <span class="col-auto ellipsis">{{
                                          request.stage
                                        }}</span>

                                        <BaseIcon
                                          name="mdi-circle-small"
                                          color="secondary"
                                        />
                                        <span class="col">{{
                                          lastActionDuration(request)
                                        }}</span>

                                        <BaseActionButton
                                          v-tooltip.top="'history'"
                                          icon="mdi-history"
                                          color="primary"
                                          is-flat
                                          no-border
                                          class="col-auto q-mr-sm actions"
                                          size="22px"
                                          @click.stop="
                                            $emit('history', request)
                                          "
                                        />

                                        <BaseActionButton
                                          v-tooltip.top="'attachments'"
                                          is-flat
                                          no-border
                                          icon="mdi-attachment"
                                          color="primary"
                                          class="col-auto q-mr-sm actions"
                                          size="22px"
                                          @click.stop="
                                            $emit('attachments', request)
                                          "
                                        >
                                          <span
                                            v-if="request.attachmentCount"
                                            class="badge"
                                            >{{ request.attachmentCount }}
                                          </span>
                                        </BaseActionButton>
                                        <BaseActionButton
                                          v-tooltip.top="'comments'"
                                          is-flat
                                          no-border
                                          icon="mdi-comment-text-outline"
                                          color="primary"
                                          class="col-auto q-mr-xs actions"
                                          size="22px"
                                          @click.stop="
                                            $emit('comments', request)
                                          "
                                        >
                                          <span
                                            v-if="request.commentsCount"
                                            class="badge"
                                            >{{ request.commentsCount }}
                                          </span>
                                        </BaseActionButton>
                                      </div>
                                    </div>
                                  </template>
                                </td>

                                <!-- ... -->
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </BaseScrollbar>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>

          <div id="workflow" class="col q-mb-sm">
            <BaseScrollbar height="390px">
              <table id="workflowTable">
                <thead>
                  <tr>
                    <!-- columns -->

                    <th v-for="column in allCountColumns" :key="column.id">
                      <template>
                        {{ column.label }}
                      </template>
                    </th>

                    <!-- ... -->
                  </tr>
                </thead>
                <tbody>
                  <template v-for="item in allCountTable">
                    <tr :key="item.id">
                      <!-- columns -->

                      <td v-for="column in allCountColumns" :key="column.id">
                        <slot
                          :name="column.name"
                          :value="item[column.name]"
                          :item="item"
                        >
                          <template>
                            {{ item[column.name] }}
                          </template>
                        </slot>
                      </td>

                      <!-- ... -->
                    </tr>
                  </template>
                </tbody>
              </table>
            </BaseScrollbar>
          </div>
        </div>

        <div class="row">
          <div
            v-if="Object.keys(masterFileProcessTable).length"
            class="col-6 card"
            style="height: 420px"
          >
            <div class="q-pa-sm text-bold">Last Excel Import Report</div>
            <table class="tbl-import-status">
              <tbody>
                <tr>
                  <td class="text-bold text-secondary">Import Date</td>
                  <td>{{ masterFileProcessTable.importDate }}</td>
                </tr>
                <tr>
                  <td class="text-bold text-secondary">Total Imported Rows</td>
                  <td>{{ masterFileProcessTable.totalRows }}</td>
                </tr>
                <tr>
                  <td class="text-bold text-secondary">
                    Newly Discovered Resource IDs
                  </td>
                  <td>{{ masterFileProcessTable.inserted }}</td>
                </tr>
                <tr>
                  <td class="text-bold text-secondary">Resource IDs Updated</td>
                  <td>{{ masterFileProcessTable.updated }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </BaseScrollbar>
    <UnpinDashboard
      v-model="isDeleteConfirmationVisible"
      :delete-data="deleteData"
      :label="deleteName"
      @delete="success = true"
    />
  </div>
</template>

<script>
import BarChart from "@/components/common/charts/BarChart.vue";
import PieChart from "@/components/common/charts/PieChart.vue";
import VueHighcharts from "vue2-highcharts";
import Exporting from "highcharts/modules/exporting.js";
import Drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts";
import { report, workflow, overview, dashboard } from "@/api/factory.js";
import UnpinDashboard from "@/components/common/display/item-actions/components/UnpinDashboard.vue";

Drilldown(Highcharts);
Exporting(Highcharts);
export default {
  name: "OverviewReportCharts",

  components: {
    BarChart,
    VueHighcharts,
    PieChart,
    UnpinDashboard,
  },

  props: {
    workflowId: {
      type: Number,
      default: 0,
    },

    formId: {
      type: String,
      default: "",
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    repositoryFields: {
      type: Array,
      default: () => [],
    },

    slaSettings: {
      type: Number,
      default: 0,
    },

    workflowList: {
      type: Array,
      default: () => [],
    },

    workflow: {
      type: Object,
      default: () => {},
    },

    refreshInbox: {
      type: Boolean,
      default: false,
    },

    repositoryName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      workflowCount: [
        {
          workflowStatus: "Total Requests",
          matchingKey: "totalRequests",
          visible: true,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "Completed Requests",
          matchingKey: "completedCount",
          visible: true,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "Inbox Requests",
          matchingKey: "inboxCount",
          visible: true,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "Sent Requests",
          matchingKey: "processCount",
          visible: true,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "SLA Overdue",
          matchingKey: "slaOverdue",
          visible: true,
          additional: true,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "SLA Processing",
          matchingKey: "slaProcessing",
          visible: true,
          additional: true,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "SLA Achieved",
          matchingKey: "slaAchieved",
          visible: true,
          additional: true,
          totalCount: 0,
          dashboard: false,
        },
      ],
      barChartData: [],
      barChartOptions: {
        chart: {
          height: "85%",
        },
      },
      Highcharts: Highcharts,
      chartData: {
        chart: {
          type: "spline",
          height: "300px",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          // categories: [],
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yAxis: {
          title: {
            text: "Count",
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: ["#bfa0fe"],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name && e.point.y) {
                    if (this.chartDataDrilldownStage.month === "") {
                      this.chartDataDrilldownStage.month = e.point.name;
                      this.getWorkflowChartCount(e.point.name);
                    } else if (this.chartDataDrilldownStage.week === "") {
                      this.chartDataDrilldownStage.week = e.point.name;
                      this.getWorkflowChartCount(
                        this.chartDataDrilldownStage.month,
                        e.point.name.replace("Week ", "")
                      );
                    }
                  }
                },
              },
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          // {
          //   name: "Running",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0],
          // },
          // {
          //   name: "Completed",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 21, 11],
          // },
        ],
        drilldown: {},
      },
      chartDataDrilldownStage: { month: "", week: "" },
      pieChartData: [],
      pieChartOptions: {
        chart: {
          height: "85%",
        },
      },
      allCountColumns: [
        {
          id: this.$nano.id(),
          name: "workflowName",
          label: "Workflow Name",
        },
        {
          id: this.$nano.id(),
          name: "currentInbox",
          label: "Current Inbox",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "<15 Days",
          label: "<15 Days",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "<30 Days",
          label: "<30 Days",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "<45 Days",
          label: "<45 Days",
          type: "SHORT_TEXT",
        },
      ],
      allCountTable: [],
      currentPage: 1,
      itemsPerPage: 20,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupKey: [],
      filterBy: [],
      filters: [],
      totalItems: 0,
      myInboxCount: 0,
      inboxCount: 0,
      exceedSla: "",
      metSla: "",
      selectedWorkflow: {},
      workflowRequestList: [],
      formEditAccess: false,
      formEditControls: [],
      workflowNameAndCountTable: [],
      options: {
        colors: ["#a48965", "#ae845c", "#ae9751", "#7c7f83", "#a6a9aa"],
      },
      masterFileProcessTable: {},
      listedData: [],
      dashboard: false,
      chartFilter: [],
      idToLabelMap: {},
      success: false,
      isDeleteConfirmationVisible: false,
      deleteData: {},
      chartItem: {
        name: "monthlychart",
        description: "monthly chart",
      },
      pinedchart: false,
      deleteName: "",
    };
  },

  computed: {
    columns() {
      let column = [];
      this.repositoryFields.forEach((field) => {
        if (field.name === "Department" || field.name === "Document Type") {
          column.push({
            id: this.$nano.id(),
            name: field.name,
            label: field.name,
            dataType: field.dataType,
          });
        }
      });

      return column;
    },
  },

  watch: {
    workflow: {
      deep: true,
      immediate: true,
      async handler() {
        await this.getFilterList();
        if (this.workflowId) {
          this.getWorkflowCount();
          this.getWorkflowChartCount();
          this.getInboxList();
        } else {
          this.getUserOverallCount();
          this.getUserOverallInbox();
          // this.getAllWorkflowChartCount();
          this.getUserMyInbox();
          this.getSlaPercentage();
          this.getMyInboxList();
        }
        let origin = location.origin;
        if (
          origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com"
        ) {
          //this.getMasterFileProcess();
        }
      },
    },

    refreshInbox: {
      deep: true,
      immediate: true,
      handler() {
        if (this.refreshInbox) {
          this.$emit("update:refreshInbox", false);
          if (this.workflowId) {
            this.getInboxList();
          } else {
            this.getUserMyInbox();
            this.getMyInboxList();
          }
        }
      },
    },

    formFields: {
      deep: true,
      immediate: true,
      handler() {
        if (this.formFields.length) {
          let filterBy = [];
          filterBy = this.formFields
            .filter((field) => field.dataType === "SINGLE_SELECT")
            .map((field) => `${field.value}`);
          this.getChartByColumns(filterBy);
        }
      },
    },

    repositoryId: {
      deep: true,
      immediate: true,
      handler() {
        if (this.repositoryId) {
          this.$route.query.repositoryId = this.repositoryId;
        }
      },
    },

    success: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.success) {
          await this.getFilterList();
          if (this.workflowId) {
            this.getWorkflowCount();
            this.getWorkflowChartCount();
            this.getInboxList();
          } else {
            this.getUserOverallCount();
            this.getUserOverallInbox();
            this.getUserMyInbox();
            this.getSlaPercentage();
            this.getMyInboxList();
          }

          if (this.formFields.length) {
            let filterBy = [];
            filterBy = this.formFields
              .filter((field) => field.dataType === "SINGLE_SELECT")
              .map((field) => `${field.value}`);
            this.getChartByColumns(filterBy);
          }
          // let origin = location.origin;
          // if (
          //   origin === "https://app.ezofis.com1" ||
          //   origin === "https://appuat.ezofis.com" ||
          //   origin === "http://localhost:8080"
          // ) {
          //   //this.getMasterFileProcess();
          // }
        }
      },
    },
  },

  methods: {
    gotoInbox() {
      this.$router.push({ name: "workflows-inbox" });
    },

    groupSelected(group) {
      let groupList = this.workflowRequestList.filter(
        (row) => row.key === group
      );
      let count = 0;
      groupList.forEach((process) => {
        if (process.checked) {
          count++;
        }
      });
      return count > 1 ? true : false;
    },

    groupRequestList(group) {
      return this.workflowRequestList.filter((row) => row.key === group);
    },

    showRequest(request) {
      this.$emit("open", request);
    },

    lastActionDuration(request) {
      const date =
        request.transaction_createdAt instanceof Date
          ? request.transaction_createdAt
          : new Date(request.transaction_createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    async getUserOverallCount() {
      this.workflowId = 0;

      this.workflowCount.forEach(
        (item) => (
          (item.visible = true), (item.totalCount = 0), (item.dashboard = false)
        )
      );

      this.$store.commit("showLoadingBar");
      const { error, payload } = await overview.getUserOverallCount();
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.workflowId === 0) {
        let List = this.listedData.map((filter) => ({
          id: this.$nano.id(),
          label: filter.name,
          filterJson: JSON.parse(filter.filterJson),
          dashboard: false,
        }));
        List.forEach((item) => {
          let id = item.filterJson[0].uId;
          let key = item.filterJson[0].key;
          let payloade = key in payload;
          if (id === this.workflowId && payloade) {
            this.workflowCount.forEach((workflowItem) => {
              if (workflowItem.matchingKey === key) {
                workflowItem.dashboard = true;
              }
            });
          }
        });
      }
      if (payload) {
        this.workflowCount.forEach((entry) => {
          const matchingKey = Object.keys(payload).find(
            (key) => key === entry.matchingKey
          );

          if (matchingKey) {
            entry.totalCount = payload[matchingKey];
          }
        });
      }
    },

    async getUserOverallInbox() {
      this.$store.commit("showLoadingBar");
      this.workflowNameAndCountTable = [];
      const { error, payload } = await overview.getUserOverallInbox();
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        payload.forEach((count) => {
          this.allCountTable.push({
            workflowName: count.wname,
            currentInbox: count.currentInbox,
            ["<15 Days"]: count["< 15 Days"],
            ["<30 Days"]: count["< 30 Days"],
            ["<45 Days"]: count["< 45 Days"],
          });
        });
      }
    },

    async getAllWorkflowChartCount() {
      this.$store.commit("showLoadingBar");
      this.chartData.series = [];
      let input = {
        year: new Date().getFullYear(),
      };
      const { error, payload } = await report.getAllWorkflowChart(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        const series = payload.map((item) => {
          const category = Object.keys(item)[0];
          const seriesData = [];

          for (const status in item[category]) {
            const data = Object.entries(item[category][status]).map(
              ([month, value]) => ({
                name: month,
                y: parseInt(value),
                drilldown: true,
                category,
                status,
              })
            );
            seriesData.push({
              name: status,
              data,
            });
          }

          return {
            name: category,
            data: seriesData,
          };
        });

        let lineCharts = this.$refs.refWorkflowChart;
        if (lineCharts) {
          try {
            lineCharts.removeSeries();
          } catch (e) {
            //
          }

          series.forEach((item) => {
            this.chartData.series = item;
            lineCharts.addSeries(this.chartData.series);
          });
        }
      }
    },

    async getWorkflowCount() {
      this.$store.commit("showLoadingBar");
      this.workflowCount.forEach(
        (item) => ((item.visible = true), (item.totalCount = 0))
      );
      const { error, payload } = await overview.getRequestCountByUserId(
        this.workflowId
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (this.slaSettings === 1 || this.slaSettings === 3) {
        this.workflowCount.forEach((item) => {
          if (item.additional) {
            item.visible = false;
          }
        });
      }
      if (payload) {
        this.workflowCount.forEach((entry) => {
          const matchingKey = Object.keys(payload).find(
            (key) => key === entry.matchingKey
          );

          if (matchingKey) {
            entry.totalCount = payload[matchingKey];
          }
        });
      }
      this.workflowCount.forEach((workflowItem) => {
        workflowItem.dashboard = false;
      });

      if (this.workflowId !== 0) {
        let List = this.listedData.map((filter) => ({
          id: this.$nano.id(),
          label: filter.name,
          filterJson: JSON.parse(filter.filterJson),
          dashboard: false,
        }));

        List.forEach((item) => {
          let id = item.filterJson[0].uId;
          let key = item.filterJson[0].key;
          let payloade = key in payload;
          if (id === this.workflowId && payloade) {
            this.workflowCount.forEach((workflowItem) => {
              if (workflowItem.matchingKey === key) {
                workflowItem.dashboard = true;
              }
            });
          }
        });
      }
    },

    async getWorkflowChartCount(month = "", weekno = "") {
      this.pinedchart = false;
      this.$store.commit("showLoadingBar");
      this.chartData.series = [];
      const weekNumber = weekno.replace(/\D/g, "");
      let input = {
        year: new Date().getFullYear(),
        month: month,
        weekno: weekNumber,
      };
      const { error, payload } = await report.getWorkflowChartByUserId(
        this.workflowId,
        input
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        if (payload.completed && payload.initiated) {
          let yAxis = [];
          // this.chartData.xAxis.categories = Object.keys(payload.completed);

          yAxis.push({
            name: "Running",
            data: Object.values(payload.initiated).map(Number),
          });

          yAxis.push({
            name: "Completed",
            data: Object.values(payload.completed).map(Number),
          });

          this.chartData.series = yAxis;

          let lineCharts = this.$refs.refWorkflowChart;
          try {
            lineCharts.removeSeries();
          } catch (e) {
            //
          }

          lineCharts.addSeries(this.chartData.series[0]);
          lineCharts.addSeries(this.chartData.series[1]);
        }
      }
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.description,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      List.forEach((item) => {
        let id = item.filterJson[0].uId;
        let type = item.filterJson[0].type;
        if (id === this.workflowId && type === "fullchart") {
          this.pinedchart = true;
        }
      });
    },

    async getChartByColumns(filterBy) {
      this.chartFilter = filterBy;
      this.barChartData = [];
      this.pieChartData = [];
      const { error, payload } = await report.getChartByColumnsByUserId(
        this.workflowId,
        filterBy
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        const idToLabelMap = this.formFields
          .filter((field) => field.dataType === "SINGLE_SELECT")
          .reduce((acc, field) => {
            acc[field.value] = field.label;
            return acc;
          }, {});
        this.idToLabelMap = idToLabelMap;
        const payloadWithLabels = Object.keys(payload).reduce((acc, key) => {
          const label = idToLabelMap[key.replace(/"/g, "")];
          if (label) {
            acc[label] = payload[key];
          }
          return acc;
        }, {});

        Object.keys(payload).forEach((category) => {
          const categoryName = category.replace(/"/g, "");

          payload[category].forEach((entry) => {
            entry.name = entry[categoryName] ? entry[categoryName] : "";
            delete entry[categoryName];
          });
        });

        Object.keys(payloadWithLabels).forEach((key) => {
          let xAxis = [],
            yAxisSeries1 = [],
            yAxisSeries2 = [];
          payloadWithLabels[key].forEach((row1) => {
            xAxis.push(row1.name);
            yAxisSeries1.push(row1.running);
            yAxisSeries2.push(row1.completed);
          });

          this.barChartData.push({
            key: `${key} - Report Chart`,
            xAxis: xAxis,
            yAxis: [
              {
                name: "Running",
                data: yAxisSeries1,
              },
              {
                name: "Completed",
                data: yAxisSeries2,
              },
            ],
          });
        });

        Object.keys(payloadWithLabels).forEach((key) => {
          payloadWithLabels[key].forEach((row) => {
            this.pieChartData.push({
              name: `${row.name} - Report Chart`,
              xAxis: ["Running", "Completed"],
              yAxis: [row.running, row.completed],
            });
          });
        });
      }
      this.barChartData.forEach((barChartData) => {
        barChartData.dashboard = false;
      });

      this.pieChartData.forEach((pieChartData) => {
        pieChartData.dashboard = false;
      });

      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.description,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      List.forEach((item) => {
        let id = item.filterJson[0].uId;
        let type = item.filterJson[0].type;
        let keys = item.label;
        if (id === this.workflowId && type === "bar") {
          this.barChartData.forEach((barChartData) => {
            if (barChartData.key === keys) {
              barChartData.dashboard = true;
            }
          });
        }
        if (id === this.workflowId && type === "Pie") {
          this.pieChartData.forEach((pieChartData) => {
            if (pieChartData.name === keys) {
              pieChartData.dashboard = true;
            }
          });
        }
      });
    },

    async getUserMyInbox() {
      const { error, payload } = await workflow.getUserMyInbox();

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.myInboxCount = Number(payload);
      }
    },

    async getSlaPercentage() {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await report.getSlaPercentageByUserId(
        this.workflowId
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        this.exceedSla = payload[0].exceedSla;
        this.metSla = payload[0].metSla;
      }
    },

    async getMyInboxList() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      this.$store.commit("showLoadingBar");
      let filterBy = {
        filterBy: this.filters,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      };
      const { error, payload } = await workflow.getMyInboxList(filterBy);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      this.totalItems = payload.meta.totalItems;
      payload.data.forEach((row) => {
        let listAction = [];
        let actionFields = [];
        if (row.key && row.value.length) {
          let myInboxWorkflow = this.workflowList.find(
            (workflow) => workflow.value == row.workflowId
          );
          if (myInboxWorkflow) {
            let wflowJson = myInboxWorkflow.flowJson;
            wflowJson.rules.forEach((rule) => {
              if (rule.fromBlockId === row.value[0].activityId) {
                let icon = "",
                  color = "";
                switch (rule.proceedAction) {
                  case "Forward":
                  case "Reply":
                    icon = "mdi-arrow-right";
                    color = "orange";
                    break;
                  case "Approve":
                  case "Complete":
                  case "Rightsize":
                    icon = "mdi-check";
                    color = "positive";
                    break;
                  case "Reject":
                  case "Terminate":
                    icon = "mdi-close";
                    color = "red";
                    break;
                  default:
                    icon = "mdi-arrow-left";
                    color = "primary";
                    break;
                }
                listAction.push({
                  id: this.$nano.id(),
                  name: rule.proceedAction,
                  icon: icon,
                  color: color,
                });
              }
            });
            wflowJson.blocks.forEach((block) => {
              if (block.id === row.value[0].activityId) {
                if (block.settings.formEditPartialAccess) {
                  actionFields = block.settings.formEditControls;
                }
                if (block.settings.internalForward) {
                  this.internalForward.proceed = true;
                  this.internalForward.users = [];
                  this.internalForward.groups = [];
                  listAction.push({
                    id: this.$nano.id(),
                    name: "Forward",
                    icon: "mdi-arrow-right",
                    color: "orange",
                  });
                }
              }
            });
          }
        }
        if (this.currentPage === 1) {
          this.groupKey.push({
            name: row.key,
            checked: false,
            expand: true,
            actions: listAction,
            actionFields: actionFields,
            totalCount: row.totalCount,
            checkboxRequired: false,
          });
        }
        row.value.forEach((group) => {
          group.value.forEach((process) => {
            this.workflowRequestList.push({
              key: row.key,
              ...process,
              repositoryId: this.selectedWorkflow.repositoryId,
              checked: false,
              starred: false,
              visible: true,
              checkboxRequired: false,
            });
          });
        });
      });
    },

    async getInboxList() {
      this.inboxCount = 0;
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      };

      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getInboxList(
        this.workflowId,
        filterBy
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      if (payload.meta.totalItems) {
        this.inboxCount = payload.meta.totalItems;
        this.totalItems = 0;
        payload.data.forEach((row) => {
          this.totalItems += row.totalCount;
          let listAction = [];
          let actionFields = [];
          if (row.key && row.value.length) {
            this.selectedWorkflow = this.workflowList.find(
              (workflow) => workflow.value == this.workflowId
            );
            let wflowJson = this.selectedWorkflow.flowJson;
            wflowJson.rules.forEach((rule) => {
              if (rule.fromBlockId === row.value[0].activityId) {
                let icon = "",
                  color = "";
                switch (rule.proceedAction) {
                  case "Forward":
                  case "Reply":
                    icon = "mdi-arrow-right";
                    color = "orange";
                    break;
                  case "Approve":
                  case "Complete":
                  case "Rightsize":
                    icon = "mdi-check";
                    color = "positive";
                    break;
                  case "Reject":
                  case "Terminate":
                    icon = "mdi-close";
                    color = "red";
                    break;
                  default:
                    icon = "mdi-arrow-left";
                    color = "primary";
                    break;
                }
                listAction.push({
                  id: this.$nano.id(),
                  name: rule.proceedAction,
                  icon: icon,
                  color: color,
                });
              }
            });
            wflowJson.blocks.forEach((block) => {
              if (block.id === row.value[0].activityId) {
                if (block.settings.formEditPartialAccess) {
                  actionFields = block.settings.formEditControls;
                }
                // if (block.settings.internalForward) {
                //   this.internalForward.proceed = true;
                //   this.internalForward.users = [];
                //   this.internalForward.groups = [];
                //   listAction.push({
                //     id: this.$nano.id(),
                //     name: "Assign",
                //     icon: "mdi-arrow-right",
                //     color: "orange",
                //   });
                // }
              }
            });
          }
          if (this.currentPage === 1) {
            this.groupKey.push({
              name: row.key,
              checked: false,
              expand: true,
              actions: listAction,
              actionFields: actionFields,
              totalCount: row.totalCount,
              checkboxRequired: false,
            });
          }
          row.value.forEach((process) => {
            this.workflowRequestList.push({
              key: row.key,
              ...process,
              repositoryId: this.selectedWorkflow.repositoryId,
              checked: false,
              starred: false,
              visible: true,
              checkboxRequired: false,
            });
          });
        });
      }
    },

    async getMasterFileProcess() {
      this.masterFileProcessTable = {};
      const { error, payload } = await report.getMasterFileProcess(
        this.workflowId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.masterFileProcessTable = payload;
      }
    },

    async saveFilter(item, type) {
      this.success = false;
      let filter = JSON.stringify(this.filterBy);
      let filtering = [
        {
          uId: this.workflowId,
          type: type,
          chartFilter: this.chartFilter,
          key: item.matchingKey,
          formField: this.idToLabelMap,
          filters: filter,
        },
      ];
      let filterss = JSON.stringify(filtering);
      let userId = [this.$store.state.session.id];
      let name = "";
      let description = "";
      if (this.workflowId !== 0) {
        if (type === "Pie") {
          name = this.repositoryName + "  " + item.name;
          description = item.name;
        } else if (type === "bar") {
          name = this.repositoryName + "  " + item.key;
          description = item.key;
        } else if (type === "report") {
          name = this.repositoryName + "  " + item.workflowStatus;
          description = item.workflowStatus;
        } else if (type === "fullchart") {
          name = this.repositoryName + "  " + item.name;
          description = item.name;
        }
      } else if (this.workflowId === 0) {
        name = "All" + "  " + item.workflowStatus;
        description = item.workflowStatus;
      }
      let input = {
        name: name,
        description: description,
        filterJson: filterss,
        filterFrom: "workflowOverview",
        pinDashboard: true,
        assignTo: JSON.stringify(userId),
      };
      const { error } = await dashboard.saveDashboard(input);
      if (error) {
        this.$alert.warning("The Name Already Registered ");
        return;
      }
      this.success = true;
      this.$alert.success(name + "  " + "Pinned SuccessFully");
    },
    async getFilterList() {
      this.loadingBarContent = true;
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "workflowOverview",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
              {
                criteria: "filterJson",
                condition: "CONTAINS",
                value: '"uId":' + this.workflowId + ",",
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      this.loadingBarContent = false;
      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      let data = payload.data[0].value;
      this.listedData = data;
    },

    deleteItem(value, type) {
      this.success = false;
      let names = "";
      if (this.workflowId !== 0) {
        if (type === "Pie") {
          names = this.repositoryName + "  " + value.name;
        } else if (type === "bar") {
          names = this.repositoryName + "  " + value.key;
        } else if (type === "report") {
          names = this.repositoryName + "  " + value.workflowStatus;
        } else if (type === "fullchart") {
          names = this.repositoryName + "  " + value.name;
        }
      } else if (this.workflowId === 0) {
        names = "All" + "  " + value.workflowStatus;
      }
      this.deleteName = names;
      this.listedData.map((item) => {
        const parse = JSON.parse(item.filterJson);
        if (parse[0].uId === this.workflowId && item.name === names) {
          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  .underline {
    text-decoration: underline;
  }

  .link-style {
    cursor: pointer;
    text-decoration: none;
  }

  .link-style:hover {
    text-decoration: underline;
  }

  .wrapper {
    position: relative;
    height: calc(100vh - 208px);
  }

  .chart {
    height: 375px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    &.chartBox {
      height: 100px;
    }
  }

  .header {
    text-anchor: start;
    font-size: 15px;
    font-weight: 700;
    fill: rgb(55, 61, 63);
  }

  .header-top {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .label {
    color: #a391cf !important;
  }

  .count {
    color: #8162cc;
    font-weight: 800;
    font-size: 24px;
  }

  .group-by {
    //background-color: var(--deep-orange);
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #e9a177;
    //color: white;

    .groupLabel {
      font-weight: 500;
    }
  }

  .list {
    background-color: #e1f5f9;
    border-radius: 6px;
    height: 100%;
    border: 1px solid #e1f5f9;
    cursor: pointer;

    &.active-list {
      background-color: $secondary-2 !important;
    }

    &:hover {
      background-color: $secondary-2 !important;
    }
  }

  #workflow {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #workflowTable {
      border: 1px solid var(--divider-color);
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
        font-weight: 500;
      }

      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }
    }
  }

  #inbox {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #inboxTable {
      width: 100%;
      white-space: nowrap;
      border: 1px solid var(--divider-color);
      border-radius: 10px;

      table {
        width: 100%;
        padding: 5px;
        border-top: 1px solid var(--divider-color);
      }

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        font-weight: 500;
      }
    }

    #action-btn.actions {
      width: 26px !important;
      height: 26px !important;
    }

    .badge {
      -webkit-border-radius: 9px;
      -moz-border-radius: 9px;
      border-radius: 9px;
      font-size: 10px;
      background: $primary;
      color: #fff;
      padding: 0 5px;
      vertical-align: top;
      height: 18px;
      margin-top: -18px;
      margin-right: -18px;
      position: absolute;
      z-index: 1;
    }
  }

  .card {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    table {
      border: 1px solid var(--divider-color);
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
      }

      tr:not(:first-child) td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      td:first-child {
        border-right: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      .bold {
        font-weight: bold;
      }
    }
  }

  .tbl-import-status {
    td {
      width: 50%;
    }
  }
}
</style>
