<template>
  <div>
    <FormFieldLabel label="Workflow" is-mandatory />
    <div class="q-pa-md" style="border: 1px solid var(--divider-color)">
      <div id="global-search" class="q-mb-md">
        <BaseIcon name="eva-search" class="q-ml-xs" />

        <div class="label">
          <input
            ref="searchQuery"
            v-model="search"
            type="text"
            class="q-px-xs"
            placeholder="Search ..."
          />
        </div>
      </div>

      <div
        class="add-workflow row justify-center items-center q-mb-md"
        @click="addWorkflow"
      >
        <BaseIcon name="eva-plus" class="q-mr-sm" />
        <div class="">Add Workflow</div>
      </div>
      <Draggable v-model="workflowSettings">
        <template v-for="(settings, index) in _workflowSettings">
          <div
            :key="settings.id"
            class="workflow-list row items-center q-mt-sm"
          >
            <div class="col">
              {{ findWorkflowName(settings.workflowId) }}
            </div>
            <div class="col-1 edit">
              <BaseIcon
                name="eva-edit-outline"
                size="22px"
                class="q-mr-md cursor-pointer"
                @click="editWorkflow(settings)"
              />
            </div>
            <div class="col-1 delete">
              <BaseIcon
                name="eva-trash-outline"
                size="22px"
                class="cursor-pointer"
                @click="deleteWorflow(index)"
              />
            </div>
          </div>
        </template>
      </Draggable>
    </div>

    <Sheet
      :value="showAddWorkflowModal"
      width="520px"
      no-padding
      has-footer
      @input="closeWorkflow"
    >
      <!-- title -->

      <template #title>
        {{ editWorkflowModal ? "Edit" : "Add" }} Workflow Settings
      </template>

      <!-- ... -->

      <!-- form -->

      <template #default>
        <div id="workflow-settings" class="row">
          <!-- ... -->

          <div class="content field">
            <ValidationObserver ref="workflowForm">
              <ValidationProvider
                v-slot="{ errors }"
                name="name"
                :rules="{ required: true }"
              >
                <SelectField
                  v-model="workflow"
                  is-mandatory
                  label="Workflow"
                  :options="workflowList"
                  class="q-mb-md"
                  :error="errors[0]"
                />
              </ValidationProvider>

              <MultiSelectField
                v-model="workflowFormFields"
                label="Request preview info"
                :options="formFields"
                class="q-mb-md"
              />
              <template v-if="categoryFields.length">
                <SelectField
                  v-model="categoryField"
                  label="category Field"
                  :options="categoryFields"
                  class="q-mb-md"
                />

                <!-- <div
                  class="add-workflow row justify-center items-center q-mb-md"
                  @click="addCategory"
                >
                  <BaseIcon name="eva-plus" class="q-mr-sm" />
                  <div class="">
                    Add Category
                  </div>
                </div> -->
                <template v-if="categoryField">
                  <FormFieldLabel label="Category Settings" class="q-mb-md" />
                  <template v-if="categoryType === 'CUSTOM'">
                    <Draggable v-model="categorySettings">
                      <template v-for="(category, index) in categorySettings">
                        <div
                          :key="category.id"
                          class="workflow-list row items-center q-mt-sm"
                        >
                          <div class="col">
                            {{ category.title }}
                          </div>
                          <div class="col-1 edit">
                            <BaseIcon
                              name="eva-edit-outline"
                              size="22px"
                              class="q-mr-md cursor-pointer"
                              @click="editCategory(category)"
                            />
                          </div>
                          <div class="col-1 delete">
                            <BaseIcon
                              name="eva-trash-outline"
                              size="22px"
                              class="cursor-pointer"
                              @click="deleteCategory(index)"
                            />
                          </div>
                        </div>
                      </template>
                    </Draggable>
                  </template>

                  <div v-if="categoryType === 'MASTER'" id="master-table">
                    <table>
                      <thead>
                        <tr>
                          <th class="medium">Title</th>
                          <th class="medium">Description</th>

                          <th class="action">
                            <BaseActionButton
                              is-flat
                              class="q-ml-xs"
                              color="secondary"
                              icon="eva-plus-outline"
                              no-border
                              @click="addCategory"
                            />
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(row, index) in categoryFieldMasterSync"
                          :key="index"
                        >
                          <td>
                            <div class="row">
                              <SelectField
                                v-model="
                                  categoryFieldMasterSync[index]['title']
                                "
                                class="col"
                                :options="titleColumns"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <TextField
                                v-model="
                                  categoryFieldMasterSync[index]['description']
                                "
                                class="col"
                              />
                            </div>
                          </td>

                          <td class="action">
                            <BaseActionButton
                              is-flat
                              color="red"
                              icon="eva-close-outline"
                              no-border
                              @click="removeMasterFormSyncSettings(index)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </template>
            </ValidationObserver>
          </div>

          <!-- ... -->

          <!-- tabs -->

          <!-- <div class="tabs">
          <BaseActionButton
            v-for="tab in tabs"
            :key="tab.id"
            v-tooltip.left="tab.label"
            :is-flat="activeTab !== tab.value"
            :icon="tab.icon"
            :color="activeTab === tab.value ? tabColor : 'gray'"
            class="q-mb-md"
            @click="activeTab = tab.value"
          />
        </div> -->

          <!-- ... -->
        </div>
      </template>

      <!-- ... -->

      <!-- footer -->

      <template #footer>
        <BaseButton
          label="cancel"
          is-flat
          color="secondary"
          class="q-mr-sm"
          @click="closeWorkflow"
        />

        <BaseButton label="save" color="secondary" @click="saveWorkflow" />
      </template>

      <!-- ... -->
    </Sheet>

    <Modal
      v-model="addCategoryModal"
      has-footer
      width="40vw"
      height="60vh"
      @input="closeCategory"
    >
      <!-- title -->

      <template #title
        >{{ editCategoryModal ? "Edit" : "Add" }} Category
      </template>

      <!-- ... -->

      <template #default>
        <div class="content field">
          <ValidationObserver ref="categoryForm">
            <ValidationProvider
              v-slot="{ errors }"
              name="title"
              :rules="{ required: true }"
            >
              <TextField
                v-model="categoryTitle"
                label="title"
                is-mandatory
                is-readonly
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <!-- description -->

            <TextAreaField
              v-model="categoryDescription"
              label="description"
              class="q-mb-md"
            />

            <!-- ... -->
          </ValidationObserver>
        </div>
      </template>

      <!-- footer -->

      <template #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          @click="closeCategory"
        />

        <BaseButton label="Save" @click="saveCategory" />
      </template>

      <!-- ... -->
    </Modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
// import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
// import { ref } from "vue";
// import MultipleCheckboxField from "@/components/common/form/multiple-checkbox-field/MultipleCheckboxField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { workflow, form } from "@/api/factory.js";
import Modal from "@/components/common/popup/Modal.vue";
import { axiosCrypto } from "@/api/axios.js";
import Draggable from "@/components/common/Draggable.vue";

export default {
  name: "WorkflowSettings",

  components: {
    ValidationObserver,
    ValidationProvider,
    SelectField,
    TextField,
    MultiSelectField,
    TextAreaField,
    // MultipleCheckboxField,
    FormFieldLabel,
    Sheet,
    Modal,
    Draggable,
  },

  props: {
    workflowList: {
      type: Array,
      default: () => [],
    },

    workflowCategorySettings: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      search: "",
      showAddWorkflowModal: false,
      workflow: "",
      workflowFormFields: [],
      formFields: [],
      workflowSettings: [],
      categoryFields: [],
      categoryField: "",
      editWorkflowModal: false,
      addCategoryModal: false,
      editCategoryModal: false,
      categoryTitle: "",
      categoryDescription: "",
      categorySettings: [],
      fields: [],
      titleColumns: [],
      descriptionColumns: [],
      categoryFieldMasterSync: [],
      categoryType: "",
      workflowNanoId: "",
    };
  },

  computed: {
    _workflowSettings() {
      if (this.search) {
        let data = this.workflowSettings.filter((item) =>
          item.workflowName.toLowerCase().includes(this.search.toLowerCase())
        );
        return data;
      }
      return this.workflowSettings;
    },
  },

  watch: {
    workflow: {
      immediate: true,
      async handler() {
        if (this.workflow) {
          await this.getWorkflow();
        }
      },
    },

    categoryField: {
      immediate: true,
      handler(oldv, newv) {
        console.log(oldv, "oldv");
        console.log(newv, "newv");
        console.log(this.categoryField, "this.categoryField");

        if (this.categoryField) {
          let fields = this.fields.find(
            (field) => field.id === this.categoryField
          );
          if (fields) {
            if (fields.settings.specific.optionsType === "CUSTOM") {
              const separator =
                fields.settings.specific.separateOptionsUsing === "NEWLINE"
                  ? "\n"
                  : ",";

              let customOptions = fields.settings.specific.customOptions
                .split(separator)
                .map((option) => ({
                  id: this.$nano.id(),
                  label: option.trim(),
                  value: option.trim(),
                }));

              this.categorySettings = [];
              customOptions.forEach((item) => {
                this.categorySettings.push({
                  id: this.$nano.id(),
                  title: item.label,
                  description: "",
                });
              });

              this.categoryFieldMasterSync = [];
              this.categoryType = "CUSTOM";
            } else if (fields.settings.specific.optionsType === "MASTER") {
              this.titleColumns = [];
              this.categoryFieldMasterSync = [];

              this.getMasterEntries(
                fields.settings.specific.masterFormId,
                fields.settings.specific.masterFormColumn
              );
              this.categorySettings = [];
              this.categoryType = "MASTER";
            }
          }
        }
      },
    },

    workflowCategorySettings: {
      immediate: true,
      handler() {
        if (this.workflowCategorySettings.length) {
          this.workflowSettings = this.workflowCategorySettings;
        }
      },
    },

    // fields: {
    //   deep: true,
    //   handler() {
    //     if (this.categoryField) {
    //       let fields = this.fields.find(
    //         (field) => field.id === this.categoryField
    //       );
    //       if (fields) {
    //         if (fields.settings.specific.optionsType === "CUSTOM") {
    //           const separator =
    //             fields.settings.specific.separateOptionsUsing === "NEWLINE"
    //               ? "\n"
    //               : ",";

    //           let customOptions = fields.settings.specific.customOptions
    //             .split(separator)
    //             .map((option) => ({
    //               id: this.$nano.id(),
    //               label: option.trim(),
    //               value: option.trim(),
    //             }));

    //           customOptions.forEach((item) => {
    //             this.categorySettings.push({
    //               id: this.$nano.id(),
    //               title: item.label,
    //               description: "",
    //             });
    //           });

    //           this.categoryFieldMasterSync = [];
    //           this.categoryType = "CUSTOM";
    //         } else if (fields.settings.specific.optionsType === "MASTER") {
    //           this.titleColumns = [];
    //           // this.categoryFieldMasterSync = [];

    //           this.getMasterEntries(
    //             fields.settings.specific.masterFormId,
    //             fields.settings.specific.masterFormColumn
    //           );
    //           this.categorySettings = [];
    //           this.categoryType = "MASTER";
    //         }
    //       }
    //     }
    //   },
    // },
  },

  methods: {
    addWorkflow() {
      this.showAddWorkflowModal = true;
      this.workflow = "";
      this.workflowFormFields = [];
      this.categoryField = "";
      this.categorySettings = [];
      this.editWorkflowModal = false;
    },

    findWorkflowName(id) {
      return this.workflowList.find((item) => item.value === id)?.label;
    },

    async getWorkflow() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workflow.getWorkflow(this.workflow);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload);
      // console.log(JSON.parse(payload.flowJson));
      if (payload.wFormId) {
        let formId = String(payload.wFormId);
        await this.getForm(formId);
      }
    },

    async getForm(formId) {
      this.formFields = [];
      // this.workflowFormFields = [];
      this.categoryFields = [];
      if (formId) {
        this.$store.commit("showLoadingBar");

        const { error, payload } = await form.getForm(formId);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        let formJson = JSON.parse(payload.formJson);

        const panels = [...formJson.panels, ...formJson.secondaryPanels];

        if (!panels.length) {
          return;
        }
        let fields = [];
        for (const panel of panels) {
          fields.push(...panel.fields);
        }

        this.fields = fields;

        fields.forEach((field) => {
          if (field.type !== "DIVIDER") {
            this.formFields.push({
              id: this.$nano.id(),
              label: field.label,
              value: field.id,
              dataType: field.type === "DATE" ? field.type : "SHORT_TEXT",
            });
          }

          if (field.type === "SINGLE_SELECT") {
            this.categoryFields.push({
              id: this.$nano.id(),
              label: field.label,
              value: field.id,
            });
          }
        });

        if (this.categoryField) {
          let fields = this.fields.find(
            (field) => field.id === this.categoryField
          );
          if (fields) {
            // if (fields.settings.specific.optionsType === "CUSTOM") {
            //   const separator =
            //     fields.settings.specific.separateOptionsUsing === "NEWLINE"
            //       ? "\n"
            //       : ",";

            //   let customOptions = fields.settings.specific.customOptions
            //     .split(separator)
            //     .map((option) => ({
            //       id: this.$nano.id(),
            //       label: option.trim(),
            //       value: option.trim(),
            //     }));

            //   customOptions.forEach((item) => {
            //     this.categorySettings.push({
            //       id: this.$nano.id(),
            //       title: item.label,
            //       description: "",
            //     });
            //   });

            //   this.categoryFieldMasterSync = [];
            //   this.categoryType = "CUSTOM";
            // } else
            if (fields.settings.specific.optionsType === "MASTER") {
              this.titleColumns = [];
              // this.categoryFieldMasterSync = [];

              this.getMasterEntries(
                fields.settings.specific.masterFormId,
                fields.settings.specific.masterFormColumn
              );
              this.categorySettings = [];
              this.categoryType = "MASTER";
            }
          }
        }
      }
    },

    async saveWorkflow() {
      const isValid = await this.$refs.workflowForm.validate();
      if (!isValid) {
        return;
      }

      if (this.editWorkflowModal) {
        let index = this.workflowSettings.findIndex((item) => {
          return item.id === this.workflowNanoId;
        });
        if (index >= 0) {
          this.workflowSettings[index].workflowId = this.workflow;

          this.workflowSettings[index].formFields = this.workflowFormFields;

          this.workflowSettings[index].categoryField = this.categoryField;
          this.workflowSettings[index].categorySettings = this.categorySettings;
          this.workflowSettings[index].categorySettings = this.categorySettings;
          this.workflowSettings[index].workflowName = this.findWorkflowName(
            this.workflow
          );
        }
      } else {
        this.workflowSettings.push({
          id: this.$nano.id(),
          workflowId: this.workflow,
          formFields: this.workflowFormFields,
          categoryField: this.categoryField,
          categorySettings: this.categorySettings,
          categoryFieldMasterSync: this.categoryFieldMasterSync,
          workflowName: this.findWorkflowName(this.workflow),
        });
      }
      this.closeWorkflow();
      this.$parent.$emit(
        "update:workflowCategorySettings",
        this.workflowSettings
      );
    },

    closeWorkflow() {
      this.showAddWorkflowModal = false;
      this.workflow = "";
      this.workflowFormFields = [];
      this.categoryField = "";
      this.categorySettings = [];
      this.workflowNanoId = "";
    },

    editWorkflow(workflow) {
      this.editWorkflowModal = true;
      this.showAddWorkflowModal = true;

      this.workflowNanoId = workflow.id;
      this.workflow = workflow.workflowId;
      this.workflowFormFields = workflow.formFields;
      this.categoryField = workflow.categoryField;
      console.log(workflow.categoryField, "workflow.categoryField");
      this.categorySettings = workflow.categorySettings;
      this.categoryFieldMasterSync = workflow.categoryFieldMasterSync;

      if (this.categorySettings.length) {
        this.categoryType = "CUSTOM";
      } else if (this.categoryFieldMasterSync.length) {
        this.categoryType = "MASTER";
      }
    },

    deleteWorflow(index) {
      this.workflowSettings.splice(index, 1);
    },

    addCategory() {
      // this.addCategoryModal = true;
      this.editCategoryModal = false;
      this.categoryTitle = "";
      this.categoryDescription = "";
      this.categoryFieldMasterSync.push({
        id: this.$nano.id(),
        title: "",
        description: "",
      });
    },

    closeCategory() {
      this.addCategoryModal = false;
      this.editCategoryModal = false;
      this.categoryTitle = "";
      this.categoryDescription = "";
    },

    async saveCategory() {
      const isValid = await this.$refs.categoryForm.validate();
      if (!isValid) {
        return;
      }

      if (!this.editCategoryModal) {
        this.categorySettings.push({
          id: this.$nano.id(),
          title: this.categoryTitle,
          description: this.categoryDescription,
        });
      } else {
        let index = this.categorySettings.findIndex((item) => {
          return item.title === this.categoryTitle;
        });
        if (index >= 0) {
          this.categorySettings[index].description = this.categoryDescription;
        }
      }
      this.closeCategory();
    },

    editCategory(category) {
      this.addCategoryModal = true;
      this.editCategoryModal = true;

      this.categoryTitle = category.title;
      this.categoryDescription = category.description;
    },

    deleteCategory(index) {
      this.categorySettings.splice(index, 1);
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.titleColumns = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));

          // this.addCategory();
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    removeMasterFormSyncSettings(index) {
      this.categoryFieldMasterSync.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
#global-search {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: var(--icon-color);
  border: 1px solid var(--divider-color);

  .icon {
    color: var(--icon-color-inverted);
    border-right: 1px solid var(--divider-color);
    margin-right: 5px;
    padding: 7px;

    &.is-selected {
      color: var(--secondary) !important;
    }
  }

  .label {
    @extend .text-sm;
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;

    .icon,
    .label {
      color: var(--secondary);
    }
  }

  &.is-focused {
    .icon {
      color: var(--secondary);
    }
  }
}

.add-workflow {
  border: 3px dashed var(--secondary);
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.25rem;
  font-weight: 500;
  padding: 10px;
}

.workflow-list {
  border: 3px solid #d3ecf0;
  border-radius: 8px;
  cursor: all-scroll;
  font-size: 13px;
  line-height: 1.25rem;
  // font-weight: 500;
  padding: 10px;
  background: #d3ecf0;
}

#workflow-settings {
  .field {
    margin: 16px 16px 24px 16px;
  }

  .content {
    flex: 1;
    padding-bottom: 36px;
    // border-right: 1px solid var(--divider-color);
  }

  .tabs {
    width: 68px;
    height: 100%;
    height: calc(100vh - 112px);
    padding: 16px;
  }
}

#master-table {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 80px;
      }

      &.medium {
        width: 150px;
      }

      &.large {
        width: 210px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      vertical-align: top;
    }
  }
}
</style>
