<template>
  <div id="divWorkflowTree" class="tree">
    <BaseScrollbar height="calc(100vh - 225px)">
      <div class="row items-center">
        <div class="q-pa-xs">
          <q-tree
            ref="tree"
            :nodes="treeNodes"
            node-key="id"
            no-connectors
            :expanded.sync="expanded"
            selected-color="white"
            :selected.sync="selectedNode"
          >
            <template v-slot:default-header="prop">
              <div class="row items-center">
                <q-icon
                  v-if="prop.node.icon"
                  :name="
                    selectedNode === prop.node.id
                      ? prop.node.fillIcon
                      : prop.node.icon
                  "
                  :color="prop.node.color"
                  size="20px"
                  class="q-mr-sm"
                />
                <div
                  :class="[
                    selectedNode === prop.node.id
                      ? 'text-weight-bold'
                      : 'text-weight-normal',
                  ]"
                >
                  {{ prop.node.label }}
                </div>
              </div>
            </template>
          </q-tree>
        </div>
      </div>
    </BaseScrollbar>
  </div>
</template>
<script>
export default {
  name: "WorkflowTree",

  props: {
    workflowList: {
      type: Array,
      default: () => [],
    },

    myInboxCount: {
      type: Number,
      default: 0,
    },

    refresh: {
      type: Boolean,
      default: true,
    },

    loadInitial: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      initial: true,
      expanded: ["Shared Inbox"],
      selectedNode: "",
      treeNodes: [
        {
          id: this.$nano.id(),
          label: "My Inbox",
          children: [
            {
              id: "inbox",
              label: "Inbox (0)",
              icon: "eva-email-outline",
              fillIcon: "eva-email",
            },
            // {
            //   id: "starred",
            //   label: "Starred (0)",
            //   icon: "eva-star-outline",
            //   fillIcon: "eva-star",
            // },
          ],
        },
      ],
    };
  },

  watch: {
    workflowList: {
      deep: true,
      immediate: true,
      handler() {
        let origin = location.origin;
        if (this.workflowList.length && this.refresh) {
          // if (this.myInboxCount && !this.isTenantArmgroup()) {
          //   this.expanded = ["My Inbox"];
          //   this.selectedNode = "inbox";
          // } else {
          this.expanded = ["Shared Inbox"];
          this.selectedNode = "Shared Inbox";
          if (this.workflowList.length) {
            let expandWorkflow = this.workflowList.find(
              (row) => row.inboxCount
            );
            if (expandWorkflow) {
              this.expanded.push("workflow-" + expandWorkflow.id);
              this.selectedNode = "inbox-" + expandWorkflow.id;
            }
          }
          //}
          this.treeNodes = [];
          //let myStarredCount = 0;
          let sharedInbox = {
            id: "Shared Inbox",
            label: "Shared Inbox",
            children: [],
          };
          this.workflowList.forEach((workflow) => {
            let completedChild = [];
            let completedNode = {};

            if (workflow.completedInfo) {
              if (workflow.completedInfo.length > 1) {
                workflow.completedInfo.forEach((row) => {
                  completedChild.push({
                    id: `completed-${workflow.id}-${row.value}`,
                    label: `${row.value} (${row.totalCount})`,
                    icon: "eva-arrow-circle-right-outline",
                    fillIcon: "eva-arrow-circle-right",
                  });
                });
                completedNode = {
                  id: `completed-${workflow.id}`,
                  label: `Completed (${workflow.completedCount})`,
                  icon: "",
                  fillIcon: "",
                  children: completedChild,
                };
              } else if (workflow.completedInfo.length) {
                completedNode = {
                  id: `completed-${workflow.id}`,
                  label: `${workflow.completedInfo[0].value} (${workflow.completedCount})`,
                  icon: "eva-checkmark-circle-2-outline",
                  fillIcon: "eva-checkmark-circle-2",
                  children: completedChild,
                };
              }
            } else {
              completedNode = {
                id: `completed-${workflow.id}`,
                label: `Completed (${workflow.completedCount})`,
                icon: "eva-checkmark-circle-2-outline",
                fillIcon: "eva-checkmark-circle-2",
                children: completedChild,
              };
            }

            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net" ||
              (origin === "https://trial.ezofis.com" &&
                this.$store.state.session.tenantId === 12) ||
              origin === "http://localhost:8080"
            ) {
              sharedInbox.children.push({
                id: `workflow-${workflow.id}`,
                label: workflow.inboxCount
                  ? `${workflow.name} (${workflow.inboxCount})`
                  : workflow.name,
                children: [
                  {
                    id: `common-${workflow.id}`,
                    label: `All Inbox (${workflow.runningCount})`,
                    icon: "mdi-inbox-outline",
                    fillIcon: "mdi-inbox",
                  },
                  {
                    id: `inbox-${workflow.id}`,
                    label: `Inbox (${workflow.inboxCount})`,
                    icon: "eva-email-outline",
                    fillIcon: "eva-email",
                  },
                  {
                    id: `sent-${workflow.id}`,
                    label: `Sent (${workflow.processCount})`,
                    icon: "eva-person-done-outline",
                    fillIcon: "eva-person-done",
                  },
                  {
                    id: `payment-${workflow.id}`,
                    label: `Payment (${workflow.paymentProcessCount})`,
                    icon: "mdi-account-cash-outline",
                    fillIcon: "mdi-account-cash",
                  },
                  completedNode,
                ],
              });
            } else {
              sharedInbox.children.push({
                id: `workflow-${workflow.id}`,
                label: workflow.inboxCount
                  ? `${workflow.name} (${workflow.inboxCount})`
                  : workflow.name,
                children: [
                  {
                    id: `inbox-${workflow.id}`,
                    label: `Inbox (${workflow.inboxCount})`,
                    icon: "eva-email-outline",
                    fillIcon: "eva-email",
                  },
                  {
                    id: `sent-${workflow.id}`,
                    label: `Sent (${workflow.processCount})`,
                    icon: "eva-person-done-outline",
                    fillIcon: "eva-person-done",
                  },
                  // {
                  //   id: `starred-${workflow.id}`,
                  //   label: `Starred (0)`,
                  //   icon: "eva-star-outline",
                  //   fillIcon: "eva-star",
                  // },
                  completedNode,
                ],
              });
            }
          });
          let myInbox = {
            id: "My Inbox",
            label: this.myInboxCount
              ? `My Inbox (${this.myInboxCount})`
              : `My Inbox`,
            children: [
              {
                id: "inbox",
                label: `Inbox (${this.myInboxCount})`,
                icon: "eva-email-outline",
                fillIcon: "eva-email",
              },
              // {
              //   id: "starred",
              //   label: `Starred (${myStarredCount})`,
              //   icon: "eva-star-outline",
              //   fillIcon: "eva-star",
              // },
            ],
          };
          if (!this.isTenantArmgroup()) {
            this.treeNodes.push(myInbox);
          }
          this.treeNodes.push(sharedInbox);
        } else if (this.workflowList.length) {
          let treeIndex = this.treeNodes.findIndex(
            (tree) => tree.label === "Shared Inbox"
          );
          this.workflowList.forEach((workflow) => {
            let workflowTreeIndex = this.treeNodes[
              treeIndex
            ].children.findIndex(
              (workflowTree) => workflowTree.id === "workflow-" + workflow.id
            );
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net" ||
              (origin === "https://trial.ezofis.com" &&
                this.$store.state.session.tenantId === 12) ||
              origin === "http://localhost:8080"
            ) {
              this.treeNodes[treeIndex].children[
                workflowTreeIndex
              ].children[0].label = `All Inbox (${workflow.runningCount})`;
              this.treeNodes[treeIndex].children[
                workflowTreeIndex
              ].children[1].label = `Inbox (${workflow.inboxCount})`;
              this.treeNodes[treeIndex].children[
                workflowTreeIndex
              ].children[2].label = `Sent (${workflow.processCount})`;
              this.treeNodes[treeIndex].children[
                workflowTreeIndex
              ].children[3].label = `Payment (${workflow.paymentProcessCount})`;
              // this.treeNodes[treeIndex].children[
              //   workflowTreeIndex
              // ].children[2].label = `Starred (0)`;
              this.treeNodes[treeIndex].children[
                workflowTreeIndex
              ].children[4].label = `Completed (${workflow.completedCount})`;
            } else {
              this.treeNodes[treeIndex].children[
                workflowTreeIndex
              ].children[0].label = `Inbox (${workflow.inboxCount})`;
              this.treeNodes[treeIndex].children[
                workflowTreeIndex
              ].children[1].label = `Sent (${workflow.processCount})`;
              // this.treeNodes[treeIndex].children[
              //   workflowTreeIndex
              // ].children[2].label = `Starred (0)`;
              this.treeNodes[treeIndex].children[
                workflowTreeIndex
              ].children[2].label = `Completed (${workflow.completedCount})`;
            }
          });
        }
      },
    },
    selectedNode: {
      immediate: true,
      handler() {
        if (this.selectedNode && !this.initial) {
          this.initial = false;
          const type = this.selectedNode.split("-")[0];
          if (type === "My Inbox") {
            if (this.expanded.length === 2) {
              this.expanded.pop();
              this.expanded.pop();
            }
            this.$refs.tree.setExpanded(this.selectedNode, true);
          } else if (type === "Shared Inbox") {
            this.expanded.pop();
            this.$refs.tree.setExpanded(this.selectedNode, true);
          } else if (type === "workflow") {
            if (this.expanded.indexOf("My Inbox") > -1) {
              if (this.expanded.length > 2) {
                this.expanded.pop();
              }
            } else if (this.expanded.length > 1) {
              this.expanded.pop();
            }
            this.$refs.tree.setExpanded(this.selectedNode, true);
            this.selectedNode = "inbox-" + this.selectedNode.split("-")[1];
          } else {
            if (this.loadInitial) {
              this.$emit("update:loadInitial", false);
              this.$emit("selectedNode", this.selectedNode, false);
            } else {
              this.$emit("selectedNode", this.selectedNode, true);
            }
          }
        }
        if (this.initial) {
          this.initial = false;
        }
      },
    },
  },

  methods: {
    isTenantArmgroup() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12) ||
        origin === "http://localhost:80801"
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
#divWorkflowTree {
  &.tree {
    border-radius: 6px;
    background-color: var(--component-bg-color);
    border: 6px solid var(--component-bg-color);
  }

  .q-tree__icon,
  .q-tree__node-header-content .q-icon {
    font-size: 18px !important;
  }

  .q-tree__node-header-content {
    color: var(--title-3-color);
    transition: color 0.3s;
    // text-overflow: ellipsis;
    white-space: nowrap;
    // overflow: hidden;
  }

  .q-tree__node-header-content:hover {
    color: #000;
    transition: color 0.3s;
    // text-overflow: ellipsis;
    white-space: nowrap;
    // overflow: hidden;
  }

  .q-tree__node-header:hover {
    background: $primary-2;
  }

  .q-tree__node--selected {
    background: $primary;
  }

  .q-tree__children {
    padding-left: 0px !important;
  }

  .q-tree__children .q-tree__node--parent {
    padding-left: 22px !important;
  }
}
</style>
