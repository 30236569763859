import { render, staticRenderFns } from "./Alignment.vue?vue&type=template&id=4d937f62&scoped=true&"
import script from "./Alignment.vue?vue&type=script&lang=js&"
export * from "./Alignment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d937f62",
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../../../../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu});
