<template>
  <AppLayout>
    <ViewWrapper
      title="folders"
      :breadcrumbs="breadcrumbs"
      :tabs="tabs"
      :tab="tab"
    >
      <div id="repository-ocr-reports">
        <Portal to="action-bar">
          <keep-alive>
            <ItemActions
              :id="repositoryId"
              item="repositories-ocr-report"
              :columns="[]"
              group-by=""
              order=""
              :filter-by="[]"
              criteria=""
              active-view=""
              :hide-actions="[
                'toggle',
                'sort',
                'group',
                'filter',
                'activeView',
              ]"
              :simple-search-filter="true"
              :refresh="false"
              :export="false"
              :export-data="exportDataList"
              @refresh="refreshData"
              @search="searchRow"
              @export="export_Data"
              @filter="getData"
            />
          </keep-alive>
        </Portal>

        <SimpleFilter
          :id.sync="repositoryId"
          :workspace-id.sync="workspaceId"
          :columns="_columns"
          :filter-by.sync="filterBy"
          :workspace="true"
          name="Select Folder"
          :workspace-list="workspaceList"
          :list="repositoriesList"
          module="repository"
          :module-id="repositoryId"
          :active-view="activeView"
          :fid.sync="filterId"
          :filter-list="WfilterList"
          :w-id="workspaceId"
          :no-filter="true"
          @update="getOcrReportData"
          @saveData="issavesheet = true"
          @viewFilter="showFilterList = true"
        />

        <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />

        <SaveSheet
          v-model="issavesheet"
          :filter="filterBy"
          :repository-id="repositoryId"
          :page="'repositoryOcrReport'"
          @success="getFilterList"
        />

        <div class="content">
          <FilterListType
            v-if="showFilterList && filterId === '-1'"
            :mode="mode"
            :items="savedItems"
            :columns="columnss"
            :filter-list="savedList"
            @view="openFile"
            @isDelete="deleteItem"
          />
          <template v-else-if="repositoryOcrReportList.length">
            <OcrReportList
              :mode="mode"
              :items="items"
              :columns="columns"
              @view="openFile"
            />
          </template>

          <template v-else-if="!loadingBarContent">
            <StateWrapper
              icon="eva-archive-outline"
              title="Generate Report"
              description="Select workspace and repository to display data"
              style="margin-top: 20px"
            />
          </template>
        </div>

        <Pagination
          v-if="repositoryOcrReportList.length || filterId === '-1'"
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
          :file-sheet="hideFileSheet"
          @loadAll="loadAll"
        />

        <FileSheet
          v-model="isFileSheetVisible"
          :file="selectedFile"
          :repository-field="[]"
          :options="options"
        />
      </div>
      <ConfirmDelete
        v-model="isDeleteConfirmationVisible"
        :delete-data="deleteData"
        @delete="getFilterList"
      />
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import { Portal } from "portal-vue";
import {
  repository,
  user,
  report,
  workspace,
  dashboard,
} from "@/api/factory.js";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import OcrReportList from "./components/OcrReportList.vue";
import DataType from "@/constants/data-type.js";
import SimpleFilter from "@/components/common/display/item-actions/components/SimpleFilter.vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import { exportData } from "@/helpers/export-excel-data";
import FileSheet from "../repository-browse-and-trash/components/FileSheet.vue";
import formatBytes from "@/helpers/format-bytes.js";
import SaveSheet from "@/components/common/display/item-actions/components/SaveSheet.vue";
import FilterListType from "@/components/common/display/item-actions/components/FilterListType.vue";
import ConfirmDelete from "@/components/common/display/item-actions/components/DeleteSaveItems.vue";

export default {
  name: "RepositoriesOcrReports",

  components: {
    Portal,
    ItemActions,
    OcrReportList,
    SimpleFilter,
    StateWrapper,
    Pagination,
    FileSheet,
    AppLayout,
    ViewWrapper,
    SaveSheet,
    FilterListType,
    ConfirmDelete,
  },

  data() {
    return {
      mode: "REPORT",
      activeView: "TABLE",
      sortBy: {
        criteria: "",
        order: "ASC",
      },
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "folders",
          route: "repositories-browse",
        },
        {
          id: this.$nano.id(),
          label: "reports",
          route: "repositories-reports-overview",
        },
        {
          id: this.$nano.id(),
          label: "",
          route: "",
        },
      ],
      tabs: [
        // {
        //   id: this.$nano.id(),
        //   label: "overview",
        //   icon: "mdi-view-dashboard-outline",
        //   route: "repositories-overview",
        //   access: false,
        //   accessLabel: "Overview",
        // },
        {
          id: this.$nano.id(),
          label: "browse",
          icon: "eva-folder-outline",
          route: "repositories-browse",
          access: true,
          accessLabel: "Manage Folders",
        },
        {
          id: this.$nano.id(),
          label: "trash",
          icon: "eva-trash-2-outline",
          route: "repositories-trash",
          access: true,
          accessLabel: "Manage Folders",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "deleted files",
        //   icon: "eva-trash-2-outline",
        //   route: "file-trash",
        //   access: false,
        //   accessLabel: "Manage Folders",
        // },
        {
          id: this.$nano.id(),
          label: "reports",
          icon: "eva-trash-2-outline",
          route: "repositories-ocr-report",
          access: false,
          accessLabel: "Audit Report",
        },
      ],
      tab: "repositories-ocr-report",
      columns: [
        {
          id: this.$nano.id(),
          name: "fileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileStatus",
          label: "Status",
          isVisible: true,
          options: this.fileStatus,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "ocrType",
          label: "Type",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "processedBy",
          label: "Processed By",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          options: this.userList,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "processedOn",
          label: "Processed On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "totalPage",
          label: "Total Pages",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "processedPages",
          label: "Processed Pages",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileSize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ],
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      hideFileSheet: false,
      userList: [],
      workspaceId: 0,
      workspaceList: [],
      itemId: 0,
      repositoryId: 0,
      repositoriesList: [],
      OcrReportList: [],
      repositoryOcrReportList: [],
      repositoryOcrReportListData: [],
      options: {},
      selectedFile: {},
      isFileSheetVisible: false,
      loadingBarContent: false,
      fileStatus: [
        {
          id: this.$nano.id(),
          label: "Archived",
          value: "Archived",
        },
        {
          id: this.$nano.id(),
          label: "Deleted",
          value: "Deleted",
        },
      ],
      type: [
        {
          id: this.$nano.id(),
          label: "Advanced",
          value: "Advanced",
        },
        {
          id: this.$nano.id(),
          label: "Standard",
          value: "Standard",
        },
      ],
      exportDataList: false,
      filterId: "",
      WfilterList: [],
      issavesheet: false,
      showFilterList: false,
      savedList: [],
      columnss: [],
      savedListData: [],
      isDeleteConfirmationVisible: false,
      deleteData: {},
    };
  },

  computed: {
    _columns() {
      let columns = [
        {
          id: this.$nano.id(),
          name: "fileName",
          label: "FileName",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "ocrType",
          label: "Type",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          options: this.type,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "processedOn",
          label: "ProcessedOn",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "processedBy",
          label: "Processed By",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "fileStatus",
          label: "Status",
          options: this.fileStatus,
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
      ];
      columns[2].options = this.userList;

      return columns;
    },

    _tabs() {
      if (this.$store.state.profileMenus.length) {
        this.tabs.forEach((tab) => {
          if (!tab.access) {
            tab.access = this.$store.state.profileMenus.find(
              (row) => row.folders
            )?.Menu[tab.accessLabel];
          }
        });
      }
      return this.tabs.filter((tab) => tab.access);
    },

    items() {
      return this.repositoryOcrReportList.map(({ key, value }) => ({
        key: "",
        value: key,
        data: value.map((item) => ({
          icon: "mdi-text-box",
          itemId: item.itemId,
          link: true,
          fileName: item.fileName,
          fileSize: item.fileSize ? formatBytes(item.fileSize) : "",
          totalPage: item.totalPage,
          fileStatus: item.fileStatus,
          processedPages: item.processedPages,
          ocrType: item.ocrType,
          processedOn: this.$day.format(item.processedOn),
          processedBy: item.processedBy,
        })),
      }));
    },

    savedItems() {
      return this.savedListData.map(({ key, value }) => ({
        key: "",
        value: key,
        data: value.map((item) => ({
          icon: "mdi-text-box",
          id: item.id,
          itemId: item.itemId,
          link: true,
          name: item.name,
          description: item.description,
          createdBy: item.createdBy,
          createdon: this.$day.format(item.createdAt),
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.tab = this.$route.name;
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },

    workspaceId: {
      deep: true,
      immediate: true,
      handler() {
        this.clearData();
        if (this.workspaceId) {
          this.getRepositories();
        }
        if (this.workspaceId && this.repositoryId) {
          this.getOcrReportData();
        }
      },
    },

    repositoryId: {
      deep: true,
      immediate: true,
      async handler() {
        this.repositoryOcrReportList = [];
        if (this.repositoryId) {
          this.getOcrReportData();
        }
      },
    },

    itemsPerPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workspaceId && this.repositoryId && this.itemsPerPage) {
          this.getOcrReportData();
        } else if (this.savedListData && this.itemsPerPage) {
          this.getFilterData();
        }
      },
    },

    currentPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workspaceId && this.repositoryId && this.currentPage) {
          this.getOcrReportData();
        } else if (this.savedListData && this.itemsPerPage) {
          this.getFilterData();
        }
      },
    },

    filterId: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.filterId === "") {
          this.repositoryReportList = [];
          this.workspaceId = 0;
          this.repositoryId = 0;
          this.filterBy = [];
        } else if (this.filterId === "-1") {
          this.repositoryOcrReportList = [];
          this.showFilterList = true;
          this.getFilterData();
        } else if (this.filterId) {
          this.columnss = [];
          this.showFilterList = false;
          this.filterList();
          this.selectedIds = this.filterId;
        } else if (!this.filterId) {
          this.showFilterList = false;
          this.repositoryReportList = [];
        }
      },
    },
  },
  created() {
    this.getWorkspaces();
    this.getUsers();
    this.getFilterList();
  },

  methods: {
    refreshData() {
      if (this.workspaceId && this.repositoryId) {
        this.getOcrReportData();
      } else if (this.workspaceId) {
        this.getRepositories();
      }
    },

    searchRow(search) {
      if (search) {
        this.repositoryOcrReportList = this.repositoryOcrReportListData.map(
          ({ key, value }) => ({
            key: key,
            value: value.filter((row) => {
              for (let cell in row) {
                let index = this._columns.findIndex((column) => {
                  return column.name === cell;
                });
                console.log(cell);
                console.log(index);
                if (index >= 0 && row[cell]) {
                  if (
                    String(row[cell])
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return row;
                  }
                }
              }
            }),
          })
        );
      } else {
        this.repositoryOcrReportList = this.repositoryOcrReportListData;
      }
    },

    async export_Data() {
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await report.getOcrReportData(
        this.repositoryId,
        {
          filterBy: this.filterBy,
          itemsPerPage: 500,
          currentPage: 1,
        }
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      let tableData = [];
      payload.data.forEach((row) => {
        row.value.forEach((item) => {
          tableData.push({
            "File Name": item.fileName,
            Status: item.fileStatus,
            "Processed By": item.processedBy,
            "Processed On": this.$day.format(item.processedOn),
            Size: formatBytes(item.fileSize),
            "Processed Pages": item.processedPages,
            "Total Pages": item.totalPage,
          });
        });
      });

      if (tableData.length) {
        let selectedName = this.repositoriesList.find(
          (repository) => repository.value === this.repositoryId
        );
        exportData(tableData, selectedName.label + "-OCR Report");
      }
    },

    openFile(rowId) {
      let selectedList = this.repositoryOcrReportList[0].value.find(
        (file) => file.rNo === rowId
      );
      this.selectedFile = {
        id: selectedList.itemid,
        name: selectedList.fileName,
        size: selectedList.fileSize,
        repositoryId: this.repositoryId,
        createdAt: selectedList.createdAt,
        createdBy: "",
      };
      this.isFileSheetVisible = true;
    },

    loadAll() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    /* api Functions */

    async getWorkspaces() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await workspace.getWorkspaceList();

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.workspaceList = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.id,
      }));
    },

    async getRepositories() {
      if (this.workspaceId) {
        this.repositoriesList = [];
        // this.$store.commit("showLoadingBarPage");
        this.loadingBarContent = true;

        const { error, payload } = await repository.getRepositoryList(
          "workspaceId",
          this.workspaceId
        );

        this.loadingBarContent = false;
        // this.$store.commit("hideLoadingBarPage");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (!payload.length) {
          return;
        }
        this.repositoriesList = payload.map((repository) => ({
          id: this.$nano.id(),
          label: repository.value,
          value: repository.id,
        }));
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value || user.loginName,
            value: user.value || user.loginName,
          });
        });
      }
    },

    async getOcrReportData() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBarPage");
      this.repositoryOcrReportList = [];
      this.repositoryOcrReportListData = [];
      this.list = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = JSON.stringify([row.arrayValue]);
            }
          });
        }
      });
      const { error, payload } = await report.getOcrReportData(
        this.repositoryId,
        {
          filterBy: this.filterBy,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        }
      );
      // this.$store.commit("hideLoadingBarPage");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.totalItems = payload.meta?.totalItems || 0;

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.repositoryOcrReportList = [];
        this.repositoryOcrReportListData = [];
      } else {
        this.repositoryOcrReportList = payload.data;
        this.repositoryOcrReportListData = payload.data;
        if (this.repositoryOcrReportList.length) {
          this.exportDataList = true;
        }
      }
    },

    async getData(fromDate, toDate) {
      const filterBy = [
        {
          id: this.$nano.id(),
          filters: [
            {
              id: this.$nano.id(),
              criteria: "processedOn",
              condition: "IS_EQUALS_TO",
              value: fromDate,
              valueTo: toDate,
              dataType: "DataType.DATE",
            },
          ],
          groupCondition: "",
        },
      ];

      this.$store.commit("showLoadingBarPage");
      this.repositoryOcrReportList = [];
      this.repositoryOcrReportListData = [];
      this.list = [];
      const { error, payload } = await report.getOcrReportData(
        this.repositoryId,
        {
          filterBy: filterBy,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        }
      );
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.data.length) {
        payload.data.forEach((row) => {
          row.value.forEach((process) => {
            this.OcrReportList.push({
              ...process,
            });
          });
        });
      }

      this.totalItems = payload.meta?.totalItems || 0;

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.repositoryOcrReportList = [];
        this.repositoryOcrReportListData = [];
      } else {
        this.repositoryOcrReportList = payload.data;
        this.repositoryOcrReportListData = payload.data;
      }
    },

    clearData() {
      this.exportDataList = false;
      this.repositoryId = 0;
      this.repositoryOcrReportList = [];
    },

    async getFilterData() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBarPage");
      this.savedList = [];
      this.savedListData = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = row.arrayValue;
            }
          });
        }
      });
      // const { error, payload } = await report.getRepositoryData(
      //   this.repositoryId,
      //   {
      //     itemId: this.itemId,
      //     filterBy: this.filterBy,
      //     itemsPerPage: this.itemsPerPage,
      //     currentPage: this.currentPage,
      //   }
      // );
      // this.$store.commit("hideLoadingBarPage");
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "repositoryOcrReport",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.columnss = [
        {
          id: this.$nano.id(),
          name: "id",
          label: "Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "itemId",
          label: "Item Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdon",
          label: "Created On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
      ];

      this.loadingBarContent = false;
      if (payload.data.length) {
        payload.data.forEach((row) => {
          row.value.forEach((process) => {
            this.OcrReportList.push({
              ...process,
            });
          });
        });
      }

      this.totalItems = payload.meta?.totalItems || 0;
      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.savedList = [];
        this.savedListData = [];
      } else {
        this.savedList = payload.data;
        this.savedListData = payload.data;
        if (this.savedList.length) {
          this.exportDataList = true;
        }
      }
      // console.log(this.savedListData, "savedListData");
      console.log(payload, "savedListData");
    },

    async getFilterList() {
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "repositoryOcrReport",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
      });
      // this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload, "dasta");
      let data = payload.data[0].value;
      this.listedData = data;
      console.log(this.listedData, "data");

      let List = data.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        value: filter.name,
      }));

      const removeObserver = (obj) => {
        const newObj = {};
        Object.keys(obj).forEach((key) => {
          if (key !== "__ob__") {
            newObj[key] = obj[key];
          }
        });
        return newObj;
      };
      const arrayOfObjects = List.map((obj) => removeObserver(obj));
      const viewAll = { id: this.$nano.id(), label: "Show All", value: "-1" };

      this.WfilterList = [
        ...(arrayOfObjects.length ? [viewAll] : []),
        ...arrayOfObjects,
      ];

      console.log(this.WfilterList, "this.WfilterList");
    },

    async filterList() {
      console.log(this.listedData, "ListdData");
      this.listedData.map((item) => {
        if (item.name === this.filterId) {
          // let search = item.filterJson;
          // const filterItem = search.slice(1, -1);
          const parsedArray = JSON.parse(item.filterJson);
          let filters = [];
          if (parsedArray[0].filters) {
            filters = JSON.parse(parsedArray[0].filters);
          }
          console.log(parsedArray, "parsedArray");
          this.filterListData = {
            id: item.itemId,
            name: item.name,

            rId: parsedArray[0].uId,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });
      console.log(this.filterListData.filters, "this.filterListData.filters;");
      this.repositoryId = this.filterListData.rId;
      this.filterFrom = this.filterListData.filterFrom;
      await this.getRepository();
      this.filterFrom = this.filterListData.filterFrom;
      this.repositoryId = this.filterListData.rId;
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      this.getRepositoryData();
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.repositoryDetails = payload;
      this.workspaceId = this.repositoryDetails.workspace.id;
      if (payload.options) {
        this.options = payload.options;
      }

      this.repositoryField = this.repositoryDetails.fields;
      this.columns = [
        {
          id: this.$nano.id(),
          name: "fileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileStatus",
          label: "Status",
          isVisible: true,
          options: this.fileStatus,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "ocrType",
          label: "Type",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "processedBy",
          label: "Processed By",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          options: this.userList,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "processedOn",
          label: "Processed On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "totalPage",
          label: "Total Pages",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "processedPages",
          label: "Processed Pages",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileSize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ];
    },
    deleteItem(items) {
      this.savedListData[0].value.map((item) => {
        if (item.id === items) {
          console.log(item, "itemd");
          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },
    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#repository-ocr-reports {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 300px);
  }

  .backGround {
    border: 2px;
    border-radius: 10px;
    background-color: #87dcf626;
    margin-bottom: 8px;
  }
}
</style>

<style lang="scss">
#select-field.fieldList {
  .select {
    min-height: 0px !important;
    color: $secondary !important;
    width: 140px !important;
    overflow: hidden !important;
  }
}

#text-field.textField {
  input {
    min-height: 35px;
    width: 140px;
  }
}

.loading-content {
  background-color: var(--body-bg-color) !important;
  position: absolute;
  z-index: 9999;
}
</style>
