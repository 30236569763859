var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"validation-settings"}},[(_vm.showFieldRule)?_c('SingleChoiceField',{staticClass:"q-pa-md",attrs:{"value":_vm.fieldRule,"label":"field rule","options":_vm.fieldRules,"options-per-line":1},on:{"input":_vm.updateFieldRule}}):_vm._e(),(_vm.showContentRule)?_c('SingleChoiceField',{staticClass:"q-pa-md",attrs:{"value":_vm.contentRule,"label":"content rule","options":_vm._contentRules,"options-per-line":1},on:{"input":_vm.updateContentRule}}):_vm._e(),(_vm.showRange)?_c('div',{staticClass:"q-pa-md"},[_c('FormFieldLabel',{staticClass:"field-label",attrs:{"label":['NUMBER', 'COUNTER'].includes(_vm.fieldType)
          ? 'range'
          : 'character limit'}}),_c('div',{staticClass:"row q-col-gutter-sm"},[_c('ValidationProvider',{staticClass:"col",attrs:{"name":"minimum","rules":{
          lessThanMax: _vm.maximum ? { target: '@maximum' } : false,
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('NumberField',{attrs:{"value":_vm.minimum,"placeholder":"Minimum","error":errors[0]},on:{"input":_vm.updateMinimum}})]}}],null,false,3284407544)}),_c('NumberField',{attrs:{"value":_vm.maximum,"placeholder":"Maximum"},on:{"input":_vm.updateMaximum}})],1)],1):_vm._e(),(_vm.fieldType === 'DATE')?_c('div',{staticClass:"q-pa-md"},[_c('FormFieldLabel',{staticClass:"field-label",attrs:{"label":"range"}}),_c('div',{staticClass:"row q-col-gutter-sm"},[_c('ValidationProvider',{staticClass:"col",attrs:{"name":"minimum","rules":{
          lessThanMaxDate: _vm.maximum ? { target: '@maximum' } : false,
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateField',{attrs:{"value":_vm.minimum,"placeholder":"Minimum","error":errors[0]},on:{"input":_vm.updateMinimum}})]}}],null,false,2281554287)}),_c('ValidationProvider',{staticClass:"col",attrs:{"name":"maximum"}},[_c('DateField',{attrs:{"value":_vm.maximum,"placeholder":"Maximum"},on:{"input":_vm.updateMaximum}})],1)],1)],1):_vm._e(),(_vm.fieldType === 'TIME')?_c('div',{staticClass:"q-pa-md"},[_c('FormFieldLabel',{staticClass:"field-label",attrs:{"label":"range"}}),_c('div',{staticClass:"row q-col-gutter-sm"},[_c('ValidationProvider',{staticClass:"col",attrs:{"name":"minimum","rules":{
          lessThanMaxTime: _vm.maximum ? { target: '@maximum' } : false,
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TimeField',{attrs:{"value":_vm.minimum,"placeholder":"Minimum","error":errors[0]},on:{"input":_vm.updateMinimum}})]}}],null,false,4267608654)}),_c('ValidationProvider',{staticClass:"col",attrs:{"name":"maximum"}},[_c('TimeField',{attrs:{"value":_vm.maximum,"placeholder":"Maximum"},on:{"input":_vm.updateMaximum}})],1)],1)],1):_vm._e(),(_vm.fieldType === 'DATE_TIME')?_c('div',{staticClass:"q-pa-md"},[_c('FormFieldLabel',{staticClass:"field-label",attrs:{"label":"range"}}),_c('ValidationProvider',{staticClass:"col",attrs:{"name":"minimum","rules":{
        lessThanMaxDate: _vm.maximum ? { target: '@maximum' } : false,
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateTimeField',{staticClass:"q-mb-sm",attrs:{"value":_vm.minimum,"error":errors[0]},on:{"input":_vm.updateMinimum}})]}}],null,false,2286772217)}),_c('ValidationProvider',{staticClass:"col",attrs:{"name":"maximum"}},[_c('DateTimeField',{attrs:{"value":_vm.maximum},on:{"input":_vm.updateMaximum}})],1)],1):_vm._e(),(_vm.fieldType === 'FILE_UPLOAD')?_c('MultiSelectField',{staticClass:"q-pa-md",attrs:{"value":_vm.allowedFileTypes,"label":"allowed file types","tooltip":"By default, all the file types are allowed","options":_vm.fileTypes},on:{"input":_vm.updateAllowedFileTypes}}):_vm._e(),(_vm.showMaxFileSize)?_c('ValidationProvider',{attrs:{"name":"max file size","rules":{ required: true, min_value: 1, max_value: 25 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('NumberField',{staticClass:"q-pa-md",attrs:{"value":_vm.maxFileSize,"is-mandatory":"","label":"max file size (MB)","is-clearable":false,"error":errors[0]},on:{"input":_vm.updateMaxFileSize}})]}}],null,false,3626288226)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }