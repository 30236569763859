<template>
  <ValidationProvider v-slot="{ errors }" :rules="validationRules">
    <template v-if="!_isReadonly">
      <FileUploadField
        :value="value"
        :is-readonly="_isReadonly || uploadDisable"
        :is-disabled="isDisabled"
        :is-multiple="allowMultipleFiles"
        :is-clearable="false"
        :error="errors[0]"
        :field="field"
        :settings-for="settingsFor"
        :is-file-upload-new="isFileUploadNew"
        @input="handleInput"
        @showQr="$emit('showQr', field.id)"
      />

      <FormFieldError
        v-if="isFileValidate"
        :error="isFileValidate"
        class="upload-error q-mt-sm"
      />
    </template>

    <div v-if="_value.length" id="file-upload-list" class="wrapper q-my-sm">
      <div v-if="!isFileUploadNew" class="title-3 q-mb-sm">Attachments</div>
      <div class="files">
        <BaseScrollbar style="max-height: 130px">
          <div v-for="(file, index) in value" :key="index">
            <template v-if="file.repositoryId && file.fileName">
              <div class="row items-center">
                <!-- <div class="col-auto q-mr-sm">{{ index + 1 }}.</div> -->
                <div class="col-auto q-mr-sm" style="width: 23px">
                  <FileIcon :mime-type="fileType(file.fileName)" />
                </div>
                <div
                  class="col file-name cursor-pointer text-underline text-secondary q-mr-sm"
                  @click="viewFile(file)"
                >
                  <!-- @click="openFile(file)" -->
                  {{ file.fileName }}
                </div>
                <div v-if="!isReadonly" class="col-auto">
                  <BaseActionButton
                    v-tooltip="'delete'"
                    is-flat
                    icon="eva-trash-outline"
                    color="red"
                    no-border
                    @click="deleteFile(index, file)"
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <div v-if="!file.error && file.name" class="row items-center">
                <!-- <div class="col-auto q-mr-sm">{{ index + 1 }}.</div> -->
                <div class="col-auto q-mr-sm" style="width: 23px">
                  <FileIcon :mime-type="fileType(file.name)" />
                </div>
                <div
                  class="col file-name cursor-pointer text-underline text-secondary q-mr-sm"
                  @click="viewFile(file, 'new')"
                >
                  {{ file.name }}
                </div>

                <div
                  v-if="Number(file.uploadedPercentage) === 100"
                  class="col-auto"
                >
                  <BaseActionButton
                    v-tooltip="'delete'"
                    is-flat
                    icon="eva-trash-outline"
                    color="red"
                    no-border
                    @click="deleteFile(index, file, 'file')"
                  />
                </div>
                <div class="col-auto">
                  <q-chip
                    v-if="file.prediction"
                    outline
                    color="pink"
                    size="sm"
                    class="q-mr-sm"
                  >
                    <q-avatar
                      icon="mdi-auto-fix"
                      class="q-mr-sm"
                      color="pink"
                      text-color="white"
                    />
                    {{ file.prediction }}
                  </q-chip>
                </div>
                <div v-if="!file.error1" class="col-auto q-mr-md">
                  <div class="row items-center justify-end">
                    <template v-if="Number(file.uploadedPercentage) === 100">
                      <q-chip
                        size="13px"
                        outline
                        color="positive"
                        class="q-mr-sm"
                        >Uploaded</q-chip
                      >
                      <!-- <BaseIcon
                        v-if="Number(file.uploadedPercentage) === 100"
                        name="eva-checkmark-circle-2-outline"
                        color="light-green"
                        size="30px"
                      /> -->
                    </template>
                    <template v-else>
                      <!-- {{ file.uploadedPercentage }} -->
                      <q-chip
                        size="13px"
                        outline
                        color="warning"
                        class="q-mr-sm"
                        >{{
                          uploadingStatus(Number(file.uploadedPercentage))
                        }}</q-chip
                      >

                      <!-- <div
                        v-if="Number(file.uploadedPercentage) < 50"
                        class="q-mr-sm text-secondary"
                      >
                        Uploading...
                      </div>
                      <div
                        v-if="Number(file.uploadedPercentage) > 50"
                        class="q-mr-sm text-secondary"
                      >
                        Extracting...
                      </div> -->
                      <!-- <q-circular-progress
                        show-value
                        font-size="13px"
                        size="45px"
                        color="light-green"
                        :track-color="trackColor"
                        :thickness="0.22"
                        :value="file.uploadedPercentage"
                        :indeterminate="!file.uploadedPercentage"
                        class="font-semibold"
                      >
                        {{ file.uploadedPercentage }}%
                      </q-circular-progress> -->
                      <!-- <q-spinner-ios color="secondary" size="2em" /> -->
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </BaseScrollbar>
      </div>
    </div>

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <template v-if="selectedFile">
          <div class="attachmentFileHeader">
            <div class="avatar q-ml-sm">
              <FileIcon
                :mime-type="
                  fileType(selectedFile.fileName || selectedFile.name)
                "
              />
            </div>

            <div class="q-ml-md">
              <div class="filename">
                {{ selectedFile.fileName || selectedFile.name }}
              </div>

              <div class="meta">
                <div>Archived At: {{ selectedFile.createdAt }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>Archived By: {{ selectedFile.createdBy }}</div>
                <div v-if="selectedFile.size">
                  <BaseIcon name="mdi-circle-small" />
                  File Size: {{ fileSize(selectedFile.size) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-if="selectedFile">
        <div class="attachmentFileFrame">
          <div class="file-list">
            <iframe
              id="file-frame"
              ref="myFiles"
              :src="selectedFile.name ? fileLink() : archivedFileLink()"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </template>
    </Sheet>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FileUploadField from "@/components/common/form/file-upload-field/FileUploadField.vue";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
} from "@/helpers/file-format.js";
import Sheet from "@/components/common/popup/Sheet.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import { isEmpty } from "lodash-es";

export default {
  name: "FileUploadFieldWrapper",

  components: {
    ValidationProvider,
    FileUploadField,
    Sheet,
    FileIcon,
    FormFieldError,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    settingsFor: {
      type: String,
      default: "",
    },

    isFileUploadNew: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      assignControls: [],
      fileSheet: false,
      selectedFile: {},
      deletedFiles: [],
      aiSettings: {},
      isFileValidate: "",
      prediction: "",
    };
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this._isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      if (this.allowedFileTypes.length) {
        rules.fileTypes = this.allowedFileTypes;
      }

      if (this.maxFileSize) {
        // rules.maxFileSize = this.maxFileSize;
      }

      return rules;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    _isMandatory() {
      if (this.isMandatory) {
        return true;
      } else {
        return this.field.settings.validation.fieldRule === "REQUIRED";
      }
    },

    allowedFileTypes() {
      return this.field.settings.validation.allowedFileTypes;
    },

    maxFileSize() {
      return this.field.settings.validation.maxFileSize;
    },

    allowMultipleFiles() {
      return this.field.settings.specific.allowMultipleFiles;
    },

    _value() {
      // console.log(this.value, "file");
      if (this.value && this.value.length) {
        return this.value.filter((file) => !file.error);
      }
      return [];
    },

    fileExpired() {
      if (this.value && this.value.length) {
        return this.value.filter((file) => file.error);
      }
      return [];
    },

    uploadDisable() {
      if (this.isFileUploadNew) {
        return false;
      } else {
        if (this.value && this.value.length) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        if (this.value.length) {
          let isFileValidate = true;
          this.value.forEach((field) => {
            if (field.fileDataValidate && isFileValidate) {
              let issues = JSON.parse(field.fileDataValidate);
              if (!isEmpty(issues)) {
                let status = issues.prediction.replace(/[^a-zA-Z ]/g, "");
                if (status) {
                  if (
                    status.toLowerCase() === "valid" ||
                    status.toLowerCase() === "not expired" ||
                    status.toLowerCase() === "not_expired"
                  ) {
                    this.prediction = `${issues.prediction}`;
                  } else {
                    this.prediction = `${issues.prediction}`;
                    this.isFileValidate = `The uploaded document has expired (${issues.name}: ${issues.value})`;
                    isFileValidate = false;
                  }
                }
              }
            }
          });
        } else {
          this.isFileValidate = "";
          this.prediction = "";
        }
      },
    },
  },

  created() {
    this.assignControls = this.field.settings.validation.assignOtherControls;
    if (this.field.settings.aiSettings) {
      this.aiSettings = this.field.settings.aiSettings;
    }
  },

  methods: {
    handleInput(value, files, error) {
      // this.$emit("input", value);

      if (!value.length) {
        this.value.forEach((item, index) => {
          if (item.name) {
            this.value.splice(index, 1);
            this.deleteFile(index, item, "hasFile");
          }
        });
        if (!this.value.length) {
          this.$emit("input", value);
        }
      } else {
        // console.log(files, "files");
        let _files = Array.from(files);
        var fileSizeError = false;

        if (_files.length) {
          _files.forEach((file, index) => {
            const fileSize = Math.floor(file.size / 1024 / 1024);
            const fileType = file.name.split(".").reverse()[0];

            if (fileSize > this.maxFileSize) {
              files[index].error = "error";
              fileSizeError = true;
            }
            if (this.allowedFileTypes.length) {
              if (!this.allowedFileTypes.includes(fileType)) {
                files[index].error = "error";
              }
            }
          });
        }
        // console.log(value, "value");
        if (value.length) {
          value.forEach((file, index) => {
            if (file.name) {
              const fileSize = Math.floor(file.size / 1024 / 1024);
              const fileType = file.name.split(".").reverse()[0];

              if (fileSize > this.maxFileSize) {
                value[index].error = "error";
                this.value.splice(index, 1);
                // this.deleteFile(index, file, "hasFile");

                fileSizeError = true;
              }
              if (this.allowedFileTypes.length) {
                if (!this.allowedFileTypes.includes(fileType)) {
                  value[index].error = "error";
                }
              }
            }
          });
        }
        console.log(fileSizeError, "fileSizeError");
        if (!fileSizeError) {
          this.$emit("input", value);
        } else {
          this.$alert.warning(
            `File size should not be more than ${this.maxFileSize} mb`
          );
        }
      }
      if (files && !error && !fileSizeError) {
        let emitValue = {
          files: files,
          assignControls: this.assignControls,
          fieldId: this.field.id,
          aiSettings: this.aiSettings,
        };
        this.$emit("uploadFiles", emitValue);
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        if (this.selectedFile.name) {
          window.open(this.fileLink());
        } else {
          window.open(this.archivedFileLink());
        }
      } else {
        this.fileSheet = true;
        if (
          origin === "http://52.172.32.88" ||
          origin === "http://localhost:8080"
        ) {
          // localStorage.setItem("viewerClose", false);
          // this.watchViewerClose = setInterval(
          //   () => this.checkViewerClose(),
          //   1000
          // );
        }
      }
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      let file = this.selectedFile;

      if (file.name) {
        let ext = file.name.split(".").pop();
        // if (file.initiate) {
        let type = 1;
        // if (
        //   this.repositoryDetails.fieldsType === "STATIC" &&
        //   this.workflow.initiatedBy === "FORM"
        // ) {
        //   type = 2;
        // }
        let domainURL = "";
        if (pdfSupport(ext)) {
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          } else if (origin === "https://ezappdev01.azurewebsites.net") {
            domainURL =
              "https://ezviewerdev01.azurewebsites.net/web/viewer.html";
          } else if (origin === "https://trial.ezofis.com") {
            domainURL = "https://trial.ezofis.com/docsviewer/index.html";
          } else {
            domainURL = `${origin}/PDFViewer/web/viewer.html`;
          }
        } else if (origin === "http://52.172.32.88") {
          domainURL = "http://52.172.32.88/DocsViewer/index.html";
        } else {
          return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${file.fileId}/${type}`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uploadId=${file.fileId}&type=${type}`;
      }
    },

    archivedFileLink() {
      let file = this.selectedFile;

      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (file.fileName) {
        let ext = file.fileName.split(".").pop();
        let action = "";
        action = "&action=all";
        let domainURL = "";
        if (pdfSupport(ext)) {
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            action = "";
            domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          } else if (origin === "https://ezappdev01.azurewebsites.net") {
            action = "";
            domainURL =
              "https://ezviewerdev01.azurewebsites.net/web/viewer.html";
          } else if (
            origin === "https://trial.ezofis.com" &&
            !otherFilePDFSupport(ext)
          ) {
            domainURL = "https://trial.ezofis.com/docsviewer/index.html";
          } else if (origin === "https://app.ezofis.com") {
            domainURL =
              "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
          } else {
            domainURL = `${origin}/PDFViewer/web/viewer.html`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          domainURL = "http://52.172.32.88/DocsViewer/index.html";
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${file.repositoryId}/${file.itemId}/2`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${file.repositoryId}&itemId=${file.itemId}&wId=${file.workflowId}&pId=${file.processId}&type=2&filename=${file.fileName}${action}`;
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    deleteFile(index, file, hasFile) {
      this.value.splice(index, 1);
      console.log(file, "delete");
      // this.formUploadFiles.splice(index, 1);
      // this.deletedFiles.push(file.itemId);
      if (file.itemId) {
        this.deletedFiles.push({
          id: file.itemId,
          jsonId: this.field.id,
          index: index,
        });
      } else {
        this.deletedFiles.push({
          id: file.fileId,
          jsonId: this.field.id,
          index: index,
          initiate: true,
        });
      }
      let files = "";
      if (hasFile) {
        files = {
          file: file,
          index: index,
          controls: this.assignControls,
        };
      }
      let emitValue = {
        deletedFiles: this.deletedFiles,
        settings: files,
      };
      this.$emit("deletedFiles", emitValue);
    },

    viewFile(file, initiate) {
      if (initiate) {
        this.$set(file, "initiate", true);
      }
      this.$emit("viewFile", file);
    },

    uploadingStatus(percentage) {
      let number = 0;
      if (percentage) {
        number = percentage;
      }
      console.log(50 > number, number);
      if (50 > number) {
        return "Extracting...";
      } else {
        return "Uploading...";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#file-upload-list .wrapper {
  // min-height: 46px;
  // position: relative;
  // display: flex;
  // align-items: center;
  // margin-right: 8px;
  border: 1px solid var(--icon-color-inverted);
  padding: 8px 5px;
  border-radius: 5px;

  ol {
    margin: 0px;
    padding-left: 16px;

    li {
      margin: 8px 0px;
      color: var(--secondary);
    }
  }

  .text-underline {
    text-decoration: underline;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-name:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<style lang="scss">
#field-error.upload-error {
  margin-top: 0px !important;
}
</style>
