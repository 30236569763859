<template>
  <FormSection
    :icon="isActive ? 'mdi-file-document' : 'mdi-file-document-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Form Sync</template>
    <template #description> Form data sync from last request </template>

    <MultiSelectField
      :value="syncFormFields"
      label="Form Fields"
      :options="formColumns"
      class="q-mb-lg"
      @input="updateSyncFormFields"
    />
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
// import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import { form } from "@/api/factory.js";

export default {
  name: "FormSyncSettings",

  components: {
    FormSection,
    MultiSelectField,
  },

  props: {
    syncFormFields: {
      type: Array,
      default: () => [],
    },
    formId: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formColumns: [],
    };
  },

  watch: {
    formId: {
      immediate: true,
      handler() {
        this.getFormColumns();
      },
    },
  },

  created() {},

  methods: {
    updateSyncFormFields(value) {
      this.$emit("update:syncFormFields", value);
    },

    async getFormColumns() {
      this.formColumns = [];
      if (this.formId) {
        const { error, payload } = await form.getForm(this.formId);

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload) {
          let form = JSON.parse(payload.formJson);
          let fields = [];
          const panels = [...form.panels, ...form.secondaryPanels];

          if (!panels.length) {
            return;
          }
          // let panelIndex = 1;
          for (const panel of panels) {
            if (panel.fields.length) {
              fields.push(...panel.fields);
            }
          }

          fields.forEach((field) => {
            if (field.type !== "DIVIDER") {
              this.formColumns.push({
                id: field.id,
                label: field.label ? field.label : field.type,
                value: field.id,
              });
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
