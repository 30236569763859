<template>
  <ValidationProvider v-slot="{ errors }" :rules="validationRules">
    <DateField
      :value="value"
      :is-mandatory="!_isReadonly ? _isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :is-clearable="false"
      :error="errors[0]"
      :minimum-validation="minimumValidation"
      :maximum-validation="maximumValidation"
      :maximum-date="maximumDay"
      :minimum-date="minimumDay"
      :date-range-validation="dateRangeValidation"
      :field="field"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import DateField from "@/components/common/form/date-field/DateField.vue";
import { axiosCrypto } from "@/api/axios.js";
import { connector } from "@/api/factory.js";

export default {
  name: "DateFieldWrapper",

  components: { ValidationProvider, DateField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: [Number, String],
      default: 0,
    },

    filterBy: {
      type: Array,
      default: () => [],
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    formSettings: {
      type: Object,
      required: true,
    },

    lookupFilter: {
      type: String,
      default: "",
    },

    lookupFilterBy: {
      type: Array,
      default: () => [],
    },

    lookupFilterInTable: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      minimumValidation: false,
      maximumValidation: false,
      maximumDay: 0,
      minimumDay: 0,
      dateRangeValidation: false,
      options: [],
      hubLinkId: "",
    };
  },

  computed: {
    // validationRules() {
    //   const rules = {};

    //   if (this.isMandatory && !this.isReadonly) {
    //     rules.required = true;
    //   }

    //   const minDate = this.field.settings.validation.minimum;
    //   if (minDate) {
    //     rules.minDate = minDate;
    //   }

    //   const maxDate = this.field.settings.validation.maximum;
    //   if (maxDate) {
    //     rules.maxDate = maxDate;
    //   }

    //   return rules;
    // },

    validationRules() {
      const rules = {};

      if (this._isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      const range = this.field.settings.validation.dateRange;
      const miniDays = this.field.settings.validation.miniDays;
      const maxiDays = this.field.settings.validation.maxiDays;
      if (range === "DATE_RANGE") {
        const minDate = this.field.settings.validation.minimum;
        if (minDate) {
          rules.minDate = minDate;
        }

        const maxDate = this.field.settings.validation.maximum;
        if (maxDate) {
          rules.maxDate = maxDate;
        }
        this.getDateRange();
      } else if (range === "MAX_CURRENT_DATE") {
        this.getMaxCurrentDate(miniDays);
        if (miniDays) {
          rules.minDate = this.$day.subtractDate(this.$day.newDate(), miniDays);
          rules.maxDate = this.$day.newDate();
        }
      } else if (range === "MINI_CURRENT_DATE") {
        this.getMinCurrentDate(maxiDays);
        if (maxiDays) {
          rules.minDate = this.$day.newDate();
          rules.maxDate = this.$day.addDate(this.$day.newDate(), maxiDays);
        }
      }

      return rules;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    _isMandatory() {
      if (this.isMandatory) {
        return true;
      } else {
        return this.field.settings.validation.fieldRule === "REQUIRED";
      }
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filterBy) {
          if (this.filterBy.length) {
            if (this.field.settings.specific.masterFormId) {
              this.getMasterEntriesFilter();
            }
          }
        }
      },
    },

    lookupFilter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.lookupFilterBy) {
          if (this.lookupFilterBy.length) {
            if (this.hubLinkId) {
              this.getLookupFilterData(this.lookupFilterBy);
            }
          }
        }
      },
    },

    lookupFilterInTable: {
      immediate: true,
      handler() {
        console.log(this.field.lookupFilterBy, "1 this.field.lookupFilterBy");
        if (this.field.lookupFilterBy) {
          if (this.field.lookupFilterBy.length) {
            if (this.hubLinkId) {
              this.getLookupFilterData(this.field.lookupFilterBy);
            }
          }
        }
      },
    },
  },

  created() {
    let hasLookup = this.field.settings.lookupSettings;
    console.log(hasLookup, "hasLookup");
    if (hasLookup && hasLookup.columnName) {
      if (this.formSettings.hubLinkIds && this.formSettings.hubLinkIds.length) {
        let hublink = this.formSettings.hubLinkIds.find(
          (item) => item.jsonId === this.field.id
        );
        if (hublink) {
          this.hubLinkId = hublink.hubLinkId;
          this.getLookupData(this.hubLinkId);
        }
      }
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    async getMasterEntriesFilter() {
      let filterBy = [];
      this.filterBy.forEach((item) => {
        filterBy.push({ ...item, value: item.value.toString() });
      });

      try {
        const response = await axiosCrypto.post(
          `/form/${this.field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: this.field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.handleInput(options[0]);
        } else {
          this.handleInput("");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    getMinCurrentDate(day) {
      this.minimumValidation = true;
      this.maximumDay = day;
    },

    getMaxCurrentDate(day) {
      this.maximumValidation = true;
      this.minimumDay = day;
    },

    getDateRange() {
      this.dateRangeValidation = true;
    },

    async getLookupData(hubLinkId) {
      const { error, payload } = await connector.getSyncValue({
        hublinkId: hubLinkId,
        payloadMapping: "",
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload, this.field.label, "payload");
      this.options = [];
      if (payload.data[0].value.length) {
        let modal = payload.data[0].value;
        modal.forEach((item) => {
          if (item[this.field.id]) {
            let unique = this.options.findIndex((option) => {
              return option.value === item[this.field.id];
            });
            if (unique === -1) {
              this.options.push({
                id: this.$nano.id(),
                label: item[this.field.id],
                value: item[this.field.id],
              });

              // this.$emit(
              //   "updateOptions",
              //   this.field,
              //   this.rowIndex,
              //   this.options
              // );
            }
          }
        });
      }
    },

    async getLookupFilterData(filter) {
      let input = "";
      if (filter && filter.length && filter[0].value) {
        input = {
          filterBy: [
            {
              id: this.$nano.id(),
              filters: filter,
              groupCondition: "",
            },
          ],
        };
      } else {
        input = "";
      }
      // console.log(input, this.hubLinkDetails, this.field.label, "input");

      const { error, payload } = await connector.getSyncValue({
        hublinkId: this.hubLinkId,
        payloadMapping: input ? JSON.stringify(input) : "",
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload, this.field.label, "payload");
      this.options = [];
      if (payload.data[0].value.length) {
        let modal = payload.data[0].value;
        modal.forEach((item) => {
          if (item[this.field.id]) {
            let unique = this.options.findIndex((option) => {
              return option.value === item[this.field.id];
            });
            if (unique === -1) {
              this.options.push({
                id: this.$nano.id(),
                label: item[this.field.id],
                value: item[this.field.id],
              });
              // this.$emit(
              //   "updateOptions",
              //   this.field,
              //   this.rowIndex,
              //   this.options
              // );
            }
          }
        });

        if (this.options.length === 1) {
          this.handleInput(this.options[0].value);
        } else {
          this.handleInput("");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
