<template>
  <ValidationProvider v-slot="{ errors }" :name="field.label" class="col-12">
    <TableField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      transform=""
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :calculations="_calculations"
      :table-columns="tableColumns"
      :secondary-panels="secondaryPanels"
      :table-row-choose-type="tableRowChooseType"
      :table-rows-type="tableRowsType"
      :table-fixed-row-count="tableFixedRowCount"
      :table-fixed-row-labels="tableFixedRowLabels"
      :qr-value="qrValue"
      :form-settings="formSettings"
      :form-id="formId"
      :has-master-table-change="hasMasterTableChange"
      :has-change-table-row="hasChangeTableRow"
      :fields-model="fieldsModel"
      :repository-details="repositoryDetails"
      :lookup-filter-in-table="lookupFilterInTable"
      @input="handleInput"
      @uploadFiles="uploadFiles"
      @deletedFiles="deletedFiles"
      @showQr="showQr"
      @viewFile="viewFile"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TableField from "@/components/common/form/table-field/TableField.vue";
import { form } from "@/api/factory.js";
import { isEmpty } from "lodash-es";

export default {
  name: "TableFieldWrapper",

  components: { ValidationProvider, TableField },

  props: {
    value: {
      type: Array,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    rules: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "",
    },

    filter: {
      type: [Number, String],
      default: 0,
    },

    editControls: {
      type: Array,
      default: () => [],
    },

    hasChangeTableRow: {
      type: Array,
      default: () => [],
    },

    fieldsModel: {
      type: Object,
      default: () => {},
    },

    repositoryDetails: {
      type: Object,
      default: () => {},
    },

    lookupFilterInTable: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      hasMasterTableChange: false,
      fieldChange: true,
    };
  },

  computed: {
    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    tableColumns() {
      let tblColumns = [];
      if (this.tableRowChooseType) {
        tblColumns.push({
          id: this.$nano.id(),
          label: "",
          settings: {
            general: {},
            specific: {},
            validation: {},
          },
          size: "SMALL",
          type: "SELECTION",
        });
      }
      this.field.settings.specific.tableColumns.forEach((col) => {
        // if (this.editControls.length) {
        //   if (
        //     typeof this.editControls[0] === "object" ||
        //     typeof this.editControls[0] === "string"
        //   ) {
        //     let formEditControls = [];
        //     if (typeof this.editControls[0] === "object") {
        //       formEditControls = this.editControls;
        //     } else {
        //       this.editControls.forEach((edit) => {
        //         if (typeof edit === "string") {
        //           formEditControls.push(JSON.parse(edit));
        //         }
        //       });
        //     }
        //     let hasEmail = formEditControls.find(
        //       (item) => item.userId === this.$store.state.session.id
        //     );
        //     console.log(hasEmail, hasEmail.formFields);
        //     if (hasEmail && hasEmail.formFields.length) {
        //       // let formfields = [];
        //       hasEmail.formFields.forEach((form) => {
        //         let table = form.split("@");
        //         // console.log(table);
        //         if (table.length === 2) {
        //           console.log(table[1].trim(), col.id, "same");
        //           if (table[1].trim() !== col.id) {
        //             // return true;
        //             col.settings.general.visibility = "READ_ONLY";
        //             // formfields.push(table[0].trim());
        //           }
        //         }
        //       });
        //     }
        //   }
        // } else {
        tblColumns.push(col);
        // }
      });
      return tblColumns;
    },

    tableRowChooseType() {
      return this.field.settings.specific.tableRowChooseType;
    },

    tableRowsType() {
      return this.field.settings.specific.tableRowsType;
    },

    tableFixedRowCount() {
      return this.field.settings.specific.tableFixedRowCount;
    },

    tableFixedRowLabels() {
      return this.field.settings.specific.tableFixedRowLabels;
    },

    _calculations() {
      const _calculations = [];

      for (const rule of this.rules) {
        if (rule.isConditionalRule) {
          return;
        }

        const calculations = rule.calculations;

        for (const calculation of calculations) {
          if (calculation.fieldId === this.field.id) {
            _calculations.push(calculation);
          }
        }
      }

      return _calculations;
    },

    qrValue() {
      return this.field.settings.specific.qrValue;
    },
  },

  watch: {
    filter: {
      // immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          }
        }
      },
    },

    field: {
      immediate: true,
      // deep: true,
      handler() {
        if (this.fieldChange) {
          if (this.field.settings.specific.masterFormParentColumn) {
            this.fieldChange = false;
            this.$emit(
              "getParentColumnValueInTable",
              this.field.settings.specific.masterFormParentColumn
            );
          }

          let columns = this.field.settings.specific.tableColumns;
          columns.forEach((column) => {
            if (column.settings.specific.masterFormParentColumn) {
              this.$emit(
                "getParentColumnValueInTable",
                column.settings.specific.masterFormParentColumn
              );
            }
          });
        }
      },
    },
  },

  created() {
    if (this.editControls.length) {
      if (
        typeof this.editControls[0] === "object" ||
        typeof this.editControls[0] === "string"
      ) {
        let formEditControls = [];
        if (typeof this.editControls[0] === "object") {
          formEditControls = this.editControls;
        } else {
          this.editControls.forEach((edit) => {
            if (typeof edit === "string") {
              formEditControls.push(edit);
            } else {
              formEditControls.push(JSON.parse(edit));
            }
          });
        }
        let hasEmail = formEditControls.find(
          (item) => item.userId === this.$store.state.session.id
        );
        if (hasEmail && hasEmail.formFields.length) {
          this.field.settings.specific.tableColumns.forEach((col) => {
            let fields = [];
            hasEmail.formFields.forEach((item) => {
              let table = item.split("@");
              if (fields.indexOf() === -1 && table.length === 2) {
                fields.push(table[1].trim());
              }
            });
            let find = fields.find((fie) => fie === col.id);
            if (!find) {
              col.settings.general.visibility = "READ_ONLY";
            }
          });
        }
      }
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    async getMasterEntriesFilter(field) {
      let columns = field.settings.specific.masterFormTableColumns;
      const { error, payload } = await form.getFormEntries(
        field.settings.specific.masterFormId,
        {
          mode: "BROWSE",
          sortBy: {
            criteria: "",
            order: "DESC",
          },
          groupBy: "",
          filterBy: [
            {
              id: this.$nano.id(),
              filters: field.filterBy,
              groupCondition: "",
            },
          ],
          itemsPerPage: 0,
          currentPage: 1,
        }
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        let rows = [];
        // let objectClone = {};
        // if (this.value.length) {
        //   objectClone = this.value[0];
        // } else {
        //   columns.forEach((column) => {
        //     objectClone[column.tableColumn] = "";
        //   });
        // }
        if (data[0].value.length) {
          if (this.field.isManualChange) {
            data[0].value.forEach((control) => {
              let tableValue = {};
              this.tableColumns.forEach((column) => {
                this.$set(
                  tableValue,
                  [column.id],
                  this.getEmptyDefaultValue(column.type)
                );
              });
              for (let key in control) {
                let hasName = columns.find((item) => item.masterColumn === key);
                if (!isEmpty(hasName)) {
                  this.$set(
                    tableValue,
                    [hasName.tableColumn],
                    control[hasName.masterColumn]
                  );
                  // objectClone[hasName.tableColumn] =
                  //   control[hasName.masterColumn];
                } else if (
                  typeof control[key] === "string" &&
                  control[key] &&
                  this.$day.format(control[key]) === "Invalid Date"
                ) {
                  if (control[key].charAt(0) === "[") {
                    let table = JSON.parse(control[key]);

                    table.forEach((col) => {
                      let tableValue1 = {};
                      this.tableColumns.forEach((column) => {
                        this.$set(
                          tableValue1,
                          [column.id],
                          this.getEmptyDefaultValue(column.type)
                        );
                      });

                      for (let tableKey in col) {
                        let tableName = columns.find(
                          (item) => item.masterColumn === tableKey
                        );

                        if (!isEmpty(tableName)) {
                          tableValue1[tableName.tableColumn] =
                            col[tableName.masterColumn];
                        }
                      }

                      if (!isEmpty(tableValue1)) {
                        rows.push(tableValue1);
                      }
                    });
                  }
                }
              }
              if (!isEmpty(tableValue)) {
                // rows.push(objectClone);
                rows.push(tableValue);
              }
            });
            this.hasMasterTableChange = !this.hasMasterTableChange;
            this.handleInput(rows);
          }
        } else {
          this.hasMasterTableChange = !this.hasMasterTableChange;
          this.handleInput([]);
        }
      } else {
        this.hasMasterTableChange = !this.hasMasterTableChange;
        this.handleInput([]);
      }
    },

    getEmptyDefaultValue(fieldType) {
      switch (fieldType) {
        case "MULTI_SELECT":
        case "MULTIPLE_CHOICE":
        case "FILE_UPLOAD":
        case "IMAGE_UPLOAD":
        case "TABLE":
        case "MATRIX":
        case "CHIPS":
        case "NESTED_LIST":
          return [];
        case "COUNTER":
        case "CALCULATED":
        case "RATING":
          return 0;
        case "FILL_IN_THE_BLANKS":
        case "POPUP":
        case "TABS":
          return {};
        default:
          return "";
      }
    },

    showQr(value) {
      let values = value;
      this.$set(values, "tableId", this.field.id);
      this.$emit("showQrInTable", values);
    },

    deletedFiles(value) {
      let values = value;
      this.$set(values, "tableId", this.field.id);
      this.$emit("deletedFilesInTable", values);
    },

    uploadFiles(value) {
      let values = value;
      this.$set(values, "tableId", this.field.id);
      this.$emit("uploadFilesInTable", values);
    },

    viewFile(file) {
      this.$emit("viewFile", file);
    },
  },
};
</script>

<style lang="scss" scoped></style>
