import { render, staticRenderFns } from "./ChatFooter.vue?vue&type=template&id=51eb6c31&"
import script from "./ChatFooter.vue?vue&type=script&lang=js&"
export * from "./ChatFooter.vue?vue&type=script&lang=js&"
import style0 from "./ChatFooter.vue?vue&type=style&index=0&id=51eb6c31&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu});
