<template>
  <div id="user-browse">
    <Portal to="action-bar">
      <ItemActions
        v-if="!isLoading"
        item="user"
        :columns.sync="columns"
        :group-by.sync="groupBy"
        :order.sync="sortBy.order"
        :filter-by.sync="filterBy"
        :active-view.sync="activeView"
        :criteria.sync="sortBy.criteria"
        @refresh="getProfile"
        @search="searchRow"
        @update="getProfile"
        @export="export_Data"
      />
    </Portal>

    <div v-if="!isLoading" class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <ProfileGrid
          v-if="activeView === 'GRID'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editProfile"
          @more="showProfileDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />

        <ProfileList
          v-if="activeView === 'LIST'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editProfile"
          @more="showProfileDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="mdi-account-cog-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      v-if="!isLoading"
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
      @loadAll="loadAll"
    />

    <ProfileDetails
      v-model="isProfileDetailsVisible"
      :columns="columns"
      :user="selectedProfile"
      @edit="editProfile"
      @delete="showDeleteConfirmation"
    />

    <ConfirmDeleteProfile
      v-model="isDeleteConfirmationVisible"
      :profile="selectedProfile"
      @delete="updateProfile"
    />

    <ConfirmRestoreProfile
      v-model="isRestoreConfirmationVisible"
      :profile="selectedProfile"
      @restore="updateProfile"
    />
  </div>
</template>

<script>
// import { startCase, capitalize } from "lodash-es";
// import { user } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import ProfileGrid from "./components/ProfileGrid.vue";
import ProfileList from "./components/ProfileList.vue";
import ProfileDetails from "./components/ProfileDetails.vue";
import ConfirmDeleteProfile from "./components/ConfirmDeleteProfile.vue";
import ConfirmRestoreProfile from "./components/ConfirmRestoreProfile.vue";
import { profile } from "@/api/factory.js";
import { exportData } from "@/helpers/export-excel-data";

export default {
  name: "ProfileBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    ItemActionChips,
    ItemActions,
    ProfileGrid,
    ProfileList,
    ProfileDetails,
    Pagination,
    ConfirmDeleteProfile,
    ConfirmRestoreProfile,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "users",
          label: "Users",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "menu",
          label: "Menu",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "usersList",
          label: "UsersList",
          type: "NORMAL",
          isVisible: false,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "options",
          label: "MenuOptions",
          type: "NORMAL",
          isVisible: false,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
      ],
      profiles: [],
      userData: [],
      activeView: "GRID",
      selectedProfile: {},
      isProfileDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
    };
  },

  computed: {
    items() {
      return this.profiles.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((profile) => ({
          id: profile.id,
          name: profile.name || "",
          description: profile.description,
          users: profile.userId_Array.length,
          usersList: profile.userId_Array,
          options: JSON.parse(profile.menuOptions),
          menu: JSON.parse(profile.menuOptions).length
            ? JSON.parse(profile.menuOptions).reduce((acc, obj) => {
                for (let key in obj) {
                  if (obj[key] && key !== "Menu") {
                    acc.push(obj);
                  }
                  if (key === "Menu") {
                    for (let key1 in obj.Menu) {
                      if (obj.Menu[key1] && key === "Menu") {
                        acc.push(obj.Menu[key1]);
                      }
                    }
                  }
                }
                return acc;
              }, []).length
            : 0,
          createdBy: profile.createdBy,
          createdAt: this.$day.format(profile.createdAt),
          modifiedBy: profile.modifiedBy,
          modifiedAt: this.$day.format(profile.modifiedAt),
          icon: "mdi-account-cog",
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.defaultView();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getProfile(),
      { deep: true, immediate: true }
    );
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      if (Object.keys(options).length) {
        if (options[menu]) {
          let view = options[menu].find((item) => item.subMenu === subMenu);
          if (view) {
            this.activeView = view.view;
          }
        }
      }
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) {
        this.filterBy = [];
      }
      this.getProfile();
    },

    findUser(userId) {
      const profiles = [];
      this.items.forEach((item) => profiles.push(...item.data));
      return profiles.find((user) => user.id === userId);
    },

    showProfileDetails(userId) {
      this.selectedProfile = this.findUser(userId);
      this.isProfileDetailsVisible = true;
    },

    editProfile(userId) {
      this.$router.push({
        name: "profile-builder",
        params: { id: String(userId) },
      });
    },

    showDeleteConfirmation(userId) {
      this.selectedProfile = this.findUser(userId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(userId) {
      this.selectedProfile = this.findUser(userId);
      this.isRestoreConfirmationVisible = true;
    },

    loadAll() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    searchRow(search) {
      if (search) {
        this.profiles = this.profileList.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.profiles = this.profileList;
      }
    },

    /* api functions */

    async getProfile() {
      this.$store.commit("showLoadingBarPage");
      let input = {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      };

      const { error, payload } = await profile.getProfile(input);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        const { data, meta } = payload;
        // console.log(data);
        this.profiles = data || [];
        this.profileList = data || [];
        this.totalItems = meta ? Number(meta.totalItems) : 0;
      } else {
        this.profiles = [];
        this.profileList = [];
      }
    },

    async updateProfile(payload) {
      this.$store.commit("showLoadingBarPage");

      const { error } = await profile.deleteProfile(this.selectedProfile.id);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const mode = payload.isDeleted ? "deleted" : "restored";
      const name = this.selectedProfile.name;
      this.$alert.success(`Profile "${name}" ${mode} successfully`);

      this.getProfile();
    },

    async export_Data() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await profile.getProfile({
        filterBy: this.filterBy,
        itemsPerPage: 0,
        currentPage: 0,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { meta, data } = payload;
      if (meta.totalItems && data.length) {
        let exportRecords = [];
        data[0].value.forEach((profile) => {
          exportRecords.push({
            Name: profile.name,
            Description: profile.description,
            "No.of Users": profile.userId_Array.length,
            "Created By": profile.createdBy,
            "Created At": this.$day.format(profile.createdAt),
            "Last Modified By": profile.modifiedBy,
            "Last Modified At": this.$day.format(profile.modifiedAt),
          });
        });
        let exportName = "Profile List - Report";
        if (this.mode === "TRASH") {
          exportName = "Profile Trash List - Report";
        }
        exportData(exportRecords, exportName);
      } else {
        //this.$alert.info("No data found for export");
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#user-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
