<template>
  <div id="custom-form-view" :style="{ width: formWidth }">
    <!-- form meta -->

    <div class="header">
      <div class="col-auto logo">
        <img :src="_logo" />
      </div>
      <div class="col q-mr-xl text-center">
        <div class="title-1">Request Submission</div>
        <div class="description">To submit the Claim Request</div>
      </div>

      <!-- <BaseActionButton
        is-flat
        :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
        @click="toggleWidth"
      />
      <ThemeSwitcher class="q-ml-sm" :no-border="false" /> -->
    </div>

    <!-- ... -->

    <!-- form -->
    <div class="content">
      <FormFieldLabel label="Claimant Search" />

      <div class="content-wrapper">
        <div v-if="$q.screen.width > 1280" class="row q-mb-md">
          <!-- lender -->
          <MultiSelectField
            v-model="lender"
            label="lender"
            :options="lenderList"
            class="col"
          />
          <!-- ... -->

          <!-- first name -->

          <TextField
            v-model="firstName"
            label="first name"
            class="col q-ml-md"
            @enter="getData"
          />

          <!-- ... -->

          <!-- last name -->

          <TextField
            v-model="lastName"
            label="last name"
            class="col q-ml-md"
            @enter="getData"
          />

          <!-- ... -->

          <!-- dob -->

          <DateField
            v-model="dob"
            label="DOB"
            class="col q-ml-md"
            @enter="getData"
          />

          <!-- ... -->

          <!-- province -->

          <TextField
            v-model="province"
            label="province"
            class="col q-ml-md"
            @enter="getData"
          />

          <!-- ... -->

          <!-- claim number -->

          <TextField
            v-model="claimNumber"
            label="claim number"
            class="col q-ml-md"
            @enter="getData"
          />

          <!-- ... -->

          <!-- contract number -->

          <TextField
            v-model="contractNumber"
            label="contract number"
            class="col q-ml-md"
            @enter="getData"
          />

          <!-- ... -->

          <!-- ref number -->

          <TextField
            v-model="refNumber"
            label="Ref. number"
            class="col q-ml-md"
            @enter="getData"
          />

          <!-- ... -->

          <!-- search -->
          <BaseButton
            label="Search"
            icon="eva-search-outline"
            class="searchBtn col-auto q-ml-md"
            @click="getData"
          />
          <!-- ... -->
        </div>
        <div v-else>
          <div class="row q-mb-md">
            <!-- lender -->
            <MultiSelectField
              v-model="lender"
              label="lender"
              :options="lenderList"
              class="col"
            />
            <!-- ... -->

            <!-- first name -->

            <TextField
              v-model="firstName"
              label="first name"
              class="col q-ml-md"
              @enter="getData"
            />

            <!-- ... -->

            <!-- last name -->

            <TextField
              v-model="lastName"
              label="last name"
              class="col q-ml-md"
              @enter="getData"
            />

            <!-- ... -->

            <!-- dob -->

            <DateField
              v-model="dob"
              label="DOB"
              class="col q-ml-md"
              @enter="getData"
            />

            <!-- ... -->
          </div>
          <div class="row q-mb-md">
            <!-- province -->

            <TextField
              v-model="province"
              label="province"
              class="col"
              @enter="getData"
            />

            <!-- ... -->

            <!-- claim number -->

            <TextField
              v-model="claimNumber"
              label="claim number"
              class="col q-ml-md"
              @enter="getData"
            />

            <!-- ... -->

            <!-- contract number -->

            <TextField
              v-model="contractNumber"
              label="contract number"
              class="col q-ml-md"
              @enter="getData"
            />

            <!-- ... -->

            <!-- ref number -->

            <TextField
              v-model="refNumber"
              label="Ref. number"
              class="col q-ml-md"
              @enter="getData"
            />

            <!-- ... -->

            <!-- search -->
            <BaseButton
              label="Search"
              icon="eva-search-outline"
              class="searchBtn col-auto q-ml-md"
              @click="getData"
            />
          </div>
        </div>
      </div>
      <div class="row q-mb-xs">
        <div class="col q-mt-sm">
          <FormFieldLabel label="Search Results" />
        </div>
        <q-space />
        <div class="col-auto">
          <ExpandableSearch class="q-mr-sm" @search="searchRow" />
        </div>
        <div class="col-auto">
          <BaseButton
            label="Add Contract and Send Package"
            icon="eva-plus-outline"
            color="gray"
            @click="addContractSheet = true"
          />
        </div>
      </div>
      <div class="content-wrapper tables">
        <BaseScrollbar height="calc(100vh - 360px)">
          <div class="row">
            <FormFieldLabel :label="contractCount" />
            <BaseActionButton
              :icon="contractExpand ? 'mdi-chevron-down' : 'mdi-chevron-up'"
              class="q-ml-sm small"
              is-flat
              no-border
              @click="contractExpand = !contractExpand"
            />
          </div>
          <table v-if="contractExpand">
            <thead>
              <tr>
                <!-- info -->

                <th class="sticky" style="left: 0; width: 15px"></th>

                <!-- ... -->
                <!-- columns -->

                <th v-for="column in contractColumnsFilter" :key="column.id">
                  {{ column.label }}
                </th>

                <!-- ... -->

                <!-- actions -->

                <th class="sticky"></th>

                <!-- ... -->
              </tr>
            </thead>
            <tbody>
              <template v-for="item in tableContracts">
                <tr :key="item.id">
                  <!-- info -->

                  <td class="sticky" style="left: 0; width: 15px">
                    <div class="row items-center justify-center">
                      <BaseButton
                        label="Expand"
                        :icon="item.infoVisible ? 'eva-eye-off' : 'eva-eye'"
                        color="secondary"
                        is-flat
                        no-border
                        @click="item.infoVisible = !item.infoVisible"
                      />
                    </div>
                  </td>

                  <!-- ... -->

                  <!-- columns -->

                  <td v-for="column in contractColumnsFilter" :key="column.id">
                    <template v-if="column.editable">
                      <template v-if="column.valueType === 'email'">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="column.name"
                          :rules="{ required: column.required, email: true }"
                        >
                          <TextField
                            v-model="item[column.name]"
                            is-mandatory
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </template>
                      <template v-else-if="column.name === 'lender'">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="column.name"
                          :rules="{ required: column.required }"
                        >
                          <SelectField
                            v-model="item[column.name]"
                            is-mandatory
                            :options="lenderList"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </template>
                      <template v-else>
                        <TextField v-model="item[column.name]" is-mandatory />
                      </template>
                    </template>
                    <template v-else>
                      <slot
                        :name="column.name"
                        :value="item[column.name]"
                        :item="item"
                      >
                        {{ item[column.name] }}
                      </slot>
                    </template>
                  </td>

                  <!-- ... -->

                  <!-- actions -->

                  <td class="sticky">
                    <div class="row justify-center">
                      <BaseButton
                        label="Send Package"
                        icon="mdi-file-send-outline"
                        class="col-auto q-ml-md"
                        color="secondary"
                        @click="getProduct(item.id)"
                      />
                    </div>
                  </td>

                  <!-- ... -->
                </tr>
                <tr v-if="item.infoVisible" :key="'sub-' + item.id">
                  <td :colspan="contractColumnsFilter.length + 2">
                    <table class="subDetails">
                      <thead>
                        <th
                          v-for="column in contractColumnsInfo"
                          :key="column.id"
                        >
                          {{ column.label }}
                        </th>
                      </thead>
                      <tbody>
                        <td
                          v-for="column in contractColumnsInfo"
                          :key="column.id"
                        >
                          <slot
                            :name="column.name"
                            :value="item[column.name]"
                            :item="item"
                          >
                            {{ item[column.name] }}
                          </slot>
                        </td>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="row">
            <FormFieldLabel :label="claimCount" />
            <BaseActionButton
              :icon="claimExpand ? 'mdi-chevron-down' : 'mdi-chevron-up'"
              class="q-ml-sm small"
              is-flat
              no-border
              @click="claimExpand = !claimExpand"
            />
          </div>
          <table v-if="claimExpand">
            <thead>
              <tr>
                <!-- info -->

                <th class="sticky" style="left: 0; width: 15px"></th>
                <!-- ... -->

                <!-- columns -->

                <th v-for="column in claimColumnsFilter" :key="column.id">
                  {{ column.label }}
                </th>

                <!-- ... -->

                <!-- actions -->

                <th class="sticky"></th>

                <!-- ... -->
              </tr>
            </thead>
            <tbody>
              <template v-for="item in tableClaims">
                <tr :key="item.id">
                  <!-- info -->

                  <td class="sticky" style="left: 0; width: 15px">
                    <div class="row items-center justify-center">
                      <BaseButton
                        label="Expand"
                        :icon="item.infoVisible ? 'eva-eye-off' : 'eva-eye'"
                        color="secondary"
                        is-flat
                        no-border
                        @click="item.infoVisible = !item.infoVisible"
                      />
                    </div>
                  </td>

                  <!-- ... -->

                  <!-- columns -->

                  <td v-for="column in claimColumnsFilter" :key="column.id">
                    <template v-if="column.name === 'history'">
                      <BaseActionButton
                        v-tooltip.bottom="'Claim History'"
                        icon="mdi-history"
                        color="secondary"
                        is-flat
                        no-border
                        @click="claimHistory(item)"
                      />
                    </template>
                    <template v-else-if="column.editable">
                      <template v-if="column.valueType === 'email'">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="column.name"
                          :rules="{ required: column.required, email: true }"
                        >
                          <TextField
                            v-model="item[column.name]"
                            is-mandatory
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </template>
                      <template v-else>
                        <TextField v-model="item[column.name]" is-mandatory />
                      </template>
                    </template>
                    <template v-else>
                      <slot
                        :name="column.name"
                        :value="item[column.name]"
                        :item="item"
                      >
                        {{ item[column.name] }}
                      </slot>
                    </template>
                  </td>

                  <!-- ... -->

                  <!-- actions -->

                  <td class="sticky">
                    <div class="row justify-center q-mt-sm">
                      <template v-if="claimStatus(item)">
                        <div @click="reopenClaim(item.id)">
                          <q-chip outline color="pink" class="chips pink-hover">
                            <q-avatar
                              icon="mdi-arrow-top-right"
                              class="q-mr-sm"
                              color="pink"
                              text-color="white"
                            />
                            Re-open
                          </q-chip>
                        </div>
                      </template>
                      <!-- <template v-else>
                        <q-chip outline color="gray" class="chips gray-hover">
                          <q-avatar
                            icon="mdi-plus"
                            class="q-mr-sm"
                            color="gray"
                            text-color="white"
                          />
                          Continue
                        </q-chip>
                      </template> -->
                      <!-- <div @click="newClaim(item.id)">
                        <q-chip
                          outline
                          color="orange"
                          class="chips orange-hover"
                          @click="newClaim(item.id)"
                        >
                          <q-avatar
                            icon="mdi-plus"
                            class="q-mr-sm"
                            color="orange"
                            text-color="white"
                          />
                          New Claim
                        </q-chip>
                      </div> -->
                      <div @click="viewClaim(item.id)">
                        <q-chip
                          outline
                          color="orange"
                          class="chips orange-hover"
                        >
                          <q-avatar
                            icon="eva-eye-outline"
                            class="q-mr-sm"
                            color="orange"
                            text-color="white"
                          />
                          View Claim
                        </q-chip>
                      </div>
                    </div>
                  </td>

                  <!-- ... -->
                </tr>
                <tr v-if="item.infoVisible" :key="'sub-' + item.id">
                  <td :colspan="claimColumnsFilter.length + 2">
                    <table class="subDetails">
                      <thead>
                        <th v-for="column in claimColumnsInfo" :key="column.id">
                          {{ column.label }}
                        </th>
                      </thead>
                      <tbody>
                        <td v-for="column in claimColumnsInfo" :key="column.id">
                          <slot
                            :name="column.name"
                            :value="item[column.name]"
                            :item="item"
                          >
                            {{ item[column.name] }}
                          </slot>
                        </td>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </BaseScrollbar>
      </div>
    </div>
    <!-- ... -->

    <!-- footer -->

    <!-- <div class="footer">
      <div class="label">Powered by</div>
      <img src="@/assets/logo/logo-light.png" alt="logo" />
    </div> -->

    <!-- ... -->

    <AddContract
      :value="addContractSheet"
      :lender-list="lenderList"
      @initiate="processInitiate"
      @close="addContractSheet = false"
    ></AddContract>

    <Modal
      v-model="productModal"
      width="40vw"
      height="60vh"
      has-footer
      @input="productModal = false"
    >
      <!-- title -->

      <template #title> Choose Product</template>

      <!-- ... -->

      <template #default>
        <div id="productModal">
          <!-- product -->

          <MultiSelectField
            v-model="products"
            label="product"
            is-mandatory
            :options="productList"
            class="field"
          />

          <!-- ... -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Send Package"
          color="primary"
          @click="processInitiate('')"
        />
      </template>
    </Modal>
    <Modal
      v-model="claimHistoryModal"
      width="70vw"
      @input="claimHistoryModal = false"
    >
      <!-- title -->

      <template #title>Claim History</template>

      <!-- ... -->

      <template #default>
        <div id="claimHistory">
          <table>
            <thead>
              <tr>
                <!-- columns -->

                <th v-for="column in historyColumns" :key="column.id">
                  {{ column.label }}
                </th>

                <!-- ... -->
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in claimHistoryData" :key="item.id">
                <!-- columns -->

                <td v-for="column in historyColumns" :key="column.id">
                  <slot
                    :name="column.name"
                    :value="item[column.name]"
                    :item="item"
                  >
                    {{ item[column.name] || "-" }}
                  </slot>
                </td>

                <!-- ... -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>

    <Sheet
      v-model="processSheet"
      width="100vw"
      no-padding
      has-footer
      @input="processSheet = false"
    >
      <template #title> {{ headerTitle }} </template>

      <div id="content-wrapper" class="row">
        <div class="col">
          <CustomProcessForm
            :form-id="formId"
            :panels="panels"
            :secondary-panels="secondaryPanels"
            :selected-process="selectedProcess"
            :is-readonly="false"
            :check-list.sync="checkList"
            :form-model="formModel"
            :form-fields="formFields"
            :inbox="true"
          />
        </div>
        <div class="col-auto q-mr-sm leftBorder">
          <template v-for="tab in tabs">
            <BaseActionButton
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
      </div>
      <!-- footer -->
      <template #footer>
        <div class="footer row">
          <BaseButton
            label="Update Claim Details"
            class="q-ml-md"
            color="secondary"
            @click="updateClaimDetails()"
          />
        </div>
      </template>
    </Sheet>

    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="false"
      no-padding
      @input="attachmentSheet = false"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <BaseActionButton
          v-if="selectedProcess.processId"
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getAttachments()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar>
          <template v-for="row in checkList">
            <template v-if="row.name">
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else :key="row.uid">
                <template v-for="file in attachmentFilterCheckList(row.name)">
                  <div :key="file.id" class="attachmentList">
                    <div class="title row">
                      <div v-if="selectedProcess.processId" class="col-auto">
                        <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                        <template v-else>
                          <BaseIcon
                            :name="
                              file.checked
                                ? 'eva-checkmark-circle-2'
                                : 'eva-radio-button-off-outline'
                            "
                            color="secondary"
                            class="q-mr-sm"
                            @click="file.checked = !file.checked"
                          />
                        </template>
                      </div>
                      <div v-else>
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                      </div>
                      <div v-if="file.formJsonId" class="col-auto">
                        <BaseIcon
                          v-tooltip:green.top="getLabel(file.formJsonId)"
                          name="mdi-form-select"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        <FileIcon
                          :mime-type="fileType(file.name)"
                          class="mini-avatar q-mr-sm"
                        />
                      </div>
                      <div class="col ellipsis" @click="openFile(file)">
                        {{ file.name }}
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Download'"
                          icon="eva-download-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Download')"
                        />
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Print'"
                          icon="eva-printer-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Print')"
                        />
                      </div>
                    </div>

                    <div class="meta row">
                      <div class="col row">
                        <!-- created at -->

                        <div>{{ $day.format(file.createdAt) }}</div>

                        <!-- ... -->

                        <!-- created by -->

                        <template v-if="file.createdBy">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ file.createdByEmail }}</div>
                        </template>

                        <!-- ... -->

                        <!-- size -->

                        <template v-if="file.size">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ fileSize(file.size) }}</div>
                        </template>

                        <!-- ... -->
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <template v-if="isCheckList(file.name)">
              <div :key="file.id" class="attachmentList">
                <div class="title row">
                  <template v-if="file.loading">
                    <BaseIcon
                      name="mdi-loading"
                      color="secondary"
                      class="q-mr-sm loading"
                    />
                  </template>
                  <div v-if="selectedProcess.processId" class="col-auto">
                    <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->

                    <BaseIcon
                      :name="
                        file.checked
                          ? 'eva-checkmark-circle-2'
                          : 'eva-radio-button-off-outline'
                      "
                      color="secondary"
                      class="q-mr-sm"
                      @click="file.checked = !file.checked"
                    />
                  </div>
                  <div v-if="file.formJsonId" class="col-auto">
                    <BaseIcon
                      v-tooltip:green.top="getLabel(file.formJsonId)"
                      name="mdi-form-select"
                      color="primary"
                      class="q-mr-sm"
                    />
                  </div>
                  <div class="col-auto">
                    <FileIcon
                      :mime-type="fileType(file.name)"
                      class="mini-avatar q-mr-sm"
                    />
                  </div>
                  <div class="col ellipsis" @click="openFile(file)">
                    {{ file.name }}
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      icon="eva-download-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Download')"
                    />
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Print'"
                      icon="eva-printer-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Print')"
                    />
                  </div>
                </div>

                <div class="meta row">
                  <div class="col row">
                    <!-- created at -->

                    <div>{{ $day.format(file.createdAt) }}</div>

                    <!-- ... -->

                    <!-- created by -->

                    <template v-if="file.createdBy">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ file.createdByEmail }}</div>
                    </template>

                    <!-- ... -->

                    <!-- size -->

                    <template v-if="file.size">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ fileSize(file.size) }}</div>
                    </template>

                    <!-- ... -->
                  </div>
                </div>
              </div>
            </template>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      no-padding
      @input="commentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getComments(0)"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 160px)">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            >
              <template v-slot:avatar>
                <div
                  v-if="row.embedFileList && row.embedFileList.length"
                  class="file-icons"
                >
                  <FileIcon
                    :key="`Icon-${idx}`"
                    v-tooltip.top="row.embedFileList[0].fileName"
                    :mime-type="fileType(row.embedFileList[0].fileName)"
                    class="file-icon q-mr-sm"
                  />
                </div>
              </template>
            </q-chat-message>
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <div class="col borderTop">
        <div class="row q-ma-sm">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto; margin-right: 8px">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm comment-actions"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm comment-actions"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>

      <!-- ... -->
    </Sheet>

    <Sheet
      v-model="mailTransactionSheet"
      width="30vw"
      no-padding
      @input="mailTransactionSheet = false"
    >
      <!-- title -->

      <template #title> Mail Transactions </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getMailTransactions()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <div class="q-pl-sm">
          <Tabs v-model="mailTab" :tabs="mailTabs" />
        </div>
        <template v-if="mailTab === 'OUTGOING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in outgoingMails">
              <div :key="'outgoing' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
        <template v-if="mailTab === 'INCOMING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in incomingMails">
              <div :key="'incoming' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
      </div>

      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="mailAttachmentSheet"
      width="30vw"
      no-padding
      @input="mailAttachmentSheet = false"
    >
      <!-- title -->

      <template #title> Mail Attachments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 200px)">
          <template v-for="file in mailAttachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row">
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openAttachmentFile(file)">
                  {{ file.name }}
                </div>
              </div>

              <div class="meta">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- size -->

                <template v-if="file.size">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ fileSize(file.size) }}</div>
                </template>

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myAttachmentFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <BaseButton
          label="Post Comments"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { custom, form, workflow, report, menu } from "@/api/factory.js";
import { ValidationProvider } from "vee-validate";
// import ThemeSwitcher from "@/layouts/app/components/toolbar/components/ThemeSwitcher.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import AddContract from "./components/AddContract.vue";
import Modal from "@/components/common/popup/Modal.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";
import { pdfSupport, htmlSupport } from "@/helpers/file-format.js";
import CustomProcessForm from "./CustomProcessForm.vue";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";

export default {
  name: "CustomForm",

  components: {
    ValidationProvider,
    // ThemeSwitcher,
    ExpandableSearch,
    FormFieldLabel,
    TextField,
    MultiSelectField,
    SelectField,
    DateField,
    AddContract,
    Modal,
    Sheet,
    Tabs,
    FileIcon,
    CustomProcessForm,
    ProcessHistory,
    TextAreaField,
    TextBuilderField,
  },

  props: {
    workflowId: {
      type: Number,
      default: 0,
    },

    workflow: {
      type: Object,
      default: () => {},
    },

    formId: {
      type: [String, Number],
      default: 0,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    saveAction: {
      type: String,
      default: "",
    },

    workflowFormModel: {
      type: Object,
      default: () => {},
    },

    actions: {
      type: Array,
      default: () => [],
    },

    requestPrefix: {
      type: String,
      default: "",
    },

    mlPrediction: {
      type: String,
      default: "",
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    generatePDF: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      form: null,
      formWidth: "100%",
      lender: [],
      firstName: "",
      lastName: "",
      dob: "",
      province: "",
      claimNumber: "",
      contractNumber: "",
      refNumber: "",
      contractColumns: [
        {
          id: this.$nano.id(),
          name: "firstName",
          label: "First Name",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: true,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "lastName",
          label: "Last Name",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: true,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "loanNumber",
          label: "Loan Number",
          subLabel: "Loan No.",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: true,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "dob",
          label: "DOB",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "date",
          main: true,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "currentAge",
          label: "Current Age",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "number",
          required: false,
          main: true,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "policyNumber",
          label: "Policy Number",
          subLabel: "Group Policy",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: true,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "email",
          label: "Email",
          subLabel: "Email ID",
          type: "NORMAL",
          editable: true,
          valueType: "email",
          required: true,
          main: true,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "address1",
          label: "Address1",
          subLabel: "Address",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "address2",
          label: "Address2",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "city",
          label: "City",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "province",
          label: "Province",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "postalCode",
          label: "Postal Code",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "phone",
          label: "Phone",
          subLabel: "Phone No.",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "startDate",
          label: "Start Date",
          subLabel: "Loan Start Date",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "endDate",
          label: "End Date",
          subLabel: "Loan End Date",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "cancelDate",
          label: "Cancel Date",
          subLabel: "Cancellation Date",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "contractNumber",
          label: "Contract Number",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "product",
          label: "Product",
          subLabel: "Benefit Claimed",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: true,
        },
        {
          id: this.$nano.id(),
          name: "loanNumber",
          label: "Loan No.",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: false,
        },
        {
          id: this.$nano.id(),
          name: "paymentFrequency",
          label: "Payment Freq",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: false,
        },
        {
          id: this.$nano.id(),
          name: "term",
          label: "Term",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: false,
        },
        {
          id: this.$nano.id(),
          name: "remitDate",
          label: "Last Remitted Date",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: false,
        },
        {
          id: this.$nano.id(),
          name: "reason",
          label: "Reason",
          subLabel: "",
          type: "NORMAL",
          editable: false,
          valueType: "text",
          required: false,
          main: false,
          visible: false,
        },
      ],
      claimColumns: [
        {
          id: this.$nano.id(),
          name: "claimsStoreLoanNumber",
          label: "Claims Store Loan Number",
          store: "Loan Number",
          type: "NORMAL",
          editable: false,
          main: true,
        },
        {
          id: this.$nano.id(),
          name: "claimNumber",
          label: "Claim Number",
          store: "Claim Number",
          type: "NORMAL",
          editable: false,
          main: true,
        },
        {
          id: this.$nano.id(),
          name: "claimantFirstName",
          label: "Claimant First Name",
          store: "First Name",
          type: "NORMAL",
          editable: false,
          main: true,
        },
        {
          id: this.$nano.id(),
          name: "claimantLastName",
          label: "Claimant Last Name",
          store: "Last Name",
          type: "NORMAL",
          editable: false,
          main: true,
        },
        {
          id: this.$nano.id(),
          name: "claimantDOB",
          label: "Claimant DOB",
          store: "DOB",
          type: "NORMAL",
          editable: false,
          main: true,
        },
        {
          id: this.$nano.id(),
          name: "claimantEmail",
          label: "Claimant Email",
          store: "Email",
          type: "NORMAL",
          editable: true,
          main: true,
        },
        {
          id: this.$nano.id(),
          name: "claimStatus",
          label: "Claim Status",
          store: "",
          type: "NORMAL",
          editable: false,
          main: true,
        },
        {
          id: this.$nano.id(),
          name: "history",
          label: "",
          store: "",
          type: "NORMAL",
          editable: false,
          main: true,
        },
        {
          id: this.$nano.id(),
          name: "branchNumber",
          label: "Branch Number",
          store: "",
          type: "NORMAL",
          editable: false,
          main: false,
        },
        {
          id: this.$nano.id(),
          name: "claimDate",
          label: "Claim Date",
          store: "",
          type: "NORMAL",
          editable: false,
          main: false,
        },
        {
          id: this.$nano.id(),
          name: "receiveDate",
          label: "Receive Date",
          store: "",
          type: "NORMAL",
          editable: false,
          main: false,
        },
        {
          id: this.$nano.id(),
          name: "recommendation",
          label: "Recommendation",
          store: "",
          type: "NORMAL",
          editable: false,
          main: false,
        },
        {
          id: this.$nano.id(),
          name: "product",
          label: "Product",
          store: "Product",
          type: "NORMAL",
          editable: false,
          main: false,
        },
        {
          id: this.$nano.id(),
          name: "claimantCity",
          label: "Claimant City",
          store: "City",
          type: "NORMAL",
          editable: false,
          main: false,
        },
        {
          id: this.$nano.id(),
          name: "claimantProv",
          label: "Claimant Prov",
          store: "Province",
          type: "NORMAL",
          editable: false,
          main: false,
        },
        {
          id: this.$nano.id(),
          name: "currentAge",
          label: "Current Age",
          store: "Current Age",
          type: "NORMAL",
          editable: false,
          main: false,
        },
      ],
      tableContracts: [],
      tableContractsData: [],
      tableClaims: [],
      tableClaimsData: [],
      contractExpand: true,
      claimExpand: true,
      selectedContract: {},
      selectedClaim: {},
      search: "",
      addContractSheet: false,
      productModal: false,
      products: [],
      productList: [
        {
          id: this.$nano.id(),
          label: "Critical Illness",
          value: "Critical Illness",
        },
        {
          id: this.$nano.id(),
          label: "Disability",
          value: "Disability",
        },
        {
          id: this.$nano.id(),
          label: "Involuntary Unemployment",
          value: "Involuntary Unemployment",
        },
        {
          id: this.$nano.id(),
          label: "Life",
          value: "Life",
        },
        {
          id: this.$nano.id(),
          label: "Lifetime Milestone",
          value: "Lifetime Milestone",
        },
        {
          id: this.$nano.id(),
          label: "Sickness or Injury",
          value: "Sickness or Injury",
        },
        {
          id: this.$nano.id(),
          label: "Unpaid Family Leave",
          value: "Unpaid Family Leave",
        },
      ],
      fieldsModel: {},
      historyColumns: [
        {
          id: this.$nano.id(),
          name: "claimNumber",
          label: "Claim Number",
        },
        {
          id: this.$nano.id(),
          name: "status",
          label: "Status",
        },
        {
          id: this.$nano.id(),
          name: "date",
          label: "Date",
        },
        {
          id: this.$nano.id(),
          name: "note",
          label: "Note",
        },
      ],
      claimHistoryData: [],
      claimHistoryModal: false,
      lenderList: [],
      totalItems: 0,
      itemsPerPage: 500,
      currentPage: 1,
      contractDataResult: false,
      claimDataResult: false,
      selectedProcess: {},
      processSheet: false,
      headerTitle: "",
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "mail transaction",
          value: "MAIL",
          icon: "mdi-email-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
      ],
      mailTabs: [
        {
          id: this.$nano.id(),
          label: "outgoing",
          value: "OUTGOING",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "incoming",
          value: "INCOMING",
          count: 0,
        },
      ],
      mailTab: "OUTGOING",
      attachmentSheet: false,
      commentsSheet: false,
      mailTransactionSheet: false,
      commentText: "",
      commentsList: [],
      attachmentList: [],
      mailTransactionList: [],
      mailAttachmentSheet: false,
      mailAttachmentList: [],
      fileSheet: false,
      selectedFile: {},
      formModel: {},
      historyProcessId: 0,
      checkList: [],
      advancedCommentsSheet: false,
      advancedCommentText: "",
    };
  },

  computed: {
    isMaximized() {
      return this.formWidth === "100%";
    },

    _logo() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return require(`@/assets/logo/psg-logo.png`);
      }
      return require(`@/assets/logo/name-${
        this.darkTheme ? "dark" : "light"
      }.png`);
    },

    contractCount() {
      return `Contracts (${this.tableContracts.length})`;
    },

    claimCount() {
      return `Claims (${this.tableClaims.length})`;
    },

    contractColumnsFilter() {
      return this.contractColumns.filter((col) => col.main && col.visible);
    },

    contractColumnsInfo() {
      return this.contractColumns.filter((col) => !col.main && col.visible);
    },

    claimColumnsFilter() {
      return this.claimColumns.filter((col) => col.main);
    },

    claimColumnsInfo() {
      return this.claimColumns.filter((col) => !col.main);
    },

    outgoingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Outgoing"
      );
    },

    incomingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Incoming"
      );
    },
  },

  watch: {},

  created() {
    let origin = location.origin;
    if (
      origin === "https://ag-appsvc01.azurewebsites.net" ||
      origin === "https://ag-appsvc05.azurewebsites.net" ||
      (origin === "https://trial.ezofis.com" &&
        this.$store.state.session.tenantId === 12) ||
      origin === "http://localhost:8080"
    ) {
      this.getLenderMaster(3);
    } else {
      this.getLenderMaster(3056);
    }
  },

  methods: {
    toggleWidth() {
      this.formWidth = this.formWidth === "100%" ? "720px" : "100%";
    },

    calculateAge(birthDate) {
      if (!birthDate) return;

      const currentDate = new Date();
      if (new Date(birthDate) > currentDate) {
        return "";
      }

      const diffTime = currentDate - new Date(birthDate);
      const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      return Math.floor(totalDays / 365.25);
    },

    claimStatus(item) {
      if (
        item.claimStatus.indexOf("Declined") > -1 ||
        item.claimStatus.indexOf("Force Close") > -1
      ) {
        return true;
      }
      return false;
    },

    isArrayField(fieldType) {
      return ["MULTIPLE_CHOICE", "FILE_UPLOAD", "MULTI_SELECT"].includes(
        fieldType
      );
    },

    searchRow(search) {
      if (search) {
        this.tableContracts = this.tableContractsData.filter((row) => {
          for (let cell in row) {
            let index = this.contractColumns.findIndex((column) => {
              return column.name === cell;
            });
            if (index >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                return row;
              }
            }
          }
        });
        this.tableClaims = this.tableClaimsData.filter((row) => {
          for (let cell in row) {
            let index = this.claimColumns.findIndex((column) => {
              return column.name === cell;
            });
            if (index >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                return row;
              }
            }
          }
        });
      } else {
        this.tableContracts = this.tableContractsData;
        this.tableClaims = this.tableClaimsData;
      }
    },

    processInitiate(newContract) {
      let lenderCode = "",
        lenderEmail = "",
        lenderName = "";

      if (newContract) {
        this.selectedContract = newContract;
      } else {
        this.selectedContract.product = this.products;
      }
      let tickets = [];
      if (this.selectedContract.product.length) {
        this.selectedContract.product.forEach((product) => {
          if (this.panels.length) {
            if (this.selectedContract.policyNumber) {
              let policyNumberSplit =
                this.selectedContract.policyNumber.split("-");
              if (!isNaN(policyNumberSplit[1][2])) {
                lenderCode = policyNumberSplit[1].substring(0, 2);
              } else {
                lenderCode = policyNumberSplit[1].substring(0, 3);
              }
            } else if (this.selectedContract.lender) {
              lenderCode = this.selectedContract.lender;
            }
            let selectedLender = this.lenderList.filter(
              (row) => row.value === lenderCode
            );
            if (selectedLender.length) {
              lenderEmail = selectedLender[0].email;
              lenderName = selectedLender[0].label;
            }
            this.fieldsModel = {};
            this.panels.forEach((panel) => {
              if (!panel.fields.length) {
                return;
              }
              for (let field of panel.fields) {
                if (field.label === "Lender Email") {
                  this.fieldsModel[field.id] = lenderEmail;
                } else if (field.label === "Lender Name") {
                  this.fieldsModel[field.id] = lenderName;
                } else if (field.label === "Initials1") {
                  let initials = "";
                  if (this.$store.state.session.firstName) {
                    initials +=
                      this.$store.state.session.firstName[0].toUpperCase();
                  }
                  if (this.$store.state.session.lastName) {
                    initials +=
                      this.$store.state.session.lastName[0].toUpperCase();
                  } else {
                    initials += this.$store.state.session.firstName[1];
                  }
                  this.fieldsModel[field.id] = initials;
                } else {
                  let cols = this.contractColumns.find(
                    (col) =>
                      col.label === field.label || col.subLabel === field.label
                  );

                  if (cols) {
                    if (cols.name === "product") {
                      this.fieldsModel[field.id] = product;
                    } else {
                      this.fieldsModel[field.id] =
                        this.selectedContract[cols.name] || "";
                    }
                  }
                }
              }
            });
          }
          tickets.push(this.fieldsModel);
        });
      }

      // this.$emit("update:workflowFormModel", this.fieldsModel);
      // this.$emit("update:requestPrefix", lenderCode);
      // this.$emit("update:mlPrediction", lenderName);

      if (this.actions.length) {
        this.initiateRequest(tickets, lenderCode, lenderName, this.actions[0]);
        // this.$emit("update:saveAction", this.actions[0]);
      } else {
        this.$alert.error("Process Configuration Error");
      }
    },

    async initiateRequest(tickets, lenderCode, lenderName, actions) {
      let requestNo = [];
      if (tickets && tickets.length) {
        for (let model of tickets) {
          let inputData = {
            workflowId: this.workflow.id,
            review: actions === "Save" ? "" : actions,
            comments: this.commentsList,
            formData: {
              formId: this.formId,
              fields: model,
              formUpload: [],
            },
            fileIds: [],
            task: [],
            hasFormPDF: this.generatePDF,
            prefix: lenderCode,
            mlPrediction: lenderName,
          };

          this.$store.commit("showLoadingBarSave");
          const { error, payload } = await workflow.processTransaction(
            inputData
          );
          this.$store.commit("hideLoadingBarSave");
          this.saveAction = "";
          this.fieldsModel = {};

          if (error) {
            this.$alert.error(error);
            return;
          }

          requestNo.push(payload.requestNo);
        }
      }

      if (requestNo.length) {
        if (actions !== "Save") {
          this.$alert.success(`${requestNo.toString()} Request Initiated`);
        } else {
          this.$alert.success(
            `${requestNo.toString()} Request Initiated and Moved to your inbox`
          );
        }
      }

      this.$emit("refresh");
    },

    newClaim(id) {
      let lenderCode = "",
        lenderEmail = "",
        lenderName = "";
      this.selectedClaim = this.tableClaims.find((item) => item.id === id);
      if (this.panels.length) {
        this.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          if (this.selectedClaim.claimNumber) {
            let claimNumberSplit = this.selectedClaim.claimNumber.split("-");
            if (!isNaN(claimNumberSplit[1][2])) {
              lenderCode = claimNumberSplit[1].substring(0, 2);
            } else {
              lenderCode = claimNumberSplit[1].substring(0, 3);
            }
          }
          let selectedLender = this.lenderList.filter(
            (row) => row.value === lenderCode
          );
          if (selectedLender.length) {
            lenderEmail = selectedLender[0].email;
            lenderName = selectedLender[0].label;
          }
          for (let field of panel.fields) {
            if (field.label === "Lender Email") {
              this.fieldsModel[field.id] = lenderEmail;
            } else if (field.label === "Product") {
              this.fieldsModel[field.id] =
                this.selectedClaim.product.split(",") || "";
            } else if (field.label === "Lender Name") {
              this.fieldsModel[field.id] = lenderName;
            } else if (field.label === "Initials") {
              this.fieldsModel[field.id] = lenderCode;
            } else {
              let cols = this.claimColumns.find(
                (col) => col.store === field.label
              );
              if (cols) {
                this.fieldsModel[field.id] =
                  this.selectedClaim[cols.name] || "";
              }
            }
          }
        });
      }
      this.$emit("update:workflowFormModel", this.fieldsModel);
      this.$emit("update:requestPrefix", lenderCode);
      this.$emit("update:mlPrediction", lenderName);
      if (this.actions.length) {
        this.$emit("update:saveAction", this.actions[0]);
      } else {
        this.$alert.error("Process Configuration Error");
      }
    },

    async viewClaim(id) {
      this.formModel = {};
      this.selectedProcess = {};
      this.selectedClaim = this.tableClaims.find((item) => item.id === id);
      this.$store.commit("showLoadingBarSave");
      this.workflowProcessList = [];
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "requestNo",
                condition: "IS_EQUALS_TO",
                value: this.selectedClaim.claimNumber,
              },
            ],
          },
        ],
        itemsPerPage: 10,
        currentPage: 1,
        mode: "",
      });
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.data.length) {
        if (payload.data[0].value.length) {
          this.selectedProcess = payload.data[0].value[0];
          this.headerTitle = `${this.selectedProcess.requestNo} - ${this.selectedProcess.stage}`;
          let keys = Object.keys(this.selectedProcess.formData.fields);
          let newValues = {};
          keys.forEach((key) => {
            newValues[key] = this.validateTypeValue(
              this.selectedProcess.formData.fields[key]
            );
          });
          this.formModel = newValues;
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.selectedProcess.commentsCount;
            } else if (tab.value === "ATTACHMENTS") {
              tab.count = this.selectedProcess.attachmentCount;
            }
          });
          let wflowJson = JSON.parse(this.workflow.flowJson);

          wflowJson.blocks.forEach((block) => {
            if (block.id === this.selectedProcess.activityId) {
              if (block.settings.fileSettings) {
                if (block.settings.fileSettings.fileCheckList.length) {
                  this.checkList = block.settings.fileSettings.fileCheckList;
                  this.checkList.forEach((row) => {
                    row.uid = this.$nano.id();
                    row.attach = false;
                  });
                }
              }
            }
          });
          this.getComments();
          this.getAttachments();
          this.getMailTransactions();
          this.processSheet = true;
        }
      } else {
        this.$alert.info("No Details Found");
      }
    },

    async reopenClaim(id) {
      this.selectedClaim = this.tableClaims.find((item) => item.id === id);
      this.$store.commit("showLoadingBarSave");
      const { error } = await workflow.reopenRequest(1, 0, {
        requestNumber: this.selectedClaim.claimNumber,
      });
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        console.log(error);
        //this.$alert.error(error);
        this.$alert.info(error);
        return;
      }

      this.$alert.success(
        `${this.selectedClaim.claimNumber} request re-initiated`
      );
    },

    async getLenderMaster(formId) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getFormEntries(formId, {
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [],
        itemsPerPage: 0,
        currentPage: 0,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;

      this.lenderList = data[0].value.map((entry) => ({
        id: entry.itemid,
        label: entry["xPAsoGQlJ_xrm5f3EAeo6"],
        value: entry["aeyluPk9Q8w6NaUs0HO8r"],
        email: entry["XsPSz3d4dF2VSz1Svmymv"],
      }));
    },

    async getProduct(id) {
      this.selectedContract = this.tableContracts.find(
        (item) => item.id === id
      );

      if (Object.keys(this.selectedContract).length) {
        let lenderCode = "";
        if (this.selectedContract.policyNumber) {
          let policyNumberSplit = this.selectedContract.policyNumber.split("-");
          if (!isNaN(policyNumberSplit[1][2])) {
            lenderCode = policyNumberSplit[1].substring(0, 2);
          } else {
            lenderCode = policyNumberSplit[1].substring(0, 3);
          }
        } else if (this.selectedContract.lender) {
          lenderCode = this.selectedContract.lender;
        }
        if (!lenderCode) {
          this.$alert.warning("Policy Number Not Found");
          return;
        }
        if (!this.selectedContract.email) {
          this.$alert.warning("Required Claimant Email");
          return;
        }
        this.products = [];
        if (
          this.selectedContract.product &&
          !this.products.includes(this.selectedContract.product)
        ) {
          this.products.push(this.selectedContract.product);
        }
        this.productModal = true;
      }
    },

    async claimHistory(item) {
      this.claimHistoryData = [];
      let lenderCode = "",
        lenderName = "";
      if (item.claimNumber) {
        let claimNumberSplit = item.claimNumber.split("-");
        if (!isNaN(claimNumberSplit[1][2])) {
          lenderCode = claimNumberSplit[1].substring(0, 2);
        } else {
          lenderCode = claimNumberSplit[1].substring(0, 3);
        }
      }
      let selectedLender = this.lenderList.filter(
        (row) => row.value === lenderCode
      );
      if (selectedLender.length) {
        lenderName = selectedLender[0].label;
      }
      this.$store.commit("showLoadingBarSave");

      let input = {
        claimNumber: item.claimNumber,
        lenderName: lenderName,
      };
      const { payload, error } = await custom.getClaimHistory(input);

      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.claimHistoryData = payload.map((item) => ({
          id: item.ClaimStatusID,
          claimNumber: item.CSClaimNumber,
          status: item.ClaimStatus,
          date: this.$day.format(item.CSDate, false),
          note: item.CSNote,
        }));
        this.claimHistoryModal = true;
      } else {
        this.$alert.info("Claim history records not found");
      }
    },

    getData() {
      this.tableContracts = [];
      this.tableContractsData = [];
      this.tableClaims = [];
      this.tableClaimsData = [];
      this.contractDataResult = false;
      this.claimDataResult = false;
      this.getContractData();
      this.getClaimData();
    },

    async getContractData() {
      if (
        this.firstName ||
        this.lastName ||
        this.dob ||
        this.province ||
        this.contractNumber ||
        this.refNumber
      ) {
        let condition = {};
        if (this.firstName) {
          condition["FirstName"] = this.firstName;
        }
        if (this.lastName) {
          condition["LastName"] = this.lastName;
        }
        if (this.dob) {
          condition["DOB"] = this.dob;
        }
        if (this.province) {
          condition["Province"] = this.province;
        }
        if (this.contractNumber) {
          condition["StoreLoanNumber"] = this.contractNumber;
        }
        if (this.refNumber) {
          //condition[""] = this.refNumber;
        }

        let lenderName = "";
        if (this.lender.length) {
          let selectedLender = this.lenderList.filter(
            (row) => row.value === this.lender[0]
          );
          if (selectedLender.length) {
            lenderName = selectedLender[0].label;
          }
        }

        this.$store.commit("showLoadingBarSave");
        let input = {
          hublinkId: 1,
          lenderName: lenderName,
          payloadMapping: JSON.stringify(condition),
          itemsPerPage: 500,
          currentPage: 1,
        };
        const { payload, error } = await custom.getDataSync(input);

        this.$store.commit("hideLoadingBarSave");
        this.contractDataResult = true;

        if (error) {
          if (error === "Connection Interrupted") {
            this.$alert.warning(error);
          } else {
            this.$alert.error(error);
          }
          return;
        }

        const { data, meta } = payload;

        if (meta.totalItems) {
          if (data.length) {
            if (data[0].value.length) {
              this.tableContracts = data[0].value.map((item) => ({
                id: this.$nano.id(),
                firstName: item.FirstName,
                lastName: item.LastName,
                loanNumber: item.LoanNumber,
                dob: item.DOB ? item.DOB.split(" ")[0] : "",
                policyNumber: item.PolicyNumber,
                lender: "",
                email: item.Email || "",
                currentAge: item.DOB
                  ? this.calculateAge(item.DOB.split(" ")[0])
                  : "",
                address1: item.Address1,
                address2: item.Address2,
                city: item.City,
                province: item.Province,
                postalCode: item.PostalCode,
                phone: item.Phone,
                startDate: item.StartDate ? item.StartDate.split(" ")[0] : "",
                endDate: item.EndDate ? item.EndDate.split(" ")[0] : "",
                cancelDate: item.CancelDate
                  ? item.CancelDate.split(" ")[0]
                  : "",
                contractNumber: item.StoreLoanNumber,
                product: item.Product,
                paymentFrequency: item.PmtFrq,
                term: item.Term,
                remitDate: item.RemitDate ? item.RemitDate.split(" ")[0] : "",
                reason: item.Comments,
                infoVisible: false,
              }));
              this.tableContractsData = this.tableContracts;
            }
          }
        } else {
          if (this.claimDataResult && this.tableClaimData.length === 0) {
            this.$alert.info("No result found");
          }
        }
      }
    },

    async getClaimData() {
      if (
        this.firstName ||
        this.lastName ||
        this.dob ||
        this.province ||
        this.claimNumber ||
        this.contractNumber
      ) {
        this.$store.commit("showLoadingBarSave");
        let condition = {};
        if (this.firstName) {
          condition["ClaimantFirstName"] = this.firstName;
        }
        if (this.lastName) {
          condition["ClaimantLastName"] = this.lastName;
        }
        if (this.dob) {
          condition["ClaimantDOB"] = this.dob;
        }
        if (this.province) {
          condition["ClaimantProv"] = this.province;
        }
        if (this.claimNumber) {
          condition["ClaimNumber"] = this.claimNumber;
        }
        if (this.contractNumber) {
          condition["ClaimsStoreLoanNumber"] = this.contractNumber;
        }

        let lenderName = "";
        if (this.lender.length) {
          let selectedLender = this.lenderList.filter(
            (row) => row.value === this.lender[0]
          );
          if (selectedLender.length) {
            lenderName = selectedLender[0].label;
          }
        }

        let input = {
          hublinkId: 2,
          lenderName: lenderName,
          payloadMapping: JSON.stringify(condition),
          itemsPerPage: 500,
          currentPage: 1,
        };
        const { payload, error } = await custom.getDataSync(input);

        this.$store.commit("hideLoadingBarSave");
        this.claimDataResult = true;
        if (error) {
          this.$alert.error(error);
          return;
        }

        const { data, meta } = payload;

        if (meta.totalItems) {
          if (data.length) {
            if (data[0].value.length) {
              this.tableClaims = data[0].value.map((item) => ({
                id: this.$nano.id(),
                claimsStoreLoanNumber: item.ClaimsStoreLoanNumber,
                claimNumber: item.ClaimNumber,
                claimantFirstName: item.ClaimantFirstName,
                claimantLastName: item.ClaimantLastName,
                claimantDOB: item.ClaimantDOB
                  ? item.ClaimantDOB.split(" ")[0]
                  : "",
                claimantEmail: item.ClaimantEmail,
                claimStatus: item.ClaimStatus || item.ClaimDecision || "",
                branchNumber: item.BranchNumber,
                claimDate: item.ClaimDate ? item.ClaimDate.split(" ")[0] : "",
                receiveDate: item.ReceiveDate
                  ? item.ReceiveDate.split(" ")[0]
                  : "",
                recommendation: item.Recommendation,
                product: item.Product,
                claimantCity: item.ClaimantCIty,
                claimantProv: item.ClaimantProv,
                currentAge: item.ClaimantDOB
                  ? this.calculateAge(item.ClaimantDOB.split(" ")[0])
                  : "",
                infoVisible: false,
              }));
              this.tableClaimsData = this.tableClaims;
            }
          }
        } else {
          if (this.contractDataResult && this.tableContractsData.length === 0) {
            this.$alert.info("No result found");
          }
        }
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    openFile(fileObj) {
      if (!fileObj.loading) {
        this.selectedFile = fileObj;
        window.open(this.fileLink());
      } else {
        this.$alert.info("Please wait to complete the uploading");
      }
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();

        if (pdfSupport(ext)) {
          let action = "";
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflowId}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}`;
          } else if (origin === "https://trial.ezofis.com") {
            return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflowId}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}${action}`;
          } else if (origin === "https://app.ezofis.com1") {
            return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflowId}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}${action}`;
          } else {
            return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflowId}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}${action}`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflowId}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}`;
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        }
      }
    },

    async showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        this.attachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.commentsSheet = true;
      } else if (tab === "HISTORY" && this.selectedProcess.processId) {
        this.historyProcessId = this.selectedProcess.processId;
      } else if (tab === "PRINT") {
        if (this.selectedProcess.formData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/form/generatePdfPrint/${this.$store.state.session.tenantId}/${this.workflowId}/${this.selectedProcess.processId}/${this.formId}/${this.selectedProcess.formData.formEntryId}/1`
          );
        } else if (this.selectedProcess.itemData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedProcess.itemData.repositoryId}/${this.selectedProcess.itemData.itemId}/2`
          );
        }
        //window.print();
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.$emit("addTask");
        }
      } else if (tab === "VALIDATION") {
        this.$emit("validation", this.selectedProcess.processId);
      } else if (tab === "PAYMENT_TABLE") {
        this.$emit("paymentTable");
      } else if (tab === "MAIL") {
        this.getMailTransactions();
        this.mailTransactionSheet = true;
      } else if (tab === "SUB_PROCESS") {
        this.subProcessSheet = true;
      }
    },

    lastActionDuration(request) {
      const date =
        request.transaction_createdAt instanceof Date
          ? request.transaction_createdAt
          : new Date(request.transaction_createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    attachmentFilterCheckList(name) {
      return this.attachmentList.filter(
        (row) =>
          row.name.substring(0, row.name.lastIndexOf(".")) === name ||
          row.name.substring(0, row.name.lastIndexOf(".")).split("_")[0] ===
            name
      );
    },

    isCheckList(name) {
      let list = this.checkList.filter(
        (row) =>
          row.name === name.substring(0, name.lastIndexOf(".")) ||
          row.name === name.substring(0, name.lastIndexOf(".")).split("_")[0]
      );
      if (list.length) {
        return false;
      }
      return true;
    },

    showMailAttachments(mail) {
      this.mailAttachmentList = [];
      let files = mail.attachmentJson.split(",");
      files.forEach((file) => {
        let filename = file.split("\\").pop();
        this.mailAttachmentList.push({
          id: this.$nano.id(),
          mailId: mail.id,
          name: filename,
          createdAt: mail.createdAt,
        });
      });
      this.mailAttachmentSheet = true;
    },

    openAttachmentFile(file) {
      let link = `${process.env.VUE_APP_API_URL}/client/viewAttachment/${this.$store.state.session.tenantId}/${file.mailId}/${file.name}`;
      window.open(link, "_blank");
    },

    fileMenuAction(fileObj, action) {
      this.selectedFile = fileObj;
      this.documentAction(action, true);
    },

    documentAction(action, viewPrint) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          if (viewPrint) {
            let link = "";
            if (this.selectedFile.initiate) {
              let type = 1;
              if (this.repositoryDetails.fieldsType === "STATIC") {
                type = 2;
              }
              link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/1`;
            } else {
              link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
            }
            window.open(link, "_blank");
          } else {
            let ext = this.selectedFile.name.split(".").pop();
            if (pdfSupport(ext)) {
              localStorage.setItem("filePrint", true);
              localStorage.setItem("filePrintId", this.selectedFile.id);
            } else {
              this.$refs.myAttachmentFiles.contentWindow.print();
            }
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getMailTransactions() {
      this.mailTransactionList = [];
      this.tabs.find((tab) => {
        if (tab.value === "MAIL") {
          tab.count = 0;
        }
      });
      let filter = { filterBy: [] };
      const { error, payload } = await workflow.getMailTransactions(
        this.workflowId,
        this.selectedProcess.processId,
        filter
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.mailTransactionList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "MAIL") {
            tab.count = this.mailTransactionList.length;
          }
        });
      }
    },

    async getComments(workflowId = 0, processId = 0) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      if (!workflowId && !processId) {
        workflowId = this.workflowId;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async getAttachments(workflowId = 0, processId = 0) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      let count = this.attachmentList.length;

      if (!workflowId && !processId) {
        workflowId = this.workflowId;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getAttachments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          //clearInterval(this.attachmentLoad);
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = this.attachmentList.length;
          }
        });
      } else {
        this.attachmentList = [];
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          let filterAttach = this.attachmentList.filter((file) => {
            let filename = file.name.substr(0, file.name.lastIndexOf("."));
            if (row.name === filename || row.name === filename.split("_")[0]) {
              return file;
            }
          });
          if (filterAttach.length) {
            row.attach = true;
          } else {
            row.attach = false;
          }
        });
        let origin = location.origin;
        if (
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          origin === "http://localhost:8080" ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)
        ) {
          this.formCheckList = true;
        }
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.selectedProcess.processId) {
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.commentText,
              showTo: 2,
            }
          );

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.commentText = "";
          this.getComments();
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText && this.advancedCommentText != "<p></p>") {
        if (this.selectedProcess.processId) {
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.advancedCommentText,
              showTo: 2,
            }
          );

          if (error) {
            this.$alert.error(error);
            return;
          }
        }
        this.advancedCommentText = "";
        this.advancedCommentsSheet = false;
        this.getComments();
      } else {
        this.$alert.warning("Comments Text Required");
      }
    },

    async updateClaimDetails() {
      let inputData = {};
      inputData = {
        workflowId: this.workflow.id,
        transactionId: this.selectedProcess.transactionId,
        review: "",
        formData: {
          formId: this.formId,
          formEntryId: this.selectedProcess.formData.formEntryId,
          fields: this.formModel,
        },
      };
      const { error } = await workflow.processTransaction(inputData);
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Claim details are updated");
      this.processSheet = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#custom-form-view {
  margin: auto;
  padding: 16px;
  background-color: var(--body-bg-color);

  .logo img {
    height: 40px;
    width: auto;
  }

  .header {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .content {
    padding: 15px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    height: calc(100vh - 160px);

    .content-wrapper {
      border-top: 1px solid var(--divider-color);
      padding-top: 8px;
    }

    .searchBtn {
      margin-top: 33px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    &.subDetails {
      background: #eee;
    }

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
      #text-field {
        min-width: 250px;
      }

      .chips {
        cursor: pointer;
        background-color: #fff !important;
        margin: 0px !important;
        margin-right: 10px !important;
        margin-bottom: 10px !important;
        font-size: 12px !important;
      }

      .gray-hover:hover {
        background: $gray-2 !important;
      }

      .orange-hover:hover {
        background: $orange-2 !important;
      }

      .pink-hover:hover {
        background: $pink-2 !important;
      }
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }

  .tables #action-btn.small {
    width: 24px !important;
    height: 24px !important;
  }
}

#modal #claimHistory {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
        width: 70px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  .border-height {
    height: calc(-104px + 100vh);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }

  #action-btn.linkActions {
    width: 38px !important;
    height: 38px !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }
}

#sheet .attachmentList,
#sheet .taskEntries,
#sheet .checkList,
#sheet .mailTransactionList,
#sheet .subProcess {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}

#sheet .mailTransactionList {
  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }

  #action-btn.comment-actions {
    height: 28px !important;
    width: 28px !important;
  }
}
</style>
