<template>
  <AppLayout>
    <ViewWrapper
      title="folders"
      :breadcrumbs="breadcrumbs"
      :tabs="tabs"
      :tab="tab"
    >
      <div id="repository-reports">
        <Portal to="action-bar">
          <keep-alive>
            <ItemActions
              :id="repositoryId"
              item="repositories-activity-report"
              :columns="[]"
              group-by=""
              order=""
              :filter-by="[]"
              criteria=""
              active-view=""
              :hide-actions="[
                'toggle',
                'sort',
                'group',
                'filter',
                'activeView',
              ]"
              :simple-search-filter="true"
              :refresh="false"
              :export="false"
              :export-data="exportDataList"
              @refresh="refreshData"
              @search="searchRow"
              @export="export_Data"
              @filter="getData"
            />
          </keep-alive>
        </Portal>

        <SimpleFilter
          :id.sync="repositoryId"
          :workspace-id.sync="workspaceId"
          :columns="_columns"
          :filter-by.sync="filterBy"
          :workspace="true"
          name="Select Folder"
          :workspace-list="workspaceList"
          :list="repositoriesList"
          module="repository"
          :module-id="repositoryId"
          active-view="TABLE"
          :fid.sync="filterId"
          :w-id="workspaceId"
          :filter-list="WfilterList"
          @update="getRepositoryData"
          @saveData="saveSheet"
          @viewFilter="showFilterList = true"
        />

        <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />

        <SaveSheet
          v-model="issavesheet"
          :filter="filterBy"
          :repository-id="repositoryId"
          :page="'repositoryActivityReport'"
          :edit="editData"
          :fid="filterId"
          @success="success = true"
        />

        <div class="content">
          <div v-if="showFilterList && filterId === '-1'" class="col-12">
            <div>
              <Breadcrumbs :breadcrumbs="breadcrumb" @reset="homeRepository" />
            </div>
            <q-space />
          </div>
          <FilterListType
            v-if="showFilterList && filterId === '-1'"
            :mode="mode"
            :items="savedItems"
            :columns="columns"
            :filter-list="savedList"
            @view="openFilter"
            @isDelete="deleteItem"
            @isSavesheet="edit"
          />

          <template
            v-else-if="repositoryReportList.length || filterId === '-1'"
          >
            <ReportList
              :mode="mode"
              :items="items"
              :columns="columns"
              @view="openFile"
            />
          </template>

          <template v-else-if="!loadingBarContent">
            <StateWrapper
              icon="eva-archive-outline"
              title="Generate Report"
              description="Select workspace and repository to display data"
              style="margin-top: 20px"
            />
          </template>
        </div>

        <Pagination
          v-if="repositoryReportList.length || filterId === '-1'"
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
          :file-sheet="hideFileSheet"
          @loadAll="loadAll"
        />

        <FileSheet
          v-model="isFileSheetVisible"
          :file="selectedFile"
          :repository-field="repositoryField"
          :options="options"
        />
      </div>

      <ConfirmDelete
        v-model="isDeleteConfirmationVisible"
        :delete-data="deleteData"
        @delete="success = true"
      />
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import { Portal } from "portal-vue";
import {
  repository,
  user,
  report,
  workspace,
  dashboard,
} from "@/api/factory.js";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ReportList from "./components/ReportList.vue";
import DataType from "@/constants/data-type.js";
import SimpleFilter from "@/components/common/display/item-actions/components/SimpleFilter.vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import { exportData } from "@/helpers/export-excel-data";
import FileSheet from "../repository-browse-and-trash/components/FileSheet.vue";
import formatBytes from "@/helpers/format-bytes.js";
import SaveSheet from "@/components/common/display/item-actions/components/SaveSheet.vue";
import Breadcrumbs from "@/views/repositories/views/repository-browse-and-trash/components/Breadcrumbs.vue";
import FilterListType from "@/components/common/display/item-actions/components/FilterListType.vue";
import ConfirmDelete from "@/components/common/display/item-actions/components/DeleteSaveItems.vue";

export default {
  name: "ActivityReports",

  components: {
    Portal,
    ItemActions,
    ReportList,
    SimpleFilter,
    StateWrapper,
    Pagination,
    FileSheet,
    AppLayout,
    ViewWrapper,
    SaveSheet,
    Breadcrumbs,
    FilterListType,
    ConfirmDelete,
  },

  data() {
    return {
      mode: "REPORT",
      sortBy: {
        criteria: "",
        order: "ASC",
      },
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "folders",
          route: "repositories-browse",
        },
        {
          id: this.$nano.id(),
          label: "reports",
          route: "repositories-reports-overview",
        },
        {
          id: this.$nano.id(),
          label: "activity",
          route: "",
        },
      ],
      tabs: [
        {
          id: this.$nano.id(),
          label: "overview",
          icon: "mdi-view-dashboard-outline",
          route: "repositories-overview",
          access: false,
          accessLabel: "Overview",
        },
        {
          id: this.$nano.id(),
          label: "browse",
          icon: "eva-folder-outline",
          route: "repositories-browse",
          access: true,
          accessLabel: "Manage Folders",
        },
        {
          id: this.$nano.id(),
          label: "trash",
          icon: "eva-trash-2-outline",
          route: "repositories-trash",
          access: true,
          accessLabel: "Manage Folders",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "deleted files",
        //   icon: "eva-trash-2-outline",
        //   route: "file-trash",
        //   access: false,
        //   accessLabel: "Manage Folders",
        // },
        {
          id: this.$nano.id(),
          label: "reports",
          icon: "eva-trash-2-outline",
          route: "activity-reports",
          access: false,
          accessLabel: "Audit Report",
        },
      ],
      tab: "activity-reports",
      columns: [],
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      hideFileSheet: false,
      userList: [],
      workspaceId: 0,
      workspaceList: [],
      itemId: 0,
      repositoryId: 0,
      repositoriesList: [],
      reportList: [],
      repositoryReportList: [],
      repositoryReportListData: [],
      repositoryDetails: {},
      repositoryField: [],
      options: {},
      selectedFile: {},
      isFileSheetVisible: false,
      loadingBarContent: false,
      exportDataList: [],
      activities: [],
      issavesheet: false,
      showFilterList: false,
      WfilterList: [],
      filterId: "",
      savedList: [],
      savedListData: [],
      selectedIds: 0,
      listedData: [],
      filterListData: [],
      filterFrom: "",
      isDeleteConfirmationVisible: false,
      deleteData: {},
      success: false,
      editData: [],
      breadcrumb: [],
    };
  },

  computed: {
    _columns() {
      let columns = [
        {
          id: this.$nano.id(),
          name: "ifileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },

        {
          id: this.$nano.id(),
          name: "activityId",
          label: "Activity",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "activityUserEmail",
          label: "Activity User Email",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "activityOn",
          label: "Activity On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "filesize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "version",
          label: "Version",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
      ];
      this.repositoryField.forEach((field) => {
        columns.push({
          id: this.$nano.id(),
          label: field.name,
          name: field.name,
          dataType: field.dataType,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        });
      });
      columns[1].options = this.activities;
      columns[2].options = this.userList;
      return columns;
    },

    items() {
      return this.repositoryReportList.map(({ key, value }) => ({
        key: "",
        value: key,
        data: value.map((item) => ({
          icon: "mdi-text-box",
          id: item.rNo,
          itemId: item.itemId,
          link: true,
          ifileName: item.ifileName,
          activity: item.activity,
          email: item.email,
          activityOn: this.$day.format(item.activityOn),
          filesize: item.ifileSize ? formatBytes(item.ifileSize) : "",
          createdAt: this.$day.format(item.createdAt),
          version: item.fileVersion,
          ...this.getFileData(item),
        })),
      }));
    },

    savedItems() {
      return this.savedListData.map(({ key, value }) => ({
        key: "",
        value: key,
        data: value.map((item) => ({
          icon: "mdi-text-box",
          id: item.id,
          itemId: item.itemId,
          link: true,
          name: item.name,
          description: item.description,
          createdBy: item.createdByEmail,
          assignTo: JSON.parse(item.assignTo).length,
          createdon: this.$day.format(item.createdAt),
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.tab = this.$route.name;
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },
    workspaceId: {
      deep: true,
      immediate: true,
      handler() {
        this.clearData();
        if (this.workspaceId) {
          this.getRepositories();
        }
      },
    },

    repositoryId: {
      deep: true,
      immediate: true,
      async handler() {
        this.repositoryReportList = [];
        if (this.repositoryId) {
          await this.getRepository();
          this.getRepositoryData();
        }
      },
    },

    itemsPerPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workspaceId && this.repositoryId && this.itemsPerPage) {
          this.getRepositoryData();
        } else if (this.savedListData && this.itemsPerPage) {
          this.getFilterData();
        }
      },
    },

    currentPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workspaceId && this.repositoryId && this.currentPage) {
          this.getRepositoryData();
        } else if (this.savedListData && this.itemsPerPage) {
          this.getFilterData();
        }
      },
    },

    filterId: {
      deep: true,

      immediate: true,

      async handler() {
        if (this.filterId === "") {
          this.repositoryReportList = [];
          this.workspaceId = 0;
          this.repositoryId = 0;
          this.filterBy = [];
        } else if (this.filterId === "-1") {
          this.breadcrumb = [];
          this.breadcrumb.push({
            id: 0,
            label: "Save Search",
            entity: "this.selectedEntity",
            type: "REPOSITORY ACTIVITY",
          });
          this.showFilterList = true;
          this.getFilterData();
        } else if (this.filterId) {
          this.showFilterList = false;
          this.filterList();
          this.selectedIds = this.filterId;
        } else if (!this.filterId) {
          this.showFilterList = false;
          this.repositoryReportList = [];
        }
      },
    },

    success: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.success) {
          await this.getFilterList();
          if (this.filterId === "-1") {
            this.getFilterData();
          }
        }
      },
    },
  },

  created() {
    this.getWorkspaces();
    this.getUsers();
    this.getActivityList();
    this.getFilterList();
  },

  methods: {
    refreshData() {
      if (this.workspaceId && this.repositoryId) {
        this.getRepositoryData();
      } else if (this.workspaceId) {
        this.getRepositories();
      }
    },

    searchRow(search) {
      if (search) {
        this.repositoryReportList = this.repositoryReportListData.map(
          ({ key, value }) => ({
            key: key,
            value: value.filter((row) => {
              for (let cell in row) {
                let index = this._columns.findIndex((column) => {
                  return column.name === cell;
                });

                if (index >= 0 && row[cell]) {
                  if (
                    String(row[cell])
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return row;
                  }
                }
              }
            }),
          })
        );
      } else {
        this.repositoryReportList = this.repositoryReportListData;
      }
    },

    async export_Data() {
      //this.$store.commit("showLoadingBarPage");
      this.loadingBarContent = true;
      const { error, payload } = await report.getRepositoryData(
        this.repositoryId,
        {
          itemId: this.itemId,
          filterBy: this.filterBy,
          itemsPerPage: 0,
          currentPage: 0,
        }
      );

      //this.$store.commit("hideLoadingBarPage");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      let tableData = [];
      if (payload) {
        payload.data.forEach((row) => {
          row.value.forEach((item) => {
            tableData.push({
              "File Name": item.ifileName,
              Activity: item.activity,
              "Activity User Email": item.email,
              "Activity On": this.$day.format(item.activityOn),
              Size: formatBytes(item.ifileSize),
              "Created At": this.$day.format(item.createdAt),
              Version: item.fileVersion,
              ...this.getFileData(item),
            });
          });
        });
      }

      if (tableData.length) {
        exportData(tableData, this.repositoryDetails.name + "-Activity Report");
      }
    },

    openFile(rowId) {
      let selectedList = this.repositoryReportList[0].value.find(
        (file) => file.rNo === rowId
      );
      this.selectedFile = {
        id: selectedList.itemId,
        name: selectedList.ifileName,
        size: selectedList.ifileSize,
        repositoryId: this.repositoryId,
        createdAt: selectedList.createdAt,
        createdBy: "",
      };
      this.isFileSheetVisible = true;
    },

    getFileData(item) {
      const data = {};
      this.repositoryField.forEach((field) => {
        data[field.name] = item[field.name];
      });
      return data;
    },

    loadAll() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    /* api Functions */

    async getWorkspaces() {
      //this.$store.commit("showLoadingBarPage");
      this.loadingBarContent = true;

      const { error, payload } = await workspace.getWorkspaceList();

      //this.$store.commit("hideLoadingBarPage");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.workspaceList = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.id,
      }));
    },

    async getRepositories() {
      if (this.workspaceId) {
        this.repositoriesList = [];
        // this.$store.commit("showLoadingBarPage");
        this.loadingBarContent = true;

        const { error, payload } = await repository.getRepositoryList(
          "workspaceId",
          this.workspaceId
        );

        this.loadingBarContent = false;
        // this.$store.commit("hideLoadingBarPage");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (!payload.length) {
          return;
        }
        this.repositoriesList = payload.map((repository) => ({
          id: this.$nano.id(),
          label: repository.value,
          value: repository.id,
        }));
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value || user.loginName,
            value: user.id,
          });
        });
      }
    },

    async getActivityList() {
      this.activities = [];
      let filter = {
        filterBy: [
          {
            filters: [
              {
                criteria: "activityType",
                condition: "IS_EQUALS_TO",
                value: "Document Report",
              },
            ],
            groupCondition: "",
          },
        ],
      };
      const { error, payload } = await report.activityList(filter);
      if (error) {
        this.$alert.error("Error fetching activity list");
        return;
      }

      if (payload) {
        payload.map((activity) => {
          this.activities.push({
            id: this.$nano.id(),
            label: activity.activityName,
            value: activity.id,
          });
        });
      }
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.repositoryDetails = payload;
      this.workspaceId = this.repositoryDetails.workspace.id;
      if (payload.options) {
        this.options = payload.options;
      }

      this.repositoryField = this.repositoryDetails.fields;
      this.columns = [
        {
          id: this.$nano.id(),
          name: "id",
          label: "Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "itemId",
          label: "Item Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "ifileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },

        {
          id: this.$nano.id(),
          name: "activity",
          label: "Activity",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "email",
          label: "Activity User Email",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "activityOn",
          label: "Activity On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "filesize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "version",
          label: "Version",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
      ];
      this.repositoryField.forEach((field) => {
        this.columns.push({
          id: this.$nano.id(),
          label: field.name,
          name: field.name,
          dataType: field.dataType,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        });
      });
    },

    async getRepositoryData() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBarPage");
      this.repositoryReportList = [];
      this.repositoryReportListData = [];
      this.list = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = row.arrayValue;
            }
          });
        }
      });
      const { error, payload } = await report.getRepositoryData(
        this.repositoryId,
        {
          itemId: this.itemId,
          filterBy: this.filterBy,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        }
      );
      // this.$store.commit("hideLoadingBarPage");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        if (payload.data.length) {
          payload.data.forEach((row) => {
            row.value.forEach((process) => {
              this.reportList.push({
                ...process,
              });
            });
          });
        }
      }

      this.totalItems = payload.meta?.totalItems || 0;

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.repositoryReportList = [];
        this.repositoryReportListData = [];
      } else {
        this.repositoryReportList = payload.data;
        this.repositoryReportListData = payload.data;
        if (this.repositoryReportList.length) {
          this.exportDataList = true;
        }
      }
    },

    async getData(fromDate, toDate) {
      const filterBy = [
        {
          id: this.$nano.id(),
          filters: [
            {
              id: this.$nano.id(),
              criteria: "activityOn",
              condition: "IS_EQUALS_TO",
              value: fromDate,
              valueTo: toDate,
            },
          ],
          groupCondition: "",
        },
      ];

      //this.$store.commit("showLoadingBarPage");
      this.repositoryReportList = [];
      this.repositoryReportListData = [];
      this.list = [];
      const { error, payload } = await report.getRepositoryData(
        this.repositoryId,
        {
          itemId: this.itemId,
          filterBy: filterBy,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        }
      );
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.data.length) {
        payload.data.forEach((row) => {
          row.value.forEach((process) => {
            this.reportList.push({
              ...process,
            });
          });
        });
      }

      this.totalItems = payload.meta?.totalItems || 0;

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.repositoryReportList = [];
        this.repositoryReportListData = [];
      } else {
        this.repositoryReportList = payload.data;
        this.repositoryReportListData = payload.data;
      }
    },

    clearData() {
      this.exportDataList = false;
      this.repositoryId = 0;
      this.repositoryReportList = [];
    },

    async getFilterData() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBarPage");
      this.savedList = [];
      this.savedListData = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = row.arrayValue;
            }
          });
        }
      });
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "repositoryActivityReport",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.columns = [
        {
          id: this.$nano.id(),
          name: "id",
          label: "Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "itemId",
          label: "Item Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdon",
          label: "Created On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "assignTo",
          label: "Assign To",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ];

      this.loadingBarContent = false;
      if (payload) {
        if (payload.data.length) {
          payload.data.forEach((row) => {
            row.value.forEach((process) => {
              this.reportList.push({
                ...process,
              });
            });
          });
        }
      }
      this.totalItems = payload.meta?.totalItems || 0;
      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.savedList = [];
        this.savedListData = [];
      } else {
        this.savedList = payload.data;
        this.savedListData = payload.data;
        if (this.savedList.length) {
          this.exportDataList = true;
        }
      }
    },

    async openFilter(rowId) {
      this.showFilterList = false;
      this.listedData.map((item) => {
        if (item.id === rowId) {
          const parsedArray = JSON.parse(item.filterJson);
          let filters = [];
          if (parsedArray[0].filters) {
            filters = JSON.parse(parsedArray[0].filters);
          }
          this.filterListData = {
            id: item.itemId,
            name: item.name,
            rId: parsedArray[0].uId,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });
      this.filterId = this.filterListData.name;
      console.log(this.filterId);
      this.repositoryId = this.filterListData.rId;
      this.filterFrom = this.filterListData.filterFrom;
      await this.getRepository();
      this.filterFrom = this.filterListData.filterFrom;
      this.repositoryId = this.filterListData.rId;
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      this.getRepositoryData();
    },

    async getFilterList() {
      this.loadingBarContent = true;
      this.success = false;
      let userId = this.$store.state.session.id.toString();

      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "repositoryActivityReport",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      this.loadingBarContent = false;
      //this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);

        return;
      }
      let data = payload.data[0].value;
      this.listedData = data;
      let List = data.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        value: filter.name,
      }));
      const removeObserver = (obj) => {
        const newObj = {};
        Object.keys(obj).forEach((key) => {
          if (key !== "__ob__") {
            newObj[key] = obj[key];
          }
        });

        return newObj;
      };
      const arrayOfObjects = List.map((obj) => removeObserver(obj));
      const viewAll = { id: this.$nano.id(), label: "Show All", value: "-1" };
      this.WfilterList = [
        ...(arrayOfObjects.length ? [viewAll] : []),

        ...arrayOfObjects,
      ];
    },

    async filterList() {
      this.listedData.map((item) => {
        if (item.name === this.filterId) {
          const parsedArray = JSON.parse(item.filterJson);
          let filters = [];
          if (parsedArray[0].filters) {
            filters = JSON.parse(parsedArray[0].filters);
          }
          this.filterListData = {
            id: item.itemId,
            name: item.name,
            rId: parsedArray[0].uId,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });

      this.repositoryId = this.filterListData.rId;
      this.filterFrom = this.filterListData.filterFrom;
      if (this.filterId !== "0") {
        await this.getRepository();
      }
      this.filterFrom = this.filterListData.filterFrom;
      this.repositoryId = this.filterListData.rId;
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      this.getRepositoryData();
    },

    deleteItem(items) {
      this.savedListData[0].value.map((item) => {
        if (item.id === items) {
          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },

    edit(items) {
      this.editData = [];
      this.savedListData[0].value.map((item) => {
        if (item.id === items) {
          this.issavesheet = true;
          this.editData = [
            {
              id: item.id,
              name: item.name,
              description: item.description,
              filterJson: item.filterJson,
              filterFrom: item.filterFrom,
              createdAt: item.createdAt,
              modifiedAt: item.modifiedAt,
              createdBy: item.createdBy,
              modifiedBy: item.modifiedBy,
              dashboard: item.pinDashboard,
              assignTo: item.assignTo,
            },
          ];
        }
      });
    },
    saveSheet() {
      this.editData = [];
      this.issavesheet = true;
    },
    homeRepository() {
      this.filterId = "0";
      this.getFilterList();
      this.breadcrumb = [];
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#repository-reports {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 300px);
  }

  .backGround {
    border: 2px;
    border-radius: 10px;
    background-color: #87dcf626;
    margin-bottom: 8px;
  }
}
</style>

<style lang="scss">
#select-field.fieldList {
  .select {
    min-height: 0px !important;
    color: $secondary !important;
    width: 140px !important;
    overflow: hidden !important;
  }
}

#text-field.textField {
  input {
    min-height: 35px;
    width: 140px;
  }
}

.loading-content {
  background-color: var(--body-bg-color) !important;
  position: absolute;
  z-index: 9999;
}
</style>
