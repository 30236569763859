<template>
  <div v-if="expand" id="repository-reports">
    <div
      style="cursor: pointer; border-bottom: 1px solid var(--divider-color)"
      class="row q-pb-sm"
      @click.stop="expand = false"
    >
      <span class="col-auto"
        ><BaseIcon
          v-tooltip.top="findIcon.iconTootip"
          :name="findIcon.icon"
          :color="findIcon.color"
          class="q-mr-sm q-pb-sm"
          size="20px" /></span
      ><span
        class="col text-base font-bold text-uppercase items-center text-black"
        >{{ findValue.name }}
        <div
          class="q-mt-xs text-base font-bold text-uppercase items-center"
          style="color: #475569; width: 100%"
        >
          {{ findValue.description }}
        </div></span
      >

      <div v-if="filterContains.length" class="icon-container">
        <BaseActionButton
          is-flat
          no-border
          icon="eva-eye"
          color="primary"
          class="q-ml-xs col-auto icon"
        />
        <div class="iconcontent" @click.stop="">
          <div
            v-for="(item, index) in filterContains"
            :key="index"
            class="col-12 q-mr-sm row items-center filerItem"
          >
            <span
              class="q-ml-sm q-mr-xs q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center"
              style="cursor: pointer; max-width: 100px; border-right: 0px"
            >
              {{ item.criteria }}</span
            >
            <span
              class="q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center"
              style="cursor: pointer; max-width: 40px; border-right: 0px"
            >
              {{ item.condition }}</span
            >
            <span
              class="q-mr-sm q-ml-sm q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center text-align-right"
              style="cursor: pointer; max-width: 100px; border-right: 0px"
            >
              {{ item.value }}</span
            >
          </div>
        </div>
      </div>

      <BaseActionButton
        v-tooltip.top="'refresh'"
        is-flat
        no-border
        icon="eva-refresh"
        class="q-ml-sm col-auto"
        @click.stop="refresh"
      />
      <BaseActionButton
        v-tooltip.top="'export'"
        is-flat
        no-border
        icon="mdi-table-arrow-right"
        class="q-ml-sm col-auto"
        @click.stop="export_Data"
      />
      <BaseActionButton
        v-tooltip.top="'close'"
        is-flat
        no-border
        icon="eva-arrow-ios-upward-outline"
        class="q-ml-sm col-auto"
        @click.stop="expand = false"
      />
      <BaseActionButton
        v-tooltip.bottom="'UnPin'"
        icon="mdi-pin"
        color="secondary"
        no-border
        class="col-auto"
        is-flat
        size="20px"
        rotate="rotate-45"
        @click.stop="deleteItem"
      />
    </div>

    <div v-if="loadingBarContent">
      <ProgressBar
        :container-height="'75vh'"
        :container-color="'white'"
        :progress-bars="progressBars"
      />
    </div>
    <div v-else>
      <div class="content">
        <DashboardFilterList
          v-if="
            repositoryReportList.length ||
            repositoryOcrReportListData.length ||
            repositorySignReportListData.length
          "
          :flength="length"
          :mode="mode"
          :items="items"
          :columns="columns"
          @view="openFile"
        />
        <ReportList
          v-else-if="workflowRequestList.length"
          :mode="mode"
          :items="items"
          :columns="columns"
          :lists="list"
          :form="form"
          @open="openProcess"
          @history="openHistory"
          @attachments="openAttachments"
          @comments="openComments"
          @viewFile="openFiles"
          @openSubWorkflow="updateSelectedSubWorkflow"
          @subHistory="showSubHistory"
          @subComments="openSubComments"
          @subAttachments="openSubAttachments"
        />
        <StateWrapper
          v-else
          icon="eva-archive-outline"
          title="Generate Report"
          description="Select workspace and repository to display data"
          style="margin-top: 20px"
        />
      </div>
      <Pagination
        v-if="
          repositoryReportList.length ||
          repositoryOcrReportListData.length ||
          workflowRequestList.length
        "
        :total-items="totalItems"
        :current-page.sync="currentPage"
        :items-per-page.sync="itemsPerPage"
        :file-sheet="hideFileSheet"
      />
      <FileSheet
        v-model="isFileSheetVisible"
        :file="selectedFile"
        :repository-field="repositoryField"
        :options="options"
      />
      <ProcessHistory
        v-if="historyProcessId"
        :process-id="historyProcessId"
        :selected-process="selectedProcess"
        @close="historyProcessId = 0"
      />

      <!-- comments -->

      <Sheet
        v-model="commentsSheet"
        width="30vw"
        height="80vh"
        custom-height="30"
        @input="closeCommentsSheet"
      >
        <!-- title -->

        <template #title> Comments </template>

        <!-- ... -->

        <!-- content -->
        <div id="content-wrapper">
          <BaseScrollbar height="calc(100vh - 155px)">
            <!-- comments -->
            <template v-for="(row, idx) in commentsList">
              <q-chat-message
                :key="idx"
                :name="
                  row.createdBy === $store.state.session.id
                    ? 'me'
                    : row.createdByEmail
                "
                :text="[row.comments]"
                text-html
                :sent="row.createdBy === $store.state.session.id"
                :stamp="lastCommentDuration(row)"
                :bg-color="
                  row.createdBy === $store.state.session.id
                    ? 'primary-1'
                    : 'secondary-2'
                "
                class="q-ma-sm"
              />
            </template>
            <!-- ... -->
          </BaseScrollbar>
        </div>

        <!-- ... -->
      </Sheet>
      <!-- Attachement -->
      <Sheet
        v-model="attachmentSheet"
        width="30vw"
        :upload="false"
        no-padding
        @input="closeAttachmentsSheet"
      >
        <!-- title -->

        <template #title> Attachments </template>

        <template #actions>
          <BaseActionButton
            v-tooltip.top="'refresh'"
            icon="mdi-refresh"
            color="secondary"
            class="q-mr-xs"
            no-border
            is-flat
            size="20px"
            @click="getAttachments"
          />
        </template>

        <!-- content -->
        <div id="content-wrapper">
          <BaseScrollbar>
            <template v-for="file in attachmentList">
              <div :key="file.id" class="attachmentList">
                <div class="title row">
                  <div v-if="selectedProcess.processId" class="col-auto">
                    <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                    <BaseIcon
                      :name="
                        file.checked
                          ? 'eva-checkmark-circle-2'
                          : 'eva-radio-button-off-outline'
                      "
                      color="secondary"
                      class="q-mr-sm"
                      @click="file.checked = !file.checked"
                    />
                  </div>
                  <div v-if="file.formJsonId" class="col-auto">
                    <BaseIcon
                      v-tooltip:green.top="getLabel(file.formJsonId)"
                      name="mdi-form-select"
                      color="primary"
                      class="q-mr-sm"
                    />
                  </div>
                  <div class="col-auto">
                    <FileIcon
                      :mime-type="fileType(file.name)"
                      class="mini-avatar q-mr-sm"
                    />
                  </div>
                  <div class="col ellipsis" @click="openFiles(file)">
                    {{ file.name }}
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      icon="eva-download-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Download')"
                    />
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Print'"
                      icon="eva-printer-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Print')"
                    />
                  </div>
                </div>

                <div class="meta row">
                  <div class="col row">
                    <!-- created at -->

                    <div>{{ $day.format(file.createdAt) }}</div>

                    <!-- ... -->

                    <!-- created by -->

                    <template v-if="file.createdBy">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ file.createdByEmail }}</div>
                    </template>

                    <!-- ... -->

                    <!-- size -->

                    <!-- <template v-if="file.size">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ fileSize(file.size) }}</div>
              </template> -->

                    <!-- ... -->
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Metadata'"
                      icon="eva-file-text-outline"
                      color="secondary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="getFilesData(file)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </div>
      </Sheet>
      <!--taskSheet-->
      <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
        <!-- title -->

        <template #title> Tasks </template>

        <!-- ... -->

        <template #actions>
          <BaseActionButton
            v-tooltip.top="'refresh'"
            icon="mdi-refresh"
            color="secondary"
            no-border
            is-flat
            size="20px"
          />

          <BaseActionButton
            v-tooltip.top="'Add Task'"
            icon="mdi-plus"
            color="secondary"
            no-border
            is-flat
            size="20px"
            class="q-mr-sm"
            @click="$emit('addTask')"
          />
        </template>

        <!-- content -->
        <div id="content-wrapper">
          <template v-for="task in taskEntries">
            <div :key="task.id" class="taskEntries">
              <div class="col-12">
                <div class="row">
                  <div
                    class="col-auto q-mr-md"
                    style="margin-top: 3px"
                    @click="$emit('viewTask', task.id)"
                  >
                    {{ task.name }}
                  </div>
                  <q-chip
                    size="10px"
                    :color="getColor(task.priority)"
                    text-color="white"
                  >
                    {{ task.priority }}
                  </q-chip>
                  <q-chip
                    size="10px"
                    :color="getColor(task.status)"
                    text-color="white"
                  >
                    {{ task.status }}
                  </q-chip>
                </div>
              </div>

              <div class="meta row">
                <div class="col-12">Assigned To: {{ task.assigned }}</div>
                <div class="col-12">
                  Start Date: {{ $day.format(task.startDate, false) }}
                  <BaseIcon name="mdi-circle-small" />
                  End Date: {{ $day.format(task.endDate, false) }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </Sheet>

      <!--open file-->
      <FileDetails
        v-model="fileDetailsSheet"
        :file-data="filesData"
        @input="fileDetailsSheet = false"
      ></FileDetails>
      <Sheet
        v-model="fileSheet"
        no-padding
        width="100vw"
        @input="fileSheet = false"
      >
        <!-- title -->

        <template #title>
          <div class="attachmentFileHeader">
            <template v-if="selectedFile.formJsonId">
              <BaseActionButton
                v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
                is-flat
                icon="mdi-form-select"
                color="primary"
                class="q-mr-sm"
              />
            </template>
            <div class="avatar q-ml-sm">
              <FileIcon :mime-type="fileType(selectedFile.name)" />
            </div>

            <div class="q-ml-md">
              <div class="filename">
                {{ selectedFile.name }}
              </div>

              <div
                v-if="selectedFile.createdAt && selectedFile.createdByEmail"
                class="meta"
              >
                <div>
                  Archived At: {{ $day.format(selectedFile.createdAt) }}
                </div>
                <BaseIcon name="mdi-circle-small" />
                <div>Archived By: {{ selectedFile.createdByEmail }}</div>
                <div v-if="selectedFile.size">
                  <BaseIcon name="mdi-circle-small" />
                  File Size: {{ fileSize(selectedFile.size) }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #actions>
          <BaseActionButton
            v-tooltip.top="'Actions'"
            is-flat
            icon="eva-menu"
            class="q-mr-sm"
          >
            <q-menu
              transition-show="scale"
              transition-hide="scale"
              anchor="bottom end"
              self="top right"
            >
              <BaseScrollbar width="240px" class="q-pb-sm">
                <!-- columns -->

                <ListItem
                  v-for="action in documentActionList"
                  :key="action.id"
                  :icon="action.icon"
                  :label="action.label"
                  @click="documentAction(action.id)"
                >
                </ListItem>
              </BaseScrollbar>

              <!-- ... -->
            </q-menu>
          </BaseActionButton>
        </template>

        <div class="attachmentFileFrame">
          <div class="file-list">
            <iframe
              id="file-frame"
              ref="myAttachmentFiles"
              :src="fileLink()"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </Sheet>

      <!--process sheet -->
      <Sheet
        v-model="processSheet"
        width="100vw"
        has-footer
        no-padding
        @input="processSheet = false"
      >
        <!-- title -->

        <template #title> {{ headerTitle }} </template>

        <!-- ... -->

        <!-- actions -->

        <template #actions>
          <BaseActionButton
            v-if="itemModel.itemId"
            v-tooltip.top="'Actions'"
            is-flat
            icon="eva-menu"
            class="q-mr-sm"
          >
            <q-menu
              transition-show="scale"
              transition-hide="scale"
              anchor="bottom end"
              self="top right"
            >
              <BaseScrollbar width="240px" class="q-pb-sm">
                <!-- columns -->

                <ListItem
                  v-for="action in documentActionList"
                  :key="action.id"
                  :icon="action.icon"
                  :label="action.label"
                  @click="processDocumentAction(action)"
                >
                </ListItem>
              </BaseScrollbar>

              <!-- ... -->
            </q-menu>
          </BaseActionButton>
          <BaseActionButton
            v-if="!formView"
            v-tooltip.top="'Form View'"
            is-flat
            :icon="'mdi-form-select'"
            class="q q-mr-sm"
            @click="formView = true"
          />

          <BaseActionButton
            v-else
            v-tooltip.top="'Details View'"
            is-flat
            :icon="'mdi-table-column'"
            class="q-mr-sm"
            @click="formView = false"
          />
        </template>

        <!-- ... -->

        <!-- content -->

        <div id="content-wrapper" class="row">
          <div v-if="formId" id="form-view" class="col">
            <BaseScrollbar height="calc(100vh - 145px)">
              <template v-if="formView">
                <RenderForm
                  v-if="formId && form"
                  :form-id="formId"
                  :panels="panels"
                  :secondary-panels="secondaryPanels"
                  :form-settings="formSettings"
                  :form-model="formModel"
                  :save-action.sync="saveAction"
                  :workflow-form-model.sync="workflowFormModel"
                  :is-readonly="true"
                  :enable-controls="_enableControls"
                />
              </template>
              <template v-else>
                <div class="col-12 q-mb-sm q-pt-md stepper">
                  <Stepper
                    :stage-level="stageLevel"
                    :current-step="currentStep"
                  />
                </div>
                <div id="requestDetails" class="col-12">
                  <div class="row justify-center">
                    <div class="col q-pa-md">
                      <div class="row items-center">
                        <table class="submittedData q-mb-sm">
                          <tbody>
                            <tr v-if="selectedProcess.createdByName">
                              <td colspan="4">
                                Requested By:
                                {{ selectedProcess.createdByName }}
                              </td>
                            </tr>
                            <tr>
                              <th v-if="selectedProcess.raisedBy">
                                Requested User Email
                              </th>
                              <th>Requested Date</th>
                              <th>Last Acted By</th>
                              <th>Last Acted On</th>
                            </tr>
                            <tr>
                              <td v-if="selectedProcess.raisedBy">
                                {{ selectedProcess.raisedBy }}
                              </td>
                              <td>
                                {{ $day.format(selectedProcess.raisedAt) }}
                              </td>
                              <td>
                                {{ selectedProcess.transaction_createdByEmail }}
                              </td>
                              <td>
                                {{
                                  $day.format(
                                    selectedProcess.transaction_createdAt
                                  )
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <BaseScrollbar height="calc(100vh - 320px)">
                          <template>
                            <table class="mainData col-12">
                              <template
                                v-for="(value, key) in selectedProcess.formData
                                  .fields"
                              >
                                <template v-if="value">
                                  <template
                                    v-if="validateType(key) !== 'TABLE'"
                                  >
                                    <tr :key="`${key}-${value}`">
                                      <td class="tblHeader ellipsis small">
                                        {{ getLabel(key) }}
                                      </td>
                                      <td class="ellipsis">
                                        <template
                                          v-if="
                                            validateType(key) === 'FILE_UPLOAD'
                                          "
                                        >
                                          <div v-if="JSON.parse(value).length">
                                            <div
                                              v-for="file in JSON.parse(value)"
                                              :key="file.fileName"
                                              class="q-mb-xs"
                                            >
                                              <div class="row items-center">
                                                <div
                                                  class="col row items-center"
                                                  @click="viewFile(file, value)"
                                                >
                                                  <FileIcon
                                                    :mime-type="
                                                      fileType(file.fileName)
                                                    "
                                                    class="mini-avatar q-mr-sm"
                                                  />
                                                  <span
                                                    class="cursor-pointer text-underline text-secondary"
                                                    >{{ file.fileName }}</span
                                                  >
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                        <template
                                          v-else-if="
                                            validateType(key) === 'DATE'
                                          "
                                        >
                                          {{ $day.format(value, false) }}
                                        </template>
                                        <template
                                          v-else-if="validateValue(value)"
                                        >
                                          {{ removeTags(value) || "-" }}
                                        </template>
                                        <template v-else>
                                          <a :href="value" target="_blank">
                                            {{ value || "-" }}
                                          </a>
                                        </template>
                                      </td>
                                    </tr>
                                  </template>
                                  <template
                                    v-else-if="JSON.parse(value).length"
                                  >
                                    <tr
                                      v-if="getLabel(key)"
                                      :key="'trlabel-' + key"
                                    >
                                      <td
                                        colspan="2"
                                        class="tblHeader ellipsis small"
                                      >
                                        <FormFieldLabel
                                          :key="'label-' + key"
                                          :label="getLabel(key)"
                                          class="q-mt-sm"
                                        />
                                      </td>
                                    </tr>
                                    <tr :key="'trtable1-' + key">
                                      <td colspan="2">
                                        <BaseScrollbar
                                          :key="'scroll-' + key"
                                          style="max-height: 200px"
                                        >
                                          <table
                                            :key="'table-' + key"
                                            class="sub-table q-mt-sm q-mb-sm"
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  v-for="(
                                                    field_item, field_key
                                                  ) in tableValue(value)[0]"
                                                  :key="field_key"
                                                >
                                                  {{
                                                    getLabel(field_key, "table")
                                                  }}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                v-for="(
                                                  field_item, field_key
                                                ) in tableValue(value)"
                                                :key="field_key"
                                              >
                                                <td
                                                  v-for="(
                                                    row_value, row_key
                                                  ) in field_item"
                                                  :key="row_key"
                                                >
                                                  {{ row_value }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </BaseScrollbar>
                                      </td>
                                    </tr>
                                  </template>
                                </template>
                              </template>
                            </table>
                          </template>
                        </BaseScrollbar>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </BaseScrollbar>
          </div>
          <div v-if="itemModel.itemId" class="col">
            <div class="fileFrame">
              <div class="file-list">
                <iframe
                  id="file-frame"
                  ref="myProcessFiles"
                  :src="itemLink()"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="col-auto q-mr-sm leftBorder">
            <template v-for="tab in tabs">
              <BaseActionButton
                v-if="
                  tab.value !== 'TASK' ||
                  (tab.value === 'TASK' && addTaskEnabled)
                "
                :key="tab.id"
                v-tooltip.left="tab.label"
                is-flat
                no-border
                :icon="tab.icon"
                color="primary"
                class="tabButtons"
                @click="showLinks(tab.value)"
              >
                <span v-if="tab.count" class="badge">{{ tab.count }} </span>
              </BaseActionButton>
            </template>
          </div>
        </div>

        <!-- ... -->
      </Sheet>

      <Sheet
        v-model="mailTransactionSheet"
        width="30vw"
        no-padding
        @input="mailTransactionSheet = false"
      >
        <!-- title -->

        <template #title> Mail Transactions </template>

        <!-- ... -->

        <template #actions>
          <BaseActionButton
            v-tooltip.top="'refresh'"
            icon="mdi-refresh"
            color="secondary"
            no-border
            is-flat
            size="20px"
            class="q-mr-sm"
            @click="getMailTransactions()"
          />
        </template>

        <!-- content -->
        <div id="content-wrapper">
          <div class="q-pl-sm">
            <Tabs v-model="mailTab" :tabs="mailTabs" />
          </div>
          <template v-if="mailTab === 'OUTGOING'">
            <BaseScrollbar height="calc(100vh - 120px)">
              <template v-for="row in outgoingMails">
                <div :key="'outgoing' + row.id" class="mailTransactionList">
                  <div class="row">
                    <div class="col">
                      <div class="row q-mb-sm">
                        <div class="col-auto">
                          <BaseIcon
                            name="eva-email-outline"
                            color="primary"
                            class="q-mr-sm"
                          />
                        </div>
                        <div class="col-auto">
                          {{ row.subject }}
                        </div>
                      </div>
                      <div class="row q-mb-sm">
                        <div class="col-auto">
                          <BaseIcon
                            name="eva-person-outline"
                            color="secondary"
                            class="q-mr-sm"
                          />
                        </div>
                        <div class="col-auto">{{ row.email }}</div>
                      </div>
                      <div class="row q-mb-sm">
                        <div class="col-auto">
                          <BaseIcon
                            name="eva-clock-outline"
                            color="orange"
                            class="q-mr-sm"
                          />
                        </div>
                        <div class="col-auto">
                          {{ $day.format(row.createdAt, true) }}
                        </div>
                      </div>
                    </div>
                    <div class="col-auto q-mr-sm">
                      <BaseActionButton
                        v-tooltip.top="'attachments'"
                        icon="eva-attach-2-outline"
                        color="primary"
                        is-flat
                        size="16px"
                        class="q-mb-sm"
                        @click.stop="showMailAttachments(row)"
                      >
                        <span v-if="row.attachmentCount" class="badge"
                          >{{ row.attachmentCount }}
                        </span>
                      </BaseActionButton>

                      <BaseActionButton
                        v-tooltip.top="'resend'"
                        icon="mdi-send"
                        color="secondary"
                        is-flat
                        size="16px"
                        @click.stop="resendMail(row.ezMailId)"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </BaseScrollbar>
          </template>
          <template v-if="mailTab === 'INCOMING'">
            <BaseScrollbar height="calc(100vh - 120px)">
              <template v-for="row in incomingMails">
                <div :key="'incoming' + row.id" class="mailTransactionList">
                  <div class="row">
                    <div class="col">
                      <div class="row q-mb-sm">
                        <div class="col-auto">
                          <BaseIcon
                            name="eva-email-outline"
                            color="primary"
                            class="q-mr-sm"
                          />
                        </div>
                        <div class="col-auto">
                          {{ row.subject }}
                        </div>
                      </div>
                      <div class="row q-mb-sm">
                        <div class="col-auto">
                          <BaseIcon
                            name="eva-person-outline"
                            color="secondary"
                            class="q-mr-sm"
                          />
                        </div>
                        <div class="col-auto">{{ row.email }}</div>
                      </div>
                      <div class="row q-mb-sm">
                        <div class="col-auto">
                          <BaseIcon
                            name="eva-clock-outline"
                            color="orange"
                            class="q-mr-sm"
                          />
                        </div>
                        <div class="col-auto">
                          {{ $day.format(row.createdAt, true) }}
                        </div>
                      </div>
                    </div>
                    <div class="col-auto q-mr-sm">
                      <BaseActionButton
                        v-tooltip.top="'attachments'"
                        icon="eva-attach-2-outline"
                        color="primary"
                        is-flat
                        size="16px"
                        class="q-mb-sm"
                        @click.stop="showMailAttachments(row)"
                      >
                        <span v-if="row.attachmentCount" class="badge"
                          >{{ row.attachmentCount }}
                        </span>
                      </BaseActionButton>
                    </div>
                  </div>
                </div>
              </template>
            </BaseScrollbar>
          </template>
        </div>

        <!-- ... -->
      </Sheet>
      <ConfirmDelete
        v-model="isDeleteConfirmationVisible"
        :label="findValue.name"
        :delete-data="deleteData"
        @delete="$emit('delete')"
      />
    </div>
  </div>
  <div
    v-else
    id="repository-reports"
    style="cursor: pointer"
    class="text-base font-bold items-center text-black row col-12"
    @click="findItem"
  >
    <span class="col-auto"
      ><BaseIcon
        v-tooltip.top="findIcon.iconTootip"
        :name="findIcon.icon"
        :color="findIcon.color"
        class="q-mr-sm q-pb-sm"
        size="20px" /></span
    ><span
      class="col text-base font-bold text-uppercase items-center text-black"
      >{{ findValue.name }}
      <div
        class="q-mt-xs text-base font-bold text-uppercase items-center"
        style="color: #475569; width: 100%"
      >
        {{ findValue.description }}
      </div></span
    >

    <div v-if="filterContains.length" class="icon-container">
      <BaseActionButton
        is-flat
        no-border
        icon="eva-eye"
        color="primary"
        class="q-ml-xs col-auto icon"
      />
      <div class="iconcontent" @click.stop="">
        <div
          v-for="(item, index) in filterContains"
          :key="index"
          class="col-12 q-mr-sm row items-center filerItem"
        >
          <span
            class="q-ml-sm q-mr-xs q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center"
            style="cursor: pointer; max-width: 100px; border-right: 0px"
          >
            {{ item.criteria }}</span
          >
          <span
            class="q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center"
            style="cursor: pointer; max-width: 40px; border-right: 0px"
          >
            {{ item.condition }}</span
          >
          <span
            class="q-mr-sm q-ml-sm q-mt-sm q-mb-sm ellipsis text-base font-bold text-black items-center text-align-right"
            style="cursor: pointer; max-width: 100px; border-right: 0px"
          >
            {{ item.value }}</span
          >
        </div>
      </div>
    </div>

    <BaseActionButton
      v-tooltip.top="'refresh'"
      is-flat
      no-border
      icon="eva-refresh"
      class="q-ml-sm col-auto"
      @click.stop="refresh"
    />
    <BaseActionButton
      v-tooltip.top="'export'"
      is-flat
      no-border
      icon="mdi-table-arrow-right"
      class="q-ml-sm col-auto"
      @click.stop="export_Data"
    />
    <BaseActionButton
      v-tooltip.top="'open'"
      is-flat
      no-border
      icon="eva-arrow-ios-downward-outline"
      class="q-ml-sm col-auto"
      @click.stop="findItem"
    />
    <BaseActionButton
      v-tooltip.bottom="'UnPin'"
      icon="mdi-pin"
      color="secondary"
      no-border
      is-flat
      class="col-auto"
      size="20px"
      rotate="rotate-45"
      @click.stop="deleteItem"
    />

    <ConfirmDelete
      v-model="isDeleteConfirmationVisible"
      :label="findValue.name"
      :delete-data="deleteData"
      @delete="$emit('delete')"
    />
  </div>
</template>

<script>
import {
  repository,
  user,
  report,
  workspace,
  workflow,
  form,
} from "@/api/factory.js";
import DashboardFilterList from "./DashboardFilterList.vue";
import DataType from "@/constants/data-type.js";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import { exportData } from "@/helpers/export-excel-data";
import FileSheet from "@/views/repositories/views/repository-browse-and-trash/components/FileSheet.vue";
import formatBytes from "@/helpers/format-bytes.js";
import ReportList from "@/views/workflows/views/workflows-report/components/ReportList.vue";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import { pdfSupport, htmlSupport } from "@/helpers/file-format.js";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import Stepper from "@/components/common/workflow/Stepper.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import ListItem from "@/components/common/ListItem.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import FileDetails from "@/views/workflows/views/workflows-inbox/components/FileDetails.vue";
import ProgressBar from "@/views/dashboard/components/MobileLoadingBar.vue";
import ConfirmDelete from "@/components/common/display/item-actions/components/UnpinDashboard.vue";

export default {
  name: "DashboardFilterReport",

  components: {
    StateWrapper,
    Pagination,
    FileSheet,
    DashboardFilterList,
    ReportList,
    ProcessHistory,
    Sheet,
    RenderForm,
    Stepper,
    FileIcon,
    ListItem,
    Tabs,
    FileDetails,
    ProgressBar,
    ConfirmDelete,
  },

  props: {
    filterItem: {
      type: Object,
      required: true,
    },
    listedData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      mode: "REPORT",
      sortBy: {
        criteria: "",
        order: "ASC",
      },
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "folders",
          route: "repositories-browse",
        },
        {
          id: this.$nano.id(),
          label: "reports",
          route: "repositories-reports-overview",
        },
        {
          id: this.$nano.id(),
          label: "activity",
          route: "",
        },
      ],
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "mail transaction",
          value: "MAIL",
          icon: "mdi-email-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Data Validation",
        //   value: "VALIDATION",
        //   icon: "mdi-file-compare",
        //   count: 0,
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "Payment Table",
        //   value: "PAYMENT_TABLE",
        //   icon: "mdi-table",
        //   count: 0,
        // },
      ],
      columns: [],
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      hideFileSheet: false,
      userList: [],
      workspaceId: 0,
      workspaceList: [],
      itemId: 0,
      repositoryId: 0,
      repositoriesList: [],
      reportList: [],
      repositoryReportList: [],
      repositoryReportListData: [],
      repositoryDetails: {},
      repositoryField: [],
      options: {},
      selectedFile: {},
      isFileSheetVisible: false,
      loadingBarContent: false,
      exportDataList: [],
      activities: [],
      issavesheet: false,
      showFilterList: false,
      WfilterList: [],
      filterId: "",
      savedList: [],
      savedListData: [],
      selectedIds: 0,
      // listedData: [],
      filterListData: [],
      filterFrom: "",
      length: 0,
      findValue: {},
      expand: false,
      repositoryOcrReportList: [],
      repositoryOcrReportListData: [],
      workflowRequestList: [],
      workflowRequestListData: [],
      formId: "",
      formFields: [],
      slaSettings: 0,
      checkList: [],
      stages: [],
      processLevel: [],
      selectedProcess: {},
      historyProcessId: 0,
      commentsSheet: false,
      attachmentSheet: false,
      attachmentList: [],
      commentsList: [],
      taskSheet: false,
      taskEntries: [],
      fileSheet: false,
      processSheet: false,
      itemModel: {},
      formModel: {},
      formView: true,
      stageLevel: [],
      currentStep: 0,
      headerTitle: "",
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      workflowFormModel: {},
      saveAction: "",
      form: null,
      addTaskEnabled: false,
      taskFormId: 0,
      taskRepositoryId: 0,
      addTaskForm: {},
      mailTransactionSheet: false,
      mailTabs: [
        {
          id: this.$nano.id(),
          label: "outgoing",
          value: "OUTGOING",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "incoming",
          value: "INCOMING",
          count: 0,
        },
      ],
      mailTab: "OUTGOING",
      mailTransactionList: [],
      workflowList: [],
      filesData: [],
      fileDetailsSheet: false,
      repositorySignReportList: [],
      repositorySignReportListData: [],
      filterContains: [],
      progressBars: [
        { width: "20%" },
        { width: "30%" },
        { width: "50%" },
        { width: "30%" },
        { width: "50%" },
        { width: "70%" },
        { width: "20%" },
        { width: "30%" },
        { width: "50%" },
        { width: "30%" },
        { width: "50%" },
        { width: "70%" },
        { width: "20%" },
        { width: "30%" },
        { width: "50%" },
      ],
      findIcon: {},
      deleteData: {},
      isDeleteConfirmationVisible: false,
    };
  },

  computed: {
    _columns() {
      let columns = [
        {
          id: this.$nano.id(),
          name: "ifileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },

        {
          id: this.$nano.id(),
          name: "activityId",
          label: "Activity",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "activityUserEmail",
          label: "Activity User Email",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "activityOn",
          label: "Activity On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "filesize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "version",
          label: "Version",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
      ];
      this.repositoryField.forEach((field) => {
        columns.push({
          id: this.$nano.id(),
          label: field.name,
          name: field.name,
          dataType: field.dataType,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        });
      });
      return columns;
    },

    items() {
      if (this.filterFrom === "repositoryActivityReport") {
        return this.repositoryReportListData.map(({ key, value }) => ({
          key: "",
          value: key,
          data: value.map((item) => ({
            icon: "mdi-text-box",
            id: item.rNo,
            itemId: item.itemId,
            link: true,
            ifileName: item.ifileName,
            activity: item.activity,
            email: item.email,
            activityOn: this.$day.format(item.activityOn),
            filesize: "",
            createdAt: this.$day.format(item.createdAt),
            version: item.fileVersion,
            ...this.getFileData(item),
          })),
        }));
      } else if (this.filterFrom === "repositoryOcrReport") {
        return this.repositoryOcrReportListData.map(({ key, value }) => ({
          key: "",
          value: key,
          data: value.map((item) => ({
            icon: "mdi-text-box",
            itemId: item.itemId,
            link: true,
            fileName: item.fileName,
            fileSize: "",
            totalPage: item.totalPage,
            fileStatus: item.fileStatus,
            processedPages: item.processedPages,
            ocrType: item.ocrType,
            processedOn: this.$day.format(item.processedOn),
            processedBy: item.processedBy,
          })),
        }));
      } else if (this.filterFrom === "workflowReport") {
        return this.workflowRequestList.map(({ key, value }) => ({
          key: "",
          value: key,
          data: value.map((item) => ({
            icon:
              item.flowStatus == 1
                ? "mdi-check-circle-outline"
                : "mdi-progress-clock",
            color: item.flowStatus == 1 ? "green" : "orange",
            iconTooltip: item.flowStatus == 1 ? "Completed" : "In-progress",
            link: true,
            id: item.processId,
            processId: item.processId,
            requestNo: item.requestNo,
            raisedBy: item.raisedBy,
            raisedAt: this.$day.format(item.raisedAt),
            stage: item.stage,
            lastActionBy: item.transaction_createdByEmail,
            lastActionOn: this.$day.format(item.transaction_createdAt),
            actionUser: item.activityUserEmail,
            duration: item.flowStatus == 1 ? this.daysOpen(item) : "",
            daysOpen: item.flowStatus == 0 ? this.daysOpen(item) : "",
            attachmentCount: item.attachmentCount,
            commentsCount: item.commentsCount,
            jiraIssueInfo: item.jiraIssueInfo,
            ...this.getResponseData(item.slaResponse),
            ...this.getResolutionData(item.slaResolution),
            ...this.getFormData(item),
            subWorkflow: item.subworkflowTransaction.map((subItem) => ({
              activityId: subItem.activityId,
              userId: subItem.activityUserId,
              color: subItem.flowStatus == 1 ? "green" : "orange",
              iconTooltip:
                subItem.flowStatus == 1 ? "Completed" : "In-progress",
              icon:
                subItem.flowStatus == 1
                  ? "mdi-check-circle-outline"
                  : "mdi-progress-clock",
              processId: subItem.processId,
              raisedAt: this.$day.format(subItem.raisedAt),
              parentProcessID: item.processId,
              raisedBy: subItem.raisedBy,
              raisedByUser: subItem.raisedByUserId,
              requestNo: subItem.requestNo,
              review: subItem.review,
              stageName: subItem.stageName,
              id: subItem.subWorkFlowId,
              stageType: subItem.stageType,
              stage: subItem.stageName,
              subWorkFlowId: subItem.subWorkFlowId,
              subWorkflowName: subItem.subWorkflowName,
              transactionId: subItem.transactionId,
              inbox:
                String(subItem.activityUserId) ===
                String(this.$store.state.session.id),
              attachmentsCount: subItem.attachmentCount,
              commentsCount: subItem.commentsCount,
              displaySubTable: false,
              actionUser: subItem.activityUserEmail,
            })),
          })),
        }));
      } else if (this.filterFrom === "repositorySignReport") {
        return this.repositorySignReportListData.map(({ key, value }) => ({
          key: "",
          value: key,
          data: value.map((item) => ({
            icon:
              item.status === "Running"
                ? "eva-clock-outline"
                : "eva-checkmark-circle-2-outline",
            color: item.status ? "orange" : "green ",
            iconTooltip: item.status ? "Processing" : "Completed ",
            itemId: item.itemId,
            link: true,
            fileName: item.fileName,
            fileSize: item.fileSize ? formatBytes(item.fileSize) : "",
            fileStatus: item.status,
            signType: item.signType,
            requestedAt: this.$day.format(item.requestedAt),
            requestedBy: item.requestedByEmail,
            version: item.version,
            currentUser: item.currentUser,
            Stage: item.stageName,
            pId: item.processId,
            wId: item.workflowId,
          })),
        }));
      }
      return [];
    },

    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.form) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.form) {
        return {};
      }
      return this.form.settings;
    },

    checkListTemplate() {
      if (this.checkList.length) {
        return this.checkList.filter((row) => row.template).length > 0;
      }
      return false;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      if (!this.form) {
        return {};
      }
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (controls.indexOf(control) < 0) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  enableControls.push({
                    id: row.id,
                    parentControls: controls,
                  });
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    outgoingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Outgoing"
      );
    },

    incomingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Incoming"
      );
    },
  },

  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filterItem) {
          this.findValue = this.filterItem;
          this.expand = this.findValue.expand;
          if (this.expand) {
            this.findItem();
          }
          let filter = JSON.parse(this.filterItem.filter);
          let filters = JSON.parse(filter[0].filters);
          console.log(filters, "filters");
          if (filters.length) {
            const conditionMapping = {
              IS_EQUALS_TO: "=",
              CONTAINS: "()",
              is_greater_than: ">",
            };
            const transformedData = filters.flatMap((filterGroup) =>
              filterGroup.filters.map((filter) => ({
                criteria: filter.selectedCriteria || filter.criteria,
                condition:
                  conditionMapping[filter.condition] || filter.condition, // Replace with symbol
                value: filter.selectedValue || filter.value,
              }))
            );
            this.filterContains = transformedData;
            console.log(transformedData, "filterss");
          }
          let findIcons = this.findValue.filterFrom;
          if (findIcons === "repositoryActivityReport") {
            this.findIcon = {
              icon: "mdi-file-table",
              iconTootip: "Repository Activity Report",
              color: "orange",
            };
          } else if (findIcons === "repositoryOcrReport") {
            this.findIcon = {
              icon: "mdi-file-table-box",
              iconTootip: "Data Capture Report",
              color: "purple",
            };
          } else if (findIcons === "workflowReport") {
            this.findIcon = {
              icon: "mdi-chart-timeline-variant-shimmer",
              iconTootip: "Workflow Report",
              color: "yellow",
            };
            this.iconTootip = "Payment";
          } else if (findIcons === "repositorySignReport") {
            this.findIcon = {
              icon: "mdi-draw",
              iconTootip: "Repository Sign Report",
              color: "green",
            };
            this.iconTootip = "Completed";
          }
        }
      },
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },
    workspaceId: {
      deep: true,
      immediate: true,
      handler() {
        this.clearData();
        if (this.workspaceId) {
          this.getRepositories();
        }
      },
    },

    repositoryId: {
      deep: true,
      immediate: true,
      async handler() {
        this.repositoryReportList = [];
        this.repositoryOcrReportListData = [];

        if (this.repositoryId && this.filterFrom) {
          // await this.getRepository();
        } else if (this.repositoryId) {
          await this.getRepository();
          this.getRepositoryData();
        }
      },
    },

    filterId: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.filterId === "-1") {
          this.showFilterList = true;
          this.getFilterData();
        } else if (this.filterId) {
          this.showFilterList = false;
          this.filterList();
          this.selectedIds = this.filterId;
        } else if (!this.filterId) {
          this.showFilterList = false;
          this.repositoryReportList = [];
        }
      },
    },

    itemsPerPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workspaceId && this.repositoryId && this.itemsPerPage) {
          this.getRepositoryData();
        }
      },
    },

    currentPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workspaceId && this.repositoryId && this.currentPage) {
          this.getRepositoryData();
        }
      },
    },
  },

  created() {
    this.getWorkspaces();
    this.getUsers();
    this.getActivityList();
    // this.getFilterList();
    // this.filterList();
  },

  methods: {
    refreshData() {
      if (this.workspaceId && this.repositoryId) {
        this.getRepositoryData();
      } else if (this.workspaceId) {
        this.getRepositories();
      }
    },
    deleteItem() {
      this.listedData.map((item) => {
        if (item.name === this.findValue.label) {
          this.isDeleteConfirmationVisible = true;
          console.log(item, "itemss");
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            pinDashboard: false,
          };
        }
      });
    },

    async export_Data() {
      let filterId = JSON.parse(this.findValue.filter);
      let uId = Number(filterId[0].uId);
      console.log(uId, "this.findValue");

      if (this.findValue.filterFrom === "workflowReport") {
        const { error, payload } = await report.getWorkflowData(uId, {
          filterBy: this.filterBy,
          itemsPerPage: 0,
          currentPage: 0,
          mode: "",
        });

        //this.loadingBarContent = false;

        if (error) {
          this.$alert.error(error);
          return;
        }

        const { meta, data } = payload;
        if (meta.totalItems && data.length) {
          let exportRecords = [];
          data[0].value.forEach((item) => {
            exportRecords.push({
              "Request No": item.requestNo,
              ...this.getJiraIssueInfo(item),
              Status: item.flowStatus == 1 ? "Completed" : "In-progress",
              Duration: item.flowStatus == 1 ? this.daysOpen(item) : "",
              "Days Open": item.flowStatus == 0 ? this.daysOpen(item) : "",
              Stage: item.stage,
              "Action User": item.activityUserEmail,
              ...this.getResponseData(item.slaResponse, true),
              ...this.getResolutionData(item.slaResolution, true),
              "Raised By": item.raisedBy,
              "Raised On": this.$day.format(item.raisedAt),
              "Last Action By": item.transaction_createdByEmail,
              "Last Action On": this.$day.format(item.transaction_createdAt),
              "Attachments Count": item.attachmentCount,
              "Comments Count": item.commentsCount,
              ...this.getFormData(item),
            });
          });
          // let selectedWorkflow = this.workflowList.find(
          //   (item) => item.value === this.workflowId
          // );
          exportData(exportRecords, this.findValue.label);
        } else {
          //this.$alert.info("No data found for export");
        }
      } else {
        this.loadingBarContent = true;
        const { error, payload } = await report.getRepositoryData(uId, {
          itemId: this.itemId,
          filterBy: this.filterBy,
          itemsPerPage: 0,
          currentPage: 0,
        });

        this.loadingBarContent = false;

        if (error) {
          this.$alert.error(error);
          return;
        }
        let tableData = [];
        if (payload) {
          payload.data.forEach((row) => {
            row.value.forEach((item) => {
              tableData.push({
                "File Name": item.ifileName,
                Activity: item.activity,
                "Activity User Email": item.email,
                "Activity On": this.$day.format(item.activityOn),
                Size: formatBytes(item.ifileSize),
                "Created At": this.$day.format(item.createdAt),
                Version: item.fileVersion,
                ...this.getFileData(item),
              });
            });
          });
        }

        if (tableData.length) {
          exportData(tableData, this.findValue.label);
        }
      }
    },

    getJiraIssueInfo(data) {
      if (data.jiraIssueInfo.id) {
        return {
          "Ref# Id": data.jiraIssueInfo.id,
          Key: data.jiraIssueInfo.key,
          Link: data.jiraIssueInfo.self
            ? this.getJIRALink(data.jiraIssueInfo)
            : "",
          Assignee: data.jiraIssueInfo.assignee,
          "Issue Status": data.jiraIssueInfo.issueStatus,
        };
      }
    },

    validateType(key) {
      let fieldType = "";
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        let formField = panel.fields.find((field) => field.id === key);
        if (formField) {
          fieldType = formField.type;
        }
      });
      return fieldType;
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    processDocumentAction(action) {
      if (action.id === "Email_Sharing") {
        //
      } else if (action.id === "Print") {
        if (this.itemModel.name) {
          let ext = this.itemModel.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.itemModel.itemId);
          } else {
            this.$refs.myProcessFiles.contentWindow.print();
          }
        }
        this.processFilePrintSession();
      } else if (action.id === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.itemModel.repositoryId}/${this.itemModel.itemId}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          if (pdfSupport(ext)) {
            if (origin === "https://ag-appsvc01.azurewebsites.net") {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            }
          } else if (origin === "http://52.172.32.88") {
            return `http://52.172.32.88/AnnViewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/1`;
          }
        } else {
          if (pdfSupport(ext)) {
            if (origin === "https://ag-appsvc01.azurewebsites.net") {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            return `http://52.172.32.88/AnnViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2`;
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
        }
      }
    },

    openFiles(fileObj) {
      this.selectedFile = fileObj;
      console.log(this.selectedFile, "this.selectedFile");
      let origin = location.origin;
      if (origin === "https://ag-appsvc01.azurewebsites.net") {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },
    async openSubAttachments(request) {
      console.log(
        this.workflowRequestList[0].value,
        "this.workflowRequestList[0].value"
      );
      const mainWorkflow = this.workflowRequestList[0].value.find(
        (row) => row.processId === request.parentProcessID
      );
      let subWorkflow;
      if (mainWorkflow) {
        subWorkflow = mainWorkflow.subworkflowTransaction.find(
          (subRow) => subRow.processId === request.processId
        );
        if (subWorkflow) {
          subWorkflow.id = subWorkflow.subWorkFlowId;
          this.selectedProcess = subWorkflow;
          // this.$emit("update:workflow", subWorkflow);
          this.$emit("update:selectedProcess", subWorkflow);
          // await this.getRepository();
          await this.getAttachments(
            subWorkflow.subWorkFlowId,
            subWorkflow.processId
          );
          this.showLinks("ATTACHMENTS");
        }
      }
    },
    async openSubComments(request) {
      const mainWorkflow = this.workflowRequestList[0].value.find(
        (row) => row.processId === request.parentProcessID
      );
      let subWorkflow;
      if (mainWorkflow) {
        subWorkflow = mainWorkflow.subworkflowTransaction.find(
          (subRow) => subRow.processId === request.processId
        );
        if (subWorkflow) {
          subWorkflow.id = subWorkflow.subWorkFlowId;
          this.selectedProcess = subWorkflow;
          // this.$emit("update:workflow", subWorkflow);
          // this.$emit("update:selectedProcess", subWorkflow);
          await this.getComments(
            subWorkflow.subWorkFlowId,
            subWorkflow.processId
          );
          this.showLinks("COMMENTS");
        }
      }
    },

    documentAction(action, viewPrint) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          if (viewPrint) {
            let link = "";
            if (this.selectedFile.initiate) {
              let type = 1;
              if (this.repositoryDetails.fieldsType === "STATIC") {
                type = 2;
              }
              link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/1`;
            } else {
              link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
            }
            window.open(link, "_blank");
          } else {
            let ext = this.selectedFile.name.split(".").pop();
            if (pdfSupport(ext)) {
              localStorage.setItem("filePrint", true);
              localStorage.setItem("filePrintId", this.selectedFile.id);
            } else {
              this.$refs.myAttachmentFiles.contentWindow.print();
            }
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";

        if (this.selectedFile.initiate) {
          let type = 1;
          if (this.repositoryDetails.fieldsType === "STATIC") {
            type = 2;
          }
          link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/2`;
        } else {
          link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        }
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    fileMenuAction(fileObj, action) {
      this.selectedFile = fileObj;
      this.documentAction(action, true);
    },
    async getFilesData(file) {
      this.loadingBarContent = true;

      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });

      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      this.filesData = payload;
      this.fileDetailsSheet = true;
    },

    openFile(rowId) {
      let selectedList = {};
      if (this.filterFrom === "repositoryActivityReport") {
        selectedList = this.repositoryReportList[0].value.find(
          (file) => file.rNo === rowId
        );
        this.selectedFile = {
          id: selectedList.itemId,
          name: selectedList.ifileName,
          size: selectedList.ifileSize,
          repositoryId: this.repositoryId,
          createdAt: selectedList.createdAt,
          createdBy: selectedList.createdBy,
        };
      } else if (this.filterFrom === "repositoryOcrReport") {
        selectedList = this.repositoryOcrReportListData[0].value.find(
          (file) => file.rNo === rowId
        );
        this.selectedFile = {
          id: selectedList.itemId,
          name: selectedList.ifileName,
          size: selectedList.ifileSize,
          repositoryId: this.repositoryId,
          createdAt: selectedList.createdAt,
          createdBy: "",
        };
      } else if (this.filterFrom === "repositorySignReport") {
        selectedList = this.repositorySignReportListData[0].value.find(
          (file) => file.rNo === rowId
        );
        console.log(selectedList, "listed");
        this.selectedFile = {
          id: selectedList.itemId,
          name: selectedList.fileName,
          size: selectedList.fileSize,
          repositoryId: this.repositoryId,
          createdAt: selectedList.requestedAt,
          createdBy: selectedList.requestedByEmail,
        };
      }

      this.isFileSheetVisible = true;
    },

    getFileData(item) {
      const data = {};
      this.repositoryField.forEach((field) => {
        data[field.name] = item[field.name];
      });
      return data;
    },

    /* api Functions */

    async getWorkspaces() {
      this.loadingBarContent = true;

      const { error, payload } = await workspace.getWorkspaceList();

      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.workspaceList = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.id,
      }));
    },

    async getRepositories() {
      if (this.workspaceId) {
        this.repositoriesList = [];
        //this.loadingBarContent = true;
        this.loadingBarContent = true;

        const { error, payload } = await repository.getRepositoryList(
          "workspaceId",
          this.workspaceId
        );

        this.loadingBarContent = false;
        // this.loadingBarContent = false;

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (!payload.length) {
          return;
        }
        this.repositoriesList = payload.map((repository) => ({
          id: this.$nano.id(),
          label: repository.value,
          value: repository.id,
        }));
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value || user.loginName,
            value: user.id,
          });
        });
      }
    },

    async getActivityList() {
      this.activities = [];
      let filter = {
        filterBy: [
          {
            filters: [
              {
                criteria: "activityType",
                condition: "IS_EQUALS_TO",
                value: "Document Report",
              },
            ],
            groupCondition: "",
          },
        ],
      };
      const { error, payload } = await report.activityList(filter);
      if (error) {
        this.$alert.error("Error fetching activity list");
        return;
      }

      if (payload) {
        payload.map((activity) => {
          this.activities.push({
            id: this.$nano.id(),
            label: activity.activityName,
            value: activity.id,
          });
        });
      }
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }

      this.repositoryDetails = payload;

      this.workspaceId = this.repositoryDetails.workspace.id;

      if (payload.options) {
        this.options = payload.options;
      }

      this.repositoryField = this.repositoryDetails.fields;
      this.columns = [
        {
          id: this.$nano.id(),
          name: "id",
          label: "Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "itemId",
          label: "Item Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "ifileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },

        {
          id: this.$nano.id(),
          name: "activity",
          label: "Activity",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "email",
          label: "Activity User Email",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "activityOn",
          label: "Activity On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "filesize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "version",
          label: "Version",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
      ];
      this.repositoryField.forEach((field) => {
        this.columns.push({
          id: this.$nano.id(),
          label: field.name,
          name: field.name,
          dataType: field.dataType,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        });
      });
    },

    async getOcrReportData() {
      this.loadingBarContent = true;
      //this.loadingBarContent = true;
      this.repositoryOcrReportList = [];
      this.repositoryOcrReportListData = [];
      this.list = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = JSON.stringify([row.arrayValue]);
            }
          });
        }
      });
      const { error, payload } = await report.getOcrReportData(
        this.repositoryId,
        {
          itemId: this.itemId,
          filterBy: this.filterBy,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        }
      );
      // this.loadingBarContent = false;
      this.loadingBarContent = false;
      console.log(payload, "payloadOcr");
      if (error) {
        this.$alert.error(error);
        return;
      }

      this.totalItems = payload.meta?.totalItems || 0;

      this.columns = [
        {
          id: this.$nano.id(),
          name: "fileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileStatus",
          label: "Status",
          isVisible: true,
          options: this.fileStatus,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "ocrType",
          label: "Type",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "processedBy",
          label: "Processed By",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          options: this.userList,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "processedOn",
          label: "Processed On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "totalPage",
          label: "Total Pages",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "processedPages",
          label: "Processed Pages",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileSize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ];

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.repositoryOcrReportList = [];
        this.repositoryOcrReportListData = [];
      } else {
        this.repositoryOcrReportList = payload.data;
        this.repositoryOcrReportListData = payload.data;
        if (this.repositoryOcrReportList.length) {
          this.exportDataList = true;
        }
      }
    },

    async getWorkflowData() {
      //this.loadingBarContent = true;
      this.loadingBarContent = true;
      this.workflowRequestList = [];
      this.workflowRequestListData = [];
      this.list = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = JSON.stringify([row.arrayValue]);
            }
          });
        }
      });
      const { error, payload } = await report.getWorkflowData(
        this.repositoryId,
        {
          filterBy: this.filterBy,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
          mode: "",
        }
      );
      // this.loadingBarContent = false;
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload.data, "wpay");

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          console.log(row.value, "wpay");
          this.list.push({
            ...process,
          });
        });
      });

      this.totalItems = payload.meta?.totalItems || 0;

      this.columns = [];
      this.columns.push(
        {
          id: this.$nano.id(),
          name: "id",
          label: "Process Id",
          dataType: "SHORT_TEXT",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
        },

        {
          id: this.$nano.id(),
          name: "requestNo",
          label: "Request No",
          type: "TITLE",
          dataType: "SHORT_TEXT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "flowStatus",
          label: "Status",
          dataType: "SINGLE_SELECT",
          options: this.status,
          isVisible: false,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "duration",
          label: "Duration",
          dataType: "SHORT_TEXT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "daysOpen",
          label: "Days Open",
          dataType: "SHORT_TEXT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "stage",
          label: "Stage",
          dataType: "SINGLE_SELECT",
          options: this.processLevel,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "actionUser",
          label: "Action User",
          dataType: "SINGLE_SELECT",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        }
      );
      if (this.slaSettings === 2) {
        this.columns.push(
          {
            id: this.$nano.id(),
            name: "resolutionTime",
            label: "Resolution Time",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "resolutionStatus",
            label: "Resolution Status",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          }
        );
      } else if (this.slaSettings === 3) {
        this.columns.push(
          {
            id: this.$nano.id(),
            name: "responseTime",
            label: "Response Time",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "responseStatus",
            label: "Response Status",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          }
        );
      } else if (this.slaSettings === 4) {
        this.columns.push(
          {
            id: this.$nano.id(),
            name: "responseTime",
            label: "Response Time",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "responseStatus",
            label: "Response Status",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "resolutionTime",
            label: "Resolution Time",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "resolutionStatus",
            label: "Resolution Status",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          }
        );
      }

      this.columns.push(
        {
          id: this.$nano.id(),
          name: "raisedBy",
          label: "Raised By",
          dataType: "SINGLE_SELECT",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedAt",
          label: "Raised On",
          dataType: "DATE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "lastActionBy",
          label: "Last Action By",
          dataType: "SINGLE_SELECT",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "lastActionOn",
          label: "Last Action On",
          dataType: "DATE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        }
      );

      this.formFields.forEach((field) => {
        this.columns.push({
          id: this.$nano.id(),
          name: this.getLabel(field.value),
          label: field.label ? field.label : field.dataType,
          dataType: field.dataType,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        });
      });

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.workflowRequestList = [];
        this.workflowRequestListData = [];
      } else {
        this.workflowRequestList = payload.data;
        this.workflowRequestListData = payload.data;
      }
      console.log(this.workflowRequestList, "this.workflowRequestList");
    },

    async openProcess(request) {
      this.selectedProcess = request;
      this.formModel = {};
      this.itemModel = {};
      if (this.selectedProcess.formData) {
        let keys = Object.keys(this.selectedProcess.formData.fields);
        let newValues = {};
        keys.forEach((key) => {
          newValues[key] = this.validateTypeValue(
            this.selectedProcess.formData.fields[key]
          );
        });
        this.formModel = newValues;
      } else if (this.selectedProcess.itemData) {
        this.itemModel = this.selectedProcess.itemData;
      }
      this.headerTitle = `${this.workflow.name} - ${this.selectedProcess.requestNo} - ${request.stage}`;
      this.processSheet = true;
      this.getTaskList();
      this.getComments();
      this.getAttachments();
      this.getMailTransactions();
      this.showHistoryStepper(
        this.selectedProcess.processId,
        this.selectedProcess.id
      );
    },
    showSubHistory(request) {
      // this.$emit("subHistory", subProcessId, mainWfProcessId);
      this.selectedProcess = request;
      this.showLinks("HISTORY");
    },
    async showHistoryStepper(processId, wId) {
      console.log("called");
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.processHistory(wId, processId);
      //this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        this.stageLevel = [];
        this.currentStep = 0;
        // console.log(payload);
        let activityId = "";
        payload.forEach((row) => {
          if (row.actionStatus !== 2 && activityId !== row.activityId) {
            activityId = row.activityId;
            this.stageLevel.push({
              id: row.activityId,
              label: row.stage,
              status: row.status,
            });
            if (
              ((row.actionUser || row.subWorkflowHistory) && row.status) ||
              (row.actionUser === null && !row.subWorkflowHistory)
            ) {
              this.currentStep += 1;
            }
          }
          // if (row.actionStatus !== 2) {
          //   if (
          //     this.stageLevel.filter((stage) => stage.id === row.activityId)
          //       .length === 0
          //   ) {
          //     this.stageLevel.push({
          //       id: row.activityId,
          //       label: row.stage,
          //       status: row.status,
          //     });
          //     if (
          //       ((row.actionUser || row.subWorkflowHistory) &&
          //         row.status &&
          //         row.status !== "Forward") ||
          //       (row.actionUser === null && !row.subWorkflowHistory)
          //     ) {
          //       this.currentStep += 1;
          //     }
          //   } else if (row.status) {
          //     if (
          //       this.stageLevel.filter(
          //         (stage) =>
          //           stage.id === row.activityId && stage.status !== row.status
          //       ).length
          //     ) {
          //       this.currentStep += 1;
          //     }
          //   } else {
          //     let index = this.stageLevel.findIndex(
          //       (stage) => stage.id === row.activityId
          //     );
          //     if (index > -1) {
          //       this.stageLevel.splice(index, this.stageLevel.length - index);
          //       this.stageLevel.push({
          //         id: row.activityId,
          //         label: row.stage,
          //         status: row.status,
          //       });
          //       if (
          //         (row.actionUser && row.status && row.status !== "Forward") ||
          //         row.actionUser === null
          //       ) {
          //         this.currentStep = index + 1;
          //       } else {
          //         this.currentStep = index;
          //       }
          //     }
          //   }
          // }
        });
      }
    },
    updateSelectedSubWorkflow(item) {
      this.subProcessData = item;
      this.selectedSubWorkflow = item;
      this.subWorkflowId = item.id;
      this.openSubProcessSheet = true;
    },
    async getMailTransactions() {
      this.mailTransactionList = [];
      this.tabs.find((tab) => {
        if (tab.value === "MAIL") {
          tab.count = 0;
        }
      });
      let filter = { filterBy: [] };
      const { error, payload } = await workflow.getMailTransactions(
        this.workflow.id,
        this.selectedProcess.processId,
        filter
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.mailTransactionList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "MAIL") {
            tab.count = this.mailTransactionList.length;
          }
        });
      }
    },
    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    async getComments() {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      this.loadingBarContent = true;
      const { error, payload } = await workflow.getProcessComments(
        this.selectedProcess.id,
        this.selectedProcess.processId
      );

      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.commentsList = [];
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async getAttachments() {
      this.checkList = [];
      this.attachmentList = [];
      if (this.selectedProcess.processId) {
        let workflowJson = JSON.parse(this.workflow.flowJson);
        let block = workflowJson.blocks.find(
          (block) => block.id === this.selectedProcess.activityId
        );
        if (block) {
          if (block.settings.fileSettings) {
            if (block.settings.fileSettings.fileCheckList.length) {
              this.checkList = block.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
              this.checkDocument();
            }
          }
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = 0;
          }
        });
        this.loadingBarContent = true;
        const { error, payload } = await workflow.getAttachments(
          this.repositoryId,
          this.selectedProcess.processId
        );

        this.loadingBarContent = false;

        if (error) {
          this.$alert.error(error);
          return;
        }
        this.attachmentList = [];
        if (payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        }
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            let filterAttach = this.attachmentList.filter((file) => {
              let filename = file.name.substr(0, file.name.lastIndexOf("."));
              if (row.name === filename) {
                return file;
              }
            });
            if (filterAttach.length) {
              row.attach = true;
            } else {
              row.attach = false;
            }
          });
        }
      }
    },

    async getTaskList() {
      this.taskEntries = [];
      let taskFilterBy = [];
      const panels = [
        ...this.addTaskForm.panels,
        ...this.addTaskForm.secondaryPanels,
      ];
      let formFields = [];
      for (const panel of panels) {
        formFields.push(...panel.fields);
      }
      let taskOwnerField = formFields.find(
        (field) => field.label === "Task Owner"
      );
      if (taskOwnerField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskOwnerField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      let taskAssignField = formFields.find(
        (field) => field.label === "Task Assign"
      );
      if (taskAssignField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskAssignField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      this.loadingBarContent = true;
      const { error, payload } = await workflow.getTaskEntries(
        this.repositoryId,
        this.selectedProcess.processId,
        {
          filterBy: [
            {
              filters: [
                {
                  criteria: "createdBy",
                  condition: "IS_EQUALS_TO",
                  value: this.$store.state.session.id,
                },
              ],
            },
            ...taskFilterBy,
          ],
        }
      );
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.taskList = payload;
        payload.forEach((row) => {
          let taskName = "",
            taskAssign = "",
            startDate = "",
            endDate = "",
            priority = "",
            status = "";
          formFields.forEach((field) => {
            switch (field.label) {
              case "Task Name":
                taskName = row.entryInfo[0][field.id];
                break;
              case "Task Assign":
                taskAssign = row.entryInfo[0][field.id];
                break;
              case "Start Date":
                startDate = row.entryInfo[0][field.id];
                break;
              case "End Date":
                endDate = row.entryInfo[0][field.id];
                break;
              case "Priority":
                priority = row.entryInfo[0][field.id];
                break;
              case "Task Status":
                status = row.entryInfo[0][field.id];
                break;
            }
          });
          this.taskEntries.push({
            id: row.entryInfo[0].itemid,
            name: taskName,
            assigned: taskAssign,
            startDate: startDate,
            endDate: endDate,
            priority: priority,
            status: status,
          });
        });
      }
    },

    async getWorkflow() {
      this.processLevel = [];
      this.stages = [];
      this.checkList = [];
      this.loadingBarContent = true;

      const { error, payload } = await workflow.getWorkflow(this.repositoryId);

      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.workflow = payload;
      this.formId = payload.wFormId;

      let workflowJson = JSON.parse(this.workflow.flowJson);
      this.slaSettings = workflowJson.hasSLASettings;
      console.log(this.workflow, "this.workflow ");
      workflowJson.blocks.forEach((block) => {
        if (
          block.type === "START" ||
          block.type === "INTERNAL_ACTOR" ||
          block.type === "END"
        ) {
          let levels = [];
          levels.push({
            id: block.id,
            label: block.settings.label,
            type: block.type,
          });

          let icon = "",
            color = "";

          if (block.type === "START") {
            icon = "mdi-flag-outline";
            color = "green";
          } else if (block.type === "INTERNAL_ACTOR") {
            if (block.settings.users.length && block.settings.groups.length) {
              icon = "mdi-account-group-outline";
              color = "secondary";
            } else if (block.settings.users.length) {
              icon = "mdi-account-outline";
              color = "primary";
            } else if (block.settings.groups.length) {
              icon = "mdi-account-group-outline";
              color = "secondary";
            } else {
              icon = "mdi-account-group-outline";
              color = "secondary";
            }
          } else if (block.type === "END") {
            icon = "mdi-stop-circle-outline";
            color = "red";
          }

          levels.forEach((level) => {
            this.processLevel.push({
              id: this.$nano.id(),
              key: level.id,
              label: level.label,
              value: level.label.toLowerCase(),
            });
            this.stages.push({
              id: this.$nano.id(),
              key: level.id,
              label: level.label,
              icon: icon,
              color: color,
              type: level.type,
            });
          });
        }
      });

      let startBlock = workflowJson.blocks.find(
        (block) => block.type === "START"
      );

      if (startBlock) {
        if (startBlock.settings.fileSettings) {
          if (startBlock.settings.fileSettings.fileCheckList.length) {
            this.checkList = startBlock.settings.fileSettings.fileCheckList;
            this.checkList.forEach((row) => {
              row.uid = this.$nano.id();
              row.attach = false;
            });
            this.checkDocument();
          }
        }
      }
    },

    checkDocument() {
      this.docWarning = false;
      if (this.trackRequest) {
        return;
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          if (row.required && !row.attach) {
            this.docWarning = true;
          }
        });
      }
    },

    // async getWorkflows() {
    //   this.workflowList = [];
    //   let filters = [
    //     {
    //       filters: [
    //         {
    //           id: this.$nano.id(),
    //           criteria: "flowStatus",
    //           condition: "IS_EQUALS_TO",
    //           value: "PUBLISHED",
    //         },
    //       ],
    //       groupCondition: "",
    //     },
    //   ];
    //   this.loadingBarContent = true;
    //   const { error, payload } = await workflow.getWorkflows({
    //     mode: "BROWSE",
    //     sortBy: {
    //       criteria: "",
    //       order: "DESC",
    //     },
    //     groupBy: this.groupBy,
    //     filterBy: filters,
    //   });
    //   this.loadingBarContent = false;

    //   if (error) {
    //     this.$alert.error(error);
    //     return;
    //   }

    //   if (payload) {
    //     if (payload.data.length) {
    //       payload.data[0].value.forEach((workflow) => {
    //         this.workflowList.push({
    //           id: this.$nano.id(),
    //           label: workflow.name,
    //           value: workflow.id,
    //           initiatedBy: workflow.initiatedBy,
    //           formId: workflow.wFormId,
    //           repositoryId: workflow.repositoryId,
    //           flowJson: JSON.parse(workflow.flowJson),
    //         });
    //       });
    //     }
    //   }
    //   if (this.workflowList.length === 1) {
    //     this.workflowId = this.workflowList[0].value;
    //   }
    //   console.log(this.workflowList, "this.workflowList");
    //   let repositoryIds = JSON.parse(this.findValue.filterJson);
    //   console.log(repositoryIds);
    //   let repositoryId = repositoryIds[0].uId;
    //   console.log(repositoryId);
    //   let formId = this.workflowList.find(
    //     (item) => item.value === repositoryId
    //   );
    //   console.log(formId, "form");
    //   this.formId = String(formId.formId);
    // },

    async getRepositoryData() {
      this.loadingBarContent = true;
      //this.loadingBarContent = true;
      this.repositoryReportList = [];
      this.repositoryReportListData = [];
      this.list = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = row.arrayValue;
            }
          });
        }
      });
      const { error, payload } = await report.getRepositoryData(
        this.repositoryId,
        {
          itemId: this.itemId,
          filterBy: this.filterBy,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        }
      );
      this.loadingBarContent = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.data.length) {
          payload.data.forEach((row) => {
            row.value.forEach((process) => {
              this.reportList.push({
                ...process,
              });
            });
          });
        }
      }
      this.totalItems = payload.meta?.totalItems || 0;
      console.log(payload.data, "datas");
      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.repositoryReportList = [];
        this.repositoryReportListData = [];
      } else {
        this.repositoryReportList = payload.data;
        this.repositoryReportListData = payload.data;
        if (this.repositoryReportList.length) {
          this.exportDataList = true;
        }
      }
    },

    getResponseData(data, excel) {
      let sla = {
        responseTime: "",
        responseStatus: "",
      };
      let timeDiff;
      if (data) {
        sla.responseStatus = Number(data.status) || 0;
        if (sla.responseStatus === 0) {
          timeDiff = new Date(data.assignedAt).getTime() - new Date().getTime();
        } else if (sla.responseStatus === 1) {
          timeDiff =
            new Date(data.assignedAt).getTime() -
            new Date(data.responsedAt).getTime();
        } else if (sla.responseStatus === 2) {
          if (data.responsedAt) {
            timeDiff =
              new Date(data.responsedAt).getTime() -
              new Date(data.assignedAt).getTime();
          } else {
            timeDiff =
              new Date().getTime() - new Date(data.assignedAt).getTime();
          }
        }
        let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        if (days) {
          if (days === 1) {
            sla.responseTime = `${days} day`;
          }
          sla.responseTime = `${days} days`;
        } else if (hours) {
          if (hours === 1) {
            sla.responseTime = `${hours} hour`;
          }
          sla.responseTime = `${hours} hours`;
        } else if (minutes) {
          if (minutes === 1) {
            sla.responseTime = `${minutes} min`;
          }
          sla.responseTime = `${minutes} mins`;
        }
      }
      if (excel) {
        if (data) {
          return {
            "Response Time": sla.responseTime,
            "Response Status":
              sla.responseStatus === 0
                ? "In-Progress"
                : sla.responseStatus === 1
                ? "WithIn SLA"
                : "Overdue",
          };
        } else {
          return {};
        }
      }
      console.log(sla, "sla");
      return sla;
    },

    async getTaskForm() {
      this.taskFormList = [];
      this.addTaskEnabled = false;
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload, "forms");
      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.addTaskEnabled = true;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length) {
            if (this.taskFormList.length === 1) {
              this.taskFormId = this.taskFormList[0].id;
              this.taskRepositoryId = this.taskFormList[0].repositoryId;
              let formDet = await this.getTaskFormById(this.formId);
              this.addTaskForm = JSON.parse(formDet.formJson);
            }
          } else {
            //this.$alert.info("Task form not found");
          }
        }
      }
    },

    async getTaskFormById(formId) {
      const { error, payload } = await form.getForm(formId);
      if (error) {
        this.$alert.error(error);
        return;
      }
      return payload;
    },

    getResolutionData(data, excel) {
      let sla = {
        resolutionTime: "",
        resolutionStatus: "",
      };
      let timeDiff;
      if (data) {
        sla.resolutionStatus = data.status || 0;
        if (sla.resolutionStatus == 0) {
          timeDiff = new Date(data.assignedAt).getTime() - new Date().getTime();
        } else if (sla.resolutionStatus == 1) {
          timeDiff =
            new Date(data.assignedAt).getTime() -
            new Date(data.responsedAt).getTime();
        } else if (sla.resolutionStatus == 2) {
          if (data.responsedAt) {
            timeDiff =
              new Date(data.responsedAt).getTime() -
              new Date(data.assignedAt).getTime();
          } else {
            timeDiff =
              new Date().getTime() - new Date(data.assignedAt).getTime();
          }
        }
        let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        if (days) {
          if (days === 1) {
            sla.resolutionTime = `${days} day`;
          }
          sla.resolutionTime = `${days} days`;
        } else if (hours) {
          if (hours === 1) {
            sla.resolutionTime = `${hours} hour`;
          }
          sla.resolutionTime = `${hours} hours`;
        } else if (minutes) {
          if (minutes === 1) {
            sla.resolutionTime = `${minutes} min`;
          }
          sla.resolutionTime = `${minutes} mins`;
        }
      }
      if (excel) {
        if (data) {
          return {
            "Resolution Time": sla.resolutionTime,
            "Resolution Status":
              sla.resolutionStatus == 0
                ? "In-Progress"
                : sla.resolutionStatus == 1
                ? "WithIn SLA"
                : "Overdue",
          };
        } else {
          return {};
        }
      }
      return sla;
    },
    getFormData(item) {
      const data = {};
      if (item.formData) {
        for (let key of Object.keys(item.formData.fields)) {
          const label = this.getLabel(key);
          data[label] = this.removeTags(item.formData.fields[key]);
        }
      }
      return data;
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },
    openComments(request) {
      this.selectedProcess = request;
      this.showLinks("COMMENTS");
    },
    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },
    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },
    openHistory(request) {
      this.selectedProcess = request;
      this.showLinks("HISTORY");
    },
    openAttachments(request) {
      this.selectedProcess = request;
      this.showLinks("ATTACHMENTS");
    },
    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },
    async showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        await this.getAttachments();
        this.attachmentSheet = true;
      } else if (tab === "COMMENTS") {
        await this.getComments();
        this.commentsSheet = true;
      } else if (tab === "HISTORY") {
        this.historyProcessId = this.selectedProcess.processId;
        console.log(this.selectedProcess, "this.selectedProcess");
      } else if (tab === "PRINT") {
        if (this.selectedProcess.formData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/form/generatePdfPrint/${this.$store.state.session.tenantId}/${this.workflow.id}/${this.selectedProcess.processId}/${this.formId}/${this.selectedProcess.formData.formEntryId}/1`
          );
        } else if (this.selectedProcess.itemData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedProcess.itemData.repositoryId}/${this.selectedProcess.itemData.itemId}/2`
          );
        }
        //window.print();
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.$alert.info("Task not found");
        }
      } else if (tab === "MAIL") {
        this.getMailTransactions();
        this.mailTransactionSheet = true;
      }
    },
    async getForm() {
      this.formFields = [];
      this.loadingBarContent = true;

      const { error, payload } = await form.getForm(this.formId);

      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      let formJson = JSON.parse(payload.formJson);
      this.form = formJson;
      this.formId = String(this.formId);
      const panels = [...formJson.panels, ...formJson.secondaryPanels];
      console.log(this.form, "form");
      if (this.form.settings.general.layout === "CARD") {
        this.form.settings.general.layout = "TAB";
      }

      if (!panels.length) {
        return;
      }
      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      fields.forEach((field) => {
        if (
          field.type !== "DIVIDER" &&
          field.type !== "LABEL" &&
          field.type !== "PARAGRAPH" &&
          (field.label || field.type === "TABLE") &&
          field.settings.general.visibility !== "DISABLE"
        ) {
          this.formFields.push({
            id: this.$nano.id(),
            label: field.label ? field.label : field.type,
            value: field.id,
            dataType: field.type,
          });
        }
      });
    },
    daysOpen(request) {
      let timeDiff;
      if (request.stageType === "END") {
        timeDiff =
          new Date(request.transaction_createdAt).getTime() -
          new Date(request.raisedAt).getTime();
      } else {
        timeDiff = new Date().getTime() - new Date(request.raisedAt).getTime();
      }
      let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

      if (days) {
        if (days === 1) {
          return `${days} day`;
        }
        return `${days} days`;
      } else if (hours) {
        if (hours === 1) {
          return `${hours} hour`;
        }
        return `${hours} hours`;
      } else if (minutes) {
        if (minutes === 1) {
          return `${minutes} min`;
        }
        return `${minutes} mins`;
      }
      return "";
    },
    getLabel(id) {
      let controlLabel = "";
      if (this.form) {
        this.form.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          let field = panel.fields.find((field) => field.id === id);
          if (field) {
            controlLabel = field.label ? field.label : field.type;
            return;
          }
        });
      }
      return controlLabel;
    },

    async getData(fromDate, toDate) {
      const filterBy = [
        {
          id: this.$nano.id(),
          filters: [
            {
              id: this.$nano.id(),
              criteria: "activityOn",
              condition: "IS_EQUALS_TO",
              value: fromDate,
              valueTo: toDate,
            },
          ],
          groupCondition: "",
        },
      ];

      this.loadingBarContent = true;
      this.repositoryReportList = [];
      this.repositoryReportListData = [];
      this.list = [];
      const { error, payload } = await report.getRepositoryData(
        this.repositoryId,
        {
          itemId: this.itemId,
          filterBy: filterBy,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        }
      );
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.data.length) {
        payload.data.forEach((row) => {
          row.value.forEach((process) => {
            this.reportList.push({
              ...process,
            });
          });
        });
      }

      this.totalItems = payload.meta?.totalItems || 0;

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.repositoryReportList = [];
        this.repositoryReportListData = [];
      } else {
        this.repositoryReportList = payload.data;
        this.repositoryReportListData = payload.data;
      }
    },

    clearData() {
      this.exportDataList = false;
      this.repositoryId = 0;
      this.repositoryReportList = [];
    },

    call() {
      this.showFilterList = false;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    async getSignReport() {
      this.loadingBarContent = true;
      //this.loadingBarContent = true;
      this.repositorySignReportList = [];
      this.repositorySignReportListData = [];
      this.list = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = JSON.stringify([row.arrayValue]);
            }
          });
        }
      });
      const { error, payload } = await report.getSignReport(this.repositoryId, {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      // this.loadingBarContent = false;
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      (this.columns = [
        {
          id: this.$nano.id(),
          name: "fileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "signType",
          label: "Sign Type",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileStatus",
          label: "Status",
          isVisible: true,
          options: this.fileStatus,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
          onClick: this.showHistory,
        },
        {
          id: this.$nano.id(),
          name: "requestedBy",
          label: "Requested By",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          options: this.userList,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "currentUser",
          label: "Current User",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          options: this.userList,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "requestedAt",
          label: "Requested At",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        // {
        //   id: this.$nano.id(),
        //   name: "Stage",
        //   label: "Stage",
        //   isVisible: true,
        //   isSortable: true,
        //   isGroupable: true,
        //   dataType: DataType.DATE,
        // },
        {
          id: this.$nano.id(),
          name: "version",
          label: "version",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileSize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ]),
        (this.totalItems = payload.meta?.totalItems || 0);

      if (this.totalItems === 0) {
        this.repositorySignReportList = [];
        this.repositorySignReportListData = [];
      } else {
        this.repositorySignReportList = payload.data;
        this.repositorySignReportListData = payload.data;
        if (this.repositorySignReportList.length) {
          this.exportDataList = false;
          this.exportDataList = true;
        }
      }
    },

    getFilterData() {
      this.loadingBarContent = true;
      //this.loadingBarContent = true;
      this.savedList = [];
      this.savedListData = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = row.arrayValue;
            }
          });
        }
      });
      // const { error, payload } = await report.getRepositoryData(
      //   this.repositoryId,
      //   {
      //     itemId: this.itemId,
      //     filterBy: this.filterBy,
      //     itemsPerPage: this.itemsPerPage,
      //     currentPage: this.currentPage,
      //   }
      // );
      // this.loadingBarContent = false;

      let payload = {
        meta: {
          currentPage: 1,
          itemsPerPage: 10,
          totalItems: 17,
        },
        data: [
          {
            key: "",
            value: [
              {
                rNo: 1,
                itemId: 72,
                pathId: "03062024101314",
                status: null,
                name: "raja report",
                totalPages: 0,
                archivedFrom: "WEB",
                createdon: "2024-03-06T10:13:14.14Z",
                createdBy: "admin@ezofis.com",
                description: "Good This Good ",
                page: "repositoryActivityReport",
                items: [],
                search:
                  '[{"id":"nAn5Yj4ymJe3PoCLGhjy3","filters":[{"id":"XqLyzjRTc0UJrJDIxogfs","criteria":"ifileName","condition":"CONTAINS","value":"muthu","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""}]}',
              },
              {
                rNo: 2,
                itemId: 72,
                pathId: "03062024101314",
                status: null,
                name: "raja report",
                totalPages: 0,
                archivedFrom: "WEB",
                createdon: "2024-03-06T10:13:14.14Z",
                createdBy: "admin@ezofis.com",
                description: "Good This Good ",
                page: "repositoryActivityReport",
                search:
                  '[{"id":"nAn5Yj4ymJe3PoCLGhjy3","filters":[{"id":"XqLyzjRTc0UJrJDIxogfs","criteria":"ifileName","condition":"CONTAINS","value":"muthu","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""}]}',
              },
              {
                rNo: 3,
                itemId: 72,
                pathId: "03062024101314",
                status: null,
                name: "raja report",
                totalPages: 0,
                archivedFrom: "WEB",
                createdon: "2024-03-06T10:13:14.14Z",
                createdBy: "admin@ezofis.com",
                description: "Good This Good ",
                page: "repositoryActivityReport",
                search:
                  '[{"id":"nAn5Yj4ymJe3PoCLGhjy3","filters":[{"id":"XqLyzjRTc0UJrJDIxogfs","criteria":"ifileName","condition":"CONTAINS","value":"muthu","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""}]}',
              },
              {
                rNo: 4,
                itemId: 72,
                pathId: "03062024101314",
                status: null,
                name: "raja report",
                totalPages: 0,
                archivedFrom: "WEB",
                createdon: "2024-03-06T10:13:14.14Z",
                createdBy: "admin@ezofis.com",
                description: "Good This Good ",
                page: "repositoryActivityReport",
                search:
                  '[{"id":"nAn5Yj4ymJe3PoCLGhjy3","filters":[{"id":"XqLyzjRTc0UJrJDIxogfs","criteria":"ifileName","condition":"CONTAINS","value":"muthu","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""}]}',
              },
            ],
          },
        ],
        executeTime: "",
      };

      this.columns = [
        {
          id: this.$nano.id(),
          name: "id",
          label: "Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "itemId",
          label: "Item Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdon",
          label: "Created On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
      ];
      this.repositoryField.forEach((field) => {
        this.columns.push({
          id: this.$nano.id(),
          label: field.name,
          name: field.name,
          dataType: field.dataType,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        });
      });
      this.loadingBarContent = false;
      if (payload) {
        if (payload.data.length) {
          payload.data.forEach((row) => {
            row.value.forEach((process) => {
              this.reportList.push({
                ...process,
              });
            });
          });
        }
      }
      this.totalItems = payload.meta?.totalItems || 0;
      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.savedList = [];
        this.savedListData = [];
      } else {
        this.savedList = payload.data;
        this.savedListData = payload.data;
        if (this.savedList.length) {
          this.exportDataList = true;
        }
      }
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    // getFilterList() {
    //   let payload = {
    //     data: [
    //       {
    //         key: "",
    //         value: [
    //           {
    //             rNo: 1,
    //             wId: 1,
    //             itemId: 1,
    //             rId: 2,
    //             pathId: "03062024101314",
    //             status: null,
    //             name: "raja report",
    //             totalPages: 0,
    //             archivedFrom: "WEB",
    //             createdon: "2024-03-06T10:13:14.14Z",
    //             createdBy: "admin@ezofis.com",
    //             description: "Good This Good ",
    //             filterFrom: "repositoryActivityReport",
    //             search:
    //               '"[{"id":"nAn5Yj4ymJe3PoCLGhjy3","filters":[{"id":"XqLyzjRTc0UJrJDIxogfs","criteria":"ifileName","condition":"CONTAINS","value":"zcm","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""}]"',
    //           },
    //           {
    //             rNo: 2,
    //             itemId: 72,
    //             rId: 2,
    //             wId: 1,
    //             pathId: "03062024101314",
    //             status: null,
    //             name: "arasu report",
    //             totalPages: 0,
    //             archivedFrom: "WEB",
    //             createdon: "2024-03-06T10:13:14.14Z",
    //             createdBy: "admin@ezofis.com",
    //             description: "Good This Good ",
    //             filterFrom: "repositoryActivityReport",
    //             search:
    //               '"[{"id":"tUHW9Z3u214I93tltIjfp","filters":[{"id":"fubToAkBmIjGbApKwnn4-","criteria":"ifileName","condition":"CONTAINS","value":"zcm","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""},{"id":"Sx358gJOgWwmosPsTh2fg","filters":[{"id":"iWu-Pbo-x9y0OGrnkN_2T","criteria":"activityId","condition":"IS_EQUALS_TO","value":"4","valueTo":"","arrayValue":"4","dataType":"SINGLE_SELECT"}],"groupCondition":"and"}]"',
    //           },
    //           {
    //             rNo: 3,
    //             rId: 28,
    //             wId: 3,
    //             itemId: 73,
    //             pathId: "03062024101314",
    //             status: null,
    //             name: "arun report",
    //             totalPages: 0,
    //             archivedFrom: "WEB",
    //             createdon: "2024-03-06T10:13:14.14Z",
    //             createdBy: "admin@ezofis.com",
    //             description: "Good This Good ",
    //             filterFrom: "repositoryActivityReport",
    //             search:
    //               '"[{"id":"-FUtb1qOWNHDoYk8762ZO","filters":[{"id":"_qaxeQEinIDECY-0mCUN7","criteria":"ifileName","condition":"CONTAINS","value":"inv","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""},{"id":"U7p52D4aaghu44s42miz3","filters":[{"id":"CZlM-1SXBmv61qyKEnNcg","criteria":"activityId","condition":"IS_EQUALS_TO","value":"3","valueTo":"","arrayValue":"3","dataType":"SINGLE_SELECT"}],"groupCondition":"and"}]"',
    //           },
    //           {
    //             rNo: 4,
    //             rId: 4,
    //             wId: 4,
    //             itemId: 74,
    //             pathId: "03062024101314",
    //             status: null,
    //             name: "perumal report",
    //             totalPages: 0,
    //             archivedFrom: "WEB",
    //             createdon: "2024-03-06T10:13:14.14Z",
    //             createdBy: "admin@ezofis.com",
    //             description: "Good This Good ",
    //             filterFrom: "repositoryActivityReport",
    //             search:
    //               '"[{"id":"nAn5Yj4ymJe3PoCLGhjy3","filters":[{"id":"XqLyzjRTc0UJrJDIxogfs","criteria":"ifileName","condition":"CONTAINS","value":"muthu","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""}]"',
    //           },
    //         ],
    //       },
    //     ],
    //   };

    //   let data = payload.data[0].value;
    //   this.listedData = data;

    //   let List = data.map((filter) => ({
    //     id: this.$nano.id(),
    //     label: filter.name,
    //     value: filter.name,
    //   }));

    //   const removeObserver = (obj) => {
    //     const newObj = {};
    //     Object.keys(obj).forEach((key) => {
    //       if (key !== "__ob__") {
    //         newObj[key] = obj[key];
    //       }
    //     });
    //     return newObj;
    //   };
    //   const arrayOfObjects = List.map((obj) => removeObserver(obj));
    //   this.WfilterList = [
    //     { id: this.$nano.id(), label: "Show All", value: "-1" },
    //     ...arrayOfObjects,
    //   ];
    // },

    // async filterList() {
    //   let payload = {
    //     data: [
    //       {
    //         key: "",
    //         value: [
    //           {
    //             rNo: 3,
    //             rId: 2,
    //             wId: 3,
    //             itemId: 73,
    //             pathId: "03062024101314",
    //             status: null,
    //             name: "arun report",
    //             totalPages: 0,
    //             archivedFrom: "WEB",
    //             createdon: "2024-03-06T10:13:14.14Z",
    //             createdBy: "admin@ezofis.com",
    //             description: "Good This Good ",
    //             filterFrom: "repositoryActivityReport",
    //             search:
    //               '"[{"id":"tUHW9Z3u214I93tltIjfp","filters":[{"id":"fubToAkBmIjGbApKwnn4-","criteria":"ifileName","condition":"CONTAINS","value":"zcm","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""}]"',
    //           },
    //           {
    //             rNo: 3,
    //             rId: 28,
    //             wId: 3,
    //             itemId: 73,
    //             pathId: "03062024101314",
    //             status: null,
    //             name: "arun report",
    //             totalPages: 0,
    //             archivedFrom: "WEB",
    //             createdon: "2024-03-06T10:13:14.14Z",
    //             createdBy: "admin@ezofis.com",
    //             description: "Good This Good ",
    //             filterFrom: "repositoryActivityReport",
    //             search:
    //             '"[{"id":"K0E8ZEcSLI4ofiwJu1FJh","filters":[{"id":"jcPEe8C2oDP-hjCoffIaC","criteria":"activityId","condition":"IS_EQUALS_TO","value":"5","valueTo":"","arrayValue":"5","dataType":"SINGLE_SELECT"}],"groupCondition":""}]"',
    //           },
    //           // {
    //           //   rNo: 3,
    //           //   rId: 28,
    //           //   wId: 3,
    //           //   itemId: 73,
    //           //   pathId: "03062024101314",
    //           //   status: null,
    //           //   name: "arun report",
    //           //   totalPages: 0,
    //           //   archivedFrom: "WEB",
    //           //   createdon: "2024-03-06T10:13:14.14Z",
    //           //   createdBy: "admin@ezofis.com",
    //           //   description: "Good This Good ",
    //           //   filterFrom: "repositoryActivityReport",
    //           //   search:
    //           //     '"[{"id":"-FUtb1qOWNHDoYk8762ZO","filters":[{"id":"_qaxeQEinIDECY-0mCUN7","criteria":"ifileName","condition":"CONTAINS","value":"inv","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""},{"id":"U7p52D4aaghu44s42miz3","filters":[{"id":"CZlM-1SXBmv61qyKEnNcg","criteria":"activityId","condition":"IS_EQUALS_TO","value":"3","valueTo":"","arrayValue":"3","dataType":"SINGLE_SELECT"}],"groupCondition":"and"}]"',
    //           // },
    //           //   {
    //           //     rNo: 4,
    //           //     rId: 4,
    //           //     wId: 4,
    //           //     itemId: 74,
    //           //     pathId: "03062024101314",
    //           //     status: null,
    //           //     name: "perumal report",
    //           //     totalPages: 0,
    //           //     archivedFrom: "WEB",
    //           //     createdon: "2024-03-06T10:13:14.14Z",
    //           //     createdBy: "admin@ezofis.com",
    //           //     description: "Good This Good ",
    //           //     filterFrom: "repositoryActivityReport",
    //           //     search:
    //           //       '"[{"id":"nAn5Yj4ymJe3PoCLGhjy3","filters":[{"id":"XqLyzjRTc0UJrJDIxogfs","criteria":"ifileName","condition":"CONTAINS","value":"muthu","valueTo":"","arrayValue":"","dataType":"SHORT_TEXT"}],"groupCondition":""}]"',
    //           //   },
    //         ],
    //       },
    //     ],
    //   };

    //   let data = payload.data[0].value;
    //   this.length = data.length;
    //   this.$emit('length',this.length);
    //   let payData = data;

    //   console.log(payData,"payData")
    //   for(let i = 0; i < payData.length; i++){
    //       let datas=payData[i]
    //   console.log(datas,"payData1")
    //     this.listedData=datas;
    //   };
    //   this.listedData.map((item) => {
    //     let search = item.search;
    //     const filterItem = search.slice(1, -1);
    //     const parsedArray = JSON.parse(filterItem);
    //     this.filterListData = {
    //       id: item.itemId,
    //       name: item.name,
    //       rId: item.rId,
    //       wId: item.wId,
    //       filterFrom: item.filterFrom,
    //       filters: parsedArray,
    //     };
    //   });
    //   console.log( this.filterListData,"payData2")
    //   this.repositoryId = this.filterListData.rId;
    //   this.filterFrom = this.filterListData.filterFrom;
    //   await this.getRepository();
    //   this.filterFrom = this.filterListData.filterFrom;
    //   this.repositoryId = this.filterListData.rId;
    //   this.itemId = 0;
    //   this.filterBy = this.filterListData.filters;
    //   this.getRepositoryData();
    // },

    async findItem() {
      console.log(this.listedData, "this.listedData");
      this.expand = true;
      this.loadingBarContent = true;
      this.listedData.map((item) => {
        if (item.name === this.findValue.label) {
          this.expand = true;

          const parsedArray = JSON.parse(item.filterJson);
          const filters = JSON.parse(parsedArray[0].filters);
          console.log(parsedArray, "parsedArray");
          this.filterListData = {
            id: item.itemId,
            name: item.name,

            rId: parsedArray[0].uId,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });

      this.filterFrom = this.filterListData.filterFrom;
      if (this.filterFrom === "repositoryActivityReport") {
        this.repositoryId = this.filterListData.rId;
        await this.getRepository();

        this.filterFrom = this.filterListData.filterFrom;
        this.repositoryId = this.filterListData.rId;
        this.itemId = 0;
        this.filterBy = this.filterListData.filters;
        this.getRepositoryData();
        console.log(this.filterListData, "this.filterListData");
      } else if (this.filterFrom === "repositoryOcrReport") {
        this.repositoryId = this.filterListData.rId;
        this.filterFrom = this.filterListData.filterFrom;
        this.repositoryId = this.filterListData.rId;
        this.itemId = 0;
        this.filterBy = this.filterListData.filters;
        this.getOcrReportData();
        console.log(this.filterListData, "this.filterListData");
      } else if (this.filterFrom === "workflowReport") {
        this.repositoryId = this.filterListData.rId;
        // await this.getWorkflows();
        this.repositoryId = this.filterListData.rId;
        await this.getWorkflow();
        await this.getTaskForm();
        await this.getForm();
        this.repositoryId = this.filterListData.rId;
        this.filterFrom = this.filterListData.filterFrom;
        this.repositoryId = this.filterListData.rId;
        this.itemId = 0;
        this.filterBy = this.filterListData.filters;
        await this.getWorkflowData();
        console.log(this.filterListData, "this.filterListData");
      } else if (this.filterFrom === "repositorySignReport") {
        this.repositoryId = this.filterListData.rId;
        this.filterFrom = this.filterListData.filterFrom;
        this.repositoryId = this.filterListData.rId;
        this.itemId = 0;
        this.filterBy = this.filterListData.filters;
        this.getSignReport();
        console.log(this.filterListData, "this.filterListData");
      }
    },
    refresh() {
      if (this.filterFrom === "repositoryActivityReport") {
        this.getRepository();
        this.getRepositoryData();
      } else if (this.filterFrom === "repositoryOcrReport") {
        this.getOcrReportData();
      } else if (this.filterFrom === "workflowReport") {
        this.getWorkflow();
        this.getTaskForm();
        this.getForm();
        this.getWorkflowData();
      } else if (this.filterFrom === "repositorySignReport") {
        this.getSignReport();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#repository-reports {
  background-color: white;
  padding: 14px;
  border-radius: 10px;
  margin: 5px;
  margin-top: 10px;
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 300px);
  }

  .backGround {
    border: 2px;
    border-radius: 10px;
    background-color: #87dcf626;
    margin-bottom: 8px;
  }
  .ellipsis:hover {
    word-wrap: break-word;
    white-space: initial;
    color: #00bcd4;
    overflow: visible;
  }
  .filerItem {
    background-color: rgb(234 235 185);
    border-radius: 20px;
    margin-top: 5px;
  }
  .icon-container {
    display: inline-block;
    position: relative;
  }

  .icon {
    font-size: 24px;
    cursor: pointer;
  }

  .iconcontent {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    width: max-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateX(-10px);
  }

  .icon:hover + .iconcontent {
    display: block;
    opacity: 1;
    transform: translateX(0);
  }
}
</style>

<style lang="scss">
#select-field.fieldList {
  .select {
    min-height: 0px !important;
    color: $secondary !important;
    width: 140px !important;
    overflow: hidden !important;
  }
}

#text-field.textField {
  input {
    min-height: 35px;
    width: 140px;
  }
}

#requestDetails {
  table {
    &.submittedData {
      background: $primary-1;
    }
    &.mainData {
      table-layout: fixed;
    }
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--divider-color);

    tr {
      height: 36px;

      th {
        color: $primary-11;
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color);
    }

    tr:not(:last-child) {
      td,
      th {
        border-bottom: 1px solid var(--divider-color);
      }
    }

    .tblHeader {
      color: $primary-11;
      font-weight: 500;
      &.small {
        width: 200px;
      }
    }
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .text-underline {
    text-decoration: underline;
  }
}

#sheet .mailTransactionList {
  padding: 12px;

  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--divider-color);
  }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#sheet .mailTransactionList {
  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}

#sheet {
  #form-view {
    background-color: #ffffff;
    margin: auto;
    padding: 16px;

    .header {
      padding: 16px;
      border-radius: 4px;
      background-color: var(--component-bg-color);
      border-top: 4px solid var(--primary);
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      .description {
        @extend .text-sm;
        color: var(--icon-color-inverted);
      }
    }

    .footer {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 0;
      bottom: 16px;
      width: 100%;

      .label {
        @extend .text-sm;
        color: var(--icon-color-inverted);
        margin-right: 4px;
      }

      img {
        height: 16px;
        width: auto;
      }
    }
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .attachmentList,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#modal #processHistory {
  #history {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    #attachments {
      width: 98%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 5px;
      background: #fcfafa;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }
}
</style>
