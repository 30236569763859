<template>
  <div id="connections">
    <!-- label -->

    <FormFieldLabel label="connections" is-mandatory />

    <!-- ... -->

    <!-- connections -->

    <table>
      <thead>
        <tr>
          <th>Block</th>
          <th>Action</th>
          <th class="medium">Remarks</th>
          <th class="medium">Password</th>
          <th class="medium">Confirm</th>
          <th class="medium">Signature</th>
          <th class="small"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="rule in value">
          <tr :key="rule.id">
            <td>
              <div
                class="text-xs"
                :style="{ marginBottom: '2px', color: '#A65EEA' }"
              >
                {{ rule.label }}
              </div>
              <div class="text-weight-medium">{{ rule.type }}</div>
            </td>
            <td class="q-pr-lg">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="{ required: true }"
              >
                <template
                  v-if="
                    rule.blockType === 'CONDITION' || rule.blockType === 'RULES'
                  "
                >
                  <SelectField
                    v-model="rule.proceedAction"
                    is-mandatory
                    new-option
                    :options="proceedConditionList"
                    :error="errors[0]"
                  />
                </template>
                <template v-else>
                  <SelectField
                    v-model="rule.proceedAction"
                    is-mandatory
                    new-option
                    :options="fillActions(rule)"
                    :error="errors[0]"
                  />
                </template>
              </ValidationProvider>
            </td>
            <td class="custom-center">
              <CheckboxField v-model="rule.remarks" />
            </td>
            <td class="custom-center">
              <CheckboxField v-model="rule.passwordAccess" />
            </td>
            <td class="custom-center">
              <CheckboxField v-model="rule.confirm" />
            </td>
            <td class="custom-center">
              <CheckboxField v-model="rule.signature" />
            </td>
            <td>
              <BaseActionButton
                v-tooltip.top="'delete connection'"
                is-flat
                color="red"
                icon="eva-close-outline"
                no-border
                @click="removeConnection(rule)"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <!-- ... -->

    <!-- error -->

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->

    <template v-if="blockType !== 'WORKFLOW_CONNECTOR'">
      <FormFieldLabel label="General Actions" class="action-header" />

      <CheckboxField
        v-model="userReply"
        label="Enable 'Reply' Action"
        description="Send requests to users who last took action"
        class="field"
        @input="$emit('update:userReply', userReply)"
      />

      <CheckboxField
        v-model="toInitiator"
        label="Enable 'To Requester' Action"
        description="Send requests directly to the initiator"
        class="field"
        @input="$emit('update:toInitiator', toInitiator)"
      />
    </template>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import { ValidationProvider } from "vee-validate";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";

export default {
  name: "Connections",

  components: {
    FormFieldLabel,
    FormFieldError,
    ValidationProvider,
    SelectField,
    CheckboxField,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    userReply: {
      type: Boolean,
      default: false,
    },

    toInitiator: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    blockType: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      proceedActionList: [
        {
          id: this.$nano.id(),
          label: "Submit",
          value: "Submit",
        },
        {
          id: this.$nano.id(),
          label: "Approve",
          value: "Approve",
        },
        {
          id: this.$nano.id(),
          label: "Reject",
          value: "Reject",
        },
        {
          id: this.$nano.id(),
          label: "Complete",
          value: "Complete",
        },
        {
          id: this.$nano.id(),
          label: "Close",
          value: "Close",
        },
      ],
      proceedConditionList: [
        {
          id: this.$nano.id(),
          label: "Satisfied",
          value: "Satisfied",
        },
        {
          id: this.$nano.id(),
          label: "Not Satisfied",
          value: "Not Satisfied",
        },
      ],
    };
  },

  methods: {
    removeConnection(rule) {
      this.$emit("remove", rule);
    },

    fillActions(rule) {
      let found = this.proceedActionList.filter(
        (action) => action.value === rule.proceedAction
      );
      if (found.length === 0 && rule.proceedAction) {
        this.proceedActionList.push({
          id: this.$nano.id(),
          label: rule.proceedAction,
          value: rule.proceedAction,
        });
      }
      return this.proceedActionList;
    },
  },
};
</script>

<style lang="scss" scoped>
#connections {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    thead {
      border-bottom: 1px solid var(--divider-color);
    }

    tr {
      th {
        text-align: left;
        color: var(--icon-color-inverted);
        font-weight: normal;
        font-size: 0.8125rem;
      }
    }

    td {
      padding-top: 10px;
    }

    .small {
      width: 5%;
    }

    .medium {
      width: 10%;
    }

    .custom-center {
      text-align: center;
    }
  }
}
</style>
<style lang="scss">
#connections {
  .custom-center {
    #checkbox-field .option {
      display: unset !important;
    }
  }

  .field {
    margin: 16px 16px 16px 0px;
  }

  .action-header {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 10px;
  }
}
</style>
