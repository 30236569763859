<template>
  <div id="process-item-list">
    <slot v-if="dummydata"></slot>
    <table v-if="items.length">
      <thead>
        <tr>
          <!-- avatar -->

          <th class="sticky icon" style="left: 0; width: 36px"></th>

          <!-- ... -->

          <!-- title -->

          <th v-if="title" class="sticky" style="left: 36px">
            <slot name="titleHead" :title="title.label">
              {{ title.label }}
            </slot>
          </th>

          <!-- ... -->

          <!-- mlcondition -->

          <th v-if="mlCondition">
            <slot name="titleHead" :title="mlCondition.label">
              {{ mlCondition.label }}
            </slot>
          </th>

          <!-- ... -->

          <!-- columns -->

          <th v-for="column in _columns" :key="column.id">
            {{ column.label }}
          </th>

          <!-- ... -->

          <th class="" style="right: 0"></th>

          <!-- ... -->
        </tr>
      </thead>
      <tbody>
        <template v-for="group in groupKey">
          <tr v-if="group.name" :key="group.name" class="background">
            <!-- && groupRequestListName(group.name) -->
            <td colspan="4" class="sticky" style="left: 0">
              <div v-if="group.name" :key="group.name" class="row">
                <div class="group-by col-auto">
                  <CheckboxField
                    v-if="group.checkboxRequired"
                    :value="group.checked"
                    class="col-auto check-group-by"
                    @input="updateSelectionGroup(group)"
                  />
                  <div class="col-auto">
                    <div class="groupLabel">
                      {{ group.name + " (" + group.totalCount + ")" }}
                    </div>
                  </div>
                  <BaseIcon
                    :name="group.expand ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                    class="cursor-pointer q-ml-sm"
                    @click="group.expand = !group.expand"
                  />
                </div>
                <div class="q-ml-sm">
                  <BaseActionButton
                    v-if="groupSelected(group.name) && group.actions.length"
                    v-tooltip.top="'Actions'"
                    icon="eva-more-horizontal-outline"
                    size="16px"
                    color="primary-5"
                  >
                    <q-menu
                      transition-show="scale"
                      transition-hide="scale"
                      anchor="bottom end"
                      self="top right"
                    >
                      <ListItem
                        v-for="action in group.actions"
                        :key="action.id"
                        :label="action.name"
                        :icon="action.icon"
                        :color="action.color"
                        @click="$emit('action', action, group)"
                      />
                    </q-menu>
                  </BaseActionButton>
                </div>
              </div>
            </td>
          </tr>
          <template v-if="group.expand">
            <template v-for="subGroup in group.subKey">
              <tr
                v-if="subGroup.name && group.subKey.length"
                :key="group.name + '-' + subGroup.name"
                class="background"
              >
                <td colspan="4" class="sticky">
                  <div
                    v-if="subGroup.name"
                    class="row"
                    style="margin-left: 15px"
                  >
                    <div class="sub-group-by col-auto">
                      <CheckboxField
                        v-if="subGroup.checkboxRequired"
                        :value="subGroup.checked"
                        class="col-auto check-group-by"
                        @input="updateSelectionGroup(subGroup)"
                      />
                      <div class="col-auto">
                        <div class="groupLabel">
                          {{ subGroup.name + " (" + subGroup.totalCount + ")" }}
                        </div>
                      </div>
                      <BaseIcon
                        :name="
                          subGroup.expand
                            ? 'mdi-chevron-down'
                            : 'mdi-chevron-up'
                        "
                        class="cursor-pointer q-ml-sm"
                        @click="subGroup.expand = !subGroup.expand"
                      />
                    </div>
                    <div class="q-ml-sm">
                      <BaseActionButton
                        v-if="
                          groupSelected(subGroup.name) &&
                          subGroup.actions.length
                        "
                        v-tooltip.top="'Actions'"
                        icon="eva-more-horizontal-outline"
                        size="16px"
                        color="primary-5"
                      >
                        <q-menu
                          transition-show="scale"
                          transition-hide="scale"
                          anchor="bottom end"
                          self="top right"
                        >
                          <ListItem
                            v-for="action in group.actions"
                            :key="action.id"
                            :label="action.name"
                            :icon="action.icon"
                            :color="action.color"
                            @click="$emit('action', action, group)"
                          />
                        </q-menu>
                      </BaseActionButton>
                    </div>
                  </div>
                </td>
              </tr>

              <template v-for="item in groupRequestList(group.name, subGroup)">
                <tr
                  v-if="item.visible && group.expand && subGroup.expand"
                  :key="group.name + '-' + item.id"
                  class="background"
                  @click="expandRow(item)"
                >
                  <!-- avatar -->

                  <td
                    class="sticky"
                    style="left: 0; width: 36px"
                    :style="{ background: actionColor(item.lastAction) }"
                  >
                    <div
                      class="items-center justify-center"
                      style="display: flex"
                    >
                      <slot name="avatar" :item="item">
                        <BaseIcon :name="item.icon" color="secondary" />
                      </slot>
                      <template v-if="item.subWorkflow">
                        <BaseIcon
                          v-if="item.subWorkflow.length"
                          :name="
                            item.expandSubworkflow
                              ? 'mdi-chevron-down'
                              : 'mdi-chevron-up'
                          "
                          class="cursor-pointer q-ml-sm"
                        />
                      </template>
                    </div>
                  </td>

                  <!-- ... -->

                  <!-- title -->

                  <td
                    v-if="title"
                    class="sticky"
                    style="
                      left: 36px;
                      left: 0;
                      width: 20%;
                      color: #007bff;
                      cursor: pointer;
                    "
                  >
                    <div>
                      <slot name="title" :item="item" :title="item[title.name]">
                        <div
                          class="font-medium ellipsis"
                          :class="item.link ? 'text-underline' : ''"
                          style="
                            overflow: hidden !important;
                            white-space: unset !important;
                            max-width: 250px;
                          "
                        >
                          {{ item[title.name] }}
                        </div>
                      </slot>
                    </div>
                  </td>

                  <!-- ... -->

                  <!-- mlcondition -->

                  <td v-if="mlCondition">
                    <slot
                      name="mlcondition"
                      :item="item"
                      :mlcondition="item[mlCondition.name]"
                    >
                      <div class="font-medium q-mr-xl">
                        {{ item[mlCondition.name] }}
                      </div>
                    </slot>
                  </td>

                  <!-- ... -->

                  <!-- columns -->

                  <td v-for="column in _columns" :key="column.id">
                    <slot
                      :name="column.name"
                      :value="item[column.name]"
                      :item="item"
                    >
                      <template v-if="column.dataType === 'TABLE'">
                        <template v-if="item[column.name]">
                          <BaseActionButton
                            v-tooltip.top="'Table Data'"
                            icon="mdi-table"
                            is-flat
                            no-border
                            @click.stop="
                              showTable(column.name, item[column.name])
                            "
                          />
                        </template>
                        <template v-else>
                          <BaseActionButton
                            icon="mdi-table"
                            is-flat
                            no-border
                            is-disabled
                          />
                        </template>
                      </template>
                      <template v-else-if="column.dataType === 'FILE_UPLOAD'">
                        <template v-if="item[column.name]">
                          <div
                            v-if="typeof item[column.name] === 'string'"
                            class="ellipsis medium"
                          >
                            <div
                              v-for="(file, index) in JSON.parse(
                                item[column.name]
                              )"
                              :key="index"
                              class="row"
                            >
                              <FileIcon
                                :mime-type="fileType(file.fileName)"
                                class="mini-avatar col-auto q-mr-sm"
                              />
                              <span
                                class="text-secondary file-underline col"
                                @click="viewFile(file, item)"
                                >{{ file.fileName }}</span
                              >
                            </div>
                          </div>
                          <div v-else class="ellipsis medium">
                            <div
                              v-for="(file, index) in item[column.name]"
                              :key="index"
                              class="row"
                            >
                              <FileIcon
                                :mime-type="fileType(file.fileName)"
                                class="mini-avatar col-auto q-mr-sm"
                              />
                              <span
                                class="text-secondary file-underline col"
                                @click="viewFile(file, item)"
                                >{{ file.fileName }}</span
                              >
                            </div>
                          </div>
                        </template>
                      </template>
                      <template
                        v-else-if="column.dataType === 'CURRENCY_AMOUNT'"
                      >
                        <template v-if="item[column.name]">
                          <template v-if="JSON.parse(item[column.name])">
                            <template
                              v-if="JSON.parse(item[column.name]).value"
                            >
                              {{ JSON.parse(item[column.name]).currency }}
                              {{ JSON.parse(item[column.name]).value }}
                            </template>
                            <template v-else> - </template>
                          </template>
                          <template v-else> - </template>
                        </template>
                        <template v-else> - </template>
                      </template>
                      <template v-else-if="column.dataType === 'PHONE_NUMBER'">
                        <template v-if="item[column.name]">
                          <template v-if="JSON.parse(item[column.name])">
                            <template
                              v-if="JSON.parse(item[column.name]).phoneNo"
                            >
                              {{ JSON.parse(item[column.name]).code }}
                              {{ JSON.parse(item[column.name]).phoneNo }}
                              <BaseIcon
                                v-if="JSON.parse(item[column.name]).verified"
                                v-tooltip.top="'Verified'"
                                name="mdi-check-all"
                                color="green"
                                class="q-ml-sm"
                              />
                            </template>
                            <template v-else> - </template>
                          </template>
                          <template v-else> - </template>
                        </template>
                        <template v-else> - </template>
                      </template>
                      <template v-else-if="column.dataType === 'SIGNATURE'">
                        <template v-if="item[column.name]">
                          <BaseActionButton
                            v-tooltip.top="'Signature'"
                            icon="eva-image-outline"
                            is-flat
                            no-border
                            @click.stop="
                              showImage(column.name, item[column.name])
                            "
                          />
                        </template>
                      </template>
                      <template v-else>
                        {{ item[column.name] || "-" }}
                      </template>
                    </slot>
                  </td>

                  <!-- ... -->

                  <!-- actions -->

                  <td class="sticky" style="right: 0">
                    <slot name="actions" :item="item"> </slot>
                  </td>

                  <!-- ... -->
                </tr>
                <template v-if="item.subWorkflow">
                  <template v-if="item.subWorkflow.length">
                    <tr
                      v-if="item.expandSubworkflow"
                      :key="group.name + '+' + item.id"
                    >
                      <td :colspan="_columns.length + 3">
                        <table
                          :key="`subtable${group.id}`"
                          class="subWorkflowTable"
                          style="border: 1px solid var(--divider-color)"
                        >
                          <thead>
                            <tr>
                              <th
                                class="sticky icon"
                                name="icon"
                                style="
                                  left: 0;
                                  background-color: rgb(222 217 247);
                                  width: 36px;
                                "
                              ></th>

                              <th
                                v-if="title"
                                class="sticky"
                                name="title"
                                style="
                                  left: 36px;
                                  background-color: rgb(222 217 247);
                                  width: 2%;
                                "
                              >
                                <div
                                  class="font-medium ellipsis"
                                  style="max-width: 250px"
                                >
                                  {{ title.label }}
                                </div>
                              </th>

                              <th
                                v-for="column in _subWorkflowColumns"
                                :key="column.id"
                                style="
                                  background-color: rgb(222 217 247);
                                  width: 2%;
                                "
                              >
                                {{ column.label }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="subItem in item.subWorkflow">
                              <tr :key="subItem.requestNo">
                                <td
                                  class="sticky icon"
                                  name="avatar"
                                  style="left: 0; width: 36px"
                                >
                                  <div
                                    class="row items-center sticky justify-center"
                                  >
                                    <BaseIcon
                                      v-tooltip.top="subItem.iconTooltip"
                                      :name="subItem.icon"
                                      :color="subItem.color"
                                    />
                                  </div>
                                </td>

                                <td
                                  class="sticky"
                                  name="title"
                                  style="
                                    left: 0;
                                    width: 20%;
                                    color: #007bff;
                                    cursor: pointer;
                                  "
                                >
                                  <div
                                    style="
                                      overflow: hidden !important;
                                      white-space: unset !important;
                                      width: 220px;
                                    "
                                    class="row items-center text-underline"
                                    @click="openSubProcess(subItem)"
                                  >
                                    {{ subItem.requestNo || "-" }}
                                  </div>
                                </td>
                                <td class="sub-background">
                                  {{ subItem.stage }}
                                </td>
                                <td class="sub-background">
                                  {{ subItem.raisedBy }}
                                </td>
                                <td class="sub-background">
                                  {{ subItem.raisedAt }}
                                </td>
                                <td>
                                  {{ subItem.duration }}
                                </td>
                                <td class="sticky" style="right: 0">
                                  <slot name="subactions" :item="subItem">
                                  </slot>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </table>
    <Modal
      v-model="expandTableModal"
      width="60vw"
      height="80vh"
      @input="expandTableModal = false"
    >
      <!-- title -->

      <template #title>{{ tableTitle }}</template>

      <!-- ... -->

      <template #default>
        <div id="tableData">
          <template v-if="tableData.cellvalues">
            <table>
              <tr v-for="(row, idx) in tableData.cellvalues" :key="idx">
                <th
                  :is="cell.kind === 'NoHeader' ? 'td' : 'th'"
                  v-for="cell in row"
                  :key="`r${idx}_c${cell.columnindex}`"
                >
                  {{ cell.cellvalue }}
                </th>
              </tr>
            </table>
          </template>
          <template v-else>
            <table>
              <thead>
                <tr>
                  <th v-for="(value, key) in tableData[0]" :key="key">
                    {{ key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in tableData" :key="index">
                  <td v-for="(value, key) in row" :key="key">
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
      </template>
    </Modal>

    <Modal
      v-model="expandImageModal"
      width="30vw"
      height="40vh"
      @input="expandImageModal = false"
    >
      <!-- title -->

      <template #title>{{ imageTitle }}</template>

      <!-- ... -->

      <template #default>
        <img :src="imageData" style="height: 150px" />
      </template>
    </Modal>
  </div>
</template>

<script>
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import ListItem from "@/components/common/ListItem.vue";
import Modal from "@/components/common/popup/Modal.vue";
import { mapState } from "vuex";

export default {
  name: "ItemList",

  components: {
    CheckboxField,
    ListItem,
    Modal,
  },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    groupKey: {
      type: Array,
      default: () => [],
    },

    groupItemSelected: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dummydata: false,
      expandTableModal: false,
      tableTitle: "",
      tableData: [],
      activeKeys: [],
      imageTitle: "",
      imageData: "",
      expandImageModal: false,
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    _columns() {
      return this.columns.filter(
        (column) =>
          column.type !== "TITLE" &&
          column.type !== "MLCONDITION" &&
          column.isVisible
      );
    },

    title() {
      return this.columns.find((column) => column.type === "TITLE");
    },

    mlCondition() {
      return this.columns.find((column) => column.type === "MLCONDITION");
    },

    _subWorkflowColumns() {
      let tableColumns = [
        {
          id: this.$nano.id(),
          name: "stage",
          label: "Stage",
          type: "SINGLE_SELECT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedBy",
          label: "Raised By",
          type: "SINGLE_SELECT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },

        {
          id: this.$nano.id(),
          name: "raisedAt",
          label: "Raised On",
          type: "DATE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "duration",
          label: "Duration",
          type: "SINGLE_SELECT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
      ];
      return tableColumns;
    },
  },

  methods: {
    groupRequestList(group, subGroup) {
      let rows = this.items.filter(
        (row) => row.key === group && row.subKey === subGroup.name
      );
      return rows;
    },

    groupRequestListName(group) {
      const matchingRows = this.items.filter((row) => row.key === group);
      if (
        matchingRows.length > 0 &&
        matchingRows.some((row) => row.visible === true)
      ) {
        return true;
      }
      return false;
    },

    groupSelected(group) {
      let groupList = this.items.filter((row) => row.key === group);
      let count = 0;
      groupList.forEach((process) => {
        if (process.checked) {
          count++;
        }
      });
      //this.$emit("groupItemSelected", count ? true : false);
      return count > 1 ? true : false;
    },

    updateSelectionGroup(group) {
      this.$emit("group", group);
    },

    actionColor(action) {
      if (this.darkTheme) {
        if (!action) {
          return "#7f7f7f80";
        }
        if (action.toLowerCase().indexOf("reply") !== -1) {
          return "#ca811680";
        } else if (action.toLowerCase().indexOf("forward") !== -1) {
          return "#fcf5bc80";
        } else if (action.toLowerCase().indexOf("approve") !== -1) {
          return "#d2bc0980";
        } else if (action.toLowerCase().indexOf("reject") !== -1) {
          return "#c9141480";
        } else {
          return "#7f7f7f80";
        }
      } else {
        if (!action) {
          return "#ffffff";
        }
        if (action.toLowerCase().indexOf("reply") !== -1) {
          return "#f9e6ca";
        } else if (action.toLowerCase().indexOf("forward") !== -1) {
          return "#fcf5bc";
        } else if (action.toLowerCase().indexOf("approve") !== -1) {
          return "#bfe2c0";
        } else if (action.toLowerCase().indexOf("reject") !== -1) {
          return "#f9c3c3";
        } else {
          return "#ffffff";
        }
      }
    },

    expandRow(item) {
      item.expandSubworkflow = !item.expandSubworkflow;
      this.dummydata = !this.dummydata;
    },

    openSubProcess(item) {
      this.$emit("openSubWorkflow", item);
    },

    showTable(name, value) {
      if (value) {
        this.tableTitle = name;
        this.tableData = JSON.parse(value);
        this.expandTableModal = true;
      }
    },

    showImage(name, value) {
      if (value) {
        this.imageTitle = name;
        this.imageData = value;
        this.expandImageModal = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#process-item-list {
  table {
    table-layout: fixed;
    max-width: 100%;
    &:not(.subWorkflowTable) {
      min-width: calc(100% - 1px);
    }
    border-collapse: collapse;
    white-space: nowrap;

    thead {
      tr {
        height: 36px;

        th {
          @extend .text-xs;
          padding: 8px 8px 16px;
          color: var(--icon-color-inverted);
          text-align: left;
          font-weight: normal;
          background-color: var(--body-bg-color);

          &.sticky {
            background-color: var(--body-bg-color);
            position: sticky;
          }
        }
      }
    }

    tbody {
      tr {
        border-top: 1px solid var(--divider-color);

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          border-top: none;
        }

        td {
          padding: 8px;
          height: 52px;
          background-color: #ffffff;

          &.sticky {
            // background-color: var(--component-bg-color);
            position: sticky;
            z-index: 1;
          }

          &.group-by {
            color: var(--deep-orange);
            font-weight: 500;
            background-color: transparent;
            padding: 36px 0px 16px;
            position: sticky;
            left: 0;
            z-index: 1;

            &:first-child {
              padding-top: 0px;
            }

            .key {
              color: var(--icon-color);
              text-transform: capitalize;
            }
          }

          .chip {
            padding: 8px;
            display: flex;
            align-items: center;
            border-radius: 4px;
            margin-right: 8px;
            color: white;

            .label {
              font-weight: 500;
              margin-right: 8px;
            }
          }

          :deep .stat {
            text-decoration: underline;

            &:hover {
              color: var(--secondary);
            }
          }
        }

        &.background td {
          background-color: var(--component-bg-color);
        }

        .sub-background {
          background-color: var(--component-bg-color);
          width: 20%;
        }
      }
    }
  }

  .group-by {
    //background-color: var(--deep-orange);
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    //color: white;
    border: 1px solid #e9a177;

    .groupLabel {
      font-weight: 500;
    }
  }

  .sub-group-by {
    //background-color: var(--deep-orange);
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    //color: white;
    border: 1px solid #4a2c1c;

    .groupLabel {
      font-weight: 500;
    }
  }

  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}

#modal #tableData {
  table {
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid var(--divider-color);
    border-radius: 10px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th {
      border-bottom: 1px solid var(--divider-color);
    }

    tr:not(:first-child) td {
      border-top: 1px solid var(--divider-color); /* Add top border to the first row */
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color); /* Add top border to the first row */
    }
  }
}
</style>
