<template>
  <BaseScrollbar height="calc(100vh - 138px)">
    <div id="panels" @click="deselectAll">
      <div class="wrapper">
        <!-- primary panels -->

        <Draggable
          v-if="panelType === 'PRIMARY_PANELS'"
          :value="panels"
          group="panels"
          @input="movePanels"
        >
          <div
            v-for="(panel, idx) in panels"
            :key="panel.id"
            class="panel flip-list draggable"
          >
            <Panel
              ref="panel"
              :panel="panel"
              :panel-type="panelType"
              :panels="panels"
              :secondary-panels="secondaryPanels"
              :is-selected="isPanelSelected(panel)"
              :form-settings="formSettings"
              :lookup-settings="lookupSettings"
              @update="updatePanel"
              @deselect="deselectAll"
              @move-up="movePanelUp(idx)"
              @select="selectPanel(panel)"
              @delete="deletePanel(panel)"
              @settings="showPanelSettings"
              @move-down="movePanelDown(idx)"
              @duplicate="duplicatePanel(idx)"
            />
          </div>
        </Draggable>

        <!-- ... -->

        <!-- secondary panels -->

        <template v-if="panelType === 'SECONDARY_PANELS'">
          <div v-for="(panel, idx) in panels" :key="panel.id" class="panel">
            <Panel
              ref="panel"
              :panel="panel"
              :panel-index="idx"
              :panel-type="panelType"
              :is-selected="isPanelSelected(panel)"
              :form-settings="formSettings"
              @update="updatePanel"
              @deselect="deselectAll"
              @select="selectPanel(panel)"
              @delete="deletePanel(panel)"
              @settings="showPanelSettings"
              @duplicate="duplicatePanel(idx)"
            />
          </div>
        </template>

        <!-- ... -->

        <!-- add panel -->

        <AddPanel @click="addPanel" />

        <!-- ... -->

        <!-- history -->

        <div class="history">
          <!-- undo -->

          <BaseActionButton
            v-tooltip:secondary.left="'undo'"
            icon="eva-refresh"
            no-border
            color="secondary"
            class="q-mb-sm flip-horizontal"
            @click="undo"
          />

          <!-- ... -->

          <!-- redo -->

          <BaseActionButton
            v-tooltip:secondary.left="'redo'"
            icon="eva-refresh"
            no-border
            color="secondary"
            @click="redo"
          />

          <!-- ... -->
        </div>

        <!-- ... -->

        <!-- panel settings -->

        <PanelSettings
          v-model="isPanelSettingsVisible"
          :panel="selectedPanel"
          @save="updatePanel"
        />

        <!-- ... -->
      </div>
    </div>
  </BaseScrollbar>
</template>

<script>
import { cloneDeep, isEmpty } from "lodash-es";
import Draggable from "@/components/common/Draggable.vue";
import History from "@/helpers/history.js";
import AddPanel from "./components/AddPanel.vue";
import Panel from "./components/panel/Panel.vue";
import PanelSettings from "./components/PanelSettings.vue";

export default {
  name: "Panels",

  components: { Draggable, AddPanel, Panel, PanelSettings },

  props: {
    panels: {
      type: Array,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    panelType: {
      type: String,
      required: true,
    },

    formSettings: {
      type: Object,
      required: true,
    },

    lookupSettings: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedPanel: {},
      panelsHistory: new History(),
      isPanelSettingsVisible: false,
    };
  },

  created() {
    if (this.panels.length) {
      return;
    }

    this.addPanel();
  },

  methods: {
    updatePanels(panels) {
      this.$emit("update", panels);
    },

    updatePanelsHistory(panels) {
      this.panelsHistory.add(panels);
    },

    movePanels(panels) {
      this.updatePanels(panels);
      this.updatePanelsHistory(panels);
    },

    deselectAll() {
      this.deselectPanel();
      this.$refs.panel.forEach((panel) => panel.deSelectField());
    },

    /* panel */

    isPanelSelected(panel) {
      if (isEmpty(this.selectedPanel)) {
        return false;
      }

      return this.selectedPanel.id === panel.id;
    },

    selectPanel(panel) {
      this.deselectAll();
      this.selectedPanel = panel;
    },

    deselectPanel() {
      this.selectedPanel = {};
    },

    addPanel() {
      const newPanel = {
        id: this.$nano.id(),
        settings: {
          title: "",
          description: "",
        },
        fields: [],
      };

      this.updatePanels([...cloneDeep(this.panels), newPanel]);
      this.updatePanelsHistory([...cloneDeep(this.panels), newPanel]);
    },

    updatePanel(panel) {
      const panels = cloneDeep(this.panels);
      const panelIdx = panels.findIndex((_panel) => _panel.id === panel.id);

      panels[panelIdx] = panel;

      this.updatePanels(panels);
      this.updatePanelsHistory(panels);
    },

    deletePanel(panel) {
      const panels = this.panels.filter((_panel) => _panel.id !== panel.id);
      this.updatePanels(panels);
      this.updatePanelsHistory(panels);
    },

    duplicatePanel(panelIdx) {
      const panel = this.panels[panelIdx];
      const duplicatePanel = cloneDeep(panel);
      duplicatePanel.id = this.$nano.id();

      duplicatePanel.fields.forEach((field) => {
        field.id = this.$nano.id();
        if (field.type === "TABLE") {
          field.settings.specific.tableColumns.forEach((column) => {
            column.id = this.$nano.id();
          });
        }
      });

      const panels = cloneDeep(this.panels);
      panels.splice(panelIdx + 1, 0, duplicatePanel);

      this.updatePanels(panels);
      this.updatePanelsHistory(panels);
    },

    movePanelUp(panelIdx) {
      if (panelIdx === 0) {
        return;
      }

      const panels = cloneDeep(this.panels);
      [panels[panelIdx], panels[panelIdx - 1]] = [
        panels[panelIdx - 1],
        panels[panelIdx],
      ];

      this.updatePanels(panels);
      this.updatePanelsHistory(panels);
    },

    movePanelDown(panelIdx) {
      if (panelIdx === this.panels.length - 1) {
        return;
      }

      const panels = cloneDeep(this.panels);
      [panels[panelIdx], panels[panelIdx + 1]] = [
        panels[panelIdx + 1],
        panels[panelIdx],
      ];

      this.updatePanels(panels);
      this.updatePanelsHistory(panels);
    },

    showPanelSettings() {
      this.isPanelSettingsVisible = true;
    },

    savePanelSettings(settings) {
      const panel = cloneDeep(this.panel);
      panel.settings = settings;

      this.$emit("update", panel);
    },

    /* ... */

    /* history fns */

    undo() {
      if (!this.panelsHistory.canUndo()) {
        return;
      }

      const panels = this.panelsHistory.undo();
      this.updatePanels(panels);
    },

    redo() {
      if (!this.panelsHistory.canRedo()) {
        return;
      }

      const panels = this.panelsHistory.redo();
      this.updatePanels(panels);
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#panels {
  height: calc(100% - 56px);
  outline: none;

  .wrapper {
    width: 820px;
    margin: auto;
    padding: 16px;
  }

  .history {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 16px;
    z-index: 1;
  }

  .panel {
    margin-bottom: 24px;
    outline: none;

    &.draggable {
      cursor: move;
    }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
