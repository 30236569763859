<template>
  <div id="group-browse">
    <Portal to="action-bar">
      <ItemActions
        v-if="!isLoading"
        item="group"
        :columns.sync="columns"
        :group-by.sync="groupBy"
        :order.sync="sortBy.order"
        :filter-by.sync="filterBy"
        :active-view.sync="activeView"
        :criteria.sync="sortBy.criteria"
        @refresh="getGroups"
        @search="searchRow"
        @update="getGroups"
        @export="export_Data"
      />
    </Portal>

    <div v-if="!isLoading" class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <GroupGrid
          v-if="activeView === 'GRID'"
          :columns="columns"
          :mode="mode"
          :items="items"
          @edit="editGroup"
          @more="showGroupDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />

        <GroupList
          v-if="activeView === 'LIST'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editGroup"
          @more="showGroupDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="mdi-account-group-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      v-if="!isLoading"
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
      @loadAll="loadAll"
    />

    <GroupDetails
      v-if="isGroupDetailsVisible"
      v-model="isGroupDetailsVisible"
      :columns="columns"
      :group="selectedGroup"
      @edit="editGroup"
      @delete="showDeleteConfirmation"
    />

    <ConfirmDeleteGroup
      v-model="isDeleteConfirmationVisible"
      :group="selectedGroup"
      @delete="updateGroup"
    />

    <ConfirmRestoreGroup
      v-model="isRestoreConfirmationVisible"
      :group="selectedGroup"
      @restore="updateGroup"
    />
  </div>
</template>

<script>
import { group } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import GroupGrid from "./components/GroupGrid.vue";
import GroupList from "./components/GroupList.vue";
import GroupDetails from "./components/GroupDetails.vue";
import ConfirmDeleteGroup from "./components/ConfirmDeleteGroup.vue";
import ConfirmRestoreGroup from "./components/ConfirmRestoreGroup.vue";
import { exportData } from "@/helpers/export-excel-data";

export default {
  name: "GroupsBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    ItemActions,
    ItemActionChips,
    GroupGrid,
    GroupList,
    GroupDetails,
    Pagination,
    ConfirmDeleteGroup,
    ConfirmRestoreGroup,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "users",
          label: "Users",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE,
        },
      ],
      groups: [],
      groupData: [],
      activeView: "GRID",
      selectedGroup: {},
      isGroupDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
    };
  },

  computed: {
    items() {
      return this.groups.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((group) => ({
          id: group.id,
          name: group.name,
          description: group.description,
          users: group.users || [],
          icon: "eva-people",
          createdBy: group.createdBy,
          createdAt: this.$day.format(group.createdAt),
          modifiedBy: group.modifiedBy,
          modifiedAt: this.$day.format(group.modifiedAt),
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.defaultView();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.mode, vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getGroups(),
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      if (Object.keys(options).length) {
        if (options[menu]) {
          let view = options[menu].find((item) => item.subMenu === subMenu);
          if (view) {
            this.activeView = view.view;
          }
        }
      }
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) {
        this.filterBy = [];
      }
      this.getGroups();
    },

    findGroup(groupId) {
      const groups = [];
      this.items.forEach((item) => groups.push(...item.data));
      return groups.find((group) => group.id === groupId);
    },

    showGroupDetails(groupId) {
      this.selectedGroup = this.findGroup(groupId);
      this.isGroupDetailsVisible = true;
    },

    editGroup(groupId) {
      this.$router.push({
        name: "group-builder",
        params: { id: String(groupId) },
      });
    },

    showDeleteConfirmation(groupId) {
      this.selectedGroup = this.findGroup(groupId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(groupId) {
      this.selectedGroup = this.findGroup(groupId);
      this.isRestoreConfirmationVisible = true;
    },

    searchRow(search) {
      if (search) {
        this.groups = this.groupData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.groups = this.groupData;
      }
    },

    loadAll() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    /* api functions */

    async getGroups() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await group.getGroups({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      this.groups = data || [];
      this.groupData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async updateGroup(payload) {
      this.$store.commit("showLoadingBarPage");

      const { error } = await group.updateGroup(this.selectedGroup.id, payload);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const mode = payload.isDeleted ? "deleted" : "restored";
      this.$alert.success(
        `Group "${this.selectedGroup.name}" ${mode} successfully`
      );

      this.getGroups();
    },

    async export_Data() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await group.getGroups({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { meta, data } = payload;
      if (meta.totalItems && data.length) {
        let exportRecords = [];
        data[0].value.forEach((group) => {
          let users = [];
          group.users.forEach((user) => {
            users.push(user.email);
          });
          exportRecords.push({
            Name: group.name,
            Description: group.description,
            Users: users.toString(),
            "Created By": group.createdBy,
            "Created At": this.$day.format(group.createdAt),
            "Last Modified By": group.modifiedBy,
            "Last Modified At": this.$day.format(group.modifiedAt),
          });
        });
        let exportName = "Group List - Report";
        if (this.mode === "TRASH") {
          exportName = "Group Trash List - Report";
        }
        exportData(exportRecords, exportName);
      } else {
        //this.$alert.info("No data found for export");
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#group-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
