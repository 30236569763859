<template>
  <div id="repository-builder">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title>
        <template v-if="mode === 'NEW'">Create Folder</template>
        <template v-if="mode === 'EDIT'">Edit Folder</template>
      </template>

      <template #description>
        <template v-if="mode === 'NEW'">
          Feel free to skip the optional fields and fill them later
        </template>
        <template v-if="mode === 'EDIT'">
          Some of the fields or non-editable
        </template>
      </template>

      <!-- ... -->

      <!-- action bar -->

      <ActionBar
        v-model="tab"
        :tabs="_tabs"
        :is-loading="isLoading"
        @save="mode === 'EDIT' ? updateRepository() : createRepository()"
      />

      <!-- ... -->

      <!-- content -->

      <BaseScrollbar height="calc(100vh - 126px)">
        <div class="row q-pb-xl">
          <ValidationObserver ref="form">
            <!-- workspace details -->

            <WorkspaceDetails
              ref="WORKSPACE_DETAILS"
              :mode="mode"
              :workspace-id.sync="workspaceId"
              :is-active="tab === 'WORKSPACE_DETAILS'"
              @click="tab = 'WORKSPACE_DETAILS'"
            />

            <!-- ... -->

            <BaseSeparator has-left-inset />

            <!-- repository details -->

            <RepositoryDetails
              ref="REPOSITORY_DETAILS"
              :mode="mode"
              :name.sync="name"
              :description.sync="description"
              :is-active="tab === 'REPOSITORY_DETAILS'"
              @click="tab = 'REPOSITORY_DETAILS'"
            />

            <!-- ... -->

            <BaseSeparator has-left-inset />

            <!-- storage details -->

            <StorageDetails
              ref="STORAGE_DETAILS"
              :mode="mode"
              :is-active="tab === 'STORAGE_DETAILS'"
              :cloud-file-server.sync="cloudFileServer"
              :local-file-server-path.sync="localFileServerPath"
              @click="switchTab('STORAGE_DETAILS')"
            />

            <!-- ... -->

            <BaseSeparator has-left-inset />

            <!-- fields details -->

            <FieldsDetails
              ref="FIELDS_DETAILS"
              :mode="mode"
              :fields.sync="fields"
              :dynamic-fields="dynamicFields"
              :fields-type.sync="fieldsType"
              :is-active="tab === 'FIELDS_DETAILS'"
              @click="switchTab('FIELDS_DETAILS')"
            />

            <!-- ... -->

            <BaseSeparator has-left-inset />

            <!-- auto indexing details -->

            <AutoIndexingDetails
              ref="AUTO_INDEXING_DETAILS"
              :mode="mode"
              :indexing-type.sync="indexingType"
              :credit-limit.sync="creditLimit"
              :constant-credit="constantCredit"
              :fields-type="fieldsType"
              :is-active="tab === 'AUTO_INDEXING_DETAILS'"
              @click="switchTab('AUTO_INDEXING_DETAILS')"
            />

            <!-- ... -->

            <BaseSeparator has-left-inset />

            <!-- security details -->

            <SecurityDetails
              ref="SECURITY_DETAILS"
              :mode="mode"
              :owner.sync="owner"
              :expiry-date.sync="expiryDate"
              :coordinator.sync="coordinator"
              :is-active="tab === 'SECURITY_DETAILS'"
              @click="switchTab('SECURITY_DETAILS')"
            />

            <!-- ... -->

            <BaseSeparator has-left-inset />

            <!-- version details -->

            <VersionDetails
              ref="VERSION_DETAILS"
              :mode="mode"
              :version-type.sync="versionType"
              :versioned-documents.sync="versionedDocuments"
              :is-active="tab === 'VERSION_DETAILS'"
              @click="switchTab('VERSION_DETAILS')"
            />

            <!-- ... -->

            <BaseSeparator v-if="fieldsType === 'STATIC'" has-left-inset />

            <!-- metadata details -->

            <MetadataDetails
              v-if="fieldsType === 'STATIC'"
              ref="METADATA_DETAILS"
              :mode="mode"
              :fields="fields"
              v-bind.sync="metaData"
              :is-active="tab === 'METADATA_DETAILS'"
              @click="switchTab('METADATA_DETAILS')"
            />

            <!-- ... -->

            <!-- lookup details -->
            <LookupDetails
              ref="CONNECTOR_DETAILS"
              :mode="mode"
              :connection.sync="connection"
              :connection-id.sync="connectionId"
              :hub-name.sync="hubName"
              :fields="repositoryfields"
              :value-mapping="valueMapping"
              :condition-mapping="conditionMapping"
              :out-data-columns="outDataColumns"
              :is-active="tab === 'CONNECTOR_DETAILS'"
              :edit-hub-name.sync="editHubName"
              @connectorHub="getConnectorHub"
            />
            <!-- ... -->
          </ValidationObserver>

          <q-space />

          <!-- quick access -->

          <QuickAccess v-if="mode === 'EDIT'" />

          <!-- ... -->
        </div>
      </BaseScrollbar>

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import ActionBar from "@/components/common/item-builder/ActionBar.vue";
import { ValidationObserver } from "vee-validate";

import WorkspaceDetails from "./components/WorkspaceDetails.vue";
import RepositoryDetails from "./components/RepositoryDetails.vue";
import StorageDetails from "./components/storage-details/StorageDetails.vue";
import FieldsDetails from "./components/fields-details/FieldsDetails.vue";
import SecurityDetails from "./components/SecurityDetails.vue";
import VersionDetails from "./components/VersionDetails.vue";
import MetadataDetails from "./components/MetadataDetails.vue";
import QuickAccess from "./components/QuickAccess.vue";
import AutoIndexingDetails from "./components/AutoIndexingDetails.vue";
import LookupDetails from "./components/LookupDetails.vue";

import { repository, connector } from "@/api/factory.js";
import { remove, lowerCase, cloneDeep } from "lodash-es";

export default {
  name: "RepositoryBuilder",

  components: {
    ItemBuilder,
    ValidationObserver,
    ActionBar,
    QuickAccess,
    WorkspaceDetails,
    RepositoryDetails,
    StorageDetails,
    FieldsDetails,
    SecurityDetails,
    VersionDetails,
    MetadataDetails,
    AutoIndexingDetails,
    LookupDetails,
  },

  props: {
    repositoryId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "WORKSPACE_DETAILS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "workspace",
          value: "WORKSPACE_DETAILS",
          for: ["STATIC", "DYNAMIC"],
        },
        {
          id: this.$nano.id(),
          label: "folder",
          value: "REPOSITORY_DETAILS",
          for: ["STATIC", "DYNAMIC"],
        },
        {
          id: this.$nano.id(),
          label: "storage",
          value: "STORAGE_DETAILS",
          for: ["STATIC", "DYNAMIC"],
        },
        {
          id: this.$nano.id(),
          label: "fields",
          value: "FIELDS_DETAILS",
          for: ["STATIC", "DYNAMIC"],
        },
        {
          id: this.$nano.id(),
          label: "Auto Indexing",
          value: "AUTO_INDEXING_DETAILS",
          for: ["STATIC", "DYNAMIC"],
        },
        {
          id: this.$nano.id(),
          label: "security",
          value: "SECURITY_DETAILS",
          for: ["STATIC", "DYNAMIC"],
        },
        {
          id: this.$nano.id(),
          label: "version",
          value: "VERSION_DETAILS",
          for: ["STATIC", "DYNAMIC"],
        },
        {
          id: this.$nano.id(),
          label: "metadata",
          value: "METADATA_DETAILS",
          for: ["STATIC"],
        },
        {
          id: this.$nano.id(),
          label: "connector",
          value: "CONNECTOR_DETAILS",
          for: ["STATIC"],
        },
      ],
      isLoading: false,

      // workspace details

      workspaceId: "",

      // repository details

      name: "",
      description: "",

      // storage details

      cloudFileServer: "EZOFIS",
      localFileServerPath: "",

      // field details

      fieldsType: "STATIC",
      fields: [],

      // auto indexing details

      indexingType: "MANUAL",

      // security details

      owner: [],
      coordinator: [],
      expiryDate: "",

      // version details

      versionType: "TIMESTAMP",
      versionedDocuments: "HIDE",

      // meta data details

      metaData: {
        title: [],
        subject: [],
        author: [],
        keywords: [],
        signature: "",
      },

      dynamicFields: [],

      // OCR credit Limit
      creditLimit: 0,
      constantCredit: 0,

      //lookup
      connection: "",
      connectionId: 0,
      hubName: 0,
      repositoryfields: [],
      valueMapping: [],
      conditionMapping: [],
      outDataColumns: [],
      connectorHub: [],
      hubLinkId: 0,
      editHubName: true,
    };
  },

  computed: {
    _tabs() {
      return this.tabs.filter((tab) => tab.for.includes(this.fieldsType));
    },
  },

  watch: {
    tab() {
      this.$refs[this.tab].$el.scrollIntoView({ behavior: "smooth" });
    },

    repositoryId: {
      immediate: true,
      handler() {
        if (this.repositoryId) {
          this.mode = "EDIT";
          this.getRepository();
          // this.getHubLinkDetails();
        } else {
          this.getDynamicFields();
        }
      },
    },

    // remove the deleted fields from the meta data

    fields: {
      deep: true,
      handler() {
        const fields = this.fields
          .filter((field) => field.isMandatory)
          .map((field) => field.name);

        remove(this.metaData.title, (field) => !fields.includes(field));
        remove(this.metaData.subject, (field) => !fields.includes(field));
        remove(this.metaData.author, (field) => !fields.includes(field));
        remove(this.metaData.keywords, (field) => !fields.includes(field));

        if (this.fields.length) {
          this.repositoryfields = [];
          let syncFieldMapping = [];

          if (this.hubLinkId) {
            syncFieldMapping = cloneDeep(this.valueMapping);
          }
          this.valueMapping = [];

          this.fields.forEach((item) => {
            this.repositoryfields.push({
              id: this.$nano.id(),
              label: item.name,
              value: item.name,
            });
            if (this.hubLinkId === 0) {
              this.valueMapping.push({
                id: this.$nano.id(),
                name: item.name,
                field: "",
              });
            } else {
              let syncField = syncFieldMapping.find(
                (row) => row.name === item.name
              );
              if (syncField) {
                this.valueMapping.push({
                  id: this.$nano.id(),
                  name: item.name,
                  field: syncField.field,
                });
              } else {
                this.valueMapping.push({
                  id: this.$nano.id(),
                  name: item.name,
                  field: "",
                });
              }
            }
          });
        }
      },
    },

    connection() {
      if (this.connection) {
        if (!this.repositoryId) {
          this.connectionId = 0;
        }
      }
    },

    hubName() {
      // console.log(this.hubName);
      if (this.hubName && this.editHubName) {
        let data = this.connectorHub.find((name) => name.id === this.hubName);
        if (data) {
          this.conditionMapping = [];
          this.outDataColumns = [];
          let inData = JSON.parse(data.inDataPoint);
          inData.forEach((item) => {
            this.conditionMapping.push({
              id: this.$nano.id(),
              name: item.key,
              field: "",
            });
          });
          let outData = data.outDataPoint ? JSON.parse(data.outDataPoint) : [];
          outData.forEach((item) => {
            // this.outData.push({
            //   name: item,
            //   field: "",
            // });
            this.outDataColumns.push({
              id: this.$nano.id(),
              label: item,
              value: item,
            });
          });
        }
      }
    },
  },

  created() {
    if (this.$store.state.session.appMode === "ON_PREMISE") {
      this.cloudFileServer = "LOCAL";
    }
  },

  methods: {
    closeBuilder() {
      this.$router.back();
    },

    switchTab(tab) {
      this.tab = tab;
    },

    async createRepository() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      if (this.fieldsType === "STATIC") {
        let levelFields = this.fields.filter((field) => field.level > 0);
        if (levelFields.length < 2) {
          this.$alert.warning("Folder structure requires 2 fields");
          return;
        }
      }

      this.isLoading = true;

      const input = {
        workspaceId: this.workspaceId,
        name: this.name,
        description: this.description,
        cloudFileServer: this.cloudFileServer,
        localFileServerPath: this.localFileServerPath,
        fieldsType: this.fieldsType,
        fields: this.fields,
        owner: this.owner,
        coordinator: this.coordinator,
        indexingType: this.indexingType,
        expiryDate: this.expiryDate,
        versionType: this.versionType,
        versionedDocuments: this.versionedDocuments,
        metaData: JSON.stringify(this.metaData),
      };

      const { error, payload } = await repository.createRepository(input);

      if (error) {
        if (error != "error creating repository") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        this.isLoading = false;
        return;
      }

      this.closeBuilder();
      this.$alert.success("New repository created succesfully");

      console.log(payload);
      let id = payload.split("rId: ");
      // var id = payload.replace(/[^0-9]/g, "");
      let rId = id.length ? id[1].split(" ") : [];

      console.log(rId);
      if (this.hubName && rId.length) {
        this.addHubLink(rId[0]);
      }
    },

    async getRepository() {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.indexingType = payload.indexingType || "STANDARD";

      this.workspaceId = payload.workspace?.id || 0;

      this.name = payload.name;
      this.description = payload.description;

      this.cloudFileServer = payload.cloudFileServer;
      this.localFileServerPath = payload.localFileServerPath;

      this.fieldsType = payload.fieldsType;
      this.fields = payload.fields.map((field) => ({
        _id: this.$nano.id(), // add _id
        ...field,
      }));

      if (this.fieldsType === "DYNAMIC") {
        this.dynamicFields = this.fields;
      }
      if (payload.ownerlist) {
        payload.ownerlist.forEach((item) => this.owner.push(item.id));
      }
      if (payload.coordinatorlist) {
        payload.coordinatorlist.forEach((item) =>
          this.coordinator.push(item.id)
        );
      }

      this.expiryDate = payload.expiryDate;

      this.versionType = payload.versionType;
      this.versionedDocuments = payload.versionedDocuments;
      this.creditLimit = payload.creditLimit;
      this.constantCredit = payload.creditLimit;

      if (payload.metaData) {
        this.metaData = JSON.parse(payload.metaData);
      } else {
        this.metaData = {
          title: [],
          subject: [],
          author: [],
          keywords: [],
          signature: "",
        };
      }

      this.getHubLink();
    },

    async updateRepository() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      if (this.fieldsType === "STATIC") {
        let levelFields = this.fields.filter((field) => field.level > 0);
        if (levelFields.length < 2) {
          this.$alert.warning("Folder structure requires 2 fields");
          return;
        }
      }

      this.isLoading = true;

      const payload = {
        id: this.repositoryId,
        workspaceId: this.workspaceId,
        name: this.name,
        description: this.description,
        cloudFileServer: this.cloudFileServer,
        localFileServerPath: this.localFileServerPath,
        fieldsType: this.fieldsType,
        fields: this.fields,
        owner: this.owner,
        coordinator: this.coordinator,
        indexingType: this.indexingType,
        expiryDate: this.expiryDate,
        versionType: this.versionType,
        versionedDocuments: this.versionedDocuments,
        metaData: JSON.stringify(this.metaData),

        creditLimit: this.creditLimit,
      };

      const { error } = await repository.updateRepository(
        this.repositoryId,
        payload
      );

      this.isLoading = false;

      if (error) {
        if (error != "error updating repository") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        return;
      }

      this.closeBuilder();
      this.$alert.success("Repository updated successfully");
      if (this.hubName) {
        this.addHubLink(this.repositoryId);
      }
    },

    async getDynamicFields() {
      const { error, payload } = await repository.getDynamicFields();
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.dynamicFields = payload.map((field) => ({
        _id: this.$nano.id(), // add _id
        name: field.name,
        dataType: field.dataType,
        isMandatory: field.levelId > 0,
        level: field.levelId,
        includeInFolderStructure: field.levelId > 0,
      }));
    },

    getConnectorHub(list) {
      this.connectorHub = list;
      this.hubName;
    },

    async addHubLink(id) {
      console.log(id);
      let payloadMapping = {};

      this.conditionMapping.forEach((item) => {
        this.$set(payloadMapping, `${item.name}`, `${item.field}`);
      });

      let responseMapping = {};

      this.valueMapping.forEach((item) => {
        let condition = this.conditionMapping.find(
          (column) => column.field === item.name
        );
        // console.log(item.name);
        // console.log(condition);
        if (!condition) {
          this.$set(responseMapping, `${item.name}`, `${item.field}`);
        }
      });

      const payload = {
        name: this.name,
        hubId: this.hubName,
        repositoryId: Number(id),
        formId: 0,
        payloadMapping: JSON.stringify(payloadMapping),
        responseMapping: JSON.stringify(responseMapping),
      };

      if (this.hubLinkId) {
        const { error } = await connector.updateHubLink(
          this.hubLinkId,
          payload
        );
        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        const { error } = await connector.addHubLink(payload);
        if (error) {
          this.$alert.error(error);
          return;
        }
      }
    },

    async getHubLink() {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await connector.getHubLink({
        filterBy: [
          {
            filters: [
              {
                criteria: "repositoryId",
                criteriaArray: ["repositoryId"],
                condition: "IS_EQUALS_TO",
                value: Number(this.repositoryId),
                arrayValue: [],
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(
      //   payload,
      //   this.$store.state.session.id,
      //   this.repositoryId,
      //   "lookup"
      // );
      let result = payload.filter(
        (item) => item.repositoryId === Number(this.repositoryId)
      );
      console.log(result, "result");

      if (result.length) {
        // this.hubName = result[0].hubId;
        this.hubLinkId = result[0].id;
        this.getHubLinkDetails(result[0].id);
        // let value = JSON.parse(result[0].responseMapping);
        // let map = JSON.parse(result[0].payloadMapping);
        // for (let key in value) {
        //   console.log(key);
        //   this.outDataColumns.push({
        //     id: this.$nano.id(),
        //     label: value[key],
        //     value: value[key],
        //   });
        // }
        // for (let key in map) {
        //   console.log(key);
        //   this.conditionMapping.push({
        //     id: this.$nano.id(),
        //     name: key,
        //     field: map[key],
        //   });
        // }
      }
    },

    async getHubLinkDetails(id) {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await connector.getHubLinkDetails(id);
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload);
      if (payload) {
        this.connection = lowerCase(payload.connection.connectorType);
        this.connectionId = payload.connection.id;
        this.connectorHub = [payload.connectorHub];
        this.editHubName = false;
        this.hubName = payload.connectorHub.id;
        let hubLink = payload.hubLink;
        let connectorHub = payload.connectorHub;
        // console.log(connectorHub);

        let outData = connectorHub.outDataPoint
          ? JSON.parse(connectorHub.outDataPoint)
          : [];
        // console.log(outData);
        outData.forEach((item) => {
          this.outDataColumns.push({
            id: this.$nano.id(),
            label: item,
            value: item,
          });
        });

        let value = JSON.parse(hubLink.responseMapping);
        let map = JSON.parse(hubLink.payloadMapping);
        this.valueMapping = [];
        for (let key in value) {
          // console.log(key);
          this.valueMapping.push({
            id: this.$nano.id(),
            name: key,
            field: value[key],
          });
        }
        for (let key in map) {
          // console.log(key, map[key], "conditionMapping");
          this.conditionMapping.push({
            id: this.$nano.id(),
            name: key,
            field: map[key],
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#repository-builder {
  height: 100vh;
}
</style>
