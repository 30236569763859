<template>
  <Sheet
    :value="value"
    :has-footer="!isReadonly"
    :width="sheetWidth"
    @input="close"
  >
    <!-- title -->

    <template #title>
      <!-- {{ addType ? "Add" : "Edit" }} Entry  -->
      {{ fileControlLabel }}
    </template>

    <template #actions>
      <template v-if="rows.length">
        <!-- <template v-if="rows.length > 1">
          <BaseActionButton
            v-if="rowIndex >= 1"
            v-tooltip.top="'Previous row'"
            is-flat
            icon="eva-arrowhead-left-outline"
            class="q-mr-sm"
            @click="previousRow"
          />

          <button id="current-row" class="q-mr-sm">{{ rowIndex + 1 }}</button>

          <BaseActionButton
            v-if="rowIndex + 1 !== rows.length"
            v-tooltip.top="'Next Row'"
            is-flat
            icon="eva-arrowhead-right-outline"
            class="q-mr-sm"
            @click="nextRow"
          />
          <BaseActionButton
            v-else
            v-tooltip.top="'Add Row'"
            is-flat
            icon="eva-plus"
            class="q-mr-sm"
            @click="addRow"
          />
        </template> -->
        <!-- <BaseActionButton
          v-else
          v-tooltip.top="'Add Row'"
          is-flat
          icon="eva-plus"
          class="q-mr-sm"
          @click="addRow"
        /> -->
      </template>
      <!-- <BaseActionButton
        v-else
        v-tooltip.top="'Add Row'"
        is-flat
        icon="eva-plus"
        class="q-mr-sm"
        @click="addRow"
      /> -->
    </template>
    <!-- <template #default>
      <div id="add-row-table">
        <div class="row items-center">
          <div class="col-5 q-mr-md file-list">
            <iframe
              id="file-frame"
              ref="myFiles"
              :src="fileLink"
              frameborder="0"
            ></iframe>
          </div>
          <div class="col-6">
            <div v-for="column in columns" :key="column.id" class="q-mb-md">
              <FormFieldLabel
                :label="column.label"
                :is-mandatory="isMandatoryColumn(column)"
                :tooltip="tooltip(column)"
              />

              <component
                :is="getField(column.type)"
                :key="column.id"
                v-model="rowModel[column.id]"
                :field="column"
                settings-for="TABLE"
                @uploadFiles="uploadFiles"
              />
            </div>
          </div>
        </div>
      </div>
    </template> -->

    <!-- <template #default> -->
    <div id="content-wrapper">
      <!-- <div v-if="hasFileControl" class="file-list">
        <iframe
          id="file-frame"
          ref="myFiles"
          :src="fileLink"
          frameborder="0"
        ></iframe>

        <div v-if="uploadedFiles.length > 1" class="row q-mt-sm q-ml-sm">
          <div
            v-for="(chip, idx) in uploadedFiles"
            :key="idx"
            class="chip col-auto q-mr-sm q-mt-sm"
            :class="
              chip.fileId === fileId ? 'same-background' : 'diff-background'
            "
            @click="openFile(chip)"
          >
            <div v-if="chip.name">{{ chip.name }}</div>
            <div v-if="chip.fileName">
              {{ chip.fileName }}
            </div>
          </div>
        </div>
      </div> -->

      <div class="full-side">
        <div class="q-pa-md">
          <!-- <div v-for="column in columns" :key="column.id" class="q-mb-md"> -->
          <!-- <template v-if="column.type !== 'FILE_UPLOAD'"> -->
          <!-- <FormFieldLabel
                :label="column.label"
                :is-mandatory="isMandatoryColumn(column)"
                :tooltip="tooltip(column)"
              /> -->
          <!-- </template>
            <template v-if="column.type === 'FILE_UPLOAD'">
              <component
                :is="getField(column.type)"
                :key="column.id"
                v-model="rowModel[column.id]"
                :field="column"
                settings-for="TABLE"
                :is-readonly="checkReadonly(rowIndex, column.id)"
                :row-index="rowIndex"
                :parent-control="checkParentControl(column.id)"
                :form-settings="formSettings"
                :is-mandatory="checkMandatory(rowIndex, column.id)"
                :filter="
                  tableFieldsModelOption.length
                    ? tableFieldsModelOption[rowIndex]?.[column.id]?.filter
                    : 0
                "
                :filter-by="
                  tableFieldsModelOption.length
                    ? tableFieldsModelOption[rowIndex]?.[column.id]?.filterBy
                    : []
                "
                :list-options="
                  tableFieldsModelOption.length
                    ? tableFieldsModelOption[rowIndex]?.[column.id]?.options
                    : []
                "
                :is-file-upload-new="true"
                @uploadFiles="uploadFiles"
                @updateOptions="updateOptions"
                @filterBy="filterChildControlValues"
                @readonlyControls="setReadonlyControls"
                @mandatoryControls="setMandatoryControls"
                @deletedFiles="deletedFiles"
                @showQr="showQr($event, rowIndex)"
              />
            </template> -->

          <!-- </div> -->
          <template v-if="!isReadonly">
            <div id="upload-file">
              <!-- <div class="title-2 q-mb-md">{{ label }}</div> -->

              <div class="row items-center">
                <div class="col-10">
                  Using a file explorer or file manager, which is an application
                  or interface that allows users to interact with files and
                  folders on their computer or device.

                  <!-- <BaseSeparator is-vertical has-inset class="q-mx-md" /> -->

                  <input
                    ref="fileUpload"
                    type="file"
                    class="file-input"
                    :multiple="isMultiple"
                    @input.stop="handleInput"
                  />
                </div>
                <div class="col-auto">
                  <q-chip
                    clickable
                    color="primary"
                    text-color="white"
                    @click="$refs.fileUpload.click()"
                    >Browse</q-chip
                  >
                </div>
              </div>
              <BaseSeparator class="q-my-md" />

              <div class="row items-center">
                <div class="col-10 q-mb-md">
                  Using a device (typically a smartphone or tablet) to read and
                  interpret a QR code (Quick Response code), which is a type of
                  two-dimensional barcode
                </div>
                <div class="col-auto">
                  <BaseActionButton
                    v-if="!isReadonly"
                    v-tooltip.top="'upload from anoter device'"
                    icon="mdi-qrcode-scan"
                    class=""
                    no-border
                    is-flat
                    @click="scanQr()"
                  />
                </div>
              </div>
            </div>
          </template>

          <div v-if="filesUploaded.length" class="row text-red q-mb-md">
            Note: Please allow an additional 2 or 5 minutes while our AI
            completes the extraction.
          </div>

          <div
            v-if="filesUploaded.length"
            id="file-upload-list"
            class="wrapper q-mb-md"
          >
            <div class="files">
              <div v-if="dummyData"></div>
              <div v-for="(file, index) in filesUploaded" :key="index">
                <div v-if="!file.error && file.name" class="row items-center">
                  <!-- <div class="col-auto q-mr-sm">{{ index + 1 }}.</div> -->
                  <div class="col-auto q-mr-sm" style="width: 23px">
                    <FileIcon :mime-type="fileType(file.name)" />
                  </div>
                  <div
                    class="col file-name cursor-pointer text-secondary q-mr-sm"
                    @click="viewFile(file, index)"
                  >
                    {{ file.name }}
                  </div>

                  <div
                    v-if="Number(file.uploadedPercentage) === 100"
                    class="col-auto"
                  >
                    <!-- <BaseActionButton
                      v-tooltip="'delete'"
                      is-flat
                      icon="eva-trash-outline"
                      color="red"
                      no-border
                      @click="deleteFile(index, file, 'file')"
                    /> -->
                  </div>
                  <div class="col-auto">
                    <q-chip
                      v-if="file.prediction"
                      outline
                      color="pink"
                      size="sm"
                      class="q-mr-sm"
                    >
                      <q-avatar
                        icon="mdi-auto-fix"
                        class="q-mr-sm"
                        color="pink"
                        text-color="white"
                      />
                      {{ file.prediction }}
                    </q-chip>
                  </div>
                  <div v-if="!file.error1" class="col-auto q-mr-md">
                    <div class="row items-center justify-end">
                      <template v-if="Number(file.uploadedPercentage) === 100">
                        <q-chip
                          size="13px"
                          outline
                          color="positive"
                          class="q-mr-sm"
                          >Uploaded</q-chip
                        >
                      </template>
                      <template v-else>
                        <q-chip
                          size="13px"
                          outline
                          color="warning"
                          class="q-mr-sm"
                          >{{
                            uploadingStatus(Number(file.uploadedPercentage))
                          }}</q-chip
                        >
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AdvanceView
            v-if="rows && filesUploaded.length && false"
            :table-columns="columns"
            :rows="rows.length"
          />
        </div>
      </div>
    </div>

    <!-- </template> -->
    <template v-if="!isReadonly" #footer>
      <!-- <div id="add-row-footer"> -->
      <BaseButton is-flat label="clear" class="q-mr-sm" @click="clearRow" />

      <BaseButton label="save" @click="saveRow" />
      <!-- </div> -->
    </template>

    <Sheet
      v-model="openFileSheetWithControl"
      width="40vw"
      :has-footer="!isReadonly && openFileSheetWithControl"
      @input="closesubSheet"
    >
      <template #title> {{ fileName }} </template>

      <div id="control-wrapper" class="q-ma-md">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink"
            frameborder="0"
          ></iframe>

          <!-- <div v-if="uploadedFiles.length > 1" class="row q-mt-sm q-ml-sm">
            <div
              v-for="(chip, idx) in uploadedFiles"
              :key="idx"
              class="chip col-auto q-mr-sm q-mt-sm"
              :class="
                chip.fileId === fileId ? 'same-background' : 'diff-background'
              "
              @click="openFile(chip)"
            >
              <div v-if="chip.name">{{ chip.name }}</div>
              <div v-if="chip.fileName">
                {{ chip.fileName }}
              </div>
            </div>
          </div> -->

          <div class="q-pb-md">
            <div
              v-for="column in columns"
              id="extracted-details"
              :key="column.id"
              class="q-mb-md"
            >
              <template v-if="column.type !== 'FILE_UPLOAD'">
                <div class="row items-center">
                  <div class="col-auto">
                    <FormFieldLabel
                      :label="column.label"
                      :is-mandatory="isMandatoryColumn(column)"
                      :tooltip="tooltip(column)"
                    />
                  </div>
                  <div
                    v-if="rowModel[column.id]"
                    class="col-auto q-ml-sm q-mb-sm"
                  >
                    <q-chip
                      v-if="extractedField[column.id]"
                      outline
                      square
                      color="green-5"
                      size="sm"
                    >
                      Extracted
                    </q-chip>
                  </div>
                </div>

                <component
                  :is="getField(column.type)"
                  :key="column.id"
                  v-model="rowModel[column.id]"
                  :field="column"
                  settings-for="TABLE"
                  :is-readonly="checkReadonly(rowIndex, column.id)"
                  :row-index="rowIndex"
                  :parent-control="checkParentControl(column.id)"
                  :form-settings="formSettings"
                  :is-mandatory="checkMandatory(rowIndex, column.id)"
                  :is-clearable="true"
                  :filter="
                    tableFieldsModelOption.length
                      ? tableFieldsModelOption[rowIndex]?.[column.id]?.filter
                      : 0
                  "
                  :filter-by="
                    tableFieldsModelOption.length
                      ? tableFieldsModelOption[rowIndex]?.[column.id]?.filterBy
                      : []
                  "
                  :list-options="
                    tableFieldsModelOption.length
                      ? tableFieldsModelOption[rowIndex]?.[column.id]?.options
                      : []
                  "
                  :is-file-upload-new="true"
                  @uploadFiles="uploadFiles"
                  @updateOptions="updateOptions"
                  @filterBy="
                    filterChildControlValues(
                      $event,
                      rowIndex,
                      rowModel[column.id]
                    )
                  "
                  @readonlyControls="setReadonlyControls"
                  @mandatoryControls="setMandatoryControls"
                  @deletedFiles="deletedFiles"
                />
              </template>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <!-- <div id="add-row-footer"> -->
        <q-chip
          outline
          color="secondary"
          size="md"
          clickable
          class="q-mr-md"
          @click="focusExtractedDetails()"
          >Extracted Details</q-chip
        >

        <BaseButton v-if="!isReadonly" label="save" @click="saveField" />

        <!-- <BaseButton is-flat label="clear" class="q-mr-sm" @click="clearRow" /> -->
        <!-- </div> -->
      </template>
    </Sheet>
  </Sheet>
</template>
<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import {
  cloneDeep,
  isEmpty,
  //   lowerCase,
  upperFirst,
  camelCase,
} from "lodash-es";
import ShortTextFieldWrapper from "@/components/common/form/nested-fields/ShortTextFieldWrapper.vue";
import SingleSelectFieldWrapper from "@/components/common/form/nested-fields/SingleSelectFieldWrapper.vue";
import MultiSelectFieldWrapper from "@/components/common/form/nested-fields/MultiSelectFieldWrapper.vue";
import NumberFieldWrapper from "@/components/common/form/nested-fields/NumberFieldWrapper.vue";
import CounterFieldWrapper from "@/components/common/form/nested-fields/CounterFieldWrapper.vue";
import CalculatedFieldWrapper from "@/components/common/form/nested-fields/CalculatedFieldWrapper.vue";
import DateFieldWrapper from "@/components/common/form/nested-fields/DateFieldWrapper.vue";
import TimeFieldWrapper from "@/components/common/form/nested-fields/TimeFieldWrapper.vue";
import ChipsFieldWrapper from "@/components/common/form/nested-fields/ChipsFieldWrapper.vue";
import FileUploadFieldWrapper from "@/components/common/form/nested-fields/FileUploadFieldWrapper.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import SingleChoiceFieldWrapper from "@/components/common/form/nested-fields/SingleChoiceFieldWrapper";
import MultipleChoiceFieldWrapper from "@/components/common/form/nested-fields/MultiChoiceFieldWrapper";
import ImageFieldWrapper from "@/components/common/form/nested-fields/ImageFieldWrapper";
import AdvanceView from "./AdvanceView.vue";
import { fileToBase64 } from "@/helpers/file-to-base64.js";
import FileIcon from "@/components/common/FileIcon.vue";

export default {
  name: "AddRow",
  components: {
    Sheet,
    ShortTextFieldWrapper,
    NumberFieldWrapper,
    CounterFieldWrapper,
    CalculatedFieldWrapper,
    DateFieldWrapper,
    TimeFieldWrapper,
    ChipsFieldWrapper,
    SingleSelectFieldWrapper,
    MultiSelectFieldWrapper,
    FileUploadFieldWrapper,
    FormFieldLabel,
    SingleChoiceFieldWrapper,
    MultipleChoiceFieldWrapper,
    ImageFieldWrapper,
    AdvanceView,
    FileIcon,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      default: () => [],
    },

    addType: {
      type: Boolean,
      default: false,
    },

    rows: {
      type: Array,
      default: () => [],
    },

    rowIndex: {
      type: Number,
      default: null,
    },

    repositoryDetails: {
      type: Object,
      default: () => {},
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    readonlyControlsList: {
      type: Array,
      default: () => [],
    },

    mandatoryControlsList: {
      type: Array,
      default: () => [],
    },

    tableFieldsModelOption: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      rowModel: {},
      hasFileControl: false,
      fileLink: "",
      uploadedFiles: [],
      fileId: 0,
      openFileSheetWithControl: false,
      sheetWidth: "40vw",
      fileName: "",
      fileControlLabel: "",
      showQRModal: false,
      extractedField: {},
      isMultiple: false,
      uploadingFiles: "",
      fileFieldId: "",
      filesUploaded: [],
      assignControls: [],
      hasClearRow: false,
      dummyData: false,
    };
  },

  computed: {},

  watch: {
    value: {
      immediate: true,
      handler() {
        console.log("watch");
        if (this.value) {
          this.columns.forEach((item) => {
            this.$set(this.rowModel, item.id, this.getDefaultValue(item));
            if (item.type === "FILE_UPLOAD") {
              // this.hasFileControl = true;
              this.fileControlLabel = item.label;
              this.fileFieldId = item.id;
            }
          });

          if (!this.addType) {
            this.rowModel = cloneDeep(this.rows[this.rowIndex]);
          }
        }
      },
    },

    rows: {
      deep: true,
      handler() {
        if (this.fileFieldId && this.rows.length && !this.hasClearRow) {
          // if (
          //   this.rows[this.rowIndex] &&
          //   this.rows[this.rowIndex][this.fileFieldId].length
          // ) {
          this.rows.forEach((row, index) => {
            let files = this.rows[index][this.fileFieldId];
            files.forEach((file) => {
              let unique = this.filesUploaded.findIndex((newFile) => {
                return file.name === newFile.name;
              });
              if (unique === -1) {
                this.filesUploaded.push({
                  ...file,
                  rowIndex: index,
                });
              } else {
                let attachment = {
                  ...file,
                  rowIndex: index,
                };
                // console.log(attachment, "attachment");
                this.filesUploaded[unique] = attachment;
              }
            });
            this.dummyData = !this.dummyData;
          });
          // console.log(this.filesUploaded, "filesUploaded");
          // this.filesUploaded.push(
          //   this.rows[this.rowIndex][this.fileFieldId][0]
          // );
          // }
        }
        if (this.showQRModal) {
          if (this.rows.length && !isEmpty(this.rows[this.rowIndex])) {
            if (this.filesUploaded.length === 1) {
              // this.rowModel = cloneDeep(this.rows[this.rowIndex]);
            } else {
              // this.rows.forEach(row=> {
              // })
            }
          }
        }
      },
    },

    rowModel: {
      deep: true,
      immediate: true,
      handler() {
        // console.log("hasFile");

        let files = false;
        let hasFile = this.columns.filter(
          (item) => item.type === "FILE_UPLOAD"
        );

        // console.log(hasFile);
        if (hasFile.length) {
          // console.log("hasFile");

          hasFile.forEach((file) => {
            // console.log(this.rowModel[file.id]);
            if (this.rowModel[file.id]) {
              if (this.rowModel[file.id].length) {
                this.rowModel[file.id].forEach((item) => {
                  let duplicate = this.uploadedFiles.findIndex((dup) => {
                    return dup.fileId === item.fileId;
                  });
                  let duplicate1 = this.uploadedFiles.findIndex((dup) => {
                    return dup.itemId === item.itemId;
                  });

                  if (
                    (duplicate === -1 && item.fileId) ||
                    (duplicate1 === -1 && file.itemId)
                  ) {
                    this.uploadedFiles.push(item);
                  }
                });
                let upload = this.rowModel[file.id].find((item) => item.fileId);
                let indexed = this.rowModel[file.id].find(
                  (item) => item.itemId
                );
                // console.log(indexed, upload);

                if (upload) {
                  files = true;
                  let type = 1;
                  if (this.repositoryDetails.fieldsType === "STATIC") {
                    type = 2;
                  }
                  let jsonId = JSON.parse(JSON.stringify(upload));
                  if (jsonId.fileId) {
                    this.fileId = jsonId.fileId;
                  }
                  // console.log(jsonId.fileId, jsonId, "fileId");
                  this.fileName = jsonId.name;

                  this.fileLink = `${process.env.VUE_APP_API_URL}/uploadAndIndex/view/${this.$store.state.session.tenantId}/${upload.fileId}/${type}`;
                } else if (indexed) {
                  if (indexed.itemId) {
                    this.fileId = indexed.itemId;
                  }
                  // console.log(indexed, "itemId");

                  files = true;
                  this.fileName = indexed.fileName;
                  this.fileLink = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${indexed.repositoryId}/${indexed.itemId}/2`;
                }
              } else {
                //
              }
            }
          });
        }

        this.hasFileControl = files;
        if (this.hasFileControl && !this.openFileSheetWithControl) {
          if (this.filesUploaded.length === 1 || !this.filesUploaded.length) {
            this.sheetWidth = "50vw";
            this.openFileSheetWithControl = true;
          }
        }
      },
    },

    openFileSheetWithControl() {
      if (this.openFileSheetWithControl) {
        if (!isEmpty(this.extractedField)) {
          for (let cell in this.rowModel) {
            // console.log(cell, this.rowModel[cell], "cell");
            if (cell in this.extractedField) {
              if (this.rowModel[cell]) {
                this.extractedField[cell] = cloneDeep(this.rowModel[cell]);
              }
            }
          }
        }
      }
    },
  },

  methods: {
    getField(fieldType) {
      return `${upperFirst(camelCase(fieldType))}FieldWrapper`;
    },

    getDefaultValue(field) {
      const defaultValue = field.settings.specific.defaultValue;

      if (defaultValue === "USER_EMAIL") {
        return this.$store.state.session.email;
      }

      if (defaultValue === "USER_NAME") {
        let name = "";
        if (this.$store.state.session.firstName) {
          name = this.$store.state.session.firstName;
        }
        if (this.$store.state.session.lastName) {
          name += " " + this.$store.state.session.lastName;
        }
        return name;
      }

      if (defaultValue === "LOGIN_NAME") {
        let name = "";
        if (this.$store.state.session.loginName) {
          name = this.$store.state.session.loginName;
        }
        return name;
      }

      if (defaultValue === "CURRENT_DATE") {
        return this.$day.newDate();
      }

      if (defaultValue === "CURRENT_TIME") {
        return this.$day.newTime();
      }

      if (defaultValue === "CURRENT_DATE_TIME") {
        return this.$day.newDateTime();
      }

      if (defaultValue === "AUTO_GENERATE") {
        const prefix = field.settings.specific.autoGenerateValue.prefix;
        const suffixFormat = field.settings.specific.autoGenerateValue.suffix;

        const suffixFormats = {
          DATE_TIME: "YYYYMMDDhhmmss",
          DATE: "YYYYMMDD",
          TIME: "hhmmss",
        };

        const suffix = this.$day.customFormat(suffixFormats[suffixFormat]);

        return `${prefix} - ${suffix}`;
      }
      if (defaultValue === "CUSTOM") {
        return (
          field.settings.specific.customDefaultValue ||
          this.getEmptyDefaultValue(field.type)
        );
      }
    },

    getEmptyDefaultValue(fieldType) {
      switch (fieldType) {
        case "MULTI_SELECT":
          return [];
        case "MULTIPLE_CHOICE":
        case "FILE_UPLOAD":
        case "IMAGE_UPLOAD":
        case "TABLE":
        case "MATRIX":
        case "CHIPS":
        case "NESTED_LIST":
          return [];
        case "COUNTER":
        case "CALCULATED":
        case "RATING":
          return 0;
        case "FILL_IN_THE_BLANKS":
        case "POPUP":
        case "TABS":
          return {};
        default:
          return "";
      }
    },

    isMandatoryColumn(column) {
      return column.settings.validation.fieldRule === "REQUIRED";
    },

    tooltip(column) {
      return column.settings.general.tooltip;
    },

    close() {
      this.$emit("close");
    },

    saveRow() {
      // if(this.addType) {

      // }
      let proceed = true;

      let hasFile = this.columns.filter((item) => item.type === "FILE_UPLOAD");
      // console.log(hasFile);
      if (hasFile.length) {
        // console.log("hasFile");
        hasFile.forEach((file) => {
          // console.log(this.rowModel[file.id]);

          if (this.rows[this.rowIndex][file.id].length) {
            // console.log("hasFile");

            proceed = false;
          }
        });
      }
      if (proceed) {
        this.$emit("save", this.rowModel);
      } else if (this.showQRModal) {
        // this.$emit("saveQR", this.rowModel);
        this.showQRModal = false;
      }
      this.$emit("close");

      // this.columns.forEach((item) => {
      //   this.rowModel[item.id] = this.getEmptyDefaultValue(item.type);
      // });
    },

    scanQr() {
      let hasFile = this.columns.find((item) => item.type === "FILE_UPLOAD");
      if (hasFile) {
        this.showQr(hasFile.id, this.rowIndex);
      }
    },

    async handleInput(e) {
      let files = e.target.files;
      let assignControls = [];
      let aiSettings = {};
      let hasFile = this.columns.find((item) => item.type === "FILE_UPLOAD");
      if (hasFile) {
        assignControls = hasFile.settings.validation.assignOtherControls;
        this.assignControls = assignControls;
        if (hasFile.settings.aiSettings) {
          aiSettings = hasFile.settings.aiSettings;
        }
      }
      let emitValue = {
        files: files,
        assignControls: assignControls,
        fieldId: hasFile.id,
        aiSettings: aiSettings,
      };
      let uploadFile = Array.from(e.target.files);
      let _files = [];
      for (let file of uploadFile) {
        if (file.name) {
          const base64String = await fileToBase64(file);

          _files.push({
            name: file.name,
            size: file.size,
            file: base64String,
          });
        }
      }
      // this.rowModel[hasFile.id] = _files;
      // this.$emit("save", this.rowModel);
      this.uploadFiles(emitValue, _files);
    },

    uploadFiles(files, _files) {
      // console.log(files, _files, "files");
      this.extractedField = {};
      if (files.assignControls) {
        if (files.assignControls.length) {
          files.assignControls.forEach((fieldId) => {
            this.$set(this.extractedField, fieldId, "");
          });
        }
      }
      this.showQRModal = false;
      // console.log(this.rowIndex, "rowIndex");
      if (this.rowIndex >= 0 && this.rows.length) {
        if (this.rows[this.rowIndex]) {
          let hasFile = this.rows[this.rowIndex][files.fieldId];
          if (hasFile && hasFile.length) {
            this.$emit("update:rowIndex", this.rowIndex + 1);
            // console.log(this.rowIndex, "rowIndex index ");
          }
        }
      }
      this.uploadingFiles = cloneDeep(files);
      // this.$emit("save", this.rowModel, _files);
      this.rowModel[this.fileFieldId] = cloneDeep(_files);
      this.$emit("save", cloneDeep(this.rowModel));
      this.$emit("uploadFiles", files);
    },

    previousRow() {
      this.rowModel = this.rows[this.rowIndex - 1];
      this.$emit("update:rowIndex", this.rowIndex - 1);
      this.$emit("update:addType", false);
    },

    nextRow() {
      this.rowModel = this.rows[this.rowIndex + 1];
      this.$emit("update:rowIndex", this.rowIndex + 1);
    },

    addRow() {
      if (this.addType) {
        // console.log(this.rowModel, "this.rowModel");
        // this.$emit("save", this.rowModel);
      }
      // this.columns.forEach((item) => {
      //   this.rowModel[item.id] = this.getEmptyDefaultValue(item.type);
      // });

      // this.$emit("update:rowIndex", this.rowIndex + 1);
      this.$emit("update:addType", true);
    },

    openFile(file) {
      let type = 1;
      if (this.repositoryDetails.fieldsType === "STATIC") {
        type = 2;
      }
      if (file.fileId) {
        this.fileId = file.fileId;
        this.fileLink = `${process.env.VUE_APP_API_URL}/uploadAndIndex/view/${this.$store.state.session.tenantId}/${file.fileId}/${type}`;
      } else if (file.itemId) {
        this.fileLink = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${file.repositoryId}/${file.itemId}/2`;
      }
    },

    clearRow() {
      // this.columns.forEach((item) => {
      //   this.rowModel[item.id] = this.getEmptyDefaultValue(item.type);

      //   if (item.id in this.extractedField) {
      //     this.extractedField[item.id] = this.getEmptyDefaultValue(item.type);
      //   }
      // });
      // console.log(this.filesUploaded);
      let rowIndex = [];
      this.hasClearRow = true;
      this.filesUploaded.forEach((file) => {
        console.log(file.rowIndex, "rowIndex");
        if (file.rowIndex >= 0) {
          rowIndex.push(file.rowIndex);
          // this.filesUploaded.splice(file.rowIndex, 1);
        }
      });
      this.$emit("clearRow", this.fileFieldId, rowIndex);
      this.filesUploaded = [];
    },

    checkReadonly(index, fieldId) {
      if (this.isReadonly) {
        return true;
      } else {
        if (this.readonlyControlsList.length) {
          let readonly = this.readonlyControlsList.find(
            (list) => list.rowIndex === index && list.id === fieldId
          );
          // console.log(readonly);
          if (readonly) {
            return readonly.isReadonly;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },

    checkParentControl(fieldId) {
      let parentFieldFound = false;
      this.columns.forEach((field) => {
        if (field.settings.specific.masterFormParentColumn) {
          if (fieldId === field.settings.specific.masterFormParentColumn) {
            parentFieldFound = true;
            return;
          }
        }
      });
      this.columns.forEach((field) => {
        if (field.settings.specific.repositoryFieldParent) {
          if (fieldId === field.settings.specific.repositoryFieldParent) {
            parentFieldFound = true;
            return;
          }
        }
      });
      return parentFieldFound;
    },

    checkMandatory(index, fieldId) {
      if (this.mandatoryControlsList.length) {
        let mandatory = this.mandatoryControlsList.find(
          (list) => list.rowIndex === index && list.id === fieldId
        );
        // console.log(mandatory);
        if (mandatory) {
          return mandatory.isMandatory;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    updateOptions(field, rowIndex, options) {
      this.$emit("updateOptions", field, rowIndex, options);
    },

    filterChildControlValues(field, rowIndex, value) {
      this.$emit("filterChildControlValues", field, rowIndex, value);
    },

    setReadonlyControls(controls, settings, immediate, fieldId, rowIndex) {
      this.$emit(
        "setReadonlyControls",
        controls,
        settings,
        immediate,
        fieldId,
        rowIndex
      );
      controls.forEach((item) => {
        // console.log(item, "item");
        if (item in this.rowModel) {
          this.rowModel[item] = "";
        }
      });
    },

    setMandatoryControls(controls, settings, immediate, fieldId, rowIndex) {
      this.$emit(
        "setMandatoryControls",
        controls,
        settings,
        immediate,
        fieldId,
        rowIndex
      );
    },

    deletedFiles(ids) {
      this.$emit("deletedFiles", ids, this.rowIndex);
      this.clearRow();
    },

    closesubSheet() {
      this.openFileSheetWithControl = false;
      this.sheetWidth = "40vw";
    },

    saveField() {
      this.closesubSheet();
      const model = cloneDeep(this.rowModel);
      // console.log(model, this.uploadingFiles, "modal");
      if (!this.showQRModal) {
        if (this.addType) {
          this.$emit("save", model, this.uploadingFiles);
        } else {
          let emitValue = {
            files: this.uploadedFiles,
            fieldId: this.fileFieldId,
          };
          this.$emit("save", model, emitValue);
        }
      } else {
        let emitValue = {
          files: this.uploadedFiles,
          fieldId: this.fileFieldId,
        };
        this.$emit("save", model, emitValue);
      }

      this.fileId = "";
      this.fileLink = "";
      this.fileName = "";
      this.columns.forEach((item) => {
        this.rowModel[item.id] = cloneDeep(
          this.getEmptyDefaultValue(item.type)
        );

        if (item.id in this.extractedField) {
          this.extractedField[item.id] = cloneDeep(
            this.getEmptyDefaultValue(item.type)
          );
        }
      });
    },

    showQr(fieldId, index) {
      this.showQRModal = true;
      this.$emit("showQr", fieldId, index);
      let controlId = this.columns.find((column) => column.id === fieldId);
      if (controlId) {
        let controls = controlId.settings.validation.assignOtherControls;
        if (controls) {
          if (controls.length) {
            controls.forEach((fieldId) => {
              this.$set(this.extractedField, fieldId, "");
            });
          }
        }
      }
    },

    uploadingStatus(percentage) {
      let number = 0;
      if (percentage) {
        number = percentage;
      }
      // console.log(50 > number, number);
      if (50 > number) {
        return "Extracting...";
      } else {
        return "Uploading...";
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    focusExtractedDetails() {
      const element = document.getElementById("extracted-details");
      element.scrollIntoView({ behavior: "smooth" });
    },

    deleteFile(index, file, hasFile) {
      // this.value.splice(index, 1);
      // console.log(file, "delete");
      let deletedFiles = [];

      // if (file.itemId) {
      //   this.deletedFiles.push({
      //     id: file.itemId,
      //     jsonId: this.field.id,
      //     index: index,
      //   });
      // } else {
      deletedFiles.push({
        id: file.fileId,
        jsonId: this.fileFieldId,
        index: index,
        initiate: true,
      });
      // }
      let files = "";
      if (hasFile) {
        files = {
          file: file,
          index: index,
          controls: this.assignControls,
        };
      }
      let emitValue = {
        deletedFiles: deletedFiles,
        settings: files,
      };
      this.deletedFiles(emitValue);
      // this.$emit("deletedFiles", emitValue);
    },

    viewFile(file, index) {
      // console.log(file, index);
      // this.rowModel = this.rows[index];
      this.rowModel = cloneDeep(this.rows[index]);

      let files = false;
      let hasFile = this.columns.filter((item) => item.type === "FILE_UPLOAD");

      // console.log(hasFile);
      if (hasFile.length && file.uploadedPercentage === 100) {
        // console.log("hasFile");

        hasFile.forEach((file) => {
          // console.log(this.rowModel[file.id]);
          if (this.rowModel[file.id]) {
            if (this.rowModel[file.id].length) {
              this.rowModel[file.id].forEach((item) => {
                let duplicate = this.uploadedFiles.findIndex((dup) => {
                  return dup.fileId === item.fileId;
                });
                let duplicate1 = this.uploadedFiles.findIndex((dup) => {
                  return dup.itemId === item.itemId;
                });

                if (
                  (duplicate === -1 && item.fileId) ||
                  (duplicate1 === -1 && file.itemId)
                ) {
                  this.uploadedFiles.push(item);
                }
              });
              let upload = this.rowModel[file.id].find((item) => item.fileId);
              let indexed = this.rowModel[file.id].find((item) => item.itemId);
              // console.log(indexed, upload);

              if (upload) {
                files = true;
                let type = 1;
                if (this.repositoryDetails.fieldsType === "STATIC") {
                  type = 2;
                }
                let jsonId = JSON.parse(JSON.stringify(upload));
                if (jsonId.fileId) {
                  this.fileId = jsonId.fileId;
                }
                console.log(jsonId.fileId, jsonId, "fileId");
                this.fileName = jsonId.name;

                this.fileLink = `${process.env.VUE_APP_API_URL}/uploadAndIndex/view/${this.$store.state.session.tenantId}/${upload.fileId}/${type}`;
              } else if (indexed) {
                if (indexed.itemId) {
                  this.fileId = indexed.itemId;
                }
                console.log(indexed, "itemId");

                files = true;
                this.fileName = indexed.fileName;
                this.fileLink = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${indexed.repositoryId}/${indexed.itemId}/2`;
              }
            } else {
              //
            }
          }
        });

        this.hasFileControl = files;
        if (this.hasFileControl && !this.openFileSheetWithControl) {
          this.sheetWidth = "50vw";
          this.openFileSheetWithControl = true;

          this.$emit("update:rowIndex", index);
          if (this.showQRModal) {
            this.$emit("saveQR", this.rowModel);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#add-row-table,
#content-wrapper {
  min-height: calc(100vh - 160px);
  // background-color: var(--body-bg-color);
  // display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 510px;
    background-color: var(--component-bg-color);
  }

  .full-side {
    width: 100%;
  }

  #file-frame {
    width: 100%;
    height: 73%;
  }
}

#control-wrapper {
  min-height: calc(100vh - 50px);
  // background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 510px;
    background-color: var(--component-bg-color);
  }

  .full-side {
    width: 100%;
  }

  #file-frame {
    width: 100%;
    height: 73%;
  }
}

#current-row {
  border: none;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary);
  //border: 1px solid var(--divider-color);
  color: white;
  font-weight: 700;
}

.chip {
  font-weight: 500;
  // background-color: var(--component-bg-color-inferior);
  padding: 5px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--secondary);
}

.same-background {
  color: white;
  background-color: var(--secondary);
}

.diff-background {
  background-color: white;
}

.diff-background:hover {
  background-color: white;
  box-shadow: 0px -1px 0px -2px var(--secondary),
    1px 0px 3px 1px var(--secondary);
}

#upload-file {
  .file-input {
    position: absolute;
    width: 0% !important;
    height: 0% !important;
    opacity: 0;
  }
}

#add-row-footer {
  display: flex !important;
  justify-content: space-between !important;
}

#sheet.footer {
  // #add-row-footer {
  display: flex !important;
  justify-content: space-between !important;
}

.file-name:hover {
  text-decoration: underline;
}
</style>
<style lang="scss">
#sheet .footer {
  // #add-row-footer {
  display: flex !important;
  // justify-content: space-between !important;
}

#single-choice-field.single-choice {
  padding-top: 16px !important;
  border: 0px solid #fff !important;
  padding-left: 0px !important;
  border-radius: 0px !important;
  border-top: 1px solid var(--divider-color) !important;
}
</style>
