<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="validationRules"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <PhoneNumberField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      transform=""
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :error="errors[0]"
      :highlight="highlight"
      :options="options"
      :new-option="allowToAddNewOptions"
      :verification-required="verificationRequired"
      @input="handleInput"
      @verify="$emit('verify')"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import PhoneNumberField from "@/components/common/form/phone-number-field/PhoneNumberField.vue";

export default {
  name: "PhoneNumberFieldWrapper",

  components: { ValidationProvider, PhoneNumberField },

  props: {
    value: {
      type: Object,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    filter: {
      type: Number,
      default: 0,
    },

    formId: {
      type: String,
      default: "0",
    },

    formSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      position: 0,
      value1: "",
      options: [],
    };
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.isMandatory && !this._isReadonly) {
        rules.required = true;
      }

      return rules;
    },

    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    contentRule() {
      return this.field.settings.validation.contentRule;
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    allowToAddNewOptions() {
      return this.field.settings.specific.allowToAddNewOptions;
    },

    verificationRequired() {
      return this.field.settings.validation.verificationRequired;
    },
  },

  watch: {},

  created() {},

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
