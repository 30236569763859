<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="validationRules"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <DateField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      transform=""
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :error="errors[0]"
      :highlight="highlight"
      :minimum-validation="minimumValidation"
      :maximum-validation="maximumValidation"
      :maximum-date="maximumDay"
      :minimum-date="minimumDay"
      :date-range-validation="dateRangeValidation"
      :field="field"
      :prediction="prediction"
      @input="handleInput"
      @verify="$emit('verify')"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import DateField from "@/components/common/form/date-field/DateField.vue";
import { axiosCrypto } from "@/api/axios.js";
import { connector } from "@/api/factory.js";

export default {
  name: "DateFieldWrapper",

  components: { ValidationProvider, DateField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    filter: {
      type: [Number, String],
      default: 0,
    },

    parentDateControl: {
      type: Boolean,
      default: false,
    },

    dateFilter: {
      type: [Number, String],
      default: 0,
    },

    parentDateOptionsControl: {
      type: Boolean,
      default: false,
    },

    dateFilterOptions: {
      type: Boolean,
      default: false,
    },

    prediction: {
      type: String,
      default: "",
    },

    lookupFilter: {
      type: String,
      default: "",
    },

    formSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      minimumValidation: false,
      maximumValidation: false,
      maximumDay: 0,
      minimumDay: 0,
      dateRangeValidation: false,
      hubLinkId: "",
    };
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      if (!this.isReadonly) {
        const range = this.field.settings.validation.dateRange;
        const miniDays = this.field.settings.validation.miniDays;
        const maxiDays = this.field.settings.validation.maxiDays;
        if (range === "DATE_RANGE") {
          const minDate = this.field.settings.validation.minimum;
          if (minDate) {
            rules.minDate = minDate;
          }

          const maxDate = this.field.settings.validation.maximum;
          if (maxDate) {
            rules.maxDate = maxDate;
          }
          this.getDateRange();
        } else if (range === "MAX_CURRENT_DATE") {
          this.getMaxCurrentDate(miniDays);
          if (miniDays) {
            rules.minDate = this.$day.subtractDate(
              this.$day.newDate(),
              miniDays
            );
            rules.maxDate = this.$day.newDate();
          }
        } else if (range === "MINI_CURRENT_DATE") {
          this.getMinCurrentDate(maxiDays);
          if (maxiDays) {
            rules.minDate = this.$day.newDate();
            rules.maxDate = this.$day.addDate(this.$day.newDate(), maxiDays);
          }
        }
      }

      return rules;
    },

    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },
  },

  watch: {
    filter: {
      // immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          }
        }
      },
    },

    dateFilter: {
      deep: true,
      immediate: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.parentDateField) {
            if (this.field.parentFieldValue) {
              // console.log(this.field);
              let childValue = this.$day.addDate(
                this.field.parentFieldValue,
                this.field.settings.specific.parentFieldsDays || 0
              );
              this.$emit("input", childValue);
            } else {
              this.$emit("input", "");
            }
          }
        }
      },
    },

    dateFilterOptions: {
      deep: true,
      handler() {
        if (this.field.parentOptionsSettings) {
          if (this.field.settings.specific.dateFieldOptionSettings.length) {
            if (
              this.field.parentOptionsSettings.dateValue &&
              this.field.parentOptionsSettings.optionField
            ) {
              let hasOptions =
                this.field.settings.specific.dateFieldOptionSettings.find(
                  (date) =>
                    date.option === this.field.parentOptionsSettings.optionField
                );
              if (hasOptions) {
                let childValue = this.$day.addDate(
                  this.field.parentOptionsSettings.dateValue,
                  hasOptions.days || 0
                );
                this.$emit("input", childValue);
              } else {
                this.$emit("input", "");
              }
            } else {
              this.$emit("input", "");
            }
          }
        }
      },
    },

    field: {
      immediate: true,
      // deep: true,
      handler() {
        if (!this.value) {
          if (this.field.settings.specific.masterFormParentColumn) {
            this.$emit(
              "getParentColumnValue",
              this.field.settings.specific.masterFormParentColumn
            );
          }
        }
      },
    },

    lookupFilter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.field.lookupFilterBy && this.field.lookupFilterBy.length) {
          // console.log(this.field.lookupFilterBy);
          if (this.hubLinkId) {
            this.getLookupFilterData(this.field.lookupFilterBy);
          }
        }
      },
    },
  },

  created() {
    if (
      this.field.settings.specific.defaultValue === "PARENT_FIELD" &&
      !this.field.settings.specific.parentFieldsDays &&
      !this.value
    ) {
      this.$emit(
        "fillDateFieldInPanel",
        this.field.settings.specific.parentDateField,
        this.field.id
      );
    }
    if (this.parentDateControl && this.value) {
      this.$emit("fillChildDateControl", this.value);
    }

    let hasLookup = this.field.settings.lookupSettings;
    if (hasLookup && hasLookup.columnName) {
      if (this.formSettings.hubLinkIds && this.formSettings.hubLinkIds.length) {
        let hublink = this.formSettings.hubLinkIds.find(
          (item) => item.jsonId === this.field.id
        );
        if (hublink) {
          this.hubLinkId = hublink.hubLinkId;
          this.getLookupData(this.hubLinkId);
        }
      }
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);

      if (this.parentDateControl) {
        this.$emit("fillChildDateControl", value);
      }

      if (this.parentDateOptionsControl) {
        this.$emit("fillChildDateUsingOptions", value);
      }
    },

    getMinCurrentDate(day) {
      this.minimumValidation = true;
      this.maximumDay = day;
    },

    getMaxCurrentDate(day) {
      this.maximumValidation = true;
      this.minimumDay = day;
    },

    getDateRange() {
      this.dateRangeValidation = true;
    },

    async getMasterEntriesFilter(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: field.filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          if (options.length === 1) {
            this.handleInput(options[0]);
          }
          this.$emit("clearFilter");
        } else {
          this.handleInput("");
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getLookupData(hubLinkId) {
      const { error, payload } = await connector.getSyncValue({
        hublinkId: hubLinkId,
        payloadMapping: "",
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload, this.field.label, "payload");
      this.options = [];
      if (payload.data[0].value.length) {
        let modal = payload.data[0].value;
        modal.forEach((item) => {
          if (item[this.field.id]) {
            let unique = this.options.findIndex((option) => {
              return option.value === item[this.field.id];
            });
            if (unique === -1) {
              this.options.push({
                id: this.$nano.id(),
                label: item[this.field.id],
                value: item[this.field.id],
              });
            }
          }
        });
      }
    },

    async getLookupFilterData(filter) {
      let input = "";
      if (filter && filter.length && filter[0].value) {
        input = {
          filterBy: [
            {
              id: this.$nano.id(),
              filters: filter,
              groupCondition: "",
            },
          ],
        };
      } else {
        input = "";
      }
      // console.log(input, this.hubLinkDetails, this.field.label, "input");

      const { error, payload } = await connector.getSyncValue({
        hublinkId: this.hubLinkId,
        payloadMapping: input ? JSON.stringify(input) : "",
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload, this.field.label, "payload");
      let options = [];
      if (payload.data[0].value.length) {
        let modal = payload.data[0].value;
        modal.forEach((item) => {
          if (item[this.field.id]) {
            let unique = options.findIndex((option) => {
              return option.value === item[this.field.id];
            });
            if (unique === -1) {
              options.push({
                id: this.$nano.id(),
                label: item[this.field.id],
                value: item[this.field.id],
              });
            }
          }
        });

        // console.log(options, "options");

        if (!this.value || this.field.isManualChange) {
          if (options.length === 1) {
            this.handleInput(options[0].value);
          } else {
            this.handleInput("");
          }
        } else {
          this.handleInput("");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
