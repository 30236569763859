<template>
  <div id="form-browse">
    <Portal to="action-bar">
      <ItemActions
        v-if="actionItems"
        item="form"
        :columns.sync="columns"
        :group-by.sync="groupBy"
        :order.sync="sortBy.order"
        :filter-by.sync="filterBy"
        :active-view.sync="activeView"
        :criteria.sync="sortBy.criteria"
        :simple-search-applyed.sync="simpleSearchApplyed"
        :import-json="true"
        @refresh="getForms"
        @search="searchRow"
        @update="getForms"
        @export="export_Data"
        @import="showImportPopup = true"
      />
    </Portal>

    <div class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          :simple-search-applyed="simpleSearchApplyed"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <FormGrid
          v-if="activeView === 'GRID'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @entries="showEntries"
          @edit="editForm"
          @more="showFormDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
          @info="copyForm"
          @export="exportJson"
        />

        <FormList
          v-if="activeView === 'LIST'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @entries="showEntries"
          @edit="editForm"
          @more="showFormDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
          @info="copyForm"
          @export="exportJson"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="mdi-text-box-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
      @loadAll="loadAll"
    />

    <FormDetails
      v-model="isFormDetailsVisible"
      :columns="columns"
      :form="selectedForm"
      @edit="editForm"
      @delete="showDeleteConfirmation"
    />

    <ConfirmDeleteForm
      v-model="isDeleteConfirmationVisible"
      :form="selectedForm"
      @delete="updateForm"
    />

    <ConfirmRestoreForm
      v-model="isRestoreConfirmationVisible"
      :form="selectedForm"
      @restore="updateForm"
    />

    <Modal
      v-model="showImportPopup"
      has-footer
      width="30vw"
      height="50vh"
      @input="showImportPopup = false"
    >
      <!-- title -->

      <template #title>Import Data</template>

      <!-- ... -->

      <template #default>
        <div class="text-caption text-grey q-mb-lg">
          Please use the export option to download the specific form you need.
          Once downloaded, you will receive a zip file. Upload this zip file to
          import the data into the system.
        </div>
      </template>

      <!-- footer -->

      <template #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          @click="showImportPopup = false"
        />

        <BaseButton label="Upload" @click="$refs.input.click()" />
      </template>

      <!-- ... -->
    </Modal>

    <input
      ref="input"
      type="file"
      accept=".zip,.rar,.7zip"
      class="hidden"
      @change="uploadJsonFile"
    />
  </div>
</template>

<script>
import { startCase, capitalize } from "lodash-es";
import { form } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import FormGrid from "./components/FormGrid.vue";
import FormList from "./components/FormList.vue";
import FormDetails from "./components/FormDetails.vue";
import ConfirmDeleteForm from "./components/ConfirmDeleteForm.vue";
import ConfirmRestoreForm from "./components/ConfirmRestoreForm.vue";
import { exportData } from "@/helpers/export-excel-data";
import Modal from "@/components/common/popup/Modal.vue";
import axios from "axios";
import { base64ToFileDownload } from "@/helpers/base64-to-file.js";

export default {
  name: "FormsBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    ItemActionChips,
    ItemActions,
    FormGrid,
    FormList,
    FormDetails,
    Pagination,
    ConfirmDeleteForm,
    ConfirmRestoreForm,
    Modal,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "type",
          label: "Type",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
          options: [
            {
              id: this.$nano.id(),
              label: "Workflow",
              value: "WORKFLOW",
            },
            {
              id: this.$nano.id(),
              label: "Master",
              value: "MASTER",
            },
            {
              id: this.$nano.id(),
              label: "Task",
              value: "TASK",
            },
            {
              id: this.$nano.id(),
              label: "SLA",
              value: "SLA",
            },
            {
              id: this.$nano.id(),
              label: "Feedback",
              value: "FEEDBACK",
            },
          ],
        },
        {
          id: this.$nano.id(),
          name: "status",
          label: "Status",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "publishOption",
          label: "Status",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
          options: [
            {
              id: this.$nano.id(),
              label: "Draft",
              value: "DRAFT",
            },
            {
              id: this.$nano.id(),
              label: "Published",
              value: "PUBLISHED",
            },
          ],
        },
        {
          id: this.$nano.id(),
          name: "entries",
          label: "Entries",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.NUMBER,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
      ],
      forms: [],
      formData: [],
      activeView: "GRID",
      selectedForm: {},
      isFormDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 100,
      currentPage: 1,
      actionItems: false,
      simpleSearchApplyed: false,
      showImportPopup: false,
    };
  },

  computed: {
    items() {
      return this.forms.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((form) => ({
          id: form.id,
          name: form.name,
          description: form.description,
          type: startCase(capitalize(form.type)),
          status: startCase(capitalize(form.publishOption)),
          publishOption: startCase(capitalize(form.publishOption)),
          entries:
            form.type === "MASTER" || form.type === "SLA" ? form.entryCount : 0,
          createdBy: form.createdBy,
          createdAt: this.$day.format(form.createdAt),
          modifiedBy: form.modifiedBy,
          modifiedAt: this.$day.format(form.modifiedAt),
          icon: "mdi-text-box",
          infoIcon: "mdi-content-duplicate",
          infoIconTooltip: "create duplicate form",
          infoIconColor: "grey",
          actionAccess: false,
          entriesLink:
            form.publishOption === "PUBLISHED"
              ? form.type === "MASTER" || form.type === "SLA"
                ? true
                : false
              : false,
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.defaultView();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getForms(),
      { deep: true, immediate: true }
    );
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      if (Object.keys(options).length) {
        if (options[menu]) {
          let view = options[menu].find((item) => item.subMenu === subMenu);
          if (view) {
            this.activeView = view.view;
          }
        }
      }
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) {
        this.filterBy = [];
      }
      this.getForms();
    },

    findForm(formId) {
      const forms = [];
      this.items.forEach((item) => forms.push(...item.data));
      return forms.find((form) => form.id === formId);
    },

    showFormDetails(formId) {
      this.selectedForm = this.findForm(formId);
      this.isFormDetailsVisible = true;
    },

    editForm(formId) {
      this.$router.push({
        name: "form-builder",
        params: { id: String(formId) },
      });
    },

    showDeleteConfirmation(formId) {
      this.selectedForm = this.findForm(formId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(formId) {
      this.selectedForm = this.findForm(formId);
      this.isRestoreConfirmationVisible = true;
    },

    loadAll() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    showEntries(formId) {
      this.selectedForm = this.findForm(formId);
      if (this.selectedForm.type !== "Workflow") {
        this.actionItems = false;
        this.$router.push({
          name: "form-entries-browse",
          params: { id: String(formId) },
        });
      }
    },

    searchRow(search) {
      if (search) {
        this.forms = this.formData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.forms = this.formData;
      }
    },

    /* api functions */

    async getForms() {
      this.actionItems = true;
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await form.getForms({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        hasSecurity: true,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;

      this.forms = data || [];
      this.formData = this.forms || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async updateForm(payload) {
      this.$store.commit("showLoadingBarPage");

      const { error } = await form.updateForm(this.selectedForm.id, payload);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$store.commit("hideLoadingBarPage");

      const mode = payload.isDeleted ? "deleted" : "restored";
      const name = this.selectedForm.name;
      this.$alert.success(`Form "${name}" ${mode} successfully`);

      this.getForms();
    },

    async getForm(formId) {
      const { error, payload } = await form.getForm(formId);
      if (error) {
        this.$alert.error(error);
        return;
      }
      return payload;
    },

    async copyForm(formId) {
      this.$store.commit("showLoadingBarPage");
      let copyForm = await this.getForm(formId);
      let copyFormJson = JSON.parse(copyForm.formJson);
      copyFormJson.settings.general.name += " - Copy";
      copyFormJson.settings.publish.publishOption = "DRAFT";

      const { error } = await form.createForm(copyFormJson);
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Duplicate Form Created");
      this.getForms();
    },

    async export_Data() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await form.getForms({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: 0,
        currentPage: 0,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { meta, data } = payload;
      if (meta.totalItems && data.length) {
        let exportRecords = [];
        data[0].value.forEach((form) => {
          exportRecords.push({
            Name: form.name,
            Description: form.description,
            Type: startCase(capitalize(form.type)),
            Status: startCase(capitalize(form.publishOption)),
            "Entries Count":
              form.type === "MASTER" || form.type === "SLA"
                ? form.entryCount
                : "-",
            "Created By": form.createdBy,
            "Created At": this.$day.format(form.createdAt),
            "Last Modified By": form.modifiedBy,
            "Last Modified At": this.$day.format(form.modifiedAt),
          });
        });
        let exportName = "Form List - Report";
        if (this.mode === "TRASH") {
          exportName = "Form Trash List - Report";
        }
        exportData(exportRecords, exportName);
      } else {
        //this.$alert.info("No data found for export");
      }
    },

    async exportJson(forms) {
      // window.open(
      //   `${process.env.VUE_APP_API_URL}/form/formMigrationExport/${this.$store.state.session.tenantId}/${formId}/1/1`
      // );

      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await form.formJsonExport({
        tenantId: this.$store.state.session.tenantId,
        id: forms.id,
        type: 1,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload);
      let files = JSON.parse(payload);

      base64ToFileDownload(files.file, "application/zip", files.filename);
      this.$alert.success(`Form download successfully`);
    },

    async uploadJsonFile(e) {
      const file = e.target.files[0];
      // console.log(file.type);
      if (
        file.type === "application/x-zip-compressed" ||
        file.type === "application/octet-stream" ||
        file.type === "application/zip" ||
        file.type === "zip" ||
        file.type === "application/x-zip"
      ) {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("type", 1);
        try {
          this.$store.commit("showLoadingBarSave");
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/form/formMigrationImport`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBarSave");
          const { status, data } = response;
          if (status === 200 && data)
            this.$alert.success(`File imported successfully`);
          else {
            this.$alert.warning("Error uploading file");
            throw response;
          }
        } catch (error) {
          this.$store.commit("hideLoadingBarSave");
          this.$alert.warning("Error uploading file");
          console.error(error);
        }
        this.showImportPopup = false;
        e.target.value = "";
        this.getForms();
      } else {
        this.$alert.error("Please upload a zip file");
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#form-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
