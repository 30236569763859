<template>
  <div class="chat-container row">
    <div class="category-list col-2">
      <BaseScrollbar :height="'calc(100vh - 250px)'">
        <div
          class="row"
          :class="taskAll ? 'category-item-active' : 'category-item'"
          style="border-bottom: 1px solid var(--divider-color)"
          @click="
            visibleItems = [];
            tasks = [];
            tasksData = [];
            currentPage = 1;
            getTaskEntriesAll('value');
            isLoadingActive = true;
          "
        >
          <BaseIcon
            :name="taskAll ? 'mdi-expand-all' : 'mdi-expand-all-outline'"
            :color="taskAll ? 'primary' : 'grey'"
            class="profile-avatar col-auto"
            :size="'20px'"
          />
          <div
            class="col q-ml-sm ellipsis"
            :class="taskAll ? 'text-dark-3' : ''"
            :style="{
              fontWeight: taskAll ? 'bold' : 'normal',
            }"
          >
            ALL
          </div>
          <!-- <div class="col-auto font-bold q-mt-sm q-mr-lg">
            {{ totalTaskCount || 0 }}
          </div> -->
          <div></div>
        </div>
        <div
          class="row"
          :class="taskImportant ? 'category-item-active' : 'category-item'"
          @click="
            visibleItems = [];
            tasks = [];
            tasksData = [];
            currentPage = 1;
            getTaskImportant('value');
            isLoadingActive = true;
          "
        >
          <!-- <img src="@/assets/avatar/avatar.png" alt="avatar" /> -->
          <!-- <img
              :src="imageURLAll()"
              alt="avatar"
              style="
                height: 28px;
                margin-top: 2px;
                border-radius: 50%;
                border: 1px solid var(--divider-color);
              "
            /> -->
          <BaseIcon
            :name="taskImportant ? 'mdi-star' : 'mdi-star-outline'"
            :color="taskImportant ? 'primary' : 'grey'"
            class="profile-avatar col-auto"
            :size="'20px'"
          />
          <div
            class="col q-ml-sm ellipsis"
            :class="taskImportant ? 'text-dark-3' : ''"
            :style="{
              fontWeight: taskImportant ? 'bold' : 'normal',
            }"
          >
            Important
          </div>
          <!-- <div class="col-auto font-bold q-mt-sm q-mr-lg">
            {{ totalTaskCount || 0 }}
          </div> -->
          <div></div>
        </div>
        <div
          class="row"
          :class="thisDay ? 'category-item-active' : 'category-item'"
          @click="
            visibleItems = [];
            tasks = [];
            tasksData = [];
            currentPage = 1;
            getTaskThisDay('value');
            isLoadingActive = true;
          "
        >
          <BaseIcon
            :name="thisDay ? 'mdi-calendar-today' : 'mdi-calendar-outline'"
            :color="thisDay ? 'primary' : 'grey'"
            class="profile-avatar col-auto"
            :size="'20px'"
          />
          <div
            class="col q-ml-sm ellipsis"
            :class="thisDay ? 'text-dark-3' : ''"
            :style="{
              fontWeight: thisDay ? 'bold' : 'normal',
            }"
          >
            Today Task
          </div>
          <!-- <div class="col-auto font-bold q-mt-sm q-mr-lg">
            {{ totalTaskCount || 0 }}
          </div> -->
          <div></div>
        </div>

        <div
          class="row"
          :class="todayTask ? 'category-item-active' : 'category-item'"
          @click="
            visibleItems = [];
            tasks = [];
            tasksData = [];
            currentPage = 1;
            getTaskToday('value');
            isLoadingActive = true;
          "
        >
          <BaseIcon
            :name="todayTask ? 'mdi-crown' : 'mdi-crown-outline'"
            :color="todayTask ? 'primary' : 'grey'"
            class="profile-avatar col-auto"
            :size="'20px'"
          />
          <div
            class="col q-ml-sm ellipsis"
            :class="todayTask ? 'text-dark-3' : ''"
            :style="{
              fontWeight: todayTask ? 'bold' : 'normal',
            }"
          >
            My day
          </div>

          <!-- <div class="col-auto font-bold q-mt-sm q-mr-lg">
            {{ totalTaskCount || 0 }}
          </div> -->
          <div></div>
        </div>
        <div
          class="row"
          :class="taskMe ? 'category-item-active' : 'category-item'"
          @click="
            visibleItems = [];
            tasks = [];
            tasksData = [];
            currentPage = 1;
            getTaskMe('value');
            isLoadingActive = true;
          "
        >
          <div class="profile-avatar col-auto">
            <BaseIcon
              :name="taskMe ? 'mdi-account' : 'mdi-account-outline'"
              :color="taskMe ? 'primary' : 'grey'"
              class="profile-avatar col-auto"
              :size="'20px'"
            />
          </div>
          <div
            class="col q-ml-sm ellipsis"
            :style="{
              fontWeight: taskMe ? 'bold' : 'normal',
            }"
          >
            Assign To Me
          </div>
          <!-- <div class="col-auto font-bold q-mt-sm q-mr-lg">
            {{ totalTaskCount || 0 }}
          </div> -->
          <div></div>
        </div>
        <BaseSeparator class="q-my-xs" />
        <div
          v-for="category in categories"
          :key="category.id"
          class="category-item row"
          :class="{
            active:
              !taskUncategory &&
              !taskAll &&
              !taskImportant &&
              !thisDay &&
              !taskMe &&
              !todayTask &&
              category.id === selectedCategory?.id,
          }"
          @click="
            visibleItems = [];
            selectCategory(category);
            isLoadingActive = true;
          "
        >
          <div class="profile-avatar col-auto">
            <!-- <img src="@/assets/avatar/avatar.png" alt="avatar" /> -->
            <img
              :src="imageURL(category.id)"
              alt="avatar"
              style="
                width: 30px;
                height: 28px;
                margin-top: 2px;
                border-radius: 50%;
                border: 1px solid var(--divider-color);
                opacity: 0.7;
              "
            />
          </div>
          <div class="col q-mt-sm q-ml-sm ellipsis">
            {{ category.category }}
          </div>
          <div class="col-auto q-mt-sm q-mr-sm">
            {{ category.taskCount }}
          </div>

          <div>
            <!-- Action Button -->
            <BaseActionButton
              is-flat
              no-border
              icon="mdi-dots-vertical"
              color="primary"
              class="col-auto"
              @click.stop="toggleMenu(category.id)"
            />

            <!-- QMenu Component -->
            <q-menu
              v-model="menuEdit[category.id]"
              anchor="top right"
              self="top left"
              @click-outside="closeMenu(category.id)"
            >
              <q-page style="min-height: 0">
                <!-- Edit Option -->
                <q-item
                  v-ripple
                  clickable
                  @click="
                    editCategory(category);
                    closeMenu(category.id);
                  "
                >
                  <q-item-section
                    ><BaseActionButton
                      is-flat
                      no-border
                      icon="eva-edit-outline"
                      color="secondary"
                      class="col-auto"
                    />
                    <div class="q-ml-xs">Edit</div></q-item-section
                  >
                </q-item>

                <!-- Delete Option -->
                <q-item
                  v-ripple
                  style="border-top: 1px solid var(--divider-color)"
                  clickable
                  @click="
                    confirmDelete(category);
                    closeMenu(category.id);
                  "
                >
                  <q-item-section
                    ><BaseActionButton
                      is-flat
                      no-border
                      icon="eva-trash-2-outline"
                      color="red"
                      class="col-auto"
                    />
                    <div class="">Delete</div></q-item-section
                  >
                </q-item>
              </q-page>
            </q-menu>
          </div>
        </div>
      </BaseScrollbar>
      <BaseActionButton
        v-tooltip="'Create List'"
        is-flat
        icon="mdi-plus"
        no-border
        color="white"
        style="
          bottom: 0px;
          right: 80%;
          margin: 16px;
          z-index: 1;
          display: flex;
          position: fixed;
          background-color: #643094;
        "
        @click="
          name = '';
          multipleUser = [];
          uploadImage = '';
          editImage = false;
          editCategorys = false;
          addCategory = true;
        "
      />
    </div>
    <!-- <BaseLoadingBarSheet
      v-if="isLoadingActive"
      style="position: absolute !important; z-index: 9999"
    /> -->
    <!-- <q-spinner
      v-if="isLoading"
      size="50px"
      color="blue"
      class="absolute-center"
    /> -->
    <div class="col">
      <RenderForm
        no-padding
        :panels="panels"
        :secondary-panels="secondaryPanels"
        :form-settings="formSettings"
        :form-model="taskModel"
        :triger-save="trigerSave"
        style="display: none"
        @save="saveTask"
      />
      <!-- <h3>{{ selectedCategory ? selectedCategory : "Select a category" }}</h3> -->
      <!-- <div v-if="filteredData.length === 0">select Category or Create New</div> -->
      <!-- <div
        class="row"
        style="
          background-color: var(--component-bg-color);
          border: 1px solid #ccc;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          padding: 4px;
          border-radius: 5px;
        "
      >
        <img
          :src="imageURL(selectedCategory.id)"
          alt="avatar"
          class="col-auto q-ml-md"
          style="
            height: 57px;
            margin-top: 2px;
            border-radius: 50%;
            border: 1px solid var(--divider-color);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          "
        />
        <div class="col q-ml-md q-mt-md font-bold" style="font-size: larger">
          {{ selectedCategory.category }}
        </div>
      </div> -->
      <template
        v-if="
          !taskAll &&
          !taskUncategory &&
          !taskMe &&
          !todayTask &&
          !thisDay &&
          !taskImportant
        "
      >
        <BaseLoadingBarSheet
          v-if="isLoadingActive"
          style="position: absolute !important; z-index: 9999"
        />
        <div v-else class="data-list q-mb-sm">
          <!-- Headings row -->
          <div
            v-if="items.length"
            class="data-row"
            style="
              padding: 0px;
              border-bottom: none;
              border-right: none;
              border-left: none;
              background: none;
              cursor: context-menu;
              color: var(--icon-color-inverted);
            "
          >
            <div
              v-for="column in visibleColumns"
              :key="column.id"
              class="text-left"
              :class="
                ['Task Name', 'Details'].includes(column.label) ? 'q-ml-md' : ''
              "
            >
              <span>{{ column.label }}</span>
            </div>
            <p class="text-left q-ml-lg"></p>
          </div>
          <BaseScrollbar :height="'calc(100vh - 305px)'" @scroll="loadMores">
            <!-- Data rows -->
            <div class="q-mb-sm">
              <!-- Show items under each status group if it is in the openStatus set -->
              <div v-for="iteme in items" :key="'task' + iteme.id">
                <div
                  classs="cursor-pointer"
                  @click="toggleVisibility(iteme.value)"
                >
                  <q-chip
                    :color="
                      iteme.value === 'In Progress'
                        ? 'orange'
                        : iteme.value === 'Completed'
                        ? 'green'
                        : 'gray'
                    "
                    class="cursor-pointer text-white"
                  >
                    {{ iteme.value || "Add Status" }}

                    <BaseIcon
                      :name="
                        visibleItems[iteme.value]
                          ? 'mdi-menu-up'
                          : 'mdi-menu-down'
                      "
                      :color="'var(--icon-color);'"
                      :size="'20px'"
                    />
                  </q-chip>
                </div>
                <div
                  v-if="visibleItems[iteme.value]"
                  class="status-items q-mt-sm"
                >
                  <div
                    v-for="item in iteme.data"
                    :key="'list' + item.ids"
                    class="data-row"
                    @click="$emit('select', item.id, tasksData)"
                  >
                    <span
                      style="
                        width: 2px;
                        display: inline-block;
                        height: 44px;
                        margin: -3px;
                      "
                      :style="{
                        backgroundColor:
                          item['Priority'] === 'High'
                            ? 'red'
                            : item['Priority'] === 'Medium'
                            ? 'orange'
                            : item['Priority'] === 'Low'
                            ? 'gray'
                            : '',
                      }"
                    ></span>
                    <BaseIcon
                      :name="item.icon"
                      :color="item.color"
                      :size="'20px'"
                      class="q-ml-sm"
                      @click.stop="
                        item.status === 'In Progress'
                          ? changeStatusIcon(item)
                          : ''
                      "
                    />
                    <div
                      v-if="isColumnVisible('Task Name')"
                      class="ellipsis q-ml-sm"
                    >
                      {{ item["Task Name"] }}
                    </div>
                    <div v-if="isColumnVisible('Details')" class="ellipsis">
                      {{ item["Details"] }}
                    </div>
                    <div v-if="isColumnVisible('Task Owner')" class="ellipsis">
                      {{ item["Task Owner"] }}
                    </div>
                    <div v-if="isColumnVisible('Task Assign')" class="ellipsis">
                      {{ item["Task Assign"] }}
                    </div>
                    <div v-if="isColumnVisible('Start Date')" class="ellipsis">
                      {{ item["Start Date"] }}
                    </div>
                    <div v-if="isColumnVisible('End Date')" class="ellipsis">
                      {{ item["End Date"] }}
                    </div>
                    <div v-if="isColumnVisible('Priority')" class="ellipsis">
                      {{ item["Priority"] }}
                    </div>
                    <!-- Item status -->
                    <div v-if="isColumnVisible('Task Status')">
                      <div>
                        <div
                          class="item-status text-white"
                          :class="
                            item.status === 'Completed'
                              ? 'bg-green'
                              : 'bg-warning'
                          "
                          style="
                            border-radius: 6px;
                            width: max-content;
                            padding: 5px;
                            margin-left: 5px;
                            margin-right: 5px;
                          "
                          @click.stop="openStatusMenu(item.id, item.status)"
                        >
                          <span>{{ item.status }}</span>
                        </div>

                        <!-- Dropdown menu for selecting status -->
                        <q-menu
                          v-model="menuStates[item.id]"
                          fit
                          :anchor="`bottom left`"
                          :self="`top left`"
                          @hide="closeStatusMenu"
                        >
                          <q-list>
                            <!-- <BaseIcon
                            :name="'eva-plus'"
                            :size="'20px'"
                            style="
                              font-size: 22px;
                              display: flex;
                              margin-left: 86%;
                              background-color: #1edbdb;
                              color: white;
                              border-radius: 50%;
                              margin-top: 2%;
                              cursor: pointer;
                            "
                          /> -->
                            <q-item
                              v-for="option in UpdateOption"
                              :key="option.id"
                              clickable
                              @click="updateStatus(item, option)"
                            >
                              <q-radio
                                v-model="selectedOption"
                                :val="option.label"
                                :label="option.label"
                                clickable
                                @input="updateStatus(item, option)"
                              />
                            </q-item>
                          </q-list>
                        </q-menu>
                      </div>
                    </div>

                    <!-- Additional Data Fields -->

                    <div v-if="isColumnVisible('Created By')" class="ellipsis">
                      {{ item.createdBy || "-" }}
                    </div>
                    <div v-if="isColumnVisible('Created At')" class="ellipsis">
                      {{ item.createdAt || "-" }}
                    </div>
                    <div v-if="isColumnVisible('Modified By')" class="ellipsis">
                      {{ item.modifiedBy || "-" }}
                    </div>
                    <div v-if="isColumnVisible('Modified At')" class="ellipsis">
                      {{ item.modifiedAt || "-" }}
                    </div>
                    <BaseActionButton
                      is-flat
                      no-border
                      :icon="item.isMarked ? 'mdi-star' : 'mdi-star-outline'"
                      :color="item.isMarked ? 'yellow' : ''"
                      @click.stop="updateImportant(item)"
                    />
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </div>
          </BaseScrollbar>
          <!-- <Pagination
          v-if="filteredData.length !== 0"
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
          @loadAll="$emit('loadAll')"
        /> -->
        </div>
      </template>
      <template v-else>
        <BaseLoadingBarSheet
          v-if="isLoadingActive"
          style="position: absolute !important; z-index: 9999"
        />
        <div v-else class="data-list q-mb-sm">
          <!-- Headings row -->
          <div
            class="data-row"
            style="
              padding: 0px;
              display: flex;
              border-bottom: none;
              border-right: none;
              border-left: none;
              background: none;
              cursor: context-menu;
              color: var(--icon-color-inverted);
            "
          >
            <p></p>
            <div
              v-for="column in visibleColumns"
              :key="column.id"
              class="text-left"
              :class="
                ['Task Name', 'Details'].includes(column.label) ? 'q-ml-md' : ''
              "
            >
              <div class="ellipsis">{{ column.label }}</div>
            </div>
            <div class="text-left q-ml-sm">List</div>
            <p class="text-left q-ml-xl"></p>
          </div>

          <!-- Categories -->
          <BaseScrollbar :height="'calc(100vh - 305px)'" @scroll="loadMore">
            <div class="q-mb-sm">
              <div v-for="iteme in items" :key="'lists' + iteme.id">
                <div
                  classs="cursor-pointer"
                  @click="toggleVisibility(iteme.value)"
                >
                  <q-chip
                    :color="
                      iteme.value === 'In Progress'
                        ? 'orange'
                        : iteme.value === 'Completed'
                        ? 'green'
                        : 'gray'
                    "
                    class="cursor-pointer text-white"
                  >
                    {{ iteme.value || "Add Status" }}

                    <BaseIcon
                      :name="
                        visibleItems[iteme.value]
                          ? 'mdi-menu-up'
                          : 'mdi-menu-down'
                      "
                      :color="'var(--icon-color);'"
                      :size="'20px'"
                    />
                  </q-chip>
                </div>
                <div
                  v-if="visibleItems[iteme.value]"
                  class="status-items q-mt-sm"
                >
                  <div
                    v-for="item in iteme.data"
                    :key="'tasks' + item.ids"
                    class="data-row"
                    @click="
                      $emit('select', item.id, tasksData);
                      $emit('category', item.categoryId);
                    "
                  >
                    <span
                      style="
                        width: 2px;
                        display: inline-block;
                        height: 44px;
                        margin: -3px;
                      "
                      :style="{
                        backgroundColor:
                          item['Priority'] === 'High'
                            ? 'red'
                            : item['Priority'] === 'Medium'
                            ? 'orange'
                            : item['Priority'] === 'Low'
                            ? 'gray'
                            : '',
                      }"
                    ></span>
                    <BaseIcon
                      :name="item.icon"
                      :color="item.color"
                      :size="'20px'"
                      class="q-ml-sm"
                      @click.stop="
                        item.status === 'In Progress'
                          ? changeStatusIcon(item)
                          : ''
                      "
                    />
                    <div
                      v-if="isColumnVisible('Task Name')"
                      class="ellipsis q-ml-sm"
                    >
                      {{ item["Task Name"] }}
                    </div>
                    <div v-if="isColumnVisible('Details')" class="ellipsis">
                      {{ item["Details"] }}
                    </div>
                    <div v-if="isColumnVisible('Task Owner')" class="ellipsis">
                      {{ item["Task Owner"] }}
                    </div>
                    <div v-if="isColumnVisible('Task Assign')" class="ellipsis">
                      {{ item["Task Assign"] }}
                    </div>
                    <div v-if="isColumnVisible('Start Date')" class="ellipsis">
                      {{ item["Start Date"] }}
                    </div>
                    <div v-if="isColumnVisible('End Date')" class="ellipsis">
                      {{ item["End Date"] }}
                    </div>
                    <div v-if="isColumnVisible('Priority')" class="ellipsis">
                      {{ item["Priority"] }}
                    </div>
                    <!-- Item status -->
                    <div v-if="isColumnVisible('Task Status')">
                      <div>
                        <div
                          class="item-status text-white"
                          :class="
                            item.status === 'Completed'
                              ? 'bg-green'
                              : 'bg-warning'
                          "
                          style="
                            border-radius: 6px;
                            width: max-content;
                            padding: 5px;
                            margin-left: 5px;
                            margin-right: 5px;
                          "
                          @click.stop="openStatusMenu(item.id, item.status)"
                        >
                          <span>{{ item.status }}</span>
                        </div>

                        <!-- Dropdown menu for selecting status -->
                        <q-menu
                          v-model="menuStates[item.id]"
                          fit
                          :anchor="`bottom left`"
                          :self="`top left`"
                          @hide="closeStatusMenu"
                        >
                          <q-list>
                            <!-- <BaseIcon
                            :name="'eva-plus'"
                            :size="'20px'"
                            style="
                              font-size: 20px;
                              display: flex;
                              margin-left: 86%;
                              background-color: #1edbdb;
                              color: white;
                              border-radius: 50%;
                              margin-top: 2%;
                              cursor: pointer;
                            "
                          /> -->
                            <q-item
                              v-for="option in UpdateOption"
                              :key="option.id"
                              clickable
                              @click="updateStatus(item, option)"
                            >
                              <q-radio
                                v-model="selectedOption"
                                :val="option.label"
                                :label="option.label"
                                clickable
                                @input="updateStatus(item, option)"
                              />
                            </q-item>
                          </q-list>
                        </q-menu>
                      </div>
                    </div>
                    <!-- Additional Data Fields -->

                    <div v-if="isColumnVisible('Created By')" class="ellipsis">
                      {{ item.createdBy || "-" }}
                    </div>
                    <div v-if="isColumnVisible('Created At')" class="ellipsis">
                      {{ item.createdAt || "-" }}
                    </div>
                    <div v-if="isColumnVisible('Modified By')" class="ellipsis">
                      {{ item.modifiedBy || "-" }}
                    </div>
                    <div v-if="isColumnVisible('Modified At')" class="ellipsis">
                      {{ item.modifiedAt || "-" }}
                    </div>
                    <div class="ellipsis q-ml-lg">{{ item.categoryName }}</div>

                    <BaseActionButton
                      is-flat
                      no-border
                      :icon="item.isMarked ? 'mdi-star' : 'mdi-star-outline'"
                      :color="item.isMarked ? 'yellow' : ''"
                      @click.stop="updateImportant(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </BaseScrollbar>
        </div>
      </template>
      <div class="chat-input-container row q-ml-sm">
        <!-- <input
          v-if="!selectedCategory"
          v-model="newCategoryName"
          type="text"
          placeholder="Type a new category..."
        />
        <BaseButton
          v-if="!selectedCategory"
          label="Update"
          color="primary"
          @click="setCategoryName"
        /> -->

        <!-- <input
            ref="newLabelInput"
            v-model="newLabel"
            type="text"
            :placeholder="dynamicPlaceholder"
            @keyup.enter="addItem"
          /> -->
        <div class="col">
          <Marquee
            :errase="errase"
            @text="dataInput"
            @errasing="errasing"
          ></Marquee>
        </div>
        <div class="col-auto row">
          <!-- <div @focus="openMenu">
            <q-icon
              slot="prepend"
              v-tooltip.top="'Change Status'"
              :name="statusIcon"
              size="20px"
              :color="statusColor"
              style="cursor: pointer"
            />
            <q-menu ref="menu" fit>
              <q-list>
               
                <q-separator />
                <q-item
                  v-for="option in filteredOptions"
                  :key="option.id"
                  clickable
                  @click="selectOption(option)"
                >
                  <q-radio
                    v-model="selectedOption"
                    :val="option"
                    :label="option.label"
                    dense
                  />
                </q-item>
              </q-list>
            </q-menu>
          </div>
          <div @focus="openPriority">
            <q-icon
              slot="prepend"
              v-tooltip.top="'Change Priority'"
              name="mdi-speedometer"
              size="20px"
              class="q-mr-sm q-ml-sm"
              :color="iconColor"
              style="cursor: pointer"
            />
            <q-menu ref="priority" fit>
              <q-list>
               
                <q-separator />
                <q-item
                  v-for="option in filteredPriorty"
                  :key="option.id"
                  clickable
                  @click="selectPriority(option)"
                >
                  <q-radio
                    v-model="selectedPriority"
                    :val="option"
                    :label="option.label"
                    dense
                  />
                </q-item>
              </q-list>
            </q-menu>
          </div> -->
          <div v-if="!taskMe" style="margin: auto" @focus="openUser">
            <BaseActionButton
              v-tooltip.top="'Select User'"
              is-flat
              icon="mdi-account-plus"
              size="20px"
              :class="selectedUsers.id ? 'text-secondary' : 'text-gray'"
              no-border
            />
            <q-menu
              auto-close
              transition-show="scale"
              transition-hide="scale"
              anchor="bottom end"
              self="top right"
            >
              <div class="title-3 border q-pa-xs">
                <input
                  ref="searchQuery"
                  v-model="searchAssignUser"
                  type="text"
                  class="q-pa-md"
                  placeholder="Search"
                  @click.stop=""
                  @keyup="searchList(searchAssignUser)"
                />
              </div>
              <BaseScrollbar
                :height="scrollbarHeight"
                width="240px"
                class="q-pb-sm"
              >
                <!-- columns -->

                <ListItem
                  v-for="user in filterUserOptions"
                  :key="user.id"
                  :icon="
                    user.selected
                      ? 'eva-radio-button-on'
                      : 'eva-radio-button-off'
                  "
                  :class="user.display"
                  :is-selected="user.selected"
                  :label="user.label"
                  @click="selectUser(user.id)"
                />
              </BaseScrollbar>

              <!-- ... -->
            </q-menu>
          </div>
          <!-- <SelectField
          v-if="selectedCategory"
          v-model="status"
          is-mandatory
          placeholder="Select day"
          :is-searchable="true"
          :options="dateFilter"
          class="fieldList q-mr-sm"
        /> -->
          <!-- <UserList
          v-if="selectedCategory"
          :selected-workspace="{}"
          @add="addUser"
         
        /> -->
          <!-- Button to open the q-menu -->
          <!-- Button to open the q-menu directly with .open() -->
          <div style="margin: auto">
            <BaseActionButton
              v-tooltip.top="'End Date'"
              is-flat
              icon="mdi-calendar"
              size="20px"
              :class="selectedDate ? 'text-primary' : 'text-gray'"
              class="q-mr-sm"
              no-border
              @click="openDateMenu"
            />

            <!-- q-menu without v-model, using open() method -->
            <q-menu
              ref="dateMenu"
              anchor="bottom left"
              self="top left"
              :persistent="false"
              @click-outside="$refs.dateMenu.hide()"
            >
              <q-date
                v-model="selectedDate"
                color="secondary"
                q_header-color="primary"
                @input="dateSelected"
              />
            </q-menu>
          </div>
          <!-- <input
          v-if="selectedCategory"
          v-model="newDate"
          type="datetime-local"
          :disabled="!selectedCategory"
          class="date-picker q-mr-md"
          style="
            width: 2%;
            margin-left: 0px;
            color: transparent; /* Hide the text */
            background: transparent; /* Make the background transparent */
            caret-color: transparent; /* Hide the caret */
            cursor: pointer;
          "
        /> -->
          <BaseButton
            label="Create"
            color="primary"
            :is-loading="isLoadingButton"
            @click="addItem"
          />
        </div>
      </div>
    </div>
    <Confirm
      :value="statusChangeConfirm"
      icon="mdi-pin-outline"
      icon-color="primary"
      @input="statusChangeConfirm = false"
    >
      <template #title>Change Task Status</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Are you sure you want to change the status of
            <b> {{ itemSelected["Task Name"] }} </b>Task.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="primary"
          @click="statusChangeConfirm = false"
        />
        <BaseButton label="yes" color="primary" @click="changeStatus = true" />
      </template>
    </Confirm>

    <Modal
      :value="addCategory"
      width="30vw"
      height="70vh"
      :has-footer="true"
      @input="addCategory = false"
    >
      <!-- title -->

      <template #title>Create List</template>

      <!-- ... -->
      <template #default>
        <div>
          <TextField
            :value="name"
            label="Category Name"
            is-mandatory
            class="q-mb-lg"
            @input="updateName"
          />
          <!-- <ColorField
            :value="color"
            label="color"
            class="q-mb-md"
            @input="updateColor"
          /> -->
          <MultiSelectField
            v-model="multipleUser"
            label="Users"
            is-mandatory
            :options="users"
            new-option
            class="q-mb-md"
          />
          <div v-if="editImage">
            <div class="row">
              <FormFieldLabel label="Image" class="col" />

              <BaseCloseButton class="col-auto" @click="editImage = false" />
            </div>
            <img
              :src="imageURL(categoryId)"
              alt="avatar"
              style="
                height: 202px;
                margin-top: 2px;
                /* border-radius: 50%; */
                border: 1px solid var(--divider-color);
                display: flex;
                margin-left: 30%;
              "
            />
          </div>
          <ImageField
            v-else
            v-model="uploadImage"
            label="upload image"
            :is-mandatory="true"
          />
        </div>
      </template>
      <template #footer>
        <BaseButton
          v-if="editCategorys"
          label="Update"
          @click="editCategoryes" />
        <BaseButton
          v-else
          label="save"
          :disabled="
            uploadImage === '' || multipleUser.length === 0 || name === ''
          "
          @click="createCategory"
      /></template>
    </Modal>

    <Confirm
      :value="deleteCategoryes"
      icon="eva-trash-2-outline"
      icon-color="red"
      @input="deleteCategoryes = false"
    >
      <template #title>Delete Category</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Are you sure you want to delete the save search
            <b>"{{ deleteCategoryDate.category }}"</b>. If you delete the save
            search, you will not be able to use this .
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="red"
          @click="deleteCategoryes = false"
        />
        <BaseButton
          label="yes"
          color="red"
          @click="deleteCategory(deleteCategoryDate)"
        />
      </template>
    </Confirm>
  </div>
</template>

<script>
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import { user, form, task } from "@/api/factory.js";
// import Pagination from "@/components/common/display/Pagination.vue";
import Confirm from "@/components/common/popup/Confirm.vue";
import Modal from "@/components/common/popup/Modal.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
// import ColorField from "@/components/common/form/color-field/ColorField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import ImageField from "@/components/common/form/image-field/ImageField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import Marquee from "./Marquee.vue";
import ListItem from "@/components/common/ListItem.vue";
import { date } from "quasar";
// import {
//   form,
// } from "@/api/factory.js";
export default {
  components: {
    RenderForm,
    // Pagination,
    Confirm,
    Modal,
    FormFieldLabel,
    // ColorField,
    TextField,
    ImageField,
    MultiSelectField,
    Marquee,
    ListItem,
  },
  props: {
    filterBy: {
      type: Array,
      default: () => [],
    },

    value: {
      type: String,
      default: "",
    },

    formSettings: {
      type: Object,
      required: true,
    },

    panels: {
      type: Array,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    formId: {
      type: Number,
      default: 0,
    },

    taskModel: {
      type: Object,
      default: () => {},
    },

    mode: {
      type: String,
      default: "",
    },

    taskName: {
      type: String,
      default: "",
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    workflowTask: {
      type: Boolean,
      default: false,
    },

    taskWorkflowList: {
      type: Array,
      default: () => [],
    },

    selectedTask: {
      type: Object,
      default: () => {},
    },

    documentTask: {
      type: Boolean,
      default: false,
    },

    repositoryFile: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Array,
      default: () => [],
    },

    editing: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: Object,
      default: () => {},
    },
    columns: {
      type: Array,
      default: () => [],
    },
    searchValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      openStatus: new Set(),
      dateFilter: [
        {
          id: this.$nano.id(),
          label: "Today",
          value: "today",
        },
        {
          id: this.$nano.id(),
          label: "Yesterday",
          value: "yesterday",
        },
        {
          id: this.$nano.id(),
          label: "This Week",
          value: "7days",
        },
        {
          id: this.$nano.id(),
          label: "Last week",
          value: "week",
        },
      ],
      // items: [
      //   {
      //     id: 1,
      //     category: "update",
      //     label: "perumal",
      //     status: "progress",
      //     from: "admin@gmain",
      //     date: "",
      //   },
      //   {
      //     id: 2,
      //     category: "execute",
      //     label: "perumal1",
      //     status: "completed",
      //     from: "admin@gmain",
      //     date: "",
      //   },
      //   {
      //     id: 3,
      //     category: "start",
      //     label: "perumal2",
      //     status: "progress",
      //     from: "admin@gmain",
      //     date: "",
      //   },
      //   {
      //     id: 4,
      //     category: "execute",
      //     label: "perumal3",
      //     status: "completed",
      //     from: "admin@gmain",
      //     date: "",
      //   },
      //   {
      //     id: 5,
      //     category: "start",
      //     label: "perumal4",
      //     status: "progress",
      //     from: "admin@gmain",
      //     date: "",
      //   },
      //   {
      //     id: 6,
      //     category: "update",
      //     label: "perumal5",
      //     status: "start",
      //     from: "admin@gmain",
      //     date: "",
      //   },
      //   {
      //     id: 7,
      //     category: "start",
      //     label: "perumal6",
      //     status: "completed",
      //     from: "admin@gmain",
      //     date: "",
      //   },
      // ],
      selectedCategory: {},
      newLabel: "",
      newDate: "",
      newCategoryName: "", // Store the new category name
      nextId: 8,
      statuses: ["value", "you", "jjhdoh"],
      values: "",
      optionPicker: false,
      status: "",
      usersList: [],
      trigerSave: false,
      form: [
        // {
        //   id: this.$nano.id(),
        //   label: "Open",
        //   link: "Open",
        // },
        {
          id: this.$nano.id(),
          label: "In Progress",
          link: "In Progress",
        },
        {
          id: this.$nano.id(),
          label: "Completed",
          link: "Completed",
        },

        // {
        //   id: this.$nano.id(),
        //   label: "ReOpen",
        //   link: "ReOpen",
        // },
      ],

      selectedOption: {
        id: this.$nano.id(),
        label: "Completed",
        link: "Completed",
      },
      filteredOptions: [],
      users: [],
      selectedUsers: {},
      filterUserOptions: [],
      searchUser: "",
      searchPriority: "",
      priority: [
        {
          id: this.$nano.id(),
          label: "Low",
          link: "Low",
        },
        {
          id: this.$nano.id(),
          label: "High",
          link: "High",
        },
        {
          id: this.$nano.id(),
          label: "Medium",
          link: "Medium",
        },
      ],
      filteredPriorty: [],
      selectedPriority: "",

      groupBy: "",
      tasks: [],
      tasksData: [],
      categoryArray: [],
      groupedData: {},
      statusMenu: {
        show: false,
        itemId: null,
      },
      menuStates: {},
      itemSelected: {},
      UpdateOption: [],
      changeStatus: false,
      statusChangeConfirm: false,
      addCategory: false,
      uploadImage: "",
      multipleUser: [],
      showDateMenu: false, // Controls the visibility of q-menu
      selectedDate: "",
      color: "",
      name: "",
      categories: [],
      editCategorys: false,
      editImage: false,
      menuEdit: {},
      selectingUser: [],
      deleteCategoryes: false,
      deleteCategoryDate: {},
      imageBg: "",
      taskLength: 0,
      taskAll: false,
      taskImportant: false,
      thisDay: false,
      scrollPosition: 0,
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
      tasksAll: [],
      visibleItems: {},
      tasksByCategory: {},
      visibleItemsCategory: {},
      itemLength: 0,
      isLoading: false,
      editId: 0,
      isLoadingActive: false,
      totalTaskCount: 0,
      taskUncategory: false,
      currentTime: new Date(),
      taskMe: false,
      important: false,
      importantValue: false,
      todayTask: false,
      activeCategory: {},
      isLoadingButton: false,
      errase: false,
      searchAssignUser: "",
    };
  },
  computed: {
    visibleColumns() {
      return this.columns.filter((column) => column.isVisible);
    },
    iconColor() {
      switch (this.selectedPriority.label) {
        case "High":
          return "pink";
        case "Low":
          return "primary";
        case "Medium":
          return "orange";
        default:
          return "var(--icon-color)";
      }
    },
    statusColor() {
      switch (this.selectedOption.label) {
        case "In Progress":
          return "orange";
        case "Completed":
          return "green";
        default:
          return "var(--icon-color)";
      }
    },
    statusIcon() {
      switch (this.selectedOption.label) {
        case "In Progress":
          return "mdi-progress-check";
        case "Completed":
          return "mdi-progress-close";
        default:
          return "var(--icon-color)";
      }
    },

    items() {
      console.log(this.tasks, "this.tasks");

      // Use a Map to group data by key and merge values
      const groupedTasks = new Map();

      // Iterate through tasks to group by key
      this.tasks.forEach(({ key, value }) => {
        if (groupedTasks.has(key)) {
          // If the key exists, append the new values
          groupedTasks.set(key, [...groupedTasks.get(key), ...value]);
        } else {
          // Otherwise, create a new entry
          groupedTasks.set(key, value);
        }
      });

      // Transform the grouped data into the desired structure
      const transformedTasks = Array.from(groupedTasks.entries()).map(
        ([key, value]) => ({
          id: this.$nano.id(),
          key: this.groupBy,
          value: key,
          data: value.map((entry) => ({
            ids: this.$nano.id(),
            id: entry.itemid,
            link: true,
            icon:
              this.getStatus(entry) === "Completed"
                ? "mdi-progress-close"
                : this.getStatusData(entry),

            color:
              this.getStatus(entry) === "Completed"
                ? "green"
                : this.getStatusDataColor(entry),
            ...this.getFormFieldsData(entry),
            createdBy: entry.createdByEmail,
            createdAt: this.$day.format(entry.createdAt),
            modifiedBy: entry.modifiedByEmail,
            modifiedAt: this.$day.format(entry.modifiedAt),
            buttonAction: true,
            formId: this.formId,
            status: this.getStatus(entry),
            categoryName: entry.categoryName,
            categoryId: entry.categoryId,
            isMarked: entry.isMarked,
          })),
        })
      );

      // Sort the tasks to ensure 'In Progress' is first, 'Completed' is second, and the rest follow
      return transformedTasks.sort((a, b) => {
        const order = { "In Progress": 1, Completed: 2 }; // Define desired order
        return (order[a.value] || 3) - (order[b.value] || 3); // Items not in `order` come last
      });
    },
    inProgressLength() {
      const inProgress = this.tasks.find((task) => task.key === "In Progress");
      console.log(inProgress, "inProgress");
      return inProgress ? inProgress.value.length : 0;
    },
    completedLength() {
      const completed = this.tasks.find((task) => task.key === "Completed");
      return completed ? completed.value.length : 0;
    },

    isInputValid() {
      return this.newLabel || (!this.selectedCategory && this.newCategoryName);
    },

    showAddTask() {
      if (this.$store.state.profileMenus.length)
        return this.$store.state.profileMenus.find((row) => row.tasks).Menu[
          "Manage Task"
        ];

      return false;
    },

    scrollbarHeight() {
      const height = this.filterUserOptions.length * 36;
      return height > 216 ? "276px" : `${height + 58}px`;
    },

    // filteredData() {
    //   console.log(this.items, "dataPush");
    //   return true;
    //   // if (!this.selectedCategory) return [];
    //   // return this.items[0].data.filter(
    //   //   (item) => item.ZxoDRaWhd04oDZTc9cJ4N === this.selectedCategory
    //   // );
    // },
    // groupedData() {
    //   return this.filteredData.reduce((groups, item) => {
    //     const status = item.status || "unknown"; // Handle items without a status
    //     if (!groups[status]) groups[status] = [];
    //     groups[status].push(item);
    //     return groups;
    //   }, {});
    // },
  },
  watch: {
    columns: {
      immediate: true, // Trigger this handler immediately on component creation
      handler() {
        console.log("Columns changed!");
        console.log("New Value:", this.columns);

        // Perform any logic when columns prop changes
        // this.handleColumnsUpdate(newVal);
      },
    },
    search(val) {
      this.filterOptions(val);
    },
    //searchUser(val) {
    //this.filterUsersOptions(val);
    //},
    searchPriority(val) {
      this.filterPriority(val);
    },

    changeStatus: {
      immediate: true,
      handler() {
        if (this.changeStatus) {
          this.tasks = [];
          this.tasksData = [];
          this.items = [];
          this.changeStatusValue();
        }
      },
    },
    searchValue: {
      immediate: true,
      handler() {
        this.searchRow(this.searchValue);
      },
    },
    // sortBy: {
    //   immediate: true,
    //   async handler() {
    //     console.log(this.sortBy,"this.sortBy")
    //     if (this.sortBy.criteria !== "") {
    //       this.isLoadingActive = true;
    //       this.$emit("refresh")
    //       if (this.taskAll) {
    //         this.tasks = [];
    //         this.currentPage = 1;
    //         await this.getTaskEntriesAll();
    //       } else if (this.taskMe) {
    //         this.tasks = [];
    //         this.currentPage = 1;
    //         await this.getTaskMe();
    //       } else if (this.taskUncategory) {
    //         this.tasks = [];
    //         this.currentPage = 1;
    //         await this.getTaskUncategory();
    //       } else if (this.todayTask) {
    //         this.tasks = [];
    //         this.currentPage = 1;
    //         await this.getTaskToday();
    //       } else if (this.thisDay) {
    //         this.tasks = [];
    //         this.currentPage = 1;
    //         await this.getTaskThisDay();
    //       } else if (this.taskImportant) {
    //         this.tasks = [];
    //         this.currentPage = 1;
    //         await this.getTaskImportant();
    //       } else {
    //         this.tasks = [];
    //         this.currentPage = 1;
    //         await this.getListBy(this.selectedCategory.id);
    //       }
    //     }
    //   },
    // },
    refresh: {
      immediate: true,
      async handler() {
        if (this.refresh) {
          this.isLoadingActive = true;
          this.$emit("refresh");
          await this.getCategory();
          if (this.taskAll) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskEntriesAll();
          } else if (this.taskMe) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskMe();
          } else if (this.taskUncategory) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskUncategory();
          } else if (this.todayTask) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskToday();
          } else if (this.thisDay) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskThisDay();
          } else if (this.taskImportant) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskImportant();
          } else {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getListBy(this.selectedCategory.id);
          }
        }
      },
    },

    filterBy: {
      immediate: true,
      async handler() {
        if (this.filterBy.length) {
          if (this.taskAll) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskEntriesAll();
          } else if (this.taskMe) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskMe();
          } else if (this.taskUncategory) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskUncategory();
          } else if (this.todayTask) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskToday();
          } else if (this.thisDay) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskThisDay();
          } else if (this.taskImportant) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getTaskImportant();
          } else {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            await this.getListBy(this.selectedCategory.id);
          }
        }
      },
    },
    editing: {
      immediate: true,
      handler() {
        if (this.editing) {
          if (this.taskAll) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            this.getTaskEntriesAll();
          } else if (this.taskMe) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            this.getTaskMe();
          } else if (this.taskUncategory) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            this.getTaskUncategory();
          } else if (this.todayTask) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            this.getTaskToday();
          } else if (this.thisDay) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            this.getTaskThisDay();
          } else if (this.taskImportant) {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            this.getTaskImportant();
          } else {
            this.tasks = [];
            this.tasksData = [];
            this.currentPage = 1;
            this.getListBy(this.selectedCategory.id);
          }
        }
      },
    },
  },
  created() {
    this.getUsers();
    this.getCategory();
    this.groupBys();
    this.firstCall();
  },
  mounted() {
    this.filteredOptions = this.form;
    this.filteredPriorty = this.priority;
    if (this.filteredOptions.length > 0) {
      this.selectedOption = this.filteredOptions[0];
    }
    if (this.filteredPriorty.length > 0) {
      this.selectedPriority = this.filteredPriorty[2];
    }
    this.$refs.newLabelInput.focus();
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  },
  methods: {
    errasing() {
      this.errase = false;
    },
    dataInput(value) {
      this.newLabel = value;
    },
    firstCall() {
      this.visibleItems = [];
      this.tasks = [];
      this.tasksData = [];
      this.currentPage = 1;
      this.getTaskEntriesAll("value");
    },
    groupBys() {
      const panels = [...this.panels, ...this.secondaryPanels];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      let groupBys = fields.find((field) => field.label === "Task Status")?.id;
      this.groupBy = groupBys;
    },
    isColumnVisible(label) {
      const column = this.columns.find((col) => col.label === label);
      return column ? column.isVisible : false;
    },
    // async deleteItem(id) {
    //   const inputs = {
    //     isDeleted: true,
    //   };
    //   // Call the API to save the form entry
    //   const { data, error } = await form.saveFormEntry(this.formId, id, inputs);
    //   console.log(data, error, "errrors");
    // },
    async getTaskImportant(dummy) {
      this.$emit("category", 0);
      this.$emit("selectMe", false);
      this.editing = false;
      this.taskAll = false;
      this.taskImportant = true;
      this.taskMe = false;
      this.importantValue = true;
      this.thisDay = false;
      this.todayTask = false;
      this.selectedCategory = {};
      this.taskUncategory = false;
      let filter = [];
      let taskOwner = "",
        taskAssign = "";
      const panels = [...this.panels, ...this.secondaryPanels];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      taskOwner = fields.find((field) => field.label === "Task Owner")?.id;
      taskAssign = fields.find((field) => field.label === "Task Assign")?.id;
      if (this.filterBy.length) {
        let filterCriteria = JSON.parse(JSON.stringify(this.filterBy));
        filter.push(
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          },
          {
            filters: [
              {
                criteria: "isMarked",
                condition: "IS_EQUALS_TO",
                value: true,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "AND",
          }
        );
        filterCriteria[0].groupCondition = "AND";
        filter.push(...filterCriteria);
      } else {
        filter = [
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          },
          {
            filters: [
              {
                criteria: "isMarked",
                condition: "IS_EQUALS_TO",
                value: true,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "AND",
          },
        ];
      }
      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: filter,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });
      this.isLoadingActive = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload.data, "mecategory");
      let payloads = payload;
      let data = payloads.data;
      const meta = payloads.meta;

      console.log(data, meta, "data");

      this.tasks.push(...(data || []));
      this.tasksData.push(...(data || []));
      this.totalItems = meta?.totalItems || 0;
      if (this.filteredOptions.length > 0) {
        this.selectedOption = this.filteredOptions[0];
      }
      if (this.filteredPriorty.length > 0) {
        this.selectedPriority = this.filteredPriorty[2];
      }
      if (dummy) {
        this.toggleFirstItemVisibility();
      }
    },
    async getTaskMe(dummy) {
      this.$emit("category", 0);
      this.$emit("selectMe", true);
      this.editing = false;
      this.taskAll = false;
      this.taskImportant = false;
      this.taskMe = true;
      this.thisDay = false;
      this.todayTask = false;
      this.selectedCategory = {};
      this.taskUncategory = false;
      let filter = [];
      let taskAssign = "";
      const panels = [...this.panels, ...this.secondaryPanels];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      taskAssign = fields.find((field) => field.label === "Task Assign")?.id;
      if (this.filterBy.length) {
        let filterCriteria = JSON.parse(JSON.stringify(this.filterBy));
        filter.push({
          filters: [
            {
              criteria: taskAssign,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
              dataType: "SHORT_TEXT",
            },
          ],
          groupCondition: "",
        });
        filterCriteria[0].groupCondition = "AND";
        filter.push(...filterCriteria);
      } else {
        filter = [
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
        ];
      }
      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: filter,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });
      this.isLoadingActive = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload.data, "mecategory");
      let payloads = payload;
      let data = payloads.data;
      const meta = payloads.meta;

      console.log(data, meta, "data");

      this.tasks.push(...(data || []));
      this.tasksData.push(...(data || []));
      this.totalItems = meta?.totalItems || 0;
      if (this.filteredOptions.length > 0) {
        this.selectedOption = this.filteredOptions[0];
      }
      if (this.filteredPriorty.length > 0) {
        this.selectedPriority = this.filteredPriorty[2];
      }
      if (dummy) {
        this.toggleFirstItemVisibility();
      }
    },
    async getTaskThisDay(dummy) {
      this.$emit("selectMe", false);
      this.$emit("category", 0);
      this.editing = false;
      this.thisDay = true;
      this.taskMe = false;
      this.taskAll = false;
      this.taskImportant = false;
      this.todayTask = false;
      this.selectedCategory = {};
      this.taskUncategory = false;
      let filter = [];
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      let Today = `${year}-${month}-${day}`;
      let taskOwner = "",
        taskToday = "",
        taskAssign = "";
      const panels = [...this.panels, ...this.secondaryPanels];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }
      taskOwner = fields.find((field) => field.label === "Task Owner")?.id;
      taskAssign = fields.find((field) => field.label === "Task Assign")?.id;
      taskToday = fields.find((field) => field.label === "End Date")?.id;
      if (this.filterBy.length) {
        let filterCriteria = JSON.parse(JSON.stringify(this.filterBy));
        filter.push(
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          },
          {
            filters: [
              {
                criteria: taskToday,
                condition: "IS_EQUALS_TO",
                value: Today,
                dataType: "DATE",
              },
            ],
            groupCondition: "AND",
          }
        );
        filterCriteria[0].groupCondition = "AND";
        filter.push(...filterCriteria);
      } else {
        filter = [
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          },
          {
            filters: [
              {
                criteria: taskToday,
                condition: "IS_EQUALS_TO",
                value: Today,
                dataType: "DATE",
              },
            ],
            groupCondition: "AND",
          },
        ];
      }
      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: filter,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });
      this.isLoadingActive = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload.data, "Today");
      let payloads = payload;
      let data = payloads.data;
      const meta = payloads.meta;

      console.log(data, meta, "data");

      this.tasks.push(...(data || []));
      this.tasksData.push(...(data || []));
      this.totalItems = meta?.totalItems || 0;
      if (this.filteredOptions.length > 0) {
        this.selectedOption = this.filteredOptions[0];
      }
      if (this.filteredPriorty.length > 0) {
        this.selectedPriority = this.filteredPriorty[2];
      }
      if (dummy) {
        this.toggleFirstItemVisibility();
      }
    },
    async getTaskToday(dummy) {
      this.$emit("selectMe", false);
      this.$emit("category", 0);
      this.editing = false;
      this.taskMe = false;
      this.taskAll = false;
      this.taskImportant = false;
      this.thisDay = false;
      this.todayTask = true;
      this.selectedCategory = {};
      this.taskUncategory = false;
      let filter = [];
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      let Today = `${year}-${month}-${day}`;
      let taskToday = "",
        taskAssign = "";
      const panels = [...this.panels, ...this.secondaryPanels];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }
      taskToday = fields.find((field) => field.label === "End Date")?.id;
      taskAssign = fields.find((field) => field.label === "Task Assign")?.id;
      if (this.filterBy.length) {
        let filterCriteria = JSON.parse(JSON.stringify(this.filterBy));
        filter.push(
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },

          {
            filters: [
              {
                criteria: taskToday,
                condition: "IS_EQUALS_TO",
                value: Today,
                dataType: "DATE",
              },
            ],
            groupCondition: "AND",
          }
        );
        filterCriteria[0].groupCondition = "AND";
        filter.push(...filterCriteria);
      } else {
        filter = [
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },

          {
            filters: [
              {
                criteria: taskToday,
                condition: "IS_EQUALS_TO",
                value: Today,
                dataType: "DATE",
              },
            ],
            groupCondition: "AND",
          },
        ];
      }
      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: filter,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });
      this.isLoadingActive = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload.data, "Today");
      let payloads = payload;
      let data = payloads.data;
      const meta = payloads.meta;

      console.log(data, meta, "data");

      this.tasks.push(...(data || []));
      this.tasksData.push(...(data || []));
      this.totalItems = meta?.totalItems || 0;
      if (this.filteredOptions.length > 0) {
        this.selectedOption = this.filteredOptions[0];
      }
      if (this.filteredPriorty.length > 0) {
        this.selectedPriority = this.filteredPriorty[2];
      }
      if (dummy) {
        this.toggleFirstItemVisibility();
      }
    },
    async getTaskUncategory(dummy) {
      this.$emit("selectMe", false);
      this.$emit("category", 0);
      this.editing = false;
      this.taskMe = false;
      this.taskAll = false;
      this.taskImportant = false;
      this.thisDay = false;
      this.todayTask = false;
      this.selectedCategory = {};
      this.taskUncategory = true;
      let filter = [];
      let taskOwner = "",
        taskAssign = "";
      const panels = [...this.panels, ...this.secondaryPanels];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      taskOwner = fields.find((field) => field.label === "Task Owner")?.id;
      taskAssign = fields.find((field) => field.label === "Task Assign")?.id;
      if (this.filterBy.length) {
        let filterCriteria = JSON.parse(JSON.stringify(this.filterBy));
        filter.push(
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          }
        );
        filterCriteria[0].groupCondition = "AND";
        filter.push(...filterCriteria);
      } else {
        filter = [
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          },
        ];
      }
      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: filter,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        hasNoCategory: true,
      });
      this.isLoadingActive = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload.data, "uncategory");
      let payloads = payload;
      let data = payloads.data;
      const meta = payloads.meta;

      console.log(data, meta, "data");

      this.tasks.push(...(data || []));
      this.tasksData.push(...(data || []));
      this.totalItems = meta?.totalItems || 0;
      if (this.filteredOptions.length > 0) {
        this.selectedOption = this.filteredOptions[0];
      }
      if (this.filteredPriorty.length > 0) {
        this.selectedPriority = this.filteredPriorty[2];
      }
      if (dummy) {
        this.toggleFirstItemVisibility();
      }
    },
    async getTaskEntriesAll(dummy) {
      this.$emit("selectMe", false);
      this.$emit("category", 0);
      this.taskUncategory = false;
      this.taskMe = false;
      this.editing = false;
      this.taskMe = false;
      this.selectedCategory = {};
      this.taskAll = true;
      this.thisDay = false;
      this.taskImportant = false;
      this.todayTask = false;
      let filter = [];
      let taskOwner = "",
        taskAssign = "";
      const panels = [...this.panels, ...this.secondaryPanels];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }
      taskOwner = fields.find((field) => field.label === "Task Owner")?.id;
      taskAssign = fields.find((field) => field.label === "Task Assign")?.id;
      if (this.filterBy.length) {
        let filterCriteria = JSON.parse(JSON.stringify(this.filterBy));
        filter.push(
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          }
        );
        filterCriteria[0].groupCondition = "AND";
        filter.push(...filterCriteria);
      } else {
        filter = [
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          },
        ];
      }

      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: filter,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });
      this.isLoadingActive = false;
      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload.data, "payloadselected");
      let payloads = payload;
      let data = payloads.data;
      const meta = payloads.meta;

      console.log(data, meta, "data");

      this.tasks.push(...(data || []));
      this.tasksData.push(...(data || []));
      this.totalItems = meta?.totalItems || 0;
      if (this.filteredOptions.length > 0) {
        this.selectedOption = this.filteredOptions[0];
      }
      if (this.filteredPriorty.length > 0) {
        this.selectedPriority = this.filteredPriorty[2];
      }
      if (dummy) {
        this.toggleFirstItemVisibility();
      }
    },
    // Toggles visibility for a specific iteme by key
    toggleVisibility(itemeKey) {
      this.$set(this.visibleItems, itemeKey, !this.visibleItems[itemeKey]);
      this.visibleItems;
    },
    toggleVisibilitye(categoryId, itemValue) {
      // Ensure the category exists in visibleItems
      if (!this.visibleItems[categoryId]) {
        this.$set(this.visibleItems, categoryId, {});
      }

      // Toggle visibility for the specific item in the category
      this.$set(
        this.visibleItems[categoryId],
        itemValue,
        !this.visibleItems[categoryId][itemValue]
      );
    },
    // toggleVisibilityCategory(itemeKey) {
    //   // Safely check if the category exists using Object.prototype.hasOwnProperty.call
    //   if (
    //     Object.prototype.hasOwnProperty.call(this.tasksByCategory, itemeKey)
    //   ) {
    //     delete this.tasksByCategory[itemeKey];
    //     console.log(`Category ${itemeKey} has been cleared.`);
    //   } else {
    //     console.log(`Category ${itemeKey} does not exist.`);
    //   }
    //   this.$set(
    //     this.visibleItemsCategory,
    //     itemeKey,
    //     !this.visibleItemsCategory[itemeKey]
    //   );
    //   this.visibleItemsCategory;
    // },
    // toggleFirstItemVisibilityCategory() {
    //   const firstKey = this.categories[0].id;
    //   if (firstKey) {
    //     this.toggleVisibilityCategory(firstKey);
    //     this.toggleFirstItemVisibility();
    //   } else {
    //     console.warn("No items available to toggle visibility.");
    //   }
    // },
    toggleFirstItemVisibility() {
      const firstKey = this.items[0]?.value;
      if (firstKey) {
        this.toggleVisibility(firstKey);
        // this.toggleFirstItemVisibilityCategory();
      } else {
        console.warn("No items available to toggle visibility.");
      }
    },
    // data() {
    //   return {
    //     isLoading: false, // Flag to track if a fetch request is in progress
    //     scrollPosition: 0, // To track scroll position
    //     // other properties...
    //   };
    // },

    loadMore(e) {
      const totalDataLength = this.items.reduce(
        (sum, item) => sum + item.data.length,
        0
      );

      console.log("Total length of all data arrays:", totalDataLength);
      console.log(totalDataLength);
      if (
        this.totalItems > this.itemsPerPage &&
        totalDataLength >= this.currentPage * this.itemsPerPage
      ) {
        let currentScrollPosition = e.srcElement.scrollTop;
        if (currentScrollPosition > this.scrollPosition) {
          this.currentPage += 1;
          if (this.taskAll) {
            this.getTaskEntriesAll();
          } else if (this.taskUncategory) {
            this.getTaskUncategory();
          } else if (this.taskMe) {
            this.getTaskMe();
          } else if (this.todayTask) {
            this.getTaskToday();
          } else if (this.thisDay) {
            this.getTaskThisDay();
          } else if (this.taskImportant) {
            this.getTaskImportant();
          }
        }
        this.scrollPosition = currentScrollPosition;
      }
    },
    loadMores(e) {
      const totalDataLength = this.items.reduce(
        (sum, item) => sum + item.data.length,
        0
      );

      console.log("Total length of all data arrays:", totalDataLength);
      console.log(totalDataLength);
      if (
        this.totalItems > this.itemsPerPage &&
        totalDataLength >= this.currentPage * this.itemsPerPage
      ) {
        let currentScrollPosition = e.srcElement.scrollTop;
        if (currentScrollPosition > this.scrollPosition) {
          this.currentPage += 1;
          if (this.taskAll) {
            let id = this.categories[0].id;
            this.getTaskEntriesAll(id);
          } else {
            this.getListBy(this.selectedCategory.id);
          }
        }
        this.scrollPosition = currentScrollPosition;
      }
    },
    // selectAllCategory(value) {
    //   console.log(value);
    // },
    imageURLAll() {
      return `${process.env.VUE_APP_API_URL}/user/avatar/${
        this.$store.state.session.tenantId
      }/${this.$store.state.session.id}?t=${new Date().getTime()}`;
    },
    toggleMenu(id) {
      this.$set(this.menuEdit, id, !this.menuEdit[id]);
    },
    // Closes the menu
    closeMenu(id) {
      this.$set(this.menuEdit, id, false);
    },
    confirmDelete(value) {
      this.deleteCategoryes = true;
      this.deleteCategoryDate = value;
    },
    async deleteCategory(value) {
      console.log(value);
      let input = { isDeleted: true };
      const { error, payload } = await task.editCategory(value.id, input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getCategory();
      console.log(payload);
    },
    editCategory(value) {
      this.editCategorys = true;
      this.editImage = true;
      this.addCategory = true;
      this.name = value.category;
      let users = JSON.parse(value.users);
      let ids = users.map((user) => String(user.id));
      this.multipleUser = ids;
      this.categoryId = value.id;
    },
    async editCategoryes() {
      let array = this.multipleUser.map(Number);
      let input = { category: this.name, users: array };
      const { error, payload } = await task.editCategory(
        this.categoryId,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.name = "";
      this.multipleUser = [];
      this.editCategorys = false;
      this.addCategory = false;
      if (this.uploadImage !== "") {
        this.uploadImages(this.categoryId);
      } else {
        this.getCategory();
      }
      console.log(input, payload);
    },
    imageURL(id) {
      return `${process.env.VUE_APP_API_URL}/task/categoryMasterIcon/${
        this.$store.state.session.tenantId
      }/${id}?t=${new Date().getTime()}`;
    },
    backGround() {
      this.imageBg = `${process.env.VUE_APP_API_URL}/task/categoryMasterIcon/${
        this.$store.state.session.tenantId
      }/${this.selectedCategory.id}?t=${new Date().getTime()}`;
    },
    async uploadImages(id) {
      let input = {
        base64File: this.uploadImage.split(",")[1],
        fileId: id,
      };

      const { error, payload } = await task.createIcon(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.uploadImage = "";
      this.addCategory = false;
      this.getCategory();
      console.log(input, payload);
    },
    async saveBycategory() {
      // this.isLoadingActive = true;
      let id = 0;
      if (
        this.taskAll ||
        this.taskUncategory ||
        this.taskMe ||
        this.thisDay ||
        this.todayTask ||
        this.taskImportant
      ) {
        id = this.editId;
      } else {
        id = this.selectedCategory.id;
      }
      const { error, payload } = await task.createTaskCategory({
        categoryId: id,
        wFormId: this.formId,
        entryId: this.entryId,
      });
      this.isLoadingActive = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.tasksByCategory[this.editId]) {
        // Use Vue's $delete to remove the key and its associated data
        this.$delete(this.tasksByCategory, this.editId);
        console.log(
          `Key ${this.editId} and its associated data have been deleted.`
        );
      } else {
        console.error(`Key ${this.editId} does not exist in tasksByCategory.`);
      }
      this.currentPage = 1;
      console.log(this.tasksByCategory, payload, "by category");
    },

    async getListBy(id, value) {
      this.taskAll = false;
      this.taskImportant = false;
      this.importantValue = false;
      this.thisDay = false;
      this.taskMe = false;
      this.todayTask = false;
      this.taskUncategory = false;
      let filter = [];
      let taskOwner = "",
        taskAssign = "";
      const panels = [...this.panels, ...this.secondaryPanels];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      taskOwner = fields.find((field) => field.label === "Task Owner")?.id;
      taskAssign = fields.find((field) => field.label === "Task Assign")?.id;
      if (this.filterBy.length) {
        let filterCriteria = JSON.parse(JSON.stringify(this.filterBy));
        filter.push(
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          }
        );
        filterCriteria[0].groupCondition = "AND";
        filter.push(...filterCriteria);
      } else {
        filter = [
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          },
        ];
      }
      const { error, payload } = await task.getTaskList(id, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: filter,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });
      this.isLoadingActive = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload.data, "payloadselected");
      let payloads = JSON.parse(payload);
      if (payload.length) {
        let data = payloads.data;
        const meta = payloads.meta;

        console.log(data, meta, "data");

        this.tasks.push(...(data || []));
        this.tasksData.push(...(data || []));
        this.totalItems = meta?.totalItems || 0;
      }
      if (value) {
        this.toggleFirstItemVisibility();
      }
      if (this.filteredOptions.length > 0) {
        this.selectedOption = this.filteredOptions[0];
      }
      if (this.filteredPriorty.length > 0) {
        this.selectedPriority = this.filteredPriorty[2];
      }
    },

    async createCategory() {
      let array = this.multipleUser.map(Number);
      console.log(array, "array");
      const { error, payload } = await task.createCategory({
        category: this.name,
        users: array,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.name = "";
      (this.multipleUser = ""), await this.uploadImages(payload.id);
      this.getCategory();
    },
    async getCategory(value) {
      let filter = [
        {
          filters: [
            {
              criteria: "users",
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.id,
            },
          ],
        },
      ];
      const { error, payload } = await task.getCategory({
        // sortBy: this.sortBy,
        // groupBy: this.groupBy,
        filterBy: filter,
        itemsPerPage: 100,
        currentPage: 1,
        // mode: this.mode,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      // console.log(data, "data");

      this.categories = data[0].value || [];
      this.totalTaskCount = this.categories.reduce(
        (sum, task) => sum + task.taskCount,
        0
      );
      // console.log(this.categories, value, "this.categories");
      this.categoriesData = data || [];
      this.totalItemsCategory = meta?.totalItems || 0;
      if (value) {
        this.selectedCategory = {};
        this.selectCategory(this.activeCategory, value);
      }
    },
    openDateMenu() {
      this.$refs.dateMenu.open; // Open the menu after DOM updates
    },
    dateSelected(selected) {
      // Format the date to YYYY-MM-DD and assign it to newDate
      this.newDate = date.formatDate(selected, "YYYY-MM-DD");
      this.selectedDate = selected;
      // this.$refs.dateMenu.hide();
      console.log("Formatted date:", this.newDate);
    },
    updateColor(color) {
      this.$emit("update:color", color);
    },
    updateName(name) {
      this.name = name;
      this.$emit("update:name", name);
    },

    // toggleStatusSection(status) {
    //   // Toggle the visibility of a status group by adding or removing it from the openStatus set
    //   if (this.openStatus.has(status)) {
    //     this.openStatus.delete(status); // Close the group if already open
    //   } else {
    //     this.openStatus.add(status); // Open the group if closed
    //   }
    //   this.updateGroupedData();
    // },
    // updateGroupedData() {
    //   this.groupedData = this.filteredData.reduce((groups, item) => {
    //     const status = item.status || "Unknown";
    //     if (!groups[status]) groups[status] = [];
    //     groups[status].push(item);
    //     return groups;
    //   }, {});
    // },
    toggleItemDropdown(item) {
      // Toggle the showStatusDropdown property on the item
      item.showStatusDropdown = !item.showStatusDropdown;
    },
    openStatusMenu(id, status) {
      if (this.menuStates[id]) {
        // If it exists, toggle the menu state to true (open it)
        this.menuStates[id] = true;
      } else {
        // Initialize the menu state for the item and open the menu
        this.$set(this.menuStates, id, true); // Open the menu
      }
      const allOptions = {
        " ": ["Open", "In Progress", "ReOpen", "Closed"],
        Completed: ["In Progress"],
        ReOpen: ["Open", "Closed", "In Progress"],
        "In Progress": ["Completed"],
        Open: ["Completed"],
      };

      const validStatus = status.trim() || " "; // Fallback to " " if status is empty

      this.UpdateOption = (allOptions[validStatus] || []).map((option) => ({
        id: this.$nano.id(),
        label: option,
        link: option,
      }));
      // const selectedItem = this.items.find(item => item.id === id);
      // if (selectedItem) {
      //   this.selectedOption = selectedItem.status; // Set the selected option to the item's current status
      // }
    },
    closeStatusMenu(id) {
      this.currentPage = 1;
      this.menuStates[id] = false; // Close the specific menu by ID
    },
    updateStatus(item) {
      this.statusChangeConfirm = true;
      this.itemSelected = item;
      this.closeStatusMenu(item.id);
    },
    updateStatusOption(item, ids) {
      this.editId = ids;
      this.statusChangeConfirm = true;
      this.itemSelected = item;
      this.closeStatusMenu(item.id);
    },
    updateImportant(item) {
      this.editId = item.id;
      this.important = true;
      if (item.isMarked) {
        this.importantValue = false;
      } else {
        this.importantValue = true;
      }
      this.currentPage = 1;
      this.itemSelected = item;
      this.trigerSave = true;
    },
    changeStatusValue() {
      this.trigerSave = true;
      this.statusChangeConfirm = false;
    },
    changeStatusIcon(item) {
      this.itemSelected = item;
      this.selectedOption = "Completed";
      this.trigerSave = true;
      this.tasks = [];
      this.tasksData = [];
      this.currentPage = 1;
      console.log(this.itemSelected, this.selectedOption, "changeStatusIcon");
    },
    showData(id) {
      // Find the item in items[0].data where item.id matches the passed id
      const matchingItem = this.items[0].data.find((item) => item.id === id);

      if (matchingItem) {
        this.newLabel = matchingItem["6eGtt2KUDGzaqJnwPQJU2"];
        this.selectedUsers = {
          id: this.$nano.id(),
          label: matchingItem["U3YNqRYR-WfuLDF6wTE__"],
          value: matchingItem["U3YNqRYR-WfuLDF6wTE__"],
        };
        this.newDate = matchingItem["APhark6KdPk8uLDBc_OQn"];

        this.selectedPriority = {
          id: this.$nano.id(),
          label: matchingItem["_15zEDXTUT_0n1BDbe590"],
          link: matchingItem["_15zEDXTUT_0n1BDbe590"],
        };
        this.selectedOption.label = {
          id: this.$nano.id(),
          label: matchingItem.status,
          link: matchingItem.status,
        };
        console.log(
          this.selectedOption,
          this.selectedPriority,
          this.selectedUsers,
          "datafilling"
        );
      } else {
        console.log("No matching item found with id:", id);
      }
    },
    // uniqueCategories() {
    //   // Check if items data exists and is not empty
    //   if (!this.items.length || !this.items[0].data.length) {
    //     return []; // Return an empty array if no data is available
    //   }
    //   // Use a Set to collect unique categories across all entries in items
    //   const categories = new Set();
    //   this.items.forEach((item) => {
    //     item.data.forEach((entry) => {
    //       if (entry.ZxoDRaWhd04oDZTc9cJ4N) {
    //         // Ensure the property exists in entry
    //         categories.add(entry.ZxoDRaWhd04oDZTc9cJ4N);
    //       }
    //     });
    //   });

    //   console.log(Array.from(categories), "unique categories"); // Log the unique categories

    //   this.categoryArray = Array.from(categories); // Return the array of unique categories
    // },
    getFormFieldsData(entry) {
      const data = {};
      // console.log(entry);
      for (let field of this.formFields) {
        const complexTypes = [
          "TABLE",
          "MATRIX",
          "FILL_IN_THE_BLANKS",
          "TABS",
          "POPUP",
        ];
        // console.log(field.label);
        if (field.label !== "Reminder" || field.label !== "Notification") {
          // if (field.label !== "Reminder" || field.label !== "Notification") {
          data[field.id] = complexTypes.includes(field.type)
            ? JSON.parse(entry[field.id])
            : entry[field.id];
        }
      }
      const panels = [...this.panels, ...this.secondaryPanels];
      let fields = [];

      // Collect all fields from the panels
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      // Create a mapping of field IDs to labels
      const idToLabelMap = fields.reduce((acc, field) => {
        acc[field.id] = field.label;
        return acc;
      }, {});

      // Transform the data object
      const transformedData = Object.entries(data).reduce(
        (acc, [key, value]) => {
          // Use the label if it exists, otherwise keep the original key
          const newKey = idToLabelMap[key] || key;
          acc[newKey] = value;
          return acc;
        },
        {}
      );

      // Log the transformed data
      return transformedData;
    },

    getStatusDataColor(entry) {
      let data = "warning";
      for (let field of this.formFields) {
        if (field.label == "End Date" && entry[field.id]) {
          var today = new Date().toISOString().split("T")[0];
          var entryDate = new Date(entry[field.id]).toISOString().split("T")[0];
          if (new Date(entryDate) >= new Date(today)) {
            data = "warning";
          } else data = "red";
        }
      }
      return data;
    },

    getStatusData(entry) {
      let data = "mdi-progress-clock";
      for (let field of this.formFields) {
        if (field.label == "End Date" && entry[field.id]) {
          var today = new Date().toISOString().split("T")[0];
          var entryDate = new Date(entry[field.id]).toISOString().split("T")[0];
          if (new Date(entryDate) >= new Date(today)) {
            data = "mdi-progress-check";
          } else data = "mdi-progress-clock";
        }
      }
      return data;
    },

    getStatus(entry) {
      let data = "";
      for (let field of this.formFields) {
        if (field.label == "Task Status") {
          data = entry[field.id];
        }
      }

      return data;
    },

    async fetchTasksByCategory(id) {
      this.taskAll = true; // Indicate that we're fetching all tasks
      this.selectedCategory = {};
      let categoryId =
        id || (this.categories.length > 0 ? this.categories[0].id : 0);

      if (!categoryId) {
        console.error("Invalid category ID");
        return;
      }

      // Ensure tasksByCategory is initialized for the selected category
      if (!this.tasksByCategory[categoryId]) {
        this.$set(this.tasksByCategory, categoryId, []);
      }

      // Fetch tasks for the selected category (replace with actual API call)
      await this.getTaskEntriesAll(categoryId);

      // Get the current tasks by category
      const existingTasks = this.tasksByCategory[categoryId] || [];

      // Iterate over the new tasks
      this.tasks.forEach((newTask) => {
        const existingTaskIndex = existingTasks.findIndex(
          (task) => task.value === newTask.value
        );

        if (existingTaskIndex !== -1) {
          // Update the currentPage for all tasks in the category
          existingTasks.forEach((task) => {
            task.currentPage = newTask.currentPage;
          });

          // Merge the data arrays
          existingTasks[existingTaskIndex].data = [
            ...existingTasks[existingTaskIndex].data,
            ...newTask.data,
          ];
        } else {
          // If task with the same value doesn't exist, add it as a new entry
          existingTasks.push(newTask);
        }
      });

      // Update the tasksByCategory with the merged data
      this.$set(this.tasksByCategory, categoryId, existingTasks);
      // const keys = Object.keys(this.tasksByCategory);
      // let categoryData = [];
      // Check if the categoryId exists in tasksByCategory
      // if (keys.includes(String(id))) {
      //   categoryData = this.tasksByCategory[id];
      //   this.itemLength = categoryData.length; // Get the current page of the category
      //   console.log(categoryData, "categoryDatadf"); // Log the category data
      // } else {
      //   console.log("Category ID not found");
      //   return; // Exit if the category ID is not found
      // }
      console.log("Tasks grouped by categoryer:", this.tasksByCategory);
    },
    async getTaskEntries(categoryId) {
      this.taskAll = true;
      this.tasks = [];
      this.tasksData = [];
      let filter = [];
      const panels = [...this.panels, ...this.secondaryPanels];
      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      const taskOwner = fields.find(
        (field) => field.label === "Task Owner"
      )?.id;
      const taskAssign = fields.find(
        (field) => field.label === "Task Assign"
      )?.id;

      // Build the filter criteria
      if (this.filterBy.length) {
        let filterCriteria = JSON.parse(JSON.stringify(this.filterBy));
        filter.push(
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          }
        );
        filterCriteria[0].groupCondition = "AND";
        filter.push(...filterCriteria);
      } else {
        filter = [
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          },
        ];
      }
      let page = this.currentPage;
      try {
        const { error, payload } = await task.getTaskList(categoryId, {
          groupBy: this.groupBy,
          filterBy: filter,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        });

        if (error) {
          this.$alert.error(error);
          return;
        }

        const payloads = JSON.parse(payload);
        const data = payloads.data;
        const meta = payloads.meta;
        console.log(data, "dtada");

        if (data.length) {
          this.tasks = data;
          const groupedTasks = new Map();

          this.tasks.forEach(({ key, value }) => {
            if (groupedTasks.has(key)) {
              // Append new values if the key already exists
              groupedTasks.set(key, [...groupedTasks.get(key), ...value]);
            } else {
              // Add a new entry for the key
              groupedTasks.set(key, value);
            }
          });

          // Step 2: Transform the grouped data into the desired structure
          const transformedData = Array.from(groupedTasks.entries()).map(
            ([key, value]) => ({
              currentPage: page,
              key: this.groupBy, // Use the groupBy value for the key
              value: key, // Original key
              data: value.map((entry) => ({
                id: entry.itemid,
                icon:
                  this.getStatus(entry) === "Closed"
                    ? "mdi-progress-close"
                    : this.getStatusData(entry),
                color:
                  this.getStatus(entry) === "Closed"
                    ? "green"
                    : this.getStatusDataColor(entry),
                ...this.getFormFieldsData(entry), // Additional fields
                createdBy: entry.createdByEmail,
                createdAt: this.$day.format(entry.createdAt),
                modifiedBy: entry.modifiedByEmail,
                modifiedAt: this.$day.format(entry.modifiedAt),
                buttonAction: true,
                formId: this.formId,
                status: this.getStatus(entry),
              })),
            })
          );

          console.log(transformedData, "transformedData");

          // Update this.tasks to contain the transformed data
          this.tasks = transformedData;

          this.tasksData.push(...transformedData);
          this.totalItems = meta?.totalItems || 0;
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    },

    async getUsers() {
      const { error, payload } = await user.getUserList();
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (!payload) {
        return;
      }
      this.users = payload.map((user) => ({
        id: this.$nano.id(),
        label: user.value,
        value: user.id,
      }));
      this.filterUserOptions = this.users;
      console.log(this.filterUserOptions, "users");
    },

    searchList(value) {
      this.filterUserOptions.forEach((user) => {
        user.display = "";
        if (value) {
          if (!user.label.toLowerCase().includes(value.toLowerCase())) {
            user.display = "hide";
          }
        }
      });
    },

    selectUser(id) {
      this.selectedUsers = {};
      this.filterUserOptions.forEach((user) => {
        user.selected = false;
      });
      let selectedUser = this.filterUserOptions.find((user) => user.id === id);
      if (selectedUser) {
        selectedUser.selected = true;
        this.selectedUsers = selectedUser;
      }
    },
    async selectCategory(category, value) {
      this.taskUncategory = false;
      this.taskMe = false;
      this.editId = 0;
      this.tasks = [];
      let id = category.id;
      this.$emit("selectMe", false);
      this.$emit("category", category.id);
      this.currentPage = 1;
      this.tasks = [];
      this.tasksData = [];
      this.taskAll = false;
      this.taskImportant = false;
      this.thisDay = false;
      this.todayTask = false;
      this.selectedCategory =
        category === this.selectedCategory ? null : category;
      this.newCategoryName = "";
      // let users = JSON.parse(category.users);
      // console.log(this.selectedCategory, "this.selectedCategory");
      // this.selectingUser = users.map((user) => ({
      //   id: this.$nano.id(),
      //   label: user.email,
      //   value: user.id,
      // }));
      // this.filterUserOptions = this.selectingUser;
      // if (this.filteredOptions.length > 0) {
      //   this.selectedOption = this.filteredOptions[0];
      // }
      // if (this.filteredPriorty.length > 0) {
      //   this.selectedPriority = this.filteredPriorty[2];
      // }
      console.log(this.selectedCategory, "this.selectingUser");
      if (!value) {
        await this.getListBy(id, "value");
      }
    },
    setCategoryName() {
      if (this.newCategoryName) {
        this.selectedCategory = this.newCategoryName; // Set the selected category to the new category name
        this.newCategoryName = ""; // Clear the input after setting
      }
    },

    async addItem() {
      if (this.newLabel) {
        this.isLoadingButton = true;
        this.tasks = [];
        this.itemSelected = {};
        this.tasksData = [];
        this.currentPage = 1;
        this.trigerSave = true;
        this.searchAssignUser = "";
      } else {
        this.$alert.warning("Kindly Add Task Name");
      }
    },
    toggleStatusDropdown(item) {
      item.showStatusDropdown = !item.showStatusDropdown; // Toggle dropdown visibility
    },
    // updateStatus(item, status) {
    //   item.status = status; // Update the item's status
    //   item.showStatusDropdown = false; // Close the dropdown
    // },
    async saveTask(fieldsModel, forms) {
      this.isLoadingActive = true;
      this.trigerSave = false;
      this.tasks = [];
      console.log("Initial fieldsModel:", fieldsModel, this.itemSelected); // Log the input fieldsModel
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      let Today = `${year}-${month}-${day}`;
      let input = { isMarked: this.importantValue, todayTask: this.todayTask };

      // const selectedDate = this.newDate;
      // const [dateOnly, timeOnly] = selectedDate.split("T");
      // console.log(timeOnly);
      // Mapping each form field based on label to populate the input object
      forms.forEach((field) => {
        switch (field.label) {
          case "Task Name":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["Task Name"]
              : this.newLabel;

            break;
          case "Details":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["Details"]
              : " ";

            break;
          case "Task Owner":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["Task Owner"]
              : this.$store.state.session.email;

            break;
          case "Task Assign":
            input[field.id] = this.taskMe
              ? this.$store.state.session.email
              : this.itemSelected.id
              ? this.itemSelected["Task Assign"]
              : this.selectedUsers.label;

            break;
          case "Start Date":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["Start Date"]
              : Today;
            break;
          case "End Date":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["End Date"]
              : this.newDate;

            break;
          case "Priority":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["Priority"]
              : this.selectedPriority.label;

            break;
          case "Task Status":
            input[field.id] = this.important
              ? this.itemSelected.status
              : this.itemSelected.id
              ? this.selectedOption
              : this.selectedOption.label;

            break;
          default:
            console.log(`Label '${field.label}' not matched with any case.`);
        }
      });
      let entryId = 0;
      if (this.important) {
        entryId = this.editId;
      } else {
        entryId = this.itemSelected.id ? this.itemSelected.id : 0;
      }
      const inputs = {
        fields: input,
      };
      console.log("Final input object:", inputs, this.formId, entryId); // Log the constructed input object
      // Call the API to save the form entry
      const { data, error } = await form.saveFormEntry(
        this.formId,
        entryId,
        inputs
      );
      this.isLoadingActive = false;
      // Check for errors in the API response
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (this.selectedCategory.id) {
        console.log(this.selectedCategory);
        let users = JSON.parse(this.selectedCategory.users);
        console.log(users, this.selectedUsers.value, "this.usercva");
        let userValidate = users.find(
          (user) => user.id === Number(this.selectedUsers.value)
        );
        if (!userValidate) {
          this.activeCategory = this.selectedCategory;
          let selectedId = Number(this.selectedUsers.value);
          const userIds = users.map((user) => user.id);
          let array = userIds;
          array.push(selectedId);
          let input = {
            category: this.selectedCategory.category,
            users: array,
          };
          console.log(input, "input");
          const { error } = await task.editCategory(
            this.selectedCategory.id,
            input
          );

          if (error) {
            this.$alert.error(error);
            return;
          }
          await this.getCategory("Call");
        }
      }
      if (!this.taskImportant) {
        this.importantValue = false;
      }
      this.important = false;
      this.entryId = data.output;
      console.log("API Response Data:", data, "Error:");
      if (this.selectedCategory.id && !this.changeStatus) {
        await this.saveBycategory();
      }
      (this.selectedOption = {}),
        // Reset trigger save flag
        (this.trigerSave = false);
      if (this.selectedCategory.id) {
        await this.getCategory();
        await this.getListBy(this.selectedCategory.id);
      } else if (this.taskMe) {
        await this.getTaskMe();
      } else if (this.taskUncategory) {
        await this.getTaskUncategory();
      } else if (this.todayTask) {
        await this.getTaskToday();
      } else if (this.thisDay) {
        await this.getTaskThisDay();
      } else if (this.taskImportant) {
        await this.getTaskImportant();
      } else {
        await this.getTaskEntriesAll();
      }
      this.selectedPriority = {};
      this.changeStatus = false;
      this.newLabel = "";
      this.errase = true;
      this.filteredPriorty = this.priority;
      if (this.filteredOptions.length > 0) {
        this.selectedOption = this.filteredOptions[0];
      }
      if (this.filteredPriorty.length > 0) {
        this.selectedPriority = this.filteredPriorty[2];
      }
      this.selectedUsers = {};
      this.selectedDate = "";
      this.isLoadingButton = false;
      // Continue with the rest of the function logic
      // if (Object.keys(fieldsModel).length !== 0) {
      //   // The rest of your function logic...
      // } else {
      // }
    },
    async saveTaskMe(fieldsModel, forms) {
      // this.isLoadingActive = true;
      this.trigerSave = false;
      this.tasks = [];
      this.tasksData = [];
      console.log("Initial fieldsModel:", fieldsModel, this.itemSelected); // Log the input fieldsModel
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      let Today = `${year}-${month}-${day}`;
      let input = {};

      // const selectedDate = this.newDate;
      // const [dateOnly, timeOnly] = selectedDate.split("T");
      // console.log(timeOnly);
      // Mapping each form field based on label to populate the input object
      forms.forEach((field) => {
        switch (field.label) {
          case "Task Name":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["6eGtt2KUDGzaqJnwPQJU2"]
              : this.newLabel;

            break;
          case "Details":
            input[field.id] = " ";

            break;
          case "Task Owner":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["hwaMAaL6R8Xcf6KpJplwO"]
              : this.$store.state.session.email;

            break;
          case "Task Assign":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["U3YNqRYR-WfuLDF6wTE__"]
              : this.$store.state.session.email;

            break;
          case "Start Date":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["APhark6KdPk8uLDBc_OQn"]
              : Today;
            break;
          case "End Date":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["ffkthyds-ad6RPaZkns3u"]
              : this.newDate;

            break;
          case "Priority":
            input[field.id] = this.itemSelected.id
              ? this.itemSelected["_15zEDXTUT_0n1BDbe590"]
              : this.selectedPriority.label;

            break;
          case "Task Status":
            input[field.id] = this.itemSelected.id
              ? this.selectedOption
              : this.selectedOption.label;

            break;
          default:
            console.log(`Label '${field.label}' not matched with any case.`);
        }
      });
      const entryId = this.itemSelected.id ? this.itemSelected.id : 0;
      const inputs = {
        fields: input,
      };
      console.log("Final input object:", inputs, this.formId, entryId); // Log the constructed input object
      // Call the API to save the form entry
      const { data, error } = await form.saveFormEntry(
        this.formId,
        entryId,
        inputs
      );
      this.isLoadingActive = false;
      // Check for errors in the API response
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.entryId = data.output;
      console.log("API Response Data:", data, "Error:", error);
      this.getTaskMe();
      // if (this.selectedCategory.id && !this.changeStatus) {
      //   await this.saveBycategory();
      // }
      // (this.selectedOption = {}),
      //   // Reset trigger save flag
      //   (this.trigerSave = false);
      // if (this.selectedCategory.id) {
      //   this.getListBy(this.selectedCategory.id);
      // } else {
      //   this.getTaskEntriesAll();
      // }
      this.changeStatus = false;
      this.newLabel = "";
      this.filteredPriorty = this.priority;
      if (this.filteredOptions.length > 0) {
        this.selectedOption = this.filteredOptions[0];
      }
      if (this.filteredPriorty.length > 0) {
        this.selectedPriority = this.filteredPriorty[2];
      }
      this.selectedUsers = {};
      this.selectedDate = "";
      // Continue with the rest of the function logic
      // if (Object.keys(fieldsModel).length !== 0) {
      //   // The rest of your function logic...
      // } else {
      // }
    },
    openMenu() {
      this.$refs.menu.show();
    },
    openUser() {
      this.$refs.user.show();
    },
    openPriority() {
      this.$refs.menu.priority();
    },
    filterOptions() {
      const query = this.search.toLowerCase();
      this.filteredOptions = this.form.filter((option) =>
        option.label.toLowerCase().includes(query)
      );
    },
    filterUsersOptions() {
      const query = this.searchUser.toLowerCase();
      this.filterUserOptions = this.users.filter((option) =>
        option.label.toLowerCase().includes(query)
      );
    },
    // filterPriority() {
    //   const query = this.searchPriority.toLowerCase();
    //   this.filterUserOptions = this.priority.filter((option) =>
    //     option.label.toLowerCase().includes(query)
    //   );
    // },
    selectOption(option) {
      this.selectedOption = option;
      this.$refs.menu.hide();
    },
    selectPriority(option) {
      this.selectedPriority = option;
      this.$refs.priority.hide();
    },
    searchRow(search) {
      if (search) {
        console.log(this.tasksData, "this.tasksData");
        this.tasks = this.tasksData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) =>
            Object.values(row).some((cell) =>
              String(cell).toLowerCase().includes(search.toLowerCase())
            )
          ),
        }));
      } else {
        console.log(this.tasksData, "this.tasksDatass");
        this.tasks = this.tasksData;
      }
    },
  },
};

// Mock API call function for demonstration
// async function fakeApiCall(input) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       console.log("Data saved:", input);
//       resolve({ success: true }); // Simulate a successful response
//     }, 1000);
//   });
// }
</script>

<style scoped>
.chat-container {
  display: flex;
}

.category-list {
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

.category-item {
  cursor: pointer;
  padding: 8px;
}

.category-item:hover {
  background-color: var(--component-bg-color);
  border-radius: 5px;
}

.category-item-active {
  padding: 8px;
  cursor: pointer;
  background-color: var(--component-bg-color);
  border-radius: 5px;
}
.data-list {
  display: flex;
  flex-direction: column;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; /* Place watermark below the content */
  background-size: cover; /* Adjust to fit or repeat */
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2; /* Adjust transparency for watermark effect */
  pointer-events: none; /* Prevent watermark from interfering with interactions */
}

.data-row {
  display: flex;
  align-items: center;
  padding: 4px;
  border-bottom: 1px solid var(--divider-color);
  border-right: 1px solid var(--divider-color);
  border-left: 1px solid var(--divider-color);
  cursor: pointer;
  background-color: var(--component-bg-color);
}

.ellipsis:hover {
  max-width: none !important;
  overflow: visible;
}
.data-row > div {
  flex: 1;
  padding: 4px;
}

.status-icon {
  cursor: pointer;
  position: relative;
}

.status-dropdown {
  position: absolute;
  background-color: var(--component-bg-color);
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 4px;
  margin-top: 4px;
}

.status-option {
  padding: 4px;
  cursor: pointer;
  width: max-content;
}

.status-option:hover {
  background-color: #eee;
}

.chat-input-container {
  display: flex;
  align-items: center;
  margin-top: auto;
  background-color: var(--component-bg-color);
  padding: 10px;
  border-radius: 5px;
  position: relative; /* Make sure to set position to relative, absolute, fixed, or sticky */
  z-index: 10; /* Set a higher value to bring this element in front */
}
.date-picker {
  position: relative;
}

.date-picker::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(163, 62, 62, 0.7); /* Adjust color here */
  z-index: -1; /* Behind the input */
  border-radius: 4px; /* Optional: add some border radius */
}

.status-header {
  font-weight: bold;
  font-size: 16px;
  padding: 8px;
  background-color: var(--component-bg-color);
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  width: max-content;
}

.item-status {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  background-color: #bae9e9;
  border-radius: 6px;
  margin: 0 5px;
}

.q-menu .border {
  border: 1px solid var(--divider-color) !important;
}

#list-item.hide {
  display: none !important;
}
</style>
