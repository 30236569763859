<template>
  <div id="marquee" class="marquee-container">
    <span v-if="showAnimation" class="marquee" @click="hideAnimation">
      <template v-for="(char, index) in firstHalf">
        <span
          :key="'first-' + index"
          :style="{ color: '#8300e6' }"
          :class="char === ' ' ? 'text-space' : ''"
        >
          {{ char }}
        </span>
      </template>

      <template v-for="(char, index) in secondHalf">
        <span
          :key="'second-' + index"
          :style="{ color: '#19c1d4' }"
          :class="char === ' ' ? 'text-space' : ''"
        >
          {{ char }}
        </span>
      </template>
    </span>
    <span v-if="showAnimation" class="cursor">|</span>

    <input
      v-if="!showAnimation"
      ref="taskInput"
      v-model="inputValue"
      :placeholder="showPlaceholder ? dynamicPlaceholder : ''"
      class="input-field"
      @focus="stopAnimation"
      @blur="startAnimation"
      @input="send"
    />
  </div>
</template>

<script>
export default {
  props: {
    errase: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTime: new Date(),
      fullText: "",
      displayedText: "",
      typingSpeed: 50,
      textIndex: 0,
      secondsRunning: false,
      inputValue: "",
      isClicked: false,
      showAnimation: false,
      showPlaceholder: false,
    };
  },
  computed: {
    dynamicPlaceholder() {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const day = days[this.currentTime.getDay()];
      const hours = this.currentTime.getHours().toString().padStart(2, "0");
      const minutes = this.currentTime.getMinutes().toString().padStart(2, "0");

      let taskSuggestion = "";
      if (hours >= 8 && hours < 10) {
        taskSuggestion =
          "Good morning! Kick off your day with Plan Daily Operations";
      } else if (hours >= 10 && hours < 12) {
        taskSuggestion =
          "Approaching mid-morning: Try Typing 'Finalize quarterly budget'";
      } else if (hours >= 12 && hours < 14) {
        taskSuggestion = "Lunchtime: Plan 'Check inventory levels'";
      } else if (hours >= 14 && hours < 16) {
        taskSuggestion =
          "Mid-afternoon focus: Try Typing 'Review production schedules'";
      } else if (hours >= 16 && hours < 18) {
        taskSuggestion =
          "As the day winds down: Action 'Close pending invoices'";
      } else if (hours >= 18 || hours < 6) {
        taskSuggestion =
          "Evening roundup: Try Typing like 'Schedule maintenance checks'";
      } else {
        taskSuggestion = "Create a new task...";
      }

      // Capitalizing the first letter of each word
      taskSuggestion = taskSuggestion
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return `${taskSuggestion}, (${day}, ${hours}:${minutes}:${this.secondsText})`;
    },
    seconds() {
      return this.currentTime.getSeconds().toString().padStart(2, "0");
    },
    secondsText() {
      return this.seconds;
    },
    firstHalf() {
      const mid = Math.ceil(this.displayedText.length / 2);
      return this.displayedText.slice(0, mid);
    },
    secondHalf() {
      const mid = Math.ceil(this.displayedText.length / 2);
      return this.displayedText.slice(mid);
    },
  },
  watch: {
    errase: {
      immediate: true,
      handler() {
        if (this.errase) {
          this.inputValue = "";
          this.$emit("errasing");
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.showAnimation = true;
      this.startTyping();
    }, 500);

    setInterval(() => {
      if (this.secondsRunning) {
        this.currentTime = new Date();
      }
    }, 1000);
  },
  methods: {
    startTyping() {
      this.fullText = this.dynamicPlaceholder;
      this.displayedText = "";
      this.textIndex = 0;
      this.secondsRunning = false;
      this.showPlaceholder = false;
      this.isAnimationComplete = false;
      this.typeCharacter();
    },
    typeCharacter() {
      if (this.textIndex < this.fullText.length) {
        this.displayedText += this.fullText.charAt(this.textIndex);
        this.textIndex++;
        setTimeout(this.typeCharacter, this.typingSpeed);
      } else {
        this.isAnimationComplete = true;
        this.secondsRunning = true;

        this.showPlaceholder = true;
        this.$nextTick(() => {
          this.$refs.taskInput.focus();
        });

        setTimeout(() => {
          this.showAnimation = false;
        }, 2000);
      }
    },
    send() {
      this.$emit("text", this.inputValue);
    },
    updateSeconds() {
      if (!this.isAnimationComplete) return;
      const secondsText = this.seconds;
      this.displayedText = this.fullText + secondsText;
    },
    stopAnimation() {
      this.isClicked = true;
    },
    startAnimation() {
      this.isClicked = false;
    },
    hideAnimation() {
      this.showAnimation = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#marquee {
  .marquee-container {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .marquee {
    display: inline-block;
    background: linear-gradient(90deg, #ff6b8f 0%, #9859ff 50%, #4b47ff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: typing 4s steps(40, end), blink-caret 0.75s step-end infinite;
    font-size: 1em;
    font-weight: bold;
  }

  .text-space {
    margin-left: 5px;
  }

  .cursor {
    display: inline-block;
    animation: blink 0.7s infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  .input-field {
    width: 100%;
    border: none;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
}
</style>
